/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporting.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./indicator-page/indicator-page.component.ngfactory";
import * as i3 from "./indicator-page/indicator-page.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../services/utils/right.service";
import * as i6 from "./services/indicator.service";
import * as i7 from "../../../services/storage/cache.service";
import * as i8 from "./services/chart-registration.service";
import * as i9 from "./services/filter.service";
import * as i10 from "../../../services/api/organisation/organisation.dispatcher";
import * as i11 from "./reporting.component";
var styles_ReportingComponent = [i0.styles];
var RenderType_ReportingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportingComponent, data: {} });
export { RenderType_ReportingComponent as RenderType_ReportingComponent };
export function View_ReportingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-indicator-page", [], null, null, null, i2.View_IndicatorPageComponent_0, i2.RenderType_IndicatorPageComponent)), i1.ɵdid(1, 4571136, null, 0, i3.IndicatorPageComponent, [i1.KeyValueDiffers, i4.Title, i5.RightService, i6.IndicatorService, i7.CacheService, i8.ChartRegistration, i9.FilterService, i10.OrganisationDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ReportingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reporting", [], null, null, null, View_ReportingComponent_0, RenderType_ReportingComponent)), i1.ɵdid(1, 49152, null, 0, i11.ReportingComponent, [], null, null)], null, null); }
var ReportingComponentNgFactory = i1.ɵccf("reporting", i11.ReportingComponent, View_ReportingComponent_Host_0, {}, {}, []);
export { ReportingComponentNgFactory as ReportingComponentNgFactory };
