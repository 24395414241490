import { RosterCompliancesService } from './../../../../../services/api/roster/dependencies/compliances/roster-compliances.api';
import { OrganisationCompliancesService } from './../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Uploader, UploadItem } from 'angular2-http-file-upload';
import { saveAs as importedSaveAs } from "file-saver";
import { URL_API_EMALSYS } from '../../../../../environments/environment';
import { DocumentService } from '../../../../../services/api/document.service';
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { WSSEService } from '../../../../../services/security/wsse.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
// Data
import { COMPLIANCE_CODE } from '../../../../app.constants';
@Component({
  selector: 'app-roster-invite-modal',
  templateUrl: './roster-invite-modal.component.html',
  styleUrls: ['./roster-invite-modal.component.scss']
})
export class RosterInviteModalComponent implements OnInit {
  @Input() notificationRoster;
  public gallery = false;
  public selectedDocument: any;
  public documentListSelect: any = [];

  public titleFile: string;
  public selectedFile: File;
  public rosterFilledCompliances: any;
  public rosterEmptyCompliances: any;
  public orgaFilledCompliances: any;
  public orgaEmptyCompliances: any;

  public complianceId;
  public context;
  public addDocument: boolean;
  constructor(
    private _documentService: DocumentService,
    private _profileService: ProfileService,
    public activeModal: NgbActiveModal,
    private _loggerService: LoggerService,
    private _rosterComplianceService: RosterCompliancesService,
    private _organisationService: OrganisationCompliancesService,
    private _uploaderService: Uploader,
    private _modalService: NgbModal,
    private _wsseService: WSSEService,
  ) { }

  ngOnInit() {
    this.addDocument = false;
    this.getRosterCompliances();
    this.getDocuments();
  }

  getRosterCompliances() {
    this._rosterComplianceService.getRosterCompliancesOfUser(this.notificationRoster.roster.id, this.notificationRoster.user.id).subscribe(res => {
      let result = res;
      this.rosterFilledCompliances = result[0];
      this.rosterEmptyCompliances = result[1];
      this.orgaFilledCompliances = result[2];
      this.orgaEmptyCompliances = result[3];
    });
  }

  // uploadComplianceDocumentModal(complianceId,context) {
  //   let modalRef = this._modalService.open(AddDocumentCompliancesModalComponent, { windowClass: 'emalsys-modal' });
  //   modalRef.componentInstance.complianceId = complianceId;
  //   modalRef.componentInstance.context = context;
  //   modalRef.componentInstance.onAdd.subscribe(($e) => {
  //     this.getRosterCompliances();
  //   })
  // }

  deleteRosterComplianceDocument(complianceId) {
    this._rosterComplianceService.deleteComplianceDocument(complianceId).subscribe(res => {
      this.getRosterCompliances();
    });
  }

  deleteOrganisationComplianceDocument(complianceId) {
    this._organisationService.deleteComplianceDocument(complianceId).subscribe(res => {
      this.getRosterCompliances();
    });
  }

  downloadDoc(document) {
    this._loggerService.log("Doc ID to download: ", document.id);

    this._documentService.documentDownload(document.id).subscribe(response => {
      importedSaveAs(response, document.title);
      this._loggerService.log("Blob received: ", response);
    }, error => {
      this._loggerService.error("Failed to download the file: ", error);
    });
  }
  activeAddDocument(complianceId, context) {
    this.complianceId = complianceId;
    this.addDocument = true;
    this.context = context;
  }

  desactiveAddDocument() {
    this.complianceId = null;
    this.addDocument = false;
    this.context = null;


  }
  setLevel(member) {
    return COMPLIANCE_CODE.filter(level => level.value === member.level)[0].label;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
    }
  }

  changedSelectDocument(data) {
    this.selectedDocument.id = data.value;
    this.selectedDocument.document = data.data[0].document;
    this.selectedDocument.text = data.data[0].text;
  }

  getDocuments() {
    this._profileService.getMyDocuments().subscribe(res => {
      let documentList = res;
      this.documentListSelect = [];
      for (let i = 0; i < documentList.length; i++) {
        this.documentListSelect.push(
          {
            id: documentList[i]['id'],
            document: documentList[i],
            text: documentList[i]['title']
          }
        );
      }
      if (this.documentListSelect && this.documentListSelect.length > 0) {
        this.selectedDocument = this.documentListSelect[0];
      }
    });
  }

  addCompliance(selectedDocumentId) {
    if (this.context === 'roster') {
      this._rosterComplianceService.addComplianceDocument(this.complianceId, selectedDocumentId).subscribe(res => {
        this.getDocuments();
        this.getRosterCompliances();
      });
    } else if (this.context === 'organisation') {
      this._organisationService.addComplianceDocument(this.complianceId, selectedDocumentId).subscribe(res => {
        this.getDocuments();
        this.getRosterCompliances();
      });
    } else {
      this._loggerService.log("Missing context");
    }
    this.desactiveAddDocument();
  }

  uploadDoc() {
    let uploadItem = new UploadItem();
    uploadItem.url = URL_API_EMALSYS + "/me/docs";
    uploadItem.headers = { 'x-wsse': this._wsseService.getHeaderValue() };
    if (this.titleFile) {
      uploadItem.formData = { title: this.titleFile };
    } else {
      uploadItem.formData = { title: this.selectedFile.name };
    }
    uploadItem.file = this.selectedFile;
    this.submitDoc(uploadItem);
  }

  submitDoc(item: UploadItem) {
    this._uploaderService.onSuccessUpload = (item2, response, status, headers) => {
      this.addCompliance(response[response.length - 1].id);
      this._loggerService.log("Success uploading file: ", item2, response, status, headers);
    };
    this._uploaderService.onErrorUpload = (item2, response, status, headers) => {
      this._loggerService.error("Error uploading file: ", item2, response, status, headers);
    };
    this._uploaderService.onCompleteUpload = (item2, response, status, headers) => {
      this.titleFile = null;

    };
    this._uploaderService.upload(item);
  }
}
