import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
// Services
import { RosterService } from '../../../../../../services/api/roster/roster.cached';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../../services/utils/toast.service';
import { RosterMembershipService } from '../../../../../../services/api/roster/dependencies/membership/roster-membership.api';


@Component({
  selector: 'roster-infos',
  templateUrl: './roster-infos.component.html',
  styleUrls: ['./roster-infos.component.scss']
})


export class RosterInfosComponent implements OnChanges {

  @Input() rosterId: number;
  @Input() rosterStatus: string;

  public roster: any;
  public displayButtons: number;   // To display the right buttons : 1 = action, 2 = confirmation

  constructor(
    private titleService: Title,
    private _rosterService: RosterService,
    private _loggerService: LoggerService,
    private router: Router,
    private _toastService: ToastService,
    private _rosterMembershipService: RosterMembershipService
  ) {
    this.roster = {};
    this.displayButtons = 1;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.rosterId && changes.rosterId.currentValue !== changes.rosterId.previousValue) {
      this.getRoster();
    }

  }

  getRoster() {
    this._rosterService.getRoster(this.rosterId).subscribe(res => {
      this.roster = res;
      this._loggerService.log("ROSTER : ", res);
      this.titleService.setTitle(this.roster.organisation.name + " - " + this.roster.name);
    });
  }

  joinRoster() {
    this._rosterMembershipService.joinRoster(this.roster.id).subscribe(res => {
      this._loggerService.log("Roster joined");
      this.showAction();
      this.router.navigate(['/responder/organisation/' + this.roster.organisation.id + '/roster/request/' + this.roster.id]);
    });
  }

  cancelPendingRequest() {
    this._rosterMembershipService.cancelPendingRequest(this.roster.id).subscribe(res => {
      // this._loggerService.log("Request cancelled");
      this.showToastCancelJoinRequest();
      this.showAction();
      this.router.navigate(['/responder/organisation/' + this.roster.organisation.id]);
    });
  }

  quitRoster() {
    this._rosterMembershipService.quitRoster(this.roster.id).subscribe(res => {
      // this._loggerService.log("Roster left");
      this.showToastQuitRoster();
      this.showAction();
      this.router.navigate(['/responder/organisation/' + this.roster.organisation.id]);
    });
  }

  acceptInvitation() {
    this._rosterMembershipService.acceptRosterMemberInvitation(this.roster.id).subscribe(res => {
      // this._loggerService.log("Invitation accepted");
      this.showToastAcceptInvitation();
      this.showAction();
      this.router.navigate(['/responder/organisation/' + this.roster.organisation.id + '/roster/member/' + this.roster.id]);
    });
  }

  declineInvitation() {
    this._rosterMembershipService.declineRosterMemberInvitation(this.roster.id).subscribe(res => {
      // this._loggerService.log("Invitation refused");
      this.showToastRefuseInvitation();
      this.showAction();
      this.router.navigate(['/responder/organisation/' + this.roster.organisation.id]);
    });
  }

  showAction() {
    this.displayButtons = 1;
  }

  showConfirmation() {
    this.displayButtons = 2;
  }


  showToastAcceptInvitation() {
    this._toastService.show("acceptInvitationToast", "show", 3000);
  }
  showToastRefuseInvitation() {
    this._toastService.show("refuseInvitationToast", "show", 3000);
  }
  showToastCancelJoinRequest() {
    this._toastService.show("cancelJoinRequestToast", "show", 3000);
  }
  showToastQuitRoster() {
    this._toastService.show("quitRosterToast", "show", 3000);
  }

}
