<div class="dashboard-members-view">
  <div class="component-member"
    [ngClass]="{'shadow' : selected[member.id], 'disabled' : isActiveOrganisationMember(member)==0}"
    *ngFor="let member of members" (click)="isActiveOrganisationMember(member)==1 ? checkboxChange(member.id) : null">
    <div *ngIf="selected[member.id]" class="selected">
      <i class="fa fa-check fa-xs"></i>
    </div>
    <div
      *ngIf="canManageRights(member)"
      class="member-dropdown">
      <button (click)="showDropdown(member.id); $event.stopPropagation();" class="member-dropdown-button">
        <i class="fa fa-caret-down"></i>
      </button>
      <div class="member-dropdown-content" [ngClass]="{'show' : show == member.id}">

        <div *ngIf="!isRosterSelected() && checkOrganisationAssociate()">
          <p *ngIf="isActiveOrganisationMember(member) == 1 && ((!isOrganisationSuperAdmin(member) && checkOrganisationAssociate())
                  ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile) && !checkIsOwner(member))
                  )" (click)="freezeOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-ban"></i> Freeze Member</p>
          <p *ngIf="isActiveOrganisationMember(member) == 0 && ((!isOrganisationSuperAdmin(member) && checkOrganisationAssociate())
                ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile))
                || checkIsOwner(member))"
            (click)="activeOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-ban"></i> Activate Member</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 &&
           (!isOrganisationSuperAdmin(member)||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile))
              )" (click)="removeFromOrganisation(member);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-trash"></i> Remove from Organisation</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 && isNotOrganisationManager(member)"
            (click)="promoteToOrganisationManager(member.id,member.email);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-plus"></i> Promote to Organisation Manager</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAdmin(member)  && isOrganisationSuperAdmin(profile)"
            (click)="changeToSuperAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
            <i class="fa fa-level-up"></i> Promote to Super Admin</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAssociate(member)"
            (click)="changeToAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
            <i class="fa fa-level-up"></i> Promote to Admin</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile)"
            (click)="changeToAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
            <i class="fa fa-level-down"></i> Demote to Admin</p>
          <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAdmin(member)"
            (click)="changeToAssociate(member.id);showDropdown(member.id);$event.stopPropagation();">
            <i class="fa fa-level-down"></i> Demote to Associate</p>

          <p *ngIf="isActiveOrganisationMember(member) == 1 
          && !isNotOrganisationManager(member)
          && (!isOrganisationSuperAdmin(member)
          ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile)
              ))" (click)="demoteToOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-minus"></i> Demote to Organisation Member</p>
        </div>

        <div *ngIf="isRosterSelected() && checkRosterGuest()">
          <p *ngIf="isActiveRosterMember(member) == 1 &&((!isRosterSupervisor(member) && checkRosterGuest())
              ||(isRosterSupervisor(member) && isRosterSupervisor(profile))
             )" (click)="removeRosterMember(member);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-trash"></i> Remove from Roster</p>
          <p *ngIf="isActiveRosterMember(member) == 1 && isNotRosterManager(member)"
            (click)="promoteToRosterManager(member.id,member.email);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-plus"></i> Promote to Guest</p>
          <p *ngIf="isActiveRosterMember(member) == 1 && isRosterRightManager(member) && isRosterSupervisor(profile)"
            (click)="changeToSupervisor(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-level-up"></i> Promote to Supervisor</p>
          <p *ngIf="isActiveRosterMember(member) == 1 && isRosterGuest(member)"
            (click)="changeToManager(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-level-up"></i> Promote to Manager</p>
          <p *ngIf="isActiveRosterMember(member) == 1 && isRosterSupervisor(member) && isRosterSupervisor(profile)"
            (click)="changeToManager(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-level-down"></i> Demote to Manager</p>
          <p *ngIf="isActiveRosterMember(member) == 1 && isRosterRightManager(member)"
            (click)="changeToGuest(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-level-down"></i> Demote to Guest</p>

          <p *ngIf="isActiveRosterMember(member) == 1 && !isNotRosterManager(member)
          && (!isRosterSupervisor(member) ||(isRosterSupervisor(member) && isRosterSupervisor(profile)
              ))" (click)="demoteToRosterMember(member.id);showDropdown(member.id); $event.stopPropagation();">
            <i class="fa fa-minus"></i> Demote to Roster member</p>
        </div>
      </div>
    </div>
    <div *ngIf="!tagsMode" class="see-member" (click)="openProfile(member); $event.stopPropagation();">
      <i class="fa fa-user-o"></i>
    </div>
    <div [ngClass]="{'available': isMemberAvailable(member.end_unavailability), 'tags': tagsMode}"
      class="component-member-image">
      <img class="image-logo" src="{{member.profile.picture}}" onerror='this.src="assets/images/default_roster.png"' />
    </div>

    <div [ngClass]="{'tags': tagsMode}" class="component-member-name">
      <h3 [ngClass]="{'disabled' : isActiveOrganisationMember(member)==0}" class="name-text">{{member.firstname}}
        {{member.lastname}}</h3>
    </div>

    <div class="component-member-manager" *ngIf="(!rosterId || rosterId==-1) && !isNotOrganisationManager(member)">
      <h4 class="text">Organisation Manager</h4>
    </div>
    <div class="component-member-manager" *ngIf="!tagsMode && (rosterId && risterId!==-1) && !isNotRosterManager(member)">
      <h4 class="text">Roster Manager</h4>
    </div>

    <div class="component-manager-right" *ngIf="(!rosterId || rosterId==-1) && isOrganisationAssociate(member)">
      <h4 class="text">Associate</h4>
    </div>
    <div class="component-manager-right" *ngIf="(!rosterId || rosterId==-1) && isOrganisationAdmin(member)">
      <h4 class="text">Admin</h4>
    </div>
    <div class="component-manager-right" *ngIf="(!rosterId || rosterId==-1) && isOrganisationSuperAdmin(member)">
      <h4 class="text">Super admin</h4>
    </div>

    <div class="component-manager-right" *ngIf="!tagsMode && (rosterId && rosterId!==-1) && isRosterGuest(member)">
      <h4 class="text">Guest</h4>
    </div>
    <div class="component-manager-right" *ngIf="!tagsMode &&  (rosterId && rosterId!==-1)  && isRosterRightManager(member)">
      <h4 class="text">Manager</h4>
    </div>
    <div class="component-manager-right" *ngIf="!tagsMode &&  (rosterId && rosterId!==-1)  && isRosterSupervisor(member)">
      <h4 class="text">Supervisor</h4>
    </div>

    <div *ngIf="tagsMode" class="tags-list">
      <div *ngFor="let tag of tagsList; index as i" class="background{{i%20+1}}"
        [ngClass]="{'notTicked': !isMemberTagged(member, tag.id)}"></div>
    </div>


  </div>
<div id="loading-member-promotion" class="toast green-toast">Role change execution, please wait...</div>
<div id="role-changed" class="toast green-toast">Role changed successfully!</div>
<div id="member-demoted" class="toast green-toast">Member demoted successfully!</div>

</div>
