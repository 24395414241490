<div id="forbidden-component">
  <div class="logo-container">
    <img class="emalsys-logo" src="../assets/images/emalsys_hand_background_red.png" alt="Emalsys">
  </div>
  <div class="window-error">
    <div class="circle">
      <i class="fa fa-hand-paper-o custom-icon" aria-hidden="true"></i>
    </div>

    <div class="infos-error">
      <h1 class="title-error">You don't have access to this section</h1>
      <p class="text-error">
        We're sorry, you don't have access to the page you requested.<br> You don't have permission to access the requested page.
        <a (click)="back()">Please go back to the previous page.</a>
    </div>
  </div>
</div>
