<!--Dashboard component: dashboard of selected organisation -->
<div class="dashboard-component">
  <dashboard-preloader-news *ngIf="!loadedAlerts" class="dashboard-component-news"></dashboard-preloader-news>
  <dashboard-news class="dashboard-component-news" *ngIf="loadedAlerts" [organisation]='organisation'></dashboard-news>

  <dashboard-preloader-alerts *ngIf="!loadedAlerts" class="dashboard-component-alerts"></dashboard-preloader-alerts>
  <dashboard-alerts class="dashboard-component-alerts" *ngIf="loadedAlerts" [organisation]='organisation'>
  </dashboard-alerts>

  <dashboard-preloader-rosters *ngIf="!loadedRosters" class="dashboard-component-rosters"></dashboard-preloader-rosters>
  <dashboard-responder-rosters *ngIf="loadedRosters" class="dashboard-component-rosters" [organisation]='organisation'>
  </dashboard-responder-rosters>
</div>
<div id="joinRequestSendToast">Request sent</div>