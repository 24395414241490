import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from 'selenium-webdriver';


export class AlertButton {
  public static RED = 'red';
  public static GREEN = 'green';

  text: string;
  color: string;
  callback: Function;
}



@Component({
  selector: 'app-alert-modal-confirmation',
  templateUrl: './alert-modal-confirmation.component.html',
  styleUrls: ['./alert-modal-confirmation.component.scss']
})
export class AlertModalConfirmationComponent implements OnInit {

  // This because I couldn't find a way
  // to use the static variables into the template!
  readonly RED = AlertButton.RED;
  readonly GREEN = AlertButton.GREEN;

  title = "";
  body = "";
  buttons = [];

  constructor(
    public _activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }
}
