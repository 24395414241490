/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preloader-profile-documents.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preloader-profile-documents.component";
var styles_PreloaderProfileDocumentsComponent = [i0.styles];
var RenderType_PreloaderProfileDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreloaderProfileDocumentsComponent, data: {} });
export { RenderType_PreloaderProfileDocumentsComponent as RenderType_PreloaderProfileDocumentsComponent };
export function View_PreloaderProfileDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "profile-preloader-documents shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "profile-preloader-documents-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "profile-preloader-documents-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "profile-preloader-documents-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "profile-preloader-documents-line"]], null, null, null, null, null))], null, null); }
export function View_PreloaderProfileDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preloader-profile-documents", [], null, null, null, View_PreloaderProfileDocumentsComponent_0, RenderType_PreloaderProfileDocumentsComponent)), i1.ɵdid(1, 114688, null, 0, i2.PreloaderProfileDocumentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreloaderProfileDocumentsComponentNgFactory = i1.ɵccf("preloader-profile-documents", i2.PreloaderProfileDocumentsComponent, View_PreloaderProfileDocumentsComponent_Host_0, {}, {}, []);
export { PreloaderProfileDocumentsComponentNgFactory as PreloaderProfileDocumentsComponentNgFactory };
