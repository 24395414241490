import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { environment } from './environments/environment';
// Services
import { CacheService } from './services/storage/cache.service';
import { LoggerService } from './services/utils/logger.service';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
// Retrieve local from the cache
let cache = new CacheService();
let settings = cache.get(CacheService.SETTINGS) || {};
// Logger
let logger = new LoggerService(cache);
if (!settings.language) {
    settings.language = "en";
    cache.set(CacheService.SETTINGS, settings, { canBeDeleted: false });
    logger.log("Langue default EN: ", settings.language);
}
let locale = settings.language;
// we use the webpack raw-loader to return the content as a string
const translations = require(`raw-loader!./app/locale/messages.${locale}.xlf`);
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    providers: [
        { provide: TRANSLATIONS, useValue: translations },
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' }
    ]
})
    .catch(err => logger.log(err));
