import { PushNotificationInterface } from './../../app/model/pushnotification.interface';
import { firebaseConfig } from './../../environments/firebasedata';
import { SwPush } from '@angular/service-worker';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { URL_API_EMALSYS } from '../../environments/environment';
import { switchMap, take, tap } from 'rxjs/operators';
import { of, from, BehaviorSubject } from 'rxjs';
import { LoggerService } from '../utils/logger.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';

/**
 * This class provides all the steps to subscribe to push notifications.
 * For further references, please visit https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications
 */
@Injectable()
export class PushNotificationService {

    message = new BehaviorSubject<any>(null);

    constructor(
        private sw: SwPush,
        private http: HttpService,
        private trueHttp: HttpClient,
        private logger: LoggerService,
        private fireAuth: AngularFireAuth,
        private fireMessaging: AngularFireMessaging
    ) {
        this.fireMessaging.messaging.subscribe(
            (messaging) => {
                messaging.onMessage = messaging.onMessage.bind(messaging);
                messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
            }
        );
    }

    subscribeToNotifications(userId: number) {
        this.listenForNotifications(userId);

    }
    updateFirebaseToken(userId, token) {
        return this.fireAuth.authState.pipe(take(1)).pipe(
            switchMap(() => {
                let data = new FormData();
                data.append('notificationToken', token);
                return this.trueHttp.post(URL_API_EMALSYS + '/pushnotifications/subscribe', data, this.http.createRequestOptions());
            })
        );
    }

    listenForNotifications(userId) {
        // Get the token from Firebase
        this.fireMessaging.requestToken.pipe(
            switchMap(
                token => {
                    // Send it to the backend
                    return this.updateFirebaseToken(userId, token);
                }
            ),
            switchMap(
                res => {
                    return this.fireMessaging.messages;
                }
            )
        // Subscribe to receive the notifications
        ).subscribe(
            (res: PushNotificationInterface) => {
                // Send the notification to the service to be displayed
                this.logger.log("GOT NOTIF", res);
            },
            (err) => {
                this.logger.error(err);
            }
        );
    }

    receiveMessage() {
        this.fireMessaging.messages.subscribe(
            (payload) => {
                // The payload will be "captured" by a PushNotificationDisplayService
                this.message.next(payload);
            }
        );
    }

}
