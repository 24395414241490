import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
// Routing
import { PublicPagesRouting } from './public-pages.routing';

// Modules
import { SharedModule   } from '../../shared.module';
import { RegisterModule } from './register/register.module';

// Components
import { HomeComponent           } from './home/home.component';
import { PublicPagesComponent    } from './public-pages.component';
import { LoginComponent          } from './login/login.component';
import { FeaturesComponent       } from './features/features.component';
import { PricingComponent        } from './pricing/pricing.component';
import { ContactUsComponent      } from './contact-us/contact-us.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { AboutComponent          } from './about/about.component';
import { FaqComponent            } from './faq/faq.component';
import { CareerComponent         } from './career/career.component';
import { TermsComponent          } from './terms/terms.component';
import { UserAgreementComponent  } from './user-agreeement/user-agreement.component';
import { PrivacyComponent        } from './privacy/privacy.component';
import { RegisterAsOrganisationComponent } from './register-as-organisation/register-as-organisation.component';

// Sub Components
import { HomefeaturesComponent     } from './home/home-features/home-features.component';
import { FeaturesCarouselComponent } from './home/features-carousel/features-carousel.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { GdprCookieAcceptationModalComponent } from './modals/gdpr-cookie-acceptation-modal/gdpr-cookie-acceptation-modal.component';
import { PasswordModalComponent } from './modals/password-modal/password-modal.component';
import { ConfirmationUserComponent } from './confirmation-user/confirmation-user.component';



@NgModule({
  imports: [
    // Routing
    PublicPagesRouting,
    // Angular Modules
    CommonModule,
    // Feature Modules
    SharedModule,
    RegisterModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  declarations: [
    HomeComponent,
    PublicPagesComponent,
    LoginComponent,
    FeaturesComponent,
    PricingComponent,
    ContactUsComponent,
    GettingStartedComponent,
    HomefeaturesComponent,
    FeaturesCarouselComponent,
    AboutComponent,
    FaqComponent,
    CareerComponent,
    TermsComponent,
    UserAgreementComponent,
    PrivacyComponent,
    LoginModalComponent,
    GdprCookieAcceptationModalComponent,
    RegisterAsOrganisationComponent,
    PasswordModalComponent,
    ConfirmationUserComponent,
  ],
  providers: [],
   entryComponents: [
       LoginModalComponent,
       GdprCookieAcceptationModalComponent,
       PasswordModalComponent
   ]
})


export class PublicPagesModule {}
