import { RosterMembershipService } from './../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { OrganisationMembershipService } from './../../../../services/api/organisation/dependencies/membership/organisation-membership.api';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { LoggerService } from '../../../../services/utils/logger.service';
import { ListService } from '../../../../services/utils/list.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { UserEventAnswerService } from '../../../../services/api/usereventanswer.service';
import { DeploymentOrderService } from '../../../../services/api/deployment-order.service';
import { DeploymentService } from "../../../../services/api/deployment.service";
import { RosterRequestModalComponent } from './roster-request-modal/roster-request-modal.component';
import { RosterInviteModalComponent } from './roster-invite-modal/roster-invite-modal.component';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../services/utils/toast.service';
import { OrganisationInterface } from '../../../model/organisation.interface';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges {
  @Output() onRefreshClick = new EventEmitter<any>();
  @Output() onCloseClick = new EventEmitter<any>();
  @Output() onAnswer = new EventEmitter<any>();
  @Output() onAnswerOrg = new EventEmitter<any>();

  @Input() notificationList;
  @Input() notificationTypeCount;
  @Input() notificationCount: number;

  public notificationOrganisation;
  public invitationOrganisationCount: number;

  public notificationRoster;
  public invitationRosterCount: number;

  public noAnsweredAlert;
  public noAnsweredAlertCount: number;

  public deployments;
  public deploymentsCount: number;


  public invitationOrganisationFilter: boolean;
  public invitationRosterFilter: boolean;
  public noAnsweredAlertFilter: boolean;
  public deploymentsFilter: boolean;

  public selectedOrganisation: OrganisationInterface;

  constructor(
    public _loggerService: LoggerService,
    public _listService: ListService,
    public _navigationService: NavigationService,
    public _usereventanswerService: UserEventAnswerService,
    public _deploymentService: DeploymentService,
    public _deploymentOrderService: DeploymentOrderService,
    public _rosterMembershipService: RosterMembershipService,
    public _organisationMembership: OrganisationMembershipService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    public _orgnisationDispatcher: OrganisationDispatcherService
  ) { }

  ngOnInit() {
    this.dispatchNotification();
    this._orgnisationDispatcher.getSelectedOrganisation().subscribe( res => this.selectedOrganisation = res);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.notificationCount && changes.notificationCount.currentValue !== changes.notificationCount.previousValue)
      && (changes.notificationList && changes.notificationList.currentValue !== changes.notificationList.previousValue)
      && (changes.notificationTypeCount && changes.notificationTypeCount.currentValue !== changes.notificationTypeCount.previousValue)
    ) {
      this.dispatchNotification();
    }
  }

  close() {
    this.onCloseClick.emit(false);
  }

  dispatchNotification() {

    this.notificationOrganisation = [];
    for (let i = 0; i < this.notificationList[3].length; i++) {
      let item = {
        notification: this.notificationList[3][i],
        date: new Date(this.notificationList[3][i].creation_date),
        type: 1
      };
      this.notificationOrganisation.push(item);
    }

    for (let i = 0; i < this.notificationList[5].length; i++) {
      let item = {
        notification: this.notificationList[5][i],
        date: new Date(this.notificationList[5][i].creation_date),
        type: 2
      };
      this.notificationOrganisation.push(item);
      this.notificationOrganisation.sort(function (a, b) {
        if (a.date > b.date) {
          return 1;
        } else if (a.date < b.date) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    this._loggerService.log(this.notificationOrganisation);

    this.invitationOrganisationCount = this.notificationTypeCount[3] + this.notificationTypeCount[5];
    if (this.invitationOrganisationCount) {
      this.invitationOrganisationFilter = true;
    } else {
      this.invitationOrganisationFilter = false;
    }


    this.notificationRoster = [];
    for (let i = 0; i < this.notificationList[0].length; i++) {
      let item = {
        notification: this.notificationList[0][i],
        date: new Date(this.notificationList[0][i].creation_date),
        type: 1
      };
      this.notificationRoster.push(item);
    }

    for (let i = 0; i < this.notificationList[4].length; i++) {
      let item = {
        notification: this.notificationList[4][i],
        date: new Date(this.notificationList[4][i].creation_date),
        type: 2
      };
      this.notificationRoster.push(item);
    }

    for (let i = 0; i < this.notificationList[6].length; i++) {
      let item = {
        notification: this.notificationList[6][i],
        date: new Date(this.notificationList[6][i].creation_date),
        type: 3
      };
      this.notificationRoster.push(item);
      this.notificationRoster.sort(function (a, b) {
        if (a.date > b.date) {
          return 1;
        } else if (a.date < b.date) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    this._loggerService.log(this.notificationRoster);
    this.invitationRosterCount = this.notificationTypeCount[0] + this.notificationTypeCount[4] + this.notificationTypeCount[6];
    if (this.invitationRosterCount) {
      this.invitationRosterFilter = true;
    } else {
      this.invitationRosterFilter = false;
    }

    this.deployments = [];
    // Deployment offers
    for (let i = 0; i < this.notificationList[2].length; i++) {
      let item = {
        notification: this.notificationList[2][i],
        date: new Date(this.notificationList[2][i].creation_date),
        type: 1
      };
      this.deployments.push(item);
    }
    // Deployment orders
    if (this.notificationList[7]) {
      for (let i = 0; i < this.notificationList[7].length; i++) {
        let item = {
          id: this.notificationList[7][i].id,
          endDate: this.notificationList[7][i].end_date,
          deploymentDate: this.notificationList[7][i].deploymentdate,
          deploymentOrder: this.notificationList[7][i].deployment_order,
          creationDate: this.notificationList[7][i].deployment_order.creation_date,
          type: 2
        };
        this.deployments.push(item);
        this.deployments.sort(function (a, b) {
          if (a.date > b.date) {
            return 1;
          } else if (a.date < b.date) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    }
    this.deploymentsCount = this.notificationTypeCount[2] + (this.notificationTypeCount[7] ? this.notificationTypeCount[7] : 0);
    if (this.deploymentsCount) {
      this.deploymentsFilter = true;
    } else {
      this.deploymentsFilter = false;
    }

    this.noAnsweredAlert = this.notificationList[1];
    this.noAnsweredAlertCount = this.notificationTypeCount[1];
    if (this.noAnsweredAlertCount) {
      this.noAnsweredAlertFilter = true;
    } else {
      this.noAnsweredAlertFilter = false;
    }

    // =======================Alert Notifications======================//
  }
  setAvailability(id, startDate, endDate, status) {
    let answer = {
      startDate: null,
      endDate: null,
      status: null,
    };
    answer.startDate = new Date(startDate);
    answer.startDate.setDate(answer.startDate.getDate() + 1);
    answer.endDate = new Date(endDate);
    answer.endDate.setDate(answer.endDate.getDate() + 1);
    answer.status = status;
    this._loggerService.log(id);
    this._loggerService.log(answer);
    this._usereventanswerService.setAvailability(id, answer)
      .subscribe(res => {
        this.onAnswer.emit(res);
        this.showToastSetAvailabilities();
      });
  }

  // =========================Deploy Notifications======================//

  // Validate the offer
  validateDeploymentOffer(idDeploymentOffer) {
    this._deploymentOrderService.validateDeploymentOffer(idDeploymentOffer).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastAcceptOffer();
    });
  }

  // Refuse the offer
  refuseDeploymentOffer(idDeploymentOffer) {
    this._deploymentOrderService.refuseDeploymentOffer(idDeploymentOffer).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastRefuseOffer();
    });
  }

  redirectAlert(idAlert, organisationId?) {
    this._navigationService.goToAlertDetails(idAlert, this.selectedOrganisation.id);
  }

  // Ackonwledge the order
  acknowledgeDeploymentOrder(idDeployment) {
    this._deploymentService.acknowledgeDeploymentOrder(idDeployment).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastAcknowledgeOrder();
    });
  }
  // ===========================Roster Notifications======================//
  acceptRosterMemberRequest(rosterId, userId) {
    this._rosterMembershipService.acceptRosterMemberRequest(rosterId, userId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastAcceptJoinRequest();
    });
  }

  declineRosterMemberRequest(rosterId, userId) {
    this._rosterMembershipService.declineRosterMemberRequest(rosterId, userId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastRefuseJoinRequest();
    });
  }

  openRequestModal(notificationRoster) {
    let modalRef = this._modalService.open(RosterRequestModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.notificationRoster = notificationRoster;
  }

  acceptRosterMemberInvitation(rosterId) {
    this._rosterMembershipService.acceptRosterMemberInvitation(rosterId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastAcceptInvitation();
    });
  }

  declineRosterMemberInvitation(rosterId) {
    this._rosterMembershipService.declineRosterMemberInvitation(rosterId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastRefuseInvitation();
    });
  }

  acceptRosterManagerInvitation(rosterId) {
    this._rosterMembershipService.acceptRosterManagerInvitation(rosterId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastAcceptInvitation();
    });
  }

  declineRosterManagerInvitation(rosterId) {
    this._rosterMembershipService.declineRosterManagerInvitation(rosterId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastRefuseInvitation();
    });
  }

  // redirectRosterMember(idOrga, idRoster) {
  //   this._navigationService.goToRosterDetails(idOrga, idRoster)
  // }

  openInviteModal(notificationRoster) {
    let modalRef = this._modalService.open(RosterInviteModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.notificationRoster = notificationRoster;
  }

  // ========================Organisation Notifications======================//

  acceptOrganisationMemberInvitation(orgaId) {
    this._organisationMembership.acceptOrganisationMemberInvitation(orgaId).subscribe(res => {
      this.onAnswerOrg.emit(res);
      this.showToastAcceptInvitation();
    });
  }

  declineOrganisationMemberInvitation(orgaId) {
    this._organisationMembership.declineOrganisationMemberInvitation(orgaId).subscribe(res => {
      this.onAnswerOrg.emit(res);
      this.showToastRefuseInvitation();
    });
  }

  acceptOrganisationManagerInvitation(orgaId) {
    this._organisationMembership.acceptOrganisationManagerInvitation(orgaId).subscribe(res => {
      this.onAnswerOrg.emit(res);
      this.showToastAcceptInvitation();
    });
  }

  declineOrganisationManagerInvitation(orgaId) {
    this._organisationMembership.declineOrganisationManagerInvitation(orgaId).subscribe(res => {
      this.onAnswer.emit(res);
      this.showToastRefuseInvitation();
    });
  }

  // ========================Toasts======================//

  showToastSetAvailabilities() {
    this._toastService.show("setAvailabilitiesToast", "show", 3000);
  }

  showToastAcceptOffer() {
    this._toastService.show("acceptOfferToast", "show", 3000);
  }
  showToastRefuseOffer() {
    this._toastService.show("refuseOfferToast", "show", 3000);
  }
  showToastAcceptJoinRequest() {
    this._toastService.show("acceptJoinRequestToast", "show", 3000);
  }
  showToastRefuseJoinRequest() {
    this._toastService.show("refuseJoinRequestToast", "show", 3000);
  }
  showToastAcceptInvitation() {
    this._toastService.show("acceptInvitationToast", "show", 3000);
  }
  showToastRefuseInvitation() {
    this._toastService.show("refuseInvitationToast", "show", 3000);
  }
  showToastAcknowledgeOrder() {
    this._toastService.show("acknowledgeOrder", "show", 3000);
  }
}
