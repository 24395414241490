<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Add members to alert</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="modal-text">
    <p>
      Choose a roster
    </p>
  </div>
  <div class="modal-select">
    <select2 class="modal-select-email" cssImport="true" width="100%" [options]="rosterOptions" (valueChanged)="rosterChanged($event)">
    </select2>
  </div>
  <div *ngIf="this.rosterId">
    <div class="modal-text">
      <p>
        Enter name or email (roster member and managers only)
      </p>
    </div>
    <div class="modal-select">
      <select2 class="modal-select-email" cssImport="true" width="100%" [options]="membersOptions" (valueChanged)="membersChanged($event)">
      </select2>
    </div>
  </div>

</div>
<div class="loading-custom" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button type="submit" class="btn emalsys_button green" (click)="addMembers()" [disabled]="!rosterId && !usersId || loading">Add members</button>
</div>

<div id="addMemberToAlertToast">Addition of a member to the alert failed</div>
