<div id="privacy-component">

  <h1 class="big-title">
    <p class="custom-title" i18n="@@privacyTitle">
      privacy
    </p>
  </h1>

  <div class="page-content">

    <div>
      <h2 i18n="@@privacyParagraph1">General terms</h2>
      <p i18n="@@privacyParagraph1Text1">
        This Privacy Policy provides our policies and procedures for collecting, using, and disclosing your information.
        This policy governs your access to our platform (“Emalsys”) and all services through any device or third party
        application. Relief Applications is following the French law in that matter and comply by the rules of the CNIL
        (Commision Nationale Informatique et Liberté) and is registered as n°144143111, you can find more information
        about the French CNIL at (http://www.cnil.fr). </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph12">Commitment to your privacy</h2>
      <p i18n="@@privacyParagraph12Text1">This website is owned and operated by Relief Applications. We understand you
        value your privacy. At Relief Applications, we want to make your online experience satisfying and safe. This
        statement governs information you provide to us, or we learn from your use of this website, and tells you how we
        may collect, use and, in some instances, share this information.</p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph2">Account Data</h2>
      <p i18n="@@privacyParagraph2Text1">
        We collect your name, email, phone, industry and role during signup. If you upgrade your subscription you will
        be asked for some billing information for payment purposes. The collected data stays with Relief Applications
        and its regional representatives. We may receive your email address and other personal information via other
        users that have invited you to their account.
      </p>

      <p i18n="@@privacyParagraph2Text2">
        How do we collect your data?
        You directly provide Relief Applications with most of the data we collect. We collect data and process data when
        you:
        Register online or place an order for any of our Emalsys product.
        Use or view our website via your browser’s cookies.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph3">Data Privacy</h2>
      <p i18n="@@privacyParagraph3Text1">
        Emalsys is a service that allows you to connect data from your emergency responders and managers, locally and/or
        remotely, up to our servers. This data is private to you and your users but there is functionality that allows
        you to share it with other people. Relief Applications does not access this data for any commercial or service
        improvement however we monitor the size and meta data of the data to improve and provide better services to you.
        We may access your data temporarily, with your permission, to assist you in issue resolution or for training
        purposes. Please note that we are not able to view your password or your billing information through this
        access. If you wish to connect data from your local data and cloud based data services you will be asked for
        your user name, login credentials and password for those systems to allow for automated connections.  These
        credentials are stored in our Services for automation purposes and you should create separate and read only
        accounts for such connections.
      </p>
      <p i18n="@@privacyParagraph3Text2">
        Relief Applications would like to make sure you are fully aware of all of your data protection rights. Every
        user is entitled to the following:
      </p>
      <ul>
        <li i18n="@@privacyParagraph3Text2Point1">
          <h4>The right to access</h4> - You have the right to request Relief Applications for copies of your personal
          data. We may charge you a small
          fee for this service.
        </li>
        <li i18n="@@privacyParagraph3Text2Point2">
          <h4>The right to rectification</h4> - You have the right to request that Relief Applications correct any
          information you believe is inaccurate. You
          also have the right to request Relief Applications to complete the information you believe is incomplete.
        </li>
        <li i18n="@@privacyParagraph3Text2Point3">
          <h4>The right to erasure</h4> - You have the right to request that Relief Applications erase your personal
          data, under certain conditions.
        </li>
        <li i18n="@@privacyParagraph3Text2Point4">
          <h4>The right to restrict processing</h4> - You have the right to request that Relief Applications restrict
          the processing of your personal data, under
          certain conditions.
        </li>
        <li i18n="@@privacyParagraph3Text2Point5">
          <h4>The right to object to processing</h4> - You have the right to object to Relief Applications’ processing
          of your personal data, under certain
          conditions.
        </li>
        <li i18n="@@privacyParagraph3Text2Point6">
          <h4>The right to data portability</h4> - have the right to request that Relief Applications transfer the data
          that we have collected to another
          organization, or directly to you, under certain conditions.
        </li>

      </ul>
      <p i18n="@@privacyParagraph3Text3">If you make a request, we have one month to respond to you. If you would like
        to exercise any of these rights, please contact us at our email: contact@reliefapplications.org
      </p><br>
      <p>
        Call us at: +33 695 397797
        Or write to us: Relief Applications RCS 804995207 Toulouse
        10 rue Guy de Maupassant, 31200 Toulouse, France <br><br>
        VAT: FR 10804995207
        CNIL: 144143111
      </p>
    </div>



    <div>
      <h2 i18n="@@privacyParagraph5">Cookies</h2>
      <p i18n="@@privacyParagraph5Text1">
        A cookie is a small piece of data that a website asks your browser to store on your computer or mobile device.
        The cookie allows the website to "remember" your actions or preferences over time. Most browsers support
        cookies, but users can set their browsers to decline them and can delete them whenever they like. We use cookies
        to do things like:
      </p>
      <ul>
        <li i18n="@@privacyParagraph5Text1Point1">
          log you in to our services
        </li>
        <li i18n="@@privacyParagraph5Text1Point2">
          remember preferences and settings
        </li>
        <li i18n="@@privacyParagraph5Text1Point3">
          keep your account secure
        </li>
        <li i18n="@@privacyParagraph5Text1Point4">
          <a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a>
        </li>
        <li i18n="@@privacyParagraph5Text1Point5">
          better understand how people are using our services, and improve and promote them based on that information
        </li>
      </ul>
      <p i18n="@@privacyParagraph5Text2">
        Relief Applications may leave a cookie on your browser when you post a comment, in order to remember your
        credentials (your name, email address and URL) and certain web statistics tool such as Google Analytics can also
        leave cookies if you accept them (please refer to your browser settings). We use these cookies to facilitate
        login or to identify improper comments, actions or unauthorized access and to monitor our marketing investment.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph4">Browser Settings</h2>
      <p i18n="@@privacyParagraph4Text1">
        Most browsers allow you to delete cookies and prevent them from being set again in the future. Note that some of
        our features may not work correctly without cookies.
      </p>
    </div>


    <div>
      <h2>Manage your preferences</h2>
      <p>Most web browsers allow you to manage your cookie preferences. You can set your browser to refuse cookies, or
        delete certain cookies. The following links show how to adjust the browser settings of commonly used browsers:
      </p>
      <ul>
        <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies">Firefox</a></li>
        <li><a href="https://support.apple.com/kb/index?page=search&fac=all&q=cookies%20safari">Safari</a></li>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
      </ul>
      <p>Please note that if you choose to block cookies, this may impair or prevent due functioning of the Emalsys Service.</p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph6">EU legislation on cookies</h2>
      <p i18n="@@privacyParagraph6Text1">
        This website follows parts of the European Commission's guidelines on <a href="#">privacy and data
          protection</a> and inform users that cookies are not being used to gather information unnecessarily.
      </p>
      <p i18n="@@privacyParagraph6Text2">
        The <a href="http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=CELEX:32002L0058:EN:HTML">ePrivacy
          directive</a> – more specifically Article 5(3) – requires prior informed consent for storage ofor access to
        information stored on a user's terminal equipment. In other words, you must ask users if they agreeto most
        cookies and similar technologies (e.g. web beacons, Flash cookies, etc.) before the site starts to use them.
      </p>
      <p i18n="@@privacyParagraph6Text3">
        For consent to be valid, it must be informed, specific, freely givenand must constitute a real indication of the
        individual's wishes.
      </p>
      <p i18n="@@privacyParagraph6Text4">
        However, some cookies are exempt from this requirement. Consent is not required if the cookie is:
      </p>
      <ul>
        <li i18n="@@privacyParagraph6Text4Point1">
          used for the sole purpose of carrying out the transmission of a communication, and
        </li>
        <li i18n="@@privacyParagraph6Text4Point2">
          strictly necessary in order for the provider of an information society service explicitly required by the user
          to provide that service.
        </li>
      </ul>
      <p i18n="@@privacyParagraph6Text5">
        Cookies clearly exempt from consent according to the <a
          href="http://ec.europa.eu/justice/data-protection/article-29/documentation/opinion-recommendation/files/2012/wp194_en.pdf">EU
          advisory body on data protection- WP29</a> include:
      </p>
      <ul>
        <li i18n="@@privacyParagraph6Text5Point1">
          <span class="bold-text">user‑input</span> cookies (session-id) such as first‑party cookies to keep track of
          the user's input when filling online forms, shopping carts, etc., for the duration of a session or persistent
          cookies limited to a few hours in some cases
        </li>
        <li i18n="@@privacyParagraph6Text5Point2">
          <span class="bold-text">authentication cookies</span>, to identify the user once he has logged in, for the
          duration of a session
        </li>
        <li i18n="@@privacyParagraph6Text5Point3">
          <span class="bold-text">user‑centric security</span> cookies, used to detect authentication abuses, for a
          limited persistent duration
        </li>
        <li i18n="@@privacyParagraph6Text5Point4">
          <span class="bold-text">multimedia content player</span> cookies, used to store technical data to play back
          video or audio content, for the duration of a session
        </li>
        <li i18n="@@privacyParagraph6Text5Point5">
          <span class="bold-text">load‑balancing</span> cookies, for the duration of session
        </li>
        <li i18n="@@privacyParagraph6Text5Point6">
          <span class="bold-text">user‑interface customisation</span> cookies such as language or font preferences, for
          the duration of a session (or slightly longer)
        </li>
        <li i18n="@@privacyParagraph6Text5Point7">
          <span class="bold-text">third‑party social plug‑in content‑sharing</span> cookies, for logged‑in members of a
          social network.
        </li>
      </ul>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph7">LOGS AND SESSIONS TRACKING</h2>
      <p i18n="@@privacyParagraph7text1">
        Relief Applications tracks usage of Service by means of IP addresses and session IDs to improve their services
        and assist customers with issues as well as present information using local language and cultural formats. We
        use these log entries to identify improper comments, actions or unauthorized access, identify issues or improve
        our services.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph8">PUBLICATIONS OF COMMENTS</h2>
      <p i18n="@@privacyParagraph8Text1">
        By posting a comment on this site, you agree to allow the editor: 1. To copy, distribute and communicate your
        comments to the public, free of charge, for the legal protection duration of the operating right under the law,
        without limitation of place neither support, 2. To remove all or part of your comment, without special
        justification to provide you, 3. To store your login information in its database without time limits.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph9">READING AND REVIEW</h2>
      <p i18n="@@privacyParagraph9Text1">
        Consultation of Relief Applications material web pages, social pages and channels and this website is free. If
        the reader does not agree with what is said, the reader is free to unsubscribe or leave the blog or channel at
        any time.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph10">CHANGES</h2>
      <p i18n="@@privacyParagraph10Text1">
        If we are involved in a reorganization, merger, acquisition or sale of our assets, your information may be
        transferred as part of that deal. We may revise this Privacy Policy from time to time, and will post the most
        current version on our website. If a revision meaningfully reduces your rights, we will notify you.
      </p>
    </div>

    <div>
      <h2 i18n="@@privacyParagraph11">QUESTIONS AND CONTACT</h2>
      <p i18n="@@privacyParagraph11Text1">
        If you have questions or concerns about our Services and/or this policy, please send us an email at
        contact@reliefapplications.org
      </p>

      <p class="contact-details">
        Relief Applications
      </p>
      <p class="contact-details">
        Relief Applications RCS 804995207 Toulouse
      </p>
      <p class="contact-details">
        10 rue Guy de Maupassant, 31200 Toulouse, France
      </p>
      <p class="contact-details">
        VAT: FR 10804995207
      </p>
      <p class="contact-details">
        CNIL: 144143111
      </p>
      <p class="contact-details">
        Tel: not available
      </p>
    </div>

  </div>

</div>