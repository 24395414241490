import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
//Routing
import { UserGuideRouting } from './user-guide.routing';
// Components
import { UserGuideComponent } from './user-guide.component';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    NgbModule,
    UserGuideRouting
  ],
  declarations: [
    UserGuideComponent
  ],
  providers: []
})


export class UserGuideModule {}
