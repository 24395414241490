/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-news.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-news-map/dashboard-news-map.component.ngfactory";
import * as i3 from "./dashboard-news-map/dashboard-news-map.component";
import * as i4 from "../../../../../services/external/leaflet.service";
import * as i5 from "./dashboard-news.component";
import * as i6 from "../../../../../services/api/organisation/organisation.api";
import * as i7 from "../../../../../services/api/alert/alert.api";
import * as i8 from "../../../../../services/external/disasters.service";
import * as i9 from "../../../../../services/utils/logger.service";
import * as i10 from "../../../../../services/api/alert/alert.dispatcher";
var styles_DashboardNewsComponent = [i0.styles];
var RenderType_DashboardNewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardNewsComponent, data: {} });
export { RenderType_DashboardNewsComponent as RenderType_DashboardNewsComponent };
export function View_DashboardNewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "dashboard-news-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dashboard-news-map", [["class", "dashboard-news-component-map"]], null, null, null, i2.View_DashboardNewsMapComponent_0, i2.RenderType_DashboardNewsMapComponent)), i1.ɵdid(2, 638976, null, 0, i3.DashboardNewsMapComponent, [i4.LeafletService], { alerts: [0, "alerts"], alertsUser: [1, "alertsUser"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; var currVal_1 = _co.alertsUser; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DashboardNewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-news", [], null, null, null, View_DashboardNewsComponent_0, RenderType_DashboardNewsComponent)), i1.ɵdid(1, 573440, null, 0, i5.DashboardNewsComponent, [i6.OrganisationApiService, i7.AlertService, i8.DisasterService, i9.LoggerService, i10.AlertDispatcherService], null, null)], null, null); }
var DashboardNewsComponentNgFactory = i1.ɵccf("dashboard-news", i5.DashboardNewsComponent, View_DashboardNewsComponent_Host_0, { organisation: "organisation" }, {}, []);
export { DashboardNewsComponentNgFactory as DashboardNewsComponentNgFactory };
