import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject               } from 'rxjs';

// Constants
import { URL_API_RELIEF_WEB } from '../../app/app.constants';


@Injectable()


export class DisasterService {

  private hoveredSource = new Subject<number>();               // Source of the stream
  private hovered       = this.hoveredSource.asObservable();   // Stream for hovered

  constructor(
    public http: HttpClient,
  ) {}

  // ------------------------------------------------------------------------//
  // -------------------------- GETTERS / SETTERS ---------------------------//
  // ------------------------------------------------------------------------//

  getHovered() {
    return this.hovered;
  }

  setHovered(hovered) {
    this.hoveredSource.next(hovered);
  }

  // ------------------------------------------------------------------------//
  // ------------------------------ DISASTERS -------------------------------//
  // ------------------------------------------------------------------------//

  getDisasters(offset: number, limit: string, status: string[]) {
    let params = new HttpParams();

    // Preferences
    params.append('limit' , limit);
    params.append('offset', offset.toString());
    params.append("preset", "latest");
    if (status) {
      for (let i = 0; i < status.length; i++) {
        params.append('filter[conditions][1][field]'  , "status");
        params.append('filter[conditions][1][value][]', status[i]);
      }
      params.append('filter[conditions][1][operator]', "OR");
    }

    // Include fields
    params.append("fields[include][]", "date.created");
    params.append("fields[include][]", "description");
    params.append("fields[include][]", "description-html");
    params.append("fields[include][]", "primary_country.name");
    params.append("fields[include][]", "primary_country.shortname");
    params.append("fields[include][]", "primary_country.iso3");
    params.append("fields[include][]", "primary_type.code");
    params.append("fields[include][]", "primary_type.id");
    params.append("fields[include][]", "primary_type.name");
    params.append("fields[include][]", "status");
    params.append("fields[include][]", "url");

    return this.http.get<any>( URL_API_RELIEF_WEB + "/disasters", {params: params});
  }

  getDesastreById(desastreId) {
		let params = new HttpParams();

		// Preferences
		params.append('filter[conditions][0][field]'  , "id");
		params.append('filter[conditions][0][value][]', desastreId.toString());

		// Include fields
		params.append("fields[include][]", "name");
		params.append("fields[include][]", "description-html");
		params.append("fields[include][]", "date.created");
		params.append("fields[include][]", "url_alias");
		params.append("fields[include][]", "status");
		params.append("fields[include][]", "primary_type.name");
		params.append("fields[include][]", "primary_type.code");
		params.append("fields[include][]", "primary_country.iso3");
		params.append("fields[include][]", "primary_country.name");
		params.append("fields[include][]", "primary_country.shortname");
		params.append("fields[include][]", "country");

  	return this.http.get<any>( URL_API_RELIEF_WEB + "/disasters", {params: params});
	}


  getDisasterNews(iso3s, number, offset, type, themeId) {
    let params = new HttpParams();

    // Preferences
    params.append('limit', number.toString());
    params.append('offset', offset.toString());
    params.append("sort[]", "date:desc");
    params.append('filter[operator]', "AND");

    if (iso3s) {                          // For multiply ISO3
      for (let i = 0 ; i < iso3s.length ; i++) {
        params.append('filter[conditions][0][field]',   "primary_country.iso3");
        params.append('filter[conditions][0][value][]', iso3s[i]);
      }
      params.append('filter[conditions][0][operator]', "OR");
    }
    if (type === "ultimo") {            // Latest
      params.append('filter[conditions][1][field]',   "format");
    }else if (type === "sitreps") {      // Situation Report
      params.append('filter[conditions][1][field]',   "format.id");
      params.append("filter[conditions][1][value][]", "10");
    }else if (type === "mapas") {        // Map
      params.append('filter[conditions][1][field]',   "format.id");
      params.append("filter[conditions][1][value][]", "12");
    }else if (type === "noticias") {     // News and Press Release
      params.append('filter[conditions][1][field]',   "format.id");
      params.append("filter[conditions][1][value][]", "8");
    }else if (type === "documentos") {   // Documents
      params.append('filter[conditions][1][field]',   "format.id");
    }else if (type === "formacion") {    // Manual and Guidelines
      params.append('filter[conditions][1][field]',   "format.id");
      params.append("filter[conditions][1][value][]", "7");
    }
    if (themeId) {   // For the sectores
      params.append('filter[conditions][2][field]', "theme.id");
      params.append('filter[conditions][2][value][]', themeId.toString());
    }

    // Include fields
    params.append("fields[include][]", "title");
    params.append("fields[include][]", "country");
    params.append("fields[include][]", "source");
    params.append("fields[include][]", "date.created");

    return this.http.get<any>(URL_API_RELIEF_WEB + "/reports" + "?appname=emalsys", {params: params});
  }


}
