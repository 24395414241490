/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-reset-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./profile-reset-password.component";
import * as i3 from "../../../../../services/api/settings-service";
import * as i4 from "../../../../../services/utils/logger.service";
import * as i5 from "../../../../../services/utils/navigation.service";
import * as i6 from "../../../../../services/security/login.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_ProfileResetPasswordComponent = [i0.styles];
var RenderType_ProfileResetPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileResetPasswordComponent, data: {} });
export { RenderType_ProfileResetPasswordComponent as RenderType_ProfileResetPasswordComponent };
export function View_ProfileResetPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "settings-reset-password-component shadow margin-b text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "margin-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change password"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn emalsys_button yellow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChangePasswordDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Change Password"]))], null, null); }
export function View_ProfileResetPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preloader-profile-reset-password", [], null, null, null, View_ProfileResetPasswordComponent_0, RenderType_ProfileResetPasswordComponent)), i1.ɵdid(1, 49152, null, 0, i2.ProfileResetPasswordComponent, [i3.SettingsService, i4.LoggerService, i5.NavigationService, i6.LoginService, i7.NgbModal], null, null)], null, null); }
var ProfileResetPasswordComponentNgFactory = i1.ɵccf("preloader-profile-reset-password", i2.ProfileResetPasswordComponent, View_ProfileResetPasswordComponent_Host_0, {}, {}, []);
export { ProfileResetPasswordComponentNgFactory as ProfileResetPasswordComponentNgFactory };
