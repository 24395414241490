import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CachedOrganisationService } from '../../../../../services/api/organisation/organisation.cacher';
import { RosterMembershipService } from '../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { RosterService } from '../../../../../services/api/roster/roster.cached';
import { RostersDispatcherService } from '../../../../../services/api/roster/rosters.dispatcher';
import { TagsService } from "../../../../../services/api/tag/tag.api";
import { UserService } from '../../../../../services/api/user.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
// import { forEach } from '@angular/router/src/utils/collection';
import { RightService } from '../../../../../services/utils/right.service';
import { ToastService } from '../../../../../services/utils/toast.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../app.constants';
import { RosterInterface } from '../../../../model/roster.interface';
import { OrganisationMembershipService } from './../../../../../services/api/organisation/dependencies/membership/organisation-membership.api';

@Component({
  selector: 'dashboard-members-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() rosterId;
  @Input() profile;
  @Input() members: any;
  @Input() organisation: any;
  @Input() selected = {};
  @Input() tagsMode: boolean;
  @Input() tagsList: any[];
  @Input() selectedTags: any[];

  @Output() onSelected = new EventEmitter<number>();
  @Output() onManagerAction = new EventEmitter<any>();

  // Provisory, needs external service
  // @Output() roleChange = new EventEmitter<string>();
  // @Output() onProfileShow = new EventEmitter<number>();

  public show: number = null;

  public currentRoster: RosterInterface;

  constructor(
    private _rightService: RightService,
    private _userService: UserService,
    private _loggerService: LoggerService,
    private _organisationMembership: OrganisationMembershipService,
    private _rosterService: RosterService,
    private _tagsService: TagsService,
    private _toastService: ToastService,
    private _cachedRosterService: RosterService,
    private _rosterDispatcher: RostersDispatcherService,
    private _cachedOrganisationService: CachedOrganisationService,
    private _rosterMembership: RosterMembershipService
  ) { }

  ngOnInit() {

    document.getElementsByTagName('body')[0].addEventListener('click', x => {
      if ((<HTMLElement>x.target).className !== 'member-dropdown-button') {
        this.show = null;
      }
    });

    this._rosterDispatcher.getSelectedRoster().subscribe(
      (res: RosterInterface) => this.currentRoster = res
    );

  }

  canManageRights(member) {
    let tagsMode = this.tagsMode;
    let id = this.rosterId;
    let organisationAssociate = this.checkOrganisationAssociate();
    let rosterGuest = this.checkRosterGuest();
    let rosterMember = this.isActiveRosterMember(member) === 1;
    return !tagsMode &&
      (
        (this.rosterId === RosterInterface.NO_ROSTER && organisationAssociate) ||
        (this.rosterId !== RosterInterface.NO_ROSTER && rosterGuest && rosterMember)
      );
  }

  isRosterSelected() {
    return this.rosterId !== RosterInterface.NO_ROSTER;
  }

  // IsOrganisation...?
  isNotOrganisationManager(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  isOrganisationAssociate(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  isOrganisationAdmin(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Admin']);
  }

  isOrganisationSuperAdmin(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['SuperAdmin']);
  }
  // IsRoster...?
  isNotRosterManager(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  isRosterGuest(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  isRosterRightManager(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Manager']);
  }

  isRosterSupervisor(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Supervisor']);
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  checkRosterGuest() {
    return this._rightService.checkRosterManagerRight(this.profile, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);

  }

  checkIsOwner(member) {
    return this.profile.id === member.id;
  }

  checkboxChange(id) {
    this.onSelected.emit(id);
  }

  showDropdown(id) {
    if (this.show === id) {
      this.show = null;
    } else {
      this.show = id;
    }
  }

  isMemberAvailable(endUnavailabality) {
    return new Date(endUnavailabality).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  isMemberTagged(member, tagId) {
    if (member.rosterTags) {
      let res = false;
      let stop = false;
      for (let i = 0; i < member.rosterTags.length && !stop; i++) {
        if (member.rosterTags[i].roster_tag.id === tagId) {
          res = true;
          stop = true;
        }
      }
      return res;
    }
  }

  openProfile(id) {
    // this._loggerService.log(this.members)
    this._userService.openProfile(id, this.organisation.id, this.rosterId);
  }

  freezeOrganisationMember(userId) {
    if (this.selected[userId]) {
      this.checkboxChange(userId);
    }
    this._organisationMembership.freezeOrganisationMember(this.organisation.id, userId).subscribe(res => {
      this._loggerService.log("organisation member froze !!!", res);
      this._cachedOrganisationService.refreshOrganisationMembers(this.organisation.id);
      this.onManagerAction.emit(res);
    });
  }

  activeOrganisationMember(userId) {
    this._organisationMembership.activateOrganisationMember(this.organisation.id, userId).subscribe(res => {
      this._loggerService.log("organisation member activated !!!", res);
      this._cachedOrganisationService.refreshOrganisationMembers(this.organisation.id);
      this.onManagerAction.emit(res);
    });
  }

  isActiveOrganisationMember(member) {
    let state = 0;
    member.organisation_members.forEach((organisationMember) => {
      if (organisationMember.organisation.id === this.organisation.id) {
        state = organisationMember.state;
      }
    });
    return state;
  }

  isActiveRosterMember(member) {
    let state = 0;
    member.roster_members.forEach((rosterMember) => {
      if (rosterMember.roster.id === this.rosterId) {
        state = rosterMember.state;
      }
    });
    return state;
  }

  removeFromOrganisation(member) {

    if (!this.isNotOrganisationManager(member)) {
      let context = "remove";
      this._organisationMembership.deleteOrganisationManager(this.organisation.id, member.id, context).subscribe(resbis => {
        this._loggerService.log("organisation Manager removed", resbis);
        this.onManagerAction.emit(resbis);
      });
    } else {
      this._organisationMembership.deleteOrganisationMember(this.organisation.id, member.id).subscribe(res => {
        this._loggerService.log("organisation Member removed", res);
        this.onManagerAction.emit(res);
      });
    }
  }

  demoteToOrganisationMember(userId) {
    let context = "demote";
    this.showLoadingToast();
    this._organisationMembership.deleteOrganisationManager(this.organisation.id, userId, context).subscribe(res => {
      this.showRoleChangedToast();

      this._loggerService.log("organisation member Demoted !!!", res);
      this.onManagerAction.emit(res);
      this.refreshOrganisationMembers();
    });
  }

  demoteToRosterMember(userId) {
    this.showLoadingToast();
    this._rosterMembership.removeRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();

      this._loggerService.log("roster member Demoted !!!", res);
      this.onManagerAction.emit(res);
      this.refreshRosterMembers();
    });
  }

  refreshRosterMembers() {
    this._rosterMembership.refreshRosterMembers(this.currentRoster.id);
  }

  refreshOrganisationMembers() {
    this._cachedOrganisationService.refreshOrganisationMembers(this.organisation.id);
  }

  removeRosterMember(member) {
    this._rosterMembership.removeRosterMember(this.rosterId, member.id).subscribe(res => {
      if (!this.isNotRosterManager(member)) {
        this._rosterMembership.removeRosterManager(this.rosterId, member.id).subscribe(resbis => {
          this.showRoleChangedToast();

          this._loggerService.log("roster Manager removed", resbis);
          this.onManagerAction.emit(resbis);
          this.refreshRosterMembers();
        });
      } else {
        this._loggerService.log("roster Member removed", res);
        this.onManagerAction.emit(res);
        this.refreshRosterMembers();
      }

    });
  }

  promoteToOrganisationManager(userId, userEmail) {
    let params = {
      emails: [],
      rights: 1
    };
    this.showLoadingToast();
    params.emails.push(userEmail);
    this._organisationMembership.addManagers(this.organisation.id, params).subscribe(add => {
      this._organisationMembership.setActiveOrganisationManager(this.organisation.id, userId).subscribe(res => {
        this.showRoleChangedToast();
        this._loggerService.log("Promoted organisation manager!!!", res);
        this.onManagerAction.emit(res);
        this.refreshOrganisationMembers();
      });
    });
  }

  promoteToRosterManager(userId, userEmail) {
    let params = {
      emails: [],
      rights: 1
    };
    this.showLoadingToast();
    params.emails.push(userEmail);
    this._rosterMembership.inviteManagers(this.rosterId, params).subscribe(add => {
      this._rosterMembership.setActiveRosterManager(this.rosterId, userId).subscribe(res => {
        this.showRoleChangedToast();
        this._loggerService.log("Promoted roster manager!!!", res);
        this.onManagerAction.emit(res);
    this.refreshRosterMembers();
      });
    });
  }

  changeToAssociate(userId) {
    this.showLoadingToast();
    this._organisationMembership.setAssociateRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Associate!!!", res);
      this.onManagerAction.emit(res);
      this.refreshOrganisationMembers();
    });
  }

  changeToAdmin(userId) {
    this.showLoadingToast();
    this._organisationMembership.setManagerRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Admin!!!", res);
      this.onManagerAction.emit(res);
      this.refreshOrganisationMembers();
    });
  }
  changeToSuperAdmin(userId) {
    this.showLoadingToast();
    this._organisationMembership.setAdminRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Super admin!!!", res);
      this.onManagerAction.emit(res);
      this.refreshOrganisationMembers();
    });
  }


  changeToGuest(userId) {
    this.showLoadingToast();
    this._rosterMembership.setGuestRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Guest!!!", res);
      this.refreshRosterMembers();
      // this.onManagerAction.emit(res);
    });
  }

  changeToManager(userId) {
    this.showLoadingToast();
    this._rosterMembership.setManagerRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Manager!!!", res);
      this.onManagerAction.emit(res);
      this.refreshRosterMembers();
    });
  }
  changeToSupervisor(userId) {
    this.showLoadingToast();
    this._rosterMembership.setSupervisorRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Supervisor!!!", res);
      this.onManagerAction.emit(res);
      this.refreshRosterMembers();
    });
  }

  // Provisory code, needs external service
  showLoadingToast() {
    this._toastService.show("loading-member-promotion", "show");
  }

  showRoleChangedToast() {
    this._toastService.show("role-changed", "show");
  }

}
