<div class="preloader-profile-general shadow">
  <div class="card-nav">
    <div class="card-nav-item">
      <h2>GENERAL INFORMATION</h2>
    </div>
    <div class="card-nav-item">
      <h2>DEPLOYMENTS</h2>
    </div>
  </div>
  <div class="info">

      <div class="preloader-profile-general-label">
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
        <div class="preloader-profile-general-big-line"></div>
      </div>
      <div class="preloader-profile-general-value">
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
        <div class="preloader-profile-general-small-line"></div>
      </div>

  </div>
</div>
