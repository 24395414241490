import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { Select2OptionData } from 'ng2-select2';

import { LATLONG } from '../../../../../../../data/countries';
import { AlertService } from '../../../../../../../services/api/alert/alert.api';
import { LoggerService } from '../../../../../../../services/utils/logger.service';
import { AlertInterface } from '../../../../../../model/alert.interface';
import { EventListenerService } from '../../../../../../../services/utils/event-listener.service';
import { ToastService } from "../../../../../../../services/utils/toast.service";
@Component({
  selector: 'app-edit-alert-modal',
  templateUrl: './edit-alert-modal.component.html',
  styleUrls: ['./edit-alert-modal.component.scss']
})
export class EditAlertModalComponent implements OnInit {
  public countries: any[];
  public moreOption = false;
  public alert: AlertInterface = new AlertInterface();
  public loading = false;

  @Output() onClickSave = new EventEmitter<any>();
  @Output() onClickDelete = new EventEmitter<any>();
  @Output() onClickCreate = new EventEmitter<any>();

  @Input() alertInfo;
  @Input() context;
  @Input() organisation;

  constructor(
    public activeModal: NgbActiveModal,
    public _alertService: AlertService,
    public _loggerService: LoggerService,
    public _toastService: ToastService,
    private _eventListenerService: EventListenerService,
  ) {
    _eventListenerService.event$.subscribe(event => {
      if (event.hasOwnProperty('event')) {
        this.loading = false;
        this.activeModal.close();
      }
    });
  }

  ngOnInit() {
    this.getCountries();
    this.alert.name = this.alertInfo.name;
    this.alert.country = this.alertInfo.country;
    if (this.alertInfo.start_date && this.alertInfo.end_date) {
      this.alert.startDate = new Date(this.alertInfo.start_date);
      this.alert.endDate = new Date(this.alertInfo.end_date);
    } else {
      this.alert.startDate = null;
      this.alert.endDate = null;
    }
    this.alert.secondaryCountries = this.alertInfo.secondary_countries;
    this.alert.description = this.alertInfo.description;
    this.alert.infoLink = this.alertInfo.info_link;

  }

  getCountries() {
    this.countries = LATLONG.map(country => {
      return {
        id: country.country_code,
        text: country.name
      };
    });
  }

  deleteAlert() {
    this.loading = true;
    this.onClickDelete.emit(this.alertInfo.id);
  }

  updateAlert() {
    this.loading = true;
    if (this.alert.description === null) {
      this.alert.description = "";
    }
    if (this.alert.description.length <= 10) {
      this._toastService.show('descriptionTooShortToast', "show", 3000);
      this.loading = false;
    } else {
      // alertInfo can't be the full alert due to
      // some request constraints on the backend.
      // therefore, we need the full alert on
      // alert-informations to update the map
      let body = {
        id: this.alertInfo.id,
        alertInfo: this.alert,
        fullAlert: this.alertInfo
      };
      this.onClickSave.emit(body);
    }
  }

  createAlert() {
    this.loading = true;
    this.alert.organisation = this.organisation.id;
    this.onClickCreate.emit(this.alert);
  }

  changedSelectSecondaryCountries(data) {
    this.alert.secondaryCountries = data.value;
  }

  checkInputs(alert) {
    return !(alert.name && alert.country && alert.startDate && alert.endDate);
  }

  changedSelectCountry(data) {
    this.alert.country = data.value;
  }

}
