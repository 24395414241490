<!--Reset password component-->
      <div class="modal-header">
          <h1>Change password</h1>
      </div>
      
      <div class="modal-body">

        <!--Form group-->
        <form class="form-group">

          <!--Current password input-->
          <div class="margin-b">
            <input id="currentPassword"
                  class="form-control"
                  type="password"
                  name="currentPassword"
                  required
                  [(ngModel)]="currentPassword"
                  placeholder="Enter current password..."
                  #currentPasswordValue="ngModel">
                  <!--Clickable icon to display the current password-->
            <i class="fa fa-eye eye-icon custom-icon-pos1" (click)="viewCurrentPass()"></i>
          </div>

          <!--New password input-->
          <div class="margin-b">
            <input id="newPassword"
                  class="form-control"
                  type="password"
                  name="newPassword"
                  required
                  [(ngModel)]="newPassword"
                  placeholder="Enter new password..."
                  pattern="^[a-zA-Z0-9]{6,30}$"
                  minlength="5"
                  #newPasswordValue="ngModel">
                  <!--Clickable icon to display the new password-->
            <i class="fa fa-eye eye-icon custom-icon-pos2" (click)="viewNewPass()"></i>
          </div>

          <!-- Repeat new password input -->
          <div class="margin-b">
            <input id="newPassword2" class="form-control" type="password" name="confirmNewPassword" required [(ngModel)]="newPassword2"
              placeholder="Confirm new password..." pattern="^[a-zA-Z0-9]{6,30}$" minlength="5" #newPasswordValue="ngModel">
            <!--Clickable icon to display the new password-->
            <i class="fa fa-eye eye-icon custom-icon-pos3" (click)="viewNewPass2()"></i>
          </div>

          <!--Toast -->
          <div *ngIf="stateMessage" [class]="stateMessage.classType">
            {{stateMessage.message}}
          </div>
          
        </form>
      </div>

      <div class="modal-footer">
        <div *ngIf="isLoading" class="loader">is loading...</div>
        <button class="btn emalsys_button red" (click)="cancel()">Cancel</button>
        <button class="btn emalsys_button green" [disabled]="isLoading" (click)="passwordCheck()">Change Password</button>
      </div>  <!-- modal-footer -->

