<app-banner></app-banner>

<div id="administration-page">
  This is the administration page in progress...

  <div id="page-content">
    <router-outlet></router-outlet>
  </div>

  <!--Menu to access to the form-job-->
  <div class="administration-menu">
    <ul>
      <li>
        <a routerLink='organisations'>Organisations</a>
      </li>
      <li>
        <a routerLink='rosters'>Rosters</a>
      </li>
      <li>
        <a routerLink='users'>Users</a>
      </li>
      <li>
        <a routerLink='authentication'>Authentications</a>
      </li>
      <li>
        <a routerLink='rights'>Rights</a>
      </li>
      <li>
        <a routerLink='banner'>Banner</a>
      </li>
      <li>
        <a routerLink='newsletters'>Newsletters</a>
      </li>
      <li>
        <a routerLink='alerts'>Alerts</a>
      </li>
      <li>
        <a routerLink='skills'>Skills</a>
      </li>
      <li class="display-submenu">
        Features
        <div class="submenu-content">
          <ul>
            <li>
              <a routerLink='features-users'>For Users</a>
            </li>
            <li>
              <a routerLink='features-managers'>For Managers</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a routerLink='documentation'>Documentation</a>
      </li>
    </ul>
  </div>

</div>
