<div id="profile-tags-component" class="shadow">
  <div class="emalsys_section-title">
    <h2 i18n="@@tagsTitle" class="margin-b">Tags</h2>
  </div>

  <div *ngIf="tagsList && tagsList.length > 0; else noTags" class="add-button" (click)="openTagModal(profileTagsModal);">Show tags</div>
  <ng-template #noTags>
    <div class="no-tags-container">
      <p>There are no tags to display</p>
    </div>
  </ng-template>

</div>

<!-- The modal is within the template because:
  1. It's not used elsewhere
  2. It has no controls. It just displays the tags -->
<ng-template #profileTagsModal let-c="close">

  <!-- HEADER -->
  <div class="modal-header">
    <h4 class="modal-title">Tags</h4>
    <button class="close" aria-label="Close" (click)="c();">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>

  <!-- BODY -->
  <div class="modal-body">
    <!-- Content wrapper (padding here) -->
    <div class="modal-general">
    <p>Tags can only be assigned by managers</p>
      <!-- Tag list -->
      <div class="tag-list">
        <!-- <div class="for-container" *ngFor="let organisation of organisations">
          <div class="for-container" *ngFor="let roster of organisation.rosters">
            <div class="tag-container" *ngFor="let tag of roster.tags">
              {{tag.name}}
            </div>
          </div>
        </div> -->
        <div class="tag-button" *ngFor="let tag of tagsList">
          {{ tag.name }}
        </div>
      </div>
    </div>

    <div class="spinner" *ngIf="loading">
      <spinner-component></spinner-component>
    </div>
  </div>
</ng-template>