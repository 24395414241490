import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CacheService } from '../storage/cache.service';
import { environment } from '../../environments/environment';

/**
 * LoggerService
 * this class is done for replacing the usual 'console.' .
 * Like that we can control what is shown on the console following the debugstate
 */
@Injectable()
export class LoggerService {

    private debugState: boolean;

    constructor(private cacheService: CacheService) {
        let tmp = this.cacheService.get(CacheService.DEBUG_STATE);
        let debugState = environment.production === undefined ? tmp : environment.production;

        this.setDebugState(debugState);
    }

    setDebugState(debug: boolean) {
        this.debugState = debug;
        this.cacheService.set(CacheService.DEBUG_STATE, debug);
    }

    getDebugState() {
        return this.debugState;
    }

    /**
     * Executes the callback if the debugState is true
     * @param  {[type]}   args     [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    doIf(args: any[], callback: Function, caller?: string) {
        if (this.debugState) {
            for (let arg in args) {
                if (arg) {
                    callback(args[arg]);
                }
            }
        }
    }

    log(...args: any[]) {
        this.doIf(args, console.log);
    }

    error(...args: any[]) {
        this.doIf(args, console.error);
    }

    warn(...args: any[]) {
        this.doIf(args, console.warn);
    }

    debug(...args: any[]) {
        this.doIf(args, console.debug);
    }

    // You can add other functions from console object here

}
