import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
// Services
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ToastService } from '../../../../../services/utils/toast.service';
import { LoggerService } from '../../../../../services/utils/logger.service';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';



@Component({
  selector: 'profile-available',
  templateUrl: './profile-available.component.html',
  styleUrls: ['./profile-available.component.scss']
})


export class ProfileAvailableComponent implements OnInit {

  public profile: any;
  public isAvailable: boolean;
  public endAvailabilityDate = new Date();             // disability period
  public today = new Date();
  public newStatus: string;        // used to check which toast to show after status update
  public loading = false;
  public modalRef;
  constructor(
    private _profileService: ProfileService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _loggerService: LoggerService,
    public datepipe: DatePipe,
    private _profileDispatcher: ProfileDispatcherService
  ) {
  }

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    this._profileDispatcher.getProfile().subscribe(res => {
      this.profile = res;
      this._loggerService.log("PROFILE : ", res);
      this.getAvailableStatus();
      // check if we need to show a toast
      if (this.newStatus) {
        if (this.newStatus === 'available') {
          this.showToastEnable();
        } else {
          this.showToastDisable();
        }
      }
    });
  }

  /**
  * Function to get if the user is available based on the date,
  * when the user's unavailability ends ('end_unavailability' profile field)
  **/
  getAvailableStatus() {
    let endDay = new Date(this.profile.end_unavailability);
    this.today.setHours(0, 0, 0, 0);
    endDay.setHours(0, 0, 0, 0);
    let isToday = (this.today.toDateString() === endDay.toDateString());
    // user is available if end_unavailability date is before today or today
    let shouldBeAvailable = endDay <= this.today;
    this.isAvailable = shouldBeAvailable;
    if (shouldBeAvailable) {
      this.endAvailabilityDate = this.today;
    } else {
      this.endAvailabilityDate = endDay;
    }
  }

  showChangeAvailability(content) {
    if (this.isAvailable) {
      this.isAvailable = true;
      let todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      let today = this.datepipe.transform(todayDate.setMilliseconds(todayDate.getMilliseconds() - 1), 'MM/dd/yyyy, hh:mm:ss a');
      this.newStatus = 'available';
      this.changeAvailability(today);
    } else {
      this.changeDate(content);
    }
  }

  changeDate(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' })
      .result.then((result) => {
        this.confirmDisability();
      }, (reason) => {
        // When the modal is DISMISSED, nothing should happen (except maybe logging)
        // this.getProfile();
        // But the modal is opened when you click on the switch,
        // So we have to restore the state
        // this.isAvailable = true;
        // NOTE: the modal is opened only when you set the UNAVAILABILITY
      });
  }

  confirmDisability() {
    this.loading = true;
    let newEndDate = this.endAvailabilityDate.toLocaleString('en-EN');
    this.newStatus = 'disabled';
    this.changeAvailability(newEndDate);
  }

  changeAvailability(date) {
    this._profileService.changeAvailability(date).subscribe(res => {
      this.loading = false;
    }, err => {
      this.loading = false;
      this._toastService.show("", "show", 3000);
    });
  }

  showToastDisable() {
    this._toastService.show("avalabilityDisableToast", "show", 3000);
  }

  showToastEnable() {
    this._toastService.show("avalabilityEnableToast", "show", 3000);
  }

}
