import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared.module';
//Routing
import { DisasterRouting } from './disaster.routing';
//Components
import { DisasterComponent } from './disaster.component';


@NgModule({
  imports: [
    //Routing
    DisasterRouting,
    //Angular Modules
    CommonModule,
    SharedModule
  ],
  declarations: [
    DisasterComponent
  ],
  providers: []
})


export class DisasterModule {}
