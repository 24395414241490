import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
// Routing
import { WelcomeRouting } from './welcome.routing';
// Components
import { WelcomeComponent              } from './welcome.component';
import { WelcomeTitleComponent         } from './welcome-title/welcome-title.component';
import { WelcomeRostersComponent       } from './welcome-rosters/welcome-rosters.component';
import { WelcomeOrganisationsComponent } from './welcome-organisations/welcome-organisations.component';

@NgModule({
  imports: [
    // Routing
    WelcomeRouting,
    // Angular Modules
    CommonModule,
    NgbModule
  ],
  declarations: [
    WelcomeComponent,
    WelcomeTitleComponent,
    WelcomeRostersComponent,
    WelcomeOrganisationsComponent

  ],
  providers: []
})


export class WelcomeModule { }
