import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';
import { URL_API_EMALSYS } from '../../../environments/environment';
import { TagDispatcherService } from './tag.dispatcher';
import { ApiCacherService } from '../../utils/api-cacher.service';
import { tap } from 'rxjs/operators';
export class TagsService {
    constructor(http, _httpService, _tagDispatcher, _apiCacher) {
        this.http = http;
        this._httpService = _httpService;
        this._tagDispatcher = _tagDispatcher;
        this._apiCacher = _apiCacher;
        this.busy = {
            allTags: {
                value: false
            },
        };
    }
    getAllRosterTags(rosterId) {
        return this._apiCacher.doWrapper(TagsService.ALL_ROSTER_TAGS + rosterId, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags', options);
        }).pipe(tap((res) => {
            this._tagDispatcher.publishRosterTags(res);
        }));
    }
    refreshTags(rosterId) {
        this._apiCacher.removeIfPresent(TagsService.ALL_ROSTER_TAGS + rosterId);
        this.getAllRosterTags(rosterId).subscribe();
    }
    addNewTagToRoster(rosterId, tagName) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags', { 'name': tagName }, options)
            .pipe(tap(() => {
            this.refreshTags(rosterId);
        }));
    }
    deleteTagRoster(tagId, rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + '/rosters/tags/' + tagId, options)
            .pipe(tap(() => {
            this.refreshTags(rosterId);
        }));
    }
    // ========== TODO: move to proper service
    addRosterMembersTags(rosterId, usersId, tags) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags/members', { 'members': usersId, 'tags': tags }, options);
    }
    removeRosterMembersTags(rosterId, usersId, tagsId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/tags/members?members=" + usersId.join() + "&tags=" + tagsId.join(), options);
    }
    getRosterMembersTagsByMember(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags/members?member=' + userId, options);
    }
}
TagsService.ALL_ROSTER_TAGS = 'all_roster_tags_';
