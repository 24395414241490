import { NgModule             } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdministrationComponent              } from './administration.component';
import { AdministrationOrganisationsComponent } from './administration-organisations/administration-organisations.component';
import { AdministrationRostersComponent       } from './administration-rosters/administration-rosters.component';
import { AdministrationUsersComponent         } from './administration-users/administration-users.component';
import { AdministrationAuthenticationComponent} from './administration-authentications/administration-authentications.component';
import { AdministrationRightsComponent        } from './administration-rights/administration-rights.component';
import { AdministrationBannerComponent        } from './administration-banner/administration-banner.component';
import { AdministrationNewslettersComponent   } from './administration-newsletters/administration-newsletters.component';
import { AdministrationAlertsComponent        } from './administration-alerts/administration-alerts.component';
import { AdministrationSkillsComponent        } from './administration-skills/administration-skills.component';
import { AdministrationFeaturesUsers          } from './administration-features-users/administration-features-users.component';
import { AdministrationFeaturesManagers       } from './administration-features-managers/administration-features-managers.component';
import { AdministrationDocumentationComponent } from './administration-documentation/administration-documentation.component';

export const routes: Routes = [
  { path: ''                 , component: AdministrationComponent              },
  { path: 'organisations'    , component: AdministrationOrganisationsComponent },
  { path: 'rosters'          , component: AdministrationRostersComponent       },
  { path: 'users'            , component: AdministrationUsersComponent         },
  { path: 'authentication'   , component: AdministrationAuthenticationComponent},
  { path: 'rights'           , component: AdministrationRightsComponent        },
  { path: 'banner'           , component: AdministrationBannerComponent        },
  { path: 'newsletters'      , component: AdministrationNewslettersComponent   },
  { path: 'alerts'           , component: AdministrationAlertsComponent        },
  { path: 'skills'           , component: AdministrationSkillsComponent        },
  { path: 'features-users'   , component: AdministrationFeaturesUsers          },
  { path: 'features-managers', component: AdministrationFeaturesManagers       },
  { path: 'documentation'    , component: AdministrationDocumentationComponent }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class AdministrationRouting {}
