import { Component } from '@angular/core';

@Component({
  selector : 'terms-page',
  templateUrl : 'terms.component.html',
  styleUrls: ['terms.component.scss']
})

export class TermsComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
