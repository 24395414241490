<!--Modal header-->
<div class="modal-header">
    <h4 *ngIf="tabIdMembersSelected ? tabIdMembersSelected.length === 0 : true" class="modal-title">Manage Tags</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>

<!--Modal body-->
<div *ngIf="tabIdMembersSelected ? tabIdMembersSelected.length === 0 : true" class="modal-body">
  <div class="main-tags-container" *ngIf="!loading">
    <div class="first-container-add-tag">
      <div class="add-tag-content">
          <input type="text" [(ngModel)]="newTagName" name="tagname" placeholder="ADD NEW" maxlength="12">
        </div>
    </div>
    <div class="second-container-tags-list">
      <div class="tag-container" *ngIf="tags.length > 0">
        <div *ngFor="let tag of tags" class="tag-content">
          <div class="container-p">
            <p>{{tag.name}}</p>
          </div>
          <div class="container-cross">
            <i class="fa fa-times" (click)="deleteTagRoster(tag.id)"></i>
          </div>
        </div>
      </div>
      <div class="tag-container" *ngIf="tags.length == 0">
        <p>No tags for this roster, click on 'ADD NEW' to add a new one.</p>
      </div>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
</div>


  <!--Modal footer-->
<div class="modal-footer">
  <button class="btn emalsys_button red" (click)="activeModal.dismiss('Cross click')">Close</button>
  <button *ngIf="tabIdMembersSelected ? tabIdMembersSelected.length === 0 : true"
          [disabled]="!newTagName"
          [ngClass]="{'disabled': !newTagName}"
          class="btn emalsys_button green" (click)="addNewTagToRoster()">
    Add
  </button>
</div>

<div id="rosterTagCreateToast">The tag has been added</div>
<div id="rosterTagDeleteToast">The tag has been deleted</div>
