import { Injectable } from '@angular/core';
import { HttpClient       } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';
import { UserInterface } from '../../app/model/user.interface';

@Injectable()
export class UserEventService {

  constructor(
    protected http: HttpClient,
    protected _httpService: HttpService
  ) {}

  getUserEvents(orgaId, filters) {
    let options = this._httpService.createRequestOptions();

    let url = URL_API_EMALSYS + "/userevents/" + orgaId + "/members";
    for (let key in filters) {
      if (filters.hasOwnProperty(key) && filters[key] !== "" && filters[key] !== null) {
        url = url + (/\?/.test(url) ? "&" : "?") + key + "=" + filters[key];
      }
    }
    return this.http.get<UserInterface[]>(url, options);
  }

  createAlert(body) {
    let options = this._httpService.createRequestOptions();

    return this.http.post(URL_API_EMALSYS + "/userevents", body, options).pipe(
      // tap()
    );
  }

}
