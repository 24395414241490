<!--Profile-documents component-->
<div id="profile-documents-component" class="shadow">
  <!--Title-->
  <h2 class="text-center margin-b" i18n="@@documentsLabel">Documents</h2>
  <!--Click to display a modal that shows all documents-->
  <div class="all-docs">
    <div class="docs">
      <!-- <img *ngIf="!documents || (documents && documents.length == 0) || (documents && documents.length == 1)"
        class="docs-image" src="assets/images/Doc_Icon.png" /> -->
      <img *ngIf="documents && documents.length > 0" (click)="showAllDocsModal(seeAll)" class="docs-image"
        src="assets/images/Docs_Icon.png" />
    </div>
    <!--Display the number of uploaded document-->
    <div class="docs-name" *ngIf="documents">
      <h3 *ngIf="!documents || (documents && documents.length == 0)">There are no uploaded documents</h3>
      <h3 *ngIf="documents && documents.length >= 1">{{documents.length}} Document</h3>
      <h3 *ngIf="documents && documents.length > 1">{{documents.length}} Documents</h3>
    </div>
  </div>

  <!--Click to upload a document-->
  <div class="all-docs" *ngIf="!userId" (click)="showUploadModal(upload)">
    <img id="add-new" class="docs-image" src="assets/images/add_doc.png" />
    <div class="docs-name">
      <h3>
        <span id="add-new-doc">Add new Document</span>
      </h3>
    </div>
  </div>

  <!-- Modal for Getting all Documents-->
  <ng-template #seeAll let-c="close" let-d="dismiss">
    <!--Modal header-->
    <div class="modal-header">
      <!--Title-->
      <h4 class="modal-title" i18n="@@uploadedDocs">Uploaded Documents</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">
          <i class="fa fa-times"></i>
        </span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">
      <!--List of document: click on a document to download-->
      <div *ngIf="!downloadingFile">
        <div class="doc-and-name" *ngFor="let doc of documents" (click)="downloadDoc(doc)">
          <!--Picture-->
          <div class="document">
            <img class="document-image" [src]="doc.browser_url" onerror='this.src="assets/images/Docs_Icon.png"' />
            <!--To delete an uploaded document-->
            <i class="fa fa-times" (click)="deleteDoc($event, doc)"></i>
          </div>
          <!--Title of document-->
          <div class="doc-name">
            {{doc.title}}
          </div>
        </div>
      </div>
    </div>
    <spinner-component *ngIf="downloadingFile">
    </spinner-component>
    <!--Modale footer-->
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-roster-red-reverse" (click)="selectedFile=null; c()" i18n="@@fileOk">OK</button> -->
      <!-- <button [disabled]="!selectedFile" type="submit" class="btn btn-success" (click)="uploadDoc(); c()">Upload</button> -->
    </div>
  </ng-template>

  <!-- Modal for Uploading New Document-->
  <ng-template #upload let-c="close" let-d="dismiss">

    <!--Modal header-->
    <div class="modal-header">
      <!--Title-->
      <h4 class="modal-title" i18n="@@addNewDoc">Add New Document</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">
          <i class="fa fa-times"></i>
        </span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">
      <!--Select a file to upload-->
      <div class="upload-wrapper" *ngIf="!uploadingFile">
        <div class="title-input">
          <label for="file-title">Title</label>
          <input id="file-title" type="text" placeholder="Title" [(ngModel)]="titleFile">
          <label for="file-title">{{extensionFile}}</label>
        </div>
        <div class="file-input">
          <label for="file-input" class="control-label">File</label>
          <input #fileInput id="file-input" type="file" (change)="fileChange($event)" placeholder=""
            accept=".pdf,.doc,.docx,.txt,.jpg,.png,.jpeg">
        </div>
        <!-- <button *ngIf="selectedFile" type="submit" class="btn btn-success upload-btn" (click)="uploadDoc()">Upload</button> -->

      </div>
      <!--To display when you upload a file-->
      <spinner-component *ngIf="uploadingFile">
      </spinner-component>
    </div>

    <!--Modal footer-->
    <div class="modal-footer">
      <!--Cancel button-->
      <button type="button" class="btn emalsys_button red" (click)="selectedFile=null; c()"
        i18n="@@fileCancel">Cancel</button>
      <!--Upload button-->
      <button [disabled]="!selectedFile || uploadingFile" type="submit" class="btn emalsys_button green"
        (click)="uploadDoc()">Upload</button>
    </div>

  </ng-template>

  <!-- Toasts -->
  <div id="docDeleteToast">You have deleted a document</div>
  <div id="docErrorToast">Error loading a document! The file is too large. Maximum size is 2 MB.
    <br> {{errorMessage ? errorMessage :''}}</div>
  <div id="docAddToast">You have added a new document</div>

</div>