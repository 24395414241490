/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/spinner/spinner.component.ngfactory";
import * as i3 from "../../../shared/spinner/spinner.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./password-modal.component";
import * as i7 from "../../../../../services/utils/logger.service";
import * as i8 from "../../../../../services/security/login.service";
import * as i9 from "../../../../../services/storage/cache.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
var styles_PasswordModalComponent = [i0.styles];
var RenderType_PasswordModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordModalComponent, data: {} });
export { RenderType_PasswordModalComponent as RenderType_PasswordModalComponent };
function View_PasswordModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
export function View_PasswordModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm your password"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 16, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "form", [["id", "login-forms"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(10, 4210688, null, 0, i4.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i1.ɵdid(12, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "input", [["class", "form-control"], ["name", "password"], ["placeholder", "Password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        ((_co.password == "") ? "" : _co.validate());
        var pd_5 = (_co.activeModal.close() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(17, 671744, null, 0, i4.NgModel, [[2, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(19, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "login margin-b text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-red btn-block btn-lg"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.password == "") ? "" : _co.validate()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Validate"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordModalComponent_1)), i1.ɵdid(24, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_14 = "password"; var currVal_15 = _co.password; _ck(_v, 17, 0, currVal_14, currVal_15); var currVal_18 = _co.loading; _ck(_v, 24, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 19).ngClassValid; var currVal_12 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_16 = !_co.password; var currVal_17 = ((_co.password == "") || _co.loading); _ck(_v, 21, 0, currVal_16, currVal_17); }); }
export function View_PasswordModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-password-modal", [], null, null, null, View_PasswordModalComponent_0, RenderType_PasswordModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.PasswordModalComponent, [i7.LoggerService, i8.LoginService, i9.CacheService, i10.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordModalComponentNgFactory = i1.ɵccf("app-password-modal", i6.PasswordModalComponent, View_PasswordModalComponent_Host_0, { password: "password" }, { onValidate: "onValidate" }, []);
export { PasswordModalComponentNgFactory as PasswordModalComponentNgFactory };
