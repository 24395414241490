import { Component, Input } from '@angular/core';


@Component({
  selector   : 'component-roster',
  templateUrl: './component-roster.component.html',
  styleUrls  : ['./component-roster.component.scss']
})


export class ComponentRoster {   // TODO: Faire quelque chose pour ces histoires d'ISO2

  @Input() roster: any;
  @Input() label : string;

  constructor() {}

}
