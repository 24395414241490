import { environment } from "../../environments/environment";
import { Injectable } from '@angular/core';

@Injectable()
export class EnvironmentChecker {

    private static conditions = {
        adpc : environment.disabledFeatures && environment.disabledFeatures.public,
        emalsys: true,
    };

    /**
     * Execute the given function.
     * The default return is null since the return type cant be guessed
     * @param env
     * @param exec
     */
    private static execute(env: string, exec: Function) {
        if (EnvironmentChecker.conditions[env]) {
            return exec();
        }
        return null;
    }
    public static ifAdpc(exec: Function) {
        return EnvironmentChecker.execute('adpc', exec);
    }

    public static ifEmalsys(exec: Function) {
        return EnvironmentChecker.execute('emalsys', exec);
    }
}
