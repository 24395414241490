import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../../../shared.module';

 // Routing
// import { AlertsRouting }     from './alerts.routing';
// Components
import { AlertsComponent } from './alerts.component';

import { AlertsInformationComponent} from './alerts-information/alerts-information.component';
import { AlertsMapComponent        } from './alerts-map/alerts-map.component';
import { AlertsProcessComponent    } from './alerts-process/alerts-process.component';

import { AlertsCalendarComponent } from './alerts-deployment-calendar/alerts-deployment-calendar.component';
import { AlertsDeploymentOfferComponent } from './alerts-deployment-offer/alerts-deployment-offer.component';
import { AlertsDeploymentOrderComponent } from './alerts-deployment-order/alerts-deployment-order.component';
import { AlertsPreloaderMapComponent } from './alerts-preloader/alerts-preloader-map/alerts-preloader-map.component';
import { AlertsPreloaderInformationComponent } from './alerts-preloader/alerts-preloader-information/alerts-preloader-information.component';
import { AlertsPreloaderProcessComponent } from './alerts-preloader/alerts-preloader-process/alerts-preloader-process.component';
import { AlertsPreloaderContentComponent } from './alerts-preloader/alerts-preloader-content/alerts-preloader-content.component';
// Classes
// import { Selection                          } from './alerts-deployment-calendar/selection.class';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule
    // AlertsRouting
  ],
  declarations: [
    // components
        AlertsComponent,
    // subcomponents
    AlertsInformationComponent,
    AlertsMapComponent,
    AlertsProcessComponent,
    AlertsCalendarComponent,
    AlertsDeploymentOfferComponent,
    AlertsDeploymentOrderComponent,
    AlertsPreloaderMapComponent,
    AlertsPreloaderInformationComponent,
    AlertsPreloaderProcessComponent,
    AlertsPreloaderContentComponent
    // Selection,




  ],
  providers: []
})


export class AlertsModule {}
