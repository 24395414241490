import { Component, Input } from '@angular/core';

@Component({
  selector   : 'spinner-component',
  templateUrl: './spinner.component.html',
  styleUrls  : ['./spinner.component.scss']
})

export class SpinnerComponent {

  // input from parent component to make the spinner small (used on buttons)
  @Input() small: boolean;
  constructor() {}

}
