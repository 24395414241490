import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '../../../../services/utils/logger.service';
import { UserService } from '../../../../services/api/user.service';
// import { ToastService } from '../../../../services/utils/toast.service';

import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-confirmation-user',
  templateUrl: './confirmation-user.component.html',
  styleUrls: ['./confirmation-user.component.scss']
})
export class ConfirmationUserComponent implements OnInit {
  public environment = environment.envName;

  public content;
  public loading: boolean;
  public enabled: boolean;
  constructor(
    private route: ActivatedRoute,
    private _loggerService: LoggerService,
    private _userService: UserService,
    // private _toastService: ToastService,
  ) {


  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      this.enableUser(params.get('token'));
    });
  }

  enableUser(token) {
    this._userService.enableUser(token).subscribe(res => {
      this.showContentConfirmSuccess();
    }, error => {
      this.showContentNotFound();
    });
  }

  showContentNotFound() {
    this.content = "Sorry ! Your account is already activated or we can not find your account.";
    this.enabled = false;
    this.loading = false;
  }

  showContentConfirmSuccess() {
    this.content = "Congratulations ! Your account is activated !";
    this.enabled = true;
    this.loading = false;
  }
}
