export class Availability {
    static checkAvailability(date) {
        let today = new Date().toDateString();
        let timestampToday = Date.parse(today);
        let result = Date.parse(date);
        return (result <= timestampToday) ? true : false;
    }
}
Availability.AVAILABLE = 1;
Availability.UNAVAILABLE = 0;
Availability.NOTSURE = 2;
