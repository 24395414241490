// Components
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Title }     from '@angular/platform-browser';
@Component({
  selector   : 'forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls  : ['./forbidden.component.scss']
})


export class ForbiddenComponent {

  constructor(
    private titleService: Title ,
    private location: Location) {
      this.titleService.setTitle("Emalsys - Forbidden");
    }

  back() {
    this.location.back();
  }

}
