import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // Variables declaration
  public environment = environment.envName;
  // Video url
  public videoHomepage = 'https://www.youtube.com/embed/2Cus966EmyU';
  // Availability on app store and google play, images
  public appStoreImageUrl = '../../../../assets/getting-started/app-store.png';
  public googlePlayUrl = '../../../../assets/getting-started/google-play.png';
  // Logos of social media
  public twitterUrl = '../../../../assets/icons/twitter.png';
  public facebookUrl = '../../../../assets/icons/facebook.png';
  public linkedinUrl = '../../../../assets/icons/linkedin.png';
  // Year for copyright in page's footer
  public yearCopyright: any = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  // Method to return video url
  public displayVideo(url: string) {
    return url;
  }

}
