import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DeploymentOrderService } from '../../../../../../services/api/deployment-order.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { ToastService } from '../../../../../../services/utils/toast.service';
@Component({
  selector: 'app-deployment-offers-modal',
  templateUrl: './deployment-offers-modal.component.html',
  styleUrls: ['./deployment-offers-modal.component.scss']
})
export class DeploymentOffersModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public _deploymentOrderService: DeploymentOrderService,
    public _loggerService: LoggerService,
    public _rightService: RightService,
    private _toastService: ToastService,

  ) { }
  @Output() onClickCancel = new EventEmitter<any>();
  @Input() deploymentOffers;
  @Input() profile;
  @Input() organisation;
  public loading = false;
  public today = new Date();
  public offerEndDate: any;

  ngOnInit() {
    this.deploymentOffers.reverse();
    this.deploymentOffers.forEach(offer => {
      offer.end_date = new Date(offer.end_date);
    });

  }

  cancelOffer(idDeploymentOffer) {
    this.loading = true;
    this._deploymentOrderService.cancelDeploymentOffer(idDeploymentOffer).subscribe(res => {
      this.onClickCancel.emit(res);
      this.showToastDeleteEvent();
      this.loading = false;
      this.activeModal.close();
    });
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  showToastDeleteEvent() {
    this._toastService.show("deploymentOfferCancelToast", "show", 3000);
  }
}
