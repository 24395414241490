import { Dispatcher } from '../../dispatcher.service';
import { BehaviorSubject } from 'rxjs';
export class OrganisationDispatcherService extends Dispatcher {
    constructor() {
        super(...arguments);
        this.organisations$ = new BehaviorSubject(null);
        this.userOrganisations$ = new BehaviorSubject(null);
        this.selectedOrganisation$ = new BehaviorSubject(null);
        this.currentlyEditingOrganisation$ = new BehaviorSubject(null);
    }
    /**
     * @param organisation OrganisationInterface or number (for the id)
     */
    selectOrganisation(organisation) {
        if (typeof organisation === 'number') {
            organisation = this.organisations$.getValue().find(x => x.id === organisation);
        }
        if (organisation) {
            this.selectedOrganisation$.next(organisation);
        }
    }
    getSelectedOrganisationScalar() {
        return this.selectedOrganisation$.getValue();
    }
    getSelectedOrganisation() {
        return this.pipeNotNull(this.selectedOrganisation$);
    }
    getScalarSelectedOrganisation() {
        return this.selectedOrganisation$.getValue();
    }
    getOrganisations() {
        return this.pipeNotNull(this.organisations$);
    }
    dispatchOrganisations(organisations) {
        this.organisations$.next(organisations);
    }
    getUserOrganisations() {
        return this.pipeNotNull(this.userOrganisations$);
    }
    dispatchUserOrganisations(organisations) {
        this.userOrganisations$.next(organisations);
    }
    getCurrentlyEditingOrganisation() {
        return this.pipeNotNull(this.currentlyEditingOrganisation$);
    }
    setCurrentlyEditingOrganisation(organisation) {
        this.currentlyEditingOrganisation$.next(organisation);
    }
}
