import { Subscription } from 'rxjs';
import { AlertCachedService } from '../../../../../../services/api/alert/alert.cacher';
import { OrganisationInterface } from './../../../../../model/organisation.interface';
import { OrganisationDispatcherService } from '../../../../../../services/api/organisation/organisation.dispatcher';
import { AlertInterface } from './../../../../../model/alert.interface';
import { AlertDispatcherService } from '../../../../../../services/api/alert/alert.dispatcher';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
// Services
import { LeafletService } from '../../../../../../services/external/leaflet.service';
import { AlertService } from '../../../../../../services/api/alert/alert.api';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { AlertPageService } from '../../../../../../services/page/alert.manager.pager';

import { LATLONG } from '../../../../../../data/countries';
import * as Leaflet from 'leaflet';

@Component({
  selector: 'alerts-manager-map',
  templateUrl: './alerts-map.component.html',
  styleUrls: ['./alerts-map.component.scss']
})
export class AlertsMapComponent implements OnInit, OnDestroy {
  organisation: any;
  events: any;
  oldEvents: any;
  @Output() onChangeEvent = new EventEmitter<any>();

  public map: any;
  public layers: any;
  public selectedAlert: number;
  public selectedAlertInfo: any;
  public selectedLayer: any;

  public dataPrimaryLayers: any;
  public dataSecondaryLayers: any;

  public alertList = false;
  public oldList = false;
  public eventsSorted = [];

  public mapCreated = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private _leafletService: LeafletService,
    private _alertDispatcher: AlertDispatcherService,
    private _alertPage: AlertPageService,
    private _alertService: AlertService,
  ) { }

  ngOnInit() {
    // Subscribe for the alerts
    let alerts = this._alertDispatcher.getOrganisationAlerts().subscribe(
      (res: AlertInterface[]) => {
        this.events = res;
        // Update eventsSorted to display them into the alert list
        // TODO see what happens if we use the same array for both views
        this.sortEvents();
        // Add 'already created' verification
        if (this.mapCreated) {
          this.refreshMap();
        } else {
          this.createMap();
          this.mapCreated = true;
        }
      }
    );

    // Subscribe for current selected alert
    let selectedAlert = this._alertDispatcher.getSelectedAlert().subscribe(
      (res: AlertInterface) => {
        this.selectedAlertInfo = res;
        this.selectedAlert = res.id;
      }
    );

    // Subscribe for old organisation alerts
    let oldAlerts = this._alertDispatcher.getOldOrganisationAlerts().subscribe(
      (res: AlertInterface[]) => this.oldEvents = res
    );

    this.subscriptions.push(alerts, selectedAlert, oldAlerts);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( x => x.unsubscribe() );
  }

  handleChangeDisplay() {
    this.alertList = !this.alertList;
    this.oldList = false;
    this.sortEvents();
    this.refreshMap();
  }

  handleChangeList() {
    if (this.oldList) {
      this.sortEvents();
    } else {
      this.eventsSorted = this.oldEvents;
    }
    this.oldList = !this.oldList;
  }

  createMap() {
    this.map = this._leafletService.createMap("alerts-map-component-map", 2);
    this.layers = this._leafletService.createLayerGroup();
    this.refreshMap();
  }

  getDataPrimaryLayers(events) {
    let dataPrimaryCountries = [];
    let isInDataCountries: boolean;

    for (let i = 0; i < events.length; i++) {
      let index = events[i].country;
      let alertsCountry = [];
      isInDataCountries = dataPrimaryCountries.findIndex(x => x.country === index) !== -1;

      if (!isInDataCountries) {
        alertsCountry = events.filter(x => x.country === index);
        dataPrimaryCountries.push({ country: index, alerts: alertsCountry });
      }
    }
    return dataPrimaryCountries;
  }

  getDataSecondaryLayers(events) {
    let dataSecondaryCountries = [];
    let isInDataCountries: boolean;
    for (let i = 0; i < events.length; i++) {
      events[i].secondary_countries.forEach((item1: string, index1: number, array) => {
        let index = item1;
        let alertsCountry = [];
        isInDataCountries = dataSecondaryCountries.findIndex(x => x.secondary_country === index) !== -1;

        if (!isInDataCountries) {
          for (let j = 0; j < events.length; j++) {
            events[j].secondary_countries.forEach((item2: string, index2: number) => {
              if (item2 === index) {
                alertsCountry.push(events[j]);
              }
            });
          }
          dataSecondaryCountries.push({ country: index, alerts: alertsCountry });
        }
      });
    }
    return dataSecondaryCountries;
  }

  addPrimaryChoropleth(dataPrimaryLayers) {
    dataPrimaryLayers.map(dataLayer => {
      let layer = this._leafletService.setPrimaryChoroplethAlert(dataLayer);
      this.handleClickOnLayer(layer.options.alerts, layer);
    });
  }

  layersForSmallCountries(alerts) {
    alerts.map((alert) => {
      let marker = this._leafletService.createMarkerSmallAlert(alert);
      if (marker) {
        this.layers.addLayer(marker);
      }
    });
    // alerts.map(alert => alert.secondary_countries.map(country => this.markers.addLayer(this._leafletService.createMarkerAlertSecondary(alert, country))));
    this.layers.addTo(this.map);
  }

  addSecondaryChoropleth(dataSecondaryLayers) {
    dataSecondaryLayers.map(dataLayer => {
      let layer = this._leafletService.setSecondaryChoroplethAlert(dataLayer);
      this.handleClickOnLayer(layer.options.alerts, layer);
    });
  }

  enableZoom() {
    this.map.scrollWheelZoom.enable();
  }

  setMostRecentAlert(events) {
    events.sort(
      (e1, e2) => {
        if (e1.start_date > e2.start_date) {
          return 1;
        } else if (e1.start_date < e2.start_date) {
          return -1;
        }
        return 0;
      }
    );
    this.setSelectedAlert(events[0]);
  }

  sortAlertsByMostRecent(events) {

  }

  setSelectedAlert(alert) {
    this._alertPage.selectAlert(alert);
  }

  handleClickOnLayer(alerts, layer) {
    if (alerts && alerts.length > 1) {
      layer.on('click', () => {
        this.selectedLayer = layer;
        this.map.fitBounds(layer.getBounds());
      }
      );
    } else {
      if (layer.options.focus) {
        layer.on('mouseover', () => layer.bindTooltip(alerts.length + " alerts in this country").openTooltip());
        layer.on('mouseover', () => layer.bindTooltip(alerts[0].name).openTooltip());
        layer.on('click', () => {
          this.setSelectedAlert(alerts[0]);
          this.selectedLayer = "";
          this.map.fitBounds(layer.getBounds());
          // this.changeEvent();
        });
      } else if (!layer.options.focus) {
        layer.on('click', () => {
          this.setBoundSmallCOuntries(layer);
        });
      }
    }
    this.layers.addLayer(layer);
  }

  refreshMap() {
    if (this.map && this.events) {
      this.layers.clearLayers();
      this.dataPrimaryLayers = this.getDataPrimaryLayers(this.events);
      this.dataSecondaryLayers = this.getDataSecondaryLayers(this.events);
      this.addPrimaryChoropleth(this.dataPrimaryLayers);
      this.addSecondaryChoropleth(this.dataSecondaryLayers);
      // this.layersForSmallCountries(this.dataPrimaryLayers);
      this.layers.addTo(this.map);
      if (!this.selectedAlert) {
        this.setMostRecentAlert(this.events);
      }
      this.setSelectedAlert(this.selectedAlertInfo);
      this.selectedLayer = "";
      this.focusCountry();
      // this.changeEvent();
    }
  }

  sortEvents() {
    // We're sorting the events alphabetically in order to display them in the event list
    this.eventsSorted = [];
    let stop;
    this.events.map((event) => {
      stop = false;
      if (event.country_name === null) {
        event.country_name = "Other";
      }
      for (let i = 0; i <= this.eventsSorted.length && !stop; i++) {
        if (!this.eventsSorted[i] && i === this.eventsSorted.length) {
          this.eventsSorted.splice(i, 0, event);
          stop = true;
        } else if (this.eventsSorted[i].country_name >= event.country_name) {
          this.eventsSorted.splice(i, 0, event);
          stop = true;
        }
      }
    });
  }

  setBoundSmallCOuntries(alert) {
    for (let x = 0; x < LATLONG.length; x++) {
      if (alert.options.alerts[0].country_name === LATLONG[x]["name"]) {
        let coor = LATLONG[x]["latlng"],
        coorS = coor[0] - 1,
        coorN = coor[0] + 1,
        coorE = coor[1] + 1,
        coorW = coor[1] - 1;

        this.map.fitBounds([ [coorN, coorE], [coorS, coorW] ]);
      }
    }
  }

  focusCountry() {
    let layers: any[] = Object.values(this.layers._layers);
    let found = false;
    // let allAlertsOnMap = layers.flat(x => x.options.alerts);
    for (let i = 0; i < layers.length && !found; i++) {
      for (let j = 0; j < layers[i].options.alerts.length && !found; j++) {
        if (layers[i].options.alerts[j].id === this.selectedAlert) {
          if (layers[i].options.focus) {
            this.map.fitBounds((layers[i]).getBounds());
            found = true;
          } else if (!layers[i].options.focus) {
            this.setBoundSmallCOuntries(layers[i]);
          }
        }
      }
    }
  }
}
