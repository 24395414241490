import { Component } from '@angular/core';

// Services
import { CacheService } from '../../../../services/storage/cache.service';

//Language
import { SettingsInterface } from '../../../../app/model/settings.interface';

@Component({
  selector: 'faq-component',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.scss']
})
export class FaqComponent {

// Basic Faq composed of question and answer
public faqOverview     : any;
public faqUsing        : any;
public faqBranding     : any;
public faqCustomisation: any;
public faqSupport      : any;
public faqPricing      : any;
public faqSecurity     : any;
public faqAbout        : any;
// Faq section titles
public faqOverviewTitle     : string = 'Overview';
public faqUsingTitle        : string = 'Using EMALSYS';
public faqBrandingTitle     : string = 'Branding';
public faqCustomisationTitle: string = 'Customisation';
public faqSupportTitle      : string = 'Support';
public faqPricingTitle      : string = 'Pricing';
public faqSecurityTitle     : string = 'Security and Privacy';
public faqAboutTitle        : string = 'About Us';
public language             : string;

constructor() {

  // For language Settings

  // tips :
// this.faqOverview['fr'] = [{...}, ...]
// this.language = settings.language;
// this.faqOverview['es'] = [{...}, ...]
// //pareil pour faqUsing ...
// ensuite tu récupère le language actuel :
// //import { SettingsInterface } from './app/model/settings.interface';
// let settings: SettingsInterface = cache.get(CacheService.SETTINGS) || {} ;
// *ngFor="let faq of faqOverview[this.language]"


  // let settings : SettingsInterface = Cache.call(CacheService.SETTINGS) || {};
  // this.language = settings.language;

  this.faqOverview = [
    { isShown: false, question: 'What is Emalsys?',                 answer: 'EMALSYS stands for Emergency Alert System, the application made by European-based company Relief Applications, which supports the NGOs and non-profit organisations in their timely and cost-effective response to worldwide crisis. EMALSYS features a roster management tool which purpose is to assist, manage, mobilise and deploy staff.'},
    { isShown: false, question: 'Who is this system designed for?', answer: 'EMALSYS has been primarily designed for humanitarian organisations to manage their emergency response teams more effectively. It can be used by any organisation dealing with crisis management, deploying staff to the field. The tool also works for internal and external pools of consultants/staff.'},
    { isShown: false, question: 'What does EMALSYS do?',            answer: 'Coordinators will organise and mobilise staff or consultants more effectively. Roster members will be ready for deployment, as they keep their profile up-to-date and remain informed.'},
    { isShown: false, question: ' Why is this needed?',             answer: 'Feedback from leading INGOs, UN agencies, and government bodies points to the lack of technological tools to coordinate humanitarian response. Our own experience as emergency responders inspired us to develop a tool to improve roster management and mobilisation. We conceived the tool as a power-up to existing working practices that fits naturally to the organisations. '}
  ];

  // this.faqOverview['fr'] = [
  //   { isShown: false, question: "Qu'est-ce que Emalsys?",            answer: "EMALSYS signifie Emergency Alert System (système d'alerte d'urgences), l'application produite par l'entreprise européeenne Relief Applications, qui soutient les ONGs et les associations à but non lucratif dans leur réponse aux urgences partout dans le monde. EMALSYS contient un outil de gestion d'équipes volantes qui vise à assister, organiser, mobiliser and deployer du personnel."},
  //   { isShown: false, question: "A qui ce système s'adresse-t-il ?", answer: "EMALSYS a tout d'abord été pensé pour les organisations humanitaires pour qu'ils organisent plus efficacement leurs équipes de réponse d'urgence. Il peut être utilisé par toute organisation qui traite de la gestion de crises en déployant du personnel sur le terrain. L'outil concerne également les réserves externes de consultants et de personnel."},
  //   { isShown: false, question: 'Que fait EMALSYS ?',                answer: "Les coordinateurs organiseront et mobiliseront du personnel ou des consultants plus efficacement. Les membres d'équipes volantes seront prêtes à être déployées, en gardant leur profil à jour et en restant informés."},
  //   { isShown: false, question: "Quelle est la raison d'être ?",     answer: "Le retour des ONGs internationales, des agences des Nations Unies et des corps gouvernementaux mettent en évidence le manque d'outils technologiques pour coordonner la réponse humanitaire. Notre expérience comme intervenants en urgence nous a inspirés dans le développement d'un outil de gestion de mobilisation d'équipes volantes. Nous avons conçu l'outil comme un catalyseur des pratiques existantes et qui s'insère naturellement dans les organisations."}
  // ];

  // this.faqOverview['es'] = [
  //   { isShown: false, question: "¿Qué es Emalsys?",            answer: "EMALSYS significa Emergency Alert System (sistema de alerta de emergencias), la aplicación producida por la empresa europea Relief Applications, que respalda las ONGs et las associaciones sin ánimo de lucro en su respuesta a a emergencias en todo el mundo. EMALSYS contiene una herramienta de gestión de rosters que tiene como objetivo asistir, organizar, mobilizar y desplegar personal."},
  //   { isShown: false, question: "¿Para quién se dirige el sistema?", answer: "EMALSYS fue concebido prioritamente por las organizaciones humanitarias para que manejen de manera más eficaz sus equipos de respuestas a emergencias. Puede ser utilizado por cualquiera organización que trata de gestión de crisis por el despliego de personal al terreno. La herramienta se utiliza también para las reservas externas de consultores et de personal."},
  //   { isShown: false, question: '¿Qué hace EMALSYS?',                answer: "Los coordinadores organisan y movilisan personal o consultores de manera más eficaz. Los mimebros de rosters están listos para el despliego, actualizando su perfil y siguidendo informados."},
  //   { isShown: false, question: "¿Por qué se lo necesita?",     answer: "La rertoalimentación de ONGs internacionales, de agencias de las Naciones Unidas y de cuerpos gubernementales evidencian la carencia de herramientas tecnológicas para coordonar la respuesta humanitaria. Nuestra experiencia comme respondores a emergnecias nos inspiró el desarrollo de una herramienta gestión de movilización de rosters. Concibimos la herramienta como un apoyo de las práticas existantes y que se introduzca naturalmente dentro las organizacioens."}
  // ];

  this.faqUsing = [
    { isShown: false, question: 'How can I learn about its features?',                              answer: 'Check out what you can do with Emalsys, ', textForLink: 'click here!', routing: '/public/features' },
    { isShown: false, question: 'How can I create a member profile?',                               answer: 'Go see our '                             , textForLink: 'Getting Started Page!', routing: '/public/getting-started' },
    { isShown: false, question: 'Where can I learn to use the system?',                             answer: 'We do our best to keep a'                , textForLink: 'Wiki guide up-to-date.', answerBis:' Our team is dedicated to help and assist organisations and users to navigate through the system. We can provide free-of-charge support, such as hand holding and remote assistance to subscribers. We are glad to help organisations through the initial steps. We are regularly updating EMALSYS and additional functionalities are regularly integrated into the system. If you need further guidance,', textForLinkBis: ' please Contact Us.', routing:'/user-guide', routingBis: '/public/contact' },
    { isShown: false, question: 'Can I have multiple accounts?',                                    answer: 'Yes, accounts are associated with the email address used to register.' },
    { isShown: false, question: 'Can I use the system on my phone?',                                answer: 'A free App is now available. For more information, you can download it on', textForLink:'Android', answerBis: '. The system is also responsive and works well on all desktops, laptops, tablets, and smart phones.', extLink: 'https://play.google.com/store/apps/details?id=org.reliefapps.emalsys' },
    { isShown: false, question: 'Can I be an emergency responder and a manager?',                   answer: 'Yes, you can both be a member of rosters and manager of rosters simultaneously.' },
    { isShown: false, question: 'How are alerts sent to emergency responders?',                     answer: 'Alerts can be sent via app push notifications, email, and SMS.' },
    { isShown: false, question: 'Why can’t I see my organisation board when I connect on Emalsys?', answer: 'When you register on Emalsys as a member, you need to apply to join an organisation roster (open for recruitment) or to be invited by an organisation manager to join a roster. You can also be invited to join the organisation as a manager. You will then see the organisation board appear on the top left.' },
    { isShown: false, question: 'Why can’t I apply to a roster?',                                   answer: 'When you register to Emalsys, you can request to join the public and ‘open for recruitment’ rosters. In order to do so, you need to upload first your resume on your profile in the section ‘Documents’ and make it public by clicking the box ‘Make it public (seen by all organisations)’.' },
    { isShown: false, question: 'How can I permanently delete documents from the platform?',        answer: 'To permanently remove a document, you need to delete it from your personal gallery. For instance if you remove a document from a roster gallery, it will remain in your personal gallery and use your personal space.' },
    { isShown: false, question: 'How can I permanently remove a user from my organisation?',        answer: 'If you remove a member from the roster, it will remain in your organisation network as an ‘Inactive User’ and you will still need to pay for his/her account.' },
    { isShown: false, question: 'How can I delete my account?',                                     answer: "Sorry to see you go! Before deleting your account forever, if you are experiencing issues with your account,", textForLink: " reach out to our support team here.", answerBis: " NB: When you delete your account, your profile, photo, documents attached, will be permanently removed and the account can’t be reactivated. To permanently delete your account:",
      dotList: [{dotListText: "Click on the profile symbol in the high bar on the right and go to the Setting page."},{dotListText: "Click on Delete my account and confirm."}],
      textAfterList: "You’ll need to be able to log into your account to delete it. If you can't remember your password click on", textForLinkBis: " Forget your password?", answerTer: " on the login page and follow the instructions.", routing: '/public/contact', routingBis: '' }, // routingBis for forgotten password page
  ];

  // this.faqUsing['fr'] = [
  //   { isShown: false, question: 'Comment en savoir plus sur les fonctionnalités ?',                 answer: 'Venez apprendre ce que vous pouvez faire avec Emalsys, ', textForLink: 'cliquez ici!', routing: '/auth/features' },
  //   { isShown: false, question: "Comment puis-je créer un profil d'équipe volante ?",               answer: 'Allez voir la page '                             , textForLink: 'pour Démarrer !', routing: '/auth/getting-started' },
  //   { isShown: false, question: "Comment puis-je apprendre à utiliser le système ?",                answer: 'Nous mettons en œuvre pour mettre à jour notre'  , textForLink: 'guide en ligne.', answerBis: " Notre équipe reste à votre disposition pour vous aider et assister les organisations et utilisateurs pour la navigation dans leur système. Nous pouvons fournir un service hors frais supplémentaires, telle que la prise de main et l'assistance aux abonnés. Nous sommes enthousiastes d'accompagner les organisations à chaque étape. Nous mettons régulièrement à jour EMALSYS et des fonctionnalités complémentaires sont ajoutées au fil du temps au système. Pour en savoir davantage,", textForLinkBis: ' contactez-nous.', routing:'/user-guide', routingBis: '/auth/demo' },
  //   { isShown: false, question: "Puis-je avoir plusieurs comptes ?",                                answer: "Oui, les comptes sont associées à une adresse électronique utilisée pour l'inscription." },
  //   { isShown: false, question: "Est-il possible d'utiliser le système sur mon téléphone ?",        answer: 'Une application gratuite est disponible. Pour la découvrir, téléchargez-là sur ', textForLink:'Android', answerBis: '. Le système est adaptatif et fonctionne aussi bien sur les ordinateurs fixes, les ordinateurs portables, les tablettes et les téléphones évolués.', extLink: 'https://play.google.com/store/apps/details?id=org.reliefapps.emalsys' },
  //   { isShown: false, question: "Puis-je être à la fois un intervenant en urgence et un administrateur ?", answer: "Oui, vous pouvez être à la fois membre et administrateur d'équipes volantes." },
  //   { isShown: false, question: 'Comment les alertes sont-elles envoyées aux intervenants en urgence ?',   answer: "Les alertes peuvent être envoyées par notifications depuis l'application, par adresse électronique et message téléphonique." },
  //   { isShown: false, question: "Pourquoi est-ce que je n'aperçois pas l'intitulé de mon organisation en me connectant sur Emalsys ?", answer: "En vous enregistrant sur Emalsys en tant que membre, vous devez contacter une équipe volante pour la rejoindre (ouvertes au recrutement), ou alors être invité par l'administrateur de l'organisation. Vous pouvez aussi être invité par une organisation comme administrateur. Ensuite, vous verrez l'intitulé de votre roganisation apparaître en haut à gauche de la page." },
  //   { isShown: false, question: 'Pourquoi ne puis-je pas candidater à une équipe volante ?',        answer: 'En vous enregistrant sur Emalsys, vous pouvez demander à rejoindre les équipes volantes ‘ouvertes au recrutement’. Chargez premièrement votre CV sur votre profil dans la section ‘Documents’ et rendez-le public en cochant la case ‘Rendre public (vu par toutes les organisations)’.' },
  //   { isShown: false, question:  "Comment puis-je supprimer définitivement les documents de la plateforme ?", answer: "Pour supprimer définitivement un document, vous devez le supprimer de vos archives personnelles. Par exemple, si vous supprimer un document des archives d'une équipe volante, il restera dans vos archives personnelles et occupe votre espace personnel." },
  //   { isShown: false, question:  "Comment puis-je supprimer définitivement un utilisateur de mon organisation ?", answer: "En supprimant un membre de l'équipe volante, il se maintiendra dans le réseau de votre organisation en tant qu' ‘utilisateur inactif’ et vous devrez prendre en charge financièrement son compte." },
  //   { isShown: false, question:  "Comment supprimer mon compte ?",                                  answer: "Nous étions si bien ensemble! Avant de supprimer une bonne fois pour toutes votre compte, si vous avez rencontré des incidents sur votre compte,", textForLink: " mettez-vous en relation avec notre équipe technique.", answerBis: " NB: Quand vous supprimez votre compte, vos profil, photographies, documents joints, seront supprimés de manière définitive et le compte ne sera plus accessible. Pour supprimer définitivement votre compte :",
  //     dotList: [{dotListText: "Cliquez sur le symbole Profil sur la page de votre compte sur la barre en haut à droite et allez sur la page des paramètres."},{dotListText: "Cliquez sur Supprimer mon compte et confirmez."}],
  //     textAfterList: "Vous devez disposer d'un accès à votre compte pour le supprimer. Si vous ne vous souvenez plus de votre mot de passe, cliquez sur", textForLinkBis: " Vous avez oublié votre mot de passe?", answerTer: " sur la page d'identification et suivez les instructions.", routing: '/auth/demo', routingBis: '' }, // routingBis for forgotten password page
  // ];

  // this.faqUsing['es'] = [
  //   { isShown: false, question: '¿Cómo saber más en cuánto a las funcionalidades?',                  answer: 'Aprende todo lo que puede hacer con Emalsys, ', textForLink: '¡pulse aquí!', routing: '/auth/features' },
  //   { isShown: false, question: "¿Cómo puedo crear un róster?",                                     answer: 'Véase la página '                             , textForLink: '¡para Empezar!', routing: '/auth/getting-started' },
  //   { isShown: false, question: "¿Cómo puedo aprender a utilizar el sistema?",                      answer: 'Sigamos trabajando para actualizar el '       , textForLink: 'manual en línea.', answerBis: " Nuestro equipo queda a su dispocisión para ayudar y asistir las organizaciones y usadores para la navegación dentro su sistema. Pdeoms brindar un servicio sin gasto suplementario, tal como la tomada de control a distancia y la asistencia a subscritores. Estamos ilusionados acompañar las organizaciones a cada etapa. Actualizamos regularmente EMALSYS y des funcionalidades complementarias se agregan poco a poco al sistema. Si tiene alguna dificultad,", textForLinkBis: ' contáctenos.', routing:'/user-guide', routingBis: '/auth/demo' },
  //   { isShown: false, question: "¿Puedo tener varias cuentas?",                                     answer: "Sí, las cuentas son asociadas a una dirección electrónica utilizada para la subscripción." },
  //   { isShown: false, question: "¿Es posible utilizar el sistema en mi téléfono ?",        answer: 'Una aplicación gratuita está disponible. Para descubrirla, desacárguela desde ', textForLink:'Android', answerBis: '. El sistema se adapta y funciona tan bien en los ordenadores, los portátiles, los tablets y los téléfonos avanzados.', extLink: 'https://play.google.com/store/apps/details?id=org.reliefapps.emalsys' },
  //   { isShown: false, question: "¿Puedo ser un administrador y un respondor a emergencias al mismo tiempo?", answer: "Sí, puede ser ambos miembro y administrador de rósters." },
  //   { isShown: false, question: '¿Cómo las alertas son enviadas a los respondores a emergencias?',   answer: "Las alertas se pueden enviar por notificaciones desde la aplicación, por dirección electrónica y mensaje telefónico." },
  //   { isShown: false, question: "¿Por qué no veo el nombre de mi organización al conectar en Emalsys?", answer: "Registrándose como miembro en Emalsys, tiene que contactar un róster para formar parte (abiertos al reclutamiento), sino ser invitado por un administrador de una organización. Puede asimismo ser invitaado como administrador. Después, verá el nombre de su organización aparecer encima por la izaquierda de la página." },
  //   { isShown: false, question: '¿Por qué no puedo postularme a un róster?',        answer: 'Registrándose en Emalsys, puede pedir acceso a rosters ‘abiertos al reclutamiento’. Primero cargue su CV en su perfil en la sección ‘Documentos’ y cámbielo en público cheqeando la casilla ‘Poner en público (visto por todas las organizaciones)’.' },
  //   { isShown: false, question:  "¿Cómo puedo suprimir definitivamente los documentos de la plataforma?", answer: "Para suprimir definitivamente un documento, tiene que suprimirlo de sus archivos personales. Por ejemplo, si suprime un documento archivos de un róster, se quedará en sus archivos personales y ocupa su espacio personal." },
  //   { isShown: false, question:  "¿Cómo puedo suprimir definitivamente un usador de mi organización?", answer: "Suprimiendo un miembro del róster, se mantendrá en la red de su organización como ‘usador inactivo’ y tendrá que encargarse financieramente de su cuenta." },
  //   { isShown: false, question:  "¿Cómo puedo suprimir mi cuenta?",                                  answer: "¡Qué lástima verle partir! Antes de suprimir definitivamente su cuenta, si encontró dificultades en su cuenta,", textForLink: " relaciónese con nuestro equipo técnico.", answerBis: " NB: Cuando suprime su cuenta, sus perfil, fotografías, documentos adjuntos, se suprimirán definitivamente y la cuenta no se podrá acceder. para suprimir definitivamente su cuenta :",
  //     dotList: [{dotListText: "Haga click en el símbolo Perfil en la página de su cuenta en la barra encima por la derecha y vé a la página parámetros."},{dotListText: "Pulse en Suprimir mi cuenta y confirme."}],
  //     textAfterList: "Tiene que tener acceso a su cuenta para suprimirla. Si no se recuerde de su contraseña, pinche", textForLinkBis: " ¿Se olvidó de su contraseña?", answerTer: " en la página de identificación y siga las instrucciones.", routing: '/auth/demo', routingBis: '' }, // routingBis for forgotten password page
  // ];

  this.faqBranding = [
    { isShown: false, question: 'My organisation would like to use EMALSYS with its own branding. Is this possible?', answer: 'The platform is designed to reach a broad, inclusive community of emergency responders. However, it is possible for organisations to brand sub-domains or create private rosters. For specific details about branding,', textForLink: 'contact us.', routing: '/public/contact' }
  ];

  // this.faqBranding['fr'] = [
  //   { isShown: false, question: "Mon organisation veut utiliser EMALSYS avec son nom propre. C'est possible?", answer: "La plateforme a été conçue pour accueillir une communauté large et inclusive d'intervenants en urgence. Cependant, il est possible pour les organisations de se franchiser ou de créer des équipes volantes privées. Pour plus de détails sur la franchise,", textForLink: 'contactez-nous', routing: '/auth/demo' }
  // ];

  // this.faqBranding['es'] = [
  //   { isShown: false, question: "Mi organización quiere utilizar EMALSYS con su nombre propio. ¿Es posible?", answer: "La plataforma fue concibida para acoger una comunidad larga e inclusiva de respondores a emergencias. También, las organizaciones pueden obtener un contrato de franquicia o crear rósters privados. Para más detalles sobre la franquicia,", textForLink: 'contáctenos', routing: '/auth/demo' }
  // ];

  this.faqCustomisation = [
    { isShown: false, question: 'Can my organisation request custom features or developments?', answer:'The general aim is to add all new features into the global platform, however we understand that organisations may rather have specific requirements. We are glad to discuss options for these developments.', textForLink: ' Fill in the contact form for more info.', routing: '/public/contact' }
  ];

  // this.faqCustomisation['fr'] = [
  //   { isShown: false, question: "Est-ce que mon organisation peut demander des fonctionnalités ou des développements personnalisés ?", answer:'En règle générale, les nouvelles fonctionnalités sont ajoutées à la plateforme, néanmoins nous comprenons que les organisations puissent présenter des exigences spécifiques. Nous serons heureux de discuter des options pour ces développements.', textForLink: ' Remplissez le formulaire de contact!', routing: '/auth/demo' }
  // ];

  // this.faqCustomisation['es'] = [
  //   { isShown: false, question: "¿Puede mi organización pedir funcionalidades o desarrollos personalizados?", answer:'Generalmente, las nuevas funcionalidades son agregadas a la plataforma, no obstante entendemos exigencias espicíficas de la parte de las organizaciones. Estaremos contentos discutir de las opciones por estos desarrollos.', textForLink: '¡Cuéntenos en el formulario de contacto!', routing: '/auth/demo' }
  // ];

  this.faqSupport = [
    { isShown: false, question: 'My organisation is interested in using EMALSYS but need training, can you help?', answer:'The system has been designed to be user-friendly and simple to use. However, we understand additional support may be useful for organizations adopting new technologies or integrating with existing databases. We are glad to provide remote and/or in-person trainings to assist your team learn about the platform. General customer service is available via email/chat between 9am-6pm CET, Monday-Friday. Emalsys is based on your needs so feel free to provide any feedback:', textForLink: ' contact us.', routing: '/public/contact' }
  ];

  // this.faqSupport['fr'] = [
  //   { isShown: false, question: "Mon organisation s'intéresse à l'utilisation d'EMALSYS mais a besoin d'une formation, pouvez-vous nous aider?", answer:"Le système a été conçu pour être ergonomique et simple d'utilisation. Toutefois, nous comprenons qu'une aide supplémentaire peut être utile pour les organisations qui adoptent de nouvelles technologies ou qui intègrent de nouvelles bases de données. Nous sommes heureux de fournir des formations à distance ou en présentiel pour assister votre équipe dans leur prise en main de la plateforme. Le service général de clientèle est disponible via chat/adresse électronique de 9h00 à 18h00 (heure d'Europe Centrale), du lundi au vendredi. Emalsys s'adapte à vos besoins alors sentez-vous libre de nous donner un retour:", textForLink: ' Contactez-nous.', routing: '/auth/demo' }
  // ];

  // this.faqSupport['es'] = [
  //   { isShown: false, question: "Mi organización le interesa utilisar EMALSYS pero precisa de una formación, ¿pueden ayudarnos?", answer:"El sistema fue concibido para ser ergonómico y simple a utilizar. Pero, entendemos que una ayuda suplementaria pueda ser útil para las organizaciones que adoptan nuevas tecnologías o que integran nuevos bases de datos. Estamos contentos proveer formacións a distancia o en persona para asistir su equipo en aprender a utilizar la plateforma. El servicio general de los clientes está disponible via chat/dirección electrónica de las 9:00 a las 18:00 (hora de Europa Central), del lunes al viernes. Emalsys se adapta à sus necesidades, siéntese libre escribirnos:", textForLink: ' Contáctenos.', routing: '/auth/demo' }
  // ];

  this.faqPricing = [
    { isShown: false, question: 'Is it free to setup?',                                                             answer: 'The Relief Applications team is happy to help you set up your profile, your organisation and your rosters for free. For any assistance, please feel free to',                     textForLink: ' contact us.', answerBis: ' Feel free also to consult our', textForLinkBis: ' Manager User Guide.', routing: '/public/contact', routingBis: '/user-guide'},
    { isShown: false, question: 'How much does it cost for roster members?',                                        answer: 'The system is completely free members. For organisations willing to use the system see',                                                                                         textForLink: ' pricing.', routing: '/public/pricing'},
    { isShown: false, question: 'How does the Free Trial work?',                                                    answer: 'Each joining organisation is initially given 10.000 credits for free (which corresponds at about 300 credits per month per user, for a total of 10 free members for three months).'},
    { isShown: false, question: 'What happens at the end of the free trial?',                                       answer: 'At the end of the free trial you can upgrade the service (see',                                                                                                                   textForLink: ' pricing',     answerBis: ') or delete your organisation profile. You can continue using the platform for free as a user.', routing: '/public/pricing'},
    { isShown: false, question: 'Do you offer discounts for small organizations which could benefit from EMALSYS?', answer: 'Yes, we try to ensure EMALSYS is available for humanitarian/development organizations of all sizes. For details, please share more information with us about your requirements.', textForLink: ' Contact us.', routing: '/public/contact'}
  ];

  // this.faqPricing['fr'] = [
  //   { isShown: false, question: "L'installation est-elle payante ?",                                                answer: "L'équipe de Relief Applications est heuresue de vous aider à mettre en place votre profil, votre organisation et vos équipes volantes hors frais supplménetaires. Pour toute assistance, vous pouvez :",                     textForLink: ' nous contacter.', answerBis: ' Consultez notre ', textForLinkBis: " manuel d'utilisateur administrateur.", routing: '/auth/demo', routingBis: '/user-guide'},
  //   { isShown: false, question: "Combien cela coûte-t-il pour les membres d'équipe volante?",                       answer: "Le système est complètement gratuit pour les membres d'équipe volante. Les organisations intéressées pour utiliser le système sont conviées à suivre le lien:",                                                                                         textForLink: ' Coûts.', routing: '/auth/pricing'},
  //   { isShown: false, question: "Comment fonctionne la période d'essai gratuite ?",                                 answer: "Toute organisation nouvelle venue reçoit initialement 10.000 crédits gratuits (correspondant à environ 300 crédits par mois par utilisateur, pour un total de 10 membres gratuits pendant trois mois)."},
  //   { isShown: false, question: "Que se passe-t-il à l'issue de la période d'essai ?",                              answer: "A l'issue de la période d'essai, vous pouvez mettre à niveau le service (regardez",                                                                                                                   textForLink: ' Coûts',     answerBis: ') ou supprimer le profil de votre organisation. Vous pouvez continuer à utiliser la plateforme comme utilisateur.', routing: '/auth/pricing'},
  //   { isShown: false, question: "Proposez-vous des promotions aux organisations de petite taille intéressées par EMALSYS ?", answer: 'Oui, nous nous assurons de mettre EMALSYS à la portée des organisations humanitaires/de développement de toutes tailles. Pour plus de détails, partagez les informations sur vos besoins.', textForLink: ' Contactez-nous', routing: '/auth/demo'}
  // ];

  // this.faqPricing['es'] = [
  //   { isShown: false, question: "¿Es la instalación pagante?",                                                      answer: "El equipo de Relief Applications está contento ayudarle a inscribir su perfil, su organización y sus rósters sin pagar más. Por cualquiera asistencia, puede :",                     textForLink: ' contactarnos.', answerBis: ' Consulte nuestro ', textForLinkBis: " manual de usador para administradores.", routing: '/auth/demo', routingBis: '/user-guide'},
  //   { isShown: false, question: "¿Cuánto cuesta para miembros de rósters?",                                         answer: "El sistema es totalmente gratuito para les miembros de rósters. Las organizaciones interesadas por utilizar el sistema son invitadas a seguir el vínculo:",                                                                                         textForLink: ' Coste.', routing: '/auth/pricing'},
  //   { isShown: false, question: "¿Cómo funciona el período de prueba gratuito?",                                    answer: "Toda organización recién inscrita recibe inicialmente 10.000 créditos gratuitos (equivalente de unos 300 créditos por mes per usador, para un total de 10 miembros gratuitos durante tres meses)."},
  //   { isShown: false, question: "¿Qué pasa cuando se caduca el período de prueba?",                                 answer: "Cuando de termina el período de prueba, puede mejorar el servicio (véase",                                                                                                                   textForLink: ' Coste',     answerBis: ') o suprimir el perfil de su organización. Puede ir utilizando la plataforma como usador.', routing: '/auth/pricing'},
  //   { isShown: false, question: "¿Proponen promociones a organizaciones de menor tamaño interesadas por EMALSYS ?", answer: 'Sí, nos aseguramos poner EMALSYS a la dispocisión de organizaciones humanitarias/de desarrollo de todos los tamaños. Para más detalles, compártenos sus necesidades.', textForLink: ' Contáctenos.', routing: '/auth/demo'}
  // ];

  this.faqSecurity = [
    { isShown: false, question: 'Is EMALSYS a secure platform?',                            answer: 'Emalsys is a very secured platform using some of the most advanced security protocols available today:',
      dotList:
    [{dotListText: "SSL and WSSE for the connection identifications"},
     {dotListText: 'Authentication and Password Policies'},
     {dotListText: 'Authorization and Privilege Management'},
     {dotListText: 'Encryption to secure sensitive data'},
     {dotListText: 'Back-up servers for further protection'}],
    textAfterList: 'All access is User Name and Pass code protected, and secured by SSL protocols for secure communications. Most importantly, the platform is developed with Symfony3, "one of the most secured frameworks available on the web".'},
    { isShown: false, question: 'How do you ensure the data is safe and protected?',        answer: 'Our servers are located in France and comply with all relevant French and European data protection laws and regulations.'},
    { isShown: false, question: 'Who can see my personal documents?',                       answer: 'You can either make your documents public (seen by all organisations), or keep it private. Documents required by the roster are just visible by the organization roster you uploaded them for.'},
    { isShown: false, question: 'Can EMALSYS be integrated into the organisation servers?', answer: 'Yes, EMALSYS can be deployed on external servers. We recommend though using the main emalsys.net platform to benefit from all EMALSYS features (shared rosters, access to broader pool of experts, etc.).'},
  ];

  // this.faqSecurity['fr'] = [
  //   { isShown: false, question: 'EMALSYS est-elle une platforme sécurisée?',                            answer: 'Emalsys est une platforme très sécurisée, elle utilise des protocoles de sécurité les plus avancées disponibles à ce jour:',
  //     dotList:
  //   [{dotListText: "SSL et WSSE pour les connections d'identification"},
  //    {dotListText: 'Authentification et Mot de Passe systématiques'},
  //    {dotListText: 'Gestion des authorisations et privilèges'},
  //    {dotListText: 'Encryptage pour sécuriser les données sensibles'},
  //    {dotListText: 'Serveurs de sauvegarde pour une meilleure protection'}],
  //   textAfterList: "Tous les accès sont protégés par un Nom d'Utilisateur et par un Mot de Passe, et sécurisé par des protocoles SSL pour sécuriser les communications. Encore plus important, la plateforme a été développée avec Symphony3: 'un des frameworks les plus sécurisés disponibles sur le net."},
  //   { isShown: false, question: 'Comment vous assurez-vous que les données soient sécurisés et protégés ?',        answer: 'Nos serveurs se trouvent en France et sont conformes à toutes les lois et réglementations françaises et européennes sur les protections des données.'},
  //   { isShown: false, question: 'Qui peut voir mes documents personnels ?',                       answer: "Vous pouvez rendre vos documents publics (vues par toutes les organisations), ou les conserver privés. Les documents requis par l'équipe volante ne seront visibles que par l'équipe volante de l'organisation pour laquelle vous avez chargé vos documents."},
  //   { isShown: false, question: 'EMALSYS peut-il être intégré aux serveurs des organisations ?', answer: "Oui, EMALSYS peut être déployé sur un serveur tiers. Nous recommandons cependant d'utiliser la plateforme principale emalsys.net pour bénéficier de toutes les fonctionnalités d'EMALSYS (équipes volantes partagées, accès à une réserve d'experts, etc.)."},
  // ];

  // this.faqSecurity['es'] = [
  //   { isShown: false, question: '¿Es EMALSYS una plataforma asegurada?',                            answer: 'Emalsys es una platforma muy asegurada, utiliza protócolos de seguridad más avanzada disponibles hoy día:',
  //     dotList:
  //   [{dotListText: "SSL y WSSE para les connexiones de identificatión"},
  //    {dotListText: 'Políticas de Autenticación y Contraseñas'},
  //    {dotListText: 'Gestión de autorizaciones y privilegios'},
  //    {dotListText: 'Encriptado para asegurar los datos sensibles'},
  //    {dotListText: 'Servidores de copia para mayor protección'}],
  //   textAfterList: "Todos los accesos están protegidos por un Nombre de Usador y par una Contraseña, y asegurada por des protócolos SSL para asegurar las comunicaciones. Más importante, la plateforma es desarrollada con Symphony3: 'uno de los frameworks más seguros disponibles en el net."},
  //   { isShown: false, question: '¿Cómo se aseran que los datos sean asegurados y protegidos ?',        answer: 'Nuestros servidores se ubican en Francia y se conforman a todas las leyes y reglementaciones francesas et europeas en cuánto a la protección de los datos.'},
  //   { isShown: false, question: '¿Quién puede ver mis documentos personales ?',                       answer: "Puede poner los documentos en público (vistas por todas las organizaciones), o conservarlas privadas. Los documentos requiridos por el róster estarán visibles sólo por el róster de la organización por la cual cargó sus documentos."},
  //   { isShown: false, question: '¿Puede EMALSYS ser integrado a los servidores de las organisationes ?', answer: "Sí, EMALSYS se puede desplegar en un servidor tercero. Recomendamos aún utilizar la plataforma principal emalsys.net para disfrutar de todas las funcionalidades de EMALSYS (rósters compartidos, acceso a una reserva de expertos, etc.)."},
  // ];

  this.faqAbout = [
    { isShown: false, question: 'Who designed EMALSYS?',                   answer: 'EMALSYS was designed by a French Non-profit NGO for the humanitarian sector.'},
    { isShown: false, question: 'Who developed EMALSYS?',                  answer: 'EMALSYS has been developed and is maintained by', textForLink: ' Relief Applications', answerBis:' a tech organization providing tools for humanitarian and development organizations.', extLink: 'http://reliefapps.org/'},
    { isShown: false, question: 'Didn’t find an answer to your question?', answer: 'Help us to improve this section by', textForLink: 'contacting us.', routing: '/public/contact'},
  ];

  // this.faqAbout['fr'] = [
  //   { isShown: false, question: 'Par qui a été conçu EMALSYS ?',                        answer: 'EMALSYS a été conçu par une ONG à but non lucratif française pour le secteur humanitaire.'},
  //   { isShown: false, question: 'Par qui a été développé EMALSYS ?',                    answer: 'EMALSYS a été développé et est maintenu par', textForLink: ' Relief Applications', answerBis:', une organisation technologique qui fournit des outils pour les organisations humanitaires et de développement.', extLink: 'http://reliefapps.org/'},
  //   { isShown: false, question: "Vous n'avez pas trouvé la réponse à votre question ?", answer: "Contribuez à l'amélioration de cette section", textForLink: 'contactez-nous.', routing: '/auth/demo'},
  // ];

  // this.faqAbout['es'] = [
  //   { isShown: false, question: '¿Por quién fue concebida EMALSYS?',                        answer: 'EMALSYS fue concibido por una ONG sin ánimo de lucro francesa por el sector humanitario.'},
  //   { isShown: false, question: '¿Por quién fue desarrollada EMALSYS?',                     answer: 'EMALSYS fue desarrollado y está mantenido por', textForLink: ' Relief Applications', answerBis:', una organización tecnológica que brinda herramientas por organizaciones humanitarias y de desarrollo.', extLink: 'http://reliefapps.org/'},
  //   { isShown: false, question: "¿No encontró la repuesta a su pregunta?", answer: "Contribuya a mejorar esta sección, ", textForLink: 'contáctenos.', routing: '/auth/demo'},
  // ];

  // French version
  // this.faqOverview['fr'] = [{...}, ...];
  // this.faqUsing['fr'] = [{...}, ...];
  // this.faqBranding['fr'] = [{...}, ...];
  // this.faqCustomisation['fr'] = [{...}, ...];
  // this.faqSupport['fr'] = [{...}, ...];
  // this.faqPricing['fr'] = [{...}, ...];
  // this.faqSecurity['fr'] = [{...}, ...];
  // this.faqAbout['fr'] = [{...}, ...];

  // Spanish version
  // this.faqOverview['es'] = [{...}, ...];
  // this.faqUsing['es'] = [{...}, ...];
  // this.faqBranding['es'] = [{...}, ...];
  // this.faqCustomisation['es'] = [{...}, ...];
  // this.faqSupport['es'] = [{...}, ...];
  // this.faqPricing['es'] = [{...}, ...];
  // this.faqSecurity['es'] = [{...}, ...];
  // this.faqAbout['es'] = [{...}, ...];

}

ngOnInit() {
  window.scrollTo(0, 0);
}

// Toggle text clicking on the questions
public showText(element: any): void {
  if(element.isShown == false) {
    element.isShown = true;
  } else {
    element.isShown = false;
  }
}



}
