<div class="container-fluid" *ngIf="organisation">

  <div class="row line-1">

    <div class="col-md-3 col-sm-4 col-xs-12 logo-org">
      <div class= "org-img section shadow" on-mouseover="showOptions=true;" on-mouseleave="showOptions=false;">
        <img class="logo" [src]="this.organisation.browser_url" onerror='this.src="../../../../assets/images/default_organisation.png"'>
        <div id="organisation-logo" class="change-button" (click)="changeLogo(logoContent)" *ngIf="showOptions">Change Logo</div> <!-- modif -->
        <h2>{{this.organisation.name}}</h2>
      </div>
    </div>

    <div class="col-md-9 col-sm-8 col-xs-12 desc">
      <div class= "description section shadow">
        <div class="edit-button" *ngIf="editingDescription==false" (click)="enableDescriptionEdit()">
            <i class="fa fa-pencil custom-edit-icon"></i>
        </div>
        <h2> Description </h2>
        <br/>
        <div *ngIf='editingDescription==false'>
          <p> {{this.organisation.description || "Pas de description définie..."}}</p>
        </div>
        <div *ngIf='editingDescription==true'>
          <textarea [(ngModel)]="this.organisation.description" [rows]="3" pInputTextarea autoResize='true'></textarea>
          <button class="btn emalsys_button green" type="button" name="button" (click)="saveChanges()">Save</button>
          <br/>
        </div>
      </div>
    </div>

  </div>

  <div class="row line-2">

    <div class="col-md-3 col-sm-4 col-xs-12 my-first-col">
      <div class="organisation-switch section shadow">
        <h2> Organisations managed by you</h2>
        <br/>
        <div *ngFor='let org of managedOrganisations'>
          <button class="list-orga" [ngClass]="{'selected':selectedOrganisation(org)}"  (click)='setOrganisation(org);'>
            <img class="orga-logo" [src]='org.browser_url' onerror='this.src="../../../../assets/images/default_organisation.png"'/>
            {{org.name}}
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-9 col-sm-8 col-xs-12 container-responsive">
      <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-second-col">
        <div class="shadow section">
        <h2> Organisation information </h2>
        <br/>
          <div class="organisation-properties" >

              <label for="organisation-name">Organisation name </label>
              <input id="organisation-name" class="form-control" type="text" [(ngModel)]="organisation.name" disabled="true"/>
              <label for="contact-email">Contact email *</label>
              <input id="contact-email" class="form-control" type="email" [(ngModel)]="profile.contact_email" />
              <label for="contact-number">Contact number</label>
              <input id="contact-number" class="form-control" type="text" [(ngModel)]="profile.contact_number" />
              <label for="organisation-website">Website</label>
              <input id="organisation-website" class="form-control" type="text" [(ngModel)]="organisation.website" />
              <label for="organisation-facebook">Facebook</label>
              <input id="organisation-facebook" class="form-control" type="text" [(ngModel)]="organisation.facebook" />
              <label for="organisation-twitter">Twitter</label>
              <input id="organisation-twitter" class="form-control" type="text" [(ngModel)]="organisation.twitter" />
              <label for="organisation-linkedin">LinkedIn</label>
              <input id="organisation-linkedin" class="form-control" type="text" [(ngModel)]="organisation.linked_in" />

              <div class="validation">
                  <button [disabled]="!profile.email || loading" type="submit" class="btn emalsys_button green" (click)="editOrganisation();">Save</button>
              </div>

          </div>
        </div>
      </div>

      <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 my-third-col">
      <div class="container-components-third-col">
        <div class="organisation-properties section shadow">
          <h2> Organisation compliances </h2>
          <br/>
          <div id="organisation-compliances">
            <table class="compliances-table">

              <tr class="table-head c-titles">
                <th>
                  <h3>Title</h3></th>
                <th>
                  <h3>Level</h3></th>
                <th class="header-edit-delete-create">
                  <button *ngIf="!addActive && editActive==null" class="add-button" title="Add compliance" (click)="addActive = true">
                    <div class="add-compliance">
                      <i class="fa fa-plus custom-add-icon"></i>
                    </div>
                  </button>
                </th>
              </tr>

              <tbody *ngIf="addActive">
                <tr class="c-infos">
                  <td>
                    <input class="compliance-name" type="text" [(ngModel)]="newTitleRequirement">
                  </td>
                  <td >
                    <select [(ngModel)]="newLevelRequirement" value="0">
                          <option value="0">Optional</option>
                          <option value="1">Required</option>
                        </select>
                  </td>
                </tr>
                <tr class="edit-delete">
                  <div class="c-actions">
                    <button class="btn emalsys_button green margin-r " [disabled]="!newTitleRequirement && !newLevelRequirement"(click)="addOrganisationCompliance(newTitleRequirement,newLevelRequirement)">Add</button>
                    <button class="btn emalsys_button red margin-r " (click)="addActive=false;newTitleRequirement=null;newLevelRequirement=null">Cancel</button>
                  </div>
                </tr>
              </tbody>

              <tbody *ngIf="!addActive" >
                <tr *ngFor='let compliance of organisationCompliances' class="compliances-ligns">
                  <td>
                    <p *ngIf="editActive==null">{{compliance.title}}</p>
                    <div *ngIf="editActive==compliance.id" class="compliances-edit">
                      <input class="compliance-name"  type="text" [(ngModel)]="editTitleRequirement">
                      <div class="button-group new-actions">
                        <button class="btn emalsys_button green margin-r"  (click)="editActive=null;editOrganisationCompliance(editTitleRequirement,editLevelRequirement,compliance.id);">Save</button>
                        <button class="btn emalsys_button red " (click)="editActive=null;editTitleRequirement=null;editLevelRequirement=null">Cancel</button>
                      </div>
                    </div>
                  </td>
                  <td [ngClass]="{'custom-td' : editActive==compliance.id}">
                    <p *ngIf="editActive==null">{{setLevel(compliance)}}</p>
                    <div *ngIf="editActive==compliance.id" class="compliances-edit">
                      <select [(ngModel)]="editLevelRequirement">
                          <option value=0>Optionnal</option>
                          <option value=1>Required</option>
                      </select>
                    </div>
                  </td>
                  <td class="edit-delete">
                    <button *ngIf="editActive==null" class="btn emalsys_button green margin-r "  (click)="editActive=compliance.id;editTitleRequirement=compliance.title;editLevelRequirement=compliance.level">Edit</button>
                    <button *ngIf="editActive==null" class="btn emalsys_button red margin-r " (click)="deleteOrganisationCompliance(compliance.id)">Delete</button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="loading" *ngIf="loading">
              <spinner-component></spinner-component>
          </div>
          
          <div class="validation">
              <button [disabled]="!profile.contact_email || loading" type="submit" class="btn emalsys_button green" (click)="editOrganisation();">Save</button>
          </div>
        </div>

        <!-- DELETE ORGANISATION -->
        <div class="deleting section shadow" *ngIf="canDeleteOrganisation"> 
            <h2> Delete the organisation </h2>
            <br/>
            <button type="submit" class="btn emalsys_button red" (click)="open(deleteContent)">Delete organisation</button>
        </div>
      </div>

      </div>
    </div>
  </div>
</div>

<ng-template #logoContent class="change-logo-content" let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h1> Change your organisation's logo</h1>
    <button type="button" class="close" aria-label="Close" (click)="logo=null; d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p> Select an image from your files :</p>
    <input id="organisation-logo"  type="file" (change)="fileChange($event)" />
    <img class="roster-image image-custom" [src]="thumbnailLogo ? thumbnailLogo : this.organisation.browser_url" onerror='this.src="assets/images/default_organisation.png"'>
    <br/>
    <p>Please use a square logo otherwise it may be cropped</p>
  </div>
  <div class="modal-footer">
    <button class="btn emalsys_button green" (click)="c()"> Confirm </button>
  </div>
</ng-template>

<!-- Modal to confirm organisation delete -->
<ng-template #deleteContent class="delete-modal-content" let-c="close" let-d="dismiss">
    <div class = "modal-header">
      <h1> Do you really wish to delete {{organisation.name}} ?</h1>
        <button type="button" class="close" aria-label="Close" (click)="c('cancel-click')">
          <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
    </div>
    <div class = "modal-body">
      <p> All members will be deleted from it and you will loose the organisation's information irredeemably.</p>
    </div>
    <div class = "modal-footer">
      <div class="validation">
        <button class="btn emalsys_button red" (click)="c('cancel-click')"> NO </button>
        <button class="btn emalsys_button green" (click)="c('delete-click')"> YES </button>
      </div>
    </div>
</ng-template>

<div id="snackbarNotif" i18n="@@notificationToast">Changes have been saved</div>
<div id="snackbarNotifCancel" i18n="@@notificationCancelToast">Deletion canceled</div>
<div id="snackbarNotifError">Failed to edit organisation</div>
