import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alerts-preloader-map',
  templateUrl: './alerts-preloader-map.component.html',
  styleUrls: ['./alerts-preloader-map.component.scss']
})
export class AlertsPreloaderMapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
