import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng2-select2';

import { LATLONG } from '../../../../../../data/countries';
import { AlertService } from '../../../../../../services/api/alert/alert.api';
import { UserEventService } from '../../../../../../services/api/userevent.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { Alert } from '../../../../../model/alert.interface';
import { ToastService } from '../../../../../../services/utils/toast.service';
import { RostersDispatcherService } from '../../../../../../services/api/roster/rosters.dispatcher';
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  public events: any;
  public step: number;
  public countries: any[];
  public option: boolean;

  public selectedEvent: any;
  public createdEvent = {
    name: null,
    country: null,
    startDate: null,
    endDate: null
  };


  public notificationTitle: any;
  public notificationBody: any;

  public loading = false;
  public rosterId;

  @Input() profile;
  @Input() organisation;
  @Input() usersId;
  @Output() onSend = new EventEmitter<any>();
  constructor(
    public activeModal: NgbActiveModal,
    public _rightService: RightService,
    public _alertService: AlertService,
    public _usereventService: UserEventService,
    public _loggerService: LoggerService,
    public _toasterService: ToastService,
    public _rosterDispatcher: RostersDispatcherService
  ) { }

  ngOnInit() {
    this.rosterId = this._rosterDispatcher.getSelectedRosterAsScalar().id;
    this.step = 1;
    this.option = false;
    this.getCountries();
    this.getEvents();
  }

  getEvents() {
    this._alertService.getOrgaAlerts(this.organisation.id).subscribe(res => {
      this.events = res;
      if (this.events && this.events.length > 0) {
        this.selectedEvent = this.events[0];
      }

    });
  }

  getCountries() {
    this.countries = [];
    for (let i = 0; i < LATLONG.length; i++) {
      this.countries.push(
        {
          id: LATLONG[i]['country_code'],
          text: LATLONG[i]['name']
        }
      );
    }
  }

  onClickOption() {
    this.option = !this.option;
    this.selectedEvent = null;
    for (let key in this.createdEvent) {
      if (key) {
        this.createdEvent[key] = null;
      }
    }
  }

  missingEvent() {
    let missingParam = false;
    for (let key in this.createdEvent) {
      if (!this.createdEvent[key]) {
        missingParam = true;
      }
    }
    if (!this.selectedEvent && missingParam) {
      return true;
    } else {
      return false;
    }
  }


  nextStep() {
    this.step = this.step + 1;
  }

  previousStep() {
    this.step = this.step - 1;
  }

  createAlerts() {
    this._loggerService.log(this.selectedEvent.id);
    let body = new FormData();
    body.append('users', JSON.stringify(this.usersId));
    body.append('event_id', this.selectedEvent.id);
    body.append('roster', this.rosterId);
    body.append('msg_title', this.notificationTitle);
    body.append('msg_content', this.notificationBody);
    this.loading = true;
    this._usereventService.createAlert(body).subscribe(res => {
      this.onSend.emit("ALERT CREATED");
      this.loading = false;
      this.activeModal.close();
    }, error => {
      this.loading = false;
      this._toasterService.show('createAlertToast', 'show');
    });
  }

  createEvents() {
    let alert = Alert.from({
      'organisation': this.organisation.id,
      'name': this.createdEvent.name,
      'country': this.createdEvent.country,
      'startDate': this.createdEvent.startDate,
      'endDate': this.createdEvent.endDate,
    });

    let body = alert.toFormData(['organisation', 'name', 'country', 'startDate', 'endDate']);

    this.loading = true;
    this._alertService.createEvent(body).subscribe(res => {
      this.selectedEvent = res;
      this._loggerService.log("EVENT CREATED");
      this.createAlerts();
    }, error => {
      this.loading = false;
      this._toasterService.show('createEventToast', 'show');
    });
  }

  sendAlert() {
    if (!this.selectedEvent) {
      this.createEvents();
    } else {
      this.createAlerts();
    }
  }
  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  checkRosterManager(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  changedSelectEvent(data) {
    this.selectedEvent = data.value;
  }

  changedSelectCountry(data) {
    this.createdEvent.country = data.value;
  }
}
