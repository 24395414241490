import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LATLONG } from '../../../../../data/countries';
// Services
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/utils/toast.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { DeploymentService } from '../../../../../services/api/deployment.service';
// Data
import { OLD_DEPLOYMENT_CODE } from '../../../../app.constants';
import { CacheService } from '../../../../../services/storage/cache.service';
import { RightService } from '../../../../../services/utils/right.service';
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';

@Component({
  selector: 'profile-general',
  templateUrl: './profile-general.component.html',
  styleUrls: ['./profile-general.component.scss']
})


export class ProfileGeneralComponent implements OnInit {

  /**
   * Profile holds the data for the profile related user
   */
  // profile: any;
  profileChange: EventEmitter<any> = new EventEmitter;

  @Input() profile: any;
  @Input() isExternal = false;
  public countries: any[];

  public tab = 'info';
  public map: any;
  public currentDeployments: any;
  public oldDeployments: any;
  public upcomingDeployments: any;
  public externalDeployments: any;
  public allOldDeployments: any;
  public editEnabled = false;
  public loading = false;
  public modalRef;
  public amIManager: boolean;

  // profile editting variables
  public firstname: string;
  public lastname: string;
  public gender: string;
  public email: string;
  public birthdate: Date;
  // public yearRange: string;
  public address: string;
  public zip: string;
  public city: string;
  public country: string;
  public website: string;
  public facebook: string;
  public twitter: string;
  public linkedin: string;
  public skype: string;
  public formatted: string;

  // adding new deployment Variables
  public addDeployModal: NgbActiveModal;
  // public deployDate: Date;
  // public deployEndDate: Date;
  public deployId: number;
  public position: string;
  public deployCountry: string;

  // The dates here are for old deployments
  public maxDate = new Date();
  // The range is from {{currentYear}} - 80 to today
  public minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 80));
  // public deployDateRange = new Array<Date>();
  // with the empty array the component throws an error
  public deployDateRange = null;
  public yearRange = (new Date().getFullYear() - 71).toString() + ":" + (new Date().getFullYear()).toString();


  constructor(
    private _deploymentService: DeploymentService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _loggerService: LoggerService,
    private _profileService: ProfileService,
    public _cacheService: CacheService,
    private _rightService: RightService,
    private _organisationService: OrganisationApiService,
    private _profileDispatcher: ProfileDispatcherService
  ) { }

  ngOnInit() {
    this.getCountries();
    this.isExternal = this.profile !== undefined;
    // this.initVariables();
    if (!this.isExternal) {
      this._profileDispatcher.getProfile().subscribe(res => {
        this.profile = res;
        this._loggerService.log("PROFILE : ", res);
        this.editEnabled = false;
        this.initVariables();
        this.getDeployments();
      }, err => {
        this._loggerService.error("ERROR getting profile: ", err);
      });

    } else {
      this.initVariables();
      this.getDeploymentsOtherUser();

    }

  }

  initVariables() {
    this.firstname = this.profile.firstname;
    this.lastname = this.profile.lastname;
    this.gender = this.profile.profile.gender;
    this.email = this.profile.profile.contact_email;
    this.birthdate = new Date(this.profile.profile.birthday);
    this.formatted = this.birthdate.toDateString();
    this._loggerService.log(this.profile.profile);
    if (this.profile.profile.profile_address) {
      this.address = this.profile.profile.profile_address.address;
      this.zip = this.profile.profile.profile_address.zip_code;
      this.city = this.profile.profile.profile_address.city;
      this.country = this.profile.profile.profile_address.country;
    }
    this.facebook = this.profile.profile.facebook;
    this.twitter = this.profile.profile.twitter;
    this.website = this.profile.profile.website;
    this.skype = this.profile.profile.skype;
    this.linkedin = this.profile.profile.linked_in;
    // Range of years. It's meant for old deployments
    this.yearRange = (new Date().getFullYear() - 71).toString() + ":" + (new Date().getFullYear()).toString();

    this.maxDate.setDate(this.maxDate.getDate() - 1);
  }

  getCountries() {
    this.countries = [];
    for (let i = 0; i < LATLONG.length; i++) {
      this.countries.push(
        {
          id: LATLONG[i]['country_code'],
          text: LATLONG[i]['name']
        }
      );
    }
  }

  resetDeployVariables() {
    this.position = null;
    this.deployCountry = null;
    this.deployId = null;
  }


  showAddDeployModal(content) {
    this.resetDeployVariables();
    this.modalRef = this.addDeployModal = this._modalService.open(content, { windowClass: 'emalsys-modal', size: 'lg', backdrop: 'static' });
  }

  showEditDeployModal(content, oldDeployment) {
    this.modalRef = this.addDeployModal = this._modalService.open(content, { windowClass: 'emalsys-modal', size: 'lg', backdrop: 'static' });
    this.deployDateRange = new Array<Date>();
    this.deployDateRange.push(new Date(oldDeployment.start_date));
    this.deployDateRange.push(new Date(oldDeployment.end_date));
    this.deployCountry = oldDeployment.country;
    this.position = oldDeployment.title;
    this.deployId = oldDeployment.id;


  }

  getExternalDeployments() {
    this._deploymentService.getExternalDeployments().subscribe(res => {
      this.externalDeployments = res;
      this._loggerService.log(this.externalDeployments);
      this.getAllOldDeployments();
    });
  }

  getExternalDeploymentsOtherUser() {
    this._deploymentService.getExternalDeploymentsOfUser(this.profile.id).subscribe(res => {
      this.externalDeployments = res;
      this._loggerService.log("External deployments of other user:", res);
      this.getAllOldDeployments();
    });
  }

  changedSelectCountry(data) {
    this.deployCountry = data.value;
  }

  getAllOldDeployments() {
    this.allOldDeployments = [];
    for (let i = 0; i < this.oldDeployments.length; i++) {
      let item = {
        deployment: this.oldDeployments[i],
        date: new Date(this.oldDeployments[i].deploymentdate),
        type: 1
      };
      this.allOldDeployments.push(item);
    }
    for (let i = 0; i < this.externalDeployments.length; i++) {
      let item = {
        deployment: this.externalDeployments[i],
        date: new Date(this.externalDeployments[i].start_date),
        type: 2
      };
      this.allOldDeployments.push(item);

    }
    this.allOldDeployments.sort(function (a, b) {
      if (a.date > b.date) {
        return 1;
      } else if (a.date < b.date) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  setTypeOldDeployment(deployment) {
    return OLD_DEPLOYMENT_CODE.filter(type => type.value === deployment.type)[0].label;
  }


  // Checks if user actually selected two dates on the range
  // If he didn't, display a toast and clear the calendar
  isDateRangeCorrect() {
    return this.deployDateRange[0] !== null && this.deployDateRange[1] !== null;
  }

  /**
   * This method adds 1 to the day number. The calendar actually
   * considers the day before the selected one.
   */
  setCorrectDates() {
    this.deployDateRange[0].setDate(this.deployDateRange[0].getDate());
    this.deployDateRange[1].setDate(this.deployDateRange[1].getDate());
  }

  addExternalDeployment() {
    // QUESTION: see modal template
    if (!this.isDateRangeCorrect()) {
      this.deployDateRange = new Array<Date>(); // clear calendar? checkcheck.
      this._toastService.show("date-not-correct", "show");
      return;
    }

    this.loading = true;
    this.setCorrectDates();

    let newDeployment = {
      startDate: this.deployDateRange[0],
      endDate: this.deployDateRange[1],
      title: this.position,
      country: this.deployCountry
      //   message: this.message,
      //   contactName: this.contactName,
      //   contactEmail: this.contactEmail,
      //   flightNo: this.flightNo,
      //   airport: this.airport,
      //   insuranceName: this.insuranceName,
      //   insuranceNo: this.insuranceNo
    };

    this._deploymentService.addExternalDeployment(newDeployment).subscribe(res => {
      this._loggerService.log("New Deployment added");
      this.loading = false;
      this.getDeployments();
      this.resetDeployVariables();
      this.modalRef.close();
      this.showToastDeployAdded();
    }, err => {
      this.showToastDeployError();
    });
  }

  editExternalDeployment() {
    // QUESTION: see modal template
    if (!this.isDateRangeCorrect()) {
      return;
    }

    this.loading = true;
    this.setCorrectDates();
    let newDeployment = {
      startDate: this.deployDateRange[0],
      endDate: this.deployDateRange[1],
      title: this.position,
      country: this.deployCountry
      //   message: this.message,
      //   contactName: this.contactName,
      //   contactEmail: this.contactEmail,
      //   flightNo: this.flightNo,
      //   airport: this.airport,
      //   insuranceName: this.insuranceName,
      //   insuranceNo: this.insuranceNo
    };
    // revert it to null for the Add Deploy modal mode
    this.deployDateRange = null;
    this._deploymentService.editExternalDeployment(this.deployId, newDeployment).subscribe(res => {
      this.loading = false;
      this.getDeployments();
      this.resetDeployVariables();
      this.modalRef.close();
      this.showToastDeployUpdated();
    }, err => {
      this.showToastDeployError();
    });
  }

  deleteExternalDeployment(deployId) {
    this._deploymentService.deleteExternalDeployment(deployId).subscribe(res => {
      this.showToastDeployDeleted();
      this.getDeployments();
    }, err => {
      this.showToastDeployError();
    });
  }

  setTab(tab: string) {
    this.tab = tab;
  }

  enableEdit() {
    this.editEnabled = true;
  }

  disableEdit() {
    this.editEnabled = false;
  }

  getDeployments() {
    this._deploymentService.getDeployments().subscribe(res => {
      const today = new Date();
      let deployments = res;
      let actualCurrentDeployments = res.current_deployments.filter(deployment => new Date(deployment.end_date) >= today);
      this.currentDeployments = actualCurrentDeployments;

      // this.currentDeployments = deployments.current_deployments;
      this.oldDeployments = deployments.old_deployments;
      const otherOldDeployments = res.current_deployments.filter(deployment => new Date(deployment.end_date) < today);
      this.oldDeployments = this.oldDeployments.concat(otherOldDeployments);

      this.upcomingDeployments = deployments.upcoming_deployments;
      this.getExternalDeployments();
    });
  }

  /**
   * Get deployments of the user that is being viewed.
   * Used when a manager is viewing an other profile
   */
  getDeploymentsOtherUser() {
    this._deploymentService.getDeploymentOrdersByUsers(this.profile.id).subscribe((res: any) => {
      let deployments = res[Object.keys(res)[0]];
      this.currentDeployments = deployments.current_deployments;
      this.oldDeployments = deployments.old_deployments;
      this.upcomingDeployments = deployments.upcoming_deployments;
      this.getExternalDeploymentsOtherUser();
      // this.getExternalDeployments();
    });
  }

  getProfile() {
    if (this.profile) {
      // if the profile 'got there by @Input()'...
      return;
    }

  }

  saveChanges() {
    let date = new Date(this.birthdate);
    date.setDate(date.getDate() + 1);
    let profile = {
      firstname: this.firstname,
      lastname: this.lastname,
      profile: {

        gender: this.gender,
        birthday: date.toISOString(),
        contact_email: this.email,
        facebook:
          // 'http:\/\/www.facebook.com\/' +
          this.facebook,
        twitter:
          // 'http:\/\/www.twitter.com\/' +
          this.twitter,
        linked_in:
          // 'http:\/\/www.linkedIn.com\/' +
          this.linkedin,
        website:
          // 'http:\/\/www.' +
          this.website,
        skype: this.skype
      },
      address: {
        address: this.address,
        zip_code: this.zip,
        city: this.city,
        country: this.country
      }
    };
    // this.profileChange.emit(profile);
    this._profileService.updateProfile(profile)
      .subscribe(res => {
        this._loggerService.log("User information updated");
        // this.getProfile();
        this.showToastUpdated();
        document.getElementById("test").innerHTML = this.firstname;
      }, err => {
        this.showToastUpdatedError();
        this._loggerService.error("User information update error: ", err);
      });
  }

  showToastUpdated() {
    this._toastService.show("generalInfoUpdateToast", "show", 3000);
  }

  showToastUpdatedError() {
    this._toastService.show("generalInfoUpdateErrorToast", "show", 3000);
  }

  showToastDeployAdded() {
    this._toastService.show("externalAddedToast", "show", 3000);
  }
  showToastDeployUpdated() {
    this._toastService.show("externalUpdatedToast", "show", 3000);
  }

  showToastDeployDeleted() {
    this._toastService.show("externalDeletedToast", "show", 3000);
  }

  showToastDeployError() {
    this._toastService.show("externalErrorToast", "show", 3000);
  }
}
