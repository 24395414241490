/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preloader-profile-phones.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preloader-profile-phones.component";
var styles_PreloaderProfilePhonesComponent = [i0.styles];
var RenderType_PreloaderProfilePhonesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreloaderProfilePhonesComponent, data: {} });
export { RenderType_PreloaderProfilePhonesComponent as RenderType_PreloaderProfilePhonesComponent };
export function View_PreloaderProfilePhonesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "profile-preloader-phones shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "profile-preloader-phones-big-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "profile-preloader-phones-small-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "profile-preloader-phones-small-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "profile-preloader-phones-small-line"]], null, null, null, null, null))], null, null); }
export function View_PreloaderProfilePhonesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preloader-profile-phones", [], null, null, null, View_PreloaderProfilePhonesComponent_0, RenderType_PreloaderProfilePhonesComponent)), i1.ɵdid(1, 114688, null, 0, i2.PreloaderProfilePhonesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreloaderProfilePhonesComponentNgFactory = i1.ɵccf("preloader-profile-phones", i2.PreloaderProfilePhonesComponent, View_PreloaderProfilePhonesComponent_Host_0, {}, {}, []);
export { PreloaderProfilePhonesComponentNgFactory as PreloaderProfilePhonesComponentNgFactory };
