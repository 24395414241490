import { UserInterface } from '../../../app/model/user.interface';
import { AlertInterface } from '../../../app/model/alert.interface';
import { MembersDispatcherService } from '../member/members.dispatcher';
import { AlertDispatcherService } from '../alert/alert.dispatcher';
import { Pipe, Injectable } from '@angular/core';
import { UserEventService } from '../userevent.service';
import { ApiCacherService } from '../../utils/api-cacher.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class CachedUserEventService {

    private readonly EVENT_USERS = "events_user_";

    constructor(
        private _apiCacher: ApiCacherService,
        private _usereventService: UserEventService,
        private _alertsDispatcher: AlertDispatcherService,
        private _memberDispatcher: MembersDispatcherService
    ) {}


    public getUserEvents(organisationId: number, filters) {
        return this._apiCacher.doWrapper(
            this.EVENT_USERS,
            () => {
                return this._usereventService.getUserEvents(organisationId, filters);
            }
        ).pipe(
            tap( (alertMembers: UserInterface[]) => this._memberDispatcher.publish(alertMembers))
        );
    }
}
