<div id="dashboard-search-component" [formGroup]="searchForm">
  <div class="search-component-search">
    <div class="filter-container">
      <i class="fa fa-search custom-icon" aria-hidden="true"></i>
      <input class="search-input" placeholder="Filter members of the organisation by email or name" formControlName="query"/>
    </div>
  </div>

  <div class="search-component-more">
    <div *ngIf="page=='alerts'" class="filter">
      <label for="select-rosters">Rosters: </label>
      <ngx-select class="search-component-select" [items]="rosters" formControlName="rosters"></ngx-select>
    </div>
    <div class="filter">
      <label for="select-skills">Skills: </label>
      <ngx-select class="search-component-select" [items]="skills" [multiple]="true" [autoClearSearch]="true" formControlName="skills"></ngx-select>
    </div>
    <div *ngIf="selectedRoster?.id !== -1" class="filter">
      <label for="select-tags">Tags: </label>
      <ngx-select class="search-component-select" [items]="tags" [multiple]="true" [autoClearSearch]="true" formControlName="tags"></ngx-select>
    </div>
    <div class="filter">
      <label for="select-languages">Languages: </label>
      <ngx-select class="search-component-select" [items]="languages" [multiple]="true" [autoClearSearch]="true" formControlName="languages"></ngx-select>
    </div>
  </div>
</div>