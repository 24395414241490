import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-preloader-rosters',
  templateUrl: './dashboard-preloader-rosters.component.html',
  styleUrls: ['./dashboard-preloader-rosters.component.scss']
})
export class DashboardPreloaderRostersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
