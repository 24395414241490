import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

// Services
import { HttpService } from './http.service';
import { LoggerService } from '../utils/logger.service';

import { URL_API_EMALSYS } from '../../environments/environment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProfileModalComponent } from '../../app/modules/shared/modals/profile/profile-modal.component';
import { UserInterface } from '../../app/model/user.interface';
import { UrlHandlingStrategy } from '@angular/router';

@Injectable()


export class UserService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService,
    private _loggerService: LoggerService,
    private _modalService: NgbModal
  ) { }

  getUser(id: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id, options);
  }

  getRostersByOrganisation(id: number, orgaId: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id + "/rosters?organisation=" + orgaId, options);
  }

  getLanguages(id: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id + "/languages", options);
  }

  getSkills(id: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id + "/skills", options);
  }

  getTags(id: number, rosterId: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id + "/tags?roster=" + rosterId, options);
  }

  getDocuments(id: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/users/" + id + "/docs", options);
  }

  setLocation(position) {
    let body = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    };
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/me/location", body, options);
  }


  /**
   *
   * @param member user
   * @param organisationId
   * @param rosterId
   */
  openProfile(member: any, organisationId: number, rosterId: number = null) {
    let id = member.id;

    let modalRef = this._modalService.open(ProfileModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.selectedRosterId = rosterId;
    // let user = {};
    this.getUser(id).subscribe(res => {
      modalRef.componentInstance.member = res;
    });

    let rosters = [];
    this.getRostersByOrganisation(id, organisationId).subscribe(res => {
      // I would really love to use roster_member directly,
      // but it doesn't have the roster image url :(
      let memberRoster = member.roster_members.map(x => x.roster.id);
      rosters = res['member'].filter(x => !(x.id in memberRoster));
      modalRef.componentInstance.rosters = rosters;

      // all this because i need roster icons
      let rosterMemberTags = member.roster_members
        .filter(x => x.roster.organisation !== null && x.roster.organisation.id === organisationId)
        .map(x => {
          return {tags: x.roster_member_tags, roster: rosters.find(r => r.id === x.roster.id)};
        });

      modalRef.componentInstance.rosterMemberTags = rosterMemberTags;

    });

    this.getLanguages(id).subscribe(res => {
      modalRef.componentInstance.languages = res;
    });

    this.getSkills(id).subscribe(res => {
      modalRef.componentInstance.skills = res;
    });

    this.getDocuments(id).subscribe(res => {
      modalRef.componentInstance.documents = res;
    });
  }

  enableUser(token) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/me/enable_account", { token: token }, options);
  }

  updatePassword(saltedPassword) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/me/password/change", { password: saltedPassword }, options);
  }

}
