/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-details.component";
import * as i3 from "../../services/indicator.service";
import * as i4 from "../../../../../services/storage/cache.service";
var styles_ModalDetailsComponent = [i0.styles];
var RenderType_ModalDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalDetailsComponent, data: {} });
export { RenderType_ModalDetailsComponent as RenderType_ModalDetailsComponent };
export function View_ModalDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["aria-labelledby", "myModalLabel"], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "myModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fermer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fermer"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.modalId, "details"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.modalTitle; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.modalComputedMethod; _ck(_v, 10, 0, currVal_2); }); }
export function View_ModalDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-details", [], null, null, null, View_ModalDetailsComponent_0, RenderType_ModalDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalDetailsComponent, [i3.IndicatorService, i4.CacheService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalDetailsComponentNgFactory = i1.ɵccf("modal-details", i2.ModalDetailsComponent, View_ModalDetailsComponent_Host_0, { modalId: "modalId", modalTitle: "modalTitle", modalComputedMethod: "modalComputedMethod", modalData: "modalData" }, { clearAction: "clearAction" }, []);
export { ModalDetailsComponentNgFactory as ModalDetailsComponentNgFactory };
