import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInterface } from '../../../../../app/model/user.interface';
import { URL_API_EMALSYS } from '../../../../../environments/environment';
import { HttpService } from '../../../http.service';

@Injectable()
export class OrganisationMembershipService {
  constructor(
    private http: HttpClient,
    private _httpService: HttpService,
  ) { }

  getOrganisationMembers(orgaId, filters) {
    let options = this._httpService.createRequestOptions();

    let url = URL_API_EMALSYS + "/organisations/" + orgaId + "/members";
    for (let key in filters) {
      if (filters.hasOwnProperty(key) && filters[key] !== "" && filters[key] !== null) {
        url = url + (/\?/.test(url) ? "&" : "?") + key + "=" + filters[key];
      }
    }
    return this.http.get<UserInterface[]>(url, options);
  }

  addMembers(orgaId, params) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/invite/members", params, options);
  }

  acceptOrganisationMemberInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/accept/member", {}, options);
  }

  declineOrganisationMemberInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/decline/member", options);
  }

  // =====================Organisation Manager invitations=====================//
  addManagers(orgaId, params) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/invite/managers", params, options);
  }

  acceptOrganisationManagerInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/accept/manager", {}, options);
  }

  declineOrganisationManagerInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/decline/manager", options);
  }

  // =====================Organisation Manager abilities=====================//

  freezeOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/freeze/member?user_id=" + userId, {}, options);
  }

  activateOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/active/member?user_id=" + userId, {}, options);
  }

  deleteOrganisationManager(orgaId, userId, context) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/remove/manager?user_id=" + userId + "&context=" + context, options);
  }

  deleteOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/remove/member?user_id=" + userId, options);
  }

  setActiveOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/promote?user_id=" + userId, {}, options);
  }

  setAssociateRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/guest?user_id=" + userId, {}, options);
  }

  setManagerRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/manager?user_id=" + userId, {}, options);
  }

  setAdminRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/admin?user_id=" + userId, {}, options);
  }
}
