<div id="faq-component">
  <div class="big-title">
    <h2 class="text-center custom-title" >FAQ</h2>
  </div>


<!--OVERVIEW-->
      <p class="faq-title">
        {{ faqOverviewTitle }}
      </p>
    <div class="faq faqOverview">
      <div *ngFor="let faq of faqOverview">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
        </p>
      </div>
    </div>
<!--USING EMALSYS-->
      <p class="faq-title" >
        {{ faqUsingTitle }}
      </p>
    <div class="faq faqUsing">
      <div *ngFor="let faq of faqUsing">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink && faq.routing">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span *ngIf="faq.extLink">
            <a href='{{ faq.extLink }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
        </p>
        <ul *ngIf="faq.dotList && faq.isShown">
          <li *ngFor="let dotText of faq.dotList" >
            {{ dotText.dotListText }}
          </li>
        </ul>
        <p *ngIf="faq.textAfterList && faq.isShown" >
          {{ faq.textAfterList }}
          <span *ngIf="faq.textForLinkBis">
            <a routerLink='{{ faq.routingBis }}' >{{ faq.textForLinkBis }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerTer" >
            {{ faq.answerTer }}
          </span>
        </p>
      </div>
    </div>
<!--BRANDING-->
      <p class="faq-title" >
        {{ faqBrandingTitle }}
      </p>
    <div class="faq faqBranding">
      <div *ngFor="let faq of faqBranding">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
        </p>
      </div>
    </div>
<!--CUSTOMISATION-->
      <p class="faq-title" >
        {{ faqCustomisationTitle }}
      </p>
    <div class="faq faqCustomisation">
      <div *ngFor="let faq of faqCustomisation">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
          <span *ngIf="faq.textForLinkBis">
            <a routerLink='{{ faq.routingBis }}' >{{ faq.textForLinkBis }}</a>
          </span>
        </p>
      </div>
    </div>
<!--SUPPORT-->
      <p class="faq-title" >
        {{ faqSupportTitle }}
      </p>
    <div class="faq faqSupport">
      <div *ngFor="let faq of faqSupport">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown">
          {{ faq.answer }}
          <span *ngIf="faq.textForLink">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
        </p>
      </div>
    </div>
<!--PRICING-->
      <p class="faq-title" >
        {{ faqPricingTitle }}
      </p>
    <div class="faq faqPricing">
      <div *ngFor="let faq of faqPricing">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
          <span *ngIf="faq.textForLinkBis">
            <a routerLink='{{ faq.routingBis }}' >{{ faq.textForLinkBis }}</a>
          </span>
        </p>
      </div>
    </div>
<!--SECURITY-->
      <p class="faq-title" >
        {{ faqSecurityTitle }}
      </p>
    <div class="faq faqSecurity">
      <div *ngFor="let faq of faqSecurity">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
        </p>
        <ul *ngIf="faq.dotList && faq.isShown">
          <li *ngFor="let dotText of faq.dotList" >
            {{ dotText.dotListText }}
          </li>
        </ul>
        <p class="faq-answer" *ngIf="faq.textAfterList && faq.isShown" >
          {{ faq.textAfterList }}
        </p>
      </div>
    </div>
<!--ABOUT-->
      <p class="faq-title" >
        {{ faqAboutTitle }}
      </p>
    <div class="faq faqAbout">
      <div *ngFor="let faq of faqAbout">
        <p class="faq-question" (click)="showText(faq)" >
          <i *ngIf="!faq.isShown" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="faq.isShown" class="fa fa-chevron-down" aria-hidden="true"></i>
          {{ faq.question }}
        </p>
        <p class="faq-answer" *ngIf="faq.isShown" >
          {{ faq.answer }}
          <span *ngIf="faq.textForLink && faq.routing">
            <a routerLink='{{ faq.routing }}' >{{ faq.textForLink }}</a>
          </span>
          <span *ngIf="faq.extLink">
            <a href='{{ faq.extLink }}' >{{ faq.textForLink }}</a>
          </span>
          <span class="faq-answer" *ngIf="faq.answerBis" >
            {{ faq.answerBis }}
          </span>
        </p>
      </div>
    </div>

</div>
