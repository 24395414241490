/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/spinner/spinner.component.ngfactory";
import * as i3 from "../../shared/spinner/spinner.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./confirmation-user.component";
import * as i7 from "../../../../services/utils/logger.service";
import * as i8 from "../../../../services/api/user.service";
var styles_ConfirmationUserComponent = [i0.styles];
var RenderType_ConfirmationUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationUserComponent, data: {} });
export { RenderType_ConfirmationUserComponent as RenderType_ConfirmationUserComponent };
function View_ConfirmationUserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
function View_ConfirmationUserComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa fa-meh-o custom-icon red"]], null, null, null, null, null))], null, null); }
function View_ConfirmationUserComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-smile-o custom-icon green"]], null, null, null, null, null))], null, null); }
function View_ConfirmationUserComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["routerLink", "/public"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Return to home page"]))], function (_ck, _v) { var currVal_2 = "/public"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ConfirmationUserComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "https://app.adpc.net/practitioners-roster"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Return to ADPC home page"]))], null, null); }
function View_ConfirmationUserComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_5)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_6)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.enabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.enabled; _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.environment !== "adpc"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.environment === "adpc"); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.content; _ck(_v, 6, 0, currVal_2); }); }
export function View_ConfirmationUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "confirmation-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationUserComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ConfirmationUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-user", [], null, null, null, View_ConfirmationUserComponent_0, RenderType_ConfirmationUserComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmationUserComponent, [i4.ActivatedRoute, i7.LoggerService, i8.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationUserComponentNgFactory = i1.ɵccf("app-confirmation-user", i6.ConfirmationUserComponent, View_ConfirmationUserComponent_Host_0, {}, {}, []);
export { ConfirmationUserComponentNgFactory as ConfirmationUserComponentNgFactory };
