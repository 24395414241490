import { Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'textSnipped'
})
@Injectable()

// Snip a long text and add "..." at the end when necessary
export class TextSnippedPipe {

  transform(value: string, length: number) {
    if (value) {   // Check if value is not null
      let snippedValue = value.substr(0, length);

      if (value.length > length) {
        snippedValue = snippedValue + "...";
      }

      return snippedValue;
    }
  }

}
