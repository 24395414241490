/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-alert-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/ng2-select2/ng2-select2.component.ngfactory";
import * as i3 from "ng2-select2/ng2-select2.component";
import * as i4 from "../../../../spinner/spinner.component.ngfactory";
import * as i5 from "../../../../spinner/spinner.component";
import * as i6 from "@angular/common";
import * as i7 from "./add-alert-modal.component";
import * as i8 from "@angular/common/http";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../../../../../../services/api/http.service";
import * as i11 from "../../../../../../../services/utils/logger.service";
import * as i12 from "../../../../../../../services/utils/toast.service";
import * as i13 from "../../../../../../../services/utils/event-listener.service";
import * as i14 from "../../../../../../../services/api/organisation/organisation.api";
import * as i15 from "../../../../../../../services/api/alert/alert.api";
var styles_AddAlertModalComponent = [i0.styles];
var RenderType_AddAlertModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddAlertModalComponent, data: {} });
export { RenderType_AddAlertModalComponent as RenderType_AddAlertModalComponent };
function View_AddAlertModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Enter name or email (roster member and managers only) "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "select2", [["class", "modal-select-email"], ["cssImport", "true"], ["width", "100%"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.membersChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Select2Component_0, i2.RenderType_Select2Component)), i1.ɵdid(6, 4964352, null, 0, i3.Select2Component, [i1.Renderer], { cssImport: [0, "cssImport"], width: [1, "width"], options: [2, "options"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "true"; var currVal_1 = "100%"; var currVal_2 = _co.membersOptions; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AddAlertModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-custom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i5.SpinnerComponent, [], null, null)], null, null); }
export function View_AddAlertModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add members to alert"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Choose a roster "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "modal-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "select2", [["class", "modal-select-email"], ["cssImport", "true"], ["width", "100%"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.rosterChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Select2Component_0, i2.RenderType_Select2Component)), i1.ɵdid(12, 4964352, null, 0, i3.Select2Component, [i1.Renderer], { cssImport: [0, "cssImport"], width: [1, "width"], options: [2, "options"] }, { valueChanged: "valueChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAlertModalComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAlertModalComponent_2)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn emalsys_button green"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addMembers() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add members"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["id", "addMemberToAlertToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Addition of a member to the alert failed"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "true"; var currVal_1 = "100%"; var currVal_2 = _co.rosterOptions; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.rosterId; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = ((!_co.rosterId && !_co.usersId) || _co.loading); _ck(_v, 18, 0, currVal_5); }); }
export function View_AddAlertModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-alert-modal", [], null, null, null, View_AddAlertModalComponent_0, RenderType_AddAlertModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.AddAlertModalComponent, [i8.HttpClient, i9.NgbActiveModal, i10.HttpService, i11.LoggerService, i12.ToastService, i13.EventListenerService, i14.OrganisationApiService, i15.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddAlertModalComponentNgFactory = i1.ɵccf("app-add-alert-modal", i7.AddAlertModalComponent, View_AddAlertModalComponent_Host_0, { organisation: "organisation", selectedEvent: "selectedEvent" }, { onClickAddMembers: "onClickAddMembers" }, []);
export { AddAlertModalComponentNgFactory as AddAlertModalComponentNgFactory };
