import { Injectable } from '@angular/core';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../app/app.constants';
import { OrganisationInterface } from './../../app/model/organisation.interface';
import { UserInterface } from './../../app/model/user.interface';
// Service
import { LoggerService } from './logger.service';
@Injectable()
export class RightService {

  constructor(
    private _loggerService: LoggerService
  ) { }

  public computeOrganisationsRight(user: UserInterface, organisation: OrganisationInterface): void {
    // UserRights.organisation.canAddNewMembers = this.checkOrganisationManagerRight();

  }

  public canPromoteInOrganisation(user: UserInterface, role: number, organisationId) {

  }


  public canPromoteInRoster(user: UserInterface, role: number, rosterId: number) {

  }

  checkOrganisationManagerRight(profile, organisationId, right) {
    let managerRight = ORGANISATION_MANAGER_RIGHTS_LEVEL.NotManager;
    if (profile && organisationId) {
      const organisationManager = profile.organisation_managers.find(x => x.organisation.id === organisationId);
      return organisationManager && organisationManager.rights > right;
      // profile.organisation_managers.forEach((orga) => {
      //   if (orga.organisation.id === organisationId) {
      //     managerRight = orga.rights;
      //   }
      // });
    }

    return false;
  }

  checkRosterManagerRight(profile, rosterId, right) {
    let managerRight = 0;
    if (profile && rosterId) {
      profile.roster_managers.forEach((roster) => {
        if (roster.roster.id === rosterId) {
          managerRight = roster.rights;
        }
      });
    }
    return managerRight > right;
  }



  isOrganisationManagerRight(member, organisationId, right) {
    let managerRight = 0;
    member.organisation_managers.forEach((orga) => {
      if (orga.organisation.id === organisationId) {
        managerRight = orga.rights;
      }
    });

    return managerRight === right;
  }


  isRosterManagerRight(member, rosterId, right) {
    let managerRight = 0;
    if (member == null) {
        return false;
    }

    member.roster_managers.forEach((roster) => {
      if (roster.roster.id === rosterId) {
        managerRight = roster.rights;
      }
    });

    return managerRight === right;
  }
}

