import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule  } from '@angular/forms';
import { Uploader } from 'angular2-http-file-upload';
import { DatePipe } from '@angular/common';
import {CalendarModule} from 'primeng/primeng';
import {InputTextModule} from 'primeng/primeng';
import {InputTextareaModule} from 'primeng/primeng';
import { Select2Module } from 'ng2-select2';
// Routing
import { ProfileRouting } from './profile.routing';

// Components
import { ProfileComponent          } from './profile.component';
import { ProfileUserComponent      } from './profile-user/profile-user.component';
import { ProfileSkillsComponent    } from './profile-skills/profile-skills.component';
import { ProfileLanguagesComponent } from './profile-languages/profile-languages.component';
import { ProfileContactComponent   } from './profile-contact/profile-contact.component';
import { ProfilePhonesComponent    } from './profile-phones/profile-phones.component';
import { ProfilePictureComponent   } from './profile-picture/profile-picture.component';
import { ProfileAvailableComponent } from './profile-available/profile-available.component';
import { ProfileGeneralComponent   } from './profile-general/profile-general.component';
import { ProfileDocumentsComponent } from './profile-documents/profile-documents.component';
import { ProfileAllDataComponent } from './profile-all-data/profile-all-data.component';
import { ProfileExternalComponent } from "./profile-external/profile-external.component";
import { ProfileTagsComponent } from "./profile-tags/profile-tags.component";


// Services
import { DeploymentService } from '../../../../services/api/deployment.service';

import { PreloaderProfileAvailableComponent } from './profile-preloader/preloader-profile-available/preloader-profile-available.component';
import { PreloaderProfileContactComponent } from './profile-preloader/preloader-profile-contact/preloader-profile-contact.component';
import { PreloaderProfileDocumentsComponent } from './profile-preloader/preloader-profile-documents/preloader-profile-documents.component';
import { PreloaderProfileGeneralComponent } from './profile-preloader/preloader-profile-general/preloader-profile-general.component';
import { PreloaderProfileLanguagesComponent } from './profile-preloader/preloader-profile-languages/preloader-profile-languages.component';
import { PreloaderProfilePhonesComponent } from './profile-preloader/preloader-profile-phones/preloader-profile-phones.component';
import { PreloaderProfilePictureComponent } from './profile-preloader/preloader-profile-picture/preloader-profile-picture.component';
import { PreloaderProfileSkillsComponent } from './profile-preloader/preloader-profile-skills/preloader-profile-skills.component';
import { PreloaderProfileUserComponent } from './profile-preloader/preloader-profile-user/preloader-profile-user.component';
import { ProfileResetPasswordComponent } from './profile-reset-password/profile-reset-password.component';
import { ProfileNotificationsComponent } from './profile-notifications/profile-notifications.component';
import { ProfileDeleteAccountComponent } from './profile-delete-account/profile-delete-account.component';
import { ResetPasswordModalComponent } from './profile-reset-password/reset-password-modal/reset-password-modal.component';
import { PreloaderProfileTagsComponent } from './profile-preloader/preloader-profile-tags/preloader-profile-tags.component';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule,
    CalendarModule,
    InputTextModule,
    InputTextareaModule,
    ProfileRouting,
    Select2Module,
    RouterModule
  ],
  declarations: [
    ProfileComponent,
    ProfileUserComponent,
    ProfileTagsComponent,
    ProfileSkillsComponent,
    ProfileLanguagesComponent,
    ProfileContactComponent,
    ProfilePhonesComponent,
    ProfilePictureComponent,
    ProfileAvailableComponent,
    ProfileGeneralComponent,
    ProfileDocumentsComponent,
    ProfileAllDataComponent ,
    ProfileExternalComponent ,
    PreloaderProfileAvailableComponent,
    PreloaderProfileContactComponent,
    PreloaderProfileDocumentsComponent,
    PreloaderProfileGeneralComponent,
    PreloaderProfileLanguagesComponent,
    PreloaderProfilePhonesComponent,
    PreloaderProfilePictureComponent,
    PreloaderProfileTagsComponent,
    PreloaderProfileSkillsComponent,
    PreloaderProfileUserComponent,
    ProfileResetPasswordComponent,
    ProfileNotificationsComponent,
    ProfileDeleteAccountComponent,
    ResetPasswordModalComponent
  ],
  providers: [
    Uploader,
    DatePipe,
    DeploymentService
  ],
  entryComponents: [
    ResetPasswordModalComponent
  ]

})


export class ProfileModule {}
