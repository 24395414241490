<!--Profile-picture component-->
<div id="profile-picture-component" class="shadow">
  <!--Current picture + button to change the picture -->
  <div class="profile-user-component-picture" on-mouseover="showOptions=true;" on-mouseleave="showOptions=false;">
    <img class="picture-image" [src]="picture" onerror='this.src="assets/images/default_avatar.png"'/>
    <div class="change-button" *ngIf="isItMe && showOptions" (click)="changePicture(content)">Change picture</div>
  </div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">

    <!--Modal header-->
    <div class="modal-header">
      <!--Modal title-->
      <h4 class="modal-title" i18n="@@changePicture">Change the picture</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="thumbnailUrl=null; d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">
      <!--Select the picture-->
      <div class="upload-wrapper">
        <label for="prevention-file" class="control-label">Upload a new picture</label>
        <div class="form-input">
            <input #fileInput id="file-input" type="file" (change)="fileChange($event)" placeholder="" accept=".jpg,.png,.jpeg">
            <!-- <button *ngIf="selectedFile" type="submit" class="btn btn-success upload-btn" (click)="uploadDoc()">Upload</button> -->
        </div>
      </div>
      <!--Thumb nail to see the selected picture-->
      <!--Defaut: le thumbnail ne se clean pas -->
      <img class="roster-image image-custom" [src]="thumbnailUrl ? thumbnailUrl : picture" onerror='this.src="assets/images/default_avatar.png"'>
      <br>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Cancel button-->
      <!--Note: Est-ce vraiment utile de mettre 2 boutons permettant de fermer le modal ???-->
      <button type="button" class="btn emalsys_button red" (click)="selectedFile=null; thumbnailUrl=null; c()" i18n="@@fileCancel">Cancel</button>
      <!--Upload button-->
      <!--Is disabled if any files are selected-->
      <button [disabled]="!selectedFile || loading" type="submit" class="btn emalsys_button green" (click)="uploadDoc();">Upload</button>
    </div>

  </ng-template>

<!--Toast-->
  <div id="pictureUploadToast">Picture uploaded</div>
  <div id="pictureUploadErrorToast">Error occured!</div>
</div>
