import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dispatcher } from '../../dispatcher.service';


@Injectable()
export class AvailabilitesDispatcher extends Dispatcher {

    private subject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public constructor() {
        super();
    }

    public getData() {
        return this.pipeNotNull(this.subject);
    }

    public publishAvailabilites(data: any) {
        this.subject.next(data);
    }
}
