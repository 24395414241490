<div class="modal-header">
    <h4 class="modal-title">
        <span *ngIf="!gallery">Upload document</span>
        <span *ngIf="gallery">Select a document from your gallery</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">
            <i class="fa fa-times"></i>
        </span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="!gallery" class="compliance-properties">

        <div class="title-input">
            <label for="compliance-title">Title</label>
            <input id="compliance-title" type="text" placeholder="Title" [(ngModel)]="titleFile">
            <label>{{extensionFile}}</label>
        </div>
        <div class="file-input">
            <label for="compliance-file">File</label>
            <input id="compliance-file" type="file" (change)="fileChange($event)" />
        </div>
    </div>
    <div *ngIf="gallery" class="compliance-properties">
        <label for="compliance-gallery">File</label>
        <select2 id="compliance-gallery" class="select" cssImport="true" [data]="documentListSelect2" [options]="{multiple: false}"
            [value]="selectedDocument?.id" (valueChanged)="changedSelectDocument($event)">
        </select2>
    </div>
</div>
<div class="loading-custom" *ngIf="loading">
    <spinner-component>
    </spinner-component>
</div>
<div class="modal-footer">
    <button class="btn emalsys_button blue" (click)="gallery=!gallery">
        <span *ngIf="!gallery">Use gallery</span>
        <span *ngIf="gallery">Upload a document</span>
    </button>

    <button [disabled]="loading" *ngIf="!gallery" class="btn emalsys_button green" type="submit" [ngClass]="{'disabled':!selectedFile || loading}"
        (click)="uploadDoc()" [disabled]="!selectedFile || loading">Upload</button>
    <button *ngIf="gallery" class="btn emalsys_button green" type="submit" [ngClass]="{'disabled':!selectedDocument || loading}"
        (click)="addCompliance(selectedDocument.id)" [disabled]="!selectedDocument || loading">Use</button>
</div>