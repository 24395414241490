/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-preloader-alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-preloader-alerts.component";
var styles_DashboardPreloaderAlertsComponent = [i0.styles];
var RenderType_DashboardPreloaderAlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPreloaderAlertsComponent, data: {} });
export { RenderType_DashboardPreloaderAlertsComponent as RenderType_DashboardPreloaderAlertsComponent };
export function View_DashboardPreloaderAlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dashboard-preloader-alerts shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "dashboard-preloader-alerts-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "dashboard-preloader-alerts-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "dashboard-preloader-alerts-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dashboard-preloader-alerts-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "dashboard-preloader-alerts-block"]], null, null, null, null, null))], null, null); }
export function View_DashboardPreloaderAlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-alerts", [], null, null, null, View_DashboardPreloaderAlertsComponent_0, RenderType_DashboardPreloaderAlertsComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardPreloaderAlertsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPreloaderAlertsComponentNgFactory = i1.ɵccf("dashboard-preloader-alerts", i2.DashboardPreloaderAlertsComponent, View_DashboardPreloaderAlertsComponent_Host_0, {}, {}, []);
export { DashboardPreloaderAlertsComponentNgFactory as DashboardPreloaderAlertsComponentNgFactory };
