import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule   } from '../../shared.module';
import { Select2Module } from 'ng2-select2';
// Routing
import { ReportingRouting } from './reporting.routing';

// Components
import { ReportingComponent } from './reporting.component';
import { DataTableComponent } from './data-table/data-table.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { IndicatorPageComponent } from './indicator-page/indicator-page.component';
import { HeaderComponent } from './charts/header/header.component';

//filter
import { ButtonFilterComponent } from './filters/button-filter/button-filter.component';

//charts
import { AdvancedPieChartComponent } from './charts/advanced-pie-chart/advanced-pie-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { BarChartVerticalComponent } from './charts/bar-chart/bar-chart-vertical/bar-chart-vertical.component';
import { ChartComponent } from './charts/chart/chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { NumberCardChartComponent } from './charts/number-card-chart/number-card-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { PieGridComponent } from './charts/pie-grid/pie-grid.component';
import { StackedVerticalBarChartComponent } from './charts/stacked-vertical-bar-chart/stacked-vertical-bar-chart.component';

//modal
import { ModalComponent } from './modals/modal.component';
import { ModalDataComponent } from './modals/modal-data/modal-data.component';
import { ModalDetailsComponent } from './modals/modal-details/modal-details.component';

//services
import { ChartDataLoaderService } from './services/chart-data-loader.service';
import { IndicatorService } from './services/indicator.service';
import { FilterService } from './services/filter.service';
import { ChartRegistration } from './services/chart-registration.service';
import { SelectorFilterComponent } from './filters/selector-filter/selector-filter.component';


@NgModule({

  imports: [
    // Routing
    ReportingRouting,
    // Angular Modules
    CommonModule,
    // Feature Modules
    NgbModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    Select2Module
  ],
  declarations: [
    ReportingComponent,
    AdvancedPieChartComponent,
    BarChartComponent,
    BarChartVerticalComponent,
    ChartComponent,
    LineChartComponent,
    NumberCardChartComponent,
    PieChartComponent,
    PieGridComponent,
    StackedVerticalBarChartComponent,
    ModalComponent,
    ModalDataComponent,
    ModalDetailsComponent,
    DataTableComponent,
    IndicatorComponent,
    IndicatorPageComponent,
    ButtonFilterComponent,
    HeaderComponent,
    SelectorFilterComponent,
  ],
  providers: [
    ChartDataLoaderService,
    IndicatorService,
    FilterService,
    ChartRegistration

  ]
})
export class ReportingModule {}
