import { ProfileManagerGuardService } from './../../../services/security/profile-manager.guard';
import { OrganisationGuardService } from './../../../services/security/organisation.guard';
import { ManagerGuardService } from './../../../services/security/manager.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportingComponent } from '../reporting/reporting.component';
import { ProfileExternalComponent } from "../shared/profile/profile-external/profile-external.component";
import { ROUTING } from './../../app.constants';
import { ForbiddenComponent } from './../../error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './../../error-pages/notFound/notFound.component';
import { DashboardManagerComponent } from './dashboard/dashboard-manager.component';
import { AlertsManagerComponent } from './organisation/alerts/alerts.component';
import { OrgaNewsManagerComponent } from './organisation/news/orga-news-manager.component';
import { SettingsComponent } from './settings/settings.component';

// Do not change the order of the routes, it matters
export const routes: Routes = [
    {
        path: `organisation/:${ROUTING.parameter.organisationId}`,
        component: DashboardManagerComponent,
        canActivate: [ManagerGuardService, OrganisationGuardService]
    },
    {
        path: `organisation/:${ROUTING.parameter.organisationId}/news`,
        component: OrgaNewsManagerComponent,
        canActivate: [ManagerGuardService, OrganisationGuardService]
    },
    {
        path: `organisation/:${ROUTING.parameter.organisationId}/alert`,
        component: AlertsManagerComponent,
        canActivate: [ManagerGuardService, OrganisationGuardService]
    },
    {
        path: `organisation/:${ROUTING.parameter.organisationId}/reporting`,
        component: ReportingComponent,
        canActivate: [ManagerGuardService, OrganisationGuardService]
    },
    {
        path: `organisation/:${ROUTING.parameter.organisationId}/settings`,
        component: SettingsComponent,
        canActivate: [ManagerGuardService, OrganisationGuardService]
    },
    {
        path: `profile/:${ROUTING.parameter.userId}`,
        component: ProfileExternalComponent,
        canActivate: [ManagerGuardService]
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent
    },
    {
        path: '**',
        component: NotFoundComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManagerRouting { }
