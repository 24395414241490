import { Subject } from 'rxjs';
export class ToastInformations {
}
ToastInformations.RED = 'red';
ToastInformations.GREEN = 'green';
ToastInformations.CLOSE_TOAST = 'close_toast';
export class ToastService {
    constructor() {
        this.toast$ = new Subject();
    }
    /**
     * @deprecated
     * use showGreen and showRed instead
     *
     * Shows a toast by using the element identified by an id.
     * @param  {string}    targetId  the element to show
     * @param  {string}    className the toast class
     * @param  {number}    timer =3000 the visibility time
     */
    show(targetId, className = 'show', timer = 3000) {
        let element = document.getElementById(targetId);
        element.classList.add(className);
        setTimeout(() => {
            element.classList.remove(className);
        }, timer);
    }
    getToasts() {
        // got by the toast component
        return this.toast$.asObservable();
    }
    // TODO improve name after implementing the service
    showNew(text, timer = 3000, color) {
        this.toast$.next({
            text: text,
            color: color,
            timer: timer
        });
    }
    showGreen(text, timer = 3000) {
        this.showNew(text, timer, 'green');
    }
    showRed(text, timer = 3000) {
        this.showNew(text, timer, 'red');
    }
    // To stop the toast early for any reason
    closeToast() {
        this.toast$.error(ToastInformations.CLOSE_TOAST);
        // this.show = false;
    }
    showAnyToast(targetId, className, timer = 3000) {
        let snackToShow = document.getElementById(targetId);
        snackToShow.className = className;
        setTimeout(function () {
            snackToShow.className = snackToShow.className.replace(className, "");
        }, timer);
    }
}
