/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-authentications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-authentications.component";
var styles_AdministrationAuthenticationComponent = [i0.styles];
var RenderType_AdministrationAuthenticationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationAuthenticationComponent, data: {} });
export { RenderType_AdministrationAuthenticationComponent as RenderType_AdministrationAuthenticationComponent };
export function View_AdministrationAuthenticationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the autehntications management page in progress...\n"]))], null, null); }
export function View_AdministrationAuthenticationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-authentication", [], null, null, null, View_AdministrationAuthenticationComponent_0, RenderType_AdministrationAuthenticationComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationAuthenticationComponent, [], null, null)], null, null); }
var AdministrationAuthenticationComponentNgFactory = i1.ɵccf("administration-authentication", i2.AdministrationAuthenticationComponent, View_AdministrationAuthenticationComponent_Host_0, {}, {}, []);
export { AdministrationAuthenticationComponentNgFactory as AdministrationAuthenticationComponentNgFactory };
