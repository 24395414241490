import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CacheService } from '../storage/cache.service';
import { NavigationService } from '../utils/navigation.service';



@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private cache: CacheService,
        private navigationService: NavigationService,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let user = this.cache.get(CacheService.USER);   // Check if the user is already logged in
        if (! user) {
            this.navigationService.goToPublic();
            return false;
        }

        return true;
    }
}
