/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-available.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../spinner/spinner.component.ngfactory";
import * as i5 from "../../spinner/spinner.component";
import * as i6 from "../../../../../../node_modules/primeng/components/calendar/calendar.ngfactory";
import * as i7 from "primeng/components/dom/domhandler";
import * as i8 from "primeng/components/calendar/calendar";
import * as i9 from "./profile-available.component";
import * as i10 from "../../../../../services/api/profile/profile.api";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
import * as i12 from "../../../../../services/utils/toast.service";
import * as i13 from "../../../../../services/utils/logger.service";
import * as i14 from "../../../../../services/api/profile/profile.dispatcher";
var styles_ProfileAvailableComponent = [i0.styles];
var RenderType_ProfileAvailableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileAvailableComponent, data: {} });
export { RenderType_ProfileAvailableComponent as RenderType_ProfileAvailableComponent };
function View_ProfileAvailableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AVAILABLE FOR DEPLOYMENT"]))], null, null); }
function View_ProfileAvailableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["UNAVAILABLE FOR DEPLOYMENT"]))], null, null); }
function View_ProfileAvailableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvailableComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvailableComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAvailable; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isAvailable; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProfileAvailableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "toggle-switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["id", "TriSeaSuccess"], ["name", "TriSea1"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isAvailable = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.showChangeAvailability(i1.ɵnov(_v.parent, 10)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "label", [["class", "label-success"], ["for", "TriSeaSuccess"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "TriSea1"; var currVal_8 = _co.isAvailable; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ProfileAvailableComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i5.SpinnerComponent, [], null, null)], null, null); }
function View_ProfileAvailableComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You will be unavailable until : "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You will be unavailable until the : "])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "p-calendar", [["id", "StartDate"], ["yearRange", "2018:2030"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.endAvailabilityDate = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_Calendar_0, i6.RenderType_Calendar)), i1.ɵprd(512, null, i7.DomHandler, i7.DomHandler, []), i1.ɵdid(12, 13877248, null, 1, i8.Calendar, [i1.ElementRef, i7.DomHandler, i1.Renderer2, i1.ChangeDetectorRef], { showIcon: [0, "showIcon"], monthNavigator: [1, "monthNavigator"], yearNavigator: [2, "yearNavigator"], yearRange: [3, "yearRange"], minDate: [4, "minDate"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.Calendar]), i1.ɵdid(15, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvailableComponent_6)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn emalsys_button green"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = "2018:2030"; var currVal_13 = _co.today; _ck(_v, 12, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.endAvailabilityDate; _ck(_v, 15, 0, currVal_14); var currVal_15 = _co.loading; _ck(_v, 19, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).filled; var currVal_1 = i1.ɵnov(_v, 12).focus; var currVal_2 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 17).ngClassValid; var currVal_7 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ProfileAvailableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "shadow"], ["id", "profile-available-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvailableComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvailableComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "btn emalsys_button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeDate(i1.ɵnov(_v, 10)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "disabled": 0 }), (_l()(), i1.ɵted(-1, null, ["Change date"])), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_ProfileAvailableComponent_5)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["id", "avalabilityDisableToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are now unavailable for deployment"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["id", "avalabilityEnableToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are now available for deployment"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["id", "settingAvalabilityErrorToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There was an issue setting your availability for deployment"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.profile; _ck(_v, 4, 0, currVal_1); var currVal_3 = "btn emalsys_button"; var currVal_4 = _ck(_v, 8, 0, _co.isAvailable); _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isAvailable; _ck(_v, 6, 0, currVal_2); }); }
export function View_ProfileAvailableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-available", [], null, null, null, View_ProfileAvailableComponent_0, RenderType_ProfileAvailableComponent)), i1.ɵdid(1, 114688, null, 0, i9.ProfileAvailableComponent, [i10.ProfileService, i11.NgbModal, i12.ToastService, i13.LoggerService, i2.DatePipe, i14.ProfileDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileAvailableComponentNgFactory = i1.ɵccf("profile-available", i9.ProfileAvailableComponent, View_ProfileAvailableComponent_Host_0, {}, {}, []);
export { ProfileAvailableComponentNgFactory as ProfileAvailableComponentNgFactory };
