import { Injectable } from '@angular/core';
import { HttpClient       } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

@Injectable()
export class UserEventAnswerService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) {}

  getAvailability(idAlert) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/usereventanswers?id=" + idAlert, options);
  }
  getAvailabilities(idAlert, usersId) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/usereventanswers/availabilities?event_id=" + idAlert + "&users_id=" + usersId, options);
  }

  setAvailability(idAlert, answers) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/usereventanswers/" + idAlert + "/availabilities", answers, options);
  }

  updateIndexedAvailability(idAlert, answers, index) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/usereventanswers/" + idAlert + "/availabilities/" + index, answers, options);
  }

  deleteAvailabilities(idAlert: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/usereventanswers/" + idAlert + "/availabilities", options);
  }
}
