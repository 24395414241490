import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardResponderComponent } from './dashboard-responder.component';
import { RosterComponent } from '../organisation/roster/roster.component';
import { DisasterComponent } from '../organisation/disaster/disaster.component';
import { OrgaNewsComponent } from '../organisation/news/orga-news.component';
import { AlertsComponent } from '../organisation/alerts/alerts.component';

// Do not change the order of the routes, it matters
export const routes: Routes = [
  { path: '', component: DashboardResponderComponent },
  { path: 'roster/:status/:id', component: RosterComponent },
  { path: 'disaster/:id', component: DisasterComponent },
  { path: 'news/:id', component: OrgaNewsComponent },
  { path: 'alert/:id', component: AlertsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardResponderRouting { }
