
<div class="modal-header">
  <h4 class="modal-title">Edit Organisation</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="organisation-properties">
    <label for="organisation-name">Organisation name </label>
    <input id="organisation-name" type="text" [(ngModel)]="organisation.name" disabled="true"/>
    <label for="contact-email">Contact email *</label>
    <input id="contact-email" type="email" [(ngModel)]="profile.contact_email" />
    <label for="contact-number">Contact number</label>
    <input id="contact-number" type="text" [(ngModel)]="profile.contact_number" />
    <label for="organisation-website">Website</label>
    <input id="organisation-website" type="text" [(ngModel)]="organisation.website" />
    <label for="organisation-facebook">Facebook</label>
    <input id="organisation-facebook" type="text" [(ngModel)]="organisation.facebook" />
    <label for="organisation-twitter">Twitter</label>
    <input id="organisation-twitter" type="text" [(ngModel)]="organisation.twitter" />
    <label for="organisation-linkedin">LinkedIn</label>
    <input id="organisation-linkedin" type="text" [(ngModel)]="organisation.linked_in" />
    <label for="organisation-logo">Logo</label>
    <input id="organisation-logo" type="file" (change)="fileChange($event)" />
    <label for="organisation-compliances">Compliances</label>
    <div id="organisation-compliances">
      <table class="compliances-table">

        <tr class="table-head">
          <th>
            <h3>Title</h3></th>
          <th>
            <h3>Level</h3></th>
          <th class="header-edit-delete-create">
            <button *ngIf="!addActive && editActive==null" class="add-button" title="Add compliance" (click)="addActive = true">
                <i class="fa fa-plus custom-add-icon"></i>
              </button></th>
        </tr>

        <tbody *ngIf="addActive">
        <tr>
          <td>
            <input class="compliance-name" type="text" [(ngModel)]="newTitleRequirement">
          </td>
          <td >
            <select [(ngModel)]="newLevelRequirement">
                  <option value=0>Optionnal</option>
                  <option value=1>Required</option>
                 </select>
          </td>
        </tr>
        <tr class="edit-delete ">
            <button class="btn emalsys_button green margin-r margin-b margin-t" [disabled]="!newTitleRequirement && !newLevelRequirement"(click)="addOrganisationCompliance(newTitleRequirement,newLevelRequirement)">Add</button>
            <button class="btn emalsys_button red margin-r margin-b margin-t" (click)="addActive=false;newTitleRequirement=null;newLevelRequirement=null">Cancel</button>
          </tr>
        </tbody>

        <tbody *ngIf="!addActive">
        <tr *ngFor='let compliance of organisationCompliances'>
          <td>
            <p *ngIf="editActive==null">{{compliance.title}}</p>
            <input class="compliance-name" *ngIf="editActive==compliance.id" type="text" [(ngModel)]="editTitleRequirement">
            <div class="button-group">
            <button *ngIf="editActive==compliance.id" class="btn emalsys_button green margin-r margin-t margin-b"  (click)="editActive=null;editOrganisationCompliance(editTitleRequirement,editLevelRequirement,compliance.id);">Save</button>
            <button *ngIf="editActive==compliance.id" class="btn emalsys_button red margin-t margin-b" (click)="editActive=null;editTitleRequirement=null;editLevelRequirement=null">Cancel</button>
          </div>
          </td>
          <td [ngClass]="{'custom-td' : editActive==compliance.id}">
            <p *ngIf="editActive==null">{{setLevel(compliance)}}</p>
            <select  *ngIf="editActive==compliance.id" [(ngModel)]="editLevelRequirement">
                  <option value=0>Optionnal</option>
                  <option value=1>Required</option>
                 </select>
          </td>
          <td class="edit-delete">
            <button *ngIf="editActive==null" class="btn emalsys_button green margin-r margin-t margin-b"  (click)="editActive=compliance.id;editTitleRequirement=compliance.title;editLevelRequirement=compliance.level">Edit</button>
            <button *ngIf="editActive==null" class="btn emalsys_button red margin-r margin-t margin-b" (click)="deleteOrganisationCompliance(compliance.id)">Delete</button>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button [disabled]="loading" type="submit" class="btn emalsys_button red" (click)="deleteOrganisation()">Delete</button>
  <button [disabled]="!profile.contact_email || loading" type="submit" class="btn emalsys_button green" (click)="editOrganisation(); activeModal.close()">Save</button>
</div>
