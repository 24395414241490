<!--Rosters-members component-->
<div id="roster-members-component" class="shadow">
  <!--Title-->
  <div class="emalsys_section-title">
    <h2 i18n="@@rosterMemberLabel">Roster Members</h2>
  </div>
  <!--List of members-->
    <div class="roster-members-component-members">
    <button class="members-member" *ngFor='let member of members'>
      
      <img *ngIf="member.user.profile.picture" class="member-picture" src="{{member.user.profile.picture}}"/>
      <img *ngIf="!member.user.profile.picture" class="member-picture" src="assets/images/default_avatar.png"/>
      <!-- <img class="member-picture" src="assets/images/default_avatar.png"/> -->
      <h3 class="member-name">{{member.user.firstname}} {{member.user.lastname}}</h3>
      <h6 class="member-status"
      [class.pending]='member.state==-1'
      [class.frozen]='member.state==0'
      [class.active]='member.state==1'
      [class.invited]='member.state==2'>{{setType(member)}}</h6>
    </button>
  </div>
</div>
