<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Deployment offers details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="deployment-offer" *ngFor="let offer of deploymentOffers">
    <div class="deployment-order-infos">
      <div class="main-infos">
        <p class="position">Position: {{offer.position}}</p>
        <div class="container-date-infos">
          <p class="date">{{offer.start_date | date: "fullDate"}}</p>
          <i class="fa fa-arrow-right custom-arrow" aria-hidden="true"></i>
          <p class="date">{{offer.end_date | date: "fullDate"}}</p>
        </div>
        <div class="sender">
          <p>Deploying manager: {{offer.sender.firstname}} {{offer.sender.lastname}}</p>
          <p><i class="fa fa-envelope custom-envelope" aria-hidden="true"></i>{{offer.sender.profile.contact_email}}</p>
        </div>
      </div>
      <div class="manager-controle">
          <div class="status">
              <p>Status:</p>
              <i *ngIf="offer.valid==1" class="fa fa-check-circle validated" aria-hidden="true" title="Deployment offer accepted"></i>
              <i *ngIf="offer.valid==0" class="fa fa-question-circle pending" aria-hidden="true" title="Deployment offer pending"></i>
              <i *ngIf="offer.valid==2" class="fa fa-times refused" aria-hidden="true" title="Deployment offer refused"></i>
              <i *ngIf="offer.valid==3" class="fa fa-ban canceled" aria-hidden="true" title="Deployment offer canceled"></i>
          </div>

        <button [disabled]="loading" *ngIf="offer.valid != 3 && checkOrganisationAssociate() && offer.end_date > today "class="btn emalsys_button red" (click)="cancelOffer(offer.id)">Cancel offer</button>
      </div>
    </div>
    <p class="message">{{offer.message}}</p>
  </div>
  <h2 class="no-offers" *ngIf="!deploymentOffers.length">There are no offers to display</h2>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
</div>
