import { catchError, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CacheService } from '../storage/cache.service';

export class SemaphoreLoader {
  public value = false;
}

/**
 * Basically this is to not write each member of
 * the "semaphore". Just generate the value if not present
 */
export class Semaphore {
  busy = {};

  generate(key: string) {
    if (this.busy[key] === undefined) {
      this.busy[key] = new SemaphoreLoader();
    }
    return this.busy[key];
  }
}


@Injectable()
export class ApiCacherService {

  private busy = new Semaphore();

  constructor(
    private _cacheService: CacheService,
    // private _http: CacheHttp
  ) { }

  get cacher() {
    return this._cacheService;
  }

  doWrapper<T>(key: string, method: () => Observable<T>, options?: any): Observable<T> {
    let loading: SemaphoreLoader = this.busy.generate(key);
    return this.do<T>(loading, key, method);
  }

  // REFACTOR: make proper interfaces here
  /**
   *
   * @param loading
   * @param key
   * @param method
   * @param options
   * @returns an observable
   */
  do<T>(loading: any, key: string, method: Function, options?: any) {
    while (loading.value) { }
    loading = true;
    let item = this._cacheService.get(key);
    // if (item != null) {
    //   return of<T>(item);
    // } else {
      return method()
      .pipe(
        catchError(x => of(this._cacheService.get(key))),
        tap(x => this._cacheService.set(key, x, options)),
        tap(x => loading = false)
      );
    // }
  }

  doCached<T>(url: string, options: any) {
    // return this._http.get<T>(url, options);
  }

  removeIfPresent(key: string) {
    if (this._cacheService.get(key) !== null) {
      this._cacheService.remove(key);
    }
  }
}
