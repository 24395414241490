/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alerts-map.component";
import * as i4 from "../../../../../../services/external/leaflet.service";
import * as i5 from "../../../../../../services/api/alert/alert.dispatcher";
import * as i6 from "../../../../../../services/page/alert.manager.pager";
import * as i7 from "../../../../../../services/api/alert/alert.api";
var styles_AlertsMapComponent = [i0.styles];
var RenderType_AlertsMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsMapComponent, data: {} });
export { RenderType_AlertsMapComponent as RenderType_AlertsMapComponent };
function View_AlertsMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setSelectedAlert(_v.context.$implicit);
        var pd_0 = ((_co.selectedLayer = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " - ", " - ", " - ", ""])), i1.ɵppd(3, 2), i1.ɵppd(4, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = i1.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.start_date, "MMMM y")); var currVal_2 = i1.ɵunv(_v, 2, 2, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.end_date, "MMMM y")); var currVal_3 = _v.context.$implicit.contact_name; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_AlertsMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsMapComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AlertsMapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setSelectedAlert(_v.context.$implicit);
        var pd_0 = ((_co.selectedLayer = "") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " - ", " - ", " - ", " - ", ""])), i1.ɵppd(3, 2), i1.ɵppd(4, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = (_v.context.$implicit.country_name ? _v.context.$implicit.country_name : "Other"); var currVal_2 = i1.ɵunv(_v, 2, 2, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.start_date, "MMMM y")); var currVal_3 = i1.ɵunv(_v, 2, 3, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.end_date, "MMMM y")); var currVal_4 = _v.context.$implicit.contact_name; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_AlertsMapComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-history"], ["title", "Display the old alerts"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleChangeList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AlertsMapComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-list-alt"], ["title", "Display the alerts list"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleChangeList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_AlertsMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["id", "alerts-map-component"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["id", "alerts-map-component-map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableZoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsMapComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "alert-list-selection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select the alert you want see"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-list-alt"], ["title", "Display the alerts list"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleChangeDisplay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["id", "alerts-list-component"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "alerts-map-component-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsMapComponent_3)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "alert-list-selection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select the alert you want see"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "alert-list-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsMapComponent_4)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsMapComponent_5)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-map-o"], ["title", "Display the alerts map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleChangeDisplay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedLayer; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.eventsSorted; _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.oldList; _ck(_v, 19, 0, currVal_4); var currVal_5 = _co.oldList; _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertList; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.alertList; _ck(_v, 9, 0, currVal_2); }); }
export function View_AlertsMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-manager-map", [], null, null, null, View_AlertsMapComponent_0, RenderType_AlertsMapComponent)), i1.ɵdid(1, 245760, null, 0, i3.AlertsMapComponent, [i4.LeafletService, i5.AlertDispatcherService, i6.AlertPageService, i7.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsMapComponentNgFactory = i1.ɵccf("alerts-manager-map", i3.AlertsMapComponent, View_AlertsMapComponent_Host_0, {}, { onChangeEvent: "onChangeEvent" }, []);
export { AlertsMapComponentNgFactory as AlertsMapComponentNgFactory };
