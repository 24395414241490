import { BannerGuardService } from './../services/security/banner.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeaturesGuardService } from '../services/security/features.guard';
import { AuthGuardService } from './../services/security/auth.guard';
import { ForbiddenComponent } from './error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './error-pages/notFound/notFound.component';
import { SettingsComponent } from './modules/manager/settings/settings.component';
import { PublicPagesComponent } from './modules/public/public-pages.component';

// Do not change the order of the routes, it matters
export const routes: Routes = [
    {
        path: '',
        redirectTo: 'public', pathMatch: 'full'
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        canActivate: [BannerGuardService]
    },
    {
        path: 'public',
        component: PublicPagesComponent,
        loadChildren: './modules/public/public-pages.module#PublicPagesModule',

        canActivate: [FeaturesGuardService, BannerGuardService]
    },
    {
        path: 'responder',
        loadChildren: './modules/responder/responder.module#ResponderModule',

        canActivate: [AuthGuardService, BannerGuardService]
    },
    {
        path: 'manager',
        loadChildren: './modules/manager/manager.module#ManagerModule',

        canActivate: [AuthGuardService, BannerGuardService]
    },
    {
        path: 'admin',
        loadChildren: './modules/administration/administration.module#AdministrationModule',

        canActivate: [AuthGuardService, BannerGuardService]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [BannerGuardService] // TODO: add guard for admin
    },
    {
        path: '**',
        component: NotFoundComponent,
        canActivate: [BannerGuardService]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting {}
