import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng2-select2';

import { DeploymentService } from '../../../../../../services/api/deployment.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrls: ['./order-details-modal.component.scss']
})
export class OrderDetailsModalComponent implements OnInit {

  @Input() deploymentOrders;
  public selectedOrder: any;

  constructor(
    public activeModal: NgbActiveModal,
    public _deploymentService: DeploymentService,
    public _loggerService: LoggerService
  ) { }


  ngOnInit() {
    this.deploymentOrders = this.deploymentOrders.reverse();
    this.selectedOrder = this.deploymentOrders[0];
    this._loggerService.log(this.selectedOrder);
  }
}
