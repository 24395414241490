import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
// Service
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { LoggerService } from '../../../../../services/utils/logger.service';


@Component({
  selector: 'orga-news',
  templateUrl: './orga-news.component.html',
  styleUrls: ['./orga-news.component.scss']
})


export class OrgaNewsComponent implements OnInit {

  public newsId: number;
  public orgaId: number;
  public orgaDetails: any;

  public newsInfo: any;

  constructor(
    private titleService: Title,
    protected route: ActivatedRoute,
    protected _routerService: Router,
    protected _orgaService: OrganisationApiService,
    protected _loggerService: LoggerService
  ) {
    this.newsInfo = {};
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.newsId = +params.get('id');
      this._loggerService.log("NEWS ID: ", this.newsId);
    });

    this.route.paramMap.subscribe(params => {
      this.orgaId = +params.get('orga');
      this._loggerService.log("ORGA ID: ", this.orgaId);
    });

    // this.getOrgaNewsDetails();

  }

  // getOrgaNewsDetails() {
  //   this._orgaService.getOrgaNewsDetails(this.orgaId, this.newsId)
  //     .subscribe( res => {
  //       this.newsInfo = res;
  //       this._loggerService.log("NEW INFO: ", this.newsInfo);
  //       this.getOrgaDetails();

  //     });
  // }

  getOrgaDetails() {
    this._orgaService.getOrganisation(this.orgaId)
      .subscribe(res => {
        this.orgaDetails = res;
        this._loggerService.log("OrgaDetails", this.orgaDetails);
        this.titleService.setTitle(this.orgaDetails.name + " - " + this.newsInfo.title);
      });
  }

}
