import { GlobalDataService } from './../../../../../services/api/global/global.api';
import { GlobalDispatcherService } from '../../../../../services/api/global/global.dispatcher';
import { UserLanguageInterface } from './../../../../model/user-language.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

// Service
import { ProfileService } from "../../../../../services/api/profile/profile.api";
import { ToastService } from '../../../../../services/utils/toast.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { UserService } from "../../../../../services/api/user.service";

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { Subscription ,  of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'profile-languages',
  templateUrl: './profile-languages.component.html',
  styleUrls: ['./profile-languages.component.scss']
})


export class ProfileLanguagesComponent implements OnInit, OnDestroy {

  @Input() languages: any[];

  public allLanguages: any[];
  public myLanguages: any;

  // public languages: any;
  public languageToAdd: any;
  public languageLevel: any;
  public displayingLanguages: any;
  public loading = false;
  public modalRef;

  public subscriptions: Subscription[] = [];

  /**
   * TRUE if we're looking at someone else's profile
   */
  public isSomeoneElse = true;

  constructor(
    private _profileService: ProfileService,
    private _userService: UserService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _loggerService: LoggerService,
    private _profileDispatcher: ProfileDispatcherService,
    private _globalDispatcher: GlobalDispatcherService,
    private _globalService: GlobalDataService
  ) {
  }

  ngOnInit() {
    this.isSomeoneElse = this.languages !== undefined;
    this.myLanguages = this.isSomeoneElse ? this.languages : [];
    // this.languages = [];
    this.languageToAdd = { id: "", name: "" };
    this.languageLevel = 1;

    if (!this.isSomeoneElse) {
      // Get MY languages
    } else {

    }


    // Subscribe to all the languages
    let allLanguagesSubscription = this._globalDispatcher.getLanguages()
      .pipe(
        switchMap(allLanguages => {
          // allLanguages is in the form
          // { fr: "French", it: "Italian", ...} and so on
          this.allLanguages = Object.keys(allLanguages)
            // Map it to make it compatible with the modal dropdown
            .map((language) => {
              return {
                id: language,
                name: allLanguages[language]
              };
            });
            let removedLanguages = ["Acoli", "Avestan", "Tunisian Arabic", "Afrihili", "Aghem", "Ainu", "Akkadian", "Alabama",
            "Aleut", "Southern Altai", "Aragonese", "Old English", "Angika", "Mapuche", "Araona", "Arapaho", "Arawak",
            "Asu", "Modern Standard Arabic", "Algerian Arabic", "Moroccan Arabic", "Egyptian Arabic", "Asturian", "Kotava", "Awadhi",
            "Basaa", "Aramaic", "Bamun", "Batak Toba", "Ghomala", "Bena", "Bafut", "Badaga", "Kom", "Siksika", "Bishnupriya", "Bakhtiari",
            "Breton", "Braj", "Brahui", "Akoose", "Buriat", "Bulu", "Blin", "Medumba", "Caddo", "Carib", "Cayuga", "Atsam", "Chakma",
            "Chiga", "Chibcha", "Chagatai", "Chuukese", "Chinook Jargon", "Choctaw", "Chipewyan", "Cherokee", "Cheyenne", "Central Kurdish",
            "Corsican", "Coptic", "Capiznon", "Seselwa Creole French", "Kashubian", "Church Slavic", "Dakota", "Taita", "Austrian German",
            "Swiss High German", "Delaware", "Slave", "Dogrib", "Dinka", "Dogri", "Lower Sorbian", "Central Dusun", "Duala", "Middle Dutch",
            "Jola-Fonyi", "Dazaga", "Embu", "Efik", "Emilian", "Ancient Egyptian", "Ekajuk", "Elamite", "Australian English", "Canadian English",
            "British English", "American English", "Middle English", "Esperanto", "Latin American Spanish", "European Spanish", "Mexican Spanish",
            "Ewondo", "Extremaduran", "Fulah", "Tornedalen Finnish", "Faroese", "Canadian French", "Swiss French", "Cajun French", "Middle French",
            "Old French", "Arpitan", "Northern Frisian", "Eastern Frisian", "Friulian", "Western Frisian", "Gan Chinese", "Gayo", "Gbaya",
            "Zoroastrian Dari", "Scottish Gaelic", "Geez", "Gilbertese", "Galician", "Gilaki", "Middle High German", "Old High German", "Gondi",
            "Gorontalo", "Gothic", "Grebo", "Ancient Greek", "Swiss German", "Wayuu", "Frafra", "Gusii", "Manx", "Hakka Chinese", "Hiligaynon",
            "Hittite", "Hmong", "Upper Sorbian", "Xiang Chinese", "Hupa", "Herero", "Interlingua", "Iban", "Ibibio", "Interlingue", "Sichuan Yi",
            "Ido", "Inuktitut", "Ingrian", "Jamaican Creole English", "Lojban", "Ngomba", "Machame", "Judeo-Persian", "Judeo-Arabic", "Jutish",
            "Kabyle", "Kachin", "Jju", "Kamba", "Kawi", "Kanembu", "Tyap", "Makonde", "Kabuverdianu", "Kenyang", "Kaingang", "Khasi", "Khotanese",
            "Koyra Chiini", "Khowar", "Kikuyu", "Kirmanjki", "Kuanyama", "Kako", "Kalenjin", "Bodo", "Kannada", "Komi-Permyak", "Zarma", "Konkani",
            "Kosraean", "Kpelle", "Kanuri", "Karachay-Balkar", "Krio", "Kinaray-a", "Karelian", "Kurukh", "Kashmiri", "Shambala", "Bafia", "Colognian",
            "Kurdish", "Kumyk", "Kutenai", "Komi", "Cornish", "Latin", "Ladino", "Langi", "Lahnda", "Lamba", "Lezghian", "Lingua Franca Nova", "Ganda",
            "Limburgish", "Ligurian", "Livonian", "Lakota", "Lombard", "Mongo", "Louisiana Creole", "Lozi", "Northern Luri", "Latgalian", "Luba-Katanga",
            "Luba-Lulua", "Luiseno", "Lunda", "Luo", "Mizo", "Luyia", "Literary Chinese", "Laz", "Madurese", "Mafa", "Magahi", "Maithili", "Makasar",
            "Mandingo", "Masai", "Maba", "Moksha", "Mandar", "Mende", "Meru", "Morisyen", "Middle Irish", "Makhuwa-Meetto", "Metaʼ", "Mi'kmaq",
            "Minangkabau", "Malayalam", "Manchu", "Manipuri", "Mohawk", "Mossi", "Marathi", "Western Mari", "Mundang", "Multiple languages", "Creek",
            "Mirandese", "Marwari", "Marwari", "Mentawai", "Myene", "Erzya", "Mazanderani", "Nauru", "Min Nan Chinese", "Neapolitan", "Nama",
            "Norwegian Bokmål", "North Ndebele", "Low German", "Low Saxon", "Newari", "Ndonga", "Nias", "Ao Naga", "Flemish", "Kwasio", "Norwegian Nynorsk",
            "Ngiemboon", "Nogai", "Old Norse", "Novial", "N’Ko", "South Ndebele", "Northern Sotho", "Nuer", "Navajo", "Classical Newari", "Nyamwezi",
            "Nyankole", "Nyankole", "Nyoro", "Nzima", "Occitan", "Ojibwa", "Oromo", "Odia", "Ossetic", "Osage", "Punjabi", "Pangasinan", "Pahlavi",
            "Pampanga", "Papiamento", "Picard", "Nigerian Pidgin", "Pennsylvania German", "Plautdietsch", "Old Persian", "Palatine German", "Phoenician",
            "Pali", "Piedmontese", "Pontic", "Pohnpeian", "Prussian", "Old Provençal", "Brazilian Portuguese", "European Portuguese", "Quechua", "Kʼicheʼ",
            "Chimborazo Highland Quichua", "Rajasthani", "Rapanui", "Romagnol", "Riffian", "Romansh", "Rombo", "Romany", "Root", "Rusyn", "Roviana",
            "Aromanian", "Rwa", "Sanskrit", "Sandawe", "Sakha", "Samaritan Aramaic", "Samburu", "Sasak", "Santali", "Saurashtra", "Ngambay", "Sangu",
            "Sardinian", "Sicilian", "Scots", "Sindhi", "Southern Kurdish", "Northern Sami", "Seneca", "Sena", "Seri", "Selkup", "Koyraboro Senni",
            "Old Irish", "Samogitian", "Serbo-Croatian", "Tachelhit", "Shan", "Chadian Arabic", "Sidamo", "Slovenian", "Lower Silesian", "Selayar",
            "Southern Sami", "Lule Sami", "Inari Sami", "Skolt Sami", "Shona", "Soninke", "Sogdien", "Sranan Tongo", "Serer", "Swati", "Saho",
            "Southern Sotho", "Saterland Frisian", "Sundanese", "Sukuma", "Susu", "Sumerian", "Swahili", "Congo Swahili", "Classical Syriac", "Syriac",
            "Silesian", "Tulu", "Telugu", "Timne", "Teso", "Tereno", "Tetum", "Tigre", "Tiv", "Tokelau", "Tsakhur", "Tagalog", "Klingon", "Tlingit",
            "Talysh", "Tamashek", "Turoyo", "Nyasa Tonga", "Taroko", "Tsonga", "Tsakonian", "Tsimshian", "Tatar", "Muslim Tat", "Tumbuka", "Twi",
            "Tasawaq", "Tuvinian", "Central Atlas Tamazight", "Udmurt", "Uyghur", "Ugaritic", "Unknown language", "Vai", "Venda", "Venetian", "Veps",
            "West Flemish", "Main-Franconian", "Volapük", "Votic", "Võro", "Vunjo", "Walloon", "Walser", "Wolaytta", "Waray", "Washo", "Warlpiri",
            "Wu Chinese", "Kalmyk", "Mingrelian", "Soga", "Yao", "Yapese", "Yangben", "Yemba", "Yiddish", "Nheengatu", "Cantonese", "Zhuang", "Zapotec",
            "Blissymbols", "Zeelandic", "Zenaga", "Standard Moroccan Tamazight", "Simplified Chinese", "Traditional Chinese", "Zuni", "No linguistic content",
            "Zaza", "Sassarese Sardinian", "Afar", "Abkhazian", "Achinese", "Adangme", "Adyghe", "Gheg Albanian", "Najdi Arabic", "Assamese", "Avaric",
            "Aymara", "Bashkir", "Baluchi", "Balinese", "Bavarian", "Beja", "Bemba", "Betawi", "Western Balochi", "Bhojpuri", "Bikol", "Bini", "Banjar",
            "Bambara", "Buginese", "Chechen", "Cebuano", "Mari", "Chuvash", "Dargwa", "Dyula", "Ewe", "Central Yupik", "Fang", "Fanti", "Ga", "Gagauz",
            "Goan Konkani", "Gujarati", "Gwichʼin", "Haida", "Hiri Motu", "Igbo", "Inupiaq", "Iloko", "Ingush", "Kara-Kalpak", "Kabardian", "Koro",
            "Kongo", "Kimbundu", "Marshallese", "Maori", "Niuean", "Rarotongan", "Rotuman", "Tongan", "Tok Pisin"];
            let removedLanguagesId = [];
            for (let i = 0; i < this.allLanguages.length; i++) {
              for (let j = 0; j < removedLanguages.length; j++) {
                if (this.allLanguages[i].name === removedLanguages[j]) {
                  removedLanguagesId.push(i);
                }
              }
            }
            removedLanguagesId.reverse();
            removedLanguagesId.forEach(id => {
              this.allLanguages.splice(id, 1);
            });

          if (!this.isSomeoneElse) {
            return this._profileDispatcher.getLanguages();
          } else {
            // When fetching someone else's languages, the array is in the form
            // [ {name: 'fr', id: 1, level: 2} ]
            // So we need allLanguages to get the full language name
            let otherPersonDisplayingLanguages = this.languages
              .map(language => {
                return {
                  language_name: allLanguages[language.name],
                  id: language.id,
                  level: language.level
                };
              });
            return of(otherPersonDisplayingLanguages);
          }
        })
      )
      .subscribe(
        (displayingLanguages: any) => {
          this.displayingLanguages = displayingLanguages;
        }
      );

    this._globalService.getAllLanguages();

    if (!this.isSomeoneElse) {
      this.getMyLanguages();
    }
    this.subscriptions.push(allLanguagesSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // what?
  filterLanguages() {
    for (let l of this.myLanguages) {
      for (let lang of this.languages) {
        if (l.language_name === lang.name) {
          let index = this.languages.indexOf(lang);
          this.languages.splice(index, 1);
        }
      }
    }
  }

  getMyLanguages() {
    this._profileService.getMyLanguages();
  }

  getLanguages() {

  }

  showAddLanguages(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  addLanguage(languageId, languageLevel) {
    this.loading = true;
    this._profileService.addMeLanguage({ language: languageId, level: languageLevel })
      .subscribe(res => {
        this.showToastAdd();
        this.getMyLanguages();
        this.loading = false;
        this.languageToAdd = { id: "", name: "" };
        this.modalRef.close();
      });
  }

  deleteLanguage(languageId) {
    this._profileService.deleteLanguage(languageId)
      .subscribe(res => {
        this.showToastDeleted();
        this._loggerService.log("Language " + languageId + " removed");
      });
  }

  showToastDeleted() {
    this._toastService.show("langDeleteToast", "show", 3000);
  }

  showToastCancel() {
    this._toastService.show("langCancelToast", "show", 3000);
  }

  showToastAdd() {
    this._toastService.show("langAddToast", "show", 3000);
  }

}
