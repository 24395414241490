<div class="modal-header">
  <h4 class="modal-title">Details of roster join invitation</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">
      <i class="fa fa-times"></i>
    </span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="!addDocument" id="roster-compliances">
    <table class="compliances-table">
      <tr class="emalsys_section-title">
        <h4 class="compliances-type">Organisation Compliances</h4>
      </tr>
      <tr class="table-head">
        <th>
          <h3>Title</h3>
        </th>
        <th>
          <h3>Level</h3>
        </th>
        <th>
          <h3>Document</h3>
        </th>
        <th>
            <h3>Upload/Update</h3>
          </th>
      </tr>
      <tr *ngFor='let compliance of orgaFilledCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <button type="text" (click)="downloadDoc(compliance.organisation_requirement_documents[0].document)">{{compliance.organisation_requirement_documents[0].document.title}}</button>
        </td>
        <td class="upload-update">
          <button class="btn emalsys_button green margin-r margin-t" (click)="activeAddDocument(compliance.id,'organisation')">Update</button>
          <button class="btn emalsys_button red margin-r margin-t" (click)="deleteOrganisationComplianceDocument(compliance.id)">Delete</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of orgaEmptyCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <i class="fa fa-times" aria-hidden="true"></i>
        </td>
        <td>
          <button class="btn emalsys_button green margin-t" (click)="activeAddDocument(compliance.id,'organisation')">Upload</button>
        </td>
      </tr>
      <div class="emalsys_section-title">
        <h4 class="compliances-type">Roster Compliances</h4>
      </div>
      <tr class="table-head">
        <th>
          <h3>Title</h3>
        </th>
        <th>
          <h3>Level</h3>
        </th>
        <th>
          <h3>Document</h3>
        </th>
      </tr>
      <tr *ngFor='let compliance of rosterFilledCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <button type="text" (click)="downloadDoc(compliance.roster_requirement_documents[0].document)">{{compliance.roster_requirement_documents[0].document.title}}</button>
        </td>
        <td class="upload-update">
          <button class="btn emalsys_button green margin-r margin-t" (click)="activeAddDocument(compliance.id,'roster')">Update</button>
          <button class="btn emalsys_button red margin-r margin-t" (click)="deleteRosterComplianceDocument(compliance.id)">Delete</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of rosterEmptyCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <i class="fa fa-times" aria-hidden="true"></i>
        </td>
        <td>
          <button class="btn emalsys_button green margin-t" (click)="activeAddDocument(compliance.id,'roster')">Upload</button>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="addDocument">
    <div *ngIf="!gallery" class="compliance-properties">
      <label for="compliance-title">Title</label>
      <input id="compliance-title" type="text" placeholder="Title" [(ngModel)]="titleFile">
      <label for="compliance-file">File</label>
      <input id="compliance-file" type="file" (change)="fileChange($event)" />
    </div>
    <div *ngIf="gallery" class="compliance-properties">
      <label for="compliance-gallery">File</label>
      <select2 id="compliance-gallery" class="select" cssImport="true" [data]="documentListSelect" [options]="{multiple: false}"
        [value]="selectedDocument?.id" (valueChanged)="changedSelectDocument($event)">
      </select2>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="addDocument">
  <button class="btn emalsys_button blue" (click)="gallery=!gallery">
    <span *ngIf="!gallery">Use gallery</span>
    <span *ngIf="gallery">Upload a document</span>
  </button>
  <div class="actions">
    <button class="btn emalsys_button red margin-r" type="submit" (click)="desactiveAddDocument()">Back</button>
    <button *ngIf="!gallery" class="btn emalsys_button green margin-l" type="submit" [ngClass]="{'disabled':!selectedFile}" (click)="uploadDoc()"
      [disabled]="!selectedFile">Upload</button>
    <button *ngIf="gallery" class="btn emalsys_button green margin-l" type="submit" (click)="addCompliance(selectedDocument.id)">Use</button>
  </div>

</div>