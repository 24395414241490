import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeploymentService } from '../../../../../../services/api/deployment.service';
import { LoggerService          } from '../../../../../../services/utils/logger.service';
import { saveAs as importedSaveAs } from "file-saver";
import { DocumentService } from '../../../../../../services/api/document.service';

@Component({
  selector: 'alerts-deployment-order',
  templateUrl: './alerts-deployment-order.component.html',
  styleUrls: ['./alerts-deployment-order.component.scss']
})
export class AlertsDeploymentOrderComponent implements OnInit {
  @Input() alertInfo;
  @Input() idAlert;
  @Input() deploymentOrders;
  @Input() nbrOrders;

  public downloadingFile = false;

  constructor(
    private _documentService: DocumentService,
    private _loggerService: LoggerService,
    private route: ActivatedRoute) { }
  ngOnInit() {
    this._loggerService.log(this.idAlert);
  }

  downloadDoc(document) {
    this._loggerService.log("Doc ID to download: ", document.id);
    this.downloadingFile = true;
    this._documentService.documentDownload(document.id).subscribe(response => {
      this.downloadingFile = false;
      importedSaveAs(response, document.title);
      this._loggerService.log("Blob received: ", response);
    }, error => {
      this.downloadingFile = false;
      this._loggerService.error("Failed to download the file: ", error);
    });
  }
}
