<div class="orga-news-component" *ngIf="newsInfo && orgaDetails">
  <div class="orga-logo margin-b shadow text-center">
    <div>
      <img class="logo-image" src="{{orgaDetails.browser_url}}" onerror='this.src="/assets/images/default_organisation.png"'>
    </div>
  </div>
  <div class="news-title shadow margin-b text-center">
    <h2 class="margin-b"> {{orgaDetails.name}} </h2>
    <p class="margin-b"> {{orgaDetails.description}} </p>
  </div>
  <div class="news-date shadow margin-b" *ngIf="newsInfo.creation_date">
    <h2>{{ newsInfo.creation_date | niceDateDetailed}}</h2>
  </div>
  <div class="news-description text-center shadow">
    <h2 class="text-center margin-b">{{ newsInfo.title }}</h2>
    <p [innerHTML]='newsInfo.content'></p>
  </div>
</div>


<div class="orga-news-component" *ngIf="!newsInfo || !orgaDetails">
  <div class="orga-logo preloader margin-b shadow">
    <div class="preloader-square"></div>
  </div>
  <div class="news-title preloader shadow margin-b">
    <div class="preloader-small-line"></div>
    <div class="preloader-big-line"></div>
    <div class="preloader-big-line"></div>
    <div class="preloader-big-line"></div>
  </div>
  <div class="news-date preloader shadow margin-b">
    <div class="preloader-small-line"></div>
  </div>
  <div class="news-description preloader shadow">
    <div class="preloader-small-line"></div>
    <div class="preloader-big-line"></div>
    <div class="preloader-big-line"></div>
    <div class="preloader-big-line"></div>
  </div>
</div>