import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AlertCachedService } from '../../../../../../services/api/alert/alert.cacher';
import { AlertDispatcherService } from '../../../../../../services/api/alert/alert.dispatcher';
import { OrganisationDispatcherService } from '../../../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../../../services/api/profile/profile.dispatcher';
import { AlertPageService } from '../../../../../../services/page/alert.manager.pager';
import { RightService } from '../../../../../../services/utils/right.service';
import { ToastService } from '../../../../../../services/utils/toast.service';
// Data
import { ISO2_TO_ISO3, URL_API_RELIEF_WEB_IMG_LG } from '../../../../../app.constants';
import { UserInterface } from '../../../../../model/user.interface';
import { EditAlertModalComponent } from '../modals/edit-alert/edit-alert-modal.component';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../../../../app.constants';
import { AlertInterface } from './../../../../../model/alert.interface';
import { OrganisationInterface } from './../../../../../model/organisation.interface';
@Component({
  selector: 'alerts-manager-informations',
  templateUrl: './alerts-informations.component.html',
  styleUrls: ['./alerts-informations.component.scss']
})
export class AlertsInformationsComponent implements OnInit, OnDestroy {
  @Output() onCatchClickSave = new EventEmitter<any>();
  @Output() onCatchClickDelete = new EventEmitter<any>();
  public idAlert: number;
  public organisation: OrganisationInterface;
  public profile: UserInterface;
  public alertInfo: any;
  public imgUrl: any;
  public iso3: any;

  public isOrganisationAssociate = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private _rightService: RightService,
    private _modalService: NgbModal,
    private _profileDispatcher: ProfileDispatcherService,
    private _alertDispatcher: AlertDispatcherService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _alertCache: AlertCachedService,
    private _alertPage: AlertPageService,
    private _toastService: ToastService
  ) {

  }

  ngOnInit() {
    this.imgUrl = URL_API_RELIEF_WEB_IMG_LG;
    this.iso3 = ISO2_TO_ISO3;

    this.subscriptions.push(
      this._profileDispatcher.getProfile().pipe(
        switchMap((res: UserInterface) => {
          this.profile = res;
          return this._organisationDispatcher.getSelectedOrganisation();
        }))
        .subscribe((organisation: OrganisationInterface) => {
          this.isOrganisationAssociate
            = this._rightService.checkOrganisationManagerRight(this.profile, organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL.Associate);
        })
    );

    this.subscriptions.push(
      this._alertDispatcher.getSelectedAlert().subscribe(
        (res: AlertInterface) => {
          this.alertInfo = res;
          this.idAlert = res.id;
        }
      )
    );

    // This subscription is only needed for updating
    this.subscriptions.push(
      this._alertDispatcher.getOrganisationAlerts().subscribe(
        (res: AlertInterface[]) => {
          let alert = res.find(x => x.id === this.idAlert);
          if (alert) {
            this.alertInfo = alert;
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  handleClickEdit() {
    let modalRef = this._modalService.open(EditAlertModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.context = "edit";
    modalRef.componentInstance.alertInfo = this.alertInfo;
    modalRef.componentInstance.onClickSave.subscribe(($e) => {
      // this.onCatchClickSave.emit($e);
      this._alertCache.updateAlert($e.id, $e.alertInfo).subscribe(
        () => {
          // Check EditAlertModal.updateAlert() to understand $e and its content
          modalRef.close();
          this._alertPage.selectAlert($e.fullAlert);
        },
        err => {
          // ERRORS
          this._toastService.showRed("Some error occurred during update.");
        }
      );
    });
    modalRef.componentInstance.onClickDelete.subscribe(($e) => {
      this.onCatchClickDelete.emit($e);
      modalRef.close();
      this._alertCache.deleteAlert($e.id);
    });
  }

  errorHandler(event) {
    event.target.src = this.imgUrl + "wld.png";
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }
}
