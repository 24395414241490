<div class="alerts-preloader-informations">
  <div class="alerts-preloader-informations-body">
    <div class="alerts-preloader-informations-line"></div>
    <div class="alerts-preloader-informations-line"></div>
    <div class="alerts-preloader-informations-line"></div>
    <div class="alerts-preloader-informations-line"></div>
    <div class="alerts-preloader-informations-line"></div>
  </div>
  <div class="alerts-preloader-informations-picture">
    <div class="alerts-preloader-informations-square"></div>
  </div>
</div>