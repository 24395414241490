import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription ,  combineLatest ,  forkJoin } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AlertCachedService } from '../../../../../services/api/alert/alert.cacher';
import { AlertDispatcherService } from '../../../../../services/api/alert/alert.dispatcher';
import { GlobalDataService } from '../../../../../services/api/global/global.api';
import { CachedOrganisationService } from '../../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../../../../../services/api/organisation/organisation.dispatcher';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { UserEventService } from "../../../../../services/api/userevent.service";
import { AlertPageService } from '../../../../../services/page/alert.manager.pager';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { RightService } from '../../../../../services/utils/right.service';
import { ToastService } from '../../../../../services/utils/toast.service';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../app.constants';
import { AlertInterface } from '../../../../model/alert.interface';
import { UserInterface } from '../../../../model/user.interface';
import { AddAlertModalComponent } from "../../../shared/members/modals/alert/add/add-alert-modal.component";
import { InitializedDashboard } from './../../../../model/utils/initialization-model.interface';
import { EditAlertModalComponent } from './modals/edit-alert/edit-alert-modal.component';
import { MembersDispatcherService } from '../../../../../services/api/member/members.dispatcher';
import { GlobalDispatcherService } from '../../../../../services/api/global/global.dispatcher';

@Component({
  selector: 'manager-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsManagerComponent implements OnInit, OnDestroy {

  @ViewChild("search") search;
  @ViewChild("eventmap") eventmap;

  public adding = false;
  public events: any;
  public oldEvents: any;
  public organisation: any;
  public profile;
  public selected = {};
  public selectedEvent: AlertInterface;

  public canCreateNewEvents = false;
  public displayEventDashboard = false;
  public isOrganisationAssociate = false;

  // Used for preloaders
  public loadedAlerts = false;
  public loadedMembers = false;

  public filteredMembers: UserInterface[] = [];
  public members: UserInterface[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private _alertDispatcher: AlertDispatcherService,
    private _alertService: AlertCachedService,
    private _globalService: GlobalDataService,
    private _loggerService: LoggerService,
    private _modalService: NgbModal,
    private _organisationCachedService: CachedOrganisationService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _profileDispatcher: ProfileDispatcherService,
    private _profileService: ProfileService,
    private _rightService: RightService,
    private route: ActivatedRoute,
    private titleService: Title,
    private _toastService: ToastService,
    private _userEventService: UserEventService,
    private _alertPageService: AlertPageService,
    private _memberDispatcher: MembersDispatcherService,
    private _globalDispatcher: GlobalDispatcherService,
  ) { }

  ngOnInit() {
    let profile$ = this._profileDispatcher.getProfile();
    let organisation$ = this._organisationDispatcher.getSelectedOrganisation();


    // Get organisation and profile to check the right of opening a new alert
    const combine$ = combineLatest(
      profile$,
      organisation$
    )
      .pipe(
        map(vals => {
          return <InitializedDashboard>{
            user: vals[0],
            organisation: vals[1]
          };
        }),
        take(1),
      )
      .subscribe(
        (values: InitializedDashboard) => {
          // No need to store profile:
          // it is only passed to search component by databinding. It will be taken from there.
          this.canCreateNewEvents =
            this._rightService.checkOrganisationManagerRight(values.user, values.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL.Associate);

          // Organisation is used both to add alerts and edit them
          this.organisation = values.organisation;

          this._alertService.getOrganisationAlerts(this.organisation.id);
          // And... let's set the title
          this.titleService.setTitle(this.organisation.name + " - Alerts");

          // this.getAlertMembers();
          this._globalService.getSkills();
          this._globalService.getAllLanguages();
          // this._rosterService.getOrganisationRosters(values.organisation.id);

          // SERVICEPAGE
          // this._rosterService.getOrganisationRostersArrayForm(values.organisation.id);
        }
      );
    this.subscriptions.push(combine$);

    const alerts$ = this._alertDispatcher.getOrganisationAlerts().subscribe(
      (res: AlertInterface[]) => {
        // no need to store the alerts. They are stored
        // into the alerts map component
        // Here we just check if there are some
        this.displayEventDashboard = res.length > 0;
        this.loadedAlerts = true;
      }
    );
    this.subscriptions.push(alerts$);

    // Subscribe to routing changes
    const route$ = this.route.paramMap
      .pipe(
        switchMap(params => {
          return forkJoin(
            this._organisationCachedService.selectOrganisation(+params.get('orga')),
            this._profileService.getProfile()
          );
        })
      )
      .subscribe();
    this.subscriptions.push(route$);


    this.subscriptions.push(
      this._memberDispatcher.getMembers().subscribe(
        _ => this.loadedMembers = true
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this._alertPageService.destroy();
    this._memberDispatcher.clearMembers();
  }

  updateAlert(alert) {
    this._alertService.updateAlert(alert.id, alert.alertInfo).subscribe(
      // TODO: handle success and error
    );
  }
  createAlert(alert) {
    this._alertService.createAlert(alert).toPromise()
      .then(
        () => {
          this._loggerService.log("Alert created and cached!");
        }
      )
      .catch(
        () => {
          this._loggerService.log("Alert not create and thus not cached");
        }
      );
  }

  deleteAlert(idAlert) {
    this._alertService.deleteAlert(idAlert).toPromise()
      .then(
        () => {
          this._loggerService.log("Alert delete");
          this.showToastDeleteEvent();
        }
      )
      .catch(
        () => this._loggerService.log("Could not delete alert")
      );

  }

  addMember(alert) {
    this._userEventService.createAlert(alert)
      .subscribe(res => {
        this.selected = {};
        this.eventmap.setSelectedAlert(this.eventmap.selectedAlertInfo.id);
        this.showToastAddMember();
      });
  }

  handleChangeEvent(event) {
    this.selected = {};
    this.selectedEvent = event;
  }

  handleCreateEvent() {
    let modalRef = this._modalService.open(EditAlertModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.context = "create";
    modalRef.componentInstance.organisation = this.organisation;
    modalRef.componentInstance.alertInfo = new AlertInterface();

    modalRef.componentInstance.onClickCreate.subscribe(($e) => {
      this.createAlert($e);
      modalRef.close();
      this.selected = {};
    });
  }

  handleAddMember(alertId) {
    let modalRef = this._modalService.open(AddAlertModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });

    modalRef.componentInstance.selectedEvent = alertId;
    modalRef.componentInstance.organisation = this.organisation;

    modalRef.componentInstance.onClickAddMembers.subscribe(($e) => {
      this.addMember($e);
    });
  }

  currentlyAdding() {
    this.adding = this.adding === false;
  }

  setFilteredMembers(members: UserInterface[]) {
    this.filteredMembers = members;
  }

  showToastAddMember() {
    this._toastService.show("eventMemberAddToast", "show", 3000);
  }
  showToastDeleteEvent() {
    this._toastService.show("eventDeleteToast", "show", 3000);
  }
  showToastEditEvent() {
    this._toastService.show("eventUpdateToast", "show", 3000);
  }
  showToastCreateEvent() {
    this._toastService.show("eventCreateToast", "show", 3000);
  }
  showToastErrorGettingEvents() {
    this._toastService.show("errorGettingEventsToast", "show", 3000);
  }

  private getAlertMembers() {
    const members$ = this._alertDispatcher.getSelectedAlert()
      .pipe(
        switchMap(alert => {
          return this._userEventService.getUserEvents(this.organisation.id, { event: alert.id });
        })
      )
      .subscribe(members => {
        this.members = members;
      });
    this.subscriptions.push(members$);
  }
}
