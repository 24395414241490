/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["viewBox", "0 0 60 60"]], [[2, "small", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M 45 30 A 15 15, 100, 1, 1, 30 15"], ["fill", "transparent"], ["stroke", "#61ABDC"], ["stroke-linecap", "round"], ["stroke-width", "2"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.small; _ck(_v, 0, 0, currVal_0); }); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "spinner-component", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i2.SpinnerComponent, [], null, null)], null, null); }
var SpinnerComponentNgFactory = i1.ɵccf("spinner-component", i2.SpinnerComponent, View_SpinnerComponent_Host_0, { small: "small" }, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
