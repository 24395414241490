import { HttpClient } from '@angular/common/http';
// Constants
import { URL_API_EMALSYS } from '../../../../environments/environment';
import { HttpService } from '../../../../services/api/http.service';
export class FilterService {
    constructor(http, _httpService) {
        this.http = http;
        this._httpService = _httpService;
    }
    /**
     * Register filters block to avoid duplicate
     * @param filters
     */
    subscribe(filterObject) {
        if (Array.isArray(filterObject)) {
            filterObject.forEach((filter) => {
                this.addSingleFilter(filter);
            });
        }
        else {
            this.addSingleFilter(filterObject);
        }
    }
    /**
     * Get unique name of filter for registration and verify if filter already exist in list registeredFilters
     * @param filter
     */
    addSingleFilter(filter) {
        if (!FilterService.registeredFilters[filter.getFullname()]) {
            FilterService.registeredFilters[filter.getFullname()] = filter;
        }
        // else{
        // throw new Error('This filter (\''+filter.getFullname()+'\') is already registered');
        // }
    }
    /**
     * Delete filter in the list registeredFilters
     * @param filter
     */
    unsubscribe(filter) {
        delete FilterService.registeredFilters[filter.getFullname()];
    }
    getRostersForSelector(organisationId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/rosters?organisation=" + organisationId, options);
    }
    getEventsForSelector(organisationId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/events?organisation=" + organisationId, options);
    }
    getLastDate() {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/lastdate", options);
    }
}
/**
 * List of filters registered
 */
FilterService.registeredFilters = {};
