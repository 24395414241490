import { environment } from '../../environments/environment';
export class CacheService {
    // public static keys = {}; // TODO: @Odysseas you will be afraid
    constructor() {
        this.PREFIX = 'erw'; // erw = emalsys_responder_web
        this.TIMEOUT = 2592000; // 30 days in seconds
        this.defaultStorage = localStorage;
    }
    /**
     * Get the key as stored in the storage
     * @param  {string} key [description]
     * @return {[type]}     [description]
     */
    getRealKey(key) {
        const env = environment.envName || 'env';
        const version = environment.version || 'version';
        return this.PREFIX + '_' + env + '_' + version + '_' + key;
    }
    secToMs(ms) {
        return ms * 1000;
    }
    /**
     * Get item from cache.
     * item = { storageTime : time, value : value}
     * @param  {[type]} key [description]
     * @return {[type]}     [description]
     */
    get(key) {
        key = this.getRealKey(key);
        let item = this.defaultStorage.getItem(key);
        // CacheService.keys[key] = CacheService.keys[key] ? CacheService.keys[key] + 1 : 1;
        if (item) {
            let object = JSON.parse(item) || {};
            let now = (new Date()).getTime();
            // apply time policy to validate the cached object
            if (object.storageTime + this.secToMs(object.limit) < now) {
                // we assume the cached value expired
                this.remove(key);
                return null;
            }
            return object.value;
        }
        return null;
    }
    getTest(key) {
        key = this.getRealKey(key);
        let item = this.defaultStorage.getItem(key);
        if (item) {
            let object = JSON.parse(item) || {};
            let now = (new Date()).getTime();
            // apply time policy to validate the cached object
            if (object.storageTime + this.secToMs(object.limit) < now) {
                // we assume the cached value expired
                this.remove(key);
                return null;
            }
            // REFACTOR casting issue
            return object.value;
        }
        return null;
    }
    /**
     * Get the value and remove it from the cache
     * @param  {string} key [description]
     * @return {any}     [description]
     */
    pop(key) {
        let item = this.get(key);
        this.remove(key);
        return item;
    }
    /**
     * Store a value to the cache.
     * object = { storageTime : time, value : value}
     */
    set(key, value, options = {}) {
        // default canBeDeleted
        if (options.canBeDeleted == null) {
            options.canBeDeleted = true;
        }
        // default timeout
        options.timeout = options.timeout || this.TIMEOUT;
        let object = {
            storageTime: (new Date()).getTime(),
            value: value,
            limit: this.TIMEOUT,
            canBeDeleted: options.canBeDeleted
        };
        key = this.getRealKey(key);
        this.defaultStorage.setItem(key, JSON.stringify(object));
    }
    isDeletable(key) {
        key = this.getRealKey(key);
        let item = JSON.parse(this.defaultStorage.getItem(key)) || {};
        return item.canBeDeleted;
    }
    /**
     * Remove an object from the cache
     * @param  {string} key [description]
     * @return {[type]}     [description]
     */
    remove(key) {
        if (this.isDeletable(key)) {
            key = this.getRealKey(key);
            this.defaultStorage.removeItem(key);
        }
    }
    /**
     * Clear the whole cache = remove all items generated by this cache
     * @return {[type]} [description]
     */
    clear() {
        Object.keys(this.defaultStorage)
            .filter(key => key.toString().indexOf(this.PREFIX) >= 0)
            .forEach((item, key, array) => {
            let cached = JSON.parse(this.defaultStorage[item]);
            if (cached.canBeDeleted) {
                delete this.defaultStorage[item];
            }
        });
        document.cookie = "credentials=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
}
// KEYS
CacheService.SETTINGS = 'app_settings';
CacheService.USER = 'user';
CacheService.ORGANISATIONS = 'all_organisations';
CacheService.CURRENT_ORGANISATION = 'current_organisation';
CacheService.DEBUG_STATE = 'debug_state';
CacheService.ISMANAGER_WEBSITE = 'isManagerWebsite';
CacheService.PREFIX_INDICATOR = 'indicatordata_';
