import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preloader-profile-general',
  templateUrl: './preloader-profile-general.component.html',
  styleUrls: ['./preloader-profile-general.component.scss']
})
export class PreloaderProfileGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
