
<div class="roster-component" *ngIf="rosterId && rosterStatus">
  <roster-infos [rosterId]='rosterId' [rosterStatus]='rosterStatus'></roster-infos>
  <roster-compliances [rosterId]='rosterId' [rosterStatus]='rosterStatus'></roster-compliances>
  <roster-members *ngIf='rosterStatus === "member"' [rosterId]='rosterId' [rosterStatus]='rosterStatus'></roster-members>
  
  <div id="not-in-roster" class="shadow" *ngIf='rosterStatus != "member"'>
    <h1>You are not in this roster!</h1>
    <p *ngIf='rosterStatus==="invited"'>
      You have been invited to join this roster.
    </p>
    <p *ngIf='rosterStatus==="request"'>
      Your request to join this roster has been sent.
    </p>
    <p *ngIf='rosterStatus==="frozen"'>
      You are frozen in this roster.
    </p>
  </div>
</div>

<div class="roster-component" *ngIf="!rosterId || !rosterStatus">
    <roster-preloader-infos></roster-preloader-infos>
    <roster-preloader-compliances></roster-preloader-compliances>
    <roster-preloader-members></roster-preloader-members>
  </div>
