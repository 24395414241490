import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import {CommonModule} from '@angular/common';
import { Subscription, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AlertDispatcherService } from '../../../../../../services/api/alert/alert.dispatcher';
import { MembersDispatcherService } from '../../../../../../services/api/member/members.dispatcher';
import { AvailabilitesDispatcher } from '../../../../../../services/api/availabilites/availabilites.dispatcher';
import { UserEventAnswerService } from '../../../../../../services/api/usereventanswer.service';
import { ProfileDispatcherService } from '../../../../../../services/api/profile/profile.dispatcher';
import { ProfileService } from '../../../../../../services/api/profile/profile.api';
import { UserInterface } from '../.././../../../model/user.interface';
import { Availability } from '../.././../../../model/availability.interface';
import { Calendar } from '../../../../../../data/calendar.class';
import { SelectionsArray } from '../../../../../../data/selectionArray.class';
import { Selection } from '../../../../../../data/selection.class';
import { stat } from 'fs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-event-calendar-modal',
  templateUrl: './event-calendar-modal.component.html',
  styleUrls: ['./event-calendar-modal.component.scss']
})
export class EventCalendarModalComponent implements OnInit {

  SELECTIONS: SelectionsArray<Selection>;
  SELECTIONSChange = new EventEmitter<any>();
  idAlert: number;

  public alertInfo: any;
  public members: any;
  public availabilities: any = [];

  public begin: any;
  public end: any;

  public calendarLoaded = false;
  public calendar: any;
  days: any = [];
  display: boolean;
  endUnavailabilityUser: Date;
  selectionClass = Selection;
  selection: Selection;

  constructor(
    public activeModal: NgbActiveModal,
    private _alertDispatcher: AlertDispatcherService,
    private _membersDispatcher: MembersDispatcherService,
    private _availabilitesDispatcher: AvailabilitesDispatcher,
    private _userEventAnswerService: UserEventAnswerService,
    private _profileDispatcher: ProfileDispatcherService,
    private _profileService: ProfileService,

  ) { }
  public loading = false;

  ngOnInit() {

    this.data();
    this.selection = new Selection(null, null, null);
    this.calendar = new Calendar();
    this.display = false;
  }

  data() {
    this.setAlert();
    this.setMember();
    this.setAvailabilities();
  }

  setAvailabilities() {
    this._availabilitesDispatcher.getData().subscribe(res => {
      this.availabilities = res;
      return this.availabilities;
    });
  }

  setAlert() {
    this._alertDispatcher.getSelectedAlert().subscribe(res => {
      this.alertInfo = res;
      this.calcData(this.alertInfo);
      this.idAlert = this.alertInfo.id;
      this.refreshSelection();
      return this.alertInfo;
    });
  }

  setMember() {
    this._membersDispatcher.getMembers().subscribe( (members: UserInterface[]) => {
      this.members = members;
      return this.members;
    });
  }

  calcData(data: any) {
    this.begin = data.start_date.split("T", 1);
    this.end = data.end_date.split("T", 1);
  }

  resetSelection() {
    this.selection = new Selection(null, null, null);
  }

  getDaysArray() {
    this.days = this.mapDateToStatus(this.SELECTIONS, this.calendar);
  }

  getAvailability(id, day) {
    let res = "";
    let end_date_event = this.alertInfo.end_date.substring(0, this.alertInfo.end_date.length - 14);
    end_date_event = new Date(end_date_event);
    let start_date_event = this.alertInfo.start_date.substring(0, this.alertInfo.start_date.length - 14);
    start_date_event = new Date(start_date_event);
    let dateCalendar = day.date;
    dateCalendar.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let status = null;

    if (this.availabilities[id] && dateCalendar >= currentDate) {
      status = 6;
      for (let i = 0; i < this.availabilities[id].length; i++) {
        let availability_start = new Date(this.availabilities[id][i].start_date);
        availability_start.setDate(availability_start.getDate());
        availability_start.setHours(0, 0, 0, 0);
        let availability_end = new Date(this.availabilities[id][i].end_date);
        availability_end.setDate(availability_end.getDate());
        availability_end.setHours(0, 0, 0, 0);
        let event_start = new Date(this.alertInfo.start_date);
        event_start.setHours(0, 0, 0, 0);
        let event_end = new Date(this.alertInfo.end_date);
        event_end.setHours(0, 0, 0, 0);
        if (availability_start <= dateCalendar && dateCalendar <= availability_end) {
          status = this.availabilities[id][i].status;
        }
      }
    } if (dateCalendar < start_date_event || dateCalendar > end_date_event || dateCalendar < currentDate) {
      status = 8;
    }
    switch (Number(status)) {
      case 0:
        res = "notAvailable";
        break;
      case 1:
        res = "available";
        break;
      case 2:
        res = "notSure";
        break;
      case 6:
        res = "notSetted";
        break;
      case 8:
        res = "disabled";
        break;
      default:
        res = "disabled";
        break;
    }
    return res;
  }

  mapDateToStatus(selections, calendar): any {
    let days = [];
    let end_date_event = new Date(this.alertInfo.end_date);
    end_date_event.setDate(end_date_event.getDate());
    let start_date_event = new Date(this.alertInfo.start_date);
    start_date_event.setDate(start_date_event.getDate());
    // loop on calendar days
    for (let i = 0; i < calendar.days.length; i++) {
      // default status
      let index = calendar.days[i]._d;
      if (index < calendar.today._d || index < start_date_event || index > end_date_event) {
        days.push({ date: index, status: Selection.DISABLED });
        continue;
      }
      days.push({ date: index, status: Selection.NOT_SETTED_STATUS });

      // check if the current date is covered by a selection
      for (let j = 0; j < selections.length; j++) {
        if (new Date(selections[j].startDate.toLocaleString("en-EN")) <= new Date(calendar.days[i]._d.toLocaleString("en-EN"))
          && new Date(calendar.days[i]._d.toLocaleString("en-EN")) <= new Date(selections[j].endDate.toLocaleString("en-EN"))) {

          // if the date is covered, then it is added to the map
          let k = days.findIndex(item => item.date === index);
          if (k > -1) {
            days[k].status = selections[j].status;
          }
        }
      }
    }
    return days;
  }

  refreshSelection() {
    this._userEventAnswerService.getAvailability(this.idAlert).subscribe((res) => {
      if (res[0].user_event_answers.length > 0) {
        this.updateUnavailableAccordingToProfile(res[0].user_event_answers[0]);
        this.SELECTIONS = res[0].user_event_answers.map(value => {
          let a = new Date(value.start_date);
          // a.setDate(a.getDate() + 1);
          a.setDate(a.getDate());
          let b = new Date(value.end_date);
          // b.setDate(b.getDate() + 1);
          b.setDate(b.getDate());
          let selectiontest = new Selection(a, b, value.status);
          return selectiontest;
        });
        this.getDaysArray();
        this.SELECTIONSChange.emit(this.SELECTIONS);
      } else {
        this.setUnavailableAccordingToProfile();
      }
    });
  }

  updateUnavailableAccordingToProfile(existingSelection) {
    this._profileDispatcher.getUserData().subscribe((user: UserInterface) => {
      if (existingSelection && existingSelection.start_date === this.alertInfo.start_date
        && existingSelection.end_date !== user.end_unavailability && existingSelection.status === Selection.NOT_AVAILABLE_STATUS
        && existingSelection.end_date !== this.alertInfo.end_date) {
        this.selection.startDate = new Date(this.alertInfo.start_date);
        this.selection.startDate.setDate(this.selection.startDate.getDate());
        this.selection.endDate = new Date(user.end_unavailability);
        this.selection.setStatus('notAvailable');
        // Since the unavailability is set up the first time the user displays the calendar, it will be the first selection to be added.
        this._userEventAnswerService.updateIndexedAvailability(this.idAlert, this.selection, 0)
          .subscribe(res => {
            this.refreshSelection();
            // Reset the parameters for a new selection
            this.resetSelection();
          });
      }
    });
  }

  setUnavailableAccordingToProfile() {
    this._profileDispatcher.getUserData().pipe(
      switchMap((user: UserInterface) => {
        this.selection.startDate = new Date(this.alertInfo.start_date);
        this.selection.startDate.setDate(this.selection.startDate.getDate());
        this.selection.endDate = new Date(user.end_unavailability);
        this.selection.setStatus('notAvailable');
        if (this.selection.endDate < this.selection.startDate) {
          this.selection.endDate = this.selection.startDate;
        }
        return this._userEventAnswerService.setAvailability(this.idAlert, this.selection);
      })
    )
    .subscribe(res => {
      this.refreshSelection();
      // Reset the parameters for a new selection
      this.resetSelection();
    });

    // Trigger user data from service
    this._profileService.getUserData();
  }
}

