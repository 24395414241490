import { EnvironmentChecker } from './../utils/environment-checker.service';
import { Injectable          } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CacheService } from '../storage/cache.service';
import { LoggerService } from '../utils/logger.service';

import { UserInterface } from '../../app/model/user.interface';

import { environment } from '../../environments/environment';
import { NavigationService } from '../utils/navigation.service';

@Injectable()


export class FeaturesGuardService implements CanActivate {
    constructor (
        private router: Router,
        private cache: CacheService,
        private logger: LoggerService,
        private navigator: NavigationService,
    ) {}

    /**
     * Here, by returning false, we deny access to public page when the user is logged in.
     * He's redirected to the responder dashboard.
     * This feature has been changed. Uncomment the code below to activate it
     */
    canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // const url = state.url;

        // const accessAllowed = EnvironmentChecker.ifAdpc(() => {
        //     // prevent access to public routes
        //     return (url.indexOf('register') > -1 || url.indexOf('confirm') > -1);
        // });

        // if (accessAllowed === false) {
        //     this.navigator.goToForbidden();
        //     return accessAllowed;
        // }

        return true;
        // this.navigator.goToResponderDashboard();
        // return false;
    }
}
