import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// Services
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { AlertService        } from '../../../../../services/api/alert/alert.api';
import { DisasterService     } from '../../../../../services/external/disasters.service';
import { LoggerService       } from '../../../../../services/utils/logger.service';
import { AlertDispatcherService } from '../../../../../services/api/alert/alert.dispatcher';


@Component({
  selector   : 'dashboard-news',
  templateUrl: './dashboard-news.component.html',
  styleUrls  : ['./dashboard-news.component.scss']
})


export class DashboardNewsComponent implements OnChanges {

  @Input() organisation: any;

  // For organisation news and alerts
  public news:       any;
  public alerts:     any;
  public alertsUser: any;

  public loadedAlerts = false;

  constructor(
    private _organisationService: OrganisationApiService,
    private _alertService:        AlertService,
    private _disasterService:     DisasterService,
    private _loggerService:       LoggerService,
    private _alertDispatcher: AlertDispatcherService
  ) {
      this.news   = [];
      this.alerts = {};
      this.alertsUser = {};
    }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organisation && changes.organisation.currentValue !== changes.organisation.previousValue) {
      this.getAlerts();
    }
  }

  getItems() {
    if (this.organisation) {
      this.getAlerts();
    }
  }

  getAlerts() {
    this._alertDispatcher.getOrganisationAlerts().subscribe(res => {
      this.alerts = res;
      this._loggerService.log("ALERTS test: ", res);
    });

    this._alertDispatcher.getMyAlerts().subscribe(res => {
      this.alertsUser = res;
      this._loggerService.log("ALERTS of the user test: ", res);
    });
  }
}
