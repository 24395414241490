/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preloader-profile-contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preloader-profile-contact.component";
var styles_PreloaderProfileContactComponent = [i0.styles];
var RenderType_PreloaderProfileContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreloaderProfileContactComponent, data: {} });
export { RenderType_PreloaderProfileContactComponent as RenderType_PreloaderProfileContactComponent };
export function View_PreloaderProfileContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "profile-preloader-contact shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "profile-preloader-contact-big-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "profile-preloader-contact-small-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "profile-preloader-contact-small-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "profile-preloader-contact-small-line"]], null, null, null, null, null))], null, null); }
export function View_PreloaderProfileContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preloader-profile-contact", [], null, null, null, View_PreloaderProfileContactComponent_0, RenderType_PreloaderProfileContactComponent)), i1.ɵdid(1, 114688, null, 0, i2.PreloaderProfileContactComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreloaderProfileContactComponentNgFactory = i1.ɵccf("preloader-profile-contact", i2.PreloaderProfileContactComponent, View_PreloaderProfileContactComponent_Host_0, {}, {}, []);
export { PreloaderProfileContactComponentNgFactory as PreloaderProfileContactComponentNgFactory };
