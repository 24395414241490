import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { ReportingModule } from '../reporting/reporting.module';
import { ProfileModule } from '../shared/profile/profile.module';
import { DashboardManagerModule } from './dashboard/dashboard-manager.module';
import { ManagerRouting } from './manager.routing';
import { OrgaNewsManagerModule } from './organisation/news/orga-news-manager.module';
import { SettingsModule } from './settings/settings.module';
import { WelcomeModule } from './welcome/welcome.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WelcomeModule,
    OrgaNewsManagerModule,
    DashboardManagerModule,
    ProfileModule,
    ManagerRouting,
    SettingsModule,
    ReportingModule
  ],
  declarations: []
})
export class ManagerModule { }
