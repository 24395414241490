/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-preloader-members.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-preloader-members.component";
var styles_AlertsPreloaderMembersComponent = [i0.styles];
var RenderType_AlertsPreloaderMembersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsPreloaderMembersComponent, data: {} });
export { RenderType_AlertsPreloaderMembersComponent as RenderType_AlertsPreloaderMembersComponent };
export function View_AlertsPreloaderMembersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "alerts-preloader-members shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "alerts-preloader-members-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "alerts-preloader-members-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "alerts-preloader-members-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "alerts-preloader-members-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "alerts-preloader-members-big-square"]], null, null, null, null, null))], null, null); }
export function View_AlertsPreloaderMembersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-preloader-members", [], null, null, null, View_AlertsPreloaderMembersComponent_0, RenderType_AlertsPreloaderMembersComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertsPreloaderMembersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsPreloaderMembersComponentNgFactory = i1.ɵccf("alerts-preloader-members", i2.AlertsPreloaderMembersComponent, View_AlertsPreloaderMembersComponent_Host_0, {}, {}, []);
export { AlertsPreloaderMembersComponentNgFactory as AlertsPreloaderMembersComponentNgFactory };
