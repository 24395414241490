<div class="dashboard-preloader-members shadow">
    <div class="dashboard-preloader-members-container">
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
        <div class="dashboard-preloader-members-big-square"></div>
      </div>
</div>
