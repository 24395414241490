import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalDispatcherService } from '../../../../services/api/global/global.dispatcher';
import { MembersDispatcherService } from '../../../../services/api/member/members.dispatcher';
import { RostersDispatcherService } from '../../../../services/api/roster/rosters.dispatcher';
import { TagsService } from "../../../../services/api/tag/tag.api";
import { TagDispatcherService } from '../../../../services/api/tag/tag.dispatcher';
import { RosterInterface } from '../../../model/roster.interface';
import { UserInterface } from '../../../model/user.interface';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {

  members: UserInterface[] = [];

  @Input()
  selectedRoster: RosterInterface;

  @Input()
  public page = '';

  @Output()
  filteredMembers = new EventEmitter<UserInterface[]>();


  public languages: { id: string, text: string }[] = [];
  public rosters: { id: number, text: string }[] = [];
  public skills: { id: number, text: string }[] = [];
  public tags: { id: number, text: string }[] = [];

  // Form
  searchForm = new FormGroup({
    languages: new FormControl(''),
    query: new FormControl(''),
    rosters: new FormControl(''),
    skills: new FormControl(''),
    tags: new FormControl(''),
  });

  // Subscriptions
  private subscriptions$: Subscription[] = [];

  constructor(
    private _globalDispatcher: GlobalDispatcherService,
    private _rosterDispatcher: RostersDispatcherService,
    private _tagDispatcher: TagDispatcherService,
    private _tagService: TagsService,
    private _membersDispatcher: MembersDispatcherService
  ) { }


  ngOnInit() {
    this.getLanguages();
    this.getSkills();
    this.getTags();
    this.getRosters();

    this._membersDispatcher.getMembers().subscribe(
      members => this.members = members
    );

    const searchForm$ = this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(_ => {
        this.filter();
      });
    this.subscriptions$.push(searchForm$);

    const rosters$ = this.searchForm.controls.rosters.valueChanges.subscribe(rosterId => {
      this._tagService.getAllRosterTags(rosterId).subscribe();
    });
    this.subscriptions$.push(rosters$);
  }

  ngOnChanges() {
    if (this.members.length > 0) {
      this.filter();
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  filter() {
    const filteredMembers = this.members.filter(member => {
      return (
        member.firstname === undefined || // Quick (AND TEMPORARY) workaround to fix a "roster manager label" bug
        this.queryMatch(member) &&
        this.tagsMatch(member) &&
        this.languagesMatch(member) &&
        this.rostersMatch(member) &&
        this.skillsMatch(member)
      );

    });
    // this.filteredMembers.emit(filteredMembers);
    if (this.members.length === filteredMembers.length) {
      this._membersDispatcher.publishPreviousMembers();
    } else {
      this._membersDispatcher.publishFilteredMembers(filteredMembers);
    }
  }

  private queryMatch(member: UserInterface) {
    const query = new RegExp(this.searchForm.value.query, 'i');
    return (member.firstname.match(query) || member.lastname.match(query) || member.email.match(query));
  }

  private tagsMatch(member: UserInterface) {
    const tags = this.searchForm.value.tags;
    if (!tags || tags.length === 0) {
      return true;
    }

    const memberTags = this.getMemberTags(member);

    return this.arrayContainsOther(memberTags, tags);
  }

  private languagesMatch(member: UserInterface) {
    let languages = this.searchForm.value.languages;
    if (!languages || languages.length === 0) {
      return true;
    }

    languages = languages.map((language: string) => language.split('_')[0]);
    const memberLanguages = member.profile.languages.map(language => language.name);

    return this.arrayContainsOther(memberLanguages, languages);
  }

  private rostersMatch(member: UserInterface) {
    const roster = this.searchForm.value.rosters;
    if (!roster) {
      return true;
    }

    // return this.getMemberRosters(member).includes(x => x.rosterId === roster);
    for (const rosterId of this.getMemberRosters(member)) {
      if (rosterId === roster) {
        return true;
      }
    }

    return false;

  }

  private skillsMatch(member: UserInterface) {
    let skills: any[] = this.searchForm.value.skills;
    if (!skills || skills.length === 0) {
      return true;
    }

    const memberSkills = this.getMemberSkills(member);

    return this.arrayContainsOther(memberSkills, skills);
  }

  private getMemberTags(member: UserInterface) {
    const tags = [];
    for (const rosterMember of member.roster_members) {
      for (const tag of rosterMember.roster_member_tags) {
        tags.push(tag.roster_tag.id);
      }
    }

    return tags;
  }

  private getMemberRosters(member: UserInterface) {
    const rosters = [];
    for (const rosterMember of member.roster_members) {
      rosters.push(rosterMember.roster.id);
    }

    return rosters;
  }

  private getMemberSkills(member: UserInterface) {
    const skills = [];
    for (const skill of member.user_tags) {
      skills.push(skill.tag.id);
    }

    return skills;
  }

  private getLanguages() {
    const languages$ = this._globalDispatcher.getLanguages().subscribe(languages => {
      const countryCodes = Object.keys(languages);

      this.languages = countryCodes.map(code => {
        return {
          id: code,
          text: languages[code]
        };
      });
    });

    this.subscriptions$.push(languages$);
  }

  private getRosters() {
    const rosters$ = this._rosterDispatcher.getOrganisationRostersAsManager().subscribe(rosters => {
      this.rosters = rosters.map(roster => {
        return {
          id: roster.id,
          text: roster.name
        };
      });
    });
    this.subscriptions$.push(rosters$);
  }

  private getSkills() {
    const skills$ = this._globalDispatcher.getSkills()
      .subscribe(skills => {
        this.skills = skills.map(x => {
          return {
            id: x.id,
            text: x.name
          };
        });
      });

    this.subscriptions$.push(skills$);
  }

  private getTags() {
    const tags$ = this._tagDispatcher.getRosterTags().subscribe(tags => {
      this.tags = tags.map(x => {
        return { id: x.id, text: x.name };
      });
    });

    this.subscriptions$.push(tags$);
  }

  private arrayContainsOther(container: any[], contained: any[]) {
    const commonValues = container.filter(element => {
      return contained.indexOf(element) > -1;
    });

    return commonValues.length === contained.length;
  }
}
