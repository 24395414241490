import { AvailabilitesDispatcher } from './../../../../../services/api/availabilites/availabilites.dispatcher';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../../../shared.module';
import { Select2Module } from 'ng2-select2';

import { AlertsManagerComponent } from './alerts.component';
import { AlertsMapComponent } from './alerts-map/alerts-map.component';
import { AlertsInformationsComponent } from './alerts-informations/alerts-informations.component';


import { EditAlertModalComponent } from './modals/edit-alert/edit-alert-modal.component';
import { AlertsPreloaderMapComponent } from './alerts-preloader/alerts-preloader-map/alerts-preloader-map.component';
import { AlertsPreloaderInformationsComponent } from './alerts-preloader/alerts-preloader-informations/alerts-preloader-informations.component';
import { AlertsPreloaderSearchComponent } from './alerts-preloader/alerts-preloader-search/alerts-preloader-search.component';
import { AlertsPreloaderMembersComponent } from './alerts-preloader/alerts-preloader-members/alerts-preloader-members.component';
import { AlertsPreloaderCalendarComponent } from './alerts-preloader/alerts-preloader-calendar/alerts-preloader-calendar.component';
import { AddAlertModalComponent } from "../../../shared/members/modals/alert/add/add-alert-modal.component";
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    Select2Module,
    NgbModule
  ],
  declarations: [
    // components
    AlertsManagerComponent,
    // subcomponents
    AlertsMapComponent,

    AlertsInformationsComponent,

    EditAlertModalComponent,

    AlertsPreloaderMapComponent,

    AlertsPreloaderInformationsComponent,

    AlertsPreloaderSearchComponent,

    AlertsPreloaderMembersComponent,

    AlertsPreloaderCalendarComponent,

    AddAlertModalComponent
],
entryComponents: [
  EditAlertModalComponent,
  AddAlertModalComponent
],
providers: [
]
})
export class AlertsManagerModule { }
