import * as moment from 'moment';




export class Selection {
  static readonly  NOT_AVAILABLE_STATUS = 0;
  static readonly  AVAILABLE_STATUS = 1;
  static readonly  NOT_SURE_STATUS = 2;
  static readonly  SELECTED_FROM = 3;
  static readonly  SELECTED_BETWEEN = 4;
  static readonly  SELECTED_TO = 5;
  static readonly  DISABLED = 6;
  static readonly  NOT_SETTED_STATUS = 7;

  startDate: any;
  endDate: any;
  status: number;

  constructor(moment_from?, moment_to?, status?: number) {
    if (moment_from) {this.startDate = moment_from; }
    if (moment_to) {this.endDate = moment_to; }
    if (status !== undefined) {this.status = status; }
  }

  setStatus(status: string) {
    if (status === "available") {
      this.status = Selection.AVAILABLE_STATUS;
    } else if (status === "notAvailable") {
      this.status = Selection.NOT_AVAILABLE_STATUS;
    }else if (status === "notSure") {
      this.status = Selection.NOT_SURE_STATUS;
    } else {
      this.status = Selection.NOT_SETTED_STATUS;
    }
  }

}
