import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-preloader-members',
  templateUrl: './dashboard-preloader-members.component.html',
  styleUrls: ['./dashboard-preloader-members.component.scss']
})
export class DashboardPreloaderMembersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
