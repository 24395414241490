/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact-us/contact-us.component.ngfactory";
import * as i3 from "./contact-us/contact-us.component";
import * as i4 from "../../../../services/api/contact-us.service";
import * as i5 from "../../../../services/utils/toast.service";
import * as i6 from "../../../../services/utils/logger.service";
import * as i7 from "./contact.component";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "contact-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "contact-us", [], null, null, null, i2.View_ContactUsComponent_0, i2.RenderType_ContactUsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ContactUsComponent, [i4.ContactUsService, i5.ToastService, i6.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 49152, null, 0, i7.ContactComponent, [], null, null)], null, null); }
var ContactComponentNgFactory = i1.ɵccf("contact", i7.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
