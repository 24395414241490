<!--Profile-contact component-->
<div id="profile-contact-component" class="shadow">
  <!--Title-->
  <h2 class="text-center margin-b" i18n="@@emergencyContactLabel">Emergency Contact</h2>
  <!--List of emergency contact-->
  <div *ngIf="emergencyContacts && emergencyContacts.length > 0" class="table-responsive">
      <div class="profile-contact" *ngFor="let contact of emergencyContacts">
        <h3>{{ contact.name }} {{ contact.surname }}</h3>
        <!--To delete a emergency contact-->
        <i class="fa fa-times custom-delete-icon" *ngIf="!isSomeoneElse" (click)="deleteEmergencyContact(contact.id)"></i>
        <h4>{{ contact.email }}</h4>
        <h4>{{ contact.phone_number }}</h4>
      </div>
  </div>
  <div class="no-contact-container" *ngIf="!emergencyContacts || emergencyContacts.length === 0">
    <p>No emergency contacts provided</p>
  </div>

  <!-- + button -->
  <div class="add-button" *ngIf="!isSomeoneElse" (click)="showAddContact(content)">+</div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">
    <!--Modal header-->
    <div class="modal-header">
      <!--Title-->
      <h4 class="modal-title" i18n="@@changePicture">Emergency Contact</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">
      <form>
        <div class="form-group">
          <!--Name-->
          <input id="nameField"    type="text"  class="form-control margin-b" [(ngModel)]="nameToAdd"        name="nameToAdd"        placeholder="Enter your contact name..."   />
          <!--Surname-->
          <input id="surnameField" type="text"  class="form-control margin-b" [(ngModel)]="surnameToAdd"     name="surnameToAdd"     placeholder="Enter your contact surname..."/>
          <!--Email-->
          <input id="emailField"   type="email" class="form-control margin-b" [(ngModel)]="emailToAdd"       name="emailToAdd"       placeholder="Enter your contact email..."  />
          <!--Country code-->
          <label for="countryField" i18n="@@selectCodeLabel"> Select phone number's country code</label>
          <select id="countryField" class="form-control margin-b" [(ngModel)]="prefixe.code" name="prefixe" type="select">
            <option *ngFor="let prefixe of prefixes" [value]=prefixe.code>{{ prefixe.name }} (+{{ prefixe.code }})</option>
          </select>
          <!--Phone-->
          <input id="phoneField"   type="tel"   class="form-control margin-b" [(ngModel)]="phoneNumberToAdd" name="phoneNumberToAdd" placeholder="Enter your contact phone number..." maxlength="12"/>
          <!--Relationship-->
          <label for="relationshipField" i18n="@@selectRelationshipLabel">Select your relationship with the contact</label>
          <select id="relationshipField" class="form-control margin-b"        [(ngModel)]="relationshipToAdd" name="relationshipToAdd" type="select">
            <option value="0" i18n="@@spouseLabel"        > Spouse / Companion </option>
            <option value="1" i18n="@@parentLabel"        > Parents            </option>
            <option value="2" i18n="@@otherRelativesLabel"> Other relatives    </option>
            <option value="3" i18n="@@otherLabel"         > Other              </option>
          </select>
        </div>
      </form>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Add emergency contact-->
      <button type="button" [disabled]="loading || !nameToAdd || !surnameToAdd || !emailToAdd || !prefixe.code || !phoneNumberToAdd || !relationshipToAdd" class="btn emalsys_button green" i18n="@@addEmergencyContactLabel" (click)="addEmergencyContact()">Add Emergency Contact</button>
    </div>
  </ng-template>

  <!-- Toasts -->
  <div id="contactDeleteToast">You have deleted a contact</div>
  <div id="contactErrorToast">An error occurred! Please try again</div>
  <div id="numberErrorToast">Please, insert a correct number</div>
  <div id="wrongEmailErrorToast">Please, insert a correct email contact</div>
  <div id="contactAddToast">You have added a new contact</div>
</div>
