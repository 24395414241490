import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preloader-profile-phones',
  templateUrl: './preloader-profile-phones.component.html',
  styleUrls: ['./preloader-profile-phones.component.scss']
})
export class PreloaderProfilePhonesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
