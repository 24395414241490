<div class="alerts-preloader-process-body">
  <div class="alerts-preloader-process-small-line"></div>

  <div class="alerts-preloader-process-big-line"></div>
  <div class="alerts-preloader-process-big-line"></div>
  <div class="alerts-preloader-process-big-line"></div>

  <div class="process-preloader-container">
      <div class="preloader-calendar-icon">
        <i class="fa fa-calendar-o fa-2x" aria-hidden="true"></i>
      </div>
      <div class="preloader-lines">
      </div>
      <div class="preloader-message-icon">
        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
      </div>
      <div class="preloader-lines">
      </div>
      <div class="preloader-flight-icon">
        <i class="fa fa-plane fa-2x" aria-hidden="true"></i>
      </div>
    </div>
</div>
