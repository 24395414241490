import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../services/api/alert/alert.api';
import { AlertCachedService } from '../../../../services/api/alert/alert.cacher';
import { AlertDispatcherService } from '../../../../services/api/alert/alert.dispatcher';
import { OrganisationApiService } from '../../../../services/api/organisation/organisation.api';
import { CachedOrganisationService } from '../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { RosterService } from '../../../../services/api/roster/roster.cached';
import { RostersDispatcherService } from '../../../../services/api/roster/rosters.dispatcher';
import { CacheService } from '../../../../services/storage/cache.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { RightService } from '../../../../services/utils/right.service';
import { InitializedDashboard } from '../../../model/utils/initialization-model.interface';
import { ROUTING } from './../../../app.constants';
import { DashboardComponentModel } from './../../shared/component-model/dashboard/dashboard.component-model';
@Component({
    selector: 'dashboard',
    templateUrl: './dashboard-responder.component.html',
    styleUrls: ['./dashboard-responder.component.scss']
})
export class DashboardResponderComponent extends DashboardComponentModel implements OnInit {

    public isManagerWebsite: any;
    public events: any;

    public loadedAlerts = false;
    public loadedRosters = false;


    constructor(
        protected titleService: Title,
        protected route: ActivatedRoute,
        protected _organisationService: OrganisationApiService,
        protected _loggerService: LoggerService,
        protected _cacheService: CacheService,
        protected _alertEmalsysService: AlertService,
        protected _profileDispatcher: ProfileDispatcherService,
        protected _organisationDispatcher: OrganisationDispatcherService,
        protected _organisationCachedService: CachedOrganisationService,
        protected _rightService: RightService,
        protected _rosterService: RosterService,
        protected _alertDispatcher: AlertDispatcherService,
        protected _rosterDispatcher: RostersDispatcherService,
        protected _alertCached: AlertCachedService
    ) {
        super();
    }

    ngOnInit() {
        this.titleService.setTitle("Responder Organisation Area");
        // this._rosterService.getOrganisationRosters();
        this.isManagerWebsite = this._cacheService.get(CacheService.ISMANAGER_WEBSITE);

        this.route.paramMap.subscribe(params => {
            // this._organisationDispatcher.getSelectedOrganisation()
            // this._organisationDispatcher.selectOrganisation()
            this.subscriptions$.push(this.initialize(+params.get(ROUTING.parameter.organisationId))
                .subscribe(
                    (initialized: InitializedDashboard) => {
                        this.titleService.setTitle(initialized.organisation.name + ' - Responder');
                        this._rosterService.getOrganisationRostersAsResponder(initialized.organisation.id);
                        this.subscriptions$.push(
                            this._alertCached.getMyAlerts(initialized.organisation.id).subscribe(
                                alerts => this.loadedAlerts = true
                            )
                        );
                        this.subscriptions$.push(
                            this._rosterDispatcher.getOrganisationRostersAsResponder().subscribe(
                                _ => this.loadedRosters = true
                            )
                        );
                    }
                )
            );
        });

    }
}
