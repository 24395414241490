import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RosterMembershipService } from '../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { UserService } from '../../../../../services/api/user.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { RightService } from '../../../../../services/utils/right.service';
import { ToastService } from '../../../../../services/utils/toast.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, PAGES, ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../app.constants';
import { Availability } from '../../../../model/availability.interface';
import { DeploymentOffersModalComponent } from '../modals/deployment-offers/deployment-offers-modal.component';
import { DeploymentOrdersModalComponent } from '../modals/deployment-orders/deployment-orders-modal.component';
import { OrderDetailsModalComponent } from '../modals/order-details/order-details-modal.component';
import { OrganisationMembershipService } from './../../../../../services/api/organisation/dependencies/membership/organisation-membership.api';
import { RosterInterface } from './../../../../model/roster.interface';
import { trigger } from '@angular/animations';
import { CurrentOrderDetailsComponent } from '../modals/current-order-details/current-order-details.component';

@Component({
  selector: 'dashboard-members-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {

  /**
  * Used for define the color to display in the step-bar
  */
  public static readonly COLOR_UNDEFINED = 'grey';
  public static readonly COLOR_ACCEPTED = 'green';
  public static readonly COLOR_PENDING = 'yellow';
  public static readonly COLOR_REJECTED = 'red';

  public static readonly DEPLOYMENTOFFERS_REFUSED = 2;
  public static readonly DEPLOYMENTOFFERS_ACCEPTED = 1;
  public static readonly DEPLOYMENTOFFERS_CANCELLED = 3;
  public static readonly DEPLOYMENTOFFERS_PENDING = 0;


  @Input() rosterId;
  @Input() alertInfo;
  @Input() profile: any;
  @Input() members: any;
  @Input() organisation: any;
  @Input() page: string;
  @Input() selected = {};

  @Output() onSelected = new EventEmitter<number>();
  @Output() onManagerAction = new EventEmitter<any>();
  @Output() onCatchClickCancel = new EventEmitter<any>();
  @Output() onCatchClickDeploy = new EventEmitter<any>();
  // @Output() onProfileShow = new EventEmitter<number>();
  @Input() availabilities;
  @Input() deploymentOffers;
  @Input() deploymentOrders;
  @Input() tagsMode: boolean;
  @Input() tagsList: any[];

  public show: number = null;


  /* Template related variables */
  // If this is alert page or not
  public isAlertsPage: boolean;
  // Different variable for extendability
  public isMembersPage: boolean;
  private deployed: any;
  private end_of_deployement: Date;
  private memberCountry: string;

  /**
   * {
   *  memberId: {
   *    organisationRights: {
   *      NotManager: boolean
   *      Admin: boolean
   *      ...
   *    },
   *    rosterRights: {
   *      NotManager: boolean,
   *      Guest: boolean
   *      ...
   *    }
   *  },
   *  memberId_2: { ... }
   * }
   */
  public membersRights = {
    organisationRights: {},
    rosterRights: {},

  };

  public yearRange: string;


  constructor(
    private _rightService: RightService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _userService: UserService,
    private _organisationMembership: OrganisationMembershipService,
    private _rosterService: RosterMembershipService,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
    this.isAlertsPage = this.page === PAGES.manager.organisation.alerts;
    this.isMembersPage = this.page === PAGES.manager.organisation.members;

    // The year range is from last year to +10 years
    // I could put more that 10 years, but I'm optimist.
    this.yearRange = (new Date().getFullYear() - 1).toString() + ":" + (new Date().getFullYear() + 10).toString();

    document.getElementsByTagName('body')[0].addEventListener('click', x => {
      if ((<HTMLElement>x.target).className !== 'step') {
        this.show = null;
      }
    });


    Object.keys(this.deploymentOrders).forEach((deploymentOrder) => {
      this.deploymentOrders[deploymentOrder] = this.deploymentOrders[deploymentOrder].current_deployments
        .concat(this.deploymentOrders[deploymentOrder].old_deployments)
        .concat(this.deploymentOrders[deploymentOrder].upcoming_deployments);
    });

    if (this.isMembersPage) {
      this.getAllMembersRights();
    }
  }

  /**
   * ====================================
   *  BEGIN MANAGEMENT FUNCTIONS.
   *  USED ONLY IN THE ORGANIZATION VIEW
   *
   *  NOT INTO ALERTS!!
   * =======================================
   */

  canManageRights(member) {
    let tagsMode = this.tagsMode;
    let id = this.rosterId;
    let organisationAssociate = this.checkOrganisationAssociate();
    let rosterGuest = this.checkRosterGuest();
    let rosterMember = this.isActiveRosterMember(member) === 1;
    return !tagsMode &&
      (
        (this.rosterId === RosterInterface.NO_ROSTER && organisationAssociate) ||
        (this.rosterId !== RosterInterface.NO_ROSTER && rosterGuest && rosterMember)
      );
  }

  getAllMembersRights() {
    this.members.forEach(member => {
      this.membersRights.organisationRights[String(member.id)] = {};
      this.membersRights.rosterRights[String(member.id)] = {};
      // Organisation manager rights
      Object.keys(ORGANISATION_MANAGER_RIGHTS_LEVEL).forEach(right => {
        this.membersRights.organisationRights[member.id][right] =
          this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL[right]);
      });

      // Roaster manager right
      Object.keys(ROSTER_MANAGER_RIGHTS_LEVEL).forEach(right => {
        this.membersRights.rosterRights[member.id][right] =
          this._rightService.isRosterManagerRight(member, this.organisation.id, ROSTER_MANAGER_RIGHTS_LEVEL[right]);
      });
    });
  }

  isNotOrganisationManager(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  isOrganisationAssociate(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  isOrganisationAdmin(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Admin']);
  }

  isOrganisationSuperAdmin(member) {
    return this._rightService.isOrganisationManagerRight(member, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['SuperAdmin']);
  }

  isNotRosterManager(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  isRosterGuest(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  checkRosterGuest() {
    return this._rightService.checkRosterManagerRight(this.profile, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);

  }

  isRosterRightManager(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Manager']);
  }

  isRosterSupervisor(member) {
    return this._rightService.isRosterManagerRight(member, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Supervisor']);
  }

  higherThanOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  higherThanRosterGuest() {
    return this._rightService.checkRosterManagerRight(this.profile, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
  }

  checkIsOwner(member) {
    let owner = false;
    if (this.profile.id === member.id) {
      owner = true;
    }
    return owner;
  }

  checkboxChange(id) {
    this.onSelected.emit(id);
  }

  isMemberAvailable(endUnavailabality) {
    return new Date(endUnavailabality).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }


  openProfile(member) {
    this._userService.openProfile(member, this.organisation.id, this.rosterId);
  }

  showDropdown(id) {
    if (this.show === id) {
      this.show = null;
    } else {
      this.show = id;
    }
  }

  freezeOrganisationMember(userId) {
    if (this.selected[userId]) {
      this.checkboxChange(userId);
    }
    this._organisationMembership.freezeOrganisationMember(this.organisation.id, userId).subscribe(res => {
      this._loggerService.log("organisation member froze !!!", res);
      this.onManagerAction.emit(res);
    });
  }

  activeOrganisationMember(userId) {
    this._organisationMembership.activateOrganisationMember(this.organisation.id, userId).subscribe(res => {
      this._loggerService.log("organisation member activated !!!", res);
      this.onManagerAction.emit(res);
    });
  }


  isActiveOrganisationMember(member) {
    let state = 0;
    member.organisation_members.forEach((organisationMember) => {
      if (organisationMember.organisation.id === this.organisation.id) {
        state = organisationMember.state;
      }
    });
    return state;
  }

  isActiveRosterMember(member) {
    let state = 0;
    member.roster_members.forEach((rosterMember) => {
      if (rosterMember.roster.id === this.rosterId) {
        state = rosterMember.state;
      }
    });
    return state;
  }

  removeFromOrganisation(member) {
    if (!this.isNotOrganisationManager(member)) {
      let context = "remove";
      this._organisationMembership.deleteOrganisationManager(this.organisation.id, member.id, context).subscribe(resbis => {
        this._loggerService.log("organisation Manager removed", resbis);
        this.onManagerAction.emit(resbis);
      });
    } else {
      this._organisationMembership.deleteOrganisationMember(this.organisation.id, member.id).subscribe(res => {
        this._loggerService.log("organisation Member removed", res);
        this.onManagerAction.emit(res);
      });
    }
  }

  demoteToOrganisationMember(userId) {
    this.showLoadingToast();
    let context = "demote";
    this._organisationMembership.deleteOrganisationManager(this.organisation.id, userId, context).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("organisation member Demoted !!!", res);
      this.onManagerAction.emit(res);
    });
  }

  demoteToRosterMember(userId) {
    this.showLoadingToast();
    this._rosterService.removeRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("roster member Demoted !!!", res);
      this.onManagerAction.emit(res);
    });
  }

  removeRosterMember(member) {
    this._rosterService.removeRosterMember(this.rosterId, member.id).subscribe(res => {
      if (!this.isNotRosterManager(member)) {
        this._rosterService.removeRosterManager(this.rosterId, member.id).subscribe(resbis => {
          this._loggerService.log("roster Manager removed", resbis);
          this.onManagerAction.emit(resbis);
        });
      } else {
        this._loggerService.log("roster Member removed", res);
        this.onManagerAction.emit(res);
      }
    });
  }

  promoteToOrganisationManager(userId, userEmail) {
    let params = {
      emails: [],
      rights: 1
    };
    this.showLoadingToast();
    params.emails.push(userEmail);
    this._organisationMembership.addManagers(this.organisation.id, params).subscribe(add => {
      this._organisationMembership.setActiveOrganisationManager(this.organisation.id, userId).subscribe(res => {
        this.showRoleChangedToast();
        this._loggerService.log("Promoted organisation manager!!!", res);
        this.onManagerAction.emit(res);
      });
    });
  }

  promoteToRosterManager(userId, userEmail) {
    let params = {
      emails: [],
      rights: 1
    };
    this.showLoadingToast();
    params.emails.push(userEmail);
    this._rosterService.inviteManagers(this.rosterId, params).subscribe(add => {
      this._rosterService.setActiveRosterManager(this.rosterId, userId).subscribe(res => {
        this.showRoleChangedToast();
        this._loggerService.log("Promoted roster manager!!!", res);
        this.onManagerAction.emit(res);
      });
    });
  }

  changeToAssociate(userId) {
    this.showLoadingToast();
    this._organisationMembership.setAssociateRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Associate!!!", res);
      this.onManagerAction.emit(res);
    });
  }

  changeToAdmin(userId) {
    this.showLoadingToast();
    this._organisationMembership.setManagerRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Admin!!!", res);
      this.onManagerAction.emit(res);
    });
  }
  changeToSuperAdmin(userId) {
    this.showLoadingToast();
    this._organisationMembership.setAdminRightsToOrganisationManager(this.organisation.id, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Super admin!!!", res);
      this.onManagerAction.emit(res);
    });
  }


  changeToGuest(userId) {
    this.showLoadingToast();
    this._rosterService.setGuestRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Guest!!!", res);
      this.onManagerAction.emit(res);
    });
  }

  changeToManager(userId) {
    this.showLoadingToast();
    this._rosterService.setManagerRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Manager!!!", res);
      this.onManagerAction.emit(res);
    });
  }
  changeToSupervisor(userId) {
    this.showLoadingToast();
    this._rosterService.setSupervisorRightsToRosterManager(this.rosterId, userId).subscribe(res => {
      this.showRoleChangedToast();
      this._loggerService.log("Promoted to Supervisor!!!", res);
      this.onManagerAction.emit(res);
    });
  }

  /** =================================================
   *          END OF MANAGEMENT FUNCTIONS!
   *  =================================================
   * */


  showCalendar(id) {
    if (this.show === id) {
      this.show = null;
    } else if (this.availabilities[id].length > 0) {
      this.show = id;
    } else {
      this.showToastNoAvailabilities();
    }
  }
  showToastNoAvailabilities() {
    this._toastService.show("noAvailabilitiesToast", "show", 3000);
  }

  getCssClassStatus(id, date): string {
    let dateCalendar = new Date(date.year, date.month, date.day);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let status = null;
    if (this.availabilities[id] && dateCalendar >= currentDate) {
      status = 6;
      for (let i = 0; i < this.availabilities[id].length; i++) {
        let availability_start = new Date(this.availabilities[id][i].start_date);
        availability_start.setDate(availability_start.getDate());
        availability_start.setHours(0, 0, 0, 0);
        let availability_end = new Date(this.availabilities[id][i].end_date);
        availability_end.setDate(availability_end.getDate());
        availability_end.setHours(0, 0, 0, 0);
        let event_start = new Date(this.alertInfo.start_date);
        event_start.setHours(0, 0, 0, 0);
        let event_end = new Date(this.alertInfo.end_date);
        event_end.setHours(0, 0, 0, 0);
        if (availability_start <= dateCalendar && dateCalendar <= availability_end) {
          status = this.availabilities[id][i].status;
        } else if (event_start > dateCalendar || dateCalendar > event_end) {
          status = 7;
        }
      }
    }
    return this.statusToClass(status);
  }

  private statusToClass(status?: string): string {
    let res = "";
    switch (Number(status)) {
      case 0:
        res = "notAvailable";
        break;
      case 1:
        res = "available";
        break;
      case 2:
        res = "notSure";
        break;
      case 6:
        res = "notSetted";
        break;
      default:
        res = "disabled";
        break;
    }
    return res;
  }

  showDeploymentOffers(id) {
    let modalRef = this._modalService.open(DeploymentOffersModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.deploymentOffers = this.deploymentOffers[id];
    modalRef.componentInstance.profile = this.profile;
    modalRef.componentInstance.organisation = this.organisation;
    modalRef.componentInstance.onClickCancel.subscribe(($e) => {
      this.onCatchClickCancel.emit($e);
    });
  }

  setProgressAvailabilities(id) {
    if (this.availabilities[id] && this.availabilities[id].length > 0) {

      let counterAvailable = 0;
      let counterNotSure = 0;
      let counterUnavailable = 0;

      for (let i = 0; i < this.availabilities[id].length; i++) {
        if (this.availabilities[id][i].status === Availability.AVAILABLE) {
          counterAvailable++;
        } else if (this.availabilities[id][i].status === Availability.UNAVAILABLE) {
          counterUnavailable++;
        } else if (this.availabilities[id][i].status === Availability.NOTSURE) {
          counterNotSure++;
        }
      }

      if (counterUnavailable === this.availabilities[id].length) {
        return ListComponent.COLOR_REJECTED;
      } else if (counterAvailable > 0) {
        return ListComponent.COLOR_ACCEPTED;
      } else if (counterNotSure > 0) {
        return ListComponent.COLOR_PENDING;
      } else {
        return ListComponent.COLOR_UNDEFINED;
      }
    } else {
      return ListComponent.COLOR_UNDEFINED;
    }
  }

  setProgressOffers(id) {
    const today = new Date();

    if (this.deploymentOffers[id] && this.deploymentOffers[id].length > 0) {

      let counterAccepted = 0;
      let counterPending = 0;
      let counterRefused = 0;
      let counterCanceled = 0;

      for (let i = 0; i < this.deploymentOffers[id].length; i++) {
        if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_ACCEPTED && new Date(this.deploymentOffers[id][i].end_date) > today) {
          counterAccepted++;
        } else if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_REFUSED && new Date(this.deploymentOffers[id][i].end_date) > today) {
          counterRefused++;
        } else if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING && new Date(this.deploymentOffers[id][i].end_date) > today) {
          counterPending++;
        } else if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_CANCELLED && new Date(this.deploymentOffers[id][i].end_date) > today) {
          counterCanceled++;
        } else {
          counterCanceled++;
        }
      }

      if (counterPending > 0) {
        return ListComponent.COLOR_PENDING;
      } else if (counterAccepted > 0) {
        return ListComponent.COLOR_ACCEPTED;
      } else if (counterCanceled === this.deploymentOffers[id].length) {
        return ListComponent.COLOR_UNDEFINED;
      } else if (counterRefused + counterCanceled === this.deploymentOffers[id].length) {
        return ListComponent.COLOR_REJECTED;
      }
    } else {
      return ListComponent.COLOR_UNDEFINED;
    }
  }

  setProgressOffersForDeployed(id) {
    let counterPending = 0;
    for (let i = 0; i < this.deploymentOffers[id].length; i++) {
      if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING) {
        counterPending++;
      }
    }

    if (counterPending > 0) {
      return ListComponent.COLOR_PENDING;
    } else {
      return ListComponent.COLOR_ACCEPTED;
    }
  }

  checkFutureDeployments(id) {
    const today = new Date();
    let futureCounter = 0;
    let pendingCounter = 0;
    this.deploymentOrders[id]['upcoming_deployments'].forEach(order => {
      if (new Date(order.deploymentdate) > today) {
        futureCounter++;
      }
    });

    if (futureCounter > 0) {
      return ListComponent.COLOR_ACCEPTED;
    } else {
      this.deploymentOffers[id].forEach(order => {
        if (order.valid === 1 && new Date(order.start_date) > today) {
          pendingCounter++;
        }
      });
      if (pendingCounter > 0) {
        return ListComponent.COLOR_PENDING;
      } else {
        return ListComponent.COLOR_UNDEFINED;
      }
    }
  }

  checkOffers(id) {
    if (this.deploymentOffers[id] && this.deploymentOffers[id].length > 0) {
      let counterAcceptedOrPending = 0;
      for (let i = 0; i < this.deploymentOffers[id].length; i++) {
        if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_ACCEPTED ||
          this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING) {
          counterAcceptedOrPending++;
        }
      }
      if (counterAcceptedOrPending > 1) {
        return true;
      } else {
        return false;
      }

    }
  }

  checkRedeploy(id) {
    if (this.deploymentOffers[id]) {
      for (let i = 0; i < this.deploymentOffers[id].length; i++) {
        if (!this.deploymentOffers[id][i].deployment && (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_ACCEPTED ||
          this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING)) {
          return true;
        }
      }
    }
    return false;

  }


  getAcceptedOffers(id) {
    let arrayAcceptedOffers = [];
    this._loggerService.log(this.deploymentOffers[id]);
    for (let i = 0; i < this.deploymentOffers[id].length; i++) {
      if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING ||
        this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_ACCEPTED) {
        arrayAcceptedOffers.push(this.deploymentOffers[id][i]);
      }
    }
    return arrayAcceptedOffers;
  }

  showDeploymentOrders(id) {
    let modalRef = this._modalService.open(DeploymentOrdersModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });

    modalRef.componentInstance.deploymentOffers = this.getAcceptedOffers(id);
    modalRef.componentInstance.profile = this.profile;
    modalRef.componentInstance.organisation = this.organisation;
    modalRef.componentInstance.selectedEventID = this.alertInfo.id;
    modalRef.componentInstance.selectedUser = id;
    modalRef.componentInstance.onClickDeploy.subscribe(($e) => {
      this.onCatchClickDeploy.emit($e);
    });
  }

  showDeploymentOrderDetails(id) {
    let modalRef = this._modalService.open(OrderDetailsModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });

    let deployments = this.deploymentOrders[id]['current_deployments'];
    deployments = deployments.concat( this.deploymentOrders[id]['upcoming_deployments']);

    modalRef.componentInstance.deploymentOrders = deployments;
  }

  showCurrentDeploymentOrderDetails(id) {
    let modalRef = this._modalService.open(CurrentOrderDetailsComponent, { windowClass: 'emalsys_modal'});

    let deployments = this.deploymentOrders[id]['current_deployments'];
    deployments = deployments.concat( this.deploymentOrders[id]['upcoming_deployments']);

    modalRef.componentInstance.deploymentOrders = deployments;
    modalRef.componentInstance.country = this.alertInfo.country_name;

  }


  checkDeployementOrderByEvent(memberId) {
    const today = new Date();
    this.deployed = 0;

    if (this.deploymentOrders[memberId]) {

      let currentDeployments = this.deploymentOrders[memberId]['current_deployments'];

      if (typeof currentDeployments !== "undefined") {
        for (let i = 0; i < currentDeployments.length; i++) {
          this.end_of_deployement = new Date(currentDeployments[i].end_date);
          if (this.end_of_deployement > today) {
            this.memberCountry = (currentDeployments[i].event['country_name']);
            this.deployed += 1;
          }
        }
      }



      let upcomingDeployments = this.deploymentOrders[memberId]['upcoming_deployments'];

      if (this.deployed <= 0) {
        return false;
      } else {
        return true;
      }

    }
    return false;

  }

  checkDeploymentOrderByMember(memberId) {

    if (typeof this.deploymentOrders[memberId] !== "undefined") {
      if (this.deploymentOrders[memberId]['current_deployments'].length || this.deploymentOrders[memberId]['old_deployments'].length ||
      this.deploymentOrders[memberId]['upcoming_deployments'].length) {
        return true;
      } else {
        return false;
      }
    }

  }

  checkAvailability(argAvailability) {
    return Availability.checkAvailability(argAvailability);
  }


  showLoadingToast() {
    this._toastService.show("loading-member-promotion", "show");
  }

  showRoleChangedToast() {
    this._toastService.show("role-changed", "show");
  }

  showSuccessDemotionToast() {
    this._toastService.show("role-changed", "show");
  }
}
