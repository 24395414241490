
export const firebaseConfig = {
    apiKey: "AIzaSyDUMWz1ajcMhaxAA9vEGIsRmzp_FecYjOA",
    authDomain: "emalsys.firebaseapp.com",
    databaseURL: "https://emalsys.firebaseio.com",
    projectId: "emalsys",
    storageBucket: "emalsys.appspot.com",
    messagingSenderId: "190007968532",
    appId: "1:190007968532:web:260502463b12cd59"
};
