<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Deployment order details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="alert alert-warning" *ngIf="selectedOrder.event.country_name !== country">
      <strong>Warning!</strong> This member is currently deployed in another country.
  </div>
  <div *ngIf="deploymentOrders && deploymentOrders.length > 0">
    <h2> Current deployment order : {{selectedOrder.position}} </h2>
  </div>
    <div class="deployment-order-description-container">
      <div class="deployment-order-description-leftpart">
        <div class="deployment-order-description-subheader">
          <p class="deployment-order-description-position">Position: {{selectedOrder.deployment_order.position}}</p>
          <p class="deployment-order-description-country">Country: {{selectedOrder.event.country_name}}</p>
          <div class="deployment-order-description-date-container">
            <p class="deployment-order-description-date">{{selectedOrder.deploymentdate | date: "fullDate"}}</p>
            <i class="fa fa-arrow-right fa-1x deployment-order-description-arrow" aria-hidden="true"></i>
            <p class="deployment-order-description-date">{{selectedOrder.end_date | date: "fullDate"}}</p>
          </div>

          <p class="deployment-order-description-sender">Deploying manager: {{selectedOrder.deployment_order.sender.firstname}} {{selectedOrder.deployment_order.sender.lastname}} - {{selectedOrder.deployment_order.sender.profile.contact_email}}</p>

        </div>
        <p class="deployment-order-description-description">{{selectedOrder.message}}</p>
      </div>
      <div class="deployment-order-description-rightpart">
        <div class="deployment-order-description-additional">
          <div class="deployment-order-description-additional-airport" *ngIf="selectedOrder.airport">
            <p class="deployment-order-description-additional-airport-label">Airport</p>
            <p class="deployment-order-description-additional-airport-value">{{selectedOrder.airport}}</p>
          </div>
          <div class="deployment-order-description-additional-flight" *ngIf="selectedOrder.flight_number">
            <p class="deployment-order-description-additional-flight-label">Flight number</p>
            <p class="deployment-order-description-additional-flight-value">{{selectedOrder.flight_number}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-name" *ngIf="selectedOrder.insurance_name">
            <p class="deployment-order-description-additional-insurance-name-label">Insurance name</p>
            <p class="deployment-order-description-additional-insurance-name-value">{{selectedOrder.insurance_name}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-number" *ngIf="selectedOrder.insurance_number">
            <p class="deployment-order-description-additional-insurance-number-label">Insurance number</p>
            <p class="deployment-order-description-additional-insurance-number-value">{{selectedOrder.insurance_number}}</p>
          </div>
        </div>
        <div class="deployment-order-description-documents" *ngIf="selectedOrder.deployment_document.length">
          <p class="deployment-order-description-documents-label">Deployment documents</p>

          <div *ngFor="let doc of selectedOrder.deployment_document">
            <button class="deployment-order-description-doc-name" (click)="downloadDoc(doc.document)">
            <img class="doc-icon" src="assets/images/Docs_Icon.png" />
            <p>{{doc.document.title}}</p>
          </button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 1.5cm;">
      <h2>OLD DEPLOYMENTS</h2>
    </div>
    <div *ngIf="deploymentOrders && deploymentOrders.length > 0">
      <label for="DeploymentOrders">Select the deployment order:</label>
      <select class="emalsys_select" id="DeploymentOrders" [(ngModel)]="selectedOldOrder" name="order" type="select">
                <option *ngFor="let order of deploymentOrders" [ngValue]="order">{{ order.deployment_order.position }}</option>
            </select>
    </div>
    <div class="deployment-order-description-container">
      <div class="deployment-order-description-leftpart">
        <div class="deployment-order-description-subheader">
          <p class="deployment-order-description-position">Position: {{selectedOldOrder.deployment_order.position}}</p>
          <p class="deployment-order-description-country">Country: {{selectedOldOrder.event.country_name}}</p>
          <div class="deployment-order-description-date-container">
            <p class="deployment-order-description-date">{{selectedOldOrder.deploymentdate | date: "fullDate"}}</p>
            <i class="fa fa-arrow-right fa-1x deployment-order-description-arrow" aria-hidden="true"></i>
            <p class="deployment-order-description-date">{{selectedOldOrder.end_date | date: "fullDate"}}</p>
          </div>

          <p class="deployment-order-description-sender">Deploying manager: {{selectedOldOrder.deployment_order.sender.firstname}} {{selectedOldOrder.deployment_order.sender.lastname}} - {{selectedOldOrder.deployment_order.sender.profile.contact_email}}</p>

        </div>
        <p class="deployment-order-description-description">{{selectedOldOrder.message}}</p>
      </div>
      <div class="deployment-order-description-rightpart">
        <div class="deployment-order-description-additional">
          <div class="deployment-order-description-additional-airport" *ngIf="selectedOldOrder.airport">
            <p class="deployment-order-description-additional-airport-label">Airport</p>
            <p class="deployment-order-description-additional-airport-value">{{selectedOldOrder.airport}}</p>
          </div>
          <div class="deployment-order-description-additional-flight" *ngIf="selectedOldOrder.flight_number">
            <p class="deployment-order-description-additional-flight-label">Flight number</p>
            <p class="deployment-order-description-additional-flight-value">{{selectedOldOrder.flight_number}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-name" *ngIf="selectedOldOrder.insurance_name">
            <p class="deployment-order-description-additional-insurance-name-label">Insurance name</p>
            <p class="deployment-order-description-additional-insurance-name-value">{{selectedOldOrder.insurance_name}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-number" *ngIf="selectedOldOrder.insurance_number">
            <p class="deployment-order-description-additional-insurance-number-label">Insurance number</p>
            <p class="deployment-order-description-additional-insurance-number-value">{{selectedOldOrder.insurance_number}}</p>
          </div>
        </div>
        <div class="deployment-order-description-documents" *ngIf="selectedOldOrder.deployment_document.length">
          <p class="deployment-order-description-documents-label">Deployment documents</p>

          <div *ngFor="let doc of selectedOldOrder.deployment_document">
            <button class="deployment-order-description-doc-name" (click)="downloadDoc(doc.document)">
            <img class="doc-icon" src="assets/images/Docs_Icon.png" />
            <p>{{doc.document.title}}</p>
          </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="upcomingOrders && upcomingOrders.length > 0" style="margin-top: 1.5cm;">
      <h2>UPCOMING DEPLOYMENTS</h2>
    </div>
    <div *ngIf="upcomingOrders && upcomingOrders.length > 0">
      <label for="upcomingOrders">Select the deployment order:</label>
      <select class="emalsys_select" id="upcomingOrders" [(ngModel)]="selectedUpcomingOrder" name="order" type="select">
                <option *ngFor="let order of upcomingOrders" [ngValue]="order">{{ order.deployment_order.position }}</option>
            </select>
    </div>
    <div *ngIf="upcomingOrders && upcomingOrders.length > 0" class="deployment-order-description-container">
      <div class="deployment-order-description-leftpart">
        <div class="deployment-order-description-subheader">
          <p class="deployment-order-description-position">Position: {{selectedUpcomingOrder.deployment_order.position}}</p>
          <p class="deployment-order-description-country">Country: {{selectedUpcomingOrder.event.country_name}}</p>
          <div class="deployment-order-description-date-container">
            <p class="deployment-order-description-date">{{selectedUpcomingOrder.deploymentdate | date: "fullDate"}}</p>
            <i class="fa fa-arrow-right fa-1x deployment-order-description-arrow" aria-hidden="true"></i>
            <p class="deployment-order-description-date">{{selectedUpcomingOrder.end_date | date: "fullDate"}}</p>
          </div>

          <p class="deployment-order-description-sender">Deploying manager: {{selectedUpcomingOrder.deployment_order.sender.firstname}} {{selectedUpcomingOrder.deployment_order.sender.lastname}} - {{selectedUpcomingOrder.deployment_order.sender.profile.contact_email}}</p>

        </div>
        <p class="deployment-order-description-description">{{selectedUpcomingOrder.message}}</p>
      </div>
      <div class="deployment-order-description-rightpart">
        <div class="deployment-order-description-additional">
          <div class="deployment-order-description-additional-airport" *ngIf="selectedUpcomingOrder.airport">
            <p class="deployment-order-description-additional-airport-label">Airport</p>
            <p class="deployment-order-description-additional-airport-value">{{selectedUpcomingOrder.airport}}</p>
          </div>
          <div class="deployment-order-description-additional-flight" *ngIf="selectedUpcomingOrder.flight_number">
            <p class="deployment-order-description-additional-flight-label">Flight number</p>
            <p class="deployment-order-description-additional-flight-value">{{selectedUpcomingOrder.flight_number}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-name" *ngIf="selectedUpcomingOrder.insurance_name">
            <p class="deployment-order-description-additional-insurance-name-label">Insurance name</p>
            <p class="deployment-order-description-additional-insurance-name-value">{{selectedUpcomingOrder.insurance_name}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-number" *ngIf="selectedUpcomingOrder.insurance_number">
            <p class="deployment-order-description-additional-insurance-number-label">Insurance number</p>
            <p class="deployment-order-description-additional-insurance-number-value">{{selectedUpcomingOrder.insurance_number}}</p>
          </div>
        </div>
        <div class="deployment-order-description-documents" *ngIf="selectedUpcomingOrder.deployment_document.length">
          <p class="deployment-order-description-documents-label">Deployment documents</p>

          <div *ngFor="let doc of selectedUpcomingOrder.deployment_document">
            <button class="deployment-order-description-doc-name" (click)="downloadDoc(doc.document)">
            <img class="doc-icon" src="assets/images/Docs_Icon.png" />
            <p>{{doc.document.title}}</p>
          </button>
          </div>
        </div>
      </div>
    </div>

</div>

<!--Modal footer-->
<div class="modal-footer">

</div>
