<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Send a message</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="info-component">
    <i class="fa fa-info-circle custom-info-icon" aria-hidden="true"></i>
    <p>Your message will be sent to all selected members</p>
  </div>
  <div class="title-component">
    <input class="form-control" placeholder="Title" [(ngModel)]="emailTitle" />
    <h6>Push Notifications will only contain the title (up to 115 characters)</h6>
  </div>
  <textarea class="form-control" [rows]="4" pInputTextarea autoResize="true" [(ngModel)]="emailBody">
</textarea >
<div class="file-component">
<input id="roster-logo" type="file" (change)="fileChange($event)"/>
  <h6>Maximum size : 2 Mb</h6>
</div>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button [disabled]="!emailTitle || !emailBody || loading"
          [ngClass]="{'disabled': !emailTitle || !emailBody || loading}"
          class="btn emalsys_button green margin-b margin-r" (click)="sendEmail()">Send <i class="fa fa-share" aria-hidden="true"></i>
</button>
</div>
