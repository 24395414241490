import { RosterMembershipService } from './../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { MembersDispatcherService } from '../../../../../services/api/member/members.dispatcher';
import { Injectable } from '@angular/core';

@Injectable()
export class RosterPageService {

    constructor(
        private _rosterMembership: RosterMembershipService,
        private _memberDispatcher: MembersDispatcherService
    ) {}

    public setRosterId(rosterId: number) {
        this.getRosterMembers(rosterId);
    }

    public getRosterMembers(rosterId: number) {
        // API call, no need to unsubscribe
        this._rosterMembership.getRosterMembers(rosterId)
        .subscribe(
            members => this._memberDispatcher.publishRosterMembers(members)
        );
    }
}