import { EventEmitter } from '@angular/core';
import { ModalComponent } from '../modal.component';
export class ModalDataComponent extends ModalComponent {
    constructor() {
        super(...arguments);
        this.closeMenu = new EventEmitter();
        this.clearAction = new EventEmitter();
        this.exportMessage = false;
        this.readyState = 0;
    }
    ngOnInit() {
        if (this.data) {
            this.dataModal = this.data;
        }
    }
    ngOnChanges() {
        if (this.data) {
            this.dataModal = this.data;
        }
    }
    fermer() {
        this.exportMessage = false;
        this.closeMenu.emit(true);
        this.clearAction.emit(true);
    }
}
