<div class="orga-news-component" *ngIf="allNews && orgaDetails">

	<div class="news-menu text-center shadow" *ngIf="menuActive">
		<div class="big-title">
			<button class="hide-show-button" (click)="activateMenu()"> <i class="fa fa-angle-left fa-2x"> </i> </button>
			<h2> Your News </h2>
		</div>
		<div class="picture-social">
			<a *ngIf="orgaDetails && orgaDetails.facebook" [href]='orgaDetails?.facebook' target="_blank">
				<img class="social-image" src="assets/icons/facebook.png" />
			</a>
			<a *ngIf="orgaDetails && orgaDetails.twitter" [href]='orgaDetails?.twitter' target="_blank">
				<img class="social-image" src="assets/icons/twitter.png" />
			</a>
			<a *ngIf="orgaDetails && orgaDetails.linked_in" [href]='orgaDetails?.linked_in' target="_blank">
				<img class="social-image" src="assets/icons/linkedin.png" />
			</a>
			<a *ngIf="orgaDetails && orgaDetails.website" [href]='orgaDetails?.website' target="_blank">
				<img class="social-image" src="assets/icons/website.png" />
			</a>
		</div>
		<button *ngFor="let news of allNews" class="menu-item" (click)="navigate(news.id)">
			{{news.title}}
		</button>
	</div>

	<div class="hide-show-container" *ngIf="!menuActive">
		<button class="hide-show-button" (click)="activateMenu()"> <i class="fa fa-angle-right"></i> </button>
	</div>

	<div class="all-news" [ngClass]=" { 'menu-hide': !menuActive, 'menu-show': menuActive } ">
		<div class="list" *ngFor="let new of allNews">

			<div [id]="'ancre-'+new.id" class="news-container shadow" *ngIf="new">
				<!-- Picture of the new -->
				<div class="news-image" (mouseenter)="showButton(new.id);" (mouseleave)="hideButton(new.id);">
					<div *ngIf="new.image_name">
						<img class="logo-image" src="{{newsInfo.image_name}}">
					</div>
					<div *ngIf="!new.image_name">
						<img class="logo-image" src="/assets/images/default_new.png">
					</div>
					<!-- TODO Add picture management -->
					<div class="upload-picture" [id]="new.id">
						<span class="btn">Change Picture</span>
						<input type=file />
					</div>
				</div>
				<!-- Middle content -->
				<div class="news-description text-center">
					<!-- If normal display -->
					<h2 *ngIf="(!editEnabled) || (new.id != newsEdited.id)" class="text-center">
						{{ new.title }}
					</h2>
					<span *ngIf="(!editEnabled) || (new.id != newsEdited.id)" class="news-type">
						{{type[new.type].text}}
					</span>
					<!-- If edit mode -->
					<div class="edit-title" *ngIf="editEnabled && new.id==newsEdited.id">
						<input [value]="newsInfo.title" (input)="newsEdited.title=$event.target.value" />
						<select2 cssImport="true" width="20%" [data]="type" [value]="new.type" [options]="{multiple: false, minimumResultsForSearch: 5}"
						(valueChanged)="newsEdited.type=$event.value">
						</select2>
					</div>
					<div *ngIf="!toBeDeleted">
						<p class="news-content" *ngIf="(!editEnabled) || (new.id != newsEdited.id)" [innerHTML]='new.content'></p>
						<textarea class="edit-content" *ngIf="editEnabled && new.id==newsEdited.id" [rows]="10" autoResize="true" [value]="new.content" (input)="newsEdited.content=$event.target.value"></textarea>
						<div class="save-button" *ngIf="editEnabled && new.id==newsEdited.id">
							<button class="btn emalsys_button red" type="button" name="button" (click)="editEnabled = false;">Cancel</button>
							<button class="btn emalsys_button green" type="button" name="button" (click)="saveChanges(); editEnabled=false;">Save</button>
						</div>
					</div>
					<!-- If delete mode -->
					<div *ngIf="toBeDeleted && new.id==newsEdited.id">
						<p>Are you sure you want to delete this news?</p>
						<button class="btn emalsys_button" type="button" name="button" (click)="toBeDeleted = false;">Cancel</button>
						<button class="btn emalsys_button red" type="button" name="button" (click)="deleteNews()">Delete</button>
					</div>
				</div>
				<!-- Edition/deletion -->
				<div *ngIf="checkOrganisationAssociate()" class="news-buttons">
					<div class="edit-delete">
						<div class="edit-button" *ngIf="!editEnabled" (click)="toBeDeleted = false; editEnabled = true; newsEdited = new;">
							<i class="fa fa-pencil custom-edit-icon"></i>
						</div>
						<div class="edit-button delete" *ngIf="!toBeDeleted" (click)="toBeDeleted = true; editEnabled = false; newsEdited = new;">
							<i class="fa fa-trash custom-edit-icon"></i>
						</div>
					</div>
					<div *ngIf="!editEnabled" class="news-private">
						<label class="private-switch">
							<input type="checkbox" [(ngModel)]="new.private" (change)="newsEdited.private=new.private; saveChanges();">
							<span class="slider round"></span>
						</label>
						<h6 class="private-label" *ngIf="new.private">PRIVATE</h6>
						<h6 class="private-label" *ngIf="!new.private">PUBLIC</h6>
					</div>
				</div>
			</div>
		</div>

		<div class="empty" *ngIf='allNews.length==0'>
			<img src="../../../../../assets/images/not-yet-field.png"/>
			<h2>This section is empty</h2>
		</div>

	</div>
</div>

<news-preloader class="orga-news-component" *ngIf="!allNews || !orgaDetails">

</news-preloader>

<!-- Modal to create news -->
<ng-template #newsModal let-c="close" let-d="dismiss">
	<!--Modal header-->
	<div class="modal-header">
		<h4 class="modal-title">Create News</h4>
		<button type="button" class="close" aria-label="Close" (click)="d()">
			<span aria-hidden="true">
				<i class="fa fa-times"></i>
			</span>
		</button>
	</div>

	<!--Modal body-->
	<div class="modal-body">
		<div class="news-properties">
			<label for="news-title">Title *</label>
			<input id="news-title" type="text" [(ngModel)]="newsCreated.title" />
			<label for="news-content">Content</label>
			<textarea id="news-content" [(ngModel)]="newsCreated.content" [rows]="10" autoResize="true"></textarea>
			<label for="news-type">Type</label>
			<select2 id="news-type" cssImport="true" width="20%" [data]="type" [options]="{multiple: false, minimumResultsForSearch: 5}"
			 (valueChanged)="newsCreated.type=$event.value"></select2>
			<label for="news-privacy">Private</label>
			<input id="news-privacy" type="checkbox" [(ngModel)]="newsCreated.private" />
		</div>
		<!-- TODO Add picture management -->
		<div class="news-picture">
			<label for="news-picture">Picture</label>
			<input id="news-picture" type="file" disabled/>
		</div>
	</div>
	<div class="loading" *ngIf="loading">
		<spinner-component>
		</spinner-component>
	</div>
	<!--Modal footer-->
	<div class="modal-footer">
		<button type="submit" class="btn emalsys_button green" (click)="createNews()" [disabled]="!newsCreated.title || loading">Create</button>
	</div>
</ng-template>

<div id="snackbarNewsCreated">News created</div>
<div id="snackbarNewsDeleted">News deleted</div>
<div id="snackbarNewsUpdated">News updated</div>

<div class="add-button">

	<button class="parent" [ngClass]="{ 'clicked' : adding }" (click)="currentlyAdding()">
		<div class="plus" *ngIf="!adding"> <i class="fa fa-plus"></i> </div>
		<div class="plus" *ngIf="adding"> <i class="fa fa-times"></i> </div>
	</button>
	
	<div class="children" *ngIf="adding">
	  
		<button class="add-news" (click)="displayModal(newsModal); adding=false;">
		    <div class="plus"> 
				<i class="fa fa-envelope"></i> 
				Create news
			</div>
		</button>
	  
	</div>
	  
</div>