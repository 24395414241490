import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { RosterInterface } from '../../app/model/roster.interface';
import { TagInterface } from '../../app/model/tag.interface';
import { UserInterface } from '../../app/model/user.interface';
import { AlertService } from '../api/alert/alert.api';
import { AlertDispatcherService } from '../api/alert/alert.dispatcher';
import { OrganisationMembershipService } from '../api/organisation/dependencies/membership/organisation-membership.api';
import { RosterMembershipService } from '../api/roster/dependencies/membership/roster-membership.api';
import { TagsService } from '../api/tag/tag.api';
import { TagDispatcherService } from '../api/tag/tag.dispatcher';
import { AlertInterface } from './../../app/model/alert.interface';
import { OrganisationInterface } from './../../app/model/organisation.interface';
import { MembersDispatcherService } from './../api/member/members.dispatcher';
import { OrganisationDispatcherService } from './../api/organisation/organisation.dispatcher';
import { RosterService } from './../api/roster/roster.cached';
import { RostersDispatcherService } from './../api/roster/rosters.dispatcher';
/**
 * This class provide functions to manage different actions on the Dashboard page (manager section).
 *
 * Only the 'action' functions (such as "selectRoster", etc) shall be public.
 * Only the "changeOrganisation" needs to be private, because can't be triggered from inside the page
 */
@Injectable()
export class DashboardManagerPageService {

    private currentOrganisation: OrganisationInterface;
    private subscriptions: Subscription[] = [];

    public constructor(
        private _organisationDispatcher: OrganisationDispatcherService,
        private _rosterDispatcher: RostersDispatcherService,
        private _memberDispatcher: MembersDispatcherService,
        private _rosterService: RosterService,
        private _tagService: TagsService,
        private _organisationMembership: OrganisationMembershipService,
        private _alertService: AlertService,
        private _alertDispatcher: AlertDispatcherService,
        private _tagDispatcher: TagDispatcherService,
        private _rosterMembershipService: RosterMembershipService
    ) {

    }

    public initialization() {
        // Subscribe immediately because organisation change is triggered from GlobalPage
        this.subscriptions.push(
            this._organisationDispatcher.getSelectedOrganisation().subscribe(
                (organisation: OrganisationInterface) => {
                    this.currentOrganisation = organisation;
                    this.getOrganisationRelatedData(organisation);
                }
            )
        );
    }

    public destroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private getOrganisationRelatedData(organisation: OrganisationInterface) {
        // this._organisationDispatcher.selectOrganisation(organisation);
        this.getOrganisationRosters(organisation);
        this.getOrganisationMembers(organisation);
        this.getOrganisationAlerts(organisation);
    }

    private getOrganisationRosters(organisation: OrganisationInterface) {
        // No need to usubscribe, it's an api call
            this._rosterService.getOrganisationRostersAsManager(organisation.id) // Gets dispatched from the service itself.
                .subscribe(
                    (rosters: RosterInterface[]) => this._rosterDispatcher.publishOrganisationRosters(rosters)
        );
    }

    private getOrganisationMembers(organisation: OrganisationInterface, filters?: any) {
        // No need to usubscribe, it's an api call
            this._organisationMembership.getOrganisationMembers(organisation.id, filters).subscribe(
                (members: UserInterface[]) => this._memberDispatcher.publishAllOrganisationMembers(members)
        );
    }

    private getOrganisationAlerts(organisation: OrganisationInterface) {
        // No need to usubscribe, it's an api call
            this._alertService.getOrgaAlerts(organisation.id).subscribe(
                (alerts: AlertInterface[]) => this._alertDispatcher.nextOrganisationAlerts(alerts)
        );
    }

    public selectRoster(roster: RosterInterface) {
        this._rosterDispatcher.nextSelectedRoster(roster);
        if (roster.id === RosterInterface.NO_ROSTER) {
            this.getOrganisationMembers(this.currentOrganisation);
        } else {
            this.getRosterTags(roster);
            this.getRosterMembers(roster);
        }
    }

    public getRosterTags(roster: RosterInterface) {
        this.subscriptions.push(
            this._tagService.getAllRosterTags(roster.id).subscribe(
                (tags: TagInterface[]) => this._tagDispatcher.publishRosterTags(tags)
            )
        );
    }

    public getRosterMembers(roster: RosterInterface) {
        // Offline way
        // this._memberDispatcher.filterByRosterAndDispatch(roster.id);

        // Roster service way. Returns only the association.
        // this._rosterMembershipService.getRosterMembers(roster.id).subscribe(
        //     (members: UserInterface[]) => this._memberDispatcher.publishMembers(members)
        // );

        // Organisation service way. Returns actual User interfaces.
        this._organisationMembership.getOrganisationMembers(this.currentOrganisation.id, {roster: roster.id}).subscribe(
            (members: UserInterface[]) => this._memberDispatcher.publishMembers(members)
        );
    }
}
