import { NgModule     } from '@angular/core';
import { Select2Module } from 'ng2-select2';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared.module';
// Components
import { OrgaNewsManagerComponent } from './orga-news-manager.component';
import { NewsPreloaderComponent } from './news-preloader/news-preloader.component';


@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    SharedModule,
	Select2Module
  ],
  declarations: [
    OrgaNewsManagerComponent,
    NewsPreloaderComponent
  ],
  providers: []
})


export class OrgaNewsManagerModule {}
