import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LoggerService } from '../../../../../../services/utils/logger.service';
@Component({
  selector: 'alerts-process',
  templateUrl: 'alerts-process.component.html',
  styleUrls: ['alerts-process.component.scss']
})
export class AlertsProcessComponent implements OnChanges {
  @Input() alertInfo;
  @Input() deploymentOffers;
  @Input() nbrOrders;
  @Input() SELECTIONS;
  @Input() nbrActiveOrders;
  @Output() setting = new EventEmitter<any>();

  calendarStep = "";
  offerStep = "";
  orderStep = "";
  timeLineImage = '../../../../../assets/images/timeline.png';
  constructor(
    public logger: LoggerService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.SELECTIONS && changes.SELECTIONS.currentValue !== changes.SELECTIONS.previousValue)
      || (changes.deploymentOffers && changes.deploymentOffers.currentValue !== changes.deploymentOffers.previousValue)
      || (changes.nbrOrders && changes.nbrOrders.currentValue !== changes.nbrOrders.previousValue)
    ) {
      this.setProgress();
    }
  }

  setComponent(choice: string) {
    let listSetting = [];
    if (choice === "calendar") {
      listSetting = [true, false, false];
    } else if (choice === "offer") {
      listSetting = [false, true, false];
    } else if (choice === "order") {
      listSetting = [false, false, true];
    }
    this.setting.emit(listSetting);
  }

  setProgress() {

    const today = new Date();

    if (this.SELECTIONS.length !== 0) {
      this.calendarStep = "active";
    } else {
      this.calendarStep = "pending";
    }

    let counterValidated = 0;
    let counterRefused = 0;
    let counterPending = 0;

    for (let i = 0; i < this.deploymentOffers.length; i++) {
      if (this.deploymentOffers[i].valid === 2 || this.deploymentOffers[i].valid === 3 && new Date(this.deploymentOffers[i].end_date) > today) {
        counterRefused++;
      } else if (this.deploymentOffers[i].valid === 1 && new Date(this.deploymentOffers[i].end_date) > today) {
        this.orderStep = "pending";
        counterValidated++;
      } else if (new Date(this.deploymentOffers[i].end_date) > today) {
        counterPending++;
      } else {
        counterValidated = 0;
        counterPending = 0;
        counterRefused = 0;
      }

    }
    if (this.deploymentOffers.length > 0 && counterRefused === this.deploymentOffers.length) {
      this.offerStep = "refuse";
    } else if (counterPending > 0) {
      this.offerStep = "pending";
    } else if (counterValidated > 0) {
      this.offerStep = "active";
    }

    if (this.nbrActiveOrders > 0) {
      this.orderStep = "active";
    }
  }
}
