import { CacheService } from './../../storage/cache.service';
import { OrganisationMembershipService } from './dependencies/membership/organisation-membership.api';
import { tap, switchMap } from 'rxjs/operators';
import { RostersDispatcherService } from '../roster/rosters.dispatcher';
import { RosterInterface } from '../../../app/model/roster.interface';
import { MembersDispatcherService } from '../member/members.dispatcher';
import { UserInterface } from '../../../app/model/user.interface';
import { OrganisationDispatcherService } from './organisation.dispatcher';
import { ApiCacherService } from '../../utils/api-cacher.service';
import { OrganisationApiService } from './organisation.api';
import { OrganisationInterface } from '../../../app/model/organisation.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class CachedOrganisationService {
    private readonly ALL_ORGANISATIONS = OrganisationInterface.CACHE.ALL_ORGANISATIONS;
    private readonly USER_ORGANISATIONS = OrganisationInterface.CACHE.USER_ORGANISATIONS;
    private readonly CURRENT_ORGANISATION = OrganisationInterface.CACHE.CURRENT_ORGANISATION;
    private readonly ORGANISATION_MEMBERS = OrganisationInterface.CACHE.ORGANISATION_MEMBERS;
    private readonly ORGANISATION_ROSTERS = OrganisationInterface.CACHE.ORGANISATION_ROSTERS;

    constructor(
        public cacher: ApiCacherService,
        private dispatcher: OrganisationDispatcherService,
        private api: OrganisationApiService,
        private _memberDispatcher: MembersDispatcherService,
        private _rosterDispatcher: RostersDispatcherService,
        private _organisationMemberships: OrganisationMembershipService,
        private _cacheService: CacheService
    ) { }

    public getOrganisations() {
        return this.cacher.doWrapper(
            this.ALL_ORGANISATIONS,
            () => this.api.getAllOrganisations()
        ).pipe(
            tap(
                (res: OrganisationInterface[]) => this.dispatcher.dispatchOrganisations(res)
            )
        );
    }

    public refreshOrganisations() {
        this.cacher.removeIfPresent(this.ALL_ORGANISATIONS);
        this.getOrganisations().subscribe();
    }

    public getUserOrganisations() {
        return this.cacher.doWrapper(
            this.USER_ORGANISATIONS,
            () => this.api.getUserOrganisations()
        ).pipe(
            tap(
                (res: OrganisationInterface[]) => this.dispatcher.dispatchUserOrganisations(res)
            )
        );
    }

    public updateCurrentOrganisation(organisationId: number, data: FormData) {
        return this.api.updateOrganisation(organisationId, data)
            .pipe(
                tap(() => { }), // NODE trigger node notification service
                tap(x => this.refreshCurrentOrganisation(organisationId))
            );
    }

    public deleteOrganisation(organisationId: number) {
        return this.api.deleteOrganisation(organisationId)
            .pipe(
                tap(() => { }), // NODE trigger node notification service
                tap(() => this.refreshOrganisations())
            );
    }

    public refreshUserOrganisations() {
        this.cacher.removeIfPresent(this.USER_ORGANISATIONS);
        this.getUserOrganisations().subscribe();
    }

    public refreshCurrentOrganisation(organisationId: number) {
        this.cacher.removeIfPresent(this.CURRENT_ORGANISATION);
        this.selectOrganisation(organisationId).subscribe();
    }

    public selectOrganisation(organisationId: number) {
        return this.cacher.doWrapper(
            this.CURRENT_ORGANISATION,
            () => this.api.getOrganisation(organisationId)
        ).pipe(
            // If the cache is 'unplugged', set the organisation manually
            tap( (res: OrganisationInterface) => this._cacheService.set('current_organisation', res) ),
            tap(
                (res: OrganisationInterface) => this.dispatcher.selectOrganisation(res)
            )
        );
    }

    public getOrganisationMembers(organisationId: number, filters) {
        return this.cacher.doWrapper(
            this.ORGANISATION_MEMBERS + organisationId,
            () => this._organisationMemberships.getOrganisationMembers(organisationId, filters)
        ).pipe(
            tap((members: UserInterface[]) => this._memberDispatcher.publishAllOrganisationMembers(members))
        );
    }

    public getOrganisationRosters(organisationId: number) {
        return this.cacher.doWrapper(
            this.ORGANISATION_ROSTERS + organisationId,
            () => this.api.getOrgaRosters(organisationId)
        ).pipe(
            tap(
                (rosters: RosterInterface[]) => this._rosterDispatcher.publishOrganisationRosters(rosters)
            )
        );
    }

    public refreshOrganisationMembers(organisationId: number) {
        this.cacher.removeIfPresent(this.ORGANISATION_MEMBERS + organisationId);
        this.getOrganisationMembers(organisationId, {}).subscribe();
    }
}
