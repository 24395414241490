import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared.module';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
// Routing
import { DashboardResponderRouting } from './dashboard-responder.routing';
// Components
import { DashboardResponderComponent         } from './dashboard-responder.component';
import { DashboardNewsComponent     } from './dashboard-news/dashboard-news.component';
import { DashboardNewsMapComponent  } from './dashboard-news/dashboard-news-map/dashboard-news-map.component';
import { DashboardNewsFeedComponent } from './dashboard-news/dashboard-news-feed/dashboard-news-feed.component';
import { DashboardAlertsComponent   } from './dashboard-alerts/dashboard-alerts.component';
import { DashboardRostersComponent  } from './dashboard-rosters/dashboard-rosters.component';
import { AlertComponent    } from './components/component-alert/component-alert.component';
import { ComponentNew      } from './components/component-new/component-new.component';
import { ComponentDisaster } from './components/component-disaster/component-disaster.component';
import { ComponentRoster   } from './components/component-roster/component-roster.component';
import { ComponentListCountriesComponent } from './components/component-list-countries/component-list-countries.component';
// Modules
import { DisasterModule             } from '../organisation/disaster/disaster.module';
import { RosterModule               } from '../organisation/roster/roster.module';
import { OrgaNewsModule             } from '../organisation/news/orga-news.module';
import { AlertsModule               } from '../organisation/alerts/alerts.module';
import { DashboardPreloaderRostersComponent } from './dashboard-preloader/dashboard-preloader-rosters/dashboard-preloader-rosters.component';
import { DashboardPreloaderAlertsComponent } from './dashboard-preloader/dashboard-preloader-alerts/dashboard-preloader-alerts.component';
import { DashboardPreloaderNewsComponent } from './dashboard-preloader/dashboard-preloader-news/dashboard-preloader-news.component';



@NgModule({
  imports: [
    // Routing
    DashboardResponderRouting,
    // Angular Modules
    CommonModule,
    SharedModule,
    NgbModule,
    DisasterModule,
    RosterModule,
    OrgaNewsModule,
    AlertsModule
  ],
  declarations: [
    DashboardResponderComponent,
    DashboardNewsComponent,
    DashboardNewsMapComponent,
    DashboardNewsFeedComponent,
    DashboardAlertsComponent,
    DashboardRostersComponent,
    AlertComponent,
    ComponentNew,
    ComponentDisaster,
    ComponentRoster,
    ComponentListCountriesComponent,
    DashboardPreloaderRostersComponent,
    DashboardPreloaderAlertsComponent,
    DashboardPreloaderNewsComponent,

  ],
  providers: []
})


export class DashboardResponderModule {}
