<!--Profil-skills component-->
<div id="profile-skills-component" class="shadow">

  <!--Title-->
  <div class="emalsys_section-title">
    <h2 i18n="@@skillsTitle" class="margin-b">Skills</h2>
  </div>
  <!--List of your skills-->
  <div class="profile-skills-component-tags margin-b" *ngIf="skills && skills.length > 0">
      <h3 *ngFor='let skill of skills'>{{skill.tag.name}}
        <!--Delete a skill from the profil-->
        <!--Note: alleger le design -->
        <i class="fa fa-times custom-delete-icon" *ngIf="!isSomeoneElse" (click)="deleteSkill(skill.id)"></i>
      </h3>
  </div>
  <div *ngIf="!skills || skills.length === 0" class="no-skills-container">
    <p>There are no skills to display</p>
  </div>
  <!-- + button-->
  <!--Note: Faire en sorte que le bouton + se change en en message qd on atteint 5 skills-->
  <div class="add-button" *ngIf="!isSomeoneElse" (click)="showAddSkills(content)">+</div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">

    <!--Modal header-->
    <div class="modal-header">
      <!--Modal title-->
      <h4 class="modal-title" i18n="@@addNewSkills">Add New Skills</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d(); initVariables()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">

      <!--List of skills you want to add-->
      <h3 *ngFor="let skill of addedSkills" style="margin-bottom:10px;">{{skill.name}}
        <!--Delete a skill from the modal-->
        <i class="fa fa-times custom-delete-icon" (click)="removeSkillFromAdded(skill)"></i>
      </h3>

      <!--If you don't reach max skills number: -->
      <div class="" *ngIf="skillsCanBeAdded">
        <!--Dropdown list-->
        <select id="skillField" class="form-control margin-b" [(ngModel)]="addID" name="skill" type="select">
          <option value="-1">-- Select a skill to add --</option>
          <option *ngFor="let skillToAdd of skillsList" [ngValue]="skillToAdd.id">{{ skillToAdd.name }}</option>
        </select>
        <div class="text-center">
          <!--Add button-->
          <button id="add-skill-btn" class="btn emalsys_button green" [disabled]="!addID" (click)="addSelectedSkill()" i18n="@@addSkillLabel">Add</button>
        </div>
      </div>

      <!--If you reach max skills number: -->
      <div class="" *ngIf="!skillsCanBeAdded">
        <h4 i18n="@@noMoreSkills">No more skills can be added. Please remove a skill to add another one.</h4>
      </div>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Add skills-->
      <button type="button" class="btn emalsys_button green" [disabled]="addedSkills.length == 0 || loading" (click)="addSkills()" i18n="@@add">Add Skills</button>
    </div>

  </ng-template>

  <!-- Toasts -->
  <div id="skillDeleteToast">You have deleted a skill</div>
  <!-- <div id="skillCancelToast">Adding skills cancelled</div> -->
  <div id="skillAddToast">You have added new skill(s)</div>
  <div id="noMoreSkillsToast">You cannot add more than {{MAX_SKILL_NUMBER}} skills</div>
</div>
