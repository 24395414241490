<!--Dashboard-rosters component-->
<div id="dashboard-rosters-component" class="shadow">
  <!--Title-->
  <div class="emalsys_section-title">
    <h2 i18n="@@toTranslate">My Rosters</h2>
    <button class="add-button" (click)="joinRoster(content)">
      <i class="fa fa-plus custom-add-icon"></i>
    </button>
  </div>
  <!--List of rosters-->
  <div class="dashboard-rosters-component-rosters">
    <component-roster class="rosters-roster" [roster]='roster' [label]='"member"' *ngFor='let roster of rostersMember'>
    </component-roster>
    <component-roster class="rosters-roster" [roster]='roster' [label]='"invited"'
      *ngFor='let roster of rostersInvited'></component-roster>
    <component-roster class="rosters-roster" [roster]='roster' [label]='"request"'
      *ngFor='let roster of rostersPending'></component-roster>
    <component-roster class="rosters-roster" [roster]='roster' [label]='"frozen"' *ngFor='let roster of rostersFrozen'>
    </component-roster>

    <div class="empty" *ngIf='rostersMember.length==0'>
      <img src="../../../../../assets/images/not-yet-field.png" />
      <h2>This section is empty</h2>
    </div>

  </div>

</div>

<!-- Modal -->
<ng-template #content let-c="close" let-d="dismiss">

  <!--Modal header-->
  <div class="modal-header">
    <!--Modal title-->
    <h4 class="modal-title" i18n="@@changePicture">Join a roster</h4>
    <!--Close button-->
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
  </div>

  <!--Modal body-->
  <div class="modal-body">
    <div *ngIf="joinableRosters.length > 0" class="upload-wrapper">
      <label class="control-label">Select a Roster</label>
      <div class="form-input">
        <select class="form-control margin-b" [(ngModel)]="rosterId" type="select">
          <option *ngFor="let roster of joinableRosters" [value]="roster.id">{{ roster.name }}</option>
        </select>
      </div>
    </div>
    <div *ngIf="joinableRosters.length === 0">
      <h2>No roster to join</h2>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
  <!--Modal footer-->
  <div class="modal-footer" *ngIf="joinableRosters.length > 0">
    <!--Upload button-->
    <button [disabled]="!rosterId || loading" type="submit" class="btn emalsys_button green"
      (click)="joinSelectedRoster(rosterId)">Join</button>
  </div>

</ng-template>