<div class="reporting-banner shadow">
  <div class="first-container">
    <div class="button-container" >
      <h6 class="lastdate">Last updated on: {{lastUpdateDate}}</h6>
    </div>
    <div *ngIf="type!='Organisation'" class="selector-container"></div>
  </div>
  <div>
    <div class="button-container">
      <button-filter [id]="'frequency'" [page]="'reporting'" [canNotBeDisable]="true" (emitFilter)="onFilter($event)" [data]="dataFilterFrequence"
        [referenceKey]="'frequency'"></button-filter>
      <button-filter [id]="'emalsys'" [page]="'reporting'" [canNotBeDisable]="checkOrganisationManager()" (emitFilter)="onFilter($event)" [data]="dataFilterType"
        [referenceKey]="'emalsys_search'"></button-filter>
    </div>
    <div *ngIf="type!='Organisation'" class="selector-container">
      <selector-filter #selectorRoster *ngIf="type=='Roster' || type=='SelectedRoster'" (emitFilter)="onFilter($event)" [selectorFilter]="rosterFilter"
        [item]="type" [data]="allRosters"></selector-filter>
      <selector-filter #selectorEvent *ngIf="type=='Event' || type=='SelectedEvent'" (emitFilter)="onFilter($event)" [selectorFilter]="eventFilter"
        [item]="type" [data]="allEvents"></selector-filter>
    </div>
  </div>
</div>
<div *ngIf="indicators.length" class="indicator-container">
  <div class="indicator-box" *ngFor="let indicator of filteredIndicators; let i = index">
    <app-indicator [selectedRoster]="rosterId" [selectedEvent]="eventId" [frequence]="frequence" *ngIf="type == indicator['type']"
      [indicator]="indicator" [chartDimensions]="chartDimensions" [filters]="findFilter(indicator['filter'])"></app-indicator>
  </div>
</div>

<div *ngIf="(indicators.length == 0) && !indicatorsLoading" class="no-data-box">
  <h1 class="box-title"> Generating Reports</h1>
</div>

<div *ngIf="indicatorsLoading" class="container-fluid margin-t">
  <div class="col-sm-12 loader-title">
    <h1>Loader</h1>
  </div>
  <div class="col-sm-12 loader-title">
    <h2>Loader</h2>
  </div>
  <div class="container">
    <div class="chart chartLoader"></div>
    <div class="chart chartLoader"></div>
  </div>
</div>