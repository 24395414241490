import { Injectable, Pipe } from '@angular/core';


@Pipe({
  name: 'dateFormat'
})
@Injectable()


export class DateFormatPipe {

  transform(date: any, formatDay: string, formatMonth: string, formatYear: string) {

    // Get the infos from the bad date format
    let dateDay   = new Date(date.slice(0, 10)).getDate();
    let dateMonth = new Date(date.slice(0, 10)).getMonth();
    let dateYear  = new Date(date.slice(0, 10)).getFullYear();

    // Initialization of the variables displayed
    let day  : string;
    let month: string;
    let year : string;

    // Set the day
    day = dateDay.toString();

    // Set the month with full name
    if (formatMonth == "MMMM") {
      month = (dateMonth === 0)  ? "January"  :
              (dateMonth === 1)  ? "February" :
              (dateMonth === 2)  ? "March"    :
              (dateMonth === 3)  ? "April"    :
              (dateMonth === 4)  ? "May"      :
              (dateMonth === 5)  ? "June"     :
              (dateMonth === 6)  ? "July"     :
              (dateMonth === 7)  ? "August"   :
              (dateMonth === 8)  ? "September":
              (dateMonth === 9)  ? "October"  :
              (dateMonth === 10) ? "November" :
              (dateMonth === 11) ? "December" :
              "";
    }

    // Set the year
    year = dateYear.toString();

    let datePiped: string;

    // Set the date depending of the format asked
    if (formatDay && formatMonth && formatYear) datePiped = day + " " + month + " " + year;

  	return datePiped;
  }

}
