import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';

import { WelcomeComponent } from './welcome.component';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    NgbModule
  ],
  declarations: [
    WelcomeComponent
  ],
  providers: []
})


export class WelcomeModule {}
