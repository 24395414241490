<!-- Modal -->
<div class="modal fade" id="{{ modalId }}data" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">{{ this.modalTitle }}</h4>
      </div>
      <div class="modal-body">

        <data-table [dataTable]="this.dataModal" [dataAxis]="this.modalAxis" [typeChartTable]="this.typeChartModal">
        </data-table>

      </div>
      <div class="modal-footer">
        <button type="button" (click)="fermer()" class="btn btn-primary" data-dismiss="modal">Fermer</button>
      </div>
    </div>
  </div>
</div>