import { id } from '@swimlane/ngx-charts/release/utils';
import { UserInterface } from './../../app/model/user.interface';
import { UserService } from './../api/user.service';
import { CachedOrganisationService } from '../api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../api/organisation/organisation.dispatcher';
import { ROUTING, ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../app/app.constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, ActivatedRoute } from '@angular/router';
import { Observable ,  forkJoin ,  of } from 'rxjs';
import { RightService } from '../utils/right.service';
import { CacheService } from '../storage/cache.service';
import { NavigationService } from '../utils/navigation.service';
import { take, mergeMap, combineLatest, map } from 'rxjs/operators';
import { OrganisationInterface } from '../../app/model/organisation.interface';

interface InitProfileManager {
    user: UserInterface;
    organisation: OrganisationInterface;
}

@Injectable()
export class ProfileManagerGuardService implements CanActivate {

    readonly rightMap = {
        'settings': ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin
    };

    private user;

    constructor(
        private _rightService: RightService,
        private _cacheService: CacheService,
        private _navigationService: NavigationService,
        private _cachedOrganisationService: CachedOrganisationService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _userService: UserService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let userId: number = null;

        // REFACTOR: understand what kind of interface this is
        this.user = this._cacheService.get(CacheService.USER);
        const currentOrganisation = this._cacheService.get(OrganisationInterface.CACHE.CURRENT_ORGANISATION);
        // check if the current user is manager if the current organisation
        if (currentOrganisation && next.paramMap.has(ROUTING.parameter.userId)) {
            userId = Number(next.paramMap.get(ROUTING.parameter.userId));
            // const canNavigate = this._rightService.checkOrganisationManagerRight(this.user, organisationId, ORGANISATION_MANAGER_RIGHTS_LEVEL.NotManager);
            let user;
            forkJoin(
                this._userService.getUser(userId),
                of(currentOrganisation)
                // this._organisationDispatcher.getSelectedOrganisation()
            ).pipe(
                take(1),
                map(x => {
                    return <InitProfileManager>{
                        user: x[0] as UserInterface,
                        organisation: x[1] as OrganisationInterface,
                    };
                })
            ).subscribe(
                (res) => {
                    // 0 -> user
                    // 1 -> organisation
                    const organisation = res.user.organisation_members.find(x => x.id === res.organisation.id);
                    return organisation !== undefined;

                }
            );
            // this._userService.getUser(userId).subscribe(
            //     (res: UserInterface) => {
            //         user = res;
            //         const canNavigate = res.organisation_members.
            //     }
            // );
            // if (!canNavigate) {
            //     this._navigationService.goToManagerForbidden();
            // }
            // // trigger the update of the current organisation
            // // this._cachedOrganisationService.refreshCurrentOrganisation(organisationId);
            // return canNavigate;
        } else {
            return false;
        }

        // // check
        // if (next.paramMap.has(MANAGER_ROUTING_USER_PARAMETER)) {
        //     userId = Number(next.paramMap.get(MANAGER_ROUTING_USER_PARAMETER));
        // }

        // return this._rightService.isOrganisationManagerRight(this.userProfile, this.rightMap[page]);
        // return false;
    }


}
