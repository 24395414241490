import { Component, Input } from '@angular/core';
import { URL_API_RELIEF_WEB_IMG_LG, ISO2_TO_ISO3 } from '../../../../../app.constants';

@Component({
  selector   : 'alerts-map',
  templateUrl: 'alerts-map.component.html',
  styleUrls  : ['alerts-map.component.scss']
})
export class AlertsMapComponent {
  @Input() alertInfo: any;
  public imgUrl:      any;
  public iso3:        any;

  constructor() {
    this.imgUrl = URL_API_RELIEF_WEB_IMG_LG;
    this.iso3   = ISO2_TO_ISO3;
  }

  errorHandler(event) {
    event.target.src = this.imgUrl + "wld.png";
  }
}
