<!--Profile-available component-->
<div id="profile-available-component" class="shadow">
  <!--Title-->
  <div class="emalsys_section-title" *ngIf='profile'>
    <h2 *ngIf="isAvailable">AVAILABLE FOR DEPLOYMENT</h2>
    <h2 *ngIf="!isAvailable">UNAVAILABLE FOR DEPLOYMENT</h2>
  </div>
  <!--Switch-->
  <div class="toggle-switch" *ngIf='profile'>
    <input id="TriSeaSuccess" name="TriSea1" type="checkbox" [(ngModel)]="isAvailable"
      (change)="showChangeAvailability(content)" />
    <label for="TriSeaSuccess" class="label-success"></label>
  </div>
  <div class="text-center">
    <button [disabled]="isAvailable" [ngClass]="{'disabled': isAvailable}" class="btn emalsys_button"
      (click)="changeDate(content)">Change date</button>
  </div>

  <!-- Modal: to display when switch off -->
  <ng-template #content let-c="close" let-d="dismiss">
    <!--Modal header-->
    <div class="modal-header">
      <!--Title of the modal-->
      <h4 class="modal-title" i18n="@@selectInavailibilityPeriod">You will be unavailable until : </h4>
      <!--Close button, switch return in previous position-->
      <button type="button" class="close" aria-label="Close" (click)="d();">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>
    <!--Modal body-->
    <div class="modal-body">
      <!--Choose the unavailability period-->
      <div class="form-group">
        <label i18n="@@selectInavailibilityPeriod">You will be unavailable until the : </label>
        <p-calendar id="StartDate" [minDate]="today" [monthNavigator]="true" [yearNavigator]="true"
          yearRange="2018:2030" [showIcon]="true" [(ngModel)]="endAvailabilityDate"></p-calendar>
      </div>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Button to validate the modification-->
      <!--Note: Quand on clique en dehors du modal, le swith est modifié mais pas de toast pour confirmer que le changement est fait-->
      <!--Cas 1: le profil est bien indisponible et donc il faut que le toast s'affiche-->
      <!--Cas 2: le profil est disponible et donc il faut que le switch retourne à la position initial-->
      <!--Cas 3: On empêche l'user de quitter le modal -->
      <!--cas 2 et 3 favorable-->
      <button class="btn emalsys_button green" (click)="c()" i18n="@@confirmAvailability">Confirm</button>
    </div>
  </ng-template>
  <!-- Toasts -->
  <div id="avalabilityDisableToast">You are now unavailable for deployment</div>
  <div id="avalabilityEnableToast">You are now available for deployment</div>
  <div id="settingAvalabilityErrorToast">There was an issue setting your availability for deployment</div>
</div>