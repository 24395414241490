import { ROUTING } from './../../app.constants';
import { NotFoundComponent } from './../../error-pages/notFound/notFound.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WelcomeComponent } from './welcome/welcome.component';
import { ProfileComponent } from '../shared/profile/profile.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { ContactComponent } from './contact/contact.component';
// import { EditOrganisationModalComponent } from '../manager/dashboard/modals/edit-organisation-modal.component';

import { ReportingComponent } from '../reporting/reporting.component';
import { OrganisationGuardService } from '../../../services/security/organisation.guard';

// Do not change the order of the routes, it matters
export const routes: Routes = [
  {
    path: 'profile',
    loadChildren: '../shared/profile/profile.module#ProfileModule',

    // component: ProfileComponent,
  },
  {
    path: 'user-guide',
    component: UserGuideComponent,
    canActivate: [OrganisationGuardService]
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [OrganisationGuardService]
  },
  {
    path: `organisation/:${ROUTING.parameter.organisationId}`,
    loadChildren: './dashboard/dashboard-responder.module#DashboardResponderModule',

    canActivate: [OrganisationGuardService]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [OrganisationGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResponderRouting { }
