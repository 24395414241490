import { AlertInterface } from './../../app/model/alert.interface';
import { RosterService } from './../api/roster/roster.cached';
import { MembersDispatcherService } from './../api/member/members.dispatcher';
import { RostersDispatcherService } from './../api/roster/rosters.dispatcher';
import { OrganisationDispatcherService } from './../api/organisation/organisation.dispatcher';
import { OrganisationInterface } from './../../app/model/organisation.interface';
import { Injectable } from '@angular/core';
import { TagsService } from '../api/tag/tag.api';
import { RosterInterface } from '../../app/model/roster.interface';
import { tap, switchMap } from 'rxjs/operators';
import { OrganisationMembershipService } from '../api/organisation/dependencies/membership/organisation-membership.api';
import { UserInterface } from '../../app/model/user.interface';
import { AlertService } from '../api/alert/alert.api';
import { AlertDispatcherService } from '../api/alert/alert.dispatcher';
import { TagInterface } from '../../app/model/tag.interface';
import { TagDispatcherService } from '../api/tag/tag.dispatcher';
import { RosterMembershipService } from '../api/roster/dependencies/membership/roster-membership.api';
import { forkJoin } from 'rxjs';
/**
 * This class provide functions to manage different actions on the Dashboard page (manager section).
 *
 * Only the 'action' functions (such as "selectRoster", etc) shall be public.
 * Only the "changeOrganisation" needs to be private, because can't be triggered from inside the page
 */
@Injectable()
export class GlobalPageService {


    public constructor(
        private _organisationDispatcher: OrganisationDispatcherService,
        private _rosterDispatcher: RostersDispatcherService,
        private _memberDispatcher: MembersDispatcherService,
        private _rosterService: RosterService,
        private _tagService: TagsService,
        private _organisationMembership: OrganisationMembershipService,
        private _alertService: AlertService,
        private _alertDispatcher: AlertDispatcherService,
        private _tagDispatcher: TagDispatcherService,
        private _rosterMembershipService: RosterMembershipService
    ) {
    }

    // Does this makes sense???
    public changeOrganisation(organisation: OrganisationInterface) {
        this._organisationDispatcher.selectOrganisation(organisation);
    }
}
