import {AbstractModel} from "./abstract-model";

export class DeploymentOfferInterface {
    /**
    * Deployment offer id
    * @type {number}
    **/
    id: number;
    /**
    * Deployment offer event
    * @type {number}
    **/
    event: number;
    /**
    * Deployment offer position
    * @type {string}
    **/
    position: string;
    /**
    * Deployment offer start date
    * @type {Date}
    **/
    startDate: Date;
    /**
    * Deployment offer end date
    * @type {Date}
    **/
    endDate: Date;
    /**
    * Deployment offer message
    * @type {string}
    **/
    message: string;
}

export class DeploymentOffer extends AbstractModel implements DeploymentOfferInterface {
  id: number = null;
  event: number = null;
  usersId: number[] = [];
  position = '';
  startDate: Date = null;
  endDate: Date = null;
  message = '';
  rosterId: number = null;

  static getInstance(): DeploymentOffer {
    return new DeploymentOffer();
  }

  static from(map: any): DeploymentOffer {
    return AbstractModel.from(DeploymentOffer, map);
  }

  /**
   * Converts current object to an Alert
   *
   * @param fieldsToAdd List of field to keep in formdata. If you dont know, let it empty and all
   * all fields will be returned
   */
  toFormData(fieldsToAdd: string[] = []): FormData {
    return super.toFormData(fieldsToAdd, (body, item): boolean => {
      if (item === 'startDate' || item === 'endDate') {
        body.append(item, this[item].toLocaleString('en-EN'));
        return false;
      }
      return true;
    });
  }
}
