<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Add members
    <span *ngIf="context === 'organisation'">to organisation</span>
    <span *ngIf="context === 'roster'">to roster</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="modal-text">
    <p>
      <span *ngIf="addByEmail">
        Enter email addresses
      </span>
      <span *ngIf="!addByEmail">
        Upload a CSV file (<a href="assets/template.csv">See template</a>)
      </span>
    </p>
  </div>
  <div class="modal-select" *ngIf="addByEmail">
    <div *ngIf="context===CONTEXT_ORGANISATION">
      <div class="input-form">
        <input (keyup.enter)="addTypedEmail();" type="text" class="modal-select-email" [(ngModel)]="typedEmail">
        <button class="emalsys_button green add-button" (click)="addTypedEmail();">Add</button>
      </div>
      <!-- List of recipients added -->
      <div class="email-recipients-container">
        <div class="email-to" *ngFor="let recipient of emailRecipients">
          <p><span class="recipient-email">{{recipient}}</span></p>
          <i class="fa fa-times" (click)="removeRecipient(recipient);"></i>
        </div>
      </div>
    </div>

    <!-- Layout when adding people to roster -->
    <div *ngIf="context===CONTEXT_ROSTER">
      <select2 class="modal-select-email" cssImport="true" width="100%" [options]="options"
        (valueChanged)="changed($event)">
      </select2>
    </div>


  </div>
  <div *ngIf="context===CONTEXT_ORGANISATION || (context===CONTEXT_ROSTER && canInviteAsManager)" #checkManager class="check-manager">
    <div class="modal-manager">
      <p>As a manager</p>
      <input [(ngModel)]="asManagerChecked"  type="checkbox">
    </div>
    <div *ngIf="checkManager.checked" class="modal-rights">
      <p>
        <span *ngIf="context===CONTEXT_ORGANISATION">Organisation</span>
        <span *ngIf="context===CONTEXT_ROSTER">Roster</span>
        rights: </p>
      <select [(ngModel)]="rights">
        <option value=1>
          <span *ngIf="context===CONTEXT_ORGANISATION">Associate</span>
          <span *ngIf="context===CONTEXT_ROSTER">Guest</span>
        </option>
        <option value=2>
          <span *ngIf="context===CONTEXT_ORGANISATION">Admin</span>
          <span *ngIf="context===CONTEXT_ROSTER">Manager</span>
        </option>
        <option value=3>
          <span *ngIf="context===CONTEXT_ORGANISATION">Super Admin</span>
          <span *ngIf="context===CONTEXT_ROSTER">Supervisor</span>
        </option>
      </select>
    </div>
  </div>


  <div class="modal-select" *ngIf="!addByEmail">
    <input type="file" (change)="fileChange($event)" />
  </div>

</div>
<div class="loading-custom" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button type="submit" class="btn emalsys_button green" (click)="addMembers()"
    [disabled]="(context === CONTEXT_ORGANISATION && emailRecipients.length === 0 || context === CONTEXT_ROSTER && !toAdd) && !csv || loading">
    Add members
  </button>
</div>

<div id="email-not-valid" class="toast red-toast">Email not valid</div>