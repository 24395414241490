<div id="dashboard-members-component">
  <div class="dashboard-member-header">
    <div class="buttons-actions">
      <!-- <div class="container-first-col"> -->
      <div class="members-change-view">
        <div class="select-all" *ngIf="view != 'map'">
          <button class="fa fa-check fa-lg" (click)="changeAll(true)"></button>
          <button class="fa fa-times fa-lg left" (click)="changeAll(false)"></button>
        </div>
        <div *ngIf="page === 'alerts'" class="switch">
          <button type="button" name="button" class="btn emalsys_button blue" style="transform: translateX(-29%);" (click)="showEventCalendar();$event.stopPropagation();">Calendar</button>
        </div>
        <div class="selection" *ngIf="isMembersPage">
          <button class="fa fa-th fa-lg" (click)="view = 'card'" [ngClass]="{'selected' : view == 'card'}"></button>
          <button class="fa fa-bars fa-lg" (click)="view = 'list'" [ngClass]="{'selected' : view == 'list'}"></button>
          <button class="fa fa-map-marker fa-lg" (click)="view = 'map'"
            [ngClass]="{'selected' : view == 'map'}"></button>
        </div>
      </div>
      <div class="add-tags-menu" *ngIf="currentRoster.id !== NO_ROSTER">
        <div *ngIf="checkOrganisationAssociate() || checkRosterGuest(currentRoster.id)"
          class="clickable-add-tag-button active">
          <div class="container-button-add-tag active">

            <button *ngIf="!isSomeoneSelected()" (click)="openTagsModal();" class="addatag">Manage tags</button>
            <button *ngIf="isSomeoneSelected()" (click)="openTagsModal();" class="addatag">Assign tags</button>
            <!-- <button *ngIf="!isSomeoneSelected()" (click)="openTagsModal();" class="addatag">Manage tags | <i class="fa fa-angle-left"></i></button>
              <button *ngIf="isSomeoneSelected()" (click)="openTagsModal();" class="addatag">Assign tags | <i class="fa fa-angle-right"></i></button> -->

            <!-- <button (click)="changeTagMode()" class="addatag">Tags</button> -->

          </div>
          <!-- <i [ngClass]="{'active' :!isSomeoneSelected()}" class="fa fa-angle-right"></i> -->
        </div>
      </div>
      <!-- </div> -->
      <div *ngIf="tagsMode" class="members-actions">
        <button *ngIf="checkOrganisationAssociate() || checkRosterGuest(currentRoster.id)"
          class="members-actions-item alerting shadow active" (click)="action('search')" placement="bottom"
          ngbTooltip="Filter the members with the selected tags">
          <!-- Tooltips not working? -->
          <i class="fa fa-search custom-icon"></i>
          <p *ngIf="!hasSelectedTags()">Display all</p>
          <p *ngIf="hasSelectedTags()">Filter by tag</p>
        </button>
        <div class="etc" *ngIf="(checkOrganisationAssociate() || checkRosterGuest(currentRoster.id))">
          <i class="fa fa-ellipsis-h"></i>
        </div>
        <button *ngIf="(checkOrganisationAssociate() || checkRosterGuest(currentRoster.id))"
          class="members-actions-item informing shadow" [ngClass]="{'active' : applyButtonActive()}"
          (click)="action('apply')" placement="bottom" ngbTooltip="Apply the selected tags to the selected members"
          [disabled]="!applyButton">
          <i class="fa fa-tags"></i>
          <p>Apply</p>
        </button>
        <div class="etc" *ngIf='(checkOrganisationAssociate() || checkRosterGuest(currentRoster.id))'>
          <i class="fa fa-ellipsis-h"></i>
        </div>
        <button *ngIf='(checkOrganisationAssociate() || checkRosterGuest(currentRoster.id))'
          class="members-actions-item deploying shadow" [ngClass]="{'active' : removeButtonActive()}"
          (click)="action('remove')" placement="bottom" ngbTooltip="Remove the selected tags of selected members"
          [disabled]="!removeButton">
          <i class="fa fa-times"></i>
          <p>Remove</p>
        </button>
      </div>
      <div *ngIf="!tagsMode" class="members-actions">
        <button *ngIf="checkOrganisationAssociate() || checkRosterGuest(currentRoster.id)"
          class="members-actions-item informing shadow" [ngClass]="{'active' :hasSelectedMembers()}"
          (click)="action('inform')" placement="bottom" ngbTooltip="Inform the selected members">
          <!-- Tooltips not working? -->
          <i class="fa fa-envelope"></i>
          <p>Inform</p>
        </button>
        <ng-container *ngIf="showAlertDeployButtons">
          <div class="etc">
            <i class="fa fa-ellipsis-h"></i>
          </div>
          <button class="members-actions-item alerting shadow"
            [disabled]="isAlertsPage && hasSelectedMembers()"
            [ngClass]="{'active' : (isAlertsPage && !hasSelectedMembers()) || (isMembersPage && hasSelectedMembers())}"
            (click)="action('alert')" placement="bottom" ngbTooltip="Alert the selected members">
            <i class="fa fa-bell"></i>
            <p>Alert</p>
          </button>
        </ng-container>
        <ng-container *ngIf="showAlertOfferButtons">
          <div class="etc">
            <i class="fa fa-ellipsis-h"></i>
          </div>
          <button
            class="members-actions-item offering shadow" [ngClass]="{'active' : hasSelectedMembers()}"
            (click)="action('offer')" placement="bottom" ngbTooltip="Send a deployment offer to the selected members">
            <i class="fa fa-check"></i>
            <p>Offer</p>
          </button>
          <div class="etc">
            <i class="fa fa-ellipsis-h"></i>
          </div>
          <button
            class="members-actions-item deploying shadow" [ngClass]="{'active' : hasSelectedMembers()}"
            (click)="action('deploy')" placement="bottom" ngbTooltip="Deploy the selected members">
            <i class="fa fa-plane"></i>
            <p>Deploy</p>
          </button>
        </ng-container>
      </div>
    </div>
    <div *ngIf="tagsMode" class="tags-list">
      <div *ngFor="let tag of tagsList; index as i" class="background{{i%20+1}} tag-component"
        [ngClass]="{'active' : selectedTags.includes(tag.id)}" (click)="selectTag(tag)">{{tag.name}}</div>
    </div>
    <div class="members-title">
      <div class="title-orga" *ngIf="currentRoster.id === NO_ROSTER && (isMembersPage)">
        <p>Members of {{organisation.name}}</p>
      </div>
      <div class="title-orga" *ngIf="currentRoster.id !== NO_ROSTER && (isMembersPage)">
        <p>Members of {{currentRoster.name}} roster</p>
      </div>
      <div class="title-orga" *ngIf="alertInfo && alertInfo.name != '' && (isAlertsPage)">
        <p>Members of {{alertInfo.name}} event</p>
      </div>
    </div>
  </div>
  <dashboard-members-card [profile]="profile" [selected]="selected" (onSelected)="onSelected($event)"
    (onManagerAction)="onManagerAction($event)" [rosterId]="currentRoster.id" [members]="members"
    [organisation]='organisation' [tagsMode]="tagsMode" [tagsList]="tagsList" [selectedTags]="selectedTags"
    *ngIf="view == 'card'"></dashboard-members-card>
  <dashboard-members-list [profile]="profile" [selected]="selected" (onSelected)="onSelected($event)"
    (onManagerAction)="onManagerAction($event)" (onCatchClickCancel)="handleCancelOffer($event)"
    (onCatchClickDeploy)="handleDeploy($event)" [rosterId]="currentRoster.id" [members]="members"
    [organisation]='organisation' [page]="page" [alertInfo]="alertInfo" [availabilities]="availabilities"
    [deploymentOffers]="deploymentOffers" [deploymentOrders]="deploymentOrders" [tagsMode]="tagsMode"
    [tagsList]="tagsList" *ngIf="view == 'list' && !calendar"></dashboard-members-list>
  <dashboard-members-map [selected]="selected" [members]="members" [organisation]='organisation' *ngIf="view == 'map'">
  </dashboard-members-map>

  <div *ngIf="members && members.length == 0 && view != 'map' && page !== 'alerts'" class="no-members">
    <h2>No members match your search</h2>
  </div>
  <div *ngIf="members && members.length == 0 && view != 'map' && page === 'alerts'" class="no-members">
    <h2>You need to add members to the alert</h2>
  </div>
  <div id="informToast">Email sent</div>
  <div id="roasterMemberAddToast">Roster member(s) invited</div>
  <div id="roasterManagerAddToast">Roster manager(s) invited</div>
  <div id="offerSendToast">Deployment offer sent</div>
  <div id="alertSendToast">Alert sent</div>
  <div id="ordersDeploymentToast">Member successfully deployed</div>
  <div id="warningSelectMembersToast">You need to select at least one member</div>
  <div id="rosterTagErrorToast">An error has occured</div>
  <div id="rosterTagEditToast">The edit has been saved</div>
  <div id="deletedRosterTagToast">The tag(s) have been deleted from the members(s)</div>
  <div id="errorDeletingRosterTagToast">An error occured during the removal of the tag(s)</div>
  <div id="addedRosterTagToast">The tag(s) have been added to the member(s)</div>
  <div id="errorAddingRosterTagToast">An error occured during the addition of the tag(s)</div>
  <div id="errorAddingMemberToAlertError">An error occured during the addition of the member</div>
  <div id="member-tags-applied" class="toast green-toast">The tag(s) have been added to the member(s)</div>
  <div id="member-tags-removed" class="toast green-toast">The tag(s) have been removed from the member(s)</div>

</div>
