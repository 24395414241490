<div id="alerts-information-body">
  <div class="alerts-information-header"><h2>Information</h2></div>
 <!-- <a *ngIf="alertInfo?.info_link"class="btn btn-black-reverse" href="{{alertInfo?.info_link}}" target="_blank">See on <img class="alerts-info-link" src="assets/images/relief-web.png"/></a> -->
  <div class="alerts-informations-content">
    <h3>{{alertInfo?.start_date | date: "dd MMMM y"}} - {{alertInfo?.end_date | date: "dd MMMM y"}}</h3>
    <div>
      <p *ngIf="alertInfo?.country_name">Country: {{alertInfo?.country_name}}</p>
      <p *ngIf="alertInfo?.secondary_countries.length > 0" class="alerts-information-secondary-countries">Secondary countries: {{alertInfo?.secondary_countries}}</p>
      <p class="alerts-informations-contact">Event owner: {{alertInfo?.contact_name}} ({{alertInfo?.contact_email}})</p>
      <br/>
      <p *ngIf="alertInfo?.info_link" class="alerts-info-link-paragraph">More info :
        <a class="btn emalsys_button blue" href="{{alertInfo?.info_link}}" target="_blank"><img class="alerts-info-link" src="../../../../../../assets/icons/https.png"/></a>
      </p>
    </div>
  </div>
</div>
