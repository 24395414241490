import { EventEmitter } from '@angular/core';
import { ModalComponent } from '../modal.component';
export class ModalDetailsComponent extends ModalComponent {
    constructor() {
        super(...arguments);
        this.clearAction = new EventEmitter();
    }
    ngOnInit() {
    }
    fermer() {
        this.clearAction.emit(true);
    }
}
