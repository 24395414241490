export class AbstractModel {
  /**
   * Creates an instance of this class from a anoymous obejct
   *
   * @param map An object with fields defined in this object
   */
  static from(classToken, map: any) {
    let object = classToken.getInstance();

    Object.keys(map).forEach(item => {
      if (object.hasOwnProperty(item)) {
        object[item] = map[item];
      }
    });

    return object;
  }

  /**
   * Converts current object to an Alert
   *
   * @param fieldsToAdd List of field to keep in formdata. If you dont know, let it empty and all
   * all fields will be returned
   * @param callback A function that execute specific treatment on item before to add it to the body
   *   if true is returned then the field will be added as a key/value. Here is an example:
   * This callback specify the treatment of dates
   * (body, item): boolean => {
   *         if (item === 'startDate' || item === 'endDate') {
   *             body.append(item, this[item].toLocaleString('en-EN'));
   *             return false;
   *         }
   *         return true;
   *     }
   */
  toFormData(fieldsToAdd: string[] = [], callback: (body, item) => boolean ): FormData {
    let body = new FormData();

    const classKeys = fieldsToAdd.length === 0 ? Object.keys(this) : fieldsToAdd;

    classKeys.forEach(item => {
      if (this.hasOwnProperty(item)) {
        const _continue = callback(body, item);
        if (! _continue) {
          return;
        }

        const value = typeof this[item] === 'string' ? this[item] : String(this[item]);
        body.append(item, value);
      }
    });

    return body;
  }
}
