<!--Profile-language component-->
<div id="profile-languages-component" class="shadow">
  <!--Title-->
  <h2 class="text-center margin-b">LANGUAGES</h2>
  <!--List of languages-->
  <div *ngIf="displayingLanguages">
    <div *ngIf="displayingLanguages.length===0" class="no-languages-container">
      <p>There are no languages set</p>
    </div>
    <div *ngFor="let language of displayingLanguages">
      <h3> {{ language.language_name }} </h3>
      <!--To delete a language in the list-->
      <i class="fa fa-times custom-delete-icon" *ngIf="!isSomeoneElse" (click)="deleteLanguage(language.id)"></i>
      <!--Display the level-->
      <h4>
      {{language.level == 0 ? "Other"                          :
       language.level == 1 ? "Elementary Proficiency"          :
       language.level == 2 ? "Limited Working Proficiency"     :
       language.level == 3 ? "Professional Working Proficiency":
       language.level == 4 ? "Full Professional Profenciency"  :
       language.level == 5 ? "Native or Bilingual Proficiency" : "" }}
      </h4>
    </div>
  </div>
  <!-- + button-->
  <div class="add-button" *ngIf="!isSomeoneElse" (click)="showAddLanguages(content)">+</div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">
    <!--Modal header-->
    <div class="modal-header">
      <!--Title-->
      <h4 class="modal-title" i18n="@@changePicture">Add New Languages</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">

      <div *ngIf="allLanguages">
        <!--Set the language-->
        <label for="languageField" i18n="@@selectLanguageLabel"> Select a language to add</label>
        <select id="languageField" class="form-control margin-b" [(ngModel)]="languageToAdd.id" name="language" type="select">
          <option *ngFor="let language of allLanguages" [value]="language.id">{{ language.name }}</option>
        </select>
        <!--Set the level-->
        <label for="languageLevelField" i18n="@@selectLevelLabel">Select Language Level</label>
        <select id="languageLevelField" class="form-control margin-b" [(ngModel)]="languageLevel" name="languageLevel" type="select" i18n="@@selectLevelOptions">
          <option value="1" i18n="@@elementaryProficiencyLabel"         > Elementary Proficiency           </option>
          <option value="2" i18n="@@limitedWorkingProficiencyLabel"     > Limited Working Proficiency      </option>
          <option value="3" i18n="@@professionalWorkingProficiencyLabel"> Professional Working Proficiency </option>
          <option value="4" i18n="@@fullProfessionalProficiencyLabel"   > Full Professional Profenciency   </option>
          <option value="5" i18n="@@nativeorBiligualProficiencyLabel"   > Native or Bilingual Proficiency  </option>
          <option value="0" i18n="@@otherLabel"                         > Other                            </option>
        </select>
        <!--<div class="text-center">
        </div>-->
      </div>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Add Language to list button-->
      <button class="btn emalsys_button green" [disabled]="loading || !languageToAdd.id"(click)="addLanguage(languageToAdd.id, languageLevel)" i18n="@@addLanguageLabel">Add Language to list</button>
    </div>
  </ng-template>

  <!-- Toasts -->
  <div id="langDeleteToast">You have deleted a language</div>
  <!-- <div id="langCancelToast">You canceled adding a language.</div> -->
  <div id="langAddToast">You have added a new language</div>

</div>
