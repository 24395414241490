/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roster-preloader-infos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./roster-preloader-infos.component";
var styles_RosterPreloaderInfosComponent = [i0.styles];
var RenderType_RosterPreloaderInfosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RosterPreloaderInfosComponent, data: {} });
export { RenderType_RosterPreloaderInfosComponent as RenderType_RosterPreloaderInfosComponent };
export function View_RosterPreloaderInfosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "roster-infos-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "roster-infos-component-picture preloader shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "roster-infos-component-name shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "small-lines preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "big-lines preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "small-lines preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "big-lines preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "big-lines preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "roster-infos-component-actions shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "button preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "button preloader"]], null, null, null, null, null))], null, null); }
export function View_RosterPreloaderInfosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "roster-preloader-infos", [], null, null, null, View_RosterPreloaderInfosComponent_0, RenderType_RosterPreloaderInfosComponent)), i1.ɵdid(1, 114688, null, 0, i2.RosterPreloaderInfosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RosterPreloaderInfosComponentNgFactory = i1.ɵccf("roster-preloader-infos", i2.RosterPreloaderInfosComponent, View_RosterPreloaderInfosComponent_Host_0, {}, {}, []);
export { RosterPreloaderInfosComponentNgFactory as RosterPreloaderInfosComponentNgFactory };
