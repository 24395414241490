import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng2-select2';
import { SharedModule } from '../../../shared.module';
import { AlertModalComponent } from '../../shared/members/modals/alert/alert-modal.component';
import { DeploymentOffersModalComponent } from '../../shared/members/modals/deployment-offers/deployment-offers-modal.component';
import { DeploymentOrdersModalComponent } from '../../shared/members/modals/deployment-orders/deployment-orders-modal.component';
import { InformModalComponent } from '../../shared/members/modals/inform/inform-modal.component';
import { OrderDetailsModalComponent } from '../../shared/members/modals/order-details/order-details-modal.component';
import { AddRosterModalComponent } from '../../shared/members/modals/roster/add-roster-modal.component';
import { SendOfferModalComponent } from '../../shared/members/modals/send-offer/send-offer-modal.component';
import { TagsComponent } from '../../shared/members/modals/tags/tags.component';
import { ProfileModalComponent } from '../../shared/modals/profile/profile-modal.component';
import { AlertsManagerModule } from '../organisation/alerts/alerts.module';
import { DashboardManagerComponent } from './dashboard-manager.component';
import { DashboardPreloaderButtonsComponent } from './dashboard-preloader/dashboard-preloader-buttons/dashboard-preloader-buttons.component';
import { DashboardPreloaderMembersComponent } from './dashboard-preloader/dashboard-preloader-members/dashboard-preloader-members.component';
import { DashboardPreloaderRostersComponent } from './dashboard-preloader/dashboard-preloader-rosters/dashboard-preloader-rosters.component';
import { DashboardPreloaderSearchComponent } from './dashboard-preloader/dashboard-preloader-search/dashboard-preloader-search.component';
import { EditOrganisationModalComponent } from './modals/edit-organisation/edit-organisation-modal.component';
import { EditRosterModalComponent } from './modals/edit-roster/edit-roster-modal.component';
import { MemberModalComponent } from './modals/member/member-modal.component';
import { RosterModalComponent } from './modals/roster/roster-modal.component';
import { RostersComponent } from './rosters/rosters.component';
import { CurrentOrderDetailsComponent } from '../../shared/members/modals/current-order-details/current-order-details.component';
import { EventCalendarModalComponent } from '../../shared/members/modals/event-calendar/event-calendar-modal.component';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    SharedModule,
    RouterModule,
    AlertsManagerModule,
    NgbModule,
    Select2Module
  ],
  declarations: [
    DashboardManagerComponent,
    RostersComponent,
    // Modal
    ProfileModalComponent,
    InformModalComponent,
    TagsComponent,
    DeploymentOffersModalComponent,
    DeploymentOrdersModalComponent,
    OrderDetailsModalComponent,
    CurrentOrderDetailsComponent,
    SendOfferModalComponent,
    AddRosterModalComponent,
    AlertModalComponent,
    RosterModalComponent,
    MemberModalComponent,
    EditRosterModalComponent,
    EditOrganisationModalComponent,
    DashboardPreloaderRostersComponent,
    DashboardPreloaderMembersComponent,
    DashboardPreloaderSearchComponent,
    DashboardPreloaderButtonsComponent,
    EventCalendarModalComponent

  ],
  providers: [],
  entryComponents: [
      ProfileModalComponent,
      InformModalComponent,
      TagsComponent,
      DeploymentOffersModalComponent,
      DeploymentOrdersModalComponent,
      OrderDetailsModalComponent,
      CurrentOrderDetailsComponent,
      SendOfferModalComponent,
      AddRosterModalComponent,
      AlertModalComponent,
      RosterModalComponent,
      MemberModalComponent,
      EditRosterModalComponent,
      EditOrganisationModalComponent,
      EventCalendarModalComponent
  ]
})


export class DashboardManagerModule { }
