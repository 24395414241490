/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-preloader-process.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-preloader-process.component";
var styles_AlertsPreloaderProcessComponent = [i0.styles];
var RenderType_AlertsPreloaderProcessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsPreloaderProcessComponent, data: {} });
export { RenderType_AlertsPreloaderProcessComponent as RenderType_AlertsPreloaderProcessComponent };
export function View_AlertsPreloaderProcessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "alerts-preloader-process-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "alerts-preloader-process-small-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "alerts-preloader-process-big-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "alerts-preloader-process-big-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "alerts-preloader-process-big-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "process-preloader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "preloader-calendar-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar-o fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "preloader-lines"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "preloader-message-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-envelope-o fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "preloader-lines"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "preloader-flight-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plane fa-2x"]], null, null, null, null, null))], null, null); }
export function View_AlertsPreloaderProcessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-preloader-process", [], null, null, null, View_AlertsPreloaderProcessComponent_0, RenderType_AlertsPreloaderProcessComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertsPreloaderProcessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsPreloaderProcessComponentNgFactory = i1.ɵccf("alerts-preloader-process", i2.AlertsPreloaderProcessComponent, View_AlertsPreloaderProcessComponent_Host_0, {}, {}, []);
export { AlertsPreloaderProcessComponentNgFactory as AlertsPreloaderProcessComponentNgFactory };
