import { OrganisationDispatcherService } from '../api/organisation/organisation.dispatcher';
import { Injectable } from '@angular/core';
import { Router     } from '@angular/router';

// Service
import { LoggerService } from './logger.service';
import { CacheService } from '../storage/cache.service';

// Interface
import { UserInterface } from '../../app/model/user.interface';

import { environment } from '../../environments/environment';
import { OrganisationInterface } from '../../app/model/organisation.interface';

@Injectable()

export class NavigationService {

  private isManagerWebsite: string;
  private organisationId: number;

  constructor(
    private router: Router,
    private _loggerService: LoggerService,
    private cache: CacheService,
    private _organisationDispatcher: OrganisationDispatcherService
  ) {
    // let user: UserInterface = this.cache.get(CacheService.USER) || {};
    this._organisationDispatcher.getSelectedOrganisation().subscribe(
      (res: OrganisationInterface) => {
        this.organisationId = res.id;
      }
    );
  }

  goToResponderDashboard() {
    let lastOrganisation = this.cache.get(CacheService.CURRENT_ORGANISATION);
    this.router.navigate(['/responder/organisation/' + lastOrganisation.id]);
  }

  // Login to Welcome
  goToWelcomeResponder(organisations): void {
    this._loggerService.log('NAVIGATION : go to Welcome page', organisations[0].organisation.id);
    this.cache.set(CacheService.CURRENT_ORGANISATION, organisations[0].organisation);
    this.router.navigate(['/responder/organisation/' + organisations[0].organisation.id]);
  }

  // Login to Welcome Manager
  goToWelcomeManager(organisations): void {
    this._loggerService.log('NAVIGATION : go to Welcome manager page');
    this.cache.set(CacheService.CURRENT_ORGANISATION, organisations[0].organisation);
    this.router.navigate(['/manager/organisation/' + organisations[0].organisation.id]);
  }

  goToPublic(): void {
    this._loggerService.log('NAVIGATION : go to Login page');
    if (environment.envName === 'adpc') {
      window.location.href = 'https://app.adpc.net/practitioners-roster';
    } else {
      this.router.navigate(['/public']);
    }
  }

  goToHome(): void {
    this.router.navigate(['/public/home']);
  }

  goToRegister(): void {
    this._loggerService.log('NAVIGATION : go to Register page');
    this.router.navigate(['/public/register']);
  }

  goToProfile(): void {
    this._loggerService.log('NAVIGATION : go to Profile page');
    this.router.navigate(['/responder/profile']);
  }

  goToAlertDetails(alertId, organisationId?): void {
    // this.router.navigate(['/responder/alert/' + alertId]);
    this.router.navigate(['/responder/organisation/' + organisationId + '/alert/' + alertId]);
  }

  goToRosterDetails(organisationId, rosterId): void {
    this.router.navigate(['/responder/organisation/' + this.organisationId + '/roster/invit/' + rosterId]);
  }

  goToOrganisation(organisationId, status): void {
    this.router.navigate(['/' + status + '/organisation/' + organisationId]);
  }

  // Switch responder from Member to Manager
  goToManagerWebsite(orgaId): void {
    this.isManagerWebsite = this.cache.get(CacheService.ISMANAGER_WEBSITE);
    this._loggerService.log("Entering manager website");
      this.router.navigate(['/manager/organisation/' + orgaId]);
  }

  // Switch responder from Manager to Member
  goToMemberWebsite(orgaId): void {
    this.isManagerWebsite = this.cache.get(CacheService.ISMANAGER_WEBSITE);
    this._loggerService.log("Entering member website");
    this.router.navigate(['/responder/organisation/' + orgaId]);
  }

  goToJobsList(idOrga): void {
    this._loggerService.log("Entering Jobs List");
    this.router.navigate(['/manager/organisation/' + idOrga + '/jobs/list']);
  }

  goToSettings(): void {
    this._loggerService.log("Entering Settings of Organisation");
    this.router.navigate(['responder/settings']);
  }

  goToManagerForbidden(): void {
    this.router.navigate(['/manager/forbidden']);
  }
  goToForbidden(): void {
    this.router.navigate(['/forbidden']);
  }

}
