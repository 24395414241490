/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-preloader-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-preloader-content.component";
var styles_AlertsPreloaderContentComponent = [i0.styles];
var RenderType_AlertsPreloaderContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsPreloaderContentComponent, data: {} });
export { RenderType_AlertsPreloaderContentComponent as RenderType_AlertsPreloaderContentComponent };
export function View_AlertsPreloaderContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "alerts-preloader-content shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "alerts-preloader-content-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "alerts-preloader-content-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "alerts-preloader-content-block"]], null, null, null, null, null))], null, null); }
export function View_AlertsPreloaderContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-preloader-content", [], null, null, null, View_AlertsPreloaderContentComponent_0, RenderType_AlertsPreloaderContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertsPreloaderContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsPreloaderContentComponentNgFactory = i1.ɵccf("alerts-preloader-content", i2.AlertsPreloaderContentComponent, View_AlertsPreloaderContentComponent_Host_0, {}, {}, []);
export { AlertsPreloaderContentComponentNgFactory as AlertsPreloaderContentComponentNgFactory };
