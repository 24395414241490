/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selector-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/ng2-select2/ng2-select2.component.ngfactory";
import * as i4 from "ng2-select2/ng2-select2.component";
import * as i5 from "./selector-filter.component";
import * as i6 from "../../services/filter.service";
var styles_SelectorFilterComponent = [i0.styles];
var RenderType_SelectorFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectorFilterComponent, data: {} });
export { RenderType_SelectorFilterComponent as RenderType_SelectorFilterComponent };
function View_SelectorFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select an event : "]))], null, null); }
function View_SelectorFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select a roster : "]))], null, null); }
export function View_SelectorFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "label", [["for", "select-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorFilterComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorFilterComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "select2", [["cssImport", "true"], ["id", "select-item"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.changed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Select2Component_0, i3.RenderType_Select2Component)), i1.ɵdid(7, 4964352, null, 0, i4.Select2Component, [i1.Renderer], { data: [0, "data"], value: [1, "value"], cssImport: [2, "cssImport"], options: [3, "options"] }, { valueChanged: "valueChanged" }), i1.ɵpod(8, { multiple: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.item == "Event") || (_co.item == "SelectedEvent")); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.item == "Roster") || (_co.item == "SelectedRoster")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data; var currVal_3 = _co.selectedItem; var currVal_4 = "true"; var currVal_5 = _ck(_v, 8, 0, false); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_SelectorFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "selector-filter", [], null, null, null, View_SelectorFilterComponent_0, RenderType_SelectorFilterComponent)), i1.ɵdid(1, 376832, null, 0, i5.SelectorFilterComponent, [i1.KeyValueDiffers, i6.FilterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectorFilterComponentNgFactory = i1.ɵccf("selector-filter", i5.SelectorFilterComponent, View_SelectorFilterComponent_Host_0, { id: "id", page: "page", referenceKey: "referenceKey", data: "data", item: "item", selectorFilter: "selectorFilter" }, { emitFilter: "emitFilter" }, []);
export { SelectorFilterComponentNgFactory as SelectorFilterComponentNgFactory };
