export class ChartRegistration {
    constructor() { }
    /**
     * Provide a hash used as unique id
     */
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    /**
     * Add chart to the registration table
     * @param chart
     */
    generateId(chart) {
        if (chart.uniqId)
            throw new Error('Chart ' + chart.uniqId + ' already registered');
        let uniqId = '';
        do {
            uniqId = this.guid();
        } while (ChartRegistration.associations.findIndex((item) => item.chartId === uniqId) > -1);
        chart.uniqId = uniqId;
    }
    registerFilter(chart, filter) {
        const index = ChartRegistration.associations.findIndex((item) => item.chartId === chart.uniqId);
        ChartRegistration.associations.push({
            chartId: chart.uniqId,
            filterId: filter.getFullname(),
            currentValue: undefined,
            oldValue: undefined,
            referenceKey: filter.referenceKey
        });
    }
    /**
     * Remove chart from the registration table
     * @param chart
     */
    unregister(chart) {
        let index;
        while (index = ChartRegistration.associations.findIndex((item) => item.chartId === chart.uniqId)) {
            if (index > -1) {
                ChartRegistration.associations.splice(index, 1);
            }
        }
    }
    /**
     * Update associations with new values
     * @param index
     * @param newValue
     */
    updateAssociation(index, newValue) {
        let array = ChartRegistration.associations;
        array[index].oldValue = array[index].currentValue;
        array[index].currentValue = newValue;
        ChartRegistration.comparaisons.set(array[index].chartId, false);
    }
}
/**
 * Table association between a chart and its filters
 */
ChartRegistration.associations = [];
/**
 * Table comparaison to know if currentValue has been changed
 */
ChartRegistration.comparaisons = new Map();
