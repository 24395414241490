/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./data-table.component";
var styles_DataTableComponent = [i0.styles];
var RenderType_DataTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataTableComponent, data: {} });
export { RenderType_DataTableComponent as RenderType_DataTableComponent };
function View_DataTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_1); }); }
export function View_DataTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "table", [["class", "table text-center table-bordered table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataAxis.x; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dataAxis.y; _ck(_v, 6, 0, currVal_1); }); }
export function View_DataTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "data-table", [], null, null, null, View_DataTableComponent_0, RenderType_DataTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.DataTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataTableComponentNgFactory = i1.ɵccf("data-table", i3.DataTableComponent, View_DataTableComponent_Host_0, { dataTable: "dataTable", typeChartTable: "typeChartTable", dataAxis: "dataAxis" }, {}, []);
export { DataTableComponentNgFactory as DataTableComponentNgFactory };
