import { AppModule } from './../../../app.module';
import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule  } from '@angular/forms';

// Components
import { SettingsComponent              } from './settings.component';
import { SettingsResetPasswordComponent } from './settings-reset-password/settings-reset-password.component';
import { SettingsSetLanguageComponent   } from './settings-set-language/settings-set-language.component';
import { SettingsNotificationsComponent } from './settings-notifications/settings-notifications.component';
import { SettingsDeleteAccountComponent } from './settings-delete-account/settings-delete-account.component';
import { SettingsSetDebugModeComponent } from './settings-set-debbug-mode/settings-set-debug-mode.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    NgbModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    SettingsComponent,
    SettingsResetPasswordComponent,
    SettingsSetLanguageComponent,
    SettingsNotificationsComponent,
    SettingsDeleteAccountComponent,
    SettingsSetDebugModeComponent
  ],
  providers: []
})


export class SettingsModule {}
