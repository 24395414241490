import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; /* Reporting Module */
// Services
import { WSSEService } from '../security/wsse.service';



@Injectable()
export class HttpService {

  public lastRequestUri: string;

  constructor(
    private _wsseService: WSSEService,
    private http: HttpClient,                /* Reporting Module */
    private router: Router             /* Reporting Module */
  ) { }

  createRequestOptions() {
    let headers = new HttpHeaders({ 'x-wsse': this._wsseService.getHeaderValue() });
    let options = { headers: headers };

    return options;
  }

  /********************************************************************************/
  /************************************* REPORTING MODULE *************************/
  /********************************************************************************/

  /**
 * Build the uri from parameters
 * @param  {any}    parameters [description]
 * @return {[type]}            [description]
 */
  buildUri(parameters: any) {
    let uri = Object.keys(parameters).length > 0 ? '?' : '';

    let keys = Object.keys(parameters);
    keys.forEach((key, index) => {
      uri += key + '=' + encodeURIComponent(parameters[key]);

      if (index < (keys.length - 1)) {
        uri += '&';
      }
    });

    return uri;
  }

  prepareQuery(parameters = {}, headers = {}) {

    let useXWsse = true;
    if ('useXWsse' in parameters) {
      useXWsse = parameters['useXWsse'];
      delete parameters['useXWsse'];
    }

    if (useXWsse) {
      headers['x-wsse'] = this._wsseService.getHeaderValue();
    }
    this.lastRequestUri = this.buildUri(parameters);

    let _headers = new HttpHeaders(headers);
    let options = { headers: _headers };
    return options;
  }

  get(url, parameters = {}, headers = {}) {
    let options = this.prepareQuery(parameters, headers);
    url += this.lastRequestUri;

    return this.http.get(url, options);
  }

  post(url, body, parameters = {}, headers = {}) {
    let options = this.prepareQuery(parameters, headers);
    url += this.lastRequestUri;
    return this.http.post(url, body, options);
  }

  put(url, body, parameters = {}, headers = {}) {
    let options = this.prepareQuery(parameters, headers);
    url += this.lastRequestUri;

    return this.http.put(url, body, options);
  }

  delete(url, parameters = {}, headers = {}) {
    let options = this.prepareQuery(parameters, headers);
    url += this.lastRequestUri;

    return this.http.delete(url, options);
  }

}
