import { firebaseConfig } from "./firebasedata";

/**
 * This environnement points to the staging environment of ADPC
 */

export const environment = {
  production: true,
  envName: 'staging',
  version: require('../../package.json').version,
  disabledFeatures: {
        public: false
  },
  routes: {
    adpc: {
      home: "https://app.adpc.net/practitioners-roster"
    }
  },
  firebaseConfig: firebaseConfig
};

export const URL_API_EMALSYS = 'https://beta-apiemalsys.adpc.net/api/wsse/v3';
