/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-filter.component";
import * as i4 from "../../services/filter.service";
var styles_ButtonFilterComponent = [i0.styles];
var RenderType_ButtonFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonFilterComponent, data: {} });
export { RenderType_ButtonFilterComponent as RenderType_ButtonFilterComponent };
function View_ButtonFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["type", "button"]], [[8, "className", 0], [8, "style", 2], [2, "clicked", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filter(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.class || "btn btn-primary button-filter"); var currVal_1 = _v.context.$implicit.style; var currVal_2 = _v.context.$implicit.active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_3); }); }
export function View_ButtonFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "filter-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "bouttonBox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonFilterComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ButtonFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button-filter", [], null, null, null, View_ButtonFilterComponent_0, RenderType_ButtonFilterComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonFilterComponent, [i4.FilterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonFilterComponentNgFactory = i1.ɵccf("button-filter", i3.ButtonFilterComponent, View_ButtonFilterComponent_Host_0, { id: "id", page: "page", referenceKey: "referenceKey", data: "data", canNotBeDisable: "canNotBeDisable" }, { emitFilter: "emitFilter" }, []);
export { ButtonFilterComponentNgFactory as ButtonFilterComponentNgFactory };
