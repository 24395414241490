import { Component, Input, OnInit } from '@angular/core';
import { DeploymentOrderService } from '../../../../../../services/api/deployment-order.service';
import { DeploymentService } from '../../../../../../services/api/deployment.service';
import { UserEventAnswerService } from '../../../../../../services/api/usereventanswer.service';
// Constants
import { ISO2_TO_ISO3, URL_API_RELIEF_WEB_IMG } from '../../../../../app.constants';
@Component({
  selector: 'component-alert',
  templateUrl: './component-alert.component.html',
  styleUrls: ['./component-alert.component.scss']
})


export class AlertComponent implements OnInit {   // TODO: Do something about this stuff about ISO2
  @Input() alert: any;
  calendarStep = "";
  offerStep = "";
  orderStep = "";
  public SELECTIONS;

  public deploymentOffers;
  public nbrOrders;
  public nbrActiveOrders;
  public URL_API_RELIEF_WEB_IMG: any;
  public ISO2_TO_ISO3: any;

  constructor(
    private _userEventAnswerService: UserEventAnswerService,
    private _deploymentOrderService: DeploymentOrderService,
    private _deploymentService: DeploymentService
  ) {
    this.URL_API_RELIEF_WEB_IMG = URL_API_RELIEF_WEB_IMG;
    this.ISO2_TO_ISO3 = ISO2_TO_ISO3;

  }

  ngOnInit() {
    this.getSelection(this.alert.id);
  }

  getSelection(idAlert) {
    this._userEventAnswerService.getAvailability(idAlert).subscribe((res) => {
      this.SELECTIONS = res[0].user_event_answers;
      this.getDeploymentOffersByEvent(this.alert.id);
    });
  }
  getDeploymentOffersByEvent(idAlert) {
    this._deploymentOrderService.getDeploymentOffersByEvent(idAlert).subscribe(
      res => {
        this.deploymentOffers = res;
        this.getDeploymentByEvent(this.alert.id);
      },
      error => {
        this.deploymentOffers = [];
        this.getDeploymentByEvent(this.alert.id);
      }
    );
  }

  getDeploymentByEvent(idAlert) {
    const today = new Date();
    this._deploymentService.getDeploymentByEvent(idAlert).subscribe(res => {
      this.nbrOrders = res.length;
      this.nbrActiveOrders = 0;
      res.forEach(order => {
        if (new Date(order.end_date) > today) {
          this.nbrActiveOrders++;
        }
      });

      this.setProgress();
    },
      error => {
        this.nbrOrders = 0;
        this.setProgress();
      });
  }
  setProgress() {

    const today = new Date();

    if (this.SELECTIONS.length !== 0) {
      this.calendarStep = "active";
    } else {
      this.calendarStep = "pending";
    }

    let counterValidated = 0;
    let counterRefused = 0;
    let counterPending = 0;

    for (let i = 0; i < this.deploymentOffers.length; i++) {
      if (this.deploymentOffers[i].valid === 2 || this.deploymentOffers[i].valid === 3 && new Date(this.deploymentOffers[i].end_date) > today) {
        counterRefused++;
      } else if (this.deploymentOffers[i].valid === 1 && new Date(this.deploymentOffers[i].end_date) > today) {
        this.orderStep = "pending";
        counterValidated++;
      } else if (new Date(this.deploymentOffers[i].end_date) > today) {
        counterPending++;
      } else {
        counterValidated = 0;
        counterPending = 0;
        counterRefused = 0;
      }

    }
    if (this.deploymentOffers.length > 0 && counterRefused === this.deploymentOffers.length) {
      this.offerStep = "refuse";
    } else if (counterPending > 0) {
      this.offerStep = "pending";
    } else if (counterValidated > 0) {
      this.offerStep = "active";
    }

    if (this.nbrActiveOrders > 0) {
      this.orderStep = "active";
    }
  }
  errorHandler(event) {
    event.target.src = this.URL_API_RELIEF_WEB_IMG + "wld.png";
  }
}
