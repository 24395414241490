import { UserLanguageInterface } from '../../../app/model/user-language.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Dispatcher } from '../../dispatcher.service';
import { TagInterface } from '../../../app/model/tag.interface';

@Injectable()
export class GlobalDispatcherService extends Dispatcher {
    private allLanguages$ = new BehaviorSubject<UserLanguageInterface[]>([]);
    private allSkills$ = new BehaviorSubject<TagInterface[]>([]);

    public nextSkills(skills: TagInterface[]) {
        this.allSkills$.next(skills);
    }

    public getSkills(): Observable<TagInterface[]> {
        return this.pipeNotNull(this.allSkills$);
    }

    public nextLanguages(languages: UserLanguageInterface[]) {
        this.allLanguages$.next(languages);
    }

    public getLanguages(): Observable<UserLanguageInterface[]> {
        return this.pipeNotNull(this.allLanguages$);
    }

}
