<ng-container *ngIf="bannerVisibility">
<div *ngIf="organisation" id="main-component" (click)='openProfile(false); openNotification(false)'>
  <!--Banner of the responder -->
  <div class="main-component-banner">
    <!--Clickable logo-->
    <div class="banner-logo">

      <!-- Made it a normal link so you can open in new webpage -->
      <a class="logo-button" href="/{{status}}/organisation/{{organisation.id}}" routerLink='/{{status}}/organisation/{{organisation.id}}'>
      <!-- <button *ngIf="organisation" class="logo-button"> -->
        <img class="button-image" [src]="organisation.browser_url"
          onerror='this.src="assets/images/default_organisation.png"' />
        <h2 class="button-text">{{organisation.name}}</h2>
      <!-- </button> -->
    </a>

      <!-- <button *ngIf="organisation" class="logo-button" routerLink='/{{status}}/organisation/{{organisation.id}}'>
           <img class="button-image" [src]="organisation.browser_url" onerror='this.src="assets/images/default_organisation.png"'/>
           <h2 class="button-text">{{organisation.name}}</h2>
         </button> -->

    </div>

    <div class="banner-profile">
      <!--Items accessible only by managers-->
      <!-- <button class="profile-finder-button" *ngIf="isManagerWebsite === 1 && organisation.id" routerLink="/manager/organisation/{organisation.id}}/jobs/search" i18n="@@profileFinderLabel">Profile Finder</button>
      <button class="job-list-button" *ngIf="isManagerWebsite === 1 && organisation.id" routerLink="/manager/organisation/{{organisation.id}}/jobs/list" i18n="@@jobsListLabel">Jobs List</button> -->
      <!--Dropdown menu right-->

      <button class="reporting-button" *ngIf="isManager(organisation.id) && isManagerWebsite === 1"
        routerLink='/{{status}}/organisation/{{organisation.id}}/reporting'><i class="fa fa-bar-chart"></i></button>


      <button [disabled]="!notificationList" class="notification-button"
        (click)='openProfile(false);openNotification(); $event.stopPropagation()'>

        <i class="fa fa-flag-o" aria-hidden="true"></i>
        <div class="notification-count" *ngIf="notificationCount">
          {{notificationCount}}
        </div>
      </button>
      <notification class="notification-container shadow" *ngIf='notificationOpen' (onAnswerOrg)="onAnswerOrg($event)"
        (onAnswer)="onAnswerAlert($event)" (onCloseClick)="closeNotifications($event)"
        [notificationList]="notificationList" [notificationCount]="notificationCount"
        [notificationTypeCount]="notificationTypeCount"></notification>
      <button class="profile-name" (click)='openProfile(); $event.stopPropagation(); openNotification(false)'>
        <i class="fa fa-angle-left" [class.open]='profileOpen'></i>
        <span id="test" class="name-text">{{user.firstname}}</span>
        <!-- <span id="test" class="name-text">{{userProfile.firstname}}</span> -->
        <img class="name-picture" [src]="user.picture" onerror='this.src="assets/images/default_avatar.png"' />
        <!-- <img class="name-picture" [src]="userProfile.picture" onerror='this.src="assets/images/default_avatar.png"' /> -->
      </button>
      <div class="profile-options" *ngIf='profileOpen'>
        <button class="options-option" routerLink='/responder/profile'>
          <!-- <a href="/responder/profile" class="options-option" routerLink='/responder/profile'> -->
          <span i18n="@@profileLabel">Profile</span><i
            class="fa fa-user"></i>
          <!-- </a> -->
        </button>

        <button *ngIf="isOrganisationSuperAdmin" class="options-option"
          routerLink='/manager/organisation/{{organisation.id}}/settings'><span>Organisation settings</span><i
            class="fa fa-sliders"></i></button>
        
        
        <button *ngIf="organisations.length>1" class="options-option"
          (click)="switchOrganisations(availabilityModal)"><span>Switch organisations</span><i
            class="fa fa-bars"></i></button>
        <!-- <button class="options-option" *ngIf="isManager() && isManagerWebsite === 1" routerLink='/{{status}}/settings'><span i18n="@@settingsLabel">Organisation Settings</span><i class="fa fa-sliders"></i></button> -->
        <!-- <button class="options-option" routerLink='/{{status}}/user-guide'><span i18n="@@userGuideLabel">User Guide</span><i class="fa fa-question"></i></button> -->
        <!-- <button class="options-option" routerLink='/{{status}}/contact'><span i18n="@@contactLabel">Contact Us</span><i class="fa fa-envelope"></i></button> -->
        <button class="options-option"
          *ngIf="!checkRouteProfile() && !checkRouteSettings() && isManager(organisation.id) && (isManagerWebsite === 0 || isManagerWebsite === null)"
          (click)="switchToManagerWebsite()"><span i18n="@@switchToManagerLabel">Switch to Manager</span><i
            class="fa fa-refresh"></i></button>
        <button class="options-option"
          *ngIf="!checkRouteProfile() && !checkRouteSettings() && isManager(organisation.id) && isManagerWebsite === 1"
          (click)="switchToMemberWebsite()"> <span i18n="@@switchToManagerLabel">Switch to Member</span><i
            class="fa fa-refresh"></i></button>

        <button class="options-option" *ngIf="isAdmin()" routerLink='/admin'><span
            i18n="@@administrationLabel">Administration<i class="fa fa-cart-plus"
              aria-hidden="true"></i></span></button>
        <button class="options-option" (click)='logout()'><span i18n="@@logoutLabel">Logout</span><i
            class="fa fa-sign-out"></i></button>
        
        <button class="options-option"><span i18n="@@logoutLabel">Current Version - v{{VERSION}}</span></button>
      </div>
    </div>
  </div>
  <!--Content of the page-->
  <div class="main-component-body">

    <!--Modal switch organisations -->
    <ng-template #availabilityModal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" i18n="@@changePicture">Switch organisations</h4>
        <!--Close button-->
        <button type="button" class="close" aria-label="Close" (click)="d()">
          <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="flex-container row">
          <button class="list-orga" *ngFor='let organisation of organisations'
            (click)='switchOrganisation(organisation);c()'>
            <img class="orga-logo" [src]='organisation.browser_url'
              onerror='this.src="assets/images/default_organisation.png"' />
            {{organisation.name}}
          </button>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </ng-template>

  </div>
</div>

<!-- Temporary fix to have the content under the banner -->
<div class="banner-top-padding"></div>

<div id="setAvailabilitiesToast">Availability set</div>

<div id="acceptOfferToast">Deployment offer accepted</div>
<div id="refuseOfferToast">Deployment offer refused</div>

<div id="acknowledgeOrder">Deployment order acknowledged</div>

<div id="acceptJoinRequestToast">Request accepted</div>
<div id="refuseJoinRequestToast">Request refused</div>

<div id="acceptInvitationToast">Invitation accepted</div>
<div id="refuseInvitationToast">Invitation refused</div>

<div id="quitRosterToast">You have left the roster</div>
<div id="cancelJoinRequestToast">Request cancelled</div>

</ng-container>
<!-- <router-outlet></router-outlet> -->