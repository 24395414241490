import {Component, Input, OnInit} from '@angular/core';

import { TagsService } from '../../../../../services/api/tag/tag.api';
import { LoggerService  } from '../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../services/utils/toast.service';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TagDispatcherService } from '../../../../../services/api/tag/tag.dispatcher';

@Component({
  selector   : 'profile-tags',
  templateUrl: './profile-tags.component.html',
  styleUrls  : ['./profile-tags.component.scss']
})


export class ProfileTagsComponent implements OnInit {

  @Input() profile: any;
  public tags: any[];                     // my current skills
  public tagsList: any[];                 // all skills available to add
  public organisations: any[];                // temporary list of skills to be added
  public rosters: any[];                     // id of selected skill to add
  public skillsCanBeAdded= true;  // if you can add more skills
  public maxSkillsNumber = 5;       // max number of skills permitted; old value : 6
  public loading = false;
  public modalRef;

  constructor(
    private _tagsService: TagsService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    // private _tagDispatcher: TagDispatcherService // not useful here
  ) {
    // this.skills = null;
  }

  ngOnInit() {
    this.loading = true;
    this.initVariables();
  }

  initVariables() {
    this.getTags();
  }

  getTags() {
    this.tagsList = this.profile.roster_members
      .map(x => x.roster_member_tags)
      .flat()
      .map( x => x.roster_tag);
    this.loading = false;
  }

  openTagModal(modalId) {
    let modal = this._modalService.open(modalId,  { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

}
