<div class="event-calendar-modal">
<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Event Calendar</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div id="alerts-calendar-component" class="background-component">
    <div class ="date-container">
    <div class="first-container">
      <div class="month-container">
  
      <p style="align-self: center;">{{calendar.getSelectedMonth()}}</p>
      <p style="align-self: center;">{{calendar.getSelectedYear()}}</p>
      <div class="month-arrows">
      <button [disabled]='calendar.checkMonthValidity()' (click)="calendar.previousMonth();getDaysArray(); resetSelection()">
          <i class="fa fa-chevron-left" aria-hidden="true" ></i>
      </button>
      <button (click)="calendar.nextMonth();getDaysArray();resetSelection()">
        <i class="fa fa-chevron-right" aria-hidden="true" ></i>
      </button>
      </div>
    </div>
    </div>
    <div class="second-container">
      <div class="days-displayer">


          <div class="days" *ngFor="let day of days">
            </div>
            <div class="days-numbers-container flex-container row">
              <div *ngFor="let day of days" class="days">
                <div class="day-number-container">
                <p class="day-number">{{day.date | date : "dd"}} </p>
                </div>
              </div>
            </div>



      </div>
    </div>
    </div>
    <div class="rectangle" *ngFor=" let member of members">
      <div class="first-container">
        <div class="user-infos-container">
          <p class="name-user">{{member.firstname}} {{member.lastname}}</p>
        </div>
      </div>
      <div class="second-container">
        <div class="calendar-container column">
          <div class="days" *ngFor="let day of days">
          </div>
          <div class="days-numbers-container flex-container row">
            <div *ngFor="let day of days" class="days">
              <div class="day-number-container" [ngClass]="getAvailability(member.id, day)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
</div>
</div>
