// RELIEF WEB
export const URL_API_RELIEF_WEB           = 'https://api.reliefweb.int/v1';
export const URL_API_RELIEF_WEB_IMG       = 'https://reliefweb.int/sites/reliefweb.int/files/country-location-images/';
export const URL_API_RELIEF_WEB_IMG_LG    = 'https://reliefweb.int/sites/reliefweb.int/files/country-location-images-large/';

// MAP
export const URL_MAPBOX         = "https://api.mapbox.com/styles/v1/";
export const MAPBOX_ACCESSTOKEN = "pk.eyJ1IjoicmVsaWVmYXBwcyIsImEiOiJjajV2NDV4NmYxcmNiMnFxcnZodGJpZ3Z5In0.KzVmlsxHnmByHomEkJuRNw";

// NEWS
export const NEWS_CODE = [
  {value: 0, label: "News"     },
  {value: 1, label: "Event"    },
  {value: 2, label: "Job Offer"},
  {value: 3, label: "Training" }
];

// ROSTERS
export const MEMBER_CODE = [
  {value: -1, label: "Pending"},
  {value:  0, label: "Frozen" },
  {value:  1, label: "Active" },
  {value:  2, label: "Invited"}
];

export const ROSTER_MANAGER_RIGHTS_LEVEL = {
  NotManager: 0,
  Guest: 1,
  Manager: 2,
  Supervisor: 3
};

// ORGANISATIONS
export const ORGANISATION_MANAGER_RIGHTS_LEVEL = {
  NotManager: 0,
  Associate: 1,
  Admin: 2,
  SuperAdmin: 3
};

// Tag modal result
export const TAG_MODAL_RESULT = {
  APPLIED: 'applied'
};


export const COMPLIANCE_CODE = [
  {value: 0, label: "Optional"},
  {value: 1, label: "Required"}
];

export const OLD_DEPLOYMENT_CODE = [
  {value: 1, label: "Deployed trough Emalsys"},
  {value: 2, label: "Deployment added manually"}
];

export const PAGES = {
  manager: {
    organisation: {
      alerts: 'alerts',
      members: 'members'
    }
  }
};

export const ROUTING = {
    parameter: {
        organisationId: 'orga',
        userId: 'userId',
    }
};

export const REGEX_TYPES = {
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PHONE_NO_PREFIX: / /, // FIXME
};


// COUNTRY CODES ISO 3
// Data from http://country.io
export const ISO2_TO_ISO3 = {
    BD: "BGD".toLowerCase(),
    BE: "BEL".toLowerCase(),
    BF: "BFA".toLowerCase(),
    BG: "BGR".toLowerCase(),
    BA: "BIH".toLowerCase(),
    BB: "BRB".toLowerCase(),
    WF: "WLF".toLowerCase(),
    BL: "BLM".toLowerCase(),
    BM: "BMU".toLowerCase(),
    BN: "BRN".toLowerCase(),
    BO: "BOL".toLowerCase(),
    BH: "BHR".toLowerCase(),
    BI: "BDI".toLowerCase(),
    BJ: "BEN".toLowerCase(),
    BT: "BTN".toLowerCase(),
    JM: "JAM".toLowerCase(),
    BV: "BVT".toLowerCase(),
    BW: "BWA".toLowerCase(),
    WS: "WSM".toLowerCase(),
    BQ: "BES".toLowerCase(),
    BR: "BRA".toLowerCase(),
    BS: "BHS".toLowerCase(),
    JE: "JEY".toLowerCase(),
    BY: "BLR".toLowerCase(),
    BZ: "BLZ".toLowerCase(),
    RU: "RUS".toLowerCase(),
    RW: "RWA".toLowerCase(),
    RS: "SRB".toLowerCase(),
    TL: "TLS".toLowerCase(),
    RE: "REU".toLowerCase(),
    TM: "TKM".toLowerCase(),
    TJ: "TJK".toLowerCase(),
    RO: "ROU".toLowerCase(),
    TK: "TKL".toLowerCase(),
    GW: "GNB".toLowerCase(),
    GU: "GUM".toLowerCase(),
    GT: "GTM".toLowerCase(),
    GS: "SGS".toLowerCase(),
    GR: "GRC".toLowerCase(),
    GQ: "GNQ".toLowerCase(),
    GP: "GLP".toLowerCase(),
    JP: "JPN".toLowerCase(),
    GY: "GUY".toLowerCase(),
    GG: "GGY".toLowerCase(),
    GF: "GUF".toLowerCase(),
    GE: "GEO".toLowerCase(),
    GD: "GRD".toLowerCase(),
    GB: "GBR".toLowerCase(),
    GA: "GAB".toLowerCase(),
    SV: "SLV".toLowerCase(),
    GN: "GIN".toLowerCase(),
    GM: "GMB".toLowerCase(),
    GL: "GRL".toLowerCase(),
    GI: "GIB".toLowerCase(),
    GH: "GHA".toLowerCase(),
    OM: "OMN".toLowerCase(),
    TN: "TUN".toLowerCase(),
    JO: "JOR".toLowerCase(),
    HR: "HRV".toLowerCase(),
    HT: "HTI".toLowerCase(),
    HU: "HUN".toLowerCase(),
    HK: "HKG".toLowerCase(),
    HN: "HND".toLowerCase(),
    HM: "HMD".toLowerCase(),
    VE: "VEN".toLowerCase(),
    PR: "PRI".toLowerCase(),
    PS: "PSE".toLowerCase(),
    PW: "PLW".toLowerCase(),
    PT: "PRT".toLowerCase(),
    SJ: "SJM".toLowerCase(),
    PY: "PRY".toLowerCase(),
    IQ: "IRQ".toLowerCase(),
    PA: "PAN".toLowerCase(),
    PF: "PYF".toLowerCase(),
    PG: "PNG".toLowerCase(),
    PE: "PER".toLowerCase(),
    PK: "PAK".toLowerCase(),
    PH: "PHL".toLowerCase(),
    PN: "PCN".toLowerCase(),
    PL: "POL".toLowerCase(),
    PM: "SPM".toLowerCase(),
    ZM: "ZMB".toLowerCase(),
    EH: "ESH".toLowerCase(),
    EE: "EST".toLowerCase(),
    EG: "EGY".toLowerCase(),
    ZA: "ZAF".toLowerCase(),
    EC: "ECU".toLowerCase(),
    IT: "ITA".toLowerCase(),
    VN: "VNM".toLowerCase(),
    SB: "SLB".toLowerCase(),
    ET: "ETH".toLowerCase(),
    SO: "SOM".toLowerCase(),
    ZW: "ZWE".toLowerCase(),
    SA: "SAU".toLowerCase(),
    ES: "ESP".toLowerCase(),
    ER: "ERI".toLowerCase(),
    ME: "MNE".toLowerCase(),
    MD: "MDA".toLowerCase(),
    MG: "MDG".toLowerCase(),
    MF: "MAF".toLowerCase(),
    MA: "MAR".toLowerCase(),
    MC: "MCO".toLowerCase(),
    UZ: "UZB".toLowerCase(),
    MM: "MMR".toLowerCase(),
    ML: "MLI".toLowerCase(),
    MO: "MAC".toLowerCase(),
    MN: "MNG".toLowerCase(),
    MH: "MHL".toLowerCase(),
    MK: "MKD".toLowerCase(),
    MU: "MUS".toLowerCase(),
    MT: "MLT".toLowerCase(),
    MW: "MWI".toLowerCase(),
    MV: "MDV".toLowerCase(),
    MQ: "MTQ".toLowerCase(),
    MP: "MNP".toLowerCase(),
    MS: "MSR".toLowerCase(),
    MR: "MRT".toLowerCase(),
    IM: "IMN".toLowerCase(),
    UG: "UGA".toLowerCase(),
    TZ: "TZA".toLowerCase(),
    MY: "MYS".toLowerCase(),
    MX: "MEX".toLowerCase(),
    IL: "ISR".toLowerCase(),
    FR: "FRA".toLowerCase(),
    IO: "IOT".toLowerCase(),
    SH: "SHN".toLowerCase(),
    FI: "FIN".toLowerCase(),
    FJ: "FJI".toLowerCase(),
    FK: "FLK".toLowerCase(),
    FM: "FSM".toLowerCase(),
    FO: "FRO".toLowerCase(),
    NI: "NIC".toLowerCase(),
    NL: "NLD".toLowerCase(),
    NO: "NOR".toLowerCase(),
    NA: "NAM".toLowerCase(),
    VU: "VUT".toLowerCase(),
    NC: "NCL".toLowerCase(),
    NE: "NER".toLowerCase(),
    NF: "NFK".toLowerCase(),
    NG: "NGA".toLowerCase(),
    NZ: "NZL".toLowerCase(),
    NP: "NPL".toLowerCase(),
    NR: "NRU".toLowerCase(),
    NU: "NIU".toLowerCase(),
    CK: "COK".toLowerCase(),
    XK: "XKX".toLowerCase(),
    CI: "CIV".toLowerCase(),
    CH: "CHE".toLowerCase(),
    CO: "COL".toLowerCase(),
    CN: "CHN".toLowerCase(),
    CM: "CMR".toLowerCase(),
    CL: "CHL".toLowerCase(),
    CC: "CCK".toLowerCase(),
    CA: "CAN".toLowerCase(),
    CG: "COG".toLowerCase(),
    CF: "CAF".toLowerCase(),
    CD: "COD".toLowerCase(),
    CZ: "CZE".toLowerCase(),
    CY: "CYP".toLowerCase(),
    CX: "CXR".toLowerCase(),
    CR: "CRI".toLowerCase(),
    CW: "CUW".toLowerCase(),
    CV: "CPV".toLowerCase(),
    CU: "CUB".toLowerCase(),
    SZ: "SWZ".toLowerCase(),
    SY: "SYR".toLowerCase(),
    SX: "SXM".toLowerCase(),
    KG: "KGZ".toLowerCase(),
    KE: "KEN".toLowerCase(),
    SS: "SSD".toLowerCase(),
    SR: "SUR".toLowerCase(),
    KI: "KIR".toLowerCase(),
    KH: "KHM".toLowerCase(),
    KN: "KNA".toLowerCase(),
    KM: "COM".toLowerCase(),
    ST: "STP".toLowerCase(),
    SK: "SVK".toLowerCase(),
    KR: "KOR".toLowerCase(),
    SI: "SVN".toLowerCase(),
    KP: "PRK".toLowerCase(),
    KW: "KWT".toLowerCase(),
    SN: "SEN".toLowerCase(),
    SM: "SMR".toLowerCase(),
    SL: "SLE".toLowerCase(),
    SC: "SYC".toLowerCase(),
    KZ: "KAZ".toLowerCase(),
    KY: "CYM".toLowerCase(),
    SG: "SGP".toLowerCase(),
    SE: "SWE".toLowerCase(),
    SD: "SDN".toLowerCase(),
    DO: "DOM".toLowerCase(),
    DM: "DMA".toLowerCase(),
    DJ: "DJI".toLowerCase(),
    DK: "DNK".toLowerCase(),
    VG: "VGB".toLowerCase(),
    DE: "DEU".toLowerCase(),
    YE: "YEM".toLowerCase(),
    DZ: "DZA".toLowerCase(),
    US: "USA".toLowerCase(),
    UY: "URY".toLowerCase(),
    YT: "MYT".toLowerCase(),
    UM: "UMI".toLowerCase(),
    LB: "LBN".toLowerCase(),
    LC: "LCA".toLowerCase(),
    LA: "LAO".toLowerCase(),
    TV: "TUV".toLowerCase(),
    TW: "TWN".toLowerCase(),
    TT: "TTO".toLowerCase(),
    TR: "TUR".toLowerCase(),
    LK: "LKA".toLowerCase(),
    LI: "LIE".toLowerCase(),
    LV: "LVA".toLowerCase(),
    TO: "TON".toLowerCase(),
    LT: "LTU".toLowerCase(),
    LU: "LUX".toLowerCase(),
    LR: "LBR".toLowerCase(),
    LS: "LSO".toLowerCase(),
    TH: "THA".toLowerCase(),
    TF: "ATF".toLowerCase(),
    TG: "TGO".toLowerCase(),
    TD: "TCD".toLowerCase(),
    TC: "TCA".toLowerCase(),
    LY: "LBY".toLowerCase(),
    VA: "VAT".toLowerCase(),
    VC: "VCT".toLowerCase(),
    AE: "ARE".toLowerCase(),
    AD: "AND".toLowerCase(),
    AG: "ATG".toLowerCase(),
    AF: "AFG".toLowerCase(),
    AI: "AIA".toLowerCase(),
    VI: "VIR".toLowerCase(),
    IS: "ISL".toLowerCase(),
    IR: "IRN".toLowerCase(),
    AM: "ARM".toLowerCase(),
    AL: "ALB".toLowerCase(),
    AO: "AGO".toLowerCase(),
    AQ: "ATA".toLowerCase(),
    AS: "ASM".toLowerCase(),
    AR: "ARG".toLowerCase(),
    AU: "AUS".toLowerCase(),
    AT: "AUT".toLowerCase(),
    AW: "ABW".toLowerCase(),
    IN: "IND".toLowerCase(),
    AX: "ALA".toLowerCase(),
    AZ: "AZE".toLowerCase(),
    IE: "IRL".toLowerCase(),
    ID: "IDN".toLowerCase(),
    UA: "UKR".toLowerCase(),
    QA: "QAT".toLowerCase(),
    MZ: "MOZ".toLowerCase()
  };
