import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Select2Module } from 'ng2-select2';

// Components
import { BannerComponent } from './modules/shared/banner/banner.component';
import { SearchComponent } from './modules/shared/search/search.component';

import { NotificationComponent } from './modules/shared/notification/notification.component';
import { RosterRequestModalComponent } from './modules/shared/notification/roster-request-modal/roster-request-modal.component';
import { RosterInviteModalComponent } from './modules/shared/notification/roster-invite-modal/roster-invite-modal.component';

import { AddDocumentCompliancesModalComponent } from './modules/shared/modals/add-document-compliances-modal/add-document-compliances-modal.component';

import { MembersComponent } from './modules/shared/members/members.component';
import { CardComponent } from './modules/shared/members/card/card.component';
import { ListComponent } from './modules/shared/members/list/list.component';
import { MapComponent } from './modules/shared/members/map/map.component';
import { SpinnerComponent } from './modules/shared/spinner/spinner.component';
// Pipes
import { TextSnippedPipe } from '../pipes/text-snipped.pipe';
import { NiceDatePipe } from '../pipes/nice-date.pipe';
import { NiceDateDetailedPipe } from '../pipes/nice-date-detailed.pipe';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { SafeResourceUrlPipe } from '../pipes/safe-url-resource';
import { SearchListPipe } from '../pipes/search-list.pipe';


import { Uploader } from 'angular2-http-file-upload';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/primeng';
import { InputTextModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/primeng';
import { TagsModalComponent } from './modules/shared/modals/tags-modal/tags-modal.component';
import { AlertModalConfirmationComponent } from './modules/shared/modals/alert-modal-confirmation/alert-modal-confirmation.component';

import { NgxSelectModule } from 'ngx-select-ex';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CalendarModule,
    InputTextModule,
    InputTextareaModule,
    Select2Module,
    NgxSelectModule
  ],
  declarations: [
    SpinnerComponent,
    // Pipes
    TextSnippedPipe,
    NiceDatePipe,
    DateFormatPipe,
    NiceDateDetailedPipe,
    SafeResourceUrlPipe,
    SearchListPipe,
    BannerComponent,
    SearchComponent,
    RosterRequestModalComponent,
    RosterInviteModalComponent,
    AddDocumentCompliancesModalComponent,
    MembersComponent,
    NotificationComponent,
    CardComponent,
    ListComponent,
    MapComponent,
    TagsModalComponent,
    AlertModalConfirmationComponent,
  ],
  exports: [
    // Components
    BannerComponent,
    SearchComponent,
    MembersComponent,
    NotificationComponent,
    SpinnerComponent,
    RosterRequestModalComponent,
    RosterInviteModalComponent,
    AddDocumentCompliancesModalComponent,
    CardComponent,
    ListComponent,
    MapComponent,
    AlertModalConfirmationComponent,
    // Modules
    FormsModule,
    CalendarModule,
    InputTextModule,
    InputTextareaModule,
    // Pipes
    TextSnippedPipe,
    NiceDatePipe,
    DateFormatPipe,
    NiceDateDetailedPipe,
    SafeResourceUrlPipe,
    SearchListPipe,
    // Spinner
    SpinnerComponent,
  ],
  providers: [
    Uploader,
    DatePipe,
  ],
  entryComponents: [
    RosterRequestModalComponent,
    RosterInviteModalComponent,
    AddDocumentCompliancesModalComponent,
    TagsModalComponent,
    AlertModalConfirmationComponent,
  ]
})


export class SharedModule { }
