<div id="alerts-informations-body">
  <div class="alerts-informations-header">
    <h2 class="alerts-title">{{alertInfo?.name}}</h2>
    <div class="icon-header">
      <div *ngIf="isOrganisationAssociate" class="edit-button" (click)="handleClickEdit()"><img class="custom-edit-icon" src="../../../../../../assets/images/edit.png" /></div>
      <i title="You selected the alert {{alertInfo?.name}}, The information about this event are displayed. You can edit the event."
          class="fa fa-question-circle-o help-icon" aria-hidden="true">
    </i>
    </div>
  </div>
  <div class="alerts-informations-content">
    <div class="alerts-informations-main">
      <div class="alerts-informations-country-picture">
        <img class="alerts-country" src="{{imgUrl}}{{iso3[alertInfo?.country]}}.png" (error)="errorHandler($event)"/>
      </div>
      <div class="alerts-info">
        <h3 class="alerts-informations-date">{{alertInfo?.start_date | date: "dd MMMM y"}} - {{alertInfo?.end_date | date: "dd MMMM y"}}</h3>
        <h3 class="alerts-informations-country">{{alertInfo?.country_name}}</h3>
      </div>
    </div>
    <div class="alerts-informations-second">
      <div class="container-h3"> 
        <h3>Event owner:</h3>
      </div>
      <div class="container-alerts-informations-contact">
        <p class="alerts-informations-contact"> {{alertInfo?.contact_name}} ({{alertInfo?.contact_email}})</p>
      </div>
      <div class="container-button-link">
        <a *ngIf="alertInfo?.infoLink"class="btn emalsys_button blue" href="{{alertInfo?.infoLink}}" target="_blank"><img class="alerts-info-link" src="assets/icons/https.png"/></a>
      </div>
      <!-- <a *ngIf="alertInfo?.infoLink"class="btn emalsys_button blue" href="{{alertInfo?.infoLink}}" target="_blank">See on <img class="alerts-info-link" src="assets/images/relief-web.png"/></a> -->
    </div>
    <p class="alerts-informations-description">{{alertInfo?.description}}</p>
  </div>
</div>
