import { DashboardManagerPageService } from './../../../../services/page/dashboard.manager.pager';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { BannerUpdater } from './banner-updater.dispatcher';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// Services
import { OrganisationApiService } from '../../../../services/api/organisation/organisation.api';
import { ProfileService } from '../../../../services/api/profile/profile.api';
import { CacheService } from '../../../../services/storage/cache.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { EventListenerService } from '../../../../services/utils/event-listener.service';
import { NotificationService } from '../../../../services/api/notification.service';
import { NotificationComponent } from '../notification/notification.component';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../../services/security/login.service';
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { UserTagInterface } from '../../../model/user-tag.interface';
import { UserInterface } from '../../../model/user.interface';
import { RightService } from '../../../../services/utils/right.service';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../app.constants';
import { delay, tap, take } from 'rxjs/operators';
import { combineLatest ,  forkJoin } from 'rxjs';
import { OrganisationInterface } from '../../../model/organisation.interface';
import { CachedOrganisationService } from '../../../../services/api/organisation/organisation.cacher';
import { GlobalPageService } from '../../../../services/page/global.pager';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    public VERSION = '';

    /**
    * WARNING
    * The user into the cache holds additional informations
    * gotten from a huuuge function into the login service.
    *
    * For further informations open login.service.ts or look into the
    * local storage of browser
    */
    // got from cache
    public user: any;
    // fetched from dispatcher
    public userProfile: any;
    public organisation: any;
    public organisations: any;
    public profileOpen: boolean;
    public isManagerWebsite: any;
    public status: string;


    public notificationList;
    public notificationTypeCount;
    public notificationCount = 0;
    public notificationOpen: boolean;


    public intervalId;


    public isOrganisationManager = false;
    public isOrganisationAdmin = false;
    public isOrganisationSuperAdmin = false;

    public bannerVisibility = true;

    constructor(
        private router: Router,
        private _modalService: NgbModal,
        public _loginService: LoginService,
        public _organisationService: OrganisationApiService,
        public _organisationsDispatcherService: OrganisationDispatcherService,
        public _cachedOrganisationService: CachedOrganisationService,
        public _profileService: ProfileService,
        public _notificationService: NotificationService,
        public _navigationService: NavigationService,
        public _cacheService: CacheService,
        private _loggerService: LoggerService,
        private _eventListenerService: EventListenerService,
        private _profileDispatcher: ProfileDispatcherService,
        private _rightService: RightService,
        private bannerUpdater: BannerUpdater,
        private _globalPage: GlobalPageService,
        private _dashboardPage: DashboardManagerPageService
    ) {
        this.bannerUpdater.getVisibility()
            .pipe(delay(0))
            .subscribe(visibililty => this.bannerVisibility = visibililty);
        this.bannerUpdater.isMenuOpen()
            .subscribe(
                isOpen => {
                    this.profileOpen = isOpen;
                }
            );
    }

    ngOnInit() {
        this.VERSION = environment.version;
        this.user = this._cacheService.get(CacheService.USER);
        this.organisations = [];
        this.profileOpen = false;
        this.notificationOpen = false;
        this.isManagerWebsite = this._cacheService.get(CacheService.ISMANAGER_WEBSITE);
        if (this.isManagerWebsite) {
            this.status = "manager";
        } else {
            this.status = "responder";
        }
        this._eventListenerService.event$.subscribe(event => {
            if (event.hasOwnProperty('user')) {
                this.getUserPicture();
            }
        });

        const organisation$ = this._organisationsDispatcherService
            .getSelectedOrganisation()
            .pipe(
                tap((organisation: OrganisationInterface) => {
                    this.organisation = organisation;
                })
            );
        const profile$ = this._profileDispatcher
            .getProfile()
            .pipe(
                take(1),
                tap((profile: UserInterface) => {
                    this.userProfile = profile;
                    // this.intervalId = setInterval(() => { this.getNotifications(); }, 10000);
                })
            );

        // TODO: check permissions from profile object from dispatcher and NOT from cache.
        // (see isManager for details)
        combineLatest(
            organisation$,
            profile$
        ).subscribe(
            (res) => {
                this.isOrganisationAdmin = this.isAdmin();
                this.isOrganisationManager = this.isManager(this.organisation.id);
                this.isOrganisationSuperAdmin = this.checkOrganisationSuperAdmin();
                this.user = this._cacheService.get(CacheService.USER);
            }
        );

        if (this.user && !this.user.picture) {
            this.getUserPicture();
        }

        // if user read from the cache is not null
        if (this.user) {
            this._profileService.getProfile().subscribe();
            this._cachedOrganisationService.getUserOrganisations()
                .subscribe(organisations => {
                    this.organisations = organisations;
                });
            // this.intervalId = setInterval(() => { this.getNotifications(); }, 10000);
        }
    }

    checkOrganisationSuperAdmin() {
        return this._rightService.isOrganisationManagerRight(this.userProfile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin);
    }

    isAdmin() {
        let user = this._cacheService.get(CacheService.USER);
        if (user) {
            let voters = user.voters;
            if (voters && voters.module_admin_access) {
                return true;
            } else {
                return false;
            }

        }
        return false;
    }
    /**
    * Returns true if the user is a manager in SOMETHING
    * into his organisation (eveni if he's only a roster manager)
    *
    * @param organisationId organisation Id
    */
    isManager(organisationId) {
        let organisation_managers = [];
        let user = this._cacheService.get(CacheService.USER);
        if (user) {
            let organisationManager = user.organisation_managers.find(x => x.organisation.id === organisationId);
            let rosterManager = user.roster_managers.find(x => x.roster.organisation.id === organisationId);

            return (organisationManager != null) || (rosterManager != null);
        }
        return false;
    }

    getUserPicture() {
        this._profileService.getProfileIdentity().subscribe(res => {
            this.user.picture = res.picture;
            this._cacheService.set(CacheService.USER, this.user);
        });
    }

    switchOrganisation(organisation: OrganisationInterface) {
        this._globalPage.changeOrganisation(organisation);
        if (this.isManager(this.organisation.id)) {
            this._navigationService.goToOrganisation(this.organisation.id, this.status);
        } else {
            this.isManagerWebsite = 0;
            this._cacheService.set(CacheService.ISMANAGER_WEBSITE, this.isManagerWebsite);
            this.status = "responder";
            this._navigationService.goToOrganisation(this.organisation.id, this.status);
        }
    }

    logout() {
        clearInterval(this.intervalId);
        this._loginService.logout();
    }

    openProfile(option = true) {
        if (option === false) {
            this.profileOpen = false;
        } else {
            this.profileOpen = !this.profileOpen;
        }
    }

    openNotification(option = true) {
        if (option === false) {
            this.notificationOpen = false;
        } else {
            this.notificationOpen = !this.notificationOpen;
        }
    }

    switchToManagerWebsite() {
        // Also used in routing guard service, if user types manager url
        this.isManagerWebsite = 1;
        this._cacheService.set(CacheService.ISMANAGER_WEBSITE, this.isManagerWebsite);
        this.status = "manager";
        this._navigationService.goToManagerWebsite(this.organisation.id);
    }

    switchOrganisations(content) {
        this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
    }

    switchToMemberWebsite() {
        // Also used in routing guard service, if user types manager url
        this.isManagerWebsite = 0;
        this._cacheService.set(CacheService.ISMANAGER_WEBSITE, this.isManagerWebsite);
        this.status = "responder";
        this._navigationService.goToMemberWebsite(this.organisation.id);
    }

    getNotifications() {
        this._notificationService.getNotifications().subscribe(res => {
            this.notificationList = res;
            this.countNotifications();
        });
    }

    countNotifications() {
        this.notificationCount = 0;
        this.notificationTypeCount = [];
        for (let i = 0; i < this.notificationList.length; i++) {
            this.notificationTypeCount.push(this.notificationList[i].length);
        }
        for (let j = 0; j < this.notificationTypeCount.length; j++) {
            this.notificationCount += this.notificationTypeCount[j];
        }
    }

    closeNotifications(event) {
        this.notificationOpen = event;
    }

    onAnswerAlert(event) {
        this.getNotifications();
    }

    onAnswerOrg(event) {
        this._loggerService.error('[bannerComponent] onAnswerOrg : organisations deleted !');
        this._cachedOrganisationService.refreshUserOrganisations();
        this.getNotifications();
    }

    checkRouteProfile() {
        let regExp = new RegExp("\/responder\/profile");
        return regExp.test(this.router.url);
    }

    checkRouteSettings() {
        let regExp = new RegExp("\/responder\/settings");
        return regExp.test(this.router.url);
    }

    goHomePage() {
        this._navigationService.goToPublic();
    }
}
