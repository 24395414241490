import { CachedOrganisationService } from '../api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../api/organisation/organisation.dispatcher';
import { ROUTING, ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../app/app.constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { RightService } from '../utils/right.service';
import { CacheService } from '../storage/cache.service';
import { NavigationService } from '../utils/navigation.service';


@Injectable()
export class OrganisationGuardService implements CanActivate {

    readonly rightMap = {
        'settings': ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin
    };

    private user;

    constructor(
        private _rightService: RightService,
        private _cacheService: CacheService,
        private _navigationService: NavigationService,
        private _cachedOrganisationService: CachedOrganisationService,
        private _organisationDispatcher: OrganisationDispatcherService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let organisationId: number = null, userId: number = null;

        // REFACTOR: understand what kind of interface this is
        this.user = this._cacheService.get(CacheService.USER);

        // check if the current user is manager if the current organisation
        if (next.paramMap.has(ROUTING.parameter.organisationId)) {
            organisationId = Number(next.paramMap.get(ROUTING.parameter.organisationId));
            // this._organisationDispatcher.selectOrganisation(organisationId);
            this._cachedOrganisationService.refreshCurrentOrganisation(organisationId);
            return true;
        }
        return false;
    }


}
