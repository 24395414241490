import { MembersDispatcherService } from './../../../../../services/api/member/members.dispatcher';
import { UserInterface } from './../../../../model/user.interface';
import { CachedOrganisationService } from './../../../../../services/api/organisation/organisation.cacher';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsService } from '../../../../../services/api/tag/tag.api';
import { ToastService } from '../../../../../services/utils/toast.service';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { TagDispatcherService } from '../../../../../services/api/tag/tag.dispatcher';
import { TagInterface } from '../../../../model/tag.interface';
import { OrganisationDispatcherService } from '../../../../../services/api/organisation/organisation.dispatcher';
import { switchMap } from 'rxjs/operators';
import { OrganisationInterface } from '../../../../model/organisation.interface';

@Component({
  selector: 'app-tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss']
})
export class TagsModalComponent implements OnInit {

  @ViewChild('newTagInput') newTagInput: ElementRef;

  public tags: any[];
  public rosterId: number;

  // If true, manage roster_member_tag
  // If false, manage just tags
  public membersMode: boolean;

  public selectedMembersIds: Array<number>;
  public addingMode = false;

  public selectedTags = [];
  public newTagName = "";

  public loading = false;

  constructor(
    public _activeModal: NgbActiveModal,
    private _toasts: ToastService,
    private _tagService: TagsService,
    private _tagDispatcher: TagDispatcherService,
    private _organisationCacher: CachedOrganisationService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _memberDispatcher: MembersDispatcherService
  ) { }

  ngOnInit() {
    // this.tags.reverse();
    this._tagDispatcher.getRosterTags()
    .subscribe(
      (tags: TagInterface[]) => {
        this.tags = tags;
        // this.tags.reverse();
      }
    );
    this._tagService.getAllRosterTags(this.rosterId).subscribe();
  }


  // addMode() {
  //   this.addingMode = true;
  //   setTimeout(() => this.newTagInput.nativeElement.focus(), 10);
  // }

  addNewTag() {
    // No empty tag
    if (this.newTagName === "") { return; }
    // Add the tag remotely
    /**
     * TODO? make addNewTagToRoster return the tag itself?
     */
    this.loading = true;
    this._tagService.addNewTagToRoster(this.rosterId, this.newTagName).subscribe(() => {
      this._toasts.show("tags-added", "show", 3000);
      this.loading = false;
    });
  }

  tagSelected(tag) {
    tag.selected = !tag.selected;
  }




  /**
   * @deprecated
   */
  removeSelectedTags() {
    let selected = this.tags.filter(x => x.selected);
    if (selected.length === 0) {
      this._toasts.show('no-tag-selected', 'show');
      return;
    }
    this.loading = true;

    let count = selected.length;

    this.tags
      .filter(x => x.selected)
      .forEach(x => {
        this._tagService.deleteTagRoster(x.id, this.rosterId).subscribe(
          () => {
            this.removeFromTagList(x.id);
            if (!(--count)) {
              this.loading = false;
            }
            this._toasts.show('tags-removed', 'show');
          }
        );
      });
  }

  refreshRosterMembers() {
    const organisation = this._organisationDispatcher.getSelectedOrganisationScalar();
    // this._organisationCacher.refreshOrganisationMembers(organisation.id, {roster: this.rosterId});
    this._organisationCacher.getOrganisationMembers(organisation.id, {roster: this.rosterId}).subscribe(
      (res: UserInterface[]) => this._memberDispatcher.publishMembers(res)
    );
  }

  applyTags() {
    let tagIdList = this.tags.filter(x => x.selected).map(x => x.id);
    this.loading = true;

    // Looking at the code in members.component ( applyTags() and removeTags() )
    // Users' tags are strings
    let stringMemberIds = this.selectedMembersIds.map(x => String(x));
    this._tagService.addRosterMembersTags(this.rosterId, stringMemberIds, tagIdList).subscribe(
      () => {
        this.loading = false;
        this._activeModal.close();
        this.refreshRosterMembers();
      }
    );
  }

  disapplyTags() {
    let tagIdList = this.tags.filter(x => x.selected).map(x => x.id);
    let stringMemberIds = this.selectedMembersIds.map(x => String(x));

    this.loading = true;
    this._tagService.removeRosterMembersTags(this.rosterId, stringMemberIds, tagIdList).subscribe(
      () => {
        this.loading = false;
        this.refreshRosterMembers();
        this._toasts.show('member-tags-removed', 'show');
      }
    );

  }

  private removeFromTagList(id: number) {
    let tag = this.tags.findIndex(x => x.id === id);
    if (tag !== -1) {
      this.tags.splice(tag, 1);
    }
  }

  public deleteSingleTag(id) {
    this.loading = true;
    this._tagService.deleteTagRoster(id, this.rosterId).toPromise().then(
      () => {
        this.loading = false;
        this.removeFromTagList(id);
        this._toasts.show("tags-removed", "show");
      }
    ).catch(
      () => {
        this.loading = false;
        this._toasts.show("tag-removal-failed", "show");
      }
    );
  }

}
