import { OrganisationDispatcherService } from './../../../organisation/organisation.dispatcher';
import { CachedOrganisationService } from './../../../organisation/organisation.cacher';
import { OrganisationInterface } from './../../../../../app/model/organisation.interface';
import { MembersDispatcherService } from './../../../member/members.dispatcher';
import { UserInterface } from './../../../../../app/model/user.interface';
import { RosterInterface } from './../../../../../app/model/roster.interface';
import { ApiCacherService } from './../../../../utils/api-cacher.service';
import { URL_API_EMALSYS } from './../../../../../environments/environment';
import { HttpService } from './../../../http.service';
import { HttpClient } from '@angular/common/http';
import { tap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
@Injectable()
export class RosterMembershipService {


    constructor(
        private http: HttpClient,
        private _httpService: HttpService,
        private _apiCacher: ApiCacherService,
        private _memberDispatcher: MembersDispatcherService,
        private _cachedOrganisationService: CachedOrganisationService,
        private _organisationDispatcher: OrganisationDispatcherService,



    ) { }

    getRosterMembers(rosterId) {

        return this._apiCacher.doWrapper<UserInterface[]>(
            RosterInterface.CACHE.ROSTER_MEMBERS + rosterId,
            () => {
                let options = this._httpService.createRequestOptions();
                return this.http.get<UserInterface[]>(URL_API_EMALSYS + "/rosters/" + rosterId + "?filter=members", options);

            }
        )
        // .pipe(
        //     tap((res: UserInterface[]) => this._memberDispatcher.publish(res))
        // );
        ;
    }

    getRosterMembersByFilter(rosterId, filters) {
        return this._apiCacher.doWrapper<UserInterface[]>(
            RosterInterface.CACHE.ROSTER_MEMBERS + rosterId,
            () => {
                let options = this._httpService.createRequestOptions();

                let url = URL_API_EMALSYS + "/rosters/" + rosterId + "/members";
                for (let key in filters) {
                    if (filters.hasOwnProperty(key) && filters[key] !== "" && filters[key] !== null) {
                        url = url + (/\?/.test(url) ? "&" : "?") + key + "=" + filters[key];
                    }
                }
                return this.http.get<UserInterface[]>(url, options);
            }
        ).pipe(
            tap((res: UserInterface[]) => this._memberDispatcher.publish(res))
        );
    }

    refreshRosterMembers(rosterId: number) {
        this._apiCacher.removeIfPresent(RosterInterface.CACHE.ROSTER_MEMBERS + rosterId);
        this.getRosterMembers(rosterId).subscribe();
    }

    removeRosterMember(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/remove/member?user_id=" + userId, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }

    // TODO: unsubscribe
    refreshRosters() {
        return this._organisationDispatcher.getSelectedOrganisation()
            .pipe(
                switchMap((x) => {
                    this._apiCacher.removeIfPresent(OrganisationInterface.CACHE.ORGANISATION_ROSTERS + x.id);
                    return this._cachedOrganisationService.getOrganisationRosters(x.id);
                })
            )
            .subscribe();

    }


    removeRosterManager(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/remove/manager?user_id=" + userId, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }


    quitRoster(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/quit", options)
            .pipe(
                tap(() => this.refreshRosters())
            );
    }

    setActiveRosterManager(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/promote?user_id=" + userId, {}, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }

    setGuestRightsToRosterManager(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/rights/guest?user_id=" + userId, {}, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }
    setManagerRightsToRosterManager(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/rights/manager?user_id=" + userId, {}, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }
    setSupervisorRightsToRosterManager(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/rights/supervisor?user_id=" + userId, {}, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }


    // -----------------------------Join request-------------------------//

    // Refresh roster list
    joinRoster(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + "/join", {}, options);
    }

    acceptRosterMemberRequest(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/request/members/accept?user_id=" + userId, {}, options)
            .pipe(
                tap(() => this.refreshRosterMembers(rosterId))
            );
    }

    declineRosterMemberRequest(rosterId, userId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/request/members/refuse?user_id=" + userId, options);
    }

    cancelPendingRequest(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/request/members/cancel", options);
    }

    // -----------------------------Roster member invitation-------------------------//

    inviteMembers(rosterId, membersArray) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/members", membersArray, options);
    }

    // Refresh roster list?
    acceptRosterMemberInvitation(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/members/accept", {}, options)
            .pipe(
                tap(() => this.refreshRosters())
            );
    }

    declineRosterMemberInvitation(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/members/refuse", options);
    }




    // -----------------------------Roster manager invitation-------------------------//
    inviteManagers(rosterId, managersArray) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/managers", managersArray, options);
    }

    acceptRosterManagerInvitation(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/managers/accept", {}, options);
    }

    declineRosterManagerInvitation(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/invite/managers/refuse", options);
    }

}
