import { Component } from '@angular/core';

@Component({
  selector: 'privacy-page',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})

export class PrivacyComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
