<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="context=='edit'">Edit Event</h4>
  <h4 class="modal-title" *ngIf="context=='create'">Create Event</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="form-group">
    <label for="AlertName">Event name</label>
    <input id="AlertName" class="form-control" [(ngModel)]="alert.name" maxlength="20" />
  </div>
  <div class="form-group">
    <label for="Country">Country</label>
        <select2 id="Country" class="select" cssImport="true" [data]="countries" [options]="{multiple: false}" [value]="alert.country ? alert.country : '' " (valueChanged)="changedSelectCountry($event)">
        </select2>
  </div>
  <div class="form-group">
    <label for="StartDate">Start date</label>
    <div class="form-group">
      <p-calendar id="StartDate" [maxDate]="alert.endDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [(ngModel)]="alert.startDate" [showIcon]="true" [utc]="true"></p-calendar>
    </div>
  </div>
  <div class="form-group">
    <label for="EndDate">End date</label>
    <div class="form-group">
      <p-calendar id="EndDate" [minDate]="alert.startDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [(ngModel)]="alert.endDate" [showIcon]="true" [utc]="true"></p-calendar>
    </div>
  </div>
  <button class="more-button" (click)="moreOption=!moreOption;">
        <p *ngIf="!moreOption"><i class="fa fa-plus custom-add-icon"></i> More options</p>
        <p *ngIf="moreOption"><i class="fa fa-minus custom-add-icon"></i> Less options</p>
    </button>

  <div *ngIf="moreOption">
    <div class="form-group">
      <label for="SecondaryCountries">Secondary countries</label>
      <select2 id="SecondaryCountries" class="select" cssImport="true" [data]="countries" [options]="{multiple: true}" [value]="alert.secondaryCountries" (valueChanged)="changedSelectSecondaryCountries($event)">
      </select2>
    </div>
    <div class="form-group">
      <label for="Description">Description</label>
      <textarea id="Description" class="form-control" [rows]="3" pInputTextarea autoResize="true" [(ngModel)]="alert.description"></textarea>
    </div>
    <div class="form-group">
      <label for="Link">Information link</label>
      <input id="Link" class="form-control" [(ngModel)]="alert.infoLink" placeholder="http://" />
    </div>
  </div>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button [disabled]="loading" class="btn emalsys_button red margin-b margin-r" (click)=" deleteAlert()" *ngIf="context=='edit'"><i class="fa fa-trash" aria-hidden="true"></i>
 Delete</button>
  <button [disabled]="loading" class="btn emalsys_button green margin-b margin-r" (click)=" updateAlert()" *ngIf="context=='edit'"><i class="fa fa-pencil" aria-hidden="true"></i>
Save</button>
  <button [disabled]="checkInputs(alert) || loading" [ngClass]="{'disabled': checkInputs(alert) || loading}" class="btn emalsys_button green margin-b margin-r" (click)="createAlert()" *ngIf="context=='create'">Create</button>
</div>

<!--Toasts-->
<div id="descriptionTooShortToast">The description must be at least 2 characters long.</div>
