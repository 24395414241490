
<div #chart class="indicatorChart shadow" >
  <pie-chart style="width: 100%" *ngIf="indicator.type_graphique == 'camembert'" 
    [view]="chartDimensions" 
    [filters]="filters"
    [title]="indicator.full_name"
    [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Valeur' }"
    [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }"
    [selectedEvent]="selectedEvent"
    [selectedRoster]="selectedRoster"
    [frequence]="frequence"
    >
  </pie-chart>

  <pie-grid style="width: 100%" *ngIf="indicator.type_graphique == 'cible'" 
    [view]="chartDimensions" 
    [filters]="filters"
    [title]="indicator.full_name"
    [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Valeur' }"
    [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }" 
    [selectedEvent]="selectedEvent"
    [selectedRoster]="selectedRoster"
    [frequence]="frequence">
  </pie-grid>

  <bar-chart style="width: 100%" *ngIf="indicator.type_graphique == 'bar'" 
    [view]="chartDimensions"
    [filters]="filters" 
    [title]="indicator.full_name" 
    [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Valeur' }"
    [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }"
    [selectedEvent]="selectedEvent"
    [selectedRoster]="selectedRoster"
    [frequence]="frequence" >
  </bar-chart>

  <line-chart style="width: 100%" *ngIf="indicator.type_graphique == 'courbe'" 
    [view]="chartDimensions" 
    [filters]="filters"
    [title]="indicator.full_name" 
    [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Valeur' }"
    [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }"
    [selectedEvent]="selectedEvent"
    [selectedRoster]="selectedRoster"
    [frequence]="frequence">
  </line-chart>
  
  <number-card-chart style="width: 100%" *ngIf="indicator.type_graphique == 'nombre'" 
    [view]="chartDimensions" 
    [filters]="filters"
    [title]="indicator.full_name" 
    [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Nombre' }"
    [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }"
    [selectedEvent]="selectedEvent"
    [selectedRoster]="selectedRoster"
    [frequence]="frequence" >
  </number-card-chart>

  <stacked-vertical-bar-chart style="width: 100%" *ngIf="(indicator.type_graphique == 'histogramme')||(indicator.type_graphique == '')" 
  [view]="chartDimensions"
  [filters]="filters" 
  [title]="indicator.full_name" 
  [axis]="{ showXAxis: true, showYAxis: true, showXAxisLabel: true, showYAxisLabel: true, xAxisLabel: xAxisLabel, yAxisLabel: 'Valeur' }"
  [indicatorConfig]="{ idIndicator: indicator.id, type: indicator.type_graphique, computedMethod: computedMethod }"
  [selectedEvent]="selectedEvent"
  [selectedRoster]="selectedRoster"
  [frequence]="frequence" >
</stacked-vertical-bar-chart>
</div>