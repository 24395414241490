import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preloader-profile-languages',
  templateUrl: './preloader-profile-languages.component.html',
  styleUrls: ['./preloader-profile-languages.component.scss']
})
export class PreloaderProfileLanguagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
