import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

@Injectable()
export class DeploymentService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  createOrder(body) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/deployments", body, options);
  }

  acknowledgeDeploymentOrder(deploymentId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/deploymentorders/" + deploymentId + "/acknowledge", {}, options);
  }

  getDeploymentByEvent(idAlert) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<any>(URL_API_EMALSYS + "/deployments?method=by_event&id=" + idAlert, options);
  }

  getDeploymentCountByEvent(idAlert) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<number>(URL_API_EMALSYS + "/deployments?filter=count&id=" + idAlert, options);
  }

  getDeploymentRequirementByEvent(idAlert) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<number>(URL_API_EMALSYS + "/deployments?method=by_event_requirement&id=" + idAlert, options);
  }

  getDeployments() {
    let options = this._httpService.createRequestOptions();

    return this.http.get<any>(URL_API_EMALSYS + "/me/deployments", options);
  }

  getDeploymentOrdersByUsers(usersId) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/deployments/users?users_id=" + usersId, options);
  }

  // External Deployment

  addExternalDeployment(deployment) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/deployments/external", deployment, options);
  }

  // Get logged user's external deployments
  getExternalDeployments() {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/deployments/external", options);
  }
  // Get someone else's external deployments (manager only)
  getExternalDeploymentsOfUser(userId: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/deployments/external/" + userId, options);
  }

  editExternalDeployment(deploymentId, deployment) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/deployments/external/" + deploymentId, deployment, options);
  }

  deleteExternalDeployment(deploymentId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/deployments/external/" + deploymentId, options);
  }

}
