import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService                                      } from '../../../../../services/api/alert/alert.api';
import { LoggerService                                     } from '../../../../../services/utils/logger.service';
import { UserEventAnswerService                            } from '../../../../../services/api/usereventanswer.service';
import { DeploymentOrderService                            } from '../../../../../services/api/deployment-order.service';
import { DeploymentService                                 } from '../../../../../services/api/deployment.service';
import { CacheService                                      } from '../../../../../services/storage/cache.service';
import { Selection                                         } from '../../../../../data/selection.class';
import { SelectionsArray                                   } from '../../../../../data/selectionArray.class';
import { Title                                             } from '@angular/platform-browser';
import { dematerialize } from 'rxjs/operators';
@Component({
  selector: 'responder-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
  @ViewChild("offer") offer;
  @ViewChild("order") order;
  alertInfo: any;
  idAlert: number;
  nbrOffers: number;
  nbrOrders: number;
  nbrActiveOrders: number;
  calendarB: boolean;
  offerB: boolean;
  orderB: boolean;
  invited = true;

  SELECTIONS: SelectionsArray<Selection>;
  deploymentOffers: any = [];
  deploymentOrders: any = [];
  eventRequirement: any = [];

  constructor(
    private titleService: Title ,
    private _cacheService: CacheService ,
    private _deploymentOrderService: DeploymentOrderService,
    private _deploymentService: DeploymentService,
    private _loggerService: LoggerService,
    private _alertEmalsysService: AlertService,
    private _userEventAnswerService: UserEventAnswerService,
    private route: ActivatedRoute
  ) {

    this.route.paramMap.subscribe(params => {
      this.idAlert = +params.get('id');
      this.SELECTIONS = SelectionsArray.create<Selection>();
      this.getAlertDetails();
      this.getSelection(this.idAlert);
    });

  }


  getAlertDetails() {
    this._alertEmalsysService.getAlertDetails(this.idAlert)
      .subscribe(res => {
        this.alertInfo = res;
        this._loggerService.log("ALERT INFOS : ", this.alertInfo);
        this.titleService.setTitle( this._cacheService.get(CacheService.CURRENT_ORGANISATION).name + " - " + this.alertInfo.name );
        this.getDeploymentOffersByEvent(this.idAlert);

      });
  }
  getSelection(idAlert) {
    this._userEventAnswerService.getAvailability(idAlert).subscribe((res) => {
      this.SELECTIONS = res[0].user_event_answers.map(answer => {
        let a = new Date(answer.start_date);
        a.setDate(a.getDate() + 1);
        let b = new Date(answer.end_date);
        b.setDate(b.getDate() + 1);
        return new Selection(a, b, answer.status);
      });
    },
      error => {
      this.invited = false;
      });
  }
  /**
  *Get the list of deployment offers
  */
  getDeploymentOffersByEvent(idAlert) {
    this._deploymentOrderService.getDeploymentOffersByEvent(idAlert).subscribe(
      res => {
        this.deploymentOffers = res;
        this.nbrOffers = this.deploymentOffers.length;
        this._loggerService.log("OFFERS : ", this.deploymentOffers);
        this._loggerService.log("ALERT : ", idAlert);
        this.getDeploymentByEvent(this.idAlert);
        this.setAutoComponent(this.nbrOrders, this.nbrOffers);
      },
      error => {
        this._loggerService.log("OFFERS : ", this.deploymentOffers);
          this.setAutoComponent(this.nbrOrders, this.nbrOffers);
      }
    );
  }

  getDeploymentByEvent(idAlert) {
    const today = new Date();

    this._deploymentService.getDeploymentByEvent(idAlert).subscribe(res => {
      this.deploymentOrders = res;
      this.nbrOrders = this.deploymentOrders.length;
      this.nbrActiveOrders = 0;
      this.deploymentOrders.forEach(order => {
        if (new Date(order.end_date) > today) {

          this.nbrActiveOrders++;
        }

      });
      this._loggerService.log("ORDERS : ", this.deploymentOrders);
      this.setAutoComponent(this.nbrOrders, this.nbrOffers);
    },
    error => {
      this._loggerService.log("ORDERS : ", this.deploymentOrders);
    }
  );
  }

  setAutoComponent(nbrOrders, nbrOffers) {
    if (nbrOrders) {
      this.calendarB = false;
      this.offerB = false;
      this.orderB = true;
    } else if (nbrOffers) {
      this.calendarB = false;
      this.offerB = true;
      this.orderB = false;
    } else {
      this.calendarB = true;
      this.offerB = false;
      this.orderB = false;
    }
  }

  setComponent(e) {
    this.calendarB = e[0];
    this.offerB = e[1];
    this.orderB = e[2];
  }

  onValidate(e) {
    this.getDeploymentOffersByEvent(this.idAlert);
  }

  onRefuse(e) {
    this.getDeploymentOffersByEvent(this.idAlert);
  }


}
