import { AfterViewInit, Component, DoCheck, KeyValueChangeRecord } from '@angular/core';
import { KeyValueDiffer, KeyValueDiffers, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { CacheService } from '../../../../services/storage/cache.service';
import { RightService } from '../../../../services/utils/right.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../app.constants';
import { ButtonFilterComponent, ButtonFilterData } from '../filters/button-filter/button-filter.component';
import { SelectorFilterData } from '../filters/selector-filter/selector-filter.component';
import { AbstractFilter, FilterEvent, FilterInterface } from '../model/filter';
import { ChartRegistration, RegisteredItem } from '../services/chart-registration.service';
import { FilterService } from '../services/filter.service';
import { IndicatorService } from '../services/indicator.service';
@Component({
  selector: 'app-indicator-page',
  templateUrl: './indicator-page.component.html',
  styleUrls: ['./indicator-page.component.scss']
})
export class IndicatorPageComponent implements OnInit, DoCheck, AfterViewInit {

  @ViewChild('chart') chartDiv;
  @ViewChild('selectorEvent') selectorEvent;
  @ViewChild('selectorRoster') selectorRoster;
  @ViewChildren(ButtonFilterComponent) buttonFilters: QueryList<ButtonFilterComponent>;
  private _differ: KeyValueDiffer<any, any>;
  public type = "Organisation";
  public frequence = "month";
  public filters: Map<string, FilterInterface> = new Map<string, FilterInterface>();
  public body: any = [];
  public indicators: any[] = [];
  public filteredIndicators: any[] = [];
  public filtersButton;
  public selectorItems = [];
  public allRosters = [];
  public allEvents = [];
  public organisation;
  public profile;
  public lastUpdateDate;
  public eventId;
  public rosterId;

  public amIOrganisationManager = false;

  // Data Button Declaration
  public dataFilterFrequence: Array<ButtonFilterData> = [
    { label: 'By month', value: 'month', active: true },
    { label: 'By quarter', value: 'quarter', active: false },
    { label: 'By year', value: 'year', active: false },
  ];

  public dataFilterType: Array<ButtonFilterData> = [
    { label: 'Organisation', value: 'Organisation', active: true },
    { label: 'Per roster', value: 'Roster', active: false },
    { label: 'Per event', value: 'Event', active: false },
  ];

  public rosterFilter: SelectorFilterData =
    { value: 'SelectedRoster', item: null };

  public eventFilter: SelectorFilterData =
    { value: 'SelectedEvent', item: null };

  public chartDimensions: number[];
  public organisationManager = false;
  public indicatorsLoading = false;

  constructor(
    private _differs: KeyValueDiffers,
    private titleService: Title,
    private _rightService: RightService,
    public referedClassService: IndicatorService,
    public cacheService: CacheService,
    public chartRegistrationService: ChartRegistration,
    public filterService: FilterService,
    public _organisationDispatcher: OrganisationDispatcherService
  ) { }

  ngOnInit() {
    this.organisation = this._organisationDispatcher.getScalarSelectedOrganisation();
    this.titleService.setTitle(this.organisation.name + " - Reporting");
    // this.organisation = this.cacheService.get(CacheService.CURRENT_ORGANISATION);
    this.profile = this.cacheService.get(CacheService.USER);
    this.getRosters();
    this.getEvents();
    this.getLastDate();
    if (!this.checkOrganisationManager()) {
      this.dataFilterType[0]['active'] = true;
      this.type = this.rosterFilter['value'];
    }
    this._differ = this._differs.find({}).create();
    if (!this.indicatorsLoading) {
      this.indicatorsLoading = true;
      this.referedClassService.getIndicators().toPromise().then(response => {
        this.indicators = response as any;
        this.indicatorsLoading = false;
        this.filteredIndicators = this.filterIndicators(this.type);
      }).catch(e => {
        this.indicatorsLoading = false;
      });
    }

  }

  ngDoCheck() {
    if (this._differ) {
      const changeEvent = this._differ.diff(this.selectorEvent);
      if (changeEvent) {
        changeEvent.forEachItem(
          (record: KeyValueChangeRecord<any, any>) => {

            if (record.key === "selectedItem") {
              this.eventId = record.currentValue;

            }
          });
      }
      const changeRoster = this._differ.diff(this.selectorRoster);
      if (changeRoster) {
        changeRoster.forEachItem(
          (record: KeyValueChangeRecord<any, any>) => {

            if (record.key === "selectedItem") {
              this.rosterId = record.currentValue;
            }
          });
      }
    }
  }

  ngAfterViewInit() {
    if (this.chartDiv) {
      this.chartDimensions = [400, 300];
    }
    // Get button reference in the template and store it in variable
    this.filtersButton = this.buttonFilters;
  }

  /**
   * Call the function to connect a chart with its filters
   * @param e
   */
  onFilter(e: FilterEvent) {
    ChartRegistration.associations
      .forEach((item: RegisteredItem, index: number, array: RegisteredItem[]) => {
        if (item.filterId === AbstractFilter.formatFullname(e.page, e.id)) {
          const filter = this.filters.get(item.filterId);
          this.chartRegistrationService.updateAssociation(index, e.data);
        }
      });

    // Verify the type (here : roster, organisation, event) to display the good charts
    let organisation = true;
    this.dataFilterType.forEach(filter => {
      if (filter['active']) {
        this.type = filter['value'];
        if ((this.eventFilter['item'] && this.eventFilter['item'] !== -1) &&
          (this.type === this.dataFilterType[2].value || this.type === this.eventFilter['value'])) {
          this.type = this.eventFilter['value'];
        }
        if ((this.rosterFilter['item'] && this.rosterFilter['item'] !== -1) &&
          (this.type === this.dataFilterType[1].value || this.type === this.rosterFilter['value'])) {
          this.type = this.rosterFilter['value'];
        }
        organisation = false;
      }
      if (organisation) {
        this.type = 'Organisation';
      }
    });

    this.dataFilterFrequence.forEach(filter => {
      if (filter['active']) {
        this.frequence = filter['value'];
      }
    });
    this.filteredIndicators = this.filterIndicators(this.type);
  }

  /**
   * Update local variable filters with filters in parameters
   * @param filters
   */
  updateFilters(filters: FilterInterface[]): Array<FilterInterface> {
    filters.forEach((filter: FilterInterface) => {
      this.filters.set(filter.getFullname(), filter);
    });

    return filters;
  }

  /**
   * Compare indicator's filter and the filter in the page
   * to create the good association
   * @param filtersIndicator
   */
  findFilter(filtersIndicator: string[]) {
    let filters: Array<FilterInterface> = [];
    this.filtersButton._results.forEach(filterButton => {
      filtersIndicator.forEach(filter => {
        if (filter === filterButton.referenceKey) {
          filters.push(filterButton);
        }
      });
    });
    if (filters.length) {
      return this.updateFilters(filters);
    }
    return filters;
  }
  // Filter the indicators by given type
  filterIndicators(type: string) {
    let data = [];
    this.indicators.forEach(indicator => {
      if (indicator["type"] === type) {
        data.push(indicator);
      }
    });
    return data;
  }

  // Get rosters from reporting
  getRosters() {
    if (this.organisation) {
      this.filterService.getRostersForSelector(this.organisation.id).subscribe(res => {
        let listRoster = res as Array<any>;
        this.allRosters = [];
        if (this.checkOrganisationManager()) {
          this.allRosters.push(
            {
              id: -1,
              text: "All rosters"
            });
        }

        if (!this.checkOrganisationManager()) {
          return;
        }
        this.allRosters = listRoster
          .filter(roster => this.checkRosterManager(roster.id))
          .map(roster => <any>{ id: roster.id, text: roster.name });
      });
    }
  }

  // Get events from reporting
  getEvents() {
    this.filterService.getEventsForSelector(this.organisation.id).subscribe(res => {
      let listEvent = res as Array<any>;
      this.allEvents = [];
      this.allEvents.push(
        {
          id: -1,
          text: "All events"
        });
      this.allEvents = listEvent.map(roster => <any>{ id: roster.id, text: roster.name });
    });
  }
  // Get last update date
  getLastDate() {
    this.filterService.getLastDate().subscribe(res => {
      let date = res['Value'];
      this.lastUpdateDate = date;
    });
  }

  checkOrganisationManager() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["NotManager"]);
  }

  checkRosterManager(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }
}
