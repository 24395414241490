import { Component, Input, Output, KeyValueDiffer, EventEmitter, KeyValueDiffers, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { map } from 'rxjs/operators';
import { AlertDispatcherService } from '../../../../services/api/alert/alert.dispatcher';
import { DeploymentOrderService } from '../../../../services/api/deployment-order.service';
import { DeploymentService } from '../../../../services/api/deployment.service';
import { MembersDispatcherService } from '../../../../services/api/member/members.dispatcher';
import { OrganisationApiService } from '../../../../services/api/organisation/organisation.api';
import { CachedOrganisationService } from '../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { RosterMembershipService } from '../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { RostersDispatcherService } from '../../../../services/api/roster/rosters.dispatcher';
import { TagsService } from "../../../../services/api/tag/tag.api";
import { TagDispatcherService } from '../../../../services/api/tag/tag.dispatcher';
import { UserEventService } from '../../../../services/api/userevent.service';
import { UserEventAnswerService } from '../../../../services/api/usereventanswer.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { RightService } from '../../../../services/utils/right.service';
import { ToastService } from '../../../../services/utils/toast.service';
import { ListComponent } from './list/list.component';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, PAGES, ROSTER_MANAGER_RIGHTS_LEVEL, TAG_MODAL_RESULT } from '../../../app.constants';
import { OrganisationInterface } from '../../../model/organisation.interface';
import { RosterInterface } from '../../../model/roster.interface';
import { TagInterface } from '../../../model/tag.interface';
import { TagsModalComponent } from '../modals/tags-modal/tags-modal.component';
import { AvailabilitesDispatcher } from './../../../../services/api/availabilites/availabilites.dispatcher';
import { DeploymentOffersDispatcher } from './../../../../services/api/deploymentoffers/deployment-offers.dispatcher';
import { DeploymentOrdersDispatcher } from './../../../../services/api/deploymentorders/deployment-orders.dispatcher';
import { UserInterface } from './../../../model/user.interface';
import { AddAlertModalComponent } from './modals/alert/add/add-alert-modal.component';
import { AlertModalComponent } from './modals/alert/alert-modal.component';
import { InformModalComponent } from './modals/inform/inform-modal.component';
// Modal
import { AddRosterModalComponent } from './modals/roster/add-roster-modal.component';
import { SendOfferModalComponent } from './modals/send-offer/send-offer-modal.component';
import { DeploymentOrdersModalComponent } from './modals/deployment-orders/deployment-orders-modal.component';
import { TagsComponent } from './modals/tags/tags.component';
import { EventCalendarModalComponent } from './modals/event-calendar/event-calendar-modal.component';



@Component({
  selector: 'dashboard-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})

export class MembersComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  organisation: any;

  // Todo? : remove because we have eventMap now
  @Input()
  eventQuery: number = null;

  // Todo? : remove because we have eventMap now
  @Input()
  alertInfo;

  @Input()
  page: string;

  @Input()
  selected: any;

  @Input()
  members: UserInterface[] = [];
  /**
   * Forwarded from AlertManagerComponent (manager-alert tag) because we
   * need the setSelectedAlert to update the view when a new member is added
   * with the "Alert" button.
   */
  @Input() eventMap: any;

  @Output() onCatchClickCancel = new EventEmitter<any>();

  public profile;
  private _differ: KeyValueDiffer<any, any>;

  public readonly NO_ROSTER = RosterInterface.NO_ROSTER;

  public isAlertsPage: boolean;
  public isMembersPage: boolean;


  public allMembers: any;
  public deploymentOffers: any = [];
  public deploymentOrders: any = [];
  public availabilities: any = [];
  public view: string;
  public currentRoster: any;
  public tagsMode = false;
  public tagsList: any = [];
  public selectedTags: any = [];
  public searchButton = false;
  public applyButton = false;
  public removeButton = false;
  public calendar = false;

  public showAlertDeployButtons = false;
  public showAlertOfferButtons = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private _differs: KeyValueDiffers,
    private _rightService: RightService,
    private _loggerService: LoggerService,
    private _organisationService: OrganisationApiService,
    private _rosterDispatcherService: RostersDispatcherService,
    private _modalService: NgbModal,
    private _usereventanswerService: UserEventAnswerService,
    private _usereventService: UserEventService,
    private _deploymentorderService: DeploymentOrderService,
    private _deploymentService: DeploymentService,
    private _toastService: ToastService,
    private _tagsService: TagsService,
    private _tagDispatcher: TagDispatcherService,
    private _membersDispatcher: MembersDispatcherService,
    private _cachedOrganisationService: CachedOrganisationService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _profileDispatcher: ProfileDispatcherService,
    private _alertDispatcher: AlertDispatcherService,
    private _rosterMembership: RosterMembershipService,
    private _availabilitesDispatcher: AvailabilitesDispatcher,
    private _deploymentOffersDispatcher: DeploymentOffersDispatcher,
    private _deploymentOrdersDispatcher: DeploymentOrdersDispatcher
  ) { }

  ngOnInit() {
    this.isAlertsPage = this.page === 'alerts';
    this.isMembersPage = this.page === 'members';

    this._differ = this._differs.find({}).create();
    this.view = (this.isMembersPage) ? "card" : "list";
    this.currentRoster = RosterInterface.getNoRosterInstance();


    this.subscriptions.push(
      this._membersDispatcher.getMembers()
        .subscribe(
          (members: UserInterface[]) => this.members = members
        )
    );

    const currentProfile = this._profileDispatcher.getProfile().subscribe(
      (res: UserInterface) => this.profile = res
    );

    if (this.isMembersPage) {

      // Subscribe to get the tags of the current roster
      this._tagDispatcher.getRosterTags().subscribe(
        (res: TagInterface[]) => {
          this.tagsList = res;
        }
      );

      let currentOrganisation = this._organisationDispatcher.getSelectedOrganisation().subscribe(
        (res: OrganisationInterface) => {
          this._cachedOrganisationService.getOrganisationMembers(res.id, {});
        }
      );

      let selectedRoster = this._rosterDispatcherService.getSelectedRoster().subscribe(
        (roster: RosterInterface) => {
          // Clear user selection when changing roster
          this.selected = {};
          this.currentRoster = roster;
          this.showAlertDeployButtons = this.canSeeAlertButton();
        }
      );
      this.subscriptions.push(
        selectedRoster,
        currentOrganisation,
        currentProfile
      );

    } else if (this.isAlertsPage) {

      this.subscriptions.push(
        this._alertDispatcher.getSelectedAlert().subscribe(
          res => this.alertInfo = res
        )
      );

      this._availabilitesDispatcher.getData().subscribe(
        res => this.availabilities = res
      );
      this._deploymentOffersDispatcher.getData().subscribe(
        res => this.deploymentOffers = res
      );
      this._deploymentOrdersDispatcher.getData().subscribe(
        res => this.deploymentOrders = res
      );

      // Need both profile and organisation
      let profileAndOrganisation = combineLatest(
        this._profileDispatcher.getProfile(),
        this._organisationDispatcher.getSelectedOrganisation()
        // this._alertDispatcher.getSelectedAlert()
      ).pipe(
        map(
          values => {
            return {
              profile: values[0],
              organisation: values[1]
            };
          })
      ).subscribe(
        values => {
          // REFACTOR: do we need to store these things?
          this.profile = values.profile;
          this.organisation = values.organisation;
          this.showAlertDeployButtons = this.canSeeAlertButton();
          this.showAlertOfferButtons = this.canSeeAlertButtonEvent();
          // this.getUserEvents({});

        }
      );

      this.subscriptions.push(profileAndOrganisation);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isAlertsPage) {
      if (changes.members && changes.members.currentValue.length > 0) {
        this.getDeploymentData();
      }
    } else {
      if (changes.members && changes.members.currentValue.length > 0) {
        // Compute rights
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
  getDeploymentData() {
    this.getAvailabilities();
    this.getDeploymentOffers();
    this.getDeploymentOrders();
  }

  canSeeAlertButton() {
    let a = (this.isAlertsPage || (this.isMembersPage && this.currentRoster.id !== this.NO_ROSTER));
    let b = (this.checkOrganisationAssociate() || this.checkRosterGuest(this.currentRoster.id));
    return a && b;
  }

  canSeeAlertButtonEvent() {
    let a = (this.isAlertsPage);
    let b = (this.checkOrganisationAssociate() || this.checkRosterGuest(this.currentRoster.id));
    return a && b;
  }

  showEventCalendar() {
    let modalRef = this._modalService.open(EventCalendarModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  getAvailabilities() {
    if (this.members) {
      let arrayUsers = [];
      for (let i = 0; i < this.members.length; i++) {
        arrayUsers.push(this.members[i].id);
      }
      if (arrayUsers.length > 0 && this.eventQuery) {
        this._usereventanswerService.getAvailabilities(this.eventQuery, arrayUsers).subscribe(res => {
          this.availabilities = res;
        });
      }
    }
  }

  getDeploymentOffers() {
    if (this.members) {
      let arrayUsers = [];
      for (let i = 0; i < this.members.length; i++) {
        arrayUsers.push(this.members[i].id);
      }
      if (arrayUsers.length > 0 && this.eventQuery) {
        this._deploymentorderService.getDeploymentOffersByEventAndUsers(this.eventQuery, arrayUsers).subscribe(res => {
          this.deploymentOffers = res;
        });
      }
    }
  }

  getDeploymentOrders() {
    if (this.members) {
      let arrayUsers = [];
      for (let i = 0; i < this.members.length; i++) {
        arrayUsers.push(this.members[i].id);
      }
      if (arrayUsers.length > 0) {
        this._deploymentService.getDeploymentOrdersByUsers(arrayUsers).subscribe(res => {
          this.deploymentOrders = res;
        });
      }
    }
  }

  getAllTags() {
    this.tagsList = [];
    this._tagDispatcher.getRosterTags().subscribe(res => {
      this.tagsList = res;
    });
  }

  changeAll(option: boolean) {
    this.members.forEach(member => {
      if (this.isActiveOrganisationMember(member)) {
        this.selected[member.id] = option;
      }
    });
  }

  isActiveOrganisationMember(member) {
    let state = 0;
    member.organisation_members.forEach((organisationMember) => {
      if (organisationMember.organisation.id === this.organisation.id) {
        state = organisationMember.state;
      }
    });
    return state;
  }


  onSelected(id: number) {
    this.selected[id] = !this.selected[id];
  }

  onManagerAction(event) {
    // alert("onManagerAction (members component)");
    // this.refreshMembers();
  }

  hasSelectedMembers() {
    let ret = false;
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        ret = true;
      }
    }
    return ret;
  }

  hasSelectedTags() {
    let ret = false;
    if (this.selectedTags.length !== 0) {
      ret = true;
    }
    return ret;
  }

  action(goal) {
    if (!this.hasSelectedMembers() && !(goal === 'alert' && this.page === PAGES.manager.organisation.alerts) && !(goal === 'search')) {
      this.showToastWarning();
    } else {
      switch (goal) {
        case 'inform': this.informMembers();
          break;
        case 'alert': this.alertMembers();
          break;
        case 'offer': this.sendOffer();
          break;
        case 'apply': this.applyTags();
          break;
        case 'remove': this.removeTags();
          break;
        case 'deploy': this.showDeploymentOrders();
          break;
        default: this._loggerService.log('invalid action passed');
          break;
      }
    }
  }

  inviteMembers() {
    let modalRef = this._modalService.open(AddRosterModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    let toInvite = [];
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        toInvite.push(key);
      }
    }
    modalRef.componentInstance.toInvite = toInvite;
    this._organisationService.getOrgaRosters(this.organisation.id).subscribe(res => {
      modalRef.componentInstance.rosters = [];

      let rostersList = res;
      for (let i = 0; i < rostersList.length; i++) {
        if (this.checkRosterGuest(rostersList[i]['id'])) {
          modalRef.componentInstance.rosters.push(
            {
              id: rostersList[i]['id'],
              text: rostersList[i]['name']

            }
          );
        }
      }
    });
    modalRef.componentInstance.onAddMember.subscribe(($e) => {
      this.showToastAddMemberToRoster();
    });
    modalRef.componentInstance.onAddManager.subscribe(($e) => {
      this.showToastAddManagerToRoster();
    });
  }

  informMembers() {
    let modalRef = this._modalService.open(InformModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.organisation = this.organisation;
    let selectedMembers = [];
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        selectedMembers.push(parseInt(key, 10));
      }
    }
    modalRef.componentInstance.usersId = selectedMembers;
    modalRef.componentInstance.onSend.subscribe(($e) => {
      this.showToastInform();
    });
  }

  getAcceptedOffers(id) {
    let arrayAcceptedOffers = [];
    this._loggerService.log(this.deploymentOffers[id]);
    for (let i = 0; i < this.deploymentOffers[id].length; i++) {
      if (this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_PENDING ||
        this.deploymentOffers[id][i].valid === ListComponent.DEPLOYMENTOFFERS_ACCEPTED) {
        arrayAcceptedOffers.push(this.deploymentOffers[id][i]);
      }
    }
    return arrayAcceptedOffers;
  }

  alertMembers() {
    if (this.page === PAGES.manager.organisation.alerts) {
      let modalAdd = this._modalService.open(AddAlertModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
      modalAdd.componentInstance.selectedEvent = this.alertInfo.id;
      modalAdd.componentInstance.organisation = this.organisation;

      modalAdd.componentInstance.onClickAddMembers.subscribe(
        res => { this.addMember(res); },
        err => { this._loggerService.log("Failed to alert new people", err); }
      );
      this._loggerService.log("Alerting new people from an event");

    } else {
      let modalRef = this._modalService.open(AlertModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
      modalRef.componentInstance.organisation = this.organisation;
      let selectedMembers = [];
      for (let key in this.selected) {
        if (this.selected.hasOwnProperty(key) && this.selected[key]) {
          selectedMembers.push(parseInt(key, 10));
        }
      }
      modalRef.componentInstance.usersId = selectedMembers;
      modalRef.componentInstance.profile = this.profile;
      modalRef.componentInstance.onSend.subscribe(($e) => {
        this.showToastSendAlert();
      });
    }
  }

  addMember(alert) {
    this._usereventService.createAlert(alert).subscribe(
      res => {
        this.eventMap.setSelectedAlert(this.eventMap.selectedAlertInfo.id);
        this._toastService.show("eventMemberAddToast", "show", 3000);
      },
      err => {
        this.showToastAddMemberToAlertError();
      }

    );
  }

  sendOffer() {
    let modalRef = this._modalService.open(SendOfferModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.organisation = this.organisation;
    let selectedMembers = [];
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        selectedMembers.push(parseInt(key, 10));
      }
    }
    modalRef.componentInstance.profile = this.profile;
    modalRef.componentInstance.usersId = selectedMembers;
    modalRef.componentInstance.alertInfo = this.alertInfo;
    modalRef.componentInstance.page = this.page;
    modalRef.componentInstance.selectedEvent = this.eventQuery;
    modalRef.componentInstance.onClickSend.subscribe(($e) => {
      this.getDeploymentOffers();
      this.showToastSendOffer();
    });
  }

  showDeploymentOrders() {
    let modalRef = this._modalService.open(DeploymentOrdersModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.organisation = this.organisation;
    let selectedMembers = [];
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        selectedMembers.push(parseInt(key, 10));
      }
    }
    let id = selectedMembers.toString();
    modalRef.componentInstance.deploymentOffers = this.getAcceptedOffers(id);
    modalRef.componentInstance.profile = this.profile;
    modalRef.componentInstance.organisation = this.organisation;
    modalRef.componentInstance.selectedEventID = this.alertInfo.id;
    modalRef.componentInstance.selectedUser = id;
    modalRef.componentInstance.onClickDeploy.subscribe(($e) => {
      this.getDeploymentOrders();
      // this.showToastDeploymentOrders();
      this.onCatchClickCancel.emit($e);
    });
  }

  applyTags() {
    let memberArray = [];
    for (let [memberId, selected] of Object.entries(this.selected)) {
      if (selected) {
        memberArray.push(memberId);
      }
    }
    this._tagsService.addRosterMembersTags(this.currentRoster.id, memberArray, this.selectedTags).subscribe(() => {
      this.selected = [];
      this.selectedTags = [];
      this.showToastRosterTagAdded();
    },
      () => {
        this.showToastRosterTagAddError();
      });
  }

  removeTags() {
    let memberArray = [];
    for (let [memberId, selected] of Object.entries(this.selected)) {
      if (selected) {
        memberArray.push(memberId);
      }
    }
    this._tagsService.removeRosterMembersTags(this.currentRoster.id, memberArray, this.selectedTags).subscribe(() => {
      this.selected = [];
      this.selectedTags = [];

      this.showToastRosterTagDeleted();
    },
      () => {
        this.showToastRosterTagDeleteError();
      });
  }

  checkOrganisationManager() {
    if (this.organisation) {
      return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['NotManager']);
    }
  }

  checkOrganisationAssociate() {
    if (this.organisation) {
      return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL['Associate']);
    }
  }

  checkRosterManager(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  checkRosterGuest(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  handleCancelOffer(event) {
    this.getDeploymentOffers();

  }

  handleDeploy(event) {
    this.getDeploymentOrders();
    this.getDeploymentOffers();
  }

  showToastInform() {
    this._toastService.show("informToast", "show", 3000);
  }
  showToastAddMemberToRoster() {
    this._toastService.show("roasterMemberAddToast", "show", 3000);
  }
  showToastAddManagerToRoster() {
    this._toastService.show("roasterManagerAddToast", "show", 3000);
  }
  showToastSendOffer() {
    this._toastService.show("offerSendToast", "show", 3000);
  }
  showToastSendAlert() {
    this._toastService.show("alertSendToast", "show", 3000);
  }
  showToastWarning() {
    this._toastService.show("warningSelectMembersToast", "show", 3000);
  }
  showToastRosterTagDeleted() {
    this._toastService.show("deletedRosterTagToast", "show", 3000);
  }
  showToastRosterTagDeleteError() {
    this._toastService.show("errorDeletingRosterTagToast", "show", 3000);
  }
  showToastRosterTagAdded() {
    this._toastService.show("addedRosterTagToast", "show", 3000);
  }
  showToastRosterTagAddError() {
    this._toastService.show("errorAddingRosterTagToast", "show", 3000);
  }
  showToastAddMemberToAlertError() {
    this._toastService.show("errorAddingMemberToAlertError", "show", 3000);
  }
  // showToastDeploymentOrders() {
  //   this._toastService.show("ordersDeploymentToast", "show", 3000);
  // }

  /***********************************************/
  /*************** FEATURE TAGS ******************/
  /***********************************************/


  displayModalAddTag() {
    let modalRef = this._modalService.open(TagsComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.idRosterSelected = this.currentRoster.id;
    let selectedMembers = [];
    for (let key in this.selected) {
      if (this.selected.hasOwnProperty(key) && this.selected[key]) {
        selectedMembers.push(parseInt(key, 10));
      }
    }
    modalRef.componentInstance.tabIdMembersSelected = selectedMembers;
  }

  // Not used due to the tags-modal.component
  changeTagMode() {
    this.tagsMode = !this.tagsMode;
  }


  openTagsModal() {
    let tagModal = this._modalService.open(TagsModalComponent, { backdrop: 'static' });

    tagModal.componentInstance.rosterId = this.currentRoster.id;

    let selectedMembersIds = Object.entries(this.selected)
      .filter(x => x[1]) // [id: (true|false)]
      .map(x => x[0]);

    tagModal.componentInstance.selectedMembersIds = selectedMembersIds;
    tagModal.componentInstance.membersMode = selectedMembersIds.length > 0;


    /**
     * Update tags here
     * (both on close and dismiss for now)
     *
     * arrays are reversed another time so they're "straigh" here
     */
    tagModal.result.then(
      (result) => {
        this.tagsList = this._tagDispatcher.updateTags();
        if (result && result === TAG_MODAL_RESULT.APPLIED) {
          // TODO: move TAG_MODAL_RESULT.APPLIED      to     tagModal.componentInstance.RESULT_APPLIED
          this._toastService.show("members-tag-applied", "show");
        }
      })
      .catch(
        (result) => {
          this.tagsList = this._tagDispatcher.updateTags();
        }
      );
  }

  /**
   * This function takes O(n) to change a label.
   * Needs refactoring.
   */
  isSomeoneSelected() {
    return Object.entries(this.selected).filter(x => x[1]).length > 0;
  }

  selectTag(selectedTag) {
    let index = this.selectedTags.indexOf(selectedTag.id);
    if (index !== -1) {
      this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(selectedTag.id);
    }
  }

}
