import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.scss']
})


export class RosterComponent {

  public rosterId: number;
  public rosterStatus: string;

  constructor(
    private route: ActivatedRoute
  ) {
    this.getRosterInfo();
  }

  getRosterInfo() {
    this.route.paramMap.subscribe(params => {
      this.rosterId = +params.get('id');
      this.rosterStatus = params.get('status');
    });
  }

}
