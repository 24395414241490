import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { AlertService } from '../../../../../../services/api/alert/alert.api';
import { UserEventAnswerService } from '../../../../../../services/api/usereventanswer.service';
import { ProfileService } from '../../../../../../services/api/profile/profile.api';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../../services/utils/toast.service';
import { Calendar } from '../../../../../../data/calendar.class';
import { Selection } from '../../../../../../data/selection.class';
import { SelectionsArray } from '../../../../../../data/selectionArray.class';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileInterface } from "../../../../../model/profile.interface";
import { UserInterface } from "../../../../../model/user.interface";
import { ProfileDispatcherService } from '../../../../../../services/api/profile/profile.dispatcher';
import { switchMap, tap } from 'rxjs/operators';


@Component({
  selector: 'alerts-deployment-calendar',
  templateUrl: './alerts-deployment-calendar.component.html',
  styleUrls: ['./alerts-deployment-calendar.component.scss']
})

export class AlertsCalendarComponent implements OnInit {
  @Input() alertInfo;
  @Input() idAlert;

  selectionClass = Selection;

  selection: Selection;

  /**
  * an array that retrieve the selections made by the user
  */
  @Input() SELECTIONS: SelectionsArray<Selection>;
  @Output() SELECTIONSChange = new EventEmitter<any>();
  /**
  * able to display the calendar according to the status
  */
  days: any = [];

  /**
   * the calendar contains:
   *   -an array that contains the days of the month
   *   -a Moment with the today's date
   * (See the calendar.class for more information)
   */
  calendar: any;
  day_label: any = [];


  display: boolean;
  endUnavailabilityUser: Date;


  constructor(
    private _modalService: NgbModal,
    private _alertEmalsysService: AlertService,
    private _userEventAnswerService: UserEventAnswerService,
    private _profileService: ProfileService,
    private _toastService: ToastService,
    public logger: LoggerService,
    private _profileDispatcher: ProfileDispatcherService
  ) {
  }

  ngOnInit() {
    this.selection = new Selection(null, null, null);
    this.calendar = new Calendar();
    this.display = false;
    this.refreshSelection();
  }
  // set a boolean to display the calendar
  displayCalendar(): boolean {
    this.display = !this.display;
    return this.display;
  }

  resetSelection() {
    this.selection = new Selection(null, null, null);
  }

  /**
  * Return a map of date and status for the given calendar
  * Ex : [
  *   {date : "12/01/2018", status : Selection.NOT_SURE_STATUS},
  *   {date : "12/01/2018", status: Selection.AVAILABLE_STATUS}
  *  ]
  * @param selections
  * @param calendar
  */
  mapDateToStatus(selections, calendar): any {
    let days = [];
    let end_date_event = new Date(this.alertInfo.end_date);
    end_date_event.setDate(end_date_event.getDate());
    let start_date_event = new Date(this.alertInfo.start_date);
    start_date_event.setDate(start_date_event.getDate());
    // loop on calendar days
    for (let i = 0; i < calendar.days.length; i++) {
      // default status
      let index = calendar.days[i]._d;
      if (index < calendar.today._d || index < start_date_event || index > end_date_event) {
        days.push({ date: index, status: Selection.DISABLED });
        continue;
      }
      days.push({ date: index, status: Selection.NOT_SETTED_STATUS });

      // check if the current date is covered by a selection
      for (let j = 0; j < selections.length; j++) {
        if (new Date(selections[j].startDate.toLocaleString("en-EN")) <= new Date(calendar.days[i]._d.toLocaleString("en-EN"))
          && new Date(calendar.days[i]._d.toLocaleString("en-EN")) <= new Date(selections[j].endDate.toLocaleString("en-EN"))) {

          // if the date is covered, then it is added to the map
          let k = days.findIndex(item => item.date === index);
          if (k > -1) {
            days[k].status = selections[j].status;
          }
        }
      }
    }
    return days;
  }

  /**
  *Set the status (SELECTED_FROM,SELECTED_TO,SELECTED_BETWEEN) when click on a date
  */
  onDaySelection(date, content) {
    // loop on days
    for (let i = 0; i < this.days.length; i++) {
      if (date === this.days[i].date) {
        // if the selected date is not disabled:
        if (this.days[i].status !== Selection.DISABLED) {
          // if the beginning date of the range is not setted:
          if (!this.selection.startDate) {
            this.days[i].status = Selection.SELECTED_FROM;
            this.selection.startDate = this.days[i].date;
          } else { // if the beginning date of the range is setted:
            // if the end date of the range is after the beginning date:
            if (this.selection.startDate <= this.days[i].date) {
              this.days[i].status = Selection.SELECTED_TO;
              this.selection.endDate = this.days[i].date;

              if (this.selection.startDate !== this.selection.endDate) {
                // Set status of days between the beginning date and the end date
                for (let j = i - 1; this.days[j].status !== Selection.SELECTED_FROM; j--) {
                  this.days[j].status = Selection.SELECTED_BETWEEN;
                }
              }
              // open the modal to know the status of the selection

              this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' })
                .result.then((result) => {
                  this.setChoice(result);
                }, (reason) => {
                  this.refreshSelection();
                  this.resetSelection();
                });
            } else {
              this.showToastWrongRange();
              // Reset the parameters for a new selection
              this.resetSelection();
              // Refresh the calendar
              this.getDaysArray();
            }
          }
        } else {
          this.showToastDisabled();
          this.resetSelection();
          // Refresh the calendar
          this.getDaysArray();
        }
      }
    }
  }

  setUnavailableAccordingToProfile() {
    this._profileDispatcher.getUserData().pipe(
      switchMap((user: UserInterface) => {
        this.selection.startDate = new Date(this.alertInfo.start_date);
        this.selection.startDate.setDate(this.selection.startDate.getDate());
        this.selection.endDate = new Date(user.end_unavailability);
        this.selection.setStatus('notAvailable');
        if (this.selection.endDate < this.selection.startDate) {
          this.selection.endDate = this.selection.startDate;
        }
        return this._userEventAnswerService.setAvailability(this.idAlert, this.selection);
      })
    )
    .subscribe(res => {
      this.refreshSelection();
      // Reset the parameters for a new selection
      this.resetSelection();
    });

    // Trigger user data from service
    this._profileService.getUserData();
  }

updateUnavailableAccordingToProfile(existingSelection) {
  this._profileDispatcher.getUserData().subscribe((user: UserInterface) => {
    if (existingSelection && existingSelection.start_date === this.alertInfo.start_date
      && existingSelection.end_date !== user.end_unavailability && existingSelection.status === Selection.NOT_AVAILABLE_STATUS
      && existingSelection.end_date !== this.alertInfo.end_date) {
      this.selection.startDate = new Date(this.alertInfo.start_date);
      this.selection.startDate.setDate(this.selection.startDate.getDate());
      this.selection.endDate = new Date(user.end_unavailability);
      this.selection.setStatus('notAvailable');
      // Since the unavailability is set up the first time the user displays the calendar, it will be the first selection to be added.
      this._userEventAnswerService.updateIndexedAvailability(this.idAlert, this.selection, 0)
        .subscribe(res => {
          this.refreshSelection();
          // Reset the parameters for a new selection
          this.resetSelection();
        });
    }
  });
}

// Use for the modal buttons
setChoice(choice) {
  this.selection.setStatus(choice);

  // Send request PUT to save the selection
  this.setAvailability(this.selection);
}

// Use for the quick buttons
setFullAvailability(choice) {
  let a = new Date(this.alertInfo.start_date);
  a.setDate(a.getDate());
  let b = new Date(this.alertInfo.end_date);
  b.setDate(b.getDate());
  this.selection = new Selection(a, b, null);
  this.selection.setStatus(choice);
  this.logger.log("select", this.selection);
  // Send request PUT to save the selection
  this.setAvailability(this.selection);
}

getDaysArray() {
  this.days = this.mapDateToStatus(this.SELECTIONS, this.calendar);
  this.logger.log("SELECTIONS", this.SELECTIONS);
}

refreshSelection() {
  // this.setUnavailableAccordingToProfile();
  this._userEventAnswerService.getAvailability(this.idAlert).subscribe((res) => {
    if (res[0].user_event_answers.length > 0) {
      this.updateUnavailableAccordingToProfile(res[0].user_event_answers[0]);
      this.SELECTIONS = res[0].user_event_answers.map(value => {
        let a = new Date(value.start_date);
        // a.setDate(a.getDate() + 1);
        a.setDate(a.getDate());
        let b = new Date(value.end_date);
        // b.setDate(b.getDate() + 1);
        b.setDate(b.getDate());
        return new Selection(a, b, value.status);
      });
      this.getDaysArray();
      this.SELECTIONSChange.emit(this.SELECTIONS);
    } else {
      this.setUnavailableAccordingToProfile();
    }
  });
}

setAvailability(selection) {
  this._userEventAnswerService.setAvailability(this.idAlert, selection)
    .subscribe(res => {
      this.refreshSelection();
      // Reset the parameters for a new selection
      this.resetSelection();
      this.showToastSetAvailabilities();
    });
}

resetAll() {
  this._userEventAnswerService.deleteAvailabilities(this.idAlert)
    .subscribe(res => {
      this.setUnavailableAccordingToProfile();
      this.refreshSelection();
      // Reset the parameters for a new selection
      this.resetSelection();
      this.showToastResetAvailabilities();
    });
}


showToastDisabled() {
  this._toastService.show("disabledDateToast", "show", 3000);
}

showToastWrongRange() {
  this._toastService.show("wrongRangeDateToast", "show", 3000);
}

showToastResetAvailabilities() {
  this._toastService.show("resetAvailabilitiesToast", "show", 3000);
}

showToastSetAvailabilities() {
  this._toastService.show("setAvailabilitiesToast", "show", 3000);
}
}
