import { Injectable } from '@angular/core';
import { TagsService } from './tag.api';
import { TagInterface } from '../../../app/model/tag.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { updateLocale } from 'moment';
import { Dispatcher } from '../../dispatcher.service';


@Injectable()
export class TagDispatcherService extends Dispatcher {

    private tags$: BehaviorSubject<TagInterface[]> = new BehaviorSubject<TagInterface[]>(null);
    private rosterId;

    public constructor(
        // private _tagService: TagsService
    ) {
        super();
    }

    // private pipeNotNull(obs: BehaviorSubject<any>): Observable<any> {
    //     return obs.asObservable().pipe(
    //         filter(x => x !== null)
    //     );
    // }


    // public changeRoster(newRosterId: number) {
    //     if (newRosterId !== this.rosterId) {
    //         this._tagService.getAllRosterTags(newRosterId);
    //     }
    // }

    public updateTags() {
        // this._tagService.getAllRosterTags(this.rosterId).subscribe(
        //     (tags: TagInterface[]) => {
        //         this.tags$.next(tags);
        //     }
        // );
    }

    public getRosterTags(): Observable<TagInterface[]> {
        return this.pipeNotNull(this.tags$);
    }

    public addNewTag(tagName: string) {
        // this._tagService.addNewTagToRoster(this.rosterId, tagName).subscribe(
        //     (res) => {
        //         this.updateTags();
        //     }
        // );
    }

    public deleteTag(tagId: number) {
        // this._tagService.deleteTagRoster(tagId).subscribe(
        //     () => {
        //         this.updateTags();
        //     }
        // );
    }

    public publishRosterTags(tags: TagInterface[]) {
        this.tags$.next(tags);
    }

}
