<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Member Information</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
</div>

<!--Modal body-->
<div class="modal-body" *ngIf="member">
  <div class="modal-general">
    <div class="modal-general-image">
      <img class="image-logo" src="{{member.profile.picture}}" onerror='this.src="assets/images/default_roster.png"' />
    </div>
    <div class="modal-general-info">
      <h2>{{member.firstname}} {{member.lastname}}, {{getAge(member.profile.birthday)}}</h2>
      <p>
        <i class="fa fa-map-marker"></i> {{member.guessed_country ? member.guessed_country : 'Unknown'}}
      </p>
      <p>
        <i class="fa fa-phone"></i> {{member.profile.phones.length ? member.profile.phones[0].number : 'Unknown'}}
      </p>
      <p>
        <i class="fa fa-envelope"></i> {{member.profile.contact_email ? member.profile.contact_email : 'Unknown'}}
      </p>
    </div>
  </div>
  <div class="modal-item skills">
    <h2 class="modal-item-title">Skills</h2>
    <div class="modal-item-content">
      <p *ngIf="!skills || skills.length == 0">No skills</p>
      <p *ngFor="let skill of skills">{{skill.tag.name}}</p>
    </div>
  </div>
  <div class="modal-item languages">
    <h2 class="modal-item-title">Languages</h2>
    <div class="modal-item-content">
      <p *ngIf="!languages || languages.length == 0">No languages</p>
      <p *ngFor="let language of languages">{{language.language_name}} ({{language.level == 0 ? "Other" : language.level == 1 ? "Elementary Proficiency" : language.level == 2 ? "Limited Working Proficiency" : language.level == 3 ? "Professional Working Proficiency": language.level == 4 ?
        "Full Professional Profenciency" : language.level == 5 ? "Native or Bilingual Proficiency" : "" }})</p>
    </div>
  </div>
  <div class="modal-item rosters">
    <h2 class="modal-item-title">Rosters</h2>
    <div class="modal-item-content">
      <p *ngIf="rosters?.length == 0">No rosters</p>
      <div class="roster-info" *ngFor="let item of rosters">
        <img class="roster-image" [src]="item.roster_profile.browser_url"
          onerror='this.src="assets/images/default_roster.png"'
          title="{{item.status === 'manager' ? 'Manager' : 'Member'}}" />
        <p>{{item.name}}</p>
      </div>
    </div>
  </div>
  <div class="modal-item tags">
    <h2 class="modal-item-title">Tags</h2>
    <div class="modal-item-content">
      <!-- <p *ngIf="rosterMemberTags?.length == 0">No tags on any roster</p> -->
      <div *ngFor="let roster_member of rosterMemberTags">
        <p *ngIf="roster_member.tags.length === 0">No tags on {{roster_member.roster.name}} roster</p><br>
        <div *ngFor="let tag of roster_member.tags" class="modal-item-content">
          <div class="roster-tag-container">

            <p>
              <span>{{tag.roster_tag.name}}</span>
              <img class="roster-image" [src]="roster_member.roster.roster_profile.browser_url"
                onerror='this.src="assets/images/default_roster.png"' />
            </p>


            <!-- ({{item.roster.name}}) -->
            <!-- <span>({{item.roster.name}})</span> -->

          </div>
        </div>
      </div>
      <!-- <div *ngFor="let tag of tags">
        <p>{{tag.name}} ({{}})</p>
      </div> -->
    </div>
  </div>
  <div class="modal-item documents">
    <h2 class="modal-item-title">Documents</h2>
    <div class="modal-item-content">
      <p *ngIf="!documents || documents.length == 0">No documents</p>
      <div class="modal-docs-info" *ngFor="let doc of documents" (click)="downloadDoc(doc)">
        <img class="doc-image" [src]="doc.browser_url" onerror='this.src="assets/images/Docs_Icon.png"' />
        <p class="doc-name">{{doc.title}}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal-body loading" *ngIf="!member">
  <spinner-component>
  </spinner-component>
</div>

<!--Modal footer-->
<div class="modal-footer">
  <button type="button" class="full-profile" aria-label="Go to full profile"
    routerLink="/manager/profile/{{member?.id}}" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true"><i class="fa fa-share"></i> Go to full profile</span>
  </button>
</div>