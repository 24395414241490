import { Component, Input, OnInit } from '@angular/core';
import { AlertDispatcherService } from '../../../../../services/api/alert/alert.dispatcher';
import { LoggerService } from '../../../../../services/utils/logger.service';


@Component({
  selector: 'dashboard-alerts',
  templateUrl: './dashboard-alerts.component.html',
  styleUrls: ['./dashboard-alerts.component.scss']
})


export class DashboardAlertsComponent implements OnInit {   // TODO: Faire quelque chose pour ces histoires d'ISO2

  @Input() organisation: any;

  public alerts: any;

  constructor(
    private _loggerService: LoggerService,
    private _alertDispatcher: AlertDispatcherService
  ) {
    this.alerts = [];
  }

  ngOnInit() {
    this._alertDispatcher.getMyAlerts().subscribe(res => {
        this.alerts = res;
        this._loggerService.log("ALERTS: ", this.alerts);
      });
  }
}
