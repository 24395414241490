<div id="administration-skills">

  <h1>Global Skills Management</h1>
  <p>
    Merge the skills: Click on two rows of each skill in the table below
  </p>
  <!-- <div class="list-processing">
    <div class="merge-options text-center" *ngIf="displayMergeOptions">
      <p>
        Skills to merge: check two skills
      </p>
        <p *ngFor="let skill of skillsToMerge">
          {{ skill.name }}
        </p>
      <p *ngIf="displayConfirmMerge">
        The two skills will be merged under the name
        <br />{{ skillsToMerge[1].name }}<br />
        <button>Confirm</button><button (click)="invertSkillsToMerge()">Invert</button><button (click)="cancelMerge()" (change)="selectAll()">Cancel</button>
      </p>
      <p class="error-merge" *ngIf="displayErrorMerge">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{ errorMessageMerge }}
      </p>
    </div>
  </div> -->
    <table class="list-skills text-center">
      <tr class="addSkill">
        <td colspan="3">
          <input type="text" [(ngModel)]="keyword" placeholder="Search Skill By Keyword"/>
        </td>
      </tr>
      <tr class="addSkill">
        <td colspan="2">
          <form #addSkillForm="ngForm">
            <input id="skillName" name="skillName" type="text" minLength="1" [(ngModel)]="newSkill.name" placeholder="Add a skill"/>
          </form>
        </td>
        <td>
          <button class="add" type="submit" (click)="addSkill(newSkill)"><i class="fa fa-plus-circle" aria-hidden="true"></i></button>
        </td>
      </tr>
      <tr>
        <th colspan="1">
          <p *ngIf="ascOrder">
            Skill name<button (click)="sortSkillsByNameOrder(listSkills)"><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i></button>
          </p>
          <p *ngIf="!ascOrder">
            Skill name<button (click)="sortSkillsByNameReverse(listSkills)"><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i></button>
          </p>
        </th>
        <th>
          <p *ngIf="descOrder">
            Users<button (click)="sortSkillsByUsersOrder(listSkills)"><i class="fa fa-sort-numeric-desc" aria-hidden="true"></i></button>
          </p>
          <p *ngIf="!descOrder">
            Users<button (click)="sortSkillsByUsersReverse(listSkills)"><i class="fa fa-sort-numeric-asc" aria-hidden="true"></i></button>
          </p>
        </th>
      </tr>
      <tr *ngFor="let skill of listSkills | searchList : keyword" [class.selection]="skill === selectedSkill">
        <!-- <td>
          <input type="checkbox" value="skill" (click)="addToMerge(skill)" [(ngModel)]="skill.selected" [disabled]="displayConfirmMerge" (change)="checkIfAllSelected()"/>
        </td> -->
          <td (click)="selectSkill(skill); open(content)" class="label-name">{{ skill.name }}</td>
          <td class="label-nb">{{ skill.count_user_tags ? skill.count_user_tags : '-' }}</td>
          <td>
            <button class="delete" (click)='deleteSkill(skill)'><i class="fa fa-minus-circle" aria-hidden="true"></i></button>
          </td>
      </tr>
    </table>

    <div class="list-processing">
      <div id="snackBarAdministrationSelectSkill" *ngIf="displayMergeOptions">
        <p *ngFor="let skill of skillsToMerge">
          You selected : {{ skill.name }}.
        </p>
        <p>
          Select a second skill to complete the merge.
        </p>
      </div>

      <ng-template #content let-c="close" let-d="dismiss"  *ngIf="displayConfirmMerge" class="merge-options text-center">
        <div class="modal-header">
          <h4>MERGING SKILLS</h4>
        </div>
        <div class="modal-body">
          <p>
            The skills
          </p>
          <p>
            "{{ skillsToMerge[0].name }}" and "{{ skillsToMerge[1].name }}"
          </p>
          <p>
            will be merged under the name
          </p>
          <p>
            "{{ skillsToMerge[1].name }}"
          </p>
        </div>
        <div class="modal-footer">
          <p>
            <button>Confirm</button><button (click)="invertSkillsToMerge()">Invert</button><button (click)="cancelMerge()" (change)="selectAll()">Cancel</button>
          </p>
        </div>
      </ng-template>

    </div>


</div>
