import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Data
import { URL_API_EMALSYS } from '../../environments/environment';
// Services
import { WSSEService } from '../security/wsse.service';
import { LoggerService } from '../utils/logger.service';
import { HttpService } from './http.service';



@Injectable()


export class DocumentService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService,
    private _loggerService: LoggerService,
    private _wsseService: WSSEService
  ) { }

  documentDownload(id: number) {
    let headers = new HttpHeaders({ 'x-wsse': this._wsseService.getHeaderValue() });
    return this.http.get(URL_API_EMALSYS + "/document/" + id + "/download", { headers: headers, responseType: 'blob' });
  }
}
