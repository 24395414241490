import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Services
import { LeafletService } from '../../../../../../services/external/leaflet.service';


@Component({
  selector: 'dashboard-news-map',
  templateUrl: './dashboard-news-map.component.html',
  styleUrls: ['./dashboard-news-map.component.scss']
})


export class DashboardNewsMapComponent implements OnInit, OnChanges {

  @Input() alerts: any;
  @Input() alertsUser = [];
  // @Input() disasters: any;
  // @Input() selection: any;

  public map: any;
  public markers: any;
  public alertsArray = [];

  constructor(
    private _leafletService: LeafletService,
  ) { }

  ngOnInit() {
    this.createMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.alerts && changes.alerts.previousValue !== changes.alerts.currentValue)
      || (changes.alertsUser && changes.alertsUser.previousValue !== changes.alertsUser.currentValue)) {
      this.refreshMap();
    }
  }

  refreshMap() {
    if (this.map) {
      this.markers.clearLayers();
      this.alertsArray = [];
      let that = this;
      if (this.alerts.length > 0) {
        that.alertsArray = this.alerts.map((alert) => {
          return {
            alert: alert,
            member: false
          };
        });
        this.alertsUser.map((alert) => {
          for (let i = 0; i < that.alertsArray.length; i++) {
            if (that.alertsArray[i].alert.id === alert.id) {
              that.alertsArray[i].member = true;
              i = that.alertsArray.length;
            }
          }
        });

        this.addAlerts(this.alertsArray);
      }
    }
  }

  createMap() {
    this.map = this._leafletService.createMap("dashboard-news-map-component-map", 2);
    this.markers = this._leafletService.createLayerGroup();
    this.refreshMap();
  }

  addAlerts(alerts) {
    alerts.map((alert) => {
      let marker = this._leafletService.createMarkerAlert(alert);
      if (marker) {
        this.markers.addLayer(marker);
      }
    });
    // alerts.map(alert => alert.secondary_countries.map(country => this.markers.addLayer(this._leafletService.createMarkerAlertSecondary(alert, country))));
    this.markers.addTo(this.map);
  }

  addDisasters(disasters) {
    disasters.map(disaster => this.markers.addLayer(this._leafletService.createMarker(disaster)));
    this.markers.addTo(this.map);
  }

  enableZoom() {
    this.map.scrollWheelZoom.enable();
  }

}
