import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Data
import { MAPBOX_ACCESSTOKEN, URL_MAPBOX, ISO2_TO_ISO3 } from '../../app/app.constants';
import { LATLONG } from '../../data/countries';
import { CHOROPLETH } from '../../data/world';
// Services
import { AlertService } from '../api/alert/alert.api';
import { UserService } from '../api/user.service';
import { DisasterService } from './disasters.service';
import { NavigationService } from '../utils/navigation.service';
import { AlertPageService } from '../page/alert.manager.pager';

// Plugins
import * as Leaflet from 'leaflet';



@Injectable()


export class LeafletService {

  private readonly MIN_MAP_ZOOM = 1.5;

  constructor(
    private router: Router,
    private _alertService: AlertService,
    private _userService: UserService,
    private _disastersService: DisasterService,
    private _navigationService: NavigationService,
    private _alertPage: AlertPageService,
  ) {
    this.hoverMarker();
  }

  // ------------------------------------------------------------------------//
  // ---------------------------------- MAP ---------------------------------//
  // ------------------------------------------------------------------------//

  createMap(mapId, zoom) {
    // Create the map
    let map = Leaflet.map(mapId, {
      maxBounds: [[-70, 190], [130, -190]],
      center: [10, 0],    // Position of the map
      zoom: zoom,		   // Zoom
      zoomControl: true,			 // Hide the zoom control
      scrollWheelZoom: false   // Disable the zoom on scroll
    });

    // Add tile layer to the map
    Leaflet.tileLayer(URL_MAPBOX + '{mapboxId}/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Data <a href="https://reliefweb.int/">ReliefWeb</a> | Imagery <a href="http://mapbox.com">Mapbox</a>',
      maxZoom: 10,
      minZoom: this.MIN_MAP_ZOOM,
      mapboxId: 'reliefapps/cj8bonvph7fm42sqc88g35kcd',
      accessToken: MAPBOX_ACCESSTOKEN
    }).addTo(map);

    return map;
  }

  createLayerGroup() {
    return Leaflet.layerGroup();
  }

  // ------------------------------------------------------------------------//
  // -------------------------------- MARKER --------------------------------//
  // ------------------------------------------------------------------------//

  createMarker(disaster) {
    // Coordinates of the marker
    let coordinates: any;
    for (let iso2 in ISO2_TO_ISO3) {
      if (disaster.fields.primary_country.iso3.toString() === ISO2_TO_ISO3[iso2]) {
        coordinates = LATLONG.filter(country => country.country_code === iso2)[0].latlng;
      }
    }

    // Color of the background by creating a Sass class
    let className = (disaster.fields.status === "current") ? "disaster-marker current" :
      (disaster.fields.status === "alert") ? "disaster-marker alert" :
        (disaster.fields.status === "past") ? "disaster-marker past" :
          "";
    // Icon on the marker
    let icon = Leaflet.icon({
      iconUrl: "assets/disaster-icons/" + disaster.fields.primary_type.code + ".png",
      className: className + " " + disaster.id
    });

    // Create marker
    let marker = Leaflet.marker(coordinates, {
      icon: icon
    });
    // marker.on('click', () => this.router.navigate(['/disaster/' + disaster.id]));
    marker.on('mouseover', () => this._disastersService.setHovered(+disaster.id));
    marker.on('mouseout', () => this._disastersService.setHovered(-1));

    // marker.on('click', () => this._navigationService.goToDisasterDetails(disaster.id));
    return marker;
  }

  createMarkerMember(member, organisation, selected, openProfile) {
    // Coordinates of the marker
    let coordinates = [member.latitude, member.longitude];

    // check if member is a manager
    let manager = false;
    member.organisation_managers.forEach((orga) => {
      if (orga.organisation.id === organisation.id) {
        manager = true;
      }
    });

    // Icon on the marker
    let icon = Leaflet.icon({
      iconUrl: "assets/images/default_avatar.png",
      className: "member-marker" + (manager ? " manager" : "")
    });

    // Create marker
    let marker = Leaflet.marker(coordinates, { icon: icon });

    marker.on('mouseover', () => marker.bindTooltip(member.firstname + " " + member.lastname).openTooltip());
    marker.on('click', () => this._userService.openProfile(member, organisation.id));

    return marker;
  }

  createMarkerAlert(alert) {
    if (alert.alert.country_name) {
      // Coordinates of the marker
      let coordinates: any;
      coordinates = LATLONG.filter(country => country.country_code === alert.alert.country)[0].latlng;
      // Color of the background by creating a Sass class
      let className = alert.member ? "alert-marker" : "alert-marker not-invited";

      // Icon on the marker
      let icon = Leaflet.icon({

        iconUrl: "assets/images/emalsys_hand-white.png",
        className: className + " " + alert.alert.id
      });

      // Create marker
      let marker = Leaflet.marker(coordinates, {
        icon: icon
      });
      marker.on('click', () => this.router.navigate([this.router.url + '/alert/' + alert.alert.id]));
      marker.on('mouseover', () => this._alertService.setHovered(+alert.id));
      // marker.on('mouseout' , () => this._alertService.setHovered(-1));
      // marker.on('click', () => this._navigationService.goToAlertDetails(alert.id));
      marker.on('mouseover', () => marker.bindTooltip(alert.alert.name).openTooltip());

      return marker;
    }
    return null;
  }

  createMarkerSmallAlert(alert) {
    // Coordinates of the marker
    let coordinates: any;
    coordinates = LATLONG.filter(country => country.country_code === alert.country)[0].latlng;
    // Color of the background by creating a Sass class
    let className = "alert-marker classic";

    // Icon on the marker
    let icon = Leaflet.icon({

      iconUrl: "assets/images/emalsys_hand-white.png",
      className: className + " " + alert.id
    });

    // Create marker
    let marker = Leaflet.marker(coordinates, {
      icon: icon
    });
    marker.on('mouseover', () => this._alertService.setHovered(+alert.id));
    // marker.on('mouseout' , () => this._alertService.setHovered(-1));
    // marker.on('click', () => this._navigationService.goToAlertDetails(alert.id));
    marker.on('mouseover', () => marker.bindTooltip(alert.alerts[0].name).openTooltip());

    return marker;
  }

  createMarkerAlertSecondary(alert) {
    // Coordinates of the marker
    let coordinates: any;
    coordinates = LATLONG.filter(country => country.country_code === alert.secondaryCountry)[0].latlng;

    // Color of the background by creating a Sass class
    let className = "alert-marker secondary";

    // Icon on the marker
    let icon = Leaflet.icon({
      iconUrl: "assets/images/emalsys_hand-white.png",
      className: className + " " + alert.id
    });

    // Create marker
    let marker = Leaflet.marker(coordinates, {
      icon: icon
    });
    // marker.on('click', () => this.router.navigate(['/disaster/' + disaster.id]));
    // marker.on('mouseover', () => this._alertService.setHovered(+alert.id));
    // marker.on('mouseout' , () => this._alertService.setHovered(-1));
    // marker.on('click', () => marker.bindTooltip(alert.name).openTooltip());
    marker.on('mouseover', () => marker.bindTooltip(alert.name).openTooltip());

    return marker;
  }

  hoverMarker() {
    let disasterIdSaved: number;
    this._disastersService.getHovered().subscribe(disasterId => {
      if (disasterId !== -1) {
        Leaflet.DomUtil.addClass(document.getElementsByClassName(disasterId.toString())[0], "hover");
        disasterIdSaved = disasterId;
      } else {
        Leaflet.DomUtil.removeClass(document.getElementsByClassName(disasterIdSaved.toString())[0], "hover");
      }
    });
  }

  setChoroplethAlert(dataPrimaryLayers, dataSecondaryLayers, layers) {
    let primaryStyle = {
      fillColor: '#AF3C2C',
      weight: 1,
      opacity: 1,
      color: '#AF3C2C',
      fillOpacity: 0.8
    };

    let secondaryStyle = {
        fillColor: '#AF3C2C',
        weight: 1,
        opacity: 0.3,
        color: '#AF3C2C',
        fillOpacity: 0.2
      };
      dataSecondaryLayers.map(dataLayer => {
        if (dataLayer.country) {
          let SecondaryCountryGeoJSON: any;
          SecondaryCountryGeoJSON = CHOROPLETH.filter(country => country.properties.iso2 === dataLayer.country);
          let layer = Leaflet.geoJson(SecondaryCountryGeoJSON, { style: secondaryStyle, alerts: dataLayer.alerts });
          layers.addLayer(layer);
        }
      });
      dataPrimaryLayers.map(dataLayer => {
        if (dataLayer.country) {
          let primaryCountryGeoJSON: any;
          primaryCountryGeoJSON = CHOROPLETH.filter(country => country.properties.iso2 === dataLayer.country);
          let layer = Leaflet.geoJson(primaryCountryGeoJSON, { style: primaryStyle });
          layers.addLayer(layer);
        }
      });
      return layers;
    }

  setPrimaryChoroplethAlert(dataPrimaryLayer) {
    let primaryStyle = {
      fillColor: '#AF3C2C',
      weight: 1,
      opacity: 1,
      color: '#AF3C2C',
      fillOpacity: 0.8
    };

    let primaryCountryGeoJSON: any;
    let coordinates: any;

    primaryCountryGeoJSON = CHOROPLETH.filter(country => country.properties.iso2 === dataPrimaryLayer.country);
    if (primaryCountryGeoJSON[0] === undefined) {
      coordinates = LATLONG.filter(country => country.country_code === dataPrimaryLayer.country)[0].latlng;
      // Color of the background by creating a Sass class
      let className = "alert-marker classic";

      // Icon on the marker
      let icon = Leaflet.icon({

        iconUrl: "assets/images/emalsys_hand-white.png",
        className: className + " " + dataPrimaryLayer.id
      });

      let marker = Leaflet.marker(coordinates, { icon: icon , alerts: dataPrimaryLayer.alerts, focus: false });
      marker.on('mouseover', () => {
        this._alertService.setHovered(+dataPrimaryLayer.id);
        marker.bindTooltip(dataPrimaryLayer.alerts[0].name).openTooltip();
      });
      marker.on('click', () => {this._alertPage.selectAlert(dataPrimaryLayer.alerts[0]); });
      return marker;
    } else {
      let layer = Leaflet.geoJson(primaryCountryGeoJSON, { style: primaryStyle , alerts: dataPrimaryLayer.alerts, focus: true});
      return layer;
    }
  }

  setSecondaryChoroplethAlert(dataSecondaryLayer) {
    let secondaryStyle = {
      fillColor: '#AF3C2C',
      weight: 1,
      opacity: 0.3,
      color: '#AF3C2C',
      fillOpacity: 0.2
    };

    let secondaryCountryGeoJSON: any;
    secondaryCountryGeoJSON = CHOROPLETH.filter(country => country.properties.iso2 === dataSecondaryLayer.country);
    return Leaflet.geoJson(secondaryCountryGeoJSON, { style: secondaryStyle, alerts: dataSecondaryLayer.alerts, focus: true});
  }

}
