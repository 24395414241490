import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { OrganisationInterface } from '../../../app/model/organisation.interface';
import { RosterInterface } from '../../../app/model/roster.interface';
// Data
import { URL_API_EMALSYS } from '../../../environments/environment';
import { ApiCacherService, Semaphore } from '../../utils/api-cacher.service';
import { CacheNotificationsService } from '../../utils/cache.notifications';
// Services
import { HttpService } from '../http.service';
import { MembersDispatcherService } from '../member/members.dispatcher';
import { CachedOrganisationService } from '../organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../organisation/organisation.dispatcher';
import { ProfileService } from './../profile/profile.api';
import { RostersDispatcherService } from './rosters.dispatcher';

@Injectable()
export class RosterService {
    private readonly JOINABLE_ROSTERS = RosterInterface.CACHE.JOINABLE_ROSTERS;
    private readonly ROSTER_INFORMATION = RosterInterface.CACHE.ROSTER_INFORMATION;
    private readonly ORGANISATION_ROSTERS = RosterInterface.CACHE.ORGANISATION_ROSTERS;
    private readonly ROSTER_MEMBERS = RosterInterface.CACHE.ROSTER_MEMBERS;

    private busy = new Semaphore();

    constructor(
        private http: HttpClient,
        private _httpService: HttpService,
        private _apiCacher: ApiCacherService,
        private _rosterDispatcher: RostersDispatcherService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _memberDispatcher: MembersDispatcherService,
        private _cachedOrganisationService: CachedOrganisationService,
        private _profileService: ProfileService,

    ) { }

    getJoinableRosters(organisationId) {
        return this._apiCacher.do<RosterInterface[]>(
            this.busy.generate(this.JOINABLE_ROSTERS), this.JOINABLE_ROSTERS,
            () => {
                let options = this._httpService.createRequestOptions();
                return this.http.get(URL_API_EMALSYS + "/rosters?method=by_organisation&id=" + organisationId + "&filter=joinable", options);
            }
        ).subscribe(
            (res: RosterInterface[]) => {
                this._rosterDispatcher.nextJoinableRosters(res);
            }
        );
    }

    getRoster(rosterId: number) {
        return this._apiCacher.do(
            this.busy.generate('getRosterInformation'),
            this.ROSTER_INFORMATION + rosterId,
            () => {
                let options = this._httpService.createRequestOptions();
                return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId, options);
            }
        ).pipe(
            tap((res: RosterInterface) => this._rosterDispatcher.nextSelectedRoster(res))
        );
    }

    getOrganisationRostersAsResponder(organisationId: number) {
        return this._apiCacher.do(
            this.busy.generate('getOrganisationRosters'),
            this.ORGANISATION_ROSTERS + organisationId,
            () => {
                let options = this._httpService.createRequestOptions();
                // return this.http.get<RosterInterface[]>( URL_API_EMALSYS + "/organisations/" + organisationId + "/rosters", options);
                return this.http.get<RosterInterface[]>(URL_API_EMALSYS + "/rosters?method=by_organisation&id=" + organisationId, options);

            }
        ).subscribe(
            // TODO services consistence
            (res: Array<RosterInterface[]>) => this._rosterDispatcher.publishOrganisationRostersAsResponder(res)
        );
    }

    getOrganisationRostersAsManager(organisationId: number) {
        return this._apiCacher.do(
            this.busy.generate('getOrganisationRosters'),
            this.ORGANISATION_ROSTERS + organisationId,
            () => {
                let options = this._httpService.createRequestOptions();

                return this.http.get<RosterInterface[]>(URL_API_EMALSYS + "/organisations/" + organisationId + "/rosters", options);
                // return this.http.get<RosterInterface[]>(URL_API_EMALSYS + "/rosters?method=by_organisation&id=" + organisationId, options);

            }
        );
    }

    refreshRosters(organisationId) {
        this._apiCacher.removeIfPresent(this.ORGANISATION_ROSTERS + organisationId);
        return this._organisationDispatcher.getSelectedOrganisation()
            .pipe(
                tap((x: OrganisationInterface) => this._cachedOrganisationService.getOrganisationRosters(x.id).subscribe()),

            );

    }

    refreshCurrentOrganisationRosters() {
        return this._organisationDispatcher.getSelectedOrganisation()
            .pipe(
                switchMap((x) => {
                    this._apiCacher.removeIfPresent(OrganisationInterface.CACHE.ORGANISATION_ROSTERS + x.id);
                    return this._cachedOrganisationService.getOrganisationRosters(x.id);
                })
            )
            .subscribe();

    }



    deleteRoster(rosterId, organisationId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId, options)
            .pipe(
                tap(
                    () => this.refreshCurrentOrganisationRosters()
                ),
                tap(() => {
                    CacheNotificationsService.notifyCacheChange(RosterInterface.CACHE.ORGANISATION_ROSTERS, organisationId);
                })
            );
    }

    createRoster(body: FormData) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters", body, options)
            .pipe(
                tap(
                    () => {
                        // Need the profile to display the "Edit roster" wheel
                        this._profileService.refreshProfile();
                        this.refreshCurrentOrganisationRosters();
                    }
                ),

                tap(() => {
                    CacheNotificationsService.notifyCacheChange(RosterInterface.CACHE.ORGANISATION_ROSTERS, body.get("organisation"));
                })
            );
    }

    updateRoster(rosterId: number, body: FormData) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId, body, options)
            .pipe(
                tap(
                    () => this.refreshCurrentOrganisationRosters()
                )
            );
    }

}
