<div class="dashboard-members-view">
  <div *ngIf="isMembersPage">
    <div class="component-member" *ngFor="let member of members"
      [ngClass]="{'shadow' : selected[member.id],'disabled' : isActiveOrganisationMember(member)==0}"
      (click)="isActiveOrganisationMember(member)==1 ? checkboxChange(member.id) : null">
      <div class="component-member-container">
        <div [ngClass]="{'available': isMemberAvailable(member.end_unavailability)}" class="component-member-image">
          <img class="image-logo" src="{{member.profile.picture}}"
            onerror='this.src="assets/images/default_roster.png"' />
        </div>


        <div class="component-member-name">
          <h3 [ngClass]="{'disabled' : isActiveOrganisationMember(member)==0}" class="name-text">{{member.firstname}}
            {{member.lastname}}</h3>
          <h3 class="manager-text" *ngIf="(!rosterId || rosterId==-1) && !isNotOrganisationManager(member)">
            Organisation Manager</h3>
          <h3 class="manager-text" *ngIf="(rosterId && risterId!==-1) && !isNotRosterManager(member)">Roster
            Manager
          </h3>
          <h3 class="rights-text" *ngIf="(!rosterId || rosterId==-1) &&  isOrganisationAssociate(member)">
            Associate</h3>
          <h3 class="rights-text" *ngIf="(!rosterId || rosterId==-1) && isOrganisationAdmin(member)">Admin</h3>
          <h3 class="rights-text" *ngIf="(!rosterId || rosterId==-1) && isOrganisationSuperAdmin(member)">Super
            Admin</h3>
          <h3 class="rights-text" *ngIf="rosterId && isRosterGuest(member)">Guest</h3>
          <h3 class="rights-text" *ngIf="rosterId && isRosterRightManager(member)">Manager
          </h3>
          <h3 class="rights-text" *ngIf="rosterId && isRosterSupervisor(member)">
            Supervisor
          </h3>
        </div>



        <div *ngIf="canManageRights(member)" class="member-dropdown">
          <button (click)="showDropdown(member.id); $event.stopPropagation();" class="member-dropdown-button">
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="member-dropdown-content" [ngClass]="{'show' : show == member.id}">

            <div *ngIf="!rosterId && checkOrganisationAssociate()">
              <p *ngIf="isActiveOrganisationMember(member) == 1 && ((!isOrganisationSuperAdmin(member) && checkOrganisationAssociate())
                  ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile) && !checkIsOwner(member))
                  )" (click)="freezeOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-ban"></i> Freeze Member</p>
              <p *ngIf="isActiveOrganisationMember(member) == 0 && ((!isOrganisationSuperAdmin(member) && checkOrganisationAssociate())
                ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile))
                || checkIsOwner(member))"
                (click)="activeOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-ban"></i> Activate Member</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 &&
           (!isOrganisationSuperAdmin(member)||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile))
              )" (click)="removeFromOrganisation(member);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-trash"></i> Remove from Organisation</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 && isNotOrganisationManager(member)"
                (click)="promoteToOrganisationManager(member.id,member.email);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-plus"></i> Promote to Organisation Manager</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAdmin(member)  && isOrganisationSuperAdmin(profile)"
                (click)="changeToSuperAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
                <i class="fa fa-level-up"></i> Promote to Super Admin</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAssociate(member)"
                (click)="changeToAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
                <i class="fa fa-level-up"></i> Promote to Admin</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile)"
                (click)="changeToAdmin(member.id);showDropdown(member.id);$event.stopPropagation();">
                <i class="fa fa-level-down"></i> Demote to Admin</p>
              <p *ngIf="isActiveOrganisationMember(member) == 1 && isOrganisationAdmin(member)"
                (click)="changeToAssociate(member.id);showDropdown(member.id);$event.stopPropagation();">
                <i class="fa fa-level-down"></i> Demote to Associate</p>

              <p *ngIf="isActiveOrganisationMember(member) == 1
          && !isNotOrganisationManager(member)
          && (!isOrganisationSuperAdmin(member)
          ||(isOrganisationSuperAdmin(member) && isOrganisationSuperAdmin(profile)
              ))" (click)="demoteToOrganisationMember(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-minus"></i> Demote to Organisation Member</p>
            </div>

            <div *ngIf="rosterId && checkRosterGuest()">
              <p *ngIf="isActiveRosterMember(member) == 1 &&((!isRosterSupervisor(member) && checkRosterGuest())
              ||(isRosterSupervisor(member) && isRosterSupervisor(profile))
             )" (click)="removeRosterMember(member);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-trash"></i> Remove from Roster</p>
              <p *ngIf="isActiveRosterMember(member) == 1 && isNotRosterManager(member)"
                (click)="promoteToRosterManager(member.id,member.email);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-plus"></i> Promote to Guest</p>
              <p *ngIf="isActiveRosterMember(member) == 1 && isRosterRightManager(member) && isRosterSupervisor(profile)"
                (click)="changeToSupervisor(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-level-up"></i> Promote to Supervisor</p>
              <p *ngIf="isActiveRosterMember(member) == 1 && isRosterGuest(member)"
                (click)="changeToManager(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-level-up"></i> Promote to Manager</p>
              <p *ngIf="isActiveRosterMember(member) == 1 && isRosterSupervisor(member) && isRosterSupervisor(profile)"
                (click)="changeToManager(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-level-down"></i> Demote to Manager</p>
              <p *ngIf="isActiveRosterMember(member) == 1 && isRosterRightManager(member)"
                (click)="changeToGuest(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-level-down"></i> Demote to Guest</p>

              <p *ngIf="isActiveRosterMember(member) == 1 && !isNotRosterManager(member)
          && (!isRosterSupervisor(member) ||(isRosterSupervisor(member) && isRosterSupervisor(profile)
              ))" (click)="demoteToRosterMember(member.id);showDropdown(member.id); $event.stopPropagation();">
                <i class="fa fa-minus"></i> Demote to Roster member</p>
            </div>
          </div>
        </div>

        <button class="see-member" (click)="openProfile(member); $event.stopPropagation();">
          <i class="fa fa-user-o"></i>
        </button>
      </div>
      <div class="component-member-selected">
        <div class="icon-selected" *ngIf="selected[member.id]">
          <i class="fa fa-check fa-xs"></i>
        </div>
        <div class="icon-unselected" *ngIf="!selected[member.id]">
          <i class="fa fa-check fa-xs"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isAlertsPage" class="background-component">
    <div class="rectangle" [ngClass]="{
      'selected': selected[member.id],
      'unselected': !selected[member.id]
      }" *ngFor="let member of members"
      (click)="isActiveOrganisationMember(member)==1 ? checkboxChange(member.id) : null">
      <div class="first-container">
        <div class="checkbox-container">
          <div class="icon-selected" *ngIf="selected[member.id]">
            <i class="fa fa-check fa-xs"></i>
          </div>
          <div class="icon-unselected" *ngIf="!selected[member.id]"></div>
        </div>
        <div class="user-infos-container">
          <div class="container-img-profile">
            <img class="img-user alert-list-image" src="{{member.profile.picture}}"
              onerror='this.src="assets/images/download.png"'
              (click)="openProfile(member); $event.stopPropagation();" />

              <div class="isAvailable-status">
                <p class="available" *ngIf="member.end_unavailability && checkAvailability(member.end_unavailability)">
                  <i class="fa fa-circle green" aria-hidden="true" title="Available"></i>
                </p>
                <p class="available" *ngIf="!member.end_unavailability">
                  <i class="fa fa-circle green " aria-hidden="true" title="Available"></i>
                </p>
                <p class="not-available" *ngIf="member.end_unavailability && !checkAvailability(member.end_unavailability)">
                  <i class="fa fa-circle red" aria-hidden="true" title="Not available"></i>
                </p>
              </div>
          </div>
          <p class="name-user">{{member.firstname}} {{member.lastname}}</p>
          
        </div>
      </div>
      <div class="second-container" *ngIf="!checkDeployementOrderByEvent(member.id)">
          <div class="deployed-buttons">
              <button class="btn emalsys_button blue shadow" *ngIf="checkDeploymentOrderByMember(member.id)"
              (click)="showDeploymentOrderDetails(member.id);$event.stopPropagation();">
                <i aria-hidden="true" style="margin-left: 5px;"></i>
                Deployments 
              </button>
              <button class="btn emalsys_button blue shadow disabled" *ngIf="!checkDeploymentOrderByMember(member.id)"
              (click)=$event.stopPropagation();>
                <i aria-hidden="true" style="margin-left: 5px;"></i>
                Deployments 
              </button>
                
          </div>

          <div class="deployed-container">
              <div class="deployed-status">
              </div>
    
              <div class="second-container">
    
    
                  <div class="response-process-container">
                    <div class="response-process">
                      <span class="line"></span>
                      <button class="step-one" [ngClass]="{
                        'green': setProgressAvailabilities(member.id)=='green',
                        'yellow': setProgressAvailabilities(member.id)=='yellow',
                        'red': setProgressAvailabilities(member.id)=='red'
                        }" (click)="showCalendar(member.id); $event.stopPropagation();" title="Click to display availabilities">
                        <!-- Wolf there, calendar here (cit) -->
                        <p class="number-step" *ngIf="setProgressAvailabilities(member.id)=='grey'">1</p>
                        <p *ngIf="setProgressAvailabilities(member.id)=='red'"><i class="fa fa-times"></i></p>
                        <p *ngIf="setProgressAvailabilities(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>
                        <p *ngIf="setProgressAvailabilities(member.id)=='yellow'"><i class="fa fa-question"></i></p>
                      </button>
                      <span class="line-one" [ngClass]="{'green': setProgressAvailabilities(member.id)=='green'}"></span>
                    </div>
                    <p-calendar inline="true" class="calendar" monthNavigator="true" yearNavigator="true"
                      yearRange="{{yearRange}}" [ngClass]="{'show' : show == member.id}" (click)="$event.stopPropagation()">
                      <ng-template pTemplate="date" let-date>
                        <span class="day-number" [ngClass]="getCssClassStatus(member.id, date)">
                          {{date.day}}
                        </span>
                      </ng-template>
                    </p-calendar>
                    <p *ngIf="setProgressOffers(member.id)=='red'" class="text-step">Not Available</p>
                    <p *ngIf="setProgressOffers(member.id)=='green'" class="text-step">Availability Provided</p>
                    <p *ngIf="setProgressOffers(member.id)=='yellow'" class="text-step">Availability Requested</p>
                    <p *ngIf="setProgressOffers(member.id)=='grey'" class="text-step">Availability</p>
                  </div>
          
          
          
                  <div class="response-process-container">
                    <div class="response-process">
                      <span class="line-one" [ngClass]="{'green': setProgressAvailabilities(member.id)=='green'}"></span>
                      <button class="step-two" [ngClass]="{
                        'green': setProgressOffers(member.id)=='green',
                        'yellow': setProgressOffers(member.id)=='yellow',
                        'red': setProgressOffers(member.id)=='red'
                        }" (click)="showDeploymentOffers(member.id);$event.stopPropagation();"
                        title="Click to display deployment offers">
                        <p class="number-step" *ngIf="setProgressOffers(member.id)=='grey'">2</p>
                        <p *ngIf="setProgressOffers(member.id)=='red'"><i class="fa fa-times"></i></p>
                        <p *ngIf="setProgressOffers(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>
                        <p *ngIf="setProgressOffers(member.id)=='yellow'"><i class="fa fa-question"></i></p>
                      </button>
                      <span class="line-two" [ngClass]="{'green': setProgressOffers(member.id)=='green'}"></span>
                    </div>
                    <p *ngIf="setProgressOffers(member.id)=='red'" class="text-step">Deployment Offer Refused</p>
                    <p *ngIf="setProgressOffers(member.id)=='green'" class="text-step">Deployment Offer Accepted</p>
                    <p *ngIf="setProgressOffers(member.id)=='yellow'" class="text-step">Deployment Offer Sent</p>
                    <p *ngIf="setProgressOffers(member.id)=='grey'" class="text-step">Deployment Offer</p>
                  </div>
          
          
                  <div class="response-process-container">
                    <div class="response-process">
                      <span class="line-three" [ngClass]="{'green': setProgressOffers(member.id)=='green'}"></span>
                      <button class="step-three" [ngClass]="{'yellow': setProgressOffers(member.id)=='green'}"
                        (click)="showDeploymentOrders(member.id);$event.stopPropagation();">
                        <p *ngIf="setProgressOffers(member.id)=='grey' || setProgressOffers(member.id)=='yellow'" class="number-step">3</p>
                        <p *ngIf="setProgressOffers(member.id)=='red'" class="number-step">3</p>
                        <!--The button will never be green because when the responder is deployed, a text is displayed-->
                        <!--<p *ngIf="setProgressOffers(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>-->
                        <p *ngIf="setProgressOffers(member.id)=='green'"><i class="fa fa-question"></i></p>
                      </button>
                      <span class="line"></span>
                    </div>
                    <p class="text-step">Deployment Order</p>
                  </div>
          
                </div>
    
    
            </div>

      </div>


      <div class="second-container" *ngIf="checkDeployementOrderByEvent(member.id)">
        <div class="deployed-buttons">
              <button class="btn emalsys_button red shadow"
              (click)="showCurrentDeploymentOrderDetails(member.id);$event.stopPropagation();">
                <i aria-hidden="true" style="margin-left: 5px;"></i>
                Deployed
                <i class="fa fa-plane custom-plane" aria-hidden="true"></i>
              </button>
                
         </div>
        <div class="deployed-container">
          <div class="deployed-status">

          </div>

          <div class="second-container">


              <div class="response-process-container">
                <div class="response-process">
                  <span class="line"></span>
                  <button class="step-one" [ngClass]="{
                    'green': setProgressAvailabilities(member.id)=='green',
                    'yellow': setProgressAvailabilities(member.id)=='yellow',
                    'red': setProgressAvailabilities(member.id)=='red'
                    }" (click)="showCalendar(member.id); $event.stopPropagation();" title="Click to display availabilities">
                    <!-- Wolf there, calendar here (cit) -->
                    <p class="number-step" *ngIf="setProgressAvailabilities(member.id)=='grey'">1</p>
                    <p *ngIf="setProgressAvailabilities(member.id)=='red'"><i class="fa fa-times"></i></p>
                    <p *ngIf="setProgressAvailabilities(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>
                    <p *ngIf="setProgressAvailabilities(member.id)=='yellow'"><i class="fa fa-question"></i></p>
                  </button>
                  <span class="line-one" [ngClass]="{'green': setProgressAvailabilities(member.id)=='green'}"></span>
                </div>
                <p-calendar inline="true" class="calendar" monthNavigator="true" yearNavigator="true"
                  yearRange="{{yearRange}}" [ngClass]="{'show' : show == member.id}" (click)="$event.stopPropagation()">
                  <ng-template pTemplate="date" let-date>
                    <span class="day-number" [ngClass]="getCssClassStatus(member.id, date)">
                      {{date.day}}
                    </span>
                  </ng-template>
                </p-calendar>
                <p *ngIf="setProgressOffers(member.id)=='red'" class="text-step">Not Available</p>
                <p *ngIf="setProgressOffers(member.id)=='green'" class="text-step">Availability Provided</p>
                <p *ngIf="setProgressOffers(member.id)=='yellow'" class="text-step">Availability Requested</p>
                <p *ngIf="setProgressOffers(member.id)=='grey'" class="text-step">Availability</p>
              </div>
      
      
      
              <div class="response-process-container">
                <div class="response-process">
                  <span class="line-one" [ngClass]="{'green': setProgressAvailabilities(member.id)=='green'}"></span>
                  <button class="step-two" [ngClass]="{
                    'green': setProgressOffersForDeployed(member.id)=='green',
                    'yellow': setProgressOffersForDeployed(member.id)=='yellow',
                    'red': setProgressOffersForDeployed(member.id)=='red'
                    }" (click)="showDeploymentOffers(member.id);$event.stopPropagation();"
                    title="Click to display deployment offers">
                    <p class="number-step" *ngIf="setProgressOffersForDeployed(member.id)=='grey'">2</p>
                    <p *ngIf="setProgressOffersForDeployed(member.id)=='red'"><i class="fa fa-times"></i></p>
                    <p *ngIf="setProgressOffersForDeployed(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>
                    <p *ngIf="setProgressOffersForDeployed(member.id)=='yellow'"><i class="fa fa-question"></i></p>
                  </button>
                  <span class="line-two" [ngClass]="{'green': setProgressOffersForDeployed(member.id)=='green'}"></span>
                </div>
                <p *ngIf="setProgressOffersForDeployed(member.id)=='red'" class="text-step">Deployment Offer Refused</p>
                <p *ngIf="setProgressOffersForDeployed(member.id)=='green'" class="text-step">Deployment Offers Answered</p>
                <p *ngIf="setProgressOffersForDeployed(member.id)=='yellow'" class="text-step">Deployment Offers Unanswered</p>
                <p *ngIf="setProgressOffersForDeployed(member.id)=='grey'" class="text-step">Deployment Offer</p>
              </div>
      
      
              <div class="response-process-container">
                <div class="response-process">
                  <span class="line-three" [ngClass]="{'green': checkFutureDeployments(member.id)=='green', 'yellow': checkFutureDeployments(member.id)=='yellow'}"></span>
                  <button class="step-three" [ngClass]="{'green': checkFutureDeployments(member.id)=='green', 'yellow': checkFutureDeployments(member.id)=='yellow'}"
                    (click)="showDeploymentOrders(member.id);$event.stopPropagation();">
                    <p *ngIf="checkFutureDeployments(member.id)=='grey'" class="number-step">3</p>
                    <p *ngIf="checkFutureDeployments(member.id)=='red'"><i class="fa fa-times"></i></p>
                    <!--The button will never be green because when the responder is deployed, a text is displayed-->
                    <p *ngIf="checkFutureDeployments(member.id)=='green'"><i class="fa fa-check fa-xs"></i></p>
                    <p *ngIf="checkFutureDeployments(member.id)=='yellow'"><i class="fa fa-question"></i></p>
                    <!--<p *ngIf="setProgressOffers(member.id)=='green'"><i class="fa fa-question"></i></p>-->
                  </button>
                  <span class="line"></span>
                </div>
                <p class="text-step">Deployment Order</p>
              </div>
      
            </div>


        </div>
      </div>
    </div>
  </div>

  <div id="loading-member-promotion" class="toast green-toast">Role change execution, please wait...</div>
  <div id="role-changed" class="toast green-toast">Role changed successfully</div>
  <div id="member-demoted" class="toast green-toast">Member demoted successfully!</div>
</div>