<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button class="close" (click)="_activeModal.close()">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
</div>


<!-- BODY -->
<div class="modal-body">
  <p>{{body}}</p>
</div>

<div class="modal-footer">
  <div class="footer-buttons">
    <div class="button" *ngFor="let button of buttons">
      <button class="emalsys_button" [ngClass]="{'green':button.color === GREEN, 'red':button.color === 'red'}" (click)="button.callback();">
        {{button.text}}
      </button>
    </div>
  </div>
</div>