import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { DocumentService } from '../../../../../services/api/document.service';
import { saveAs as importedSaveAs } from "file-saver";
// Data
import { COMPLIANCE_CODE } from '../../../../app.constants';
import { RosterCompliancesService } from '../../../../../services/api/roster/dependencies/compliances/roster-compliances.api';
@Component({
  selector: 'app-roster-request-modal',
  templateUrl: './roster-request-modal.component.html',
  styleUrls: ['./roster-request-modal.component.scss']
})
export class RosterRequestModalComponent implements OnInit {
  @Input() notificationRoster;
  public rosterFilledCompliances: any;
  public rosterEmptyCompliances: any;
  public orgaFilledCompliances: any;
  public orgaEmptyCompliances: any;
  constructor(
    private _documentService: DocumentService,
    public activeModal: NgbActiveModal,
    private _loggerService: LoggerService,
    private _rosterCompliancesService: RosterCompliancesService
  ) { }

  ngOnInit() {
    this._loggerService.log(this.notificationRoster);
    this.getRosterCompliances();
  }

  getRosterCompliances() {

    this._rosterCompliancesService.getRosterCompliancesOfUser(this.notificationRoster.roster.id, this.notificationRoster.user.id).subscribe(res => {
      let result = res;
      this.rosterFilledCompliances = result[0];
      this.rosterEmptyCompliances = result[1];
      this.orgaFilledCompliances = result[2];
      this.orgaEmptyCompliances = result[3];

    });
  }
  // mapComplianceToDocument(compliancesList) {
  //   for (let i = 0; i < compliancesList.length; i++) {
  //     let mapObject = {
  //       compliance: compliancesList[i],
  //       document: null
  //
  //     }
  //
  //     for (let j = 0; j < compliancesList[i].roster_requirement_documents.length; j++) {
  //       if (compliancesList[i].roster_requirement_documents[j].document.owner.id == this.notificationRoster.user.id) {
  //         mapObject.document = compliancesList[i].roster_requirement_documents[j].document
  //       }
  //     }
  //     this.mapComplianceAndDocument.push(mapObject)
  //   }
  //   this._loggerService.log(this.mapComplianceAndDocument)
  // }

  downloadDoc(document) {
    this._loggerService.log("Doc ID to download: ", document.id);

    this._documentService.documentDownload(document.id).subscribe(response => {
      importedSaveAs(response, document.title);
      this._loggerService.log("Blob received: ", response);
    }, error => {
      this._loggerService.error("Failed to download the file: ", error);
    });
  }

  setLevel(member) {
    return COMPLIANCE_CODE.filter(level => level.value === member.level)[0].label;
  }
}
