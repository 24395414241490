<!--Roster-compliances component-->
<div id="roster-compliances-component" class="shadow">

  <div class="roster-compliances-component-compliances">
    <!--Table-->
    <table class="compliances-table">
      <tr class="emalsys_section-title">
        <h2>Organisation Compliances</h2>
    </tr>
      <!--Label-->
      <tr class="table-head">
        <th i18n="@@titleComplianceLabel">
          <h3>Title</h3></th>
        <th i18n="@@titleComplianceLabel">
          <h3>Level</h3></th>
        <th i18n="@@titleComplianceLabel">
          <h3>Documents</h3></th>
        <th i18n="@@titleComplianceLabel">
          <h3>Upload/Update</h3></th>
      </tr>
      <tr *ngFor='let compliance of orgaFilledCompliances'>
        <td class="vertical-align">
          <p>{{compliance.title}}</p>
        </td>
        <td class="vertical-align" >
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td class="vertical-align">
          <button type="text" (click)="downloadDoc(compliance.organisation_requirement_documents[0].document)">{{compliance.organisation_requirement_documents[0].document.title}}</button>
        </td>
        <td class="upload-update vertical-align">
          <button class="btn emalsys_button green margin-r margin-t" (click)="uploadComplianceDocumentModal(compliance.id,'organisation')">Update</button>
          <button class="btn emalsys_button red margin-r margin-t" (click)="deleteOrganisationComplianceDocument(compliance.id)">Delete</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of orgaEmptyCompliances'>
        <td class="vertical-align">
          <p>{{compliance.title}}</p>
        </td>
        <td class="vertical-align">
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td class="vertical-align"> <i class="fa fa-times" aria-hidden="true"></i></td>
        <td>
          <button class="btn emalsys_button green margin-t" (click)="uploadComplianceDocumentModal(compliance.id,'organisation')">Upload</button>
        </td>
      </tr>
      <div class="emalsys_section-title">
        <h2 i18n="@@rosterCompliancesLabel">Roster Compliances</h2>
      </div>
      <tr *ngFor='let compliance of rosterFilledCompliances'>
        <td class="vertical-align">
          <p>{{compliance.title}}</p>
        </td>
        <td class="vertical-align">
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td class="vertical-align">
          <button type="text" (click)="downloadDoc(compliance.roster_requirement_documents[0].document)">{{compliance.roster_requirement_documents[0].document.title}}</button>
        </td>
        <td class="upload-update">
          <button class="btn emalsys_button green margin-r margin-t" (click)="uploadComplianceDocumentModal(compliance.id,'roster')">Update</button>
          <button class="btn emalsys_button red margin-r margin-t" (click)="deleteRosterComplianceDocument(compliance.id)">Delete</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of rosterEmptyCompliances'>
        <td class="vertical-align">
          <p>{{compliance.title}}</p>
        </td>
        <td class="vertical-align">
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td class="vertical-align"> <i class="fa fa-times" aria-hidden="true"></i></td>
        <td>
          <button class="btn emalsys_button green margin-t" (click)="uploadComplianceDocumentModal(compliance.id,'roster')">Upload</button>
        </td>
      </tr>
    </table>
  </div>
</div>

<div id="deleteComplianceDocumentToast">Compliance document deleted</div>
<div id="uploadComplianceDocumentToast">Compliance document uploaded</div>
