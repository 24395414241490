<div class="modal-header">
  <h4 class="modal-title">Login</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">
      <i class="fa fa-times"></i>
    </span>
  </button>
</div>
<div class="modal-body">

  <div id="login-component" *ngIf="!loading">
    <div class="text-center">
      <form id="login-forms">
        <div class="form-group">
          <input class="form-control" type="email" name="email" placeholder="Email" [(ngModel)]="email" (keyup.enter)="email=='' || password=='' ? '' : loginIn()"
          />
        </div>
        <div class="form-group">
          <input class="form-control" type="password" name="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="email=='' || password=='' ? '' : loginIn()"
          />
        </div>
      </form>
    </div>
    <div class="checkbox" style="width:80%;margin-left:auto;margin-right:auto;">
      <label for="remember_me" class="font-emalsys-blue link-font-size" i18n="@@loginRememberMeLabel">
        <input type="checkbox" id="remember_me" name="_remember_me" value="on" /> Remember me
      </label>
        <ngb-alert *ngIf="loginFailed" [type]="'danger'">Login failed: Bad credentials.</ngb-alert>
        <ngb-alert *ngIf="gdprRefused" [type]="'danger'">Login failed: GDPR terms refused.</ngb-alert>
        <ngb-alert *ngIf="connerror" [type]="'danger'">Login failed: GDPR terms refused.</ngb-alert>
    </div>
    <div class="login margin-b text-center">
      <button class="btn btn-red btn-block btn-lg" [disabled]="email=='' || password==''" (click)="email=='' || password=='' ? '' : loginIn()">Login</button>
    </div>
    <!-- <div class="text-center margin-b link-font-size">
      <a style="text-decoration: none; color: black;" i18n="@@loginForgotPass">Forgot password ?</a>
    </div> -->
  </div>
  <div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>

</div>

