import { Component, Input, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';

// Services
import { UserService   } from '../../../../../services/api/user.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ToastService  } from "../../../../../services/utils/toast.service";

@Component({
  selector: 'user-component',
  templateUrl: './profile-external.component.html',
  styleUrls: ['./profile-external.component.scss']
})
export class ProfileExternalComponent implements OnInit {

  public profile: any;
  public picture: any;
  public userId: any;

  constructor(
    private titleService: Title,
    private _userService: UserService,
    private _loggerService: LoggerService,
    public _toastService: ToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params.userId;
    this.getUser();
    this.titleService.setTitle("Profile");
  }

  getUser() {
    this._userService.getUser(this.route.snapshot.params.userId).subscribe(res => {
      this.profile = res;
      this.profile.external = true;
      this.picture = this.profile.profile.picture;
    }, err => {
      this._loggerService.error("ERROR getting profile: ", err);
      this.showToastErrorRetrievingProfile();
    });
  }

  showToastErrorRetrievingProfile() {
    this._toastService.show("retrievingProfileToast", "show", 3000);
  }
}
