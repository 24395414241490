/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roster-preloader-members.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./roster-preloader-members.component";
var styles_RosterPreloaderMembersComponent = [i0.styles];
var RenderType_RosterPreloaderMembersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RosterPreloaderMembersComponent, data: {} });
export { RenderType_RosterPreloaderMembersComponent as RenderType_RosterPreloaderMembersComponent };
export function View_RosterPreloaderMembersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "roster-members-component shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "title preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "roster-members-component-members"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "square preloader"]], null, null, null, null, null))], null, null); }
export function View_RosterPreloaderMembersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "roster-preloader-members", [], null, null, null, View_RosterPreloaderMembersComponent_0, RenderType_RosterPreloaderMembersComponent)), i1.ɵdid(1, 114688, null, 0, i2.RosterPreloaderMembersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RosterPreloaderMembersComponentNgFactory = i1.ɵccf("roster-preloader-members", i2.RosterPreloaderMembersComponent, View_RosterPreloaderMembersComponent_Host_0, {}, {}, []);
export { RosterPreloaderMembersComponentNgFactory as RosterPreloaderMembersComponentNgFactory };
