import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Interfaces
import { TagInterface } from '../../app/model/tag.interface';
// Data
import { URL_API_EMALSYS } from '../../environments/environment';
import { LoggerService } from '../utils/logger.service';
import { ToastService } from '../utils/toast.service';
// Services
import { HttpService } from './http.service';

@Injectable()
export class AdministrationSkillsService {

  // Variable declarations

  // http request options
  public options: any;

  constructor(
    private _httpService: HttpService,
    private http: HttpClient,
    private _logger: LoggerService,
    public _toastService: ToastService
  ) {
    this.options = this._httpService.createRequestOptions();
  }

  // Methods

  // Retrieve the skills list
  public getSkillsList(): any {
    return this.http.get<TagInterface[]>(URL_API_EMALSYS + '/tags', this.options);
  }

  // ADD ONE
  public addSkill(data) {
    this._logger.log("Skill to add:", data);
    this.http.post(URL_API_EMALSYS + '/tags', data, this.options).subscribe();
  }

  // DELETE ONE
  public deleteSkill(data) {
    this._logger.log("DELETE: id to use", data.id);
    this.http.delete(URL_API_EMALSYS + '/tags/' + data.id, this.options).subscribe();
  }

  // MERGE ?



}
