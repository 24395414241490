import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { CachedOrganisationService } from '../../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { PushNotificationService } from '../../../../../services/api/pushnotifications.service';
import { RostersDispatcherService } from '../../../../../services/api/roster/rosters.dispatcher';
import { DashboardManagerPageService } from '../../../../../services/page/dashboard.manager.pager';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { RightService } from '../../../../../services/utils/right.service';
import { ToastService } from '../../../../../services/utils/toast.service';
// Data
import { ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../app.constants';
import { OrganisationInterface } from '../../../../model/organisation.interface';
import { RosterInterface } from '../../../../model/roster.interface';
import { UserInterface } from '../../../../model/user.interface';
import { InitializedRosters } from '../../../../model/utils/initialization-model.interface';
import { EditOrganisationModalComponent } from '../modals/edit-organisation/edit-organisation-modal.component';
import { EditRosterModalComponent } from '../modals/edit-roster/edit-roster-modal.component';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../../../model/right.interface';
import { RosterModalComponent } from './../modals/roster/roster-modal.component';


export interface DataFilter {
  [key: string]: any;
}
export interface RosterFilter extends DataFilter {
  rosterId?: number;
  organisationId?: number;
}

@Component({
  selector: 'dashboard-manager-rosters',
  templateUrl: './rosters.component.html',
  styleUrls: ['./rosters.component.scss']
})
export class RostersComponent implements OnInit, OnDestroy {

  // can't reference the static var from html
  public readonly NO_ROSTER = RosterInterface.NO_ROSTER;

  public profile: any;
  organisation: any;

  public rosters: any = [];
  // public rosters: RosterInterface[] = [];
  public selected = RosterInterface.NO_ROSTER;

  // Permissions
  public canAddNewRoster = false;
  // public canSeeAllOrganisationMembers = false;
  public amIOrganisationManager = false;

  // Map rosterId: (true|false)
  public canEditRoster = {};

  private subscriptions: Subscription[] = [];


  public loadedRosters = false;

  constructor(
    private _rightService: RightService,
    private _loggerService: LoggerService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _permissionService: RightService,
    private _profileDispatcher: ProfileDispatcherService,
    private _rosterDispatcher: RostersDispatcherService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _organisationCachedService: CachedOrganisationService,
    private _dashboardPage: DashboardManagerPageService,
    private notif: PushNotificationService
  ) { }

  ngOnInit() {
    // Profile observable
    const profile$ = this._profileDispatcher.getProfile().pipe(
      tap((profile: UserInterface) => this._loggerService.log(profile)),
      catchError(x => of(x))
    );

    // Organisation observable
    const organisation$ = this._organisationDispatcher.getSelectedOrganisation()
      .pipe(
        // Taken from page service
        // tap((organisation: OrganisationInterface) => this._organisationCachedService.getOrganisationRosters(organisation.id).subscribe()),
        catchError(x => of(x))
      );

    const rosters$ = this._rosterDispatcher.getOrganisationRostersAsManager()
      .pipe(
        filter((x: Array<RosterInterface>) => x !== null && x.length > 0),
        tap((rosters) => {

        }),
        catchError(x => of(x))
      );

    let organisationProfileAndRosters = combineLatest(
      organisation$,
      profile$,
      rosters$
    )
      .pipe(
        map(values => {
          return <InitializedRosters>{
            organisation: values[0] as OrganisationInterface,
            user: values[1] as UserInterface,
            rosters: values[2] as RosterInterface[]
          };
        }),
        catchError(x => of(x))
      )
      .subscribe((res: InitializedRosters) => {
        // Does it make any sense to store it?
        this.profile = res.user;
        this.organisation = res.organisation;
        // NB: checkOrganisationManager checks if your rights are GREATERT THAN the ones you provide
        this.canAddNewRoster =
          this._permissionService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL.Associate);
        this.amIOrganisationManager = this.canAddNewRoster;
        // Take only the rosters where the user is Manager
        this.rosters = res.rosters.filter(roster => {
          let myRosters = res.user.roster_managers.map(x => x.roster.id);
          return myRosters.includes(roster.id);
        });

        this.loadedRosters = true;

        this.rosters.forEach((x: RosterInterface) => {
          let canEdit = this._rightService.isRosterManagerRight(this.profile, x.id, ROSTER_MANAGER_RIGHTS_LEVEL.Supervisor);
          this.canEditRoster[x.id] = canEdit;
        });

        // this._organisationCachedService.getOrganisationMembers(this.organisation.id, {}).subscribe();
      },
        error => {
          this._loggerService.error(error);
        });
    this.subscriptions.push(organisationProfileAndRosters);
    // This is to select by force the "No roster" roster at the beginning
    this.changeRoster();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  handleClickEditOrganisation() {
    let modalRef = this._modalService.open(EditOrganisationModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.orgaId = this.organisation.id;
    modalRef.componentInstance.onDelete.subscribe(
      ($e) => { this.showToastOrganisationDeleted(); }
    );
    modalRef.componentInstance.onEdit.subscribe(
      ($e) => { this.showToastOrganisationEdited(); }
    );
  }

  showToastOrganisationDeleted() {
    this._toastService.show("organisationDeleteToast");
  }

  showToastOrganisationEdited() {
    this._toastService.show("organisationEditToast");
  }

  createRoster() {
    let modalRef = this._modalService.open(RosterModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.organisation = this.organisation.id;
    modalRef.result
      .then((newRoster: RosterInterface) => {
        // this.canEditRoster[]
      })
      // TODO: handle error
      .catch();

  }

  changeRoster(roster?: RosterInterface) {
    if (!roster) {
      roster = RosterInterface.getNoRosterInstance();
    }
    this._dashboardPage.selectRoster(roster);
  }

  checkOrganisationManager() {
    return (this.organisation)
      ? this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["NotManager"])
      : false;
  }

  checkOrganisationAssociate() {
    return (this.organisation)
      ? this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["Associate"])
      : false;
  }

  checkRosterManager(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }

  checkRosterGuest(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  handleClickEditRoster(event: Event, rosterId) {
    event.stopPropagation();
    let modalRef = this._modalService.open(EditRosterModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.organisationId = this.organisation.id;
    modalRef.componentInstance.rosterId = rosterId;
    modalRef.componentInstance.onDelete.subscribe(($e) => {
      this.showToastRosterDeleted();
      this.changeRoster();
    });
    modalRef.componentInstance.onEdit.subscribe(($e) => {
      this.showToastRosterEdited();
    });
  }



  showToastRosterDeleted() {
    this._toastService.show("rosterDeleteToast");
  }

  showToastRosterEdited() {
    this._toastService.show("rosterEditToast");
  }

}
