import { Component, Input, OnInit } from '@angular/core';

// Service
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../services/utils/toast.service';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { ProfilePhoneInterface } from '../../../../model/profile-phone.interface';

@Component({
  selector: 'profile-phones',
  templateUrl: './profile-phones.component.html',
  styleUrls: ['./profile-phones.component.scss']
})


export class ProfilePhonesComponent implements OnInit {

  @Input() phones: any[];

  public prefixes: any;    // Country indicators
  public type: number; // Type (Cellphone / Fix etc...)
  public prefixe: any;    // Country code of the number
  public phoneNumber: any;    // Phone number
  public phoneNumbers: ProfilePhoneInterface[];    // List of all user's phone numbers
  public loading = false;
  public modalRef;
  constructor(
    private _profileService: ProfileService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    private _profileDispatcher: ProfileDispatcherService
  ) {
    this.prefixes = [];
    this.type = 1;
    this.prefixe = { code: 33, name: "France", iso2: "FR" };
    this.phoneNumber = '';
    this.phoneNumbers = [];
  }

  ngOnInit() {
    // Subscribe to get phones
    this.getPrefixes();
    if (!this.phones) {
      this._profileDispatcher.getUserPhones()
        .subscribe(res => {
          this.phoneNumbers = res;
          this._loggerService.log("MY PHONES", this.phoneNumbers);
        });

      // get phones immediately
      this._profileService.getMyPhones();
    } else {
      this.phoneNumbers = this.phones;
    }
  }

  getPrefixes() {
    this._profileService.getPrefixes()
      .subscribe(res => {
        Object.keys(res).forEach(key => {
          this.prefixes.push({
            code: res[key].code,
            name: res[key].name,
            iso2: key
          });
          this.prefixes.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        });
      });
  }

  /**
   * TODO: delete if not facing any problem
   */
  getMyPhones() {
    this._profileDispatcher.getUserPhones()
      .subscribe(res => {
        this.phoneNumbers = res;
        this._loggerService.log("MY PHONES", this.phoneNumbers);
      });
  }

  showAddPhone(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  addPhone(type, iso, phoneNumber) {
    if (phoneNumber.match(/^[0-9]+$/) === null) {
      // this._toastService.show("wrongEmailErrorToast", "show");
      this._toastService.show("phoneErrorToast", "show", 3000);
      return;
    }
    this.loading = true;
    this._profileService.addPhone({ type: type, country: iso, number: phoneNumber })
      .subscribe(res => {
        this.showToastAdd();
        this.loading = false;
        this.modalRef.close();
      }, err => {
        this.loading = false;
        this.modalRef.close();
        this.showErrorToast();
      });
  }

  deletePhone(phoneNumberId) {
    this._profileService.deletePhone(phoneNumberId)
      .subscribe(res => {
        this.showToastDeleted();
      });
  }

  showToastDeleted() {
    this._toastService.show("phoneDeleteToast", "show", 3000);
  }

  showToastCancel() {
    this._toastService.show("phoneCancelToast", "show", 3000);
  }

  showErrorToast() {
    this._toastService.show("phoneErrorToast", "show", 3000);
  }

  showToastAdd() {
    this._toastService.show("phoneAddToast", "show", 3000);
  }

}
