/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "primeng/components/inputtextarea/inputtextarea";
import * as i4 from "@angular/common";
import * as i5 from "./profile-user.component";
import * as i6 from "../../../../../services/utils/logger.service";
import * as i7 from "../../../../../services/utils/toast.service";
import * as i8 from "../../../../../services/api/profile/profile.api";
import * as i9 from "../../../../../services/api/profile/profile.dispatcher";
var styles_ProfileUserComponent = [i0.styles];
var RenderType_ProfileUserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileUserComponent, data: {} });
export { RenderType_ProfileUserComponent as RenderType_ProfileUserComponent };
function View_ProfileUserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.firstname; var currVal_1 = _co.profile.lastname; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ProfileUserComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-pencil custom-edit-icon"]], null, null, null, null, null))], null, null); }
function View_ProfileUserComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "information"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.aboutMe; _ck(_v, 2, 0, currVal_0); }); }
function View_ProfileUserComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "information"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "textarea", [["autoResize", "true"], ["pInputTextarea", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null], [1, "rows", 0], [1, "cols", 0]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (i1.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).onBlur($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (i1.ɵnov(_v, 7).onKeyup($event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = ((_co.aboutMe = $event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 344064, null, 0, i3.InputTextarea, [i1.ElementRef], { autoResize: [0, "autoResize"], rows: [1, "rows"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_14 = _co.aboutMe; _ck(_v, 4, 0, currVal_14); var currVal_15 = "true"; var currVal_16 = 3; _ck(_v, 7, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; var currVal_7 = true; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = i1.ɵnov(_v, 7).filled; var currVal_12 = i1.ɵnov(_v, 7).rows; var currVal_13 = i1.ɵnov(_v, 7).cols; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
function View_ProfileUserComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ui-g-12 ui-md-12 save-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn emalsys_button red"], ["name", "button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disableEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn emalsys_button green"], ["name", "button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, null); }
export function View_ProfileUserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "shadow"], ["id", "profile-user-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileUserComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileUserComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "profile-user-component-infos"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileUserComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileUserComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileUserComponent_5)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["id", "aboutMeUpdateToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your information has been updated"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.editEnabled && !_co.profile.external); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.editEnabled; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.editEnabled; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.editEnabled; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ProfileUserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-user", [], null, null, null, View_ProfileUserComponent_0, RenderType_ProfileUserComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProfileUserComponent, [i6.LoggerService, i7.ToastService, i8.ProfileService, i9.ProfileDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileUserComponentNgFactory = i1.ɵccf("profile-user", i5.ProfileUserComponent, View_ProfileUserComponent_Host_0, { profile: "profile" }, {}, []);
export { ProfileUserComponentNgFactory as ProfileUserComponentNgFactory };
