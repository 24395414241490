import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name:'searchList'
})

export class SearchListPipe {
  transform(items: any[], searchText: string): any[] {
    if(!items) {
      return [];
    }
    if(!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.name.toString().toLowerCase().includes(searchText);
    });
  }
}
