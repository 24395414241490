/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-banner.component";
var styles_AdministrationBannerComponent = [i0.styles];
var RenderType_AdministrationBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationBannerComponent, data: {} });
export { RenderType_AdministrationBannerComponent as RenderType_AdministrationBannerComponent };
export function View_AdministrationBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the banner management page in progress...\n"]))], null, null); }
export function View_AdministrationBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-banner", [], null, null, null, View_AdministrationBannerComponent_0, RenderType_AdministrationBannerComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationBannerComponent, [], null, null)], null, null); }
var AdministrationBannerComponentNgFactory = i1.ɵccf("administration-banner", i2.AdministrationBannerComponent, View_AdministrationBannerComponent_Host_0, {}, {}, []);
export { AdministrationBannerComponentNgFactory as AdministrationBannerComponentNgFactory };
