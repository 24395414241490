import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs as importedSaveAs } from "file-saver";
import { DocumentService } from '../../../../../../services/api/document.service';
import { OrganisationCompliancesService } from '../../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api';
import { RosterCompliancesService } from '../../../../../../services/api/roster/dependencies/compliances/roster-compliances.api';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../../services/utils/toast.service';
// Data
import { COMPLIANCE_CODE } from '../../../../../app.constants';
import { AddDocumentCompliancesModalComponent } from '../../../../shared/modals/add-document-compliances-modal/add-document-compliances-modal.component';


@Component({
  selector: 'roster-compliances',
  templateUrl: './roster-compliances.component.html',
  styleUrls: ['./roster-compliances.component.scss']
})


export class RosterCompliancesComponent implements OnInit, OnChanges {

  @Input() rosterId: number;
  @Input() rosterStatus: string;
  public downloadingFile = false;
  public rosterFilledCompliances: any;
  public rosterEmptyCompliances: any;
  public orgaFilledCompliances: any;
  public orgaEmptyCompliances: any;

  constructor(
    private _documentService: DocumentService,
    private _rosterCompliancesService: RosterCompliancesService,
    private _organisationCompliances: OrganisationCompliancesService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
  ) {
    this.rosterFilledCompliances = [];
    this.rosterEmptyCompliances = [];
    this.orgaFilledCompliances = [];
    this.orgaEmptyCompliances = [];
  }

  ngOnInit() {
    this.getCompliance();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rosterId && changes.rosterId.currentValue !== changes.rosterId.previousValue) {
      this.getCompliance();
    }
  }

  getCompliance() {

    this._rosterCompliancesService.getCompliances(this.rosterId).subscribe(res => {
      let result = res;
      this.rosterFilledCompliances = result[0];
      this.rosterEmptyCompliances = result[1];
      this.orgaFilledCompliances = result[2];
      this.orgaEmptyCompliances = result[3];
      this._loggerService.log("COMPLIANCES : ", result);

    });
  }

  uploadComplianceDocumentModal(complianceId, context) {
    let modalRef = this._modalService.open(AddDocumentCompliancesModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
    modalRef.componentInstance.complianceId = complianceId;
    modalRef.componentInstance.context = context;
    modalRef.componentInstance.onAdd.subscribe(($e) => {
      this.getCompliance();
      this.showToastUploadComplianceDocument();
    });
  }

  deleteRosterComplianceDocument(complianceId) {
    this._rosterCompliancesService.deleteComplianceDocument(complianceId).subscribe(res => {
      this.showToastDeleteComplianceDocument();
      this.getCompliance();
    });
  }

  deleteOrganisationComplianceDocument(complianceId) {
    this._organisationCompliances.deleteComplianceDocument(complianceId).subscribe(res => {
      this.showToastDeleteComplianceDocument();
      this.getCompliance();
    });
  }

  downloadDoc(document) {
    this._loggerService.log("Doc ID to download: ", document.id);
    this.downloadingFile = true;
    this._documentService.documentDownload(document.id).subscribe(response => {
      this.downloadingFile = false;
      importedSaveAs(response, document.title);
      this._loggerService.log("Blob received: ", response);
    }, error => {
      this.downloadingFile = false;
      this._loggerService.error("Failed to download the file: ", error);
    });
  }

  setLevel(member) {
    return COMPLIANCE_CODE.filter(level => level.value === member.level)[0].label;
  }

  showToastDeleteComplianceDocument() {
    this._toastService.show("deleteComplianceDocumentToast", "show", 3000);
  }
  showToastUploadComplianceDocument() {
    this._toastService.show("uploadComplianceDocumentToast", "show", 3000);
  }

}
