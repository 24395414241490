/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-features-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-features-users.component";
var styles_AdministrationFeaturesUsers = [i0.styles];
var RenderType_AdministrationFeaturesUsers = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationFeaturesUsers, data: {} });
export { RenderType_AdministrationFeaturesUsers as RenderType_AdministrationFeaturesUsers };
export function View_AdministrationFeaturesUsers_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the users features mangement page in progress...\n"]))], null, null); }
export function View_AdministrationFeaturesUsers_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-features-users", [], null, null, null, View_AdministrationFeaturesUsers_0, RenderType_AdministrationFeaturesUsers)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationFeaturesUsers, [], null, null)], null, null); }
var AdministrationFeaturesUsersNgFactory = i1.ɵccf("administration-features-users", i2.AdministrationFeaturesUsers, View_AdministrationFeaturesUsers_Host_0, {}, {}, []);
export { AdministrationFeaturesUsersNgFactory as AdministrationFeaturesUsersNgFactory };
