/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-newsletters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-newsletters.component";
var styles_AdministrationNewslettersComponent = [i0.styles];
var RenderType_AdministrationNewslettersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationNewslettersComponent, data: {} });
export { RenderType_AdministrationNewslettersComponent as RenderType_AdministrationNewslettersComponent };
export function View_AdministrationNewslettersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the newletters management page in progress...\n"]))], null, null); }
export function View_AdministrationNewslettersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-newsletter", [], null, null, null, View_AdministrationNewslettersComponent_0, RenderType_AdministrationNewslettersComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationNewslettersComponent, [], null, null)], null, null); }
var AdministrationNewslettersComponentNgFactory = i1.ɵccf("administration-newsletter", i2.AdministrationNewslettersComponent, View_AdministrationNewslettersComponent_Host_0, {}, {}, []);
export { AdministrationNewslettersComponentNgFactory as AdministrationNewslettersComponentNgFactory };
