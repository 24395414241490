import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Data
import { URL_API_EMALSYS } from '../../environments/environment';
// Services
import { HttpService } from './http.service';

@Injectable()
export class DocumentationService {

  // Variable declarations

  // http request options
  public options: any;

  constructor(
    private _httpService: HttpService,
    private http: HttpClient
  ) {
    this.options = this._httpService.createRequestOptions();
  }

  // Methods

  // Retrieve the documentation data page
  public getDocumentation(): any {
    return this.http.get(URL_API_EMALSYS + '/administration/api/doc/', this.options);
  }



}
