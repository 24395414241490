import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RosterInterface } from '../../../app/model/roster.interface';
import { UserInterface } from '../../../app/model/user.interface';
import { Dispatcher } from '../../dispatcher.service';

@Injectable()
export class RostersDispatcherService extends Dispatcher {

    private organisationRosters$ = new  BehaviorSubject<RosterInterface[]>(null);
    // We can pass both the roster information themselves and it's id
    private selectedRoster$ = new  BehaviorSubject<RosterInterface>(RosterInterface.getNoRosterInstance());
    private selectedRosterId$ = new  BehaviorSubject<number>(null);
    private joinableRosters$ = new  BehaviorSubject<RosterInterface[]>(null);
    private rosterMembers$ = new BehaviorSubject<UserInterface[]>(null);
    private organisationRostersAsResponder$ = new BehaviorSubject<Array<RosterInterface[]>>(null);

    constructor() {
        super();
    }

    public getOrganisationRostersAsManager(): Observable<any[]> {
        return this.pipeNotNull(this.organisationRosters$);
    }

    public getOrganisationRostersAsResponder(): Observable<any[]> {
        return this.pipeNotNull(this.organisationRostersAsResponder$);
    }

    public publishOrganisationRostersAsResponder(rosters: Array<RosterInterface[]>) {
        this.organisationRostersAsResponder$.next(rosters);
    }
    public publishOrganisationRosters(rosters: RosterInterface[]) {
        this.organisationRosters$.next(rosters);
    }

    // This can actually be null, because you can select no roster
    // at all
    public getSelectedRoster(): Observable<RosterInterface> {
        return this.pipeNotNull(this.selectedRoster$);
    }

    /**
     * @param roster Roster object or roster's id
     */
    public nextSelectedRoster(roster: RosterInterface | number) {
        if (typeof roster === 'number') {
            let selectedRoster = this.organisationRosters$.value.find(x => x.id === roster);
            if (selectedRoster) {
                this.selectedRoster$.next(selectedRoster);
            }
        } else {
            this.selectedRoster$.next(roster);
        }
    }

    /**
     * I can always forward only the roster itself and take only the id if needed
     * @param rosterId id of the roster
     */
    public nextSelectedRosterId(rosterId: number) {
        this.selectedRosterId$.next(rosterId);
    }

    public getJoinableRosters(): Observable<RosterInterface[]> {
        return this.pipeNotNull(this.joinableRosters$);
    }

    public nextJoinableRosters(rosters: RosterInterface[]) {
        this.joinableRosters$.next(rosters);
    }

    public getRosterMembers(): Observable<UserInterface[]> {
        return this.pipeNotNull(this.rosterMembers$);
    }

    public nextRosterMembers(members: UserInterface[]) {
        this.rosterMembers$.next(members);
    }

    public getSelectedRosterAsScalar() {
        return this.selectedRoster$.getValue();
    }
}
