import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';

// Routing
import { AdministrationRouting } from './administration.routing';

// Modules
import { SharedModule   } from '../../shared.module';

// Components
import { AdministrationComponent              } from './administration.component';
import { AdministrationOrganisationsComponent } from './administration-organisations/administration-organisations.component';
import { AdministrationRostersComponent       } from './administration-rosters/administration-rosters.component';
import { AdministrationUsersComponent         } from './administration-users/administration-users.component';
import { AdministrationAuthenticationComponent} from './administration-authentications/administration-authentications.component';
import { AdministrationRightsComponent        } from './administration-rights/administration-rights.component';
import { AdministrationBannerComponent        } from './administration-banner/administration-banner.component';
import { AdministrationNewslettersComponent   } from './administration-newsletters/administration-newsletters.component';
import { AdministrationAlertsComponent        } from './administration-alerts/administration-alerts.component';
import { AdministrationSkillsComponent        } from './administration-skills/administration-skills.component';
import { AdministrationFeaturesUsers          } from './administration-features-users/administration-features-users.component';
import { AdministrationFeaturesManagers       } from './administration-features-managers/administration-features-managers.component';
import { AdministrationDocumentationComponent } from './administration-documentation/administration-documentation.component';


@NgModule({

  imports: [
    // Routing
    AdministrationRouting,
    // Angular Modules
    CommonModule,
    // Feature Modules
    SharedModule,
    NgbModule
  ],
  declarations: [
    AdministrationComponent,
    AdministrationOrganisationsComponent,
    AdministrationRostersComponent,
    AdministrationUsersComponent,
    AdministrationAuthenticationComponent,
    AdministrationRightsComponent,
    AdministrationBannerComponent,
    AdministrationNewslettersComponent,
    AdministrationAlertsComponent,
    AdministrationSkillsComponent,
    AdministrationFeaturesUsers,
    AdministrationFeaturesManagers,
    AdministrationDocumentationComponent,
  ],
  providers: [

  ]

})

export class AdministrationModule {}
