export class RosterInterface {

    public static readonly CACHE = {
        JOINABLE_ROSTERS: 'current_user_joinable_rosters',
        ROSTER_INFORMATION: 'roster_information_',
        ORGANISATION_ROSTERS: 'organisation_rosters_',
        ROSTER_MEMBERS: 'roster_members_',
    };

    static readonly NO_ROSTER = -1;

    private static noRosterInstance = null;

    /**
    * Roster id
    * @type {number}
    **/
    id: number;
    /**
    * Roster name
    * @type {string}
    **/
    name: string;
    /**
    * Roster description
    * @type {string}
    **/
    description: string;
    /**
    * Roster contact name
    * @type {string}
    **/
    contactName: string;
    /**
    * Roster contact email
    * @type {string}
    **/
    contactEmail: string;
    /**
    * Roster private
    * @type {boolean}
    **/
    private: boolean;
    /**
    * Roster website
    * @type {string}
    **/
    website: string;
    /**
    * Roster facebook
    * @type {string}
    **/
    facebook: string;
    /**
    * Roster twitter
    * @type {string}
    **/
    twitter: string;
    /**
    * Roster linkedIn
    * @type {string}
    **/
    linkedIn: string;
	/**
	* Roster creation date
	* @type {Date}
	**/
    creationDate: Date;
    /**
    * Roster global
    * @type {number}
    **/
    global: number;
    /**
    * Roster open
    * @type {boolean}
    **/
    open: boolean;
    /**
    * Roster organisation
    * @type {any}
    **/
    organisation: any;

    static getNoRosterInstance(): RosterInterface {
        if (RosterInterface.noRosterInstance === null) {
            RosterInterface.noRosterInstance = new RosterInterface();
            RosterInterface.noRosterInstance.id = RosterInterface.NO_ROSTER;
        }

        return RosterInterface.noRosterInstance;
    }
}
