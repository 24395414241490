import { Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'niceDateDetailed'
})
@Injectable()

export class NiceDateDetailedPipe {

  transform(value) {
    let day   = new Date(value.slice(0, 10)).getDate();
    let month = new Date(value.slice(0, 10)).getMonth();
    let year  = new Date(value.slice(0, 10)).getFullYear();

    let niceDay = day;

    let niceMonth = (month === 0)  ? "January"   :
                    (month === 1)  ? "February"  :
                    (month === 2)  ? "March"     :
                    (month === 3)  ? "April"     :
                    (month === 4)  ? "May"       :
                    (month === 5)  ? "June"      :
                    (month === 6)  ? "July"      :
                    (month === 7)  ? "August"    :
                    (month === 8)  ? "September" :
                    (month === 9)  ? "October"   :
                    (month === 10) ? "November"  :
                    (month === 11) ? "December"  :
                    "";
    let niceYear = year.toString();

    let date = niceDay + " " + niceMonth + " " + niceYear;
    return date;
  }

}
