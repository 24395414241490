import { interval } from 'rxjs';
import { ToastService, ToastInformations } from './../../../../../services/utils/toast.service';
import { Component, OnInit } from '@angular/core';

import { debounceTime, filter } from 'rxjs/operators';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  readonly RED = ToastInformations.RED;
  readonly GREEN = ToastInformations.GREEN;

  public show = false;
  public toast: ToastInformations;

  private timeoutId;

  constructor(
    private _toastService: ToastService
  ) { }

  ngOnInit() {
    this._toastService.getToasts()
      .subscribe(
        (res) => {
          // Close any previous toast
          if (this.show) {
            this.show = false;
          }
          this.toast = res;
          this.show = true;
          this.timeoutId = setTimeout(
            () => this.show = false,
            res.timer !== undefined && typeof res.timer === 'number' ? res.timer : 3000
          );
        });
  }


}
