import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roster-preloader-compliances',
  templateUrl: './roster-preloader-compliances.component.html',
  styleUrls: ['./roster-preloader-compliances.component.scss']
})
export class RosterPreloaderCompliancesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
