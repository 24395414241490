import * as moment from 'moment';

export class Calendar {
   selectedMonth: any;
   today: any;
   days: any;

  constructor() {
    this.today = moment().utc().startOf('day');
    this.selectedMonth = moment().utc().startOf('day');
    this.days = this.createMonth(this.selectedMonth);

  }

  createMonth(month) {
    let firstDay = moment(month).startOf('M');
    // firstDay = firstDay.add(1, 'd');
    let days = Array.apply(null, {length: month.daysInMonth()})
                    .map(Number.call, Number)
                    .map((n) => {
                      return moment(firstDay).add(n, 'd');
                    });
    return days;
  }

  currentDay(day): boolean {
    day = moment(day);
    return !day ? false : moment().format("L") === day.format("L");
  }

  invalidDay(day): boolean {
    return day ? false : true;
  }

  getSelectedMonth() {
    return this.selectedMonth.format("MMMM");
  }

  getSelectedYear() {
    return this.selectedMonth.format("YYYY");
  }

  nextMonth() {
    let newMonth = this.selectedMonth.add(1, "M");
    this.days = this.createMonth(newMonth);

  }

  previousMonth() {
    let newMonth = this.selectedMonth.subtract(1, "M");
    this.days = this.createMonth(newMonth);

  }

  getToday(): any {
    return null;
  }

  getNewDay(addedDays: number): any {
    return moment().add('d', addedDays).format('YYYY-MM-DD');
  }

  getLastDayOfMonth(): any {
    return moment().endOf("M").format('YYYY-MM-DD');
  }

  checkMonthValidity(): boolean {
    return this.selectedMonth.format("M") === moment().format("M");
  }
}
