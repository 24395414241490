<div class="dashboard-preloader-news">
  <div class="dashboard-preloader-news-map shadow">
    <div class="dashboard-preloader-news-map-container"></div>
  </div>
  <!-- <div class="dashboard-preloader-news-feed shadow">
    <div class="dashboard-preloader-news-feed-block"></div>
    <div class="dashboard-preloader-news-feed-block"></div>
    <div class="dashboard-preloader-news-feed-block"></div>
    <div class="dashboard-preloader-news-feed-block"></div>
  </div> -->
</div>