import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UploadItem } from 'angular2-http-file-upload';
import { Uploader } from 'angular2-http-file-upload';
import { saveAs as importedSaveAs } from "file-saver";


import { URL_API_EMALSYS } from '../../../../../environments/environment';

// Services
import { DocumentService } from '../../../../../services/api/document.service';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { WSSEService } from '../../../../../services/security/wsse.service';
import { ToastService } from '../../../../../services/utils/toast.service';
import { LoggerService  } from '../../../../../services/utils/logger.service';

// Modal
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from "../../../../../services/api/user.service";

@Component({
  selector: 'profile-documents',
  templateUrl: './profile-documents.component.html',
  styleUrls: ['./profile-documents.component.scss']
})


export class ProfileDocumentsComponent implements OnInit {

  @Input() userId: number;

  public documents: any[];
  public selectedFile: File;
  public downloadingFile = false;
  public uploadingFile = false;
  public errorMessage: string;
  public titleFile: string;
  public extensionFile: string;
  public allDocsModal: NgbActiveModal;
  public uploadModal: NgbActiveModal;

  @ViewChild('fileInput') inputFile: any;

  constructor(
    private _documentService: DocumentService,
    private _profileService: ProfileService,
    private _userService: UserService,
    private _modalService: NgbModal,
    public _uploaderService: Uploader,
    private _loggerService: LoggerService,
    private _wsseService: WSSEService,
    private _toastService: ToastService
  ) {
  }

  ngOnInit() {
    if (!this.userId) {
      this.getMyDocs();
    } else {
      this.getDocs();
    }
  }

  getMyDocs() {
    this._profileService.getMyDocuments()
      .subscribe(res => {
        this.documents = res;
        this._loggerService.log("MY DOCUMENTS", this.documents);
      }, err => {
        this._loggerService.error("Can't get documents: ", err);
      });
  }

  getDocs() {
    this._userService.getDocuments(this.userId)
      .subscribe((res: any) => {
        this.documents = res;
      }, err => {
        this.errorMessage = err;
        this.showToastError();
      });
  }


  /////////////////////////////////////////////////////////////
  /////////////////////// FILE UPLOAD /////////////////////////
  /////////////////////////////////////////////////////////////

  showAllDocsModal(content) {
    this.allDocsModal = this._modalService.open(content, { windowClass: 'emalsys-modal', size: 'lg', backdrop: 'static' });
  }

  showUploadModal(content) {
    this.uploadModal = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    let name: string;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
      let regExp = new RegExp('\.[0-9a-z]+$', 'i');
      this.extensionFile = regExp.exec(this.selectedFile.name)[0];
    }
  }

  uploadDoc() {
    let uploadItem = new UploadItem();
    uploadItem.url = URL_API_EMALSYS + "/me/docs";
    uploadItem.headers = { 'x-wsse': this._wsseService.getHeaderValue() };
    if (this.titleFile) {
        uploadItem.formData = { title: this.titleFile + this.extensionFile };
    } else {
        uploadItem.formData = { title: this.selectedFile.name };
    }

    uploadItem.file = this.selectedFile;
    // uploadItem.formData['prevention_id'] = this.id;
    // uploadItem.formData['private'] = this.isNewFilePrivate;
    this.submitDoc(uploadItem);
  }

  submitDoc(itemToUpload: UploadItem) {
    this.uploadingFile = true;

    this._uploaderService.onSuccessUpload = (item, response, status, headers) => {
      // success callback
      this.uploadingFile = false;
      this.uploadModal.close();
      this.showToastAdd();
      this._loggerService.log("Success uploading file: ", item, response, status, headers);
      //  this.inputFile.nativeElement.value=""; // reset the input element
    };
    this._uploaderService.onErrorUpload = (item, response, status, headers) => {
      // error callback
      this.uploadingFile = false;
      this.showToastError();
      this.uploadModal.close();
      this.errorMessage = JSON.parse(response)[0].message;
      this._loggerService.error("Error uploading file: ", item, response, status, headers);
    };
    this._uploaderService.onCompleteUpload = (item, response, status, headers) => {
      // complete callback, called regardless of success or failure
      this._loggerService.log("Complete uploading file: ", item, response, status, headers);
      this.getMyDocs();

    };
    this._uploaderService.upload(itemToUpload);
  }

  downloadDoc(document) {
    this.downloadingFile = true;
    this._documentService.documentDownload(document.id).subscribe(response => {
      this.downloadingFile = false;
      importedSaveAs(response, document.title);
    }, error => {
      this.downloadingFile = false;
      this._loggerService.error("Failed to download the file: ", error);
    });
  }

  deleteDoc(event, doc) {
    this.downloadingFile = true;
    event.stopPropagation();
    this._profileService.documentDelete(doc.id)
      .subscribe(res => {
        this._loggerService.log("Document has been deleted: ", doc.name);
        this.showToastDeleted();
        this.getMyDocs();
        this.downloadingFile = false;
      }, err => {
        this._loggerService.error("Error deleting document: ", doc.name);
        this.downloadingFile = false;
      });
  }


  /// Toasts
  showToastDeleted() {
    this._toastService.show("docDeleteToast", "show", 3000);
  }

  showToastError() {
    this._toastService.show("docErrorToast", "show", 8000);
  }

  showToastAdd() {
    this._toastService.show("docAddToast", "show", 3000);
  }


}
