/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-rosters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/component-roster/component-roster.component.ngfactory";
import * as i3 from "../components/component-roster/component-roster.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/spinner/spinner.component.ngfactory";
import * as i7 from "../../../shared/spinner/spinner.component";
import * as i8 from "./dashboard-rosters.component";
import * as i9 from "../../../../../services/api/roster/roster.cached";
import * as i10 from "../../../../../services/utils/logger.service";
import * as i11 from "@ng-bootstrap/ng-bootstrap";
import * as i12 from "../../../../../services/utils/toast.service";
import * as i13 from "../../../../../services/api/roster/rosters.dispatcher";
import * as i14 from "../../../../../services/api/roster/dependencies/membership/roster-membership.api";
var styles_DashboardRostersComponent = [i0.styles];
var RenderType_DashboardRostersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardRostersComponent, data: {} });
export { RenderType_DashboardRostersComponent as RenderType_DashboardRostersComponent };
function View_DashboardRostersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-roster", [["class", "rosters-roster"]], null, null, null, i2.View_ComponentRoster_0, i2.RenderType_ComponentRoster)), i1.ɵdid(1, 49152, null, 0, i3.ComponentRoster, [], { roster: [0, "roster"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "member"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardRostersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-roster", [["class", "rosters-roster"]], null, null, null, i2.View_ComponentRoster_0, i2.RenderType_ComponentRoster)), i1.ɵdid(1, 49152, null, 0, i3.ComponentRoster, [], { roster: [0, "roster"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "invited"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardRostersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-roster", [["class", "rosters-roster"]], null, null, null, i2.View_ComponentRoster_0, i2.RenderType_ComponentRoster)), i1.ɵdid(1, 49152, null, 0, i3.ComponentRoster, [], { roster: [0, "roster"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "request"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardRostersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-roster", [["class", "rosters-roster"]], null, null, null, i2.View_ComponentRoster_0, i2.RenderType_ComponentRoster)), i1.ɵdid(1, 49152, null, 0, i3.ComponentRoster, [], { roster: [0, "roster"], label: [1, "label"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "frozen"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardRostersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../../../../assets/images/not-yet-field.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This section is empty"]))], null, null); }
function View_DashboardRostersComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i4.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i4.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i4.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_DashboardRostersComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "upload-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "control-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a Roster"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "form-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "select", [["class", "form-control margin-b"], ["type", "select"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.rosterId = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.SelectControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(9, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_8)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.rosterId; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.joinableRosters; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DashboardRostersComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No roster to join"]))], null, null); }
function View_DashboardRostersComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i7.SpinnerComponent, [], null, null)], null, null); }
function View_DashboardRostersComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn emalsys_button green"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.joinSelectedRoster(_co.rosterId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Join"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.rosterId || _co.loading); _ck(_v, 1, 0, currVal_0); }); }
function View_DashboardRostersComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Join a roster"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_7)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_9)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_10)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_11)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.joinableRosters.length > 0); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.joinableRosters.length === 0); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.joinableRosters.length > 0); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_DashboardRostersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "shadow"], ["id", "dashboard-rosters-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Rosters"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "add-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.joinRoster(i1.ɵnov(_v, 17)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-plus custom-add-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "dashboard-rosters-component-rosters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_2)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_3)), i1.ɵdid(12, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_4)), i1.ɵdid(14, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardRostersComponent_5)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_DashboardRostersComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rostersMember; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.rostersInvited; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.rostersPending; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.rostersFrozen; _ck(_v, 14, 0, currVal_3); var currVal_4 = (_co.rostersMember.length == 0); _ck(_v, 16, 0, currVal_4); }, null); }
export function View_DashboardRostersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-responder-rosters", [], null, null, null, View_DashboardRostersComponent_0, RenderType_DashboardRostersComponent)), i1.ɵdid(1, 245760, null, 0, i8.DashboardRostersComponent, [i9.RosterService, i10.LoggerService, i11.NgbModal, i12.ToastService, i13.RostersDispatcherService, i14.RosterMembershipService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardRostersComponentNgFactory = i1.ɵccf("dashboard-responder-rosters", i8.DashboardRostersComponent, View_DashboardRostersComponent_Host_0, { organisation: "organisation" }, {}, []);
export { DashboardRostersComponentNgFactory as DashboardRostersComponentNgFactory };
