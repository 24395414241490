import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

// Interfaces
import { DeploymentOrderInterface } from '../../app/model/deployment-order.interface';

@Injectable()
export class DeploymentOrderService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  getDeploymentOffersByEvent(idAlert) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/deploymentorders?method=by_event&id=" + idAlert, options);
  }

  getDeploymentOffersByEventAndUsers(idAlert, usersId) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<DeploymentOrderInterface[]>(URL_API_EMALSYS + "/deploymentorders/users?event_id=" + idAlert + "&users_id=" + usersId, options);
  }

  validateDeploymentOffer(idDeploymentOffer) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/deploymentorders/" + idDeploymentOffer + "/validate", {}, options);
  }

  refuseDeploymentOffer(idDeploymentOffer) {
    let options = this._httpService.createRequestOptions();

    return this.http.put(URL_API_EMALSYS + "/deploymentorders/" + idDeploymentOffer + "/refuse", {}, options);
  }

  cancelDeploymentOffer(idDeploymentOffer) {
    let options = this._httpService.createRequestOptions();

    return this.http.put(URL_API_EMALSYS + "/deploymentorders/" + idDeploymentOffer + "/cancel", {}, options);
  }

  // Triggered when sending a deployment offer
  createOffer(body) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/deploymentorders", body, options);
  }

}
