import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { environment } from '../environments/environment';
// Components
import { AppComponent } from './app.component';
// Routing
import { AppRouting } from './app.routing';
// Modules
import { CoreModule } from './core.module';
import { ForbiddenComponent } from './error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './error-pages/notFound/notFound.component';
import { AdministrationModule } from './modules/administration/administration.module';
import { ManagerModule } from './modules/manager/manager.module';
import { AlertsManagerModule } from './modules/manager/organisation/alerts/alerts.module';
import { SettingsModule } from './modules/manager/settings/settings.module';
import { PublicPagesModule } from './modules/public/public-pages.module';
import { AlertsModule } from './modules/responder/organisation/alerts/alerts.module';
import { ResponderModule } from './modules/responder/responder.module';
import { ProfileModule } from './modules/shared/profile/profile.module';
import { ToastComponent } from './modules/shared/toast/toast/toast.component';
import { SharedModule } from './shared.module';
import { CurrentOrderDetailsComponent } from './modules/shared/members/modals/current-order-details/current-order-details.component';

@NgModule({
  imports: [
    // Routing
    AppRouting,
    // CarouselModule
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    // Feature modules
    CoreModule,
    SharedModule,
    PublicPagesModule,
    // AuthenticationModule,
    ResponderModule,
    ManagerModule,
    SettingsModule,
    ProfileModule,
    AdministrationModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    AlertsModule,
    AlertsManagerModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  declarations: [
    ForbiddenComponent,
    NotFoundComponent,
    AppComponent,
    ToastComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' }, Title,
  ],
  bootstrap: [AppComponent],
})


export class AppModule {

}
