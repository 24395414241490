import { BehaviorSubject } from "rxjs";
import { filter } from 'rxjs/operators';
export abstract class Dispatcher {

    protected pipeNotNull(subject: BehaviorSubject<any> ) {
        return subject.asObservable().pipe(
            // filter(x => !(x instanceof Array) ? x !== null : x.length > 0 )
            // Empty arrays MUST be accepted.
            filter(x => x !== null)
        );
    }

    /**
     * Useful?
     * @param subject
     * @param data
     */
    protected next<T>(subject: BehaviorSubject<T>, data: T) {
        return subject.next(data);
    }
}
