/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-alerts.component";
var styles_AdministrationAlertsComponent = [i0.styles];
var RenderType_AdministrationAlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationAlertsComponent, data: {} });
export { RenderType_AdministrationAlertsComponent as RenderType_AdministrationAlertsComponent };
export function View_AdministrationAlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This; is; the; alerts; management; page in progress; ...\n"]))], null, null); }
export function View_AdministrationAlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-alerts", [], null, null, null, View_AdministrationAlertsComponent_0, RenderType_AdministrationAlertsComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationAlertsComponent, [], null, null)], null, null); }
var AdministrationAlertsComponentNgFactory = i1.ɵccf("administration-alerts", i2.AdministrationAlertsComponent, View_AdministrationAlertsComponent_Host_0, {}, {}, []);
export { AdministrationAlertsComponentNgFactory as AdministrationAlertsComponentNgFactory };
