<!--Dashboard-alerts component-->
<div id="dashboard-alerts-component" class="shadow">
    <!--Title-->
  <div class="emalsys_section-title">
    <h2 i18n="@@AlertsTitle">My Alerts</h2>
  </div>
  <!--List of alerts-->
  <div class="dashboard-alerts-component-alerts">
    <component-alert [alert]='alert' *ngFor='let alert of alerts'>
    </component-alert>
    <div class="empty" *ngIf='alerts.length==0'>
      <img src="../../../../../assets/images/not-yet-field.png"/>
      <h2>This section is empty</h2>
    </div>
  </div>
</div>
