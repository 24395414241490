import { Injectable } from '@angular/core';
import { ApiCacherService } from './../../../../utils/api-cacher.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './../../../http.service';
import { URL_API_EMALSYS } from '../../../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class OrganisationCompliancesService {

    private ORGANISATION_COMPLIANCES = "organisation_compliances_"; // + organisationId

    private currentOrganisationId: number;

    constructor(
        private http: HttpClient,
        private _httpService: HttpService,
        private _apiCacher: ApiCacherService
    ) { }


    // ------------------------------------------------------------------------//
    // ----------------------------- COMPLIANCES (Manager side) ------------------------------//
    // ------------------------------------------------------------------------//


    getOrganisationCompliances(organisationId: number) {
        // TODO find a proper way
        this.currentOrganisationId = organisationId;
        return this._apiCacher.doWrapper(
            this.ORGANISATION_COMPLIANCES + organisationId,
            () => {
                let options = this._httpService.createRequestOptions();
                return this.http.get(URL_API_EMALSYS + "/organisations/" + organisationId + "/requirements", options);
            }
        ).pipe();
    }

    addOrganisationCompliance(organisationId: number, body) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/organisations/" + organisationId + "/requirements", body, options)
            .pipe(
                tap(res => this.refreshOrganisationCompliances(organisationId))
            );
    }
    // TODO understand this
    updateOrganisationCompliance(requirementId: number, body) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/organisations/requirements/" + requirementId, body, options)
            .pipe(
                tap(res => this.refreshOrganisationCompliances(this.currentOrganisationId))
            );
    }

    removeOrganisationCompliance(requirementId: number) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/organisations/requirements/" + requirementId, options)
            .pipe(
                tap(res => this.refreshOrganisationCompliances(this.currentOrganisationId))
            );
    }

    refreshOrganisationCompliances(organisationId: number) {
        this._apiCacher.removeIfPresent(this.ORGANISATION_COMPLIANCES + organisationId);
        this.getOrganisationCompliances(organisationId);
    }
    // ------------------------------------------------------------------------//
    // ----------------------------- COMPLIANCES (Member side)------------------------------//
    // ------------------------------------------------------------------------//

    addComplianceDocument(comId, docId) {
        let options = this._httpService.createRequestOptions();
        let body = JSON.stringify(docId);
        return this.http.post(URL_API_EMALSYS + "/organisations/requirements/" + comId + "/documents/" + docId, body, options);
    }

    deleteComplianceDocument(comId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/organisations/requirements/" + comId + "/documents", options);
    }
}
