import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';

// Services
import { LoggerService } from '../../../../../services/utils/logger.service';

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/utils/toast.service';
import { RosterService } from '../../../../../services/api/roster/roster.cached';
import { RostersDispatcherService } from '../../../../../services/api/roster/rosters.dispatcher';
import { RosterMembershipService } from '../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-responder-rosters',
  templateUrl: './dashboard-rosters.component.html',
  styleUrls: ['./dashboard-rosters.component.scss']
})


export class DashboardRostersComponent implements OnInit, OnDestroy {

  @Input() organisation: any;

  public rostersMember: any;
  public rostersInvited: any;
  public rostersPending: any;
  public rostersFrozen: any;
  public joinableRosters: any[] = [];
  public loading = false;
  public modalRef;

  private subscriptions: Subscription[] = [];

  constructor(
    private _rosterService: RosterService,
    private _loggerService: LoggerService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _rosterDispatcher: RostersDispatcherService,
    private _rosterMembership: RosterMembershipService
  ) {
  }

  ngOnInit() {
    this.rostersMember = [];
    this.rostersInvited = [];
    this.rostersPending = [];
    this.rostersFrozen = [];
      this.getRosters();
      this.getOrganisationRosters();
    let joinableRosters = this._rosterDispatcher.getJoinableRosters().subscribe(
      res => this.joinableRosters = res
    );

    this.subscriptions.push(joinableRosters);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(element => {
      element.unsubscribe();
    });
  }

  getRosters() {
    if (this.organisation) {
      this.subscriptions.push(
        this._rosterDispatcher.getOrganisationRostersAsResponder().subscribe(res => {
          this.rostersMember = res[0] !== undefined ? res[0] : [];
          this.rostersInvited = res[1] !== undefined ? res[1] : [];
          this.rostersPending = res[2] !== undefined ? res[2] : [];
          this.rostersFrozen = res[3]  !== undefined ? res[3] : [];
          this._loggerService.log("ROSTERS : ", res);
        })
      );
    }
  }

  getOrganisationRosters() {
    if (this.organisation) {
      this._rosterService.getJoinableRosters(this.organisation.id);
    }
  }

  joinRoster(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  joinSelectedRoster(id) {
    this.loading = true;
    this._rosterMembership.joinRoster(id).subscribe(res => {
      this._loggerService.log("Roster joined");
      this.loading = false;
      this.modalRef.close();
      // this.getRosters();
      this._rosterService.getJoinableRosters(this.organisation.id);
      // this.getOrganisationRosters();
      this._rosterService.getOrganisationRostersAsResponder(this.organisation.id);
      this.showToastJoinRequest();
    });
  }

  showToastJoinRequest() {
    this._toastService.show("joinRequestSendToast", "show", 3000);
  }
}
