import { Injector } from '@angular/core';
import { OrganisationInterface } from '../../app/model/organisation.interface';
export class CacheNotificationsService {
    constructor() {
        this.calls = {
            [OrganisationInterface.CACHE.ORGANISATION_ROSTERS]: {
                service: 'RosterService',
                method: 'refreshRosters'
            }
            // this._rosterService.refreshRosters.bind(this)
        };
    }
    static notifyCacheChange(cacheKey, ...args) {
        // CacheNotificationsService.socket.emit("cache", cacheKey, ...args);
    }
    subscribeToNotifications() {
        // This code has been used to resolve a certain problem
        // in a certain situation. Please don't do it at home.
        // CacheNotificationsService.socket.on('cache', (cacheKey: string, ...args: any[]) => {
        //     const service = this.calls[cacheKey]['service'];
        //     const provider = CacheNotificationsService.providers[service];
        //     const method = this.calls[cacheKey]['method'];
        //     const serviceInstantiated = CacheNotificationsService.injector.get(provider);
        //     let result = serviceInstantiated[method](...args);
        //     if (result instanceof Observable) {
        //         result.subscribe();
        //     }
        // });
    }
}
CacheNotificationsService.providers = {};
