import { Component, ElementRef, EventEmitter, Input, } from '@angular/core';
import { KeyValueDiffers, OnInit, Output, SimpleChanges, ViewChild, OnChanges, AfterViewChecked } from '@angular/core';
import { CacheService } from '../../../../../services/storage/cache.service';

import { FilterInterface } from '../../model/filter';
import { ChartDataLoaderService } from '../../services/chart-data-loader.service';
import { ChartRegistration, RegisteredItem } from '../../services/chart-registration.service';
import { ChartAxisClass, ChartIndicatorConfigClass, ChartInterface, ChartLegendClass, } from './chart.interface';
import { ChartModalConfigClass, ChartSchemeClass, ChartTitleClass } from './chart.interface';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, ChartInterface, OnChanges, AfterViewChecked {

  differ: any;
  @ViewChild('header') headerRef: ElementRef;

  public body = {};

  uniqId: string;

  // IMPLEMENTS ChartInterface
  @Input() public data: any = [];

  @Input() view: number[];

  @Input() header = false;

  scheme: ChartSchemeClass;
  @Input() title: ChartTitleClass;
  @Input() modalConfig: ChartModalConfigClass;
  @Input() axis: ChartAxisClass;
  legend: ChartLegendClass;


  @Input() filters: Array<FilterInterface> = [];
  oldFilters: Array<FilterInterface> = [];

  @Input() indicatorConfig: ChartIndicatorConfigClass;

  @Output() selectedChart = new EventEmitter<string>();

  @Input() menuOpen = false;
  @Input() public loader = false;
  public loaded = false;
  @Input() public noData = true;

  @Output() user_action = new EventEmitter<string>();

  @Input() selectedEvent;
  @Input() selectedRoster;
  @Input() frequence;
  public action = '';

  constructor(
    protected differs: KeyValueDiffers,
    public _cacheService: CacheService,
    protected chartRegistrationService: ChartRegistration,
    protected _chartDataLoaderService?: ChartDataLoaderService,
  ) {
    this.data = [];
    this.scheme = new ChartSchemeClass();
    this.title = new ChartTitleClass();
    this.axis = new ChartAxisClass();
    this.legend = new ChartLegendClass();
    this.modalConfig = new ChartModalConfigClass();
    this.indicatorConfig = new ChartIndicatorConfigClass();


    this.differ = this.differs.find({}).create();
  }

  ngOnInit() {

    // Visualisation
    this.scheme = {
      gradient: true,
      domain: [
        '#F0A202', '#0E1428', '#ff4514', '#009EAE', '#FF866F', '#772014', '#235789'
      ]
    };

    let header = document.getElementById("header");
    this.view = [360, 300];
    if (header) {
      this.view[0] = header.offsetWidth;
      this.view[1] = 360 - header.offsetHeight;
    }

    // Filters
    this.oldFilters = this.filters;

    // Registration
    this.chartRegistrationService.generateId(this);
    this.filters.forEach((filter: FilterInterface) => {
      this.chartRegistrationService.registerFilter(this, filter);
    });


    // Set Body with  and roster
    // Get organisation and roster associate with the current user like manager and get the current organisation
    let cachedUser = this._cacheService.get(CacheService.USER);
    let cachedOrganisation = this._cacheService.get(CacheService.CURRENT_ORGANISATION);

    let organisations = [];
    let rosters = [];

    if (cachedUser && cachedOrganisation) {
      let organisationManager = cachedUser.organisation_managers;

      // search organisationManager link to the user current
      organisationManager.forEach(element => {
        organisations.push(element.organisation.id);
      });

      // get the current organisation if the user is manager of its
      organisations.forEach(element => {
        if (element === cachedOrganisation.id) {
          this.body['organisationManagers'] = element;
        }
      });

      // get all rosters if the user is manager of its
      let rosterManager = cachedUser.roster_managers;
      rosterManager.forEach(element => {
        rosters.push(element.roster.id);
      });
    }

    // get the current organisation
    this.body['currentOrganisation'] = cachedOrganisation.id;
    this.body['roster'] = rosters;
    this.body['selectedEvent'] = this.selectedEvent;
    this.body['selectedRoster'] = this.selectedRoster;
    // Search filter associate with the chart

    if (!ChartRegistration.comparaisons.get(this.uniqId)) {
      ChartRegistration.associations
        .filter((item: RegisteredItem) => item.chartId === this.uniqId)
        .forEach((item: RegisteredItem) => {

          ChartRegistration.comparaisons.set(this.uniqId, true);
          this.body[item.referenceKey] = item.currentValue;
          if (!this.body[item.referenceKey]) {
            this.body[item.referenceKey] = this.frequence;
          }
        });
    }
    // Call data from the back
    let promise = this._chartDataLoaderService.load(this.indicatorConfig.idIndicator, this.body);
    if (promise) {
      promise.toPromise().then(data => {
        this.data = data;
        if (!this.data || this.data.length === 0) {
          this.noData = true;
          this.loader = false;
        } else {
          this.noData = false;
          if (this.indicatorConfig.type === 'nombre') {
            let lastDate = this.data[0]['date'].date;
            let newData: any = [];
            this.data.forEach(element => {
              if (element['date'].date > lastDate) {
                lastDate = element['date'].date;
              }
            });
            this.data.forEach(element => {
              if (element['date'].date === lastDate) {
                newData.push(element);
              }
            });
            this.data = newData;
          }

          if (this.indicatorConfig.type === 'courbe') {
            this.axis.yAxisLabel = this.data[0].series[0]['unity'];
          }
        }
      }, error => {
        throw new Error('Loading data failed');
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Verify if value change and if value for the chart are already compared
    this.body['selectedEvent'] = this.selectedEvent;
    this.body['selectedRoster'] = this.selectedRoster;
    if ((changes.selectedEvent && changes.selectedEvent.currentValue !== changes.selectedEvent.previousValue && changes.selectedEvent.currentValue !== -1)
      || (changes.selectedRoster && changes.selectedRoster.currentValue !== changes.selectedRoster.previousValue && changes.selectedRoster.currentValue !== -1)
      || (changes.frequence && changes.frequence.currentValue !== changes.frequence.previousValue)
    ) {

      // Search filter associate with the chart

      if (!ChartRegistration.comparaisons.get(this.uniqId)) {

        ChartRegistration.associations
          .filter((item: RegisteredItem) => item.chartId === this.uniqId)
          .forEach((item: RegisteredItem) => {

            ChartRegistration.comparaisons.set(this.uniqId, true);
            this.body[item.referenceKey] = item.currentValue;
          });
      }


      if (Object.keys(this.body).length > 3) {
        let promise = this._chartDataLoaderService.load(this.indicatorConfig.idIndicator, this.body);
        if (promise) {
          promise.toPromise().then(data => {
            this.data = data;

            if (!this.data || this.data.length === 0) {
              this.noData = true;
              this.loader = false;
            } else {
              this.noData = false;
              if (this.indicatorConfig.type === 'nombre') {
                let lastDate = this.data[0]['date'].date;
                let newData: any = [];
                this.data.forEach(element => {
                  if (element['date'].date > lastDate) {
                    lastDate = element['date'].date;
                  }
                });
                this.data.forEach(element => {
                  if (element['date'].date === lastDate) {
                    newData.push(element);
                  }
                });
                this.data = newData;
              }

              if (this.indicatorConfig.type === 'courbe') {
                this.axis.yAxisLabel = this.data[0].series[0]['unity'];
              }
            }

          });
        }

      }
      this.loader = false;
      this.loaded = true;
    }
  }

  ngAfterViewChecked() {

    /**
     *Allow a responsive chart
     */
    let header = document.getElementById("header");
    if (header && this.view && ((this.view[0] !== header.offsetWidth - 20) || (this.view[1] !== 360 - header.offsetHeight))) {
      this.view[0] = header.offsetWidth - 20;
      this.view[1] = 360 - header.offsetHeight;
    }

  }


  // Actions

  actionClicked(e: string) {
    this.action = e;
  }

  closeMenu(e) {
    this.menuOpen = false;
  }

  clearAction(e) {
    this.action = "";
  }
}
