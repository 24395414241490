<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Edit Roster</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>

<!--Modal body-->
<div class="modal-body">
  <div class="roster-properties">
    <label for="roster-name">Roster name *</label>
    <input id="roster-name" type="text" [(ngModel)]="roster.name" />
    <label for="contact-name">Contact name *</label>
    <input id="contact-name" type="text" [(ngModel)]="roster.contact_name" />
    <label for="contact-email">Contact email *</label>
    <input id="contact-email" type="email" [(ngModel)]="roster.contact_email" />
    <label for="roster-description">Description</label>
    <textarea id="roster-description" [(ngModel)]="roster.description"></textarea>
    <label for="roster-logo">Logo</label>
    <input id="roster-logo" type="file" (change)="fileChange($event)" />
    <label for="roster-privacy">Private</label>
    <div class="roster-private">
      <label class="private-switch">
          <input type="checkbox" [(ngModel)]="roster.private">
          <span class="slider round"></span>
      </label>
      <h6 class="private-label" *ngIf="roster.private">PRIVATE</h6>
      <h6 class="private-label" *ngIf="!roster.private">PUBLIC</h6>
    </div>
    <label for="roster-compliances">Compliances</label>
    <div id="roster-compliances">
      <table class="compliances-table">

        <tr class="table-head">
          <th>
            <h3>Title</h3></th>
          <th>
            <h3>Level</h3></th>
          <th class="header-edit-delete-create">
            <button *ngIf="!addActive && editActive==null" class="add-button" title="Add compliance" (click)="addActive = true">
                <i class="fa fa-plus custom-add-icon"></i>
              </button>
            </th>
        
        </tr>
        <tbody *ngIf="addActive">
        <tr>
          <td>
            <input class="compliance-name" type="text" [(ngModel)]="newTitleRequirement">
          </td>
          <td>
            <select [(ngModel)]="newLevelRequirement">
                  <option value=0>Optionnal</option>
                  <option value=1>Required</option>
                 </select>
          </td>

        </tr>
        <tr class="edit-delete ">
            <button class="btn emalsys_button green margin-r margin-b margin-t" [disabled]="!newTitleRequirement && !newLevelRequirement"(click)="addRosterCompliance(newTitleRequirement,newLevelRequirement)">Add</button>
            <button class="btn emalsys_button red margin-r margin-b margin-t" (click)="addActive=false;newTitleRequirement=null;newLevelRequirement=null">Cancel</button>
          </tr>
        </tbody>
        <tbody *ngIf="!addActive">
        <tr *ngFor='let compliance of rosterCompliances'>
          <td>
            <p *ngIf="editActive==null">{{compliance.title}}</p>
            <input class="compliance-name" *ngIf="editActive==compliance.id" type="text" [(ngModel)]="editTitleRequirement">
            <div class="button-group">
                <button *ngIf="editActive==compliance.id" class="btn emalsys_button green margin-r margin-t margin-b"  (click)="editActive=null;editRosterCompliance(editTitleRequirement,editLevelRequirement,compliance.id);">Save</button>
                <button *ngIf="editActive==compliance.id" class="btn emalsys_button red margin-r margin-t margin-b" (click)="editActive=null;editTitleRequirement=null;editLevelRequirement=null">Cancel</button>
            </div>
          </td>
          <td  [ngClass]="{'custom-td' : editActive==compliance.id}">
            <p *ngIf="editActive==null">{{setLevel(compliance)}}</p>
            <select *ngIf="editActive==compliance.id" [(ngModel)]="editLevelRequirement">
                  <option value=0>Optionnal</option>
                  <option value=1>Required</option>
                 </select>
          </td>
          <td class="edit-delete">
            <button *ngIf="editActive==null" class="btn emalsys_button green margin-r margin-t margin-b"  (click)="editActive=compliance.id;editTitleRequirement=compliance.title;editLevelRequirement=compliance.level">Edit</button>
            <button *ngIf="editActive==null" class="btn emalsys_button red margin-r margin-t margin-b" (click)="deleteRosterCompliance(compliance.id)">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <label for="roster-tags">Tags</label>
    <div id="roster-tags">
      <button class="btn emalsys_button" (click)="displayModalTag(); activeModal.dismiss('Cross click')">
        Manage tags
      </button>
    </div>

  </div>
</div>
<div class="loading" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer">
  <button *ngIf="canDeleteRoster"
   [disabled]="loading" type="submit" class="btn emalsys_button red" (click)="deleteRoster()">Delete</button>
  <button [disabled]="loading" type="submit" class="btn emalsys_button green" (click)="editRoster()">Save</button>
</div>
