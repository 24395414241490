import { Component      } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Service
import { DisasterService } from '../../../../../services/external/disasters.service';
import { LoggerService   } from '../../../../../services/utils/logger.service';

// Constants
import { URL_API_RELIEF_WEB_IMG } from '../../../../app.constants';

@Component({
  selector   : 'disaster',
  templateUrl: './disaster.component.html',
  styleUrls  : ['./disaster.component.scss']
})


export class DisasterComponent {

  public disaster              : any;
  public URL_API_RELIEF_WEB_IMG: any;
  public items                 : any;

  constructor(
    private route: ActivatedRoute,
    private _disasterService: DisasterService,
    private _loggerService: LoggerService
  ) {
    this.URL_API_RELIEF_WEB_IMG = URL_API_RELIEF_WEB_IMG;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.getDisasterDetails(+params.get('id'));
    });
  }

  getDisasterDetails(disasterId) {
    this._disasterService.getDesastreById(disasterId)
      .subscribe( res => {
        this.disaster = res.data[0];
        this._loggerService.log("DISASTER : ", this.disaster);
        this.getDisasterNews();
      });
  }

  getDisasterNews() {
    if (this.disaster) {
      this._disasterService.getDisasterNews([this.disaster.fields.primary_country.iso3], 4, 0, "ultimo", null)
        .subscribe(res => {
          this.items = res.data;
          this._loggerService.log("ITEMS DISASTERS", this.items);
        });
    }
  }

}
