<div >
    <label for="select-item">
        <span *ngIf="item=='Event' || item=='SelectedEvent'">
            Select an event : 
        </span> 
        <span *ngIf="item=='Roster' || item=='SelectedRoster'">
                Select a roster : 
            </span> 
    </label>
    <select2 id="select-item" cssImport="true"
              [data]="data" [value]="selectedItem" [options]="{multiple: false}" (valueChanged)="changed($event)">
    </select2>
</div>
