import { URL_API_EMALSYS } from './../../../../../environments/environment';
import { HttpService } from './../../../http.service';
import { HttpClient } from '@angular/common/http';
import { RosterCompliancesComponent } from './../../../../../app/modules/responder/organisation/roster/roster-compliances/roster-compliances.component';
import { Injectable } from '@angular/core';

@Injectable()
export class RosterCompliancesService {

    constructor(
        private http: HttpClient,
        private _httpService: HttpService,
    ) { }

    addRosterCompliance(rosterId, body) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + "/requirements", body, options);
    }
    getRosterCompliances(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + "/requirements", options);
    }

    getRosterCompliancesOfUser(rosterId, idUser) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + "/requirements?user_id=" + idUser, options);
    }

    updateRosterCompliance(requirementId, body) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/rosters/requirements/" + requirementId, body, options);
    }

    removeRosterCompliance(requirementId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/requirements/" + requirementId, options);
    }

    getCompliances(rosterId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + "?filter=compliances", options);
    }

    addComplianceDocument(comId, docId) {
        let options = this._httpService.createRequestOptions();
        let body = JSON.stringify(docId);
        return this.http.post(URL_API_EMALSYS + "/rosters/requirements/" + comId + "/documents/" + docId, body, options);
    }

    deleteComplianceDocument(comId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/rosters/requirements/" + comId + "/documents", options);
    }
}
