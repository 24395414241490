import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * This dispatcher is a patch to fix TEMPORARLY the css
 * without breaking the site structure
 */
@Injectable()
export class BannerUpdater {
    /**
     * Tells if the banner should be visible
     */
    visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    /**
     * Tells if the left menu should be open or closed when the page is clicked
     */
    closed: Subject<boolean> = new Subject<boolean>();

    /**
     * Informs that the banner should be shown
     */
    show() {
        this.visible.next(true);
    }

    /**
     * Informs that the banner should be hidden
     */
    hide() {
        this.visible.next(false);
    }

    /**
     * Subscribes on the visibility state
     */
    getVisibility(): Observable<boolean> {
        return this.visible.asObservable();
    }

    /**
     * Informs that the menu should be closed
     */
    closeMenu() {
        this.closed.next(false);
    }

    /**
     * Subscribes on the opening state
     */
    isMenuOpen(): Observable<boolean> {
        return this.closed.asObservable();
    }
}
