/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-preloader-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-preloader-map.component";
var styles_AlertsPreloaderMapComponent = [i0.styles];
var RenderType_AlertsPreloaderMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsPreloaderMapComponent, data: {} });
export { RenderType_AlertsPreloaderMapComponent as RenderType_AlertsPreloaderMapComponent };
export function View_AlertsPreloaderMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alerts-preloader-map-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "alerts-preloader-map"]], null, null, null, null, null))], null, null); }
export function View_AlertsPreloaderMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-preloader-map", [], null, null, null, View_AlertsPreloaderMapComponent_0, RenderType_AlertsPreloaderMapComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertsPreloaderMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsPreloaderMapComponentNgFactory = i1.ɵccf("alerts-preloader-map", i2.AlertsPreloaderMapComponent, View_AlertsPreloaderMapComponent_Host_0, {}, {}, []);
export { AlertsPreloaderMapComponentNgFactory as AlertsPreloaderMapComponentNgFactory };
