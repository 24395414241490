/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rosters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../pipes/text-snipped.pipe";
import * as i4 from "./rosters.component";
import * as i5 from "../../../../../services/utils/right.service";
import * as i6 from "../../../../../services/utils/logger.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../services/utils/toast.service";
import * as i9 from "../../../../../services/api/profile/profile.dispatcher";
import * as i10 from "../../../../../services/api/roster/rosters.dispatcher";
import * as i11 from "../../../../../services/api/organisation/organisation.dispatcher";
import * as i12 from "../../../../../services/api/organisation/organisation.cacher";
import * as i13 from "../../../../../services/page/dashboard.manager.pager";
import * as i14 from "../../../../../services/api/pushnotifications.service";
var styles_RostersComponent = [i0.styles];
var RenderType_RostersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RostersComponent, data: {} });
export { RenderType_RostersComponent as RenderType_RostersComponent };
function View_RostersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "left-divide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "button", [["class", "roster-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selected = _co.NO_ROSTER;
        var pd_0 = (_co.changeRoster() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "roster-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "image-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-clone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "roster-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All members "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "divide"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "roster-card"; var currVal_1 = _ck(_v, 4, 0, (_co.selected == _co.NO_ROSTER)); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_RostersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "edit-roster-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClickEditRoster($event, _v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-cog"]], null, null, null, null, null))], null, null); }
function View_RostersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "roster-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selected = _v.context.$implicit.id;
        var pd_0 = (_co.changeRoster(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "selected": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RostersComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "roster-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "image-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [["class", "roster-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "roster-card"; var currVal_1 = _ck(_v, 2, 0, (_co.selected === _v.context.$implicit.id)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.canEditRoster[_v.context.$implicit.id] && (_co.selected === _v.context.$implicit.id)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.roster_profile.browser_url ? _v.context.$implicit.roster_profile.browser_url : "assets/images/default_roster.png"); _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.name, 12)); _ck(_v, 8, 0, currVal_4); }); }
function View_RostersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "roster-card new-roster"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createRoster() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "image-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "roster-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create Roster"]))], null, null); }
function View_RostersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "dashboard-rosters-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RostersComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "right-divide"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RostersComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RostersComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["id", "organisationDeleteToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The organisation has been deleted"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["id", "organisationEditToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The organisation has been edited"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["id", "rosterDeleteToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The roster has been deleted"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["id", "rosterEditToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The roster has been edited"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.amIOrganisationManager; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.rosters; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.canAddNewRoster; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_RostersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TextSnippedPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RostersComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadedRosters; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RostersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-manager-rosters", [], null, null, null, View_RostersComponent_0, RenderType_RostersComponent)), i1.ɵdid(1, 245760, null, 0, i4.RostersComponent, [i5.RightService, i6.LoggerService, i7.NgbModal, i8.ToastService, i5.RightService, i9.ProfileDispatcherService, i10.RostersDispatcherService, i11.OrganisationDispatcherService, i12.CachedOrganisationService, i13.DashboardManagerPageService, i14.PushNotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RostersComponentNgFactory = i1.ɵccf("dashboard-manager-rosters", i4.RostersComponent, View_RostersComponent_Host_0, {}, {}, []);
export { RostersComponentNgFactory as RostersComponentNgFactory };
