<!--Contact us component-->
<div id="contact-us-component" class="shadow">
  <!--Title-->
  <h2 class="text-center">Contact Us</h2>
  
  <form>
    <div class="form-group">
      <!--Subject-->
      <label for="subjectField">Enter the subject:</label>
      <input id="subjectField" type="text" class="form-control margin-b" [(ngModel)]="subject" name="subject"/>
      <!--Message-->
      <label for="contentField">Enter your message:</label>
      <textarea id="contentField" type="text-area" class="form-control margin-b" [(ngModel)]="content" name="content"></textarea>
    </div>
  </form>

  <!--Send button-->
  <div class="text-center">
    <button class="btn btn-red" (click)='sendEmail(); showToastContactUs()'>SEND EMAIL</button>
  </div>
</div>

<!--Toast-->
<div id="snackContact">Your message has been sent</div>
