import { Component, Input, OnInit } from '@angular/core';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { UserService } from "../../../../../services/api/user.service";
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../services/utils/toast.service';
import { UserTagInterface } from './../../../../model/user-tag.interface';



@Component({
  selector: 'profile-skills',
  templateUrl: './profile-skills.component.html',
  styleUrls: ['./profile-skills.component.scss']
})


export class ProfileSkillsComponent implements OnInit {

  public readonly MAX_SKILL_NUMBER = 5;       // max number of skills permitted; old value : 6

  @Input() skills: any[];

  // public skills: any[];                     // my current skills
  public skillsList: any[];                 // all skills available to add
  public addedSkills: any[];                // temporary list of skills to be added
  public addID: number;                     // id of selected skill to add
  public skillsCanBeAdded = true;  // if you can add more skills
  public loading = false;
  public modalRef;
  public isSomeoneElse = false;

  constructor(
    private _profileService: ProfileService,
    private _userService: UserService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    private _profileDispatcher: ProfileDispatcherService
  ) {
    // this.skills = null;
  }

  ngOnInit() {
    this.isSomeoneElse = !!this.skills;
    this.skillsList = [];
    this.addedSkills = [];
    this.addID = -1;
    this._profileDispatcher.getSkills()
      .subscribe(res => {
        this.skillsList = res;
        this._loggerService.log("ALL SKILLS: ", this.skillsList);
      });

    if (!this.skills) {
      this._profileDispatcher.getUserSkills()
        .subscribe(
          (res: UserTagInterface[]) => {
            this.skills = res;
          }
        );
      this._profileService.getMySkills().subscribe();
    }
    this._profileService.getAllSkills().subscribe();
  }

  // initialize variables of start, after modal interaction
  initVariables() {
    this.skillsList = [];
    this.addedSkills = [];
    // this.getAllSkills();
    if (!this.isSomeoneElse) {
      this.getMySkills();
    } else {
      this.getSkills();
    }
    this.addID = -1;
  }

  // add selected skill to the temporary list before sending to API
  addSelectedSkill() {
    // Beware: the x.id can be a string equals to "-1" if the user doesn't select anything.
    // Keep the "==="
    let element = this.skillsList.find(x => x.id === this.addID);
    if (element !== undefined) {
      this.addedSkills.push(element);
    }

    if (this.skills.length + this.addedSkills.length >= this.MAX_SKILL_NUMBER) {
      this.skillsCanBeAdded = false;
    }
  }

  // remove a skill from the temporary list
  removeSkillFromAdded(skill) {
    let i = this.addedSkills.indexOf(skill);
    if (i > -1) {
      this.addedSkills.splice(i, 1);
    }
    this.skillsList.push(skill);
    if (this.skills.length + this.addedSkills.length < this.MAX_SKILL_NUMBER) {
      this.skillsCanBeAdded = true;
    }
  }

  // helping function to remove an item from array
  removeListItem(list, item) {
    let index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  // show Add Skill modal
  showAddSkills(content) {
    if (this.skillsCanBeAdded) {
      this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
    } else {
      this.showToastNoMoreSkills();
    }
  }

  // save the temporary skill list to the DB
  addSkills(idSkills) {
    this.loading = true;
    let skillsIDs = [];
    for (let s of this.addedSkills) {
      skillsIDs.push(s.id);
    }
    this._profileService.addSkill({ id: skillsIDs })
      .subscribe(data => {
        this.showToastAdd();
        this.initVariables();
        this.loading = false;
        this.modalRef.close();
        this.getMySkills();

      });
  }

  // delete a skill from the DB
  deleteSkill(idSkill) {
    this._profileService.deleteSkill(idSkill)
      .subscribe(res => {
        this.showToastDeleted();
        this.getMySkills();
        // this.getAllSkills();
      });
  }

  // get all skills of the current profile
  getMySkills() {
    this._profileService.getMySkills()
      .subscribe(res => {
        this.skills = res;
        this.skillsCanBeAdded = this.skills.length < this.MAX_SKILL_NUMBER;
      });
  }

  // get all skills of the current profile
  getSkills() {
    // this._userService.getSkills(this.user)
    //   .subscribe((res: any) => {
    //     this.skills = res;
    //   });
  }

  showToastDeleted() {
    this._toastService.show("skillDeleteToast", "show", 3000);
  }

  showToastCancel() {
    this._toastService.show("skillCancelToast", "show", 3000);
  }

  showToastAdd() {
    this._toastService.show("skillAddToast", "show", 5000);
  }

  showToastNoMoreSkills() {
    this._toastService.show("noMoreSkillsToast", "show", 3000);
  }

}
