/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-preloader-news.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-preloader-news.component";
var styles_DashboardPreloaderNewsComponent = [i0.styles];
var RenderType_DashboardPreloaderNewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPreloaderNewsComponent, data: {} });
export { RenderType_DashboardPreloaderNewsComponent as RenderType_DashboardPreloaderNewsComponent };
export function View_DashboardPreloaderNewsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dashboard-preloader-news"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "dashboard-preloader-news-map shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "dashboard-preloader-news-map-container"]], null, null, null, null, null))], null, null); }
export function View_DashboardPreloaderNewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-news", [], null, null, null, View_DashboardPreloaderNewsComponent_0, RenderType_DashboardPreloaderNewsComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardPreloaderNewsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPreloaderNewsComponentNgFactory = i1.ɵccf("dashboard-preloader-news", i2.DashboardPreloaderNewsComponent, View_DashboardPreloaderNewsComponent_Host_0, {}, {}, []);
export { DashboardPreloaderNewsComponentNgFactory as DashboardPreloaderNewsComponentNgFactory };
