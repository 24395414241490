import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Interfaces
import { AlertInterface } from '../../../app/model/alert.interface';
// Data
import { URL_API_EMALSYS } from '../../../environments/environment';
// Services
import { HttpService } from '../http.service';

@Injectable()


export class AlertService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  // ------------------------------------------------------------------------//
  // -------------------------- GETTERS / SETTERS ---------------------------//
  // ------------------------------------------------------------------------//

  getHovered() {
    // return this.hovered;
  }

  setHovered(hovered) {
    // this.hoveredSource.next(hovered);
  }

  // ------------------------------------------------------------------------//
  // ------------------------------- ALERTS ---------------------------------//
  // ------------------------------------------------------------------------//

  getOrgaAlerts(idOrga) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<AlertInterface[]>(URL_API_EMALSYS + "/events?method=by_organisation&id=" + idOrga, options);
  }

  getOldOrgaAlerts(idOrga) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<AlertInterface[]>(URL_API_EMALSYS + "/events?method=by_organisation&id=" + idOrga + "&filter=by_old", options);
  }

  getMyAlerts(idOrga) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<AlertInterface[]>(URL_API_EMALSYS + "/events?method=by_organisation&id=" + idOrga + "&filter=by_me", options);
  }

  getAlertDetails(idAlert) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<AlertInterface>(URL_API_EMALSYS + "/events/" + idAlert, options);
  }

  createEvent(body: FormData) {
      let options = this._httpService.createRequestOptions();
      return this.http.post(URL_API_EMALSYS + "/events" , body, options);
  }

  updateAlert(idAlert, alert) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/events/" + idAlert, alert, options);
  }

  deleteAlert(idAlert) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/events/" + idAlert, options);
  }


}
