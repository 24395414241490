import { NgModule             } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


// Do not change the order of the routes, it matters
export const routes: Routes = [];


@NgModule({
  imports: [RouterModule.forChild(routes)],   // forChild ? Peut etre a changer pour que ca marche
  exports: [RouterModule]
})


export class WelcomeRouting {}
