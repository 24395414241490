import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { OrganisationApiService } from '../../../../../../services/api/organisation/organisation.api';
@Component({
  selector: 'app-inform-modal',
  templateUrl: './inform-modal.component.html',
  styleUrls: ['./inform-modal.component.scss']
})
export class InformModalComponent implements OnInit {

  @Input() organisation;
  @Input() usersId;
  @Output() onSend = new EventEmitter<any>();
  public attachedFile: File;
  public emailTitle: string;
  public emailBody: string;
  public loading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public _organisationService: OrganisationApiService,
    public _loggerService: LoggerService
  ) { }

  ngOnInit() {
  }
  // to change the input into a file
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.attachedFile = fileList[0];
    }
  }

  sendEmail() {
    this.loading = true;
    let body = new FormData();
    body.append('users', JSON.stringify(this.usersId));
    body.append('title', this.emailTitle);
    body.append('content', this.emailBody);
    body.append('file', this.attachedFile);
    this._organisationService.sendEmail(this.organisation.id, body).subscribe(res => {
      this.onSend.emit("MESSAGES SENT");
      this.loading = false;
      this.activeModal.close();
    });
  }
}
