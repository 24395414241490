/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-users.component";
var styles_AdministrationUsersComponent = [i0.styles];
var RenderType_AdministrationUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationUsersComponent, data: {} });
export { RenderType_AdministrationUsersComponent as RenderType_AdministrationUsersComponent };
export function View_AdministrationUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the users management page in progress...\n"]))], null, null); }
export function View_AdministrationUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-users", [], null, null, null, View_AdministrationUsersComponent_0, RenderType_AdministrationUsersComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationUsersComponent, [], null, null)], null, null); }
var AdministrationUsersComponentNgFactory = i1.ɵccf("administration-users", i2.AdministrationUsersComponent, View_AdministrationUsersComponent_Host_0, {}, {}, []);
export { AdministrationUsersComponentNgFactory as AdministrationUsersComponentNgFactory };
