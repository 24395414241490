import { Component, OnInit } from '@angular/core';

// Service
import { SettingsService   } from '../../../../../../services/api/settings-service';
import { LoggerService     } from '../../../../../../services/utils/logger.service';
import { NavigationService } from '../../../../../../services/utils/navigation.service';
import { LoginService      } from '../../../../../../services/security/login.service';
import { WSSEService } from '../../../../../../services/security/wsse.service';
import { CacheService } from '../../../../../../services/storage/cache.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../../../services/api/user.service';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {

  public currentPassword: any;
  public saltFromApi: string;
  public newPassword: any;
  public newPassword2: any;
  public actualUser: any;
  public stateMessage: { message, classType };
  public isLoading: boolean;

  constructor(
    private _settingsService: SettingsService,
    private _navigationService: NavigationService,
    private _loginService: LoginService,
    private _userService: UserService,
    private _wsseService: WSSEService,
    private _cacheService: CacheService,
    private _modalService: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.currentPassword    = '';
    this.newPassword        = '';
    this.actualUser = this._cacheService.get(CacheService.USER);
    this.saltFromApi = '';
    this.stateMessage = {message : '', classType : ''};
    this.isLoading = false;
  }

  // Change de value of the obkect to display the right information in the DOM.
  setStateMessage(mess: string, type: string) {
    this.stateMessage.message = mess;
    this.stateMessage.classType = type;
  }

  // TODO : Should salt password before sending it threw the api (waiting for the back to update his v3 password update function).
  // Also must reload cache values....
  changeUserPassword() {
    let saltedCurrentPassword = this._wsseService.saltPassword(this.saltFromApi, this.newPassword);

    this._userService.updatePassword(saltedCurrentPassword)
      .subscribe(res => {
        this.setStateMessage("Done. You will be disconnected in 3 seconds. Please log in again.", "alert alert-success");

        setTimeout(() => {
          this._modalService.dismiss(true);
          this._loginService.logout();
        }, 3000);
      }, error => {
        this.setStateMessage("We failed to update your password", "alert alert-danger");
      });
  }

  // Runned when 'change password' button is clicked.
  passwordCheck(): void {
    this.isLoading = true;

    // Affect the state of the new password ('valid' if respecting each condition).
    let respectingRulesMessage = this.respectingPasswordRules(this.newPassword);

    if ( respectingRulesMessage !== 'valid') {
      // New password not respecting rules
      this.setStateMessage( respectingRulesMessage, "alert alert-warning");
      this.isLoading = false;
      return;
    } else if (this.newPassword !== this.newPassword2) {
      // Both new passwords not the same
      this.setStateMessage("New passwords do not match", "alert alert-danger");
      this.isLoading = false;
      return;
    }

    this.matchActualCode().then( (isMatching) =>  {
      this.isLoading = false;
      // Current password invalid
      if (!isMatching) {
        this.setStateMessage("The current password you entered is incorrect", "alert alert-danger");
      } else {
        this.changeUserPassword();
      }
    })
    .catch(error => {
      this.isLoading = false;
    });
  }

  /**
   * Affecting the value of the salted actual password, or 'undefined' if not matching
   * with the real one.
   */
  matchActualCode(): Promise<boolean> {
    return this._loginService.requestSalt(this.actualUser.email)
    .toPromise()
    .then((saltFromApi: any) => {
      this.saltFromApi = saltFromApi;
      const saltedCurrentPassword = this._wsseService.saltPassword(this.saltFromApi, this.currentPassword);

      // if salted passwords match then return true, else false
      return (saltedCurrentPassword === this.actualUser.saltedPassword);
    })
    .catch(error => {
      this.setStateMessage( error.message || "We failed to check your password, please try again later", "alert alert-danger");
      return false;
    });
  }

  /**
   * Check password rules
   * @param pass password to check
   */
  respectingPasswordRules(pass: string): string {
    if (pass.length < 6) {
      return('Must have minimum of 6 characters');
    }

    if (pass === pass.toLowerCase() || pass === pass.toUpperCase() ) {
      return('Must contain lowerCase & UPPERCASE characters');
    }

    let hasNumber = /\d/;

    if (! hasNumber.test(pass)) {
      return('Must contain at least one digit');
    }

    return('valid');
  }

  viewCurrentPass() {
    let inputCurrentPassword = document.getElementById('currentPassword');
    if (inputCurrentPassword.getAttribute('type') === 'password') {
      inputCurrentPassword.setAttribute('type', 'text');
    } else {
      inputCurrentPassword.setAttribute('type', 'password');
    }
  }
  viewNewPass() {
    let inputNewPassword = document.getElementById('newPassword');
    if (inputNewPassword.getAttribute('type') === 'password') {
      inputNewPassword.setAttribute('type', 'text');
    } else {
      inputNewPassword.setAttribute('type', 'password');
    }
  }
  viewNewPass2() {
    let inputNewPassword = document.getElementById('newPassword2');
    if (inputNewPassword.getAttribute('type') === 'password') {
      inputNewPassword.setAttribute('type', 'text');
    } else {
      inputNewPassword.setAttribute('type', 'password');
    }
  }

  cancel() {
    this._modalService.dismiss(false);
  }
}
