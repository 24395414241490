import { NgModule                   } from '@angular/core';
import { CommonModule               } from '@angular/common';
// Routing
import { ResponderRouting           } from './responder.routing';
// Modules
import { WelcomeModule              } from './welcome/welcome.module';
import { UserGuideModule            } from './user-guide/user-guide.module';
import { DashboardResponderModule            } from './dashboard/dashboard-responder.module';
import { ContactModule              } from './contact/contact.module';
import { ProfileModule              } from '../shared/profile/profile.module';
import { SettingsModule             } from '../manager/settings/settings.module';
import { SharedModule               } from '../../shared.module';

@NgModule({
  imports: [
    // Routing
    ResponderRouting,
    // Angular Modules
    CommonModule,
    // Feature Modules
    SharedModule,
    WelcomeModule,
    DashboardResponderModule,
    UserGuideModule,
    ContactModule,
    ProfileModule,
    SettingsModule
  ],
  declarations: [
  ],
  providers: []
})


export class ResponderModule {}
