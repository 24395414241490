<div id="deployment-order-what-is" class="shadow" *ngIf="!this.nbrOrders">
  <h5 class="deployment-order-description-header">You don't have a deployment order yet</h5>
  <h5 class="deployment-order-what-is-title">What is a deployment order ?</h5>
  <p class="deployment-order-what-is-description">A deployment order is a summary of key information about the deployment. It will appear once the Deployment Order has been sent by your Roster Manager.
  </p>
</div>
<div class="shadow">
  <div *ngIf=this.nbrOrders>
    <div class="deployment-order-description-header">
      <i title="A deployment order is a summary of key information about the deployment.
Once the Deployment Order has been sent by your Roster Manager, it will also be recorded in your General Profile, and will stay here even after the Deployment ends.
That will constitute your Deployment history."
          class="fa fa-question-circle-o help-icon" aria-hidden="true">
      </i>
      <h2>Deployment order</h2>
    </div>
    <div id="deployment-order-description-body" *ngFor="let order of deploymentOrders">
        <div class="deployment-order-description-container">
          <div class="deployment-order-description-leftpart">
            <div class="deployment-order-description-subheader">
              <p class="deployment-order-description-position">Position: {{order.deployment_order.position}}</p>
              <div class="deployment-order-description-date-container">
                <p class="deployment-order-description-date">{{order.deploymentdate | niceDateDetailed}}</p>
                <i class="fa fa-arrow-right fa-1x deployment-order-description-arrow" aria-hidden="true"></i>
                <p class="deployment-order-description-date">{{order.end_date | niceDateDetailed}}</p>
              </div>

              <p class="deployment-order-description-sender">Deploying manager: {{order.deployment_order.sender.firstname}} {{order.deployment_order.sender.lastname}} - {{order.deployment_order.sender.profile.contact_email}}</p>

            </div>
            <p class="deployment-order-description-description">{{order.message}}</p>
          </div>
          <div class="deployment-order-description-rightpart">
            <div class="deployment-order-description-additional">
              <div class="deployment-order-description-additional-airport" *ngIf="order.airport">
                <p class="deployment-order-description-additional-airport-label">Airport</p>
                <p class="deployment-order-description-additional-airport-value">{{order.airport}}</p>
              </div>
              <div class="deployment-order-description-additional-flight" *ngIf="order.flight_number">
                <p class="deployment-order-description-additional-flight-label">Flight number</p>
                <p class="deployment-order-description-additional-flight-value">{{order.flight_number}}</p>
              </div>
              <div class="deployment-order-description-additional-insurance-name" *ngIf="order.insurance_name">
                <p class="deployment-order-description-additional-insurance-name-label">Insurance name</p>
                <p class="deployment-order-description-additional-insurance-name-value">{{order.insurance_name}}</p>
              </div>
              <div class="deployment-order-description-additional-insurance-number" *ngIf="order.insurance_number">
                <p class="deployment-order-description-additional-insurance-number-label">Insurance number</p>
                <p class="deployment-order-description-additional-insurance-number-value">{{order.insurance_number}}</p>
              </div>
            </div>
            <div class="deployment-order-description-documents" *ngIf="order.deployment_document.length">
              <p class="deployment-order-description-documents-label">Deployment documents</p>

              <div *ngFor="let doc of order.deployment_document">
                <button class="deployment-order-description-doc-name" (click)="downloadDoc(doc.document)">
              <img class="doc-icon" src="assets/images/Docs_Icon.png" />
              <p>{{doc.document.title}}</p>
            </button>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>

    </div>
  </div>
</div>
