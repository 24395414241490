<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Send deployment offer</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body" *ngIf='step==1 && page == "members"'>
  <div class="info-component">
    <i class="fa fa-info-circle custom-left-icon" aria-hidden="true"></i>
    <p>Send an offer to the selected members (they will receive a notification with the offer).</p>
  </div>

  <div *ngIf="events && events.length > 0 && !option">
    <label for="select-event">Choose an event</label>
    <select id="select-event" class="event-modal-select" [(ngModel)]="selectedEvent" name="event" type="select">
          <option *ngFor="let event of events" [ngValue]="event">{{ event.name }}</option>
      </select>
  </div>
  <div *ngIf="!events || events.length == 0 && !option">
    <h3>There are no events created. </h3>
  </div>
  <button *ngIf="checkOrganisationAssociate()" class="option-button" (click)="onClickOption()">
      <p *ngIf="!option"> Or create a new one    <i class="fa fa-chevron-down custom-right-icon"></i></p>
      <p *ngIf="option">Select an existing event <i class="fa fa-chevron-up custom-right-icon"></i></p>
    </button>
  <div *ngIf="option">
    <div class="form-group">
      <label for="AlertName">Alert name</label>
      <input id="AlertName" class="form-control" maxlength="20" [(ngModel)]="createdEvent.name" />
    </div>
    <div class="form-group">
      <label for="Country">Country</label>
      <select id="Country" class="form-control" name="country" type="select" [(ngModel)]="createdEvent.country">
          <option *ngFor="let c of countries" [value]="c.id"> {{c.text}} </option>
        </select>
    </div>
    <div class="form-group">
      <label for="StartDate">Start date</label>
      <div class="form-group">
        <p-calendar id="StartDate" [maxDate]="createdEvent.endDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="createdEvent.startDate"></p-calendar>
      </div>
    </div>
    <div class="form-group">
      <label for="EndDate">End date</label>
      <div class="form-group">
        <p-calendar id="EndDate" [minDate]="createdEvent.startDate"  [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="createdEvent.endDate"></p-calendar>
      </div>
    </div>
  </div>
</div>

<div class="modal-body" *ngIf="step==2">
  <div class="date-group">
    <div class="date-input">
      <label for="StartOffer">Deploy From</label>
      <p-calendar id="StartOffer" [selectOtherMonths]="true" [defaultDate]="minDateValue" (onSelect)="setDefaultEndDateValue()" [maxDate]="end_deployment||maxDateValue" [minDate]="minDateValue" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="start_deployment" ></p-calendar>
    </div>

    <div class="date-input">
      <label for="EndOffer">To</label>
      <p-calendar id="EndOffer" [selectOtherMonths]="true" [defaultDate]="minDateValue" [maxDate]="maxDateValue" [minDate]="start_deployment||minDateValue" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="end_deployment"></p-calendar>
    </div>
  </div>
  <div class="form-group">
    <label for="position">Position</label>
    <textarea id="position" class="form-control" [rows]="2" pInputTextarea autoResize="true" [(ngModel)]="position">
</textarea >
</div>
<div class="form-group">
<label for="message">Message</label>
<textarea id="message" class="form-control" [rows]="4" pInputTextarea autoResize="true" [(ngModel)]="message">
</textarea >
</div>
<button class="copy-button" (click)="copy=!copy;">
    <p>Send a copy of this deployment offer</p>
    <i *ngIf="!copy" class="fa fa-chevron-down custom-right-icon"></i>
    <i *ngIf="copy" class="fa fa-chevron-up custom-right-icon"></i>
</button>
 <div class="form-group" *ngIf="copy">
   <div *ngFor="let email of emails; index as i; trackBy:customTrackBy">
     <label for="{{email}}">Email</label>
     <input id="{{email}}" type="email" name="{{email}}" placeholder="Email" [(ngModel)]="emails[i]">
     <button id="add-skill-btn" class="btn emalsys_button green" *ngIf="i===emails.length-1" [disabled]="!emails[i]" (click)="emails.push('')">Add</button>
   </div>
  </div>

</div>
<div class="loading-custom" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
<div class="modal-footer" *ngIf='step==1 && page == "members"'>
 <button [disabled]="missingEvent()" [ngClass]="{'disabled': missingEvent()}" class="btn emalsys_button blue margin-b margin-r" (click)="nextStep()">Next step<i class="fa fa-arrow-right custom-right-icon" aria-hidden="true"></i></button>
</div>
<div class="modal-footer {{page}}" *ngIf="step==2">
  <button 
    [disabled]="loading" [ngClass]="{'disabled': loading}" 
    *ngIf='page == "members"' class="btn emalsys_button margin-b margin-r" (click)="previousStep()"
  ><i class="fa fa-arrow-left custom-left-icon" aria-hidden="true"></i>Back</button>
  <button
    [disabled]="!position || !start_deployment || !end_deployment || loading"
    [ngClass]="{'disabled': !position || !start_deployment || !end_deployment || loading}"
    class="btn emalsys_button green margin-b margin-r " (click)="sendOffer()"
  >Send offer<i class="fa fa-share custom-right-icon" aria-hidden="true"></i></button>
</div>
  <div id="createDeploymentOfferToast" class="createDeploymentOfferToast">Failed to create the event,<br> please retry later</div>
  <div id="createDeploymentOrderToast" class="createDeploymentOfferToast">Failed to send offers,<br> please retry later</div>
  <div id="createDeploymentOfferValidationToast" class="createDeploymentOfferToast">{{ formValidationText }}</div>
