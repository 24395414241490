import { BehaviorSubject ,  Observable ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInterface } from '../../../app/model/user.interface';
import { Dispatcher } from '../../dispatcher.service';
import { RosterInterface } from '../../../app/model/roster.interface';

@Injectable()
export class MembersDispatcherService extends Dispatcher {

    private displayedMembers$ = new BehaviorSubject<UserInterface[]>(null);
    private rosterMembers$ = new BehaviorSubject<UserInterface[]>(null);


    private allMembers: UserInterface[];
    private previousMembers: UserInterface[];

    public getMembers() {
        return this.pipeNotNull(this.displayedMembers$);
    }

    public getFilteredMembers() {
        return this.pipeNotNull(this.displayedMembers$);
    }

    /** Used when the search query returns empty */
    public publishPreviousMembers() {
        this.displayedMembers$.next(this.previousMembers);
    }


    public publishFilteredMembers(members: UserInterface[]) {
        this.displayedMembers$.next(members);
    }

    public publishMembers(members: UserInterface[]) {
        this.previousMembers = members;
        this.displayedMembers$.next(members);
    }

    public publishAllOrganisationMembers(members: UserInterface[]) {
        this.allMembers = members;
        this.publishMembers(members);
    }

    public publish(members: UserInterface[]) {
        this.displayedMembers$.next(members);
    }

    public filterByRoster(rosterId: number) {
        let filteredMembers = this.allMembers
            .filter((member: UserInterface) => {
                return member.roster_members
                    .find(roster_member => roster_member.roster.id === rosterId) !== undefined;
            });
        return filteredMembers;
    }

    public filterByRosterAsObservable(rosterId: number) {
        return of(this.filterByRoster(rosterId));
    }

    public filterByRosterAndDispatch(rosterId: number) {
        this.publishFilteredMembers(
            this.filterByRoster(rosterId)
        );
    }

    public getRosterMembers() {
        return this.pipeNotNull(this.rosterMembers$);
    }

    public publishRosterMembers(members: UserInterface[]) {
        this.rosterMembers$.next(members);
    }

    public clearMembers() {
        this.displayedMembers$.next(null);
    }
}
