<div id="deployment-alert-container" *ngIf="alertInfo">
  <div class="alert-flex-container">
    <alerts-map
      [alertInfo]="alertInfo"
      class="alert-flex-map-item shadow"></alerts-map>
    <alerts-process
      (setting)="setComponent($event)"
      [alertInfo]="alertInfo"
      [(SELECTIONS)]="SELECTIONS"
      [deploymentOffers]="deploymentOffers"
      [nbrOrders]="nbrOrders"
      [nbrActiveOrders]="nbrActiveOrders"
      class="alert-flex-process-item shadow"></alerts-process>
    <alerts-information
      [alertInfo]="alertInfo"
      class="alert-flex-information-item shadow"></alerts-information>
  </div>
  <div *ngIf="invited">
    <alerts-deployment-calendar
      *ngIf="calendarB"
      [idAlert]="idAlert"
      [alertInfo]="alertInfo"
      [(SELECTIONS)]="SELECTIONS"></alerts-deployment-calendar>

    <alerts-deployment-offer
        *ngIf="offerB"
        [idAlert]="idAlert"
        [alertInfo]="alertInfo"
        [deploymentOffers]="deploymentOffers"
        [nbrOffers]="nbrOffers"
        (validate)="onValidate($event)"
        (refuse)="onRefuse($event)"
        ></alerts-deployment-offer>
    <alerts-deployment-order
            *ngIf="orderB"
            [idAlert]="idAlert"
            [alertInfo]="alertInfo"
            [deploymentOrders]="deploymentOrders"
            [nbrOrders]="nbrOrders"
            ></alerts-deployment-order>
  </div>
</div>

<div class="deployment-alert-container" *ngIf="!alertInfo">
  <div class="alert-flex-container">
    <alerts-preloader-map class="alert-flex-map-item shadow"></alerts-preloader-map>
    <alerts-preloader-process class="alert-flex-process-item shadow"></alerts-preloader-process>
    <alerts-preloader-information class="alert-flex-information-item shadow"></alerts-preloader-information>
  </div>
  <alerts-preloader-content></alerts-preloader-content>
  </div>

  <div id="resetAvailabilitiesToast">Availability reset</div>
