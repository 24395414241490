import { HttpClient } from '@angular/common/http';
import { HttpService } from './../../../http.service';
import { URL_API_EMALSYS } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class OrganisationNewsService {

    constructor(
        private http: HttpClient,
        private _httpService: HttpService
    ) { }
    // getOrgaNews(orgaId) {
    //   let options = this._httpService.createRequestOptions();

    //   return this.http.get<OrganisationNewsInterface[]>(URL_API_EMALSYS + "/organisations/" + orgaId + "/news", options);
    // }

    // getOrgaNewsDetails(orgaId, newsId) {
    //   let options = this._httpService.createRequestOptions();

    //   return this.http.get<OrganisationNewsInterface>(URL_API_EMALSYS + "/organisations/" + orgaId + "/news/" + newsId, options);
    // }


    /**
     * TODO: move in proper service
     */
    updateOrgaNews(newsId, news) {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/organisations/news/" + newsId, news, options);
    }
    /**
       * TODO: move in proper service
       */
    deleteOrgaNews(newsId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/organisations/news/" + newsId, options);
    }
    /**
       * TODO: move in proper service
       */
    createOrgaNews(orgaId, news) {
        let options = this._httpService.createRequestOptions();
        return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/news", news, options);
    }

}
