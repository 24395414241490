import { Injectable } from '@angular/core';

import { CachedItem } from './cachedItem.class';
import { environment } from '../../environments/environment';

export interface CacheOption {
    /**
     * can the cache item can be deleted
     */
    canBeDeleted?: boolean;
    /**
     * timeout in seconds
     */
    timeout?: number;
}

@Injectable()
export class CacheService {

  // KEYS
  static readonly SETTINGS = 'app_settings';
  static readonly USER = 'user';
  static readonly ORGANISATIONS = 'all_organisations';
  static readonly CURRENT_ORGANISATION = 'current_organisation';
  static readonly DEBUG_STATE = 'debug_state';
  static readonly ISMANAGER_WEBSITE = 'isManagerWebsite';
  static readonly PREFIX_INDICATOR = 'indicatordata_';

  readonly PREFIX = 'erw'; // erw = emalsys_responder_web
  readonly TIMEOUT = 2592000; // 30 days in seconds
  private defaultStorage: any;

  // public static keys = {}; // TODO: @Odysseas you will be afraid

  constructor() {
    this.defaultStorage = localStorage;
  }

  /**
   * Get the key as stored in the storage
   * @param  {string} key [description]
   * @return {[type]}     [description]
   */
  getRealKey(key: string) {
    const env = environment.envName || 'env';
    const version = environment.version || 'version';

    return this.PREFIX + '_' + env + '_' + version + '_' + key;
  }

  secToMs(ms) {
    return ms * 1000;
  }

  /**
   * Get item from cache.
   * item = { storageTime : time, value : value}
   * @param  {[type]} key [description]
   * @return {[type]}     [description]
   */
  get(key) {
    key = this.getRealKey(key);
    let item = this.defaultStorage.getItem(key);
    // CacheService.keys[key] = CacheService.keys[key] ? CacheService.keys[key] + 1 : 1;

    if (item) {
      let object: CachedItem = JSON.parse(item) as CachedItem || {};
      let now = (new Date()).getTime();

      // apply time policy to validate the cached object
      if (object.storageTime + this.secToMs(object.limit) < now) {
        // we assume the cached value expired
        this.remove(key);
        return null;
      }
      return object.value;
    }

    return null;
  }

  getTest<T = any>(key) {
    key = this.getRealKey(key);
    let item = this.defaultStorage.getItem(key);
    if (item) {
      let object: CachedItem = JSON.parse(item) as CachedItem || {};
      let now = (new Date()).getTime();

      // apply time policy to validate the cached object
      if (object.storageTime + this.secToMs(object.limit) < now) {
        // we assume the cached value expired
        this.remove(key);
        return null;
      }
      // REFACTOR casting issue
      return <T>object.value;
    }

    return null;
  }

  /**
   * Get the value and remove it from the cache
   * @param  {string} key [description]
   * @return {any}     [description]
   */
  pop(key: string) {
    let item = this.get(key);
    this.remove(key);
    return item;
  }

  /**
   * Store a value to the cache.
   * object = { storageTime : time, value : value}
   */
  set(key: string, value: any, options: CacheOption = {}) {
    // default canBeDeleted
    if (options.canBeDeleted == null) {
      options.canBeDeleted = true;
    }

    // default timeout
    options.timeout = options.timeout || this.TIMEOUT;

    let object: CachedItem = {
      storageTime: (new Date()).getTime(), // in milliseconds
      value: value,
      limit: this.TIMEOUT, // in seconds
      canBeDeleted: options.canBeDeleted
    };

    key = this.getRealKey(key);

    this.defaultStorage.setItem(key, JSON.stringify(object));
  }

  isDeletable(key: string) {
    key = this.getRealKey(key);
    let item: CachedItem = JSON.parse(this.defaultStorage.getItem(key)) || {};
    return item.canBeDeleted;
  }

  /**
   * Remove an object from the cache
   * @param  {string} key [description]
   * @return {[type]}     [description]
   */
  remove(key: string) {
    if (this.isDeletable(key)) {
      key = this.getRealKey(key);
      this.defaultStorage.removeItem(key);
    }
  }

  /**
   * Clear the whole cache = remove all items generated by this cache
   * @return {[type]} [description]
   */
  clear() {
    Object.keys(this.defaultStorage)
      .filter(key => key.toString().indexOf(this.PREFIX) >= 0 )
      .forEach((item, key, array) => {
        let cached: CachedItem = JSON.parse(this.defaultStorage[item]);
        if (cached.canBeDeleted) {
          delete this.defaultStorage[item];
        }
      });
      document.cookie = "credentials=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  }

}
