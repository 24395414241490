import { Component } from '@angular/core';

// External form-job
//import { WelcomeTitleComponent } from '../responder/welcome/welcome-title/welcome-title.component';

@Component({
  selector   : 'administration-page',
  templateUrl: 'administration.component.html',
  styleUrls  : ['administration.component.scss']
})

export class AdministrationComponent {}
