import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal          } from '@ng-bootstrap/ng-bootstrap';

// Services
import { SettingsService } from '../../../../../services/api/settings-service';
import { LoggerService   } from '../../../../../services/utils/logger.service';
import { ToastService    } from '../../../../../services/utils/toast.service';

@Component({
  selector: 'preloader-profile-notifications',
  templateUrl: './profile-notifications.component.html',
  styleUrls: ['./profile-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileNotificationsComponent implements OnInit {

    public mailNotification: any;
    public smsNotification: any;
    public appNotification: any;
    public emalsysNewsNotification: any;
    public orgaNewsNotification: any;
    public rosterNotification: any;
    public alertNotification: any;

    constructor(
      private _modalService: NgbModal,
      private _settingsService: SettingsService,
      private _loggerService: LoggerService,
      private _toastService: ToastService
    ) {}

    ngOnInit() {
      this.getNotifAcceptance();
    }

    open(content) {
      this._modalService.open(content, { windowClass: 'settings-modal', backdrop: 'static' });
    }

    setNotificationAcceptance() {
      let acceptNotif = {
        acceptmail: this.mailNotification,
        accept_s_m_s: this.smsNotification ,
        accept_push: this.appNotification ,
        newsletter: this.emalsysNewsNotification ,
        newsletter_member: this.orgaNewsNotification ,
        notif_roster: this.rosterNotification ,
        notif_alert: this.alertNotification
      };

      this._settingsService.setNotificationAcceptance(acceptNotif)
        .subscribe(res => {
          this._loggerService.log("SAVING...", acceptNotif);
          this.getNotifAcceptance();
          this._loggerService.log("SAVED");
        });
    }

    getNotifAcceptance() {
      this._settingsService.getNotificationAcceptance()
        .subscribe(res => {
          this.mailNotification        = res.acceptmail;
          this.smsNotification         = res.accept_s_m_s;
          this.appNotification         = res.accept_push;
          this.emalsysNewsNotification = res.newsletter;
          this.orgaNewsNotification    = res.newsletter_member;
          this.rosterNotification      = res.notif_roster;
          this.alertNotification       = res.notif_alert;

          this._loggerService.log("Notifications mail"       , this.mailNotification);
          this._loggerService.log("Notifications sms"        , this.smsNotification);
          this._loggerService.log("Notifications app"        , this.appNotification);
          this._loggerService.log("Notifications emalsysnews", this.emalsysNewsNotification);
          this._loggerService.log("Notifications organews"   , this.orgaNewsNotification);
          this._loggerService.log("Notifications roster"     , this.rosterNotification);
          this._loggerService.log("Notifications alert"      , this.alertNotification);
        });
    }

    showToastNotifChanged() {
      this._toastService.show("snackbarNotif", "show", 3000);
    }
    showToastNotifCancelChanged() {
      this._toastService.show("snackbarNotifCancel", "show", 3000);
    }
}
