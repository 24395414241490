/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-process.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alerts-process.component";
import * as i4 from "../../../../../../services/utils/logger.service";
var styles_AlertsProcessComponent = [i0.styles];
var RenderType_AlertsProcessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsProcessComponent, data: {} });
export { RenderType_AlertsProcessComponent as RenderType_AlertsProcessComponent };
function View_AlertsProcessComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "alerts-process-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There is no description."]))], null, null); }
export function View_AlertsProcessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "alerts-process-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "alerts-process-header snipped"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "alerts-process-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsProcessComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "process-icons-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setComponent("calendar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar-o fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "lines"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setComponent("offer") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-envelope-o fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "lines"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setComponent("order") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plane fa-2x"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !((_co.alertInfo == null) ? null : _co.alertInfo.description); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.alertInfo == null) ? null : _co.alertInfo.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.alertInfo == null) ? null : _co.alertInfo.description); _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "calendar-icon ", _co.calendarStep, ""); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "message-icon ", _co.offerStep, ""); _ck(_v, 11, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "flight-icon ", _co.orderStep, ""); _ck(_v, 14, 0, currVal_5); }); }
export function View_AlertsProcessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-process", [], null, null, null, View_AlertsProcessComponent_0, RenderType_AlertsProcessComponent)), i1.ɵdid(1, 573440, null, 0, i3.AlertsProcessComponent, [i4.LoggerService], null, null)], null, null); }
var AlertsProcessComponentNgFactory = i1.ɵccf("alerts-process", i3.AlertsProcessComponent, View_AlertsProcessComponent_Host_0, { alertInfo: "alertInfo", deploymentOffers: "deploymentOffers", nbrOrders: "nbrOrders", SELECTIONS: "SELECTIONS", nbrActiveOrders: "nbrActiveOrders" }, { setting: "setting" }, []);
export { AlertsProcessComponentNgFactory as AlertsProcessComponentNgFactory };
