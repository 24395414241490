/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-preloader-rosters.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-preloader-rosters.component";
var styles_DashboardPreloaderRostersComponent = [i0.styles];
var RenderType_DashboardPreloaderRostersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPreloaderRostersComponent, data: {} });
export { RenderType_DashboardPreloaderRostersComponent as RenderType_DashboardPreloaderRostersComponent };
export function View_DashboardPreloaderRostersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "dashboard-preloader-rosters shadow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "dashboard-preloader-rosters-big-square"]], null, null, null, null, null))], null, null); }
export function View_DashboardPreloaderRostersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-rosters", [], null, null, null, View_DashboardPreloaderRostersComponent_0, RenderType_DashboardPreloaderRostersComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardPreloaderRostersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPreloaderRostersComponentNgFactory = i1.ɵccf("dashboard-preloader-rosters", i2.DashboardPreloaderRostersComponent, View_DashboardPreloaderRostersComponent_Host_0, {}, {}, []);
export { DashboardPreloaderRostersComponentNgFactory as DashboardPreloaderRostersComponentNgFactory };
