import { BannerUpdater } from './../../app/modules/shared/banner/banner-updater.dispatcher';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CacheService } from '../storage/cache.service';
import { NavigationService } from '../utils/navigation.service';



@Injectable()
export class BannerGuardService implements CanActivate {

    constructor(
        private cache: CacheService,
        private navigationService: NavigationService,
        private bannerUpdater: BannerUpdater,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const url = state.url;

        if (url.match('^\/public') === null) {
            this.bannerUpdater.show();
        } else {
            this.bannerUpdater.hide();
        }

        return true;
    }
}
