import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

@Injectable()
export class ContactUsService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  sendEmail(emailValues) {
    let body = emailValues;
    let options = this._httpService.createRequestOptions();

    return this.http.post(URL_API_EMALSYS + "/contact-us", body, options);
  }

  // Method to send an e-mail for
  public sendEmailDisconnected(email) {
    let body = email;
    let options = this._httpService.createRequestOptions();

    return this.http.post(URL_API_EMALSYS + "/contact-us/disconnected", body, options);
  }
  // check the recaptcha response
  public checkRecaptchaResponse(body) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/contact-us/disconnected/recaptcha", body, options);
  }
}
