import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { HttpClient } from '@angular/common/http';

import { URL_API_EMALSYS } from '../../../environments/environment';
import { TagDispatcherService } from './tag.dispatcher';
import { TagInterface } from '../../../app/model/tag.interface';
import { ApiCacherService } from '../../utils/api-cacher.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class TagsService {

  static readonly ALL_ROSTER_TAGS = 'all_roster_tags_';

  private busy = {
    allTags: {
      value: false
    },
  };

  constructor(
    private http: HttpClient,
    private _httpService: HttpService,
    private _tagDispatcher: TagDispatcherService,
    private _apiCacher: ApiCacherService
  ) { }

  getAllRosterTags(rosterId: number) {
    return this._apiCacher.doWrapper<TagInterface[]>(
      TagsService.ALL_ROSTER_TAGS + rosterId,
      () => {
        let options = this._httpService.createRequestOptions();
        return this.http.get<TagInterface[]>(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags', options);
      }).pipe(
        tap((res: TagInterface[]) => {
          this._tagDispatcher.publishRosterTags(res);
        })
      );
  }

  refreshTags(rosterId: number) {
    this._apiCacher.removeIfPresent(TagsService.ALL_ROSTER_TAGS + rosterId);
    this.getAllRosterTags(rosterId).subscribe();
  }

  addNewTagToRoster(rosterId: number, tagName: string) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags', { 'name': tagName }, options)
      .pipe(
        tap(() => {
          this.refreshTags(rosterId);
        })
      );
  }

  deleteTagRoster(tagId: number, rosterId: number) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + '/rosters/tags/' + tagId, options)
      .pipe(
        tap(() => {
          this.refreshTags(rosterId);
        })
      );
  }

  // ========== TODO: move to proper service
  addRosterMembersTags(rosterId: number, usersId: any[], tags: any[]) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags/members', { 'members': usersId, 'tags': tags }, options);
  }

  removeRosterMembersTags(rosterId: number, usersId: any[], tagsId: any[]) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/rosters/" + rosterId + "/tags/members?members=" + usersId.join() + "&tags=" + tagsId.join(), options);
  }

  getRosterMembersTagsByMember(rosterId: number, userId: any) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/rosters/" + rosterId + '/tags/members?member=' + userId, options);
  }
}
