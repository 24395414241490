import { Injectable } from '@angular/core';

import { HttpService } from '../../../../services/api/http.service';

// Constants
import { URL_API_EMALSYS } from '../../../../environments/environment';


@Injectable()
export class IndicatorService {
    readonly api = URL_API_EMALSYS + '/indicators';

    constructor(
        public http: HttpService
    ) {

    }

    public getIndicators(body?: any) {
        let url = this.api;
        return this.http.post(url, body);
    }

    public getIndicator(idIndicator: number) {
        let url = this.api + "/" + idIndicator;
        return this.http.get(url);
    }

    public serveIndicator(body: any, id) {
        let url = this.api + "/serve/" + id;
        return this.http.post(url, body);
    }
}
