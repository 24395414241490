<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Send an alert</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body" *ngIf="step==1">
  <div class="info-component">
    <i class="fa fa-info-circle custom-left-icon" aria-hidden="true"></i>
    <p>Send an alert to receive members' availability.</p>
  </div>

  <div *ngIf="events && events.length > 0 && !option">
    <label for="select-event">Choose an event</label>
    <select id="select-event" class="event-modal-select" [(ngModel)]="selectedEvent" name="event" type="select">
          <option *ngFor="let event of events" [ngValue]="event">{{ event.name }}</option>
      </select>
  </div>
  <div *ngIf="!events || events.length == 0 && !option">
    <h3>There are no events created. </h3>
  </div>
  <button *ngIf="checkOrganisationAssociate()" class="option-button" (click)='onClickOption()'>
      <p *ngIf="!option"> Or create a new one <i class="fa fa-chevron-down custom-right-icon"></i></p>
      <p *ngIf="option">Select an existing event <i class="fa fa-chevron-up custom-right-icon"></i></p>
    </button>
  <div *ngIf="option">
    <div class="form-group">
      <label for="AlertName">Alert name</label>
      <input id="AlertName" class="form-control" maxlength="20" [(ngModel)]="createdEvent.name"/>
    </div>
    <div class="form-group">
      <label for="Country">Country</label>
        <select2 id="Country" class="select" cssImport="true" [data]="countries" [options]="{multiple: false}" [value]="createdEvent.country" (valueChanged)="changedSelectCountry($event)">
        </select2>
    </div>
    <div class="form-group">
      <label for="StartDate">Start date</label>
      <div class="form-group">
        <p-calendar id="StartDate" [maxDate]="createdEvent.endDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="createdEvent.startDate"></p-calendar>
      </div>
    </div>
    <div class="form-group">
      <label for="EndDate">End date</label>
      <div class="form-group">
        <p-calendar id="EndDate" [minDate]="createdEvent.startDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="createdEvent.endDate"></p-calendar>
      </div>
    </div>
  </div>
</div>

<div class="modal-body" *ngIf="step==2">
  <div class="info-component">
    <i class="fa fa-info-circle custom-left-icon" aria-hidden="true"></i>
    <p>Add a message for all mobilised members</p>
  </div>
  <div class="title-component">
    <input class="form-control" placeholder="Title" [(ngModel)]="notificationTitle" />
    <h6>SMS and Push Notifications will only contain the title (up to 115 characters)</h6>
  </div>
  <textarea class="form-control" [rows]="4" pInputTextarea autoResize="true" [(ngModel)]="notificationBody">
</textarea >
</div>
<div class="loading-custom" *ngIf="loading">
  <spinner-component>
  </spinner-component>
</div>
<!--Modal footer-->
 <div class="modal-footer" *ngIf="step==1">
  <button [disabled]="missingEvent()" [ngClass]="{'disabled':missingEvent()}" class="btn emalsys_button blue margin-b margin-r" (click)="nextStep()">Next step<i class="fa fa-arrow-right custom-right-icon" aria-hidden="true"></i></button>
</div>
<div class="modal-footer step2-footer" *ngIf="step==2">
  <button [disabled]="loading" class="btn emalsys_button margin-b margin-r" (click)="previousStep()"><i class="fa fa-arrow-left custom-left-icon" aria-hidden="true"></i>Back</button>
  <button [disabled]="!notificationTitle || !notificationBody || loading"
          [ngClass]="{'disabled': !notificationTitle || !notificationBody || loading}"
          class="btn emalsys_button green margin-b margin-r" (click)="sendAlert()">Send alert<i class="fa fa-share custom-right-icon" aria-hidden="true"></i></button>
</div>

<div id="createEventToast" class="createDeploymentOfferToast">Event creation failed</div>
<div id="createAlertToast" class="createDeploymentOfferToast">Alert creation failed</div>
