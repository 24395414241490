import { Component, OnInit } from '@angular/core';

// Service
import { ContactUsService } from '../../../../../services/api/contact-us.service';
import { ToastService     } from '../../../../../services/utils/toast.service';
import { LoggerService    } from '../../../../../services/utils/logger.service';

@Component({
  selector   : 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls  : ['./contact-us.component.scss']
})


export class ContactUsComponent implements OnInit {

  public subject: any;
  public content: any;

  constructor(
    private _contactUsService: ContactUsService,
    private _toastService: ToastService,
    private _loggerService: LoggerService
  ) {}

  ngOnInit() {}

  sendEmail() {
    let emailValues = {subject: this.subject, content: this.content};
    this._contactUsService.sendEmail(emailValues)
      .subscribe(res => {
        this._loggerService.log('EMAIL SENT');
      });
  }

  showToastContactUs() {
    this._toastService.show("snackContact", "show", 3000);
  }
}
