<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Deployment order details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body">
    <div *ngIf="deploymentOrders && deploymentOrders.length > 0">
      <label for="DeploymentOrders">Select the deployment order:</label>
      <select class="emalsys_select" id="DeploymentOrders" [(ngModel)]="selectedOrder" name="order" type="select">
                <option *ngFor="let order of deploymentOrders" [ngValue]="order">{{ order.deployment_order.position }}</option>
            </select>
    </div>
    <div class="deployment-order-description-container">
      <div class="deployment-order-description-leftpart">
        <div class="deployment-order-description-subheader">
          <p class="deployment-order-description-position">Position: {{selectedOrder.deployment_order.position}}</p>
          <p class="deployment-order-description-country">Country: {{selectedOrder.event.country_name}}</p>
          <div class="deployment-order-description-date-container">
            <p class="deployment-order-description-date">{{selectedOrder.deploymentdate | date: "fullDate"}}</p>
            <i class="fa fa-arrow-right fa-1x deployment-order-description-arrow" aria-hidden="true"></i>
            <p class="deployment-order-description-date">{{selectedOrder.end_date | date: "fullDate"}}</p>
          </div>

          <p class="deployment-order-description-sender">Deploying manager: {{selectedOrder.deployment_order.sender.firstname}} {{selectedOrder.deployment_order.sender.lastname}} - {{selectedOrder.deployment_order.sender.profile.contact_email}}</p>

        </div>
        <p class="deployment-order-description-description">{{selectedOrder.message}}</p>
      </div>
      <div class="deployment-order-description-rightpart">
        <div class="deployment-order-description-additional">
          <div class="deployment-order-description-additional-airport" *ngIf="selectedOrder.airport">
            <p class="deployment-order-description-additional-airport-label">Airport</p>
            <p class="deployment-order-description-additional-airport-value">{{selectedOrder.airport}}</p>
          </div>
          <div class="deployment-order-description-additional-flight" *ngIf="selectedOrder.flight_number">
            <p class="deployment-order-description-additional-flight-label">Flight number</p>
            <p class="deployment-order-description-additional-flight-value">{{selectedOrder.flight_number}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-name" *ngIf="selectedOrder.insurance_name">
            <p class="deployment-order-description-additional-insurance-name-label">Insurance name</p>
            <p class="deployment-order-description-additional-insurance-name-value">{{selectedOrder.insurance_name}}</p>
          </div>
          <div class="deployment-order-description-additional-insurance-number" *ngIf="selectedOrder.insurance_number">
            <p class="deployment-order-description-additional-insurance-number-label">Insurance number</p>
            <p class="deployment-order-description-additional-insurance-number-value">{{selectedOrder.insurance_number}}</p>
          </div>
        </div>
        <div class="deployment-order-description-documents" *ngIf="selectedOrder.deployment_document.length">
          <p class="deployment-order-description-documents-label">Deployment documents</p>

          <div *ngFor="let doc of selectedOrder.deployment_document">
            <button class="deployment-order-description-doc-name" (click)="downloadDoc(doc.document)">
            <img class="doc-icon" src="assets/images/Docs_Icon.png" />
            <p>{{doc.document.title}}</p>
          </button>
          </div>
        </div>
      </div>
    </div>
</div>

<!--Modal footer-->
<div class="modal-footer">

</div>
