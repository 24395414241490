import { InitializedDashboard } from '../../../../model/utils/initialization-model.interface';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../../../app.constants';
import { CachedOrganisationService } from '../../../../../services/api/organisation/organisation.cacher';
import { UserRights, ROSTER_MANAGER_RIGHTS_LEVEL } from './../../../../model/right.interface';
import { OrganisationDispatcherService } from '../../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { tap, map } from 'rxjs/operators';
import { OrganisationInterface } from '../../../../model/organisation.interface';
import { AlertService } from '../../../../../services/api/alert/alert.api';
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { CacheService } from './../../../../../services/storage/cache.service';
import { LoggerService } from './../../../../../services/utils/logger.service';
import { UserInterface } from '../../../../model/user.interface';
import { RightService } from '../../../../../services/utils/right.service';
import { combineLatest ,  Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';


export abstract class DashboardComponentModel implements OnDestroy {
    protected _organisationService: OrganisationApiService;
    protected _cacheService: CacheService;
    protected _loggerService: LoggerService;
    protected _alertEmalsysService: AlertService;
    protected _profileDispatcher: ProfileDispatcherService;
    protected _organisationDispatcher: OrganisationDispatcherService;
    protected _organisationCachedService: CachedOrganisationService;
    protected _rightService: RightService;

    protected userRights: UserRights;

    public organisation: OrganisationInterface;
    public eventsCount = 0;

    protected dashboardInitialized: boolean;

    protected subscriptions$: Subscription[] = [];

    canIAddOrganisationMembers = false;
    canIAddRosterMembers = false;

    initialize(organisationId: number) {
        const organisation$ = this._organisationDispatcher
            .getSelectedOrganisation()
            .pipe(
                tap(
                    response => {
                        this.organisation = response;
                        this._loggerService.log("ORGANISATION : ", this.organisation);
                        this.subscriptions$.push(this.getEventsCount().subscribe());
                    }
                )
            );

        return combineLatest(
            organisation$,
            this._profileDispatcher.getProfile()
        ).pipe(
            map(x => {
                return <InitializedDashboard>{
                    organisation: x[0] as OrganisationInterface,
                    user: x[1] as UserInterface,
                };
            }),
            tap(
                (initialized) => {
                    this.canIAddOrganisationMembers = this._rightService.checkOrganisationManagerRight(
                        initialized.user,
                        initialized.organisation.id,
                        ORGANISATION_MANAGER_RIGHTS_LEVEL.Associate
                    );
                    this.canIAddRosterMembers = this._rightService.checkRosterManagerRight(
                        initialized.user,
                        initialized.organisation.id,
                        ROSTER_MANAGER_RIGHTS_LEVEL.Guest
                    );
                }
            ),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.forEach(element => {
            element.unsubscribe();
        });
    }

    getOrganisation(id: number): void {
        // return this._organisationService.getOrgaDetails(id)
    }

    getEventsCount() {
        return this._alertEmalsysService.getOrgaAlerts(this.organisation.id)
            .pipe(
                tap(response => {
                    this.eventsCount = response instanceof Array ? response.length : 0;
                })
            );
    }
}
