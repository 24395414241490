/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/component-alert/component-alert.component.ngfactory";
import * as i3 from "../components/component-alert/component-alert.component";
import * as i4 from "../../../../../services/api/usereventanswer.service";
import * as i5 from "../../../../../services/api/deployment-order.service";
import * as i6 from "../../../../../services/api/deployment.service";
import * as i7 from "@angular/common";
import * as i8 from "./dashboard-alerts.component";
import * as i9 from "../../../../../services/utils/logger.service";
import * as i10 from "../../../../../services/api/alert/alert.dispatcher";
var styles_DashboardAlertsComponent = [i0.styles];
var RenderType_DashboardAlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardAlertsComponent, data: {} });
export { RenderType_DashboardAlertsComponent as RenderType_DashboardAlertsComponent };
function View_DashboardAlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "component-alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertComponent, [i4.UserEventAnswerService, i5.DeploymentOrderService, i6.DeploymentService], { alert: [0, "alert"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DashboardAlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../../../../assets/images/not-yet-field.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This section is empty"]))], null, null); }
export function View_DashboardAlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "shadow"], ["id", "dashboard-alerts-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Alerts"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "dashboard-alerts-component-alerts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardAlertsComponent_1)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardAlertsComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.alerts.length == 0); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_DashboardAlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-alerts", [], null, null, null, View_DashboardAlertsComponent_0, RenderType_DashboardAlertsComponent)), i1.ɵdid(1, 114688, null, 0, i8.DashboardAlertsComponent, [i9.LoggerService, i10.AlertDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardAlertsComponentNgFactory = i1.ɵccf("dashboard-alerts", i8.DashboardAlertsComponent, View_DashboardAlertsComponent_Host_0, { organisation: "organisation" }, {}, []);
export { DashboardAlertsComponentNgFactory as DashboardAlertsComponentNgFactory };
