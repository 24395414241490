import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Uploader, UploadItem } from 'angular2-http-file-upload';
import { URL_API_EMALSYS } from '../../../../../environments/environment';
import { OrganisationApiService } from '../../../../../services/api/organisation/organisation.api';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { WSSEService } from '../../../../../services/security/wsse.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { OrganisationCompliancesService } from '../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api';
import { RosterCompliancesService } from '../../../../../services/api/roster/dependencies/compliances/roster-compliances.api';

@Component({
  selector: 'app-add-document-compliances-modal',
  templateUrl: './add-document-compliances-modal.component.html',
  styleUrls: ['./add-document-compliances-modal.component.scss']
})
export class AddDocumentCompliancesModalComponent implements OnInit {
  @Output() onAdd = new EventEmitter<any>();
  @Input() complianceId;
  @Input() context;
  public gallery = false;
  public selectedDocument: any;
  public documentList: any = [];
  public documentListSelect2: any = [];

  public titleFile: string;
  public extensionFile: string;
  public selectedFile: File;
  public uploadModal: NgbActiveModal;

  public loading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _profileService: ProfileService,
    private _loggerService: LoggerService,
    private _rosterCompliancesService: RosterCompliancesService,
    private _organisationService: OrganisationCompliancesService,
    private _uploaderService: Uploader,
    private _wsseService: WSSEService,
  ) { }

  ngOnInit() {
    this.getDocuments();
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
      let regExp = new RegExp('\.[0-9a-z]+$', 'i');
      this.extensionFile = regExp.exec(this.selectedFile.name)[0];
    }
  }

  changedSelectDocument(data) {
    this.selectedDocument.id = data.value;
    this.selectedDocument.document = data.data[0].document;
    this.selectedDocument.text = data.data[0].text;
  }

  getDocuments() {
    this._profileService.getMyDocuments().subscribe(res => {
      this.documentList = res;
      this.documentListSelect2 = [];
      for (let i = 0; i < this.documentList.length; i++) {
        this.documentListSelect2.push(
          {
            id: this.documentList[i]['id'],
            document: this.documentList[i],
            text: this.documentList[i]['title']
          }
        );
      }
      if (this.documentListSelect2 && this.documentListSelect2.length > 0) {
        this.selectedDocument = this.documentListSelect2[0];
      }
    });
  }

  addCompliance(selectedDocumentId) {
    this.loading = true;
    if (this.context === 'roster') {
      this._rosterCompliancesService.addComplianceDocument(this.complianceId, selectedDocumentId).subscribe(res => {
        this.onAdd.emit("Compliance document added");
        this.loading = false;
        this.activeModal.close();
      });
    } else if (this.context === 'organisation') {
      this._organisationService.addComplianceDocument(this.complianceId, selectedDocumentId).subscribe(res => {
        this.onAdd.emit("Compliance document added");
        this.loading = false;
        this.activeModal.close();
      });
    } else {
      this._loggerService.log("Missing context");
    }
  }

  uploadDoc() {
    this.loading = true;
    let uploadItem = new UploadItem();
    uploadItem.url = URL_API_EMALSYS + "/me/docs";
    uploadItem.headers = { 'x-wsse': this._wsseService.getHeaderValue() };
    if (this.titleFile) {
      uploadItem.formData = { title: this.titleFile + this.extensionFile };
    } else {
      uploadItem.formData = { title: this.selectedFile.name };
    }
    uploadItem.file = this.selectedFile;
    this.submitDoc(uploadItem);
  }

  submitDoc(item: UploadItem) {
    this._uploaderService.onSuccessUpload = (item2, response, status, headers) => {
      this.addCompliance(response[response.length - 1].id);
      this._loggerService.log("Success uploading file: ", item2, response, status, headers);
    };
    this._uploaderService.onErrorUpload = (item2, response, status, headers) => {
      this._loggerService.error("Error uploading file: ", item2, response, status, headers);
    };
    this._uploaderService.onCompleteUpload = (item2, response, status, headers) => {
      this.titleFile = null;

    };
    this._uploaderService.upload(item);
  }
}
