<div class="roster-members-component shadow">

  <div class="title preloader"></div>

    <div class="roster-members-component-members">
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
    <div class="square preloader"></div>
  </div>
</div>
