import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Services
import { HttpService } from '../http.service';
// Data
import { URL_API_EMALSYS } from '../../../environments/environment';
// Interfaces
import { OrganisationInterface } from '../../../app/model/organisation.interface';
import { OrganisationSkillInterface } from '../../../app/model/organisation-skill.interface';
import { UserInterface } from '../../../app/model/user.interface';
import { RosterInterface } from '../../../app/model/roster.interface';

@Injectable()
export class OrganisationApiService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  getAllOrganisations() {
    let options = this._httpService.createRequestOptions();

    return this.http.get<OrganisationInterface[]>(URL_API_EMALSYS + "/organisations", options);
  }

  getUserOrganisations() {
    let options = this._httpService.createRequestOptions();

    return this.http.get<OrganisationInterface[]>(URL_API_EMALSYS + "/organisations?method=by_me", options);
  }

  getOrganisation(orgaId) {
    let options = this._httpService.createRequestOptions();

    return this.http.get<OrganisationInterface>(URL_API_EMALSYS + "/organisations/" + orgaId, options);
  }

  getOrgaMembers(orgaId, filters) {
    let options = this._httpService.createRequestOptions();

    let url = URL_API_EMALSYS + "/organisations/" + orgaId + "/members";
    for (let key in filters) {
      if (filters.hasOwnProperty(key) && filters[key] !== "" && filters[key] !== null) {
        url = url + (/\?/.test(url) ? "&" : "?") + key + "=" + filters[key];
      }
    }
    return this.http.get<UserInterface[]>(url, options);
  }

  // getOrgaNews(orgaId) {
  //   let options = this._httpService.createRequestOptions();

  //   return this.http.get<OrganisationNewsInterface[]>(URL_API_EMALSYS + "/organisations/" + orgaId + "/news", options);
  // }

  // getOrgaNewsDetails(orgaId, newsId) {
  //   let options = this._httpService.createRequestOptions();

  //   return this.http.get<OrganisationNewsInterface>(URL_API_EMALSYS + "/organisations/" + orgaId + "/news/" + newsId, options);
  // }

  updateOrgaNews(newsId, news) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/news/" + newsId, news, options);
  }

  deleteOrgaNews(newsId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/news/" + newsId, options);
  }

  createOrgaNews(orgaId, news) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/news", news, options);
  }

  getOrgaSkills(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.get<OrganisationSkillInterface[]>(URL_API_EMALSYS + "/organisation/" + orgaId + "/skills", options);
  }

  getOrgaRosters(orgaId) {
      let options = this._httpService.createRequestOptions();
      return this.http.get<RosterInterface[]>(this.getOrganisationRostersRoute(orgaId), options);
  }

  /**
   * Defined to avoid code duplication
   * @param orgaId
   */
  getOrganisationRostersRoute(orgaId) {
      return URL_API_EMALSYS + "/organisations/" + orgaId + "/rosters";
  }

  sendEmail(orgaId, emailContent) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/emailnotification", emailContent, options);
  }

  // =====================Organisation Member invitations=====================//
  addMembers(orgaId, params) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/invite/members", params, options);
  }

  acceptOrganisationMemberInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/accept/member", {}, options);
  }

  declineOrganisationMemberInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/decline/member", options);
  }

  // =====================Organisation Manager invitations=====================//
  addManagers(orgaId, params) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId + "/invite/managers", params, options);
  }

  acceptOrganisationManagerInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/accept/manager", {}, options);
  }

  declineOrganisationManagerInvitation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/decline/manager", options);
  }
  // =====================Organisation Manager abilities=====================//
  freezeOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/freeze/member?user_id=" + userId, {}, options);
  }

  activeOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/active/member?user_id=" + userId, {}, options);
  }

  deleteOrganisationManager(orgaId, userId, context) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/remove/manager?user_id=" + userId + "&context=" + context, options);
  }
  deleteOrganisationMember(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId + "/remove/member?user_id=" + userId, options);
  }
  setActiveOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/promote?user_id=" + userId, {}, options);
  }

  setAssociateRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/guest?user_id=" + userId, {}, options);
  }
  setManagerRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/manager?user_id=" + userId, {}, options);
  }
  setAdminRightsToOrganisationManager(orgaId, userId) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/" + orgaId + "/rights/admin?user_id=" + userId, {}, options);
  }
  deleteOrganisation(orgaId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/" + orgaId, options);
  }

  updateOrganisation(orgaId: number, body: FormData) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + orgaId, body, options);
  }

  // ------------------------------------------------------------------------//
  // ----------------------------- COMPLIANCES (Manager side) ------------------------------//
  // ------------------------------------------------------------------------//

  addOrganisationCompliance(idOrganisation, body) {
    let options = this._httpService.createRequestOptions();
    return this.http.post(URL_API_EMALSYS + "/organisations/" + idOrganisation + "/requirements", body, options);
  }
  getOrganisationCompliances(idOrganisation) {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/organisations/" + idOrganisation + "/requirements", options);
  }
  updateOrganisationCompliance(idRequirement, body) {
    let options = this._httpService.createRequestOptions();
    return this.http.put(URL_API_EMALSYS + "/organisations/requirements/" + idRequirement, body, options);
  }

  removeOrganisationCompliance(idRequirement) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/requirements/" + idRequirement, options);
  }
  // ------------------------------------------------------------------------//
  // ----------------------------- COMPLIANCES (Member side)------------------------------//
  // ------------------------------------------------------------------------//

  addComplianceDocument(comId, docId) {
    let options = this._httpService.createRequestOptions();
    let body = JSON.stringify(docId);
    return this.http.post(URL_API_EMALSYS + "/organisations/requirements/" + comId + "/documents/" + docId, body, options);
  }

  deleteComplianceDocument(comId) {
    let options = this._httpService.createRequestOptions();
    return this.http.delete(URL_API_EMALSYS + "/organisations/requirements/" + comId + "/documents", options);
  }
}
