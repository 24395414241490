import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared.module';
//Routing
import { OrgaNewsRouting } from './orga-news.routing';
// Components
import { OrgaNewsComponent } from './orga-news.component';


@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    SharedModule,
  ],
  declarations: [
    OrgaNewsComponent
  ],
  providers: []
})


export class OrgaNewsModule {}
