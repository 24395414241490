import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CacheService } from '../../../services/storage/cache.service';
import { NavigationService } from '../../../services/utils/navigation.service';
import { LoggerService } from '../../../services/utils/logger.service';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'public',
    templateUrl: './public-pages.component.html',
    styleUrls: ['./public-pages.component.scss']
})
export class PublicPagesComponent implements OnInit {
    // Variable declarations
    public yearCopyright: any;
    public environment = environment.envName;
    // public loggedUser = false;
    public cachedUser: any;

    constructor(
        private titleService: Title,
        private _loggerService: LoggerService,
        private _cacheService: CacheService,
        private _navigationService: NavigationService,
        private _modalService: NgbModal,
        ) {
            this.yearCopyright = new Date().getFullYear();
        }
        ngOnInit() {
            this.titleService.setTitle("Emalsys");
            this.cachedUser = this._cacheService.get(CacheService.USER);
            // this.loggedUser = this.cachedUser !== null;
            if (environment.disabledFeatures && environment.disabledFeatures.public) {
                if (this.cachedUser) {
                    if (this.goToDashboard()) {
                        this._loggerService.log('LOGIN FAIL : No organisations');
                    }
                }
            } else {
                // this._navigationService.goToHome();
            }

        }
        displayLoginModal() {
            this._modalService.open(LoginModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
        }

        goToDashboard() {
            if (this.cachedUser.organisation_managers && this.cachedUser.organisation_managers.length) {
                this._navigationService.goToWelcomeManager(this.cachedUser.organisation_managers);
                return true;
            } else if (this.cachedUser.organisation_managers && this.cachedUser.organisation_members.length) {
                this._navigationService.goToWelcomeResponder(this.cachedUser.organisation_members);
                return true;
            }
            return false;
        }

    }
