export const ROSTER_MANAGER_RIGHTS_LEVEL = {
    NotManager: 0,
    Guest: 1,
    Manager: 2,
    Supervisor: 3
};

// ORGANISATIONS
export const ORGANISATION_MANAGER_RIGHTS_LEVEL = {
    NotManager: 0,
    Associate: 1,
    Admin: 2,
    SuperAdmin: 3
};



export class UserRights {
    private static instance: UserRights;


    private constructor() { }

    public static organisation: {
        // Roster
        canCreateRoster: false,
        canViewAllRosters: false,
        canInviteRosterManager: false,
        canDeleteRoster: false,
        canInviteRosterGuest: false,
        canEditRoster: false,
        canViewRosterMembers: false,
        canInviteRosterMember: false,
        canAcceptJoinRequestToRoster: false,
        canRefuseJoinRequestToRoster: false,
        canSendMessageToRosterMembers: false,

        // Organisation
        canViewAllMembers: false,
        canInviteOrganisationMembers: false,
        canInviteOrganisationManager: false,
        canInviteOrganisationAdmin: false,
        canManageOrganisationRights: false,
        canRemoveOrganisationMember: false,
        canDeleteOrganisation: false,
        canEditOrganisation: false,
        // Events
        canCreateEvent: false,
        canDeleteEvent: false,
        canEditEvent: false,
        canAddMembersToEvent: false,
        // Deployments
        canSendDeploymentOffer: false,
        canSendDeploymentOrder: false,
        canViewDeployments: false,
        canEditDeployment: false,
        canShareDeploymentDocuments: false,
        canCancelDeploymentOffer: false,
        canConfirmDeploymentOffer: false,


    };

    public static roster: {
        canAddNewMembers: false,
    };

    static getInstance() {
        if (!this.instance) {
            this.instance = new UserRights();
        }
        return this.instance;
    }



}
