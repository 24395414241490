import { AbstractModel } from "./abstract-model";
export class DeploymentOfferInterface {
}
export class DeploymentOffer extends AbstractModel {
    constructor() {
        super(...arguments);
        this.id = null;
        this.event = null;
        this.usersId = [];
        this.position = '';
        this.startDate = null;
        this.endDate = null;
        this.message = '';
        this.rosterId = null;
    }
    static getInstance() {
        return new DeploymentOffer();
    }
    static from(map) {
        return AbstractModel.from(DeploymentOffer, map);
    }
    /**
     * Converts current object to an Alert
     *
     * @param fieldsToAdd List of field to keep in formdata. If you dont know, let it empty and all
     * all fields will be returned
     */
    toFormData(fieldsToAdd = []) {
        return super.toFormData(fieldsToAdd, (body, item) => {
            if (item === 'startDate' || item === 'endDate') {
                body.append(item, this[item].toLocaleString('en-EN'));
                return false;
            }
            return true;
        });
    }
}
