import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CacheService } from '../storage/cache.service';
import { LoggerService } from '../utils/logger.service';
import { NavigationService } from '../utils/navigation.service';

import { UserInterface } from '../../app/model/user.interface';
import { ProfileService } from '../api/profile/profile.api';

@Injectable()


export class RoutingGuardService implements CanActivate {

    constructor(
        private router: Router,
        private cache: CacheService,
        private logger: LoggerService,
        private navigationService: NavigationService,
        private _profileService: ProfileService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let user = this.cache.get(CacheService.USER);   // Check if the user is already logged in
        if (!user) {
            this.goToLogin();
            return false;
        } else {
            if (this.checkAccessRights(state.url, user)) {
                // Trigger the profile dispatcher.
                // We already know that the user is in the cache
                this._profileService.getProfile().subscribe();
                return true;
            } else {
                this.goToForbidden();
                return false;
            }
        }
    }

    goToLogin(): void {
        this.navigationService.goToPublic();
    }

    goToForbidden(): void {
        this.router.navigate(['/forbidden']);
    }

    checkAccessRights(url: string, user: UserInterface) {
        let regExp = new RegExp('responder\/profile');
        if (regExp.test(url)) {
            return true;
        } else if (this.checkRouteAccess(url, user, '^admin', 'module_admin_access')) {
            return true;
        } else if (this.checkRouteAccess(url, user, '^manager', 'module_manager_access')) {
            this.cache.set(CacheService.ISMANAGER_WEBSITE, 1);
            let regExpOrganisationId = new RegExp('organisation\/([0-9]+)');
            if (regExpOrganisationId.test(url)) {
                let id = parseInt(regExpOrganisationId.exec(url)[1], 10);
                for (let i = 0; i < user.organisation_managers.length; i++) {
                    let organisationId = user.organisation_managers[i].organisation.id;
                    if (organisationId === id) {
                        return true;
                    }
                }
                for (let i = 0; i < user.roster_managers.length; i++) {
                    let organisationId = user.roster_managers[i].roster.organisation.id;
                    if (organisationId === id) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        } else if (this.checkRouteAccess(url, user, '^responder', 'module_responder_access')) {
            this.cache.set(CacheService.ISMANAGER_WEBSITE, 0);
            let regExpOrganisationId = new RegExp('organisation\/([0-9]+)');
            if (regExpOrganisationId.test(url)) {
                let id = parseInt(regExpOrganisationId.exec(url)[1], 10);
                for (let i = 0; i < user.organisation_members.length; i++) {
                    let organisationId = user.organisation_members[i].organisation.id;
                    if (organisationId === id) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        }
        return false;
    }

    checkRouteAccess(url: string, user: UserInterface, pattern: string, right: string) {
        let regExp = new RegExp(pattern);
        // check if url matches pattern and if user has access to it
        return regExp.test(url.substr(1)) && user.voters[right];
    }

}
