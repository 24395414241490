import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ProfileDispatcherService } from './profile.dispatcher';
import { URL_API_EMALSYS } from '../../../environments/environment';
import { ApiCacherService } from '../../utils/api-cacher.service';
import { LoggerService } from '../../utils/logger.service';
import { HttpService } from '../http.service';
import { ProfileInterface } from '../../../app/model/profile.interface';
/**
 * ========================================
 *     THIS CLASS IS ALSO A CACHER
 * ========================================
 */
export class ProfileService {
    constructor(http, _httpService, _loggerService, _apiCacher, _profileDispatcher) {
        this.http = http;
        this._httpService = _httpService;
        this._loggerService = _loggerService;
        this._apiCacher = _apiCacher;
        this._profileDispatcher = _profileDispatcher;
        this.USER_PHONES = ProfileInterface.CACHE.USER_PHONES;
        this.ALL_SKILLS = ProfileInterface.CACHE.ALL_SKILLS; // GLOBAL DISPATCHER!
        this.ALL_LANGUAGES = ProfileInterface.CACHE.ALL_LANGUAGES; // SAME
        this.USER_SKILLS = ProfileInterface.CACHE.USER_SKILLS;
        this.USER_LANGUAGES = ProfileInterface.CACHE.USER_LANGUAGES;
        this.USER_DOCUMENTS = ProfileInterface.CACHE.USER_DOCUMENTS; // Keep? Remove?
        this.USER_EMERGENCY_CONTACTS = ProfileInterface.CACHE.USER_EMERGENCY_CONTACTS;
        /**
         * Each of the object inside busy
         * is passed by reference to apiCacher.do() to implement
         * the semaphore (the empty while)
         */
        this.busy = {
            getProfile: {
                value: false
            },
            getUserData: {
                value: false
            },
            getAllSkills: {
                value: false
            },
            getMyPhones: {
                value: false
            },
            getUserSkills: {
                value: false
            },
            getUserLanguages: {
                value: false
            },
            getUserDocuments: {
                value: false
            },
            getUserEmergencyContacts: {
                value: false
            },
            getAllLanguages: {
                value: false
            }
        };
        // Using ProfileService to communicate between ProfileComponent and PlatformComponent
        // The banner user picture is updated as it changes
        this.pictureSource = new BehaviorSubject(null);
        this.currentPicture = this.pictureSource.asObservable();
    }
    update(key, getter) {
        this._apiCacher.removeIfPresent(key);
        getter();
    }
    changePicture() {
        let picture;
        this.getProfileIdentity().subscribe(res => {
            picture = res.picture;
            this.pictureSource.next(picture);
        });
    }
    // General profile
    // Get the whole profile
    getProfile() {
        let options = this._httpService.createRequestOptions();
        return this._apiCacher.doWrapper(ProfileService.USER_PROFILE, () => {
            return this.http.get(URL_API_EMALSYS + "/me", options);
        }).pipe(tap((res) => this._profileDispatcher.nextProfile(res)));
    }
    getUserData() {
        this._apiCacher.doWrapper('user_data', // TODO: constant
        () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/me?filter=all_data", options);
        }).subscribe(res => this._profileDispatcher.nextUserData(res));
    }
    // Get the profile picture and the name
    getProfileIdentity() {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/me/identity", options);
    }
    refreshProfile() {
        this._apiCacher.removeIfPresent(ProfileService.USER_PROFILE);
        this.getProfile().subscribe();
    }
    updateProfile(profile) {
        let body = profile;
        this._loggerService.log("BODY", body);
        let options = this._httpService.createRequestOptions();
        // Update on endpoint
        return this.http.put(URL_API_EMALSYS + "/me", body, options).pipe(tap((res) => this.refreshProfile()));
    }
    // Availability
    changeAvailability(date) {
        let body = {
            end_unavailability: date
        };
        this._loggerService.log("BODY", body);
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/me", body, options).pipe(tap(() => {
            this.refreshProfile();
        }));
    }
    // Phones
    getMyPhones() {
        this._apiCacher.doWrapper(this.USER_PHONES, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/me/phones", options);
        }).subscribe((res) => {
            this._profileDispatcher.nextUserPhones(res);
        }, (res) => this._loggerService.log("ERROR GETTING PHONES:\n" + res));
    }
    /**
     * It returns an observable in order to make the
     * calling component aware of success
     */
    addPhone(phone) {
        let body = phone;
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/me/phones", body, options).pipe(catchError((err) => of(err)), tap((res) => this.updatePhones()));
    }
    deletePhone(phoneId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/me/phones/" + phoneId, options).pipe(catchError((err) => of(err)), tap((res) => this.updatePhones()));
    }
    updatePhones() {
        this._apiCacher.removeIfPresent(this.USER_PHONES);
        this.getMyPhones();
    }
    getPrefixes() {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/phones/regions", options);
    }
    // Skills
    // TODO: move it to the organisation service
    getAllSkills() {
        return this._apiCacher.doWrapper(this.ALL_SKILLS, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/skills", options);
        }).pipe(tap((res) => this._profileDispatcher.nextSkills(res)));
    }
    getMySkills() {
        // let options = this._httpService.createRequestOptions();
        // return this.http.get<UserTagInterface[]>(URL_API_EMALSYS + "/me/skills", options);
        return this._apiCacher.doWrapper(this.USER_SKILLS, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/me/skills", options);
        }).pipe(tap((res) => this._profileDispatcher.nextUserSkills(res)));
    }
    // private useApiCacher(loading, key, func: Function, dispatchingFunction: Function) {
    //   this._apiCacher.do(loading, key, func.)
    // }
    addSkill(idSkill) {
        let body = idSkill;
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/me/skills", body, options).pipe(catchError((err) => of(err)), tap(() => this.updateUserSkills()));
    }
    deleteSkill(idSkill) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/me/skills/" + idSkill, options).pipe(catchError((err) => of(err)), tap(() => this.updateUserSkills()));
    }
    updateAllSkills() {
        this._apiCacher.removeIfPresent(this.ALL_SKILLS);
        this.getAllSkills();
    }
    updateUserSkills() {
        this._apiCacher.removeIfPresent(this.USER_SKILLS);
        this.getMySkills();
    }
    // Languages
    getMyLanguages() {
        return this._apiCacher.doWrapper(this.USER_LANGUAGES, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/me/languages", options);
        }).subscribe((res) => {
            this._profileDispatcher.nextLanguages(res);
        });
    }
    addMeLanguage(language) {
        let body = language;
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/me/languages", body, options).pipe(catchError((err) => of(err)), tap(() => this.updateUserLanguages()));
    }
    deleteLanguage(languageId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/me/languages/" + languageId, options).pipe(catchError((err) => of(err)), tap(() => this.updateUserLanguages()));
    }
    updateUserLanguages() {
        this._apiCacher.removeIfPresent(this.USER_LANGUAGES);
        this.getMyLanguages();
    }
    // Emergency Contact
    // NEEDSUPDATE new pattern
    getMyEmergencyContact() {
        return this._apiCacher.doWrapper(this.USER_EMERGENCY_CONTACTS, () => {
            let options = this._httpService.createRequestOptions();
            return this.http.get(URL_API_EMALSYS + "/me/contacts", options);
        }).subscribe((res) => this._profileDispatcher.nextEmergencyContacts(res));
    }
    addEmergencyContact(emergencyContact) {
        let body = emergencyContact;
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/me/contacts", body, options).pipe(catchError((err) => of(err)), tap(() => this.updateEmergencyContacts()));
    }
    deleteEmergencyContact(emergencyContactId) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/me/contacts/" + emergencyContactId, options).pipe(catchError((err) => of(err)), tap(() => this.updateEmergencyContacts()));
    }
    updateEmergencyContacts() {
        this._apiCacher.removeIfPresent(this.USER_EMERGENCY_CONTACTS);
        this.getMyEmergencyContact();
    }
    // Documents
    getMyDocuments() {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/me/docs", options);
    }
    documentDelete(id) {
        let options = this._httpService.createRequestOptions();
        return this.http.delete(URL_API_EMALSYS + "/me/docs/" + id, options);
    }
    GdprAcceptation() {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/gdpr/accept", {}, options);
    }
    CookieAcceptation() {
        let options = this._httpService.createRequestOptions();
        return this.http.put(URL_API_EMALSYS + "/cookie/accept", {}, options);
    }
}
/**
 * Cache keys (used only here)
 */
ProfileService.USER_PROFILE = ProfileInterface.CACHE.USER_PROFILE;
