import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertInterface } from '../../../app/model/alert.interface';
import { Dispatcher } from '../../dispatcher.service';

@Injectable()
export class AlertDispatcherService extends Dispatcher {
    private organisationAlerts$ = new BehaviorSubject<AlertInterface[]>(null);
    private oldOrganisationAlerts$ = new BehaviorSubject<AlertInterface[]>(null);
    private myAlerts$ = new BehaviorSubject<AlertInterface[]>(null);
    private selectedAlert$ = new BehaviorSubject<AlertInterface>(null);

    public getOrganisationAlerts(): Observable<AlertInterface[]> {
        return this.pipeNotNull(this.organisationAlerts$);
    }

    public nextOrganisationAlerts(alerts: AlertInterface[]) {
        this.organisationAlerts$.next(alerts);
    }

    public getOldOrganisationAlerts(): Observable<AlertInterface[]> {
        return this.pipeNotNull(this.oldOrganisationAlerts$);
    }

    public nextOldOrganisationAlerts(alerts: AlertInterface[]) {
        this.oldOrganisationAlerts$.next(alerts);
    }

    public getMyAlerts(): Observable<AlertInterface[]> {
        return this.pipeNotNull(this.myAlerts$);
    }

    public nextMyAlerts(alerts: AlertInterface[]) {
        this.myAlerts$.next(alerts);
    }

    public getSelectedAlert(): Observable<AlertInterface> {
        return this.pipeNotNull(this.selectedAlert$);
    }

    public nextSelectedAlert(alert: AlertInterface | number) {
        if (typeof alert === 'number') {
            alert = this.organisationAlerts$.getValue()
                .find(x => x.id === alert);
        }
        if (alert) {
            this.selectedAlert$.next(alert);
        }
    }

    public getSelectedAlertAsScalar(): AlertInterface {
        return this.selectedAlert$.getValue();
    }



}
