/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../charts/pie-chart/pie-chart.component.ngfactory";
import * as i3 from "../charts/pie-chart/pie-chart.component";
import * as i4 from "../../../../services/storage/cache.service";
import * as i5 from "../services/chart-registration.service";
import * as i6 from "../services/chart-data-loader.service";
import * as i7 from "../charts/pie-grid/pie-grid.component.ngfactory";
import * as i8 from "../charts/pie-grid/pie-grid.component";
import * as i9 from "../charts/bar-chart/bar-chart.component.ngfactory";
import * as i10 from "../charts/bar-chart/bar-chart.component";
import * as i11 from "../charts/line-chart/line-chart.component.ngfactory";
import * as i12 from "../charts/line-chart/line-chart.component";
import * as i13 from "../charts/number-card-chart/number-card-chart.component.ngfactory";
import * as i14 from "../charts/number-card-chart/number-card-chart.component";
import * as i15 from "../charts/stacked-vertical-bar-chart/stacked-vertical-bar-chart.component.ngfactory";
import * as i16 from "../charts/stacked-vertical-bar-chart/stacked-vertical-bar-chart.component";
import * as i17 from "@angular/common";
import * as i18 from "./indicator.component";
var styles_IndicatorComponent = [i0.styles];
var RenderType_IndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndicatorComponent, data: {} });
export { RenderType_IndicatorComponent as RenderType_IndicatorComponent };
function View_IndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "pie-chart", [["style", "width: 100%"]], null, null, null, i2.View_PieChartComponent_0, i2.RenderType_PieChartComponent)), i1.ɵdid(1, 9027584, null, 0, i3.PieChartComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Valeur"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_IndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "pie-grid", [["style", "width: 100%"]], null, null, null, i7.View_PieGridComponent_0, i7.RenderType_PieGridComponent)), i1.ɵdid(1, 9027584, null, 0, i8.PieGridComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Valeur"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_IndicatorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "bar-chart", [["style", "width: 100%"]], null, null, null, i9.View_BarChartComponent_0, i9.RenderType_BarChartComponent)), i1.ɵdid(1, 9027584, null, 0, i10.BarChartComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Valeur"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_IndicatorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "line-chart", [["style", "width: 100%"]], null, null, null, i11.View_LineChartComponent_0, i11.RenderType_LineChartComponent)), i1.ɵdid(1, 9027584, null, 0, i12.LineChartComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Valeur"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_IndicatorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "number-card-chart", [["style", "width: 100%"]], null, null, null, i13.View_NumberCardChartComponent_0, i13.RenderType_NumberCardChartComponent)), i1.ɵdid(1, 9027584, null, 0, i14.NumberCardChartComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Nombre"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_IndicatorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "stacked-vertical-bar-chart", [["style", "width: 100%"]], null, null, null, i15.View_StackedVerticalBarChartComponent_0, i15.RenderType_StackedVerticalBarChartComponent)), i1.ɵdid(1, 9027584, null, 0, i16.StackedVerticalBarChartComponent, [i1.KeyValueDiffers, i4.CacheService, i5.ChartRegistration, i6.ChartDataLoaderService], { view: [0, "view"], title: [1, "title"], axis: [2, "axis"], filters: [3, "filters"], indicatorConfig: [4, "indicatorConfig"], selectedEvent: [5, "selectedEvent"], selectedRoster: [6, "selectedRoster"], frequence: [7, "frequence"] }, null), i1.ɵpod(2, { showXAxis: 0, showYAxis: 1, showXAxisLabel: 2, showYAxisLabel: 3, xAxisLabel: 4, yAxisLabel: 5 }), i1.ɵpod(3, { idIndicator: 0, type: 1, computedMethod: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartDimensions; var currVal_1 = _co.indicator.full_name; var currVal_2 = _ck(_v, 2, 0, true, true, true, true, _co.xAxisLabel, "Valeur"); var currVal_3 = _co.filters; var currVal_4 = _ck(_v, 3, 0, _co.indicator.id, _co.indicator.type_graphique, _co.computedMethod); var currVal_5 = _co.selectedEvent; var currVal_6 = _co.selectedRoster; var currVal_7 = _co.frequence; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_IndicatorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartDiv: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["chart", 1]], null, 12, "div", [["class", "indicatorChart shadow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_1)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_2)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_3)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_4)), i1.ɵdid(9, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_5)), i1.ɵdid(11, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IndicatorComponent_6)), i1.ɵdid(13, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.indicator.type_graphique == "camembert"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.indicator.type_graphique == "cible"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.indicator.type_graphique == "bar"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.indicator.type_graphique == "courbe"); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.indicator.type_graphique == "nombre"); _ck(_v, 11, 0, currVal_4); var currVal_5 = ((_co.indicator.type_graphique == "histogramme") || (_co.indicator.type_graphique == "")); _ck(_v, 13, 0, currVal_5); }, null); }
export function View_IndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-indicator", [], null, null, null, View_IndicatorComponent_0, RenderType_IndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i18.IndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndicatorComponentNgFactory = i1.ɵccf("app-indicator", i18.IndicatorComponent, View_IndicatorComponent_Host_0, { indicator: "indicator", chartDimensions: "chartDimensions", filters: "filters", xAxisLabel: "xAxisLabel", selectedEvent: "selectedEvent", selectedRoster: "selectedRoster", frequence: "frequence" }, {}, []);
export { IndicatorComponentNgFactory as IndicatorComponentNgFactory };
