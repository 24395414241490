import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { RightService } from '../../../../../services/utils/right.service';
import { ToastService } from '../../../../../services/utils/toast.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../app.constants';
import { OrganisationNewsService } from './../../../../../services/api/organisation/dependencies/news/organisation-news.api';
import { CachedOrganisationService } from './../../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from './../../../../../services/api/organisation/organisation.dispatcher';

@Component({
  selector: 'orga-news-manager',
  templateUrl: './orga-news-manager.component.html',
  styleUrls: ['./orga-news-manager.component.scss']
})

export class OrgaNewsManagerComponent implements OnInit, OnDestroy {
  public modalRef;
  public profile;
  public editEnabled = false;
  public toBeDeleted = false;
  public menuActive = false;

  public newsEdited: any = {};
  public newsCreated: any = {};
  public adding = false;

  public orgaId: number;
  public orgaDetails: any;

  public allNews: any;

  public newsId: number;
  public newsInfo: any;
  public loading = false;
  public type = [
    {
      id: 0,
      text: 'News'
    },
    {
      id: 1,
      text: 'Event'
    },
    {
      id: 2,
      text: 'Job Offer'
    }
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    public _toastService: ToastService,
    private titleService: Title,
    protected route: ActivatedRoute,
    protected _routerService: Router,
    protected _organisationNewsService: OrganisationNewsService,
    protected _organisationDispatcher: OrganisationDispatcherService,
    protected _cachedOrganisationService: CachedOrganisationService,
    protected _profileService: ProfileService,
    protected _rightService: RightService,
    protected _loggerService: LoggerService,
    protected _modalService: NgbModal,
    protected _profileDispatcher: ProfileDispatcherService
  ) { }

  ngOnInit() {

    const profile = this._profileDispatcher.getProfile().subscribe(res => {
      this.profile = res;
    });
    const routeParams = this.route.paramMap.subscribe(params => {
      this.orgaId = +params.get('orga');
      this._loggerService.log("ORGA ID: ", this.orgaId);
    });

    this.getOrgaDetails();

    this.subscriptions.push(
      profile,
      routeParams
    );

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getOrgaDetails() {
    this._cachedOrganisationService.selectOrganisation(this.orgaId)
      .subscribe(res => {
        this.orgaDetails = res;
        this._loggerService.log("OrgaDetails", this.orgaDetails);
        this.titleService.setTitle(this.orgaDetails.name + " - News");
      });
  }

  saveChanges() {
    this._organisationNewsService.updateOrgaNews(this.newsId, this.newsEdited).subscribe(res => {
      this._toastService.show("snackbarNewsUpdated", "show", 8000);
      // this.getOrgaNews();
    }, err => {
      this._loggerService.error("Error updating news: ", err);
    });
  }

  deleteNews() {
    this._organisationNewsService.deleteOrgaNews(this.newsId).subscribe(res => {
      this._toastService.show("snackbarNewsDeleted", "show", 8000);
      // this.getOrgaNews();
      this.newsId = null;
      this.newsInfo = null;
    });
  }

  createNews() {
    this.loading = true;
    this._organisationNewsService.createOrgaNews(this.orgaId, this.newsCreated).subscribe(res => {
      this._toastService.show("snackbarNewsCreated", "show", 8000);
      this.newsCreated = {};
      // this.getOrgaNews();
      this.loading = false;
      this.modalRef.close();
    });
  }

  currentlyAdding() {
    if (this.adding === false) {
      this.adding = true;
    } else {
      this.adding = false;
    }

  }

  activateMenu() {
    if (this.menuActive === false) {
      this.menuActive = true;
    } else {
      this.menuActive = false;
    }
  }

  navigate(ref) {
    window.location.hash = 'ancre-' + ref;
  }

  showButton(id) {
    document.getElementById(id).style.display = "inline-block";
  }

  hideButton(id) {
    document.getElementById(id).style.display = "none";
  }

  // TODO Add picture management
  changePicture() {

  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.orgaId, ORGANISATION_MANAGER_RIGHTS_LEVEL["Associate"]);
  }

  displayModal(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }
}
