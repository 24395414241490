<!--Profile-phones component-->
<div id="profile-phones-component" class="shadow">

  <!--Title-->
  <h2 class="text-center" i18n="@@phonesLabel">Phones</h2>

  <!-- List of phone numbers -->
  <div *ngIf="phoneNumbers && phoneNumbers.length > 0" class="margin-b">
    <div class="profile-phone" *ngFor='let phone of phoneNumbers'>
      <!--Phone number-->
      <h3>{{phone.number}}</h3>
      <!--To delete a phone number in the list-->
      <i class="fa fa-times custom-delete-icon" style="margin-top:-10px;" *ngIf="!phones"
        (click)="deletePhone(phone.id)"></i>
      <!--Display the type of phone number-->
      <h4>{{phone.type == 1 ? "Personal Mobile"     :
           phone.type == 2 ? "Professional Mobile" :
           phone.type == 3 ? "Landline"            :
           phone.type == 4 ? "Office"              :
           phone.type == 5 ? "Satelite Phone"      :
           phone.type == 6 ? "VOIP"                : "-"}}
      </h4>
    </div>
  </div>

  <!-- "No phone number set" -->
  <div *ngIf="!phoneNumbers || phoneNumbers.length === 0" class="no-phone-container">
    <p>No phone number set</p>
  </div>

  <!-- + button-->
  <div class="add-button" *ngIf="!phones" (click)="showAddPhone(content)">+</div>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">

    <!--Modal header-->
    <div class="modal-header">
      <!--Tilte-->
      <h4 class="modal-title" i18n="@@changePicture">Add New Phone</h4>
      <!--Close button-->
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
    </div>

    <!--Modal body-->
    <div class="modal-body">
      <form>
        <div class="form-group">
          <!--Set the type of phone-->
          <label for="typeField" i18n="@@selectTypeLabel">Select phone type</label>
          <select id="typeField" class="form-control margin-b" [(ngModel)]="type" name="type" type="select">
            <option value="1" i18n="@@personalMobileLabel"> Personal Mobile </option>
            <option value="2" i18n="@@professionalMobileLabel"> Professional Mobile</option>
            <option value="3" i18n="@@landlineLabel"> Landline </option>
            <option value="4" i18n="@@officeLabel"> Office </option>
            <option value="5" i18n="@@satelitePhoneLabel"> Satelite Phone </option>
            <option value="6" i18n="@@voipLabel"> VOIP </option>
            <!-- 0 VALUE GIVES ERROR ON THE BACKEND,
            PROBABLY FOR A BLOODY == INSTEAD OF === -->
            <!-- <option value="0" i18n="@@otherLabel"> Other </option> -->
          </select>
          <!--Note: Mettre cote à cote les champs-->
          <!--Set the country code-->
          <label for="countryField" i18n="@@selectCodeLabel"> Select phone number's country code</label>
          <select id="countryField" class="form-control margin-b" [(ngModel)]="prefixe.iso2" name="prefixe"
            type="select">
            <option *ngFor="let prefixe of prefixes" [value]=prefixe.iso2>{{ prefixe.name }} (+{{ prefixe.code }})
            </option>
          </select>
          <!--Set your phone number-->
          <input id="numberField" class="form-control margin-b" [(ngModel)]="phoneNumber" name="phoneNumber"
            placeholder="Enter your phone number..." maxlength="12" />
        </div>
      </form>
    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <!--Modal footer-->
    <div class="modal-footer">
      <!--Add phone number button-->
      <button class="btn emalsys_button green" type="button" [disabled]="loading || phoneNumber == ''"
        (click)="addPhone(type, prefixe.iso2, phoneNumber)" i18n="@@addPhoneLabel">Add phone number</button>
    </div>
  </ng-template>

  <!-- Toasts -->
  <div id="phoneDeleteToast">Phone number deleted</div>
  <div id="phoneErrorToast">Phone number is not recognized! Please try again</div>
  <div id="phoneAddToast">Phone number added</div>
</div>