import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../../../../../../../services/utils/logger.service';
import { ToastService } from "../../../../../../../services/utils/toast.service";
import { HttpService } from '../../../../../../../services/api/http.service';
import { EventListenerService } from '../../../../../../../services/utils/event-listener.service';

import { URL_API_EMALSYS } from '../../../../../../../environments/environment';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationApiService } from "../../../../../../../services/api/organisation/organisation.api";
import { AlertService } from "../../../../../../../services/api/alert/alert.api";

@Component({
  selector: 'app-add-alert-modal',
  templateUrl: './add-alert-modal.component.html',
  styleUrls: ['./add-alert-modal.component.scss']
})

export class AddAlertModalComponent implements OnInit {

  @Output() onClickAddMembers = new EventEmitter<any>();
  @Input() organisation: any;
  @Input() selectedEvent: any;
  public loading = false;
  public rosterId;
  public usersId = [];
  private ajaxRosters;
  private ajaxMembers;
  public rosterOptions;
  public membersOptions;

  constructor(
    public http: HttpClient,
    public activeModal: NgbActiveModal,
    public _httpService: HttpService,
    public _loggerService: LoggerService,
    public _toasterService: ToastService,
    public _eventListenerService: EventListenerService,
    public _organisationService: OrganisationApiService,
    public _alertService: AlertService
  ) { }

  ngOnInit() {
    // select2 options definition

    // use select2 ajax option to get matching rosters from the server
    // returns the rosters from the organisation
    this.ajaxRosters = {
      url: this._organisationService.getOrganisationRostersRoute(this.organisation.id),
      delay: 250,
      transport: (params, success, failure) => {
        this.http.get(params.url + (params.data.roster ? "?method=by_roster_name&rosterName=" + params.data.roster : ''),
          this._httpService.createRequestOptions())
          .subscribe(
            suc => {
              return success(suc);
            },
            err => {
              return failure(err);
            }
          );
      },
      dataType: 'json',
      data: params => {
        let query = {
          roster: params.term
        };
        return query;
      },
      // modify the results returned from the server so that it matches
      // what Select2 expects
      processResults: data => {
        let res = {
          results: data.map(item => {
            return {
              text: item.name,
              id: item.id
            };
          })
        };
        return res;
      }
    };
    this.rosterOptions = {
      ajax: this.ajaxRosters
    };

    // use select2 ajax option to get matching users from the server
    // returns emalsys users that are not members of the organisation
    this.ajaxMembers = null;
    this.membersOptions = {
      multiple: true,
      ajax: this.ajaxMembers,
      minimumInputLength: 1
    };
  }

  membersChanged(data) {
    this.usersId = [];
    data.value.map((val) => {
      this.usersId.push(parseInt(val, 10));
    });
  }

  rosterChanged(data) {
    this.rosterId = data.value;
    this.ajaxMembers = {
      url: URL_API_EMALSYS + "/events/" + this.selectedEvent + "/invitable",
      delay: 250,
      transport: (params, success, failure) => {
        this.http.get(params.url + "?email=" + params.data.email + "&roster=" + this.rosterId, this._httpService.createRequestOptions())
          .subscribe(
            suc => {
              return success(suc);
            },
            err => {
              return failure(err);
            }
          );
      },
      dataType: 'json',
      data: params => {
        let query = {
          email: params.term
        };
        return query;
      },
      // modify the results returned from the server so that it matches
      // what Select2 expects
      processResults: results => {
        let res = {
          results: results.map(item => {
            return {
              text: item.fullname + " (" + item.email + ")",
              id: item.id
            };
          })
        };
        return res;
      }
    };
    this.membersOptions = {
      multiple: true,
      ajax: this.ajaxMembers,
      minimumInputLength: 1
    };
  }

  addMembers() {
    this.loading = true;
    this._loggerService.log(this.selectedEvent);
    let body = new FormData();
    new Promise((resolve) => {
      let msg = {
        title: "",
        description: ""
      };
      this._alertService.getAlertDetails(this.selectedEvent)
        .subscribe((res: any) => {
          msg.title = res.name;
          msg.description = "From " + new Date(res.start_date).toLocaleDateString() + " to "
            + new Date(res.end_date).toLocaleDateString() + ": " + res.description;
          resolve(msg);
        });
    }).then((msg: any) => {
      body.append('users', JSON.stringify(this.usersId));
      body.append('event_id', this.selectedEvent);
      body.append('roster', this.rosterId);
      body.append('msg_title', msg.title);
      body.append('msg_content', msg.description);
      this.onClickAddMembers.emit(body);
      this.activeModal.close();
      this.loading = false;

      // delete content of the rosterId and the users to add
      this.rosterId = 0;
      this.usersId = [];
    })
      .catch((error) => {
        this.loading = false;
        this._toasterService.show('addMemberToAlertToast', 'show');
      });
  }

}
