// Module
import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule  } from '@angular/forms';

import { SharedModule } from '../../../shared.module';

// Components
import { RegisterComponent         } from './register.component';
import { RegisterEmailComponent    } from './register-email/register-email.component';
import { RegisterNameComponent     } from './register-name/register-name.component';
import { RegisterPasswordComponent } from './register-password/register-password.component';
import { RegisterBirthdateComponent } from './register-birthdate/register-birthdate.component';


@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    RegisterComponent,
    RegisterEmailComponent,
    RegisterNameComponent,
    RegisterPasswordComponent,
    RegisterBirthdateComponent
  ],
  providers: []
})


export class RegisterModule {}
