<div class="modal-header">
  <h4 class="modal-title">Confirm your password</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">
      <i class="fa fa-times"></i>
    </span>
  </button>
</div>
<div class="modal-body">
    <div class="text-center">
      <form id="login-forms">
        <div class="form-group">
          <input class="form-control" type="password" name="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="password=='' ? '' : validate();activeModal.close()"
          />
        </div>
      </form>
    </div>
    <div class="login margin-b text-center">
      <button [disabled]="!password" class="btn btn-red btn-block btn-lg" [disabled]="password=='' || loading" (click)="password=='' ? '' : validate()">Validate</button>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>

