/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-documentation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-documentation.component";
import * as i3 from "../../../../services/api/documentation.service";
var styles_AdministrationDocumentationComponent = [i0.styles];
var RenderType_AdministrationDocumentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationDocumentationComponent, data: {} });
export { RenderType_AdministrationDocumentationComponent as RenderType_AdministrationDocumentationComponent };
export function View_AdministrationDocumentationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the documentation management pagein progress...\n"]))], null, null); }
export function View_AdministrationDocumentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-documentation", [], null, null, null, View_AdministrationDocumentationComponent_0, RenderType_AdministrationDocumentationComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationDocumentationComponent, [i3.DocumentationService], null, null)], null, null); }
var AdministrationDocumentationComponentNgFactory = i1.ɵccf("administration-documentation", i2.AdministrationDocumentationComponent, View_AdministrationDocumentationComponent_Host_0, {}, {}, []);
export { AdministrationDocumentationComponentNgFactory as AdministrationDocumentationComponentNgFactory };
