import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Select2OptionData } from 'ng2-select2';

import { Select2Module } from 'ng2-select2';

import { LoggerService } from '../../../../../../services/utils/logger.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RosterMembershipService } from '../../../../../../services/api/roster/dependencies/membership/roster-membership.api';


@Component({
  selector: 'app-roster-modal',
  templateUrl: './add-roster-modal.component.html',
  styleUrls: ['./add-roster-modal.component.scss']
})
export class AddRosterModalComponent {

  @Input() toInvite;
  @Input() rosters: Array<Select2OptionData>;
  @Output() onAddMember = new EventEmitter<any>();
  @Output() onAddManager = new EventEmitter<any>();
  public rights = 1;
  public selectedRoster;

  constructor(
    public activeModal: NgbActiveModal,
    private _rosterMembershipService: RosterMembershipService,
    private _loggerService: LoggerService
  ) { }

  inviteMembers(isChecked) {
    this._rosterMembershipService.inviteMembers(this.selectedRoster.id, this.toInvite).subscribe(res => {
      this.onAddMember.emit("Members ADDED");
      if (isChecked) {
        this._rosterMembershipService.inviteManagers(this.selectedRoster.id, this.toInvite).subscribe(invitationResult => {
          this.onAddManager.emit("Managers ADDED");
        });
      }
    });
  }

  changed(data) {
    this.selectedRoster = {
      id: data.value,
      text: data.data[0].text
    };
  }
}
