import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng2-select2';

import { LATLONG } from '../../../../../../data/countries';
import { AlertService } from '../../../../../../services/api/alert/alert.api';
import { DeploymentOrderService } from '../../../../../../services/api/deployment-order.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { ToastService } from '../../../../../../services/utils/toast.service';
import { Alert } from '../../../../../model/alert.interface';
import { DeploymentOffer } from "../../../../../model/deployment-offer.interface";
import { AlertDispatcherService } from '../../../../../../services/api/alert/alert.dispatcher';
@Component({
  selector: 'app-send-offer-modal',
  templateUrl: './send-offer-modal.component.html',
  styleUrls: ['./send-offer-modal.component.scss']
})

export class SendOfferModalComponent implements OnInit {
  @Output() onClickSend = new EventEmitter<any>();
  @Input() alertInfo;
  public events: any;
  public step: number;
  public countries: any[];
  public option: boolean;
  public loading = false;
  public copy: boolean;

  public createdEvent = {
    name: null,
    country: null,
    startDate: null,
    endDate: null
  };
  public minDateValue: Date;
  public maxDateValue: Date;
  public start_deployment: Date;
  public end_deployment: Date;
  public position: any;
  public message = "";
  public emails: any[];
  @Input() profile;
  @Input() selectedEvent: any;
  @Input() organisation;
  @Input() usersId;
  @Input() rosterId;
  @Input() page;

  public formValidationText = '';

  constructor(
    public activeModal: NgbActiveModal,
    public _rightService: RightService,
    public _alertService: AlertService,
    public _deploymentOrderService: DeploymentOrderService,
    public _loggerService: LoggerService,
    public _toasterService: ToastService,
    public _alertDispatcher: AlertDispatcherService
  ) { }

  ngOnInit() {
    if (this.page === "members") {
      this.step = 1;
      this.getCountries();
      this.getEvents();
    } else {
      this.selectedEvent = this._alertDispatcher.getSelectedAlertAsScalar();
      this.step = 2;
    }
    this.option = false;
    this.copy = false;
    this.emails = [""];
    if (this.alertInfo) {
      this.maxDateValue = new Date(this.alertInfo.end_date);
      this.minDateValue = new Date(this.alertInfo.start_date);
    }
  }

  getEvents() {
    this._alertService.getOrgaAlerts(this.organisation.id).subscribe(res => {
      this.events = res;
      if (this.events && this.events.length > 0) {
        this.selectedEvent = this.events[0];
      }
    });
  }

  getCountries() {
    this.countries = [];
    for (let i = 0; i < LATLONG.length; i++) {
      this.countries.push(
        {
          id: LATLONG[i]['country_code'],
          text: LATLONG[i]['name']
        }
      );
    }
  }

  onClickOption() {
    this.option = !this.option;
    this.selectedEvent = null;
    for (let key in this.createdEvent) {
      if (key) {
        this.createdEvent[key] = null;
      }
    }
  }

  missingEvent() {
    let missingParam = false;
    for (let key in this.createdEvent) {
      if (!this.createdEvent[key]) {
        missingParam = true;
      }
    }
    return !this.selectedEvent && missingParam;
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  setDefaultEndDateValue() {
    this.end_deployment = this.start_deployment;
  }

  nextStep() {
    this.step = this.step + 1;
    if (this.selectedEvent) {
      this._loggerService.log(this.selectedEvent);
      this.maxDateValue = new Date(this.selectedEvent.end_date);
      this.minDateValue = new Date(this.selectedEvent.start_date);
    }
  }

  previousStep() {
    this.step = 1;
  }

  createEvents() {

    if (this.createdEvent.startDate == null || this.createdEvent.endDate == null) {
      this.formValidationText = 'The start date and the end date cannot be empty';
      this._toasterService.show('createDeploymentOfferValidationToast', 'show');
      return;
    }

    let alert = Alert.from({
      'organisation': this.organisation.id,
      'name': this.createdEvent.name,
      'country': this.createdEvent.country,
      'startDate': this.createdEvent.startDate,
      'endDate': this.createdEvent.endDate,
    });

    let body = alert.toFormData(['organisation', 'name', 'country', 'startDate', 'endDate']);

    this.loading = true;
    this._alertService.createEvent(body).subscribe(res => {
      this.selectedEvent = res;
      this.createOffers();
    }, error => {
      this.loading = false;
      this._toasterService.show('createDeploymentOrderToast', 'show');
    });
  }

  createOffers() {
    if (this.start_deployment == null || this.end_deployment == null) {
      this.formValidationText = 'The start date and the end date cannot be empty';
      this._toasterService.show('createDeploymentOfferValidationToast', 'show');
      return;
    }

    let deploymentOffer = DeploymentOffer.from({
      'startDate': this.start_deployment,
      'endDate': this.end_deployment,
      'position': this.position,
      'message': this.message,
    });

    let body = deploymentOffer.toFormData(['startDate', 'endDate', 'position', 'message']);

    if (this.selectedEvent.id) {
      body.append('id', this.selectedEvent.id);
    } else {
      body.append('id', this.selectedEvent);
    }
    body.append('responders', JSON.stringify(this.usersId));
    body.append('roster', this.rosterId);
    if (this.emails.length > 0) {
      let filteredEmails = this.emails.filter((value) => {
        return value !== "";
      });
      body.append('emails', JSON.stringify(filteredEmails));
    }

    this.loading = true;
    this._deploymentOrderService.createOffer(body).subscribe(res => {
      this.onClickSend.emit(res);
      this.loading = false;
      this.activeModal.close();
    }, error => {
      this.loading = false;
      this._toasterService.show('createDeploymentOfferToast', 'show');
    });
  }

  sendOffer() {
    if (!this.selectedEvent && this.page !== 'alerts') {
      this.createEvents();
    } else {
      this.createOffers();
    }
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["Associate"]);
  }

  checkRosterManager(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['NotManager']);
  }
}
