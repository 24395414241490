<div id="deployment-offer-what-is" class="shadow" *ngIf="!this.nbrOffers">
  <h5 class="deployment-offer-description-header">You don't have a deployment offer yet</h5>
  <h5 class="deployment-offer-what-is-title">What is a deployment offer ?</h5>
  <p class="deployment-offer-what-is-description">Roster Manager will get your availability and will establish his teams, he will then send to you a Deployment Offer.
      It will contain: your position in the team, proposed date of your deployment and a short message from your Roster Manager.
      You can access the documents related to a mission (ex. Mission description, templates…).
  </p>
</div>
<div class="shadow">
  <div *ngIf="this.nbrOffers">
    <div class="deployment-offer-description-header">
      <i title="You have now to accept or refuse this Deployment Offer.
Your decision is about this Deployment Offer, the Roster Manager will see it on his follow up board."
      class="fa fa-question-circle-o help-icon" aria-hidden="true">
      </i>
      <h2>Deployment offer</h2>
    </div>
    <div id="deployment-offer-description-body" *ngFor="let offer of deploymentOffers">
      <div class="deployment-offer-description-container">
        <div class="deployment-offer-description-leftpart">
          <div class="deployment-offer-description-subheader">
            <p class="deployment-offer-description-position">Position: {{offer.position}}</p>
            <div class="deployment-offer-description-date-container">
              <p class="deployment-offer-description-date">{{offer.start_date | niceDateDetailed}}</p>
              <i class="fa fa-arrow-right fa-1x deployment-offer-description-arrow" aria-hidden="true"></i>
              <p class="deployment-offer-description-date">{{offer.end_date | niceDateDetailed}}</p>
            </div>
            <p class="deployment-offer-description-sender">Deploying manager: {{offer.sender.firstname}} {{offer.sender.lastname}} - {{offer.sender.profile.contact_email}}</p>
          </div>

          <p class="deployment-offer-description-description">{{offer.message}}</p>
        </div>
        <div class="deployment-offer-description-rightpart">
          <p class="deployment-offer-description-buttons-label" *ngIf="offer.valid==0">Do you accept the deployment offer ?</p>
          <div class="deployment-offer-description-buttons" *ngIf="offer.valid==0">
            <button class="btn emalsys_button green accept margin-b margin-r" (click)='validateDeploymentOffer(offer.id)'>Accept</button>
            <button class="btn emalsys_button red decline margin-b margin-r" (click)='refuseDeploymentOffer(offer.id)'>Decline</button>
          </div>
          <div class="deployment-offer-description-status" *ngIf="offer.valid!==0">
            <p>Status:</p>
            <i *ngIf="offer.valid==1" class="fa fa-check-circle validated" aria-hidden="true" title="Deployment offer accepted"></i>
            <i *ngIf="offer.valid==2" class="fa fa-times refused" aria-hidden="true" title="Deployment offer refused"></i>
            <i *ngIf="offer.valid==3" class="fa fa-ban canceled" aria-hidden="true" title="Deployment offer canceled"></i>
          </div>
          <div class="deployment-offer-description-documents" *ngIf="offer.deployment&&offer.deployment.deployment_document.length">
            <p class="deployment-offer-description-documents-label">Deployment documents</p>

            <div *ngFor="let doc of offer.deployment.deployment_document">
              <button class="deployment-offer-description-doc-name" (click)="downloadDoc(doc.document)">
              <img class="doc-icon" src="assets/images/Docs_Icon.png" />
              <p>{{doc.document.title}}</p>
            </button>
            </div>
          </div>
        </div>
      </div>
      <div class="separator"></div>
    </div>
  </div>
</div>
