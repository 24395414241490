<div class="modal-header">
  <h4 class="modal-title">Cookies & GDPR acceptation</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">
      <i class="fa fa-times"></i>
    </span>
  </button>
</div>
<div class="modal-body">
    <p> The European Union put in place a new set of rules and regulations (GDPR) to better protect internet users. To fully
      comply with the GDPR we made few changes to the platform allowing an easier access and management (including deletion)
      of user personal information and updated the terms of our service You can view them
      <a href="/public/terms" target="_blank">here</a> . By ticking this box, you certify you read and agree with the Terms of Service and the storage of your data
      by Relief Applications. We will never sell your data and use the highest standards to keep your personal details safe
      and secure.
    </p>
    <div class="certify-checkbox">
      <p>I certify I read and agree with the Terms of Service and the storage of my data by Relief Applications.</p>
      <input #gdprCheck
        type="checkbox">
    </div>
 
  <div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
</div>
  <div class="modal-footer">
    <button [disabled]="!gdprCheck.checked || loading" type="submit" class="btn btn-success" (click)="acceptGDPR()">Next</button>
  </div>