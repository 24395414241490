import { Component, OnInit, Input } from '@angular/core';
import { UploadItem } from 'angular2-http-file-upload';
import { Uploader   } from 'angular2-http-file-upload';

import { URL_API_EMALSYS } from '../../../../../environments/environment';

// Services
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { WSSEService } from '../../../../../services/security/wsse.service';
import { ToastService } from '../../../../../services/utils/toast.service';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { EventListenerService } from "../../../../../services/utils/event-listener.service";

// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector   : 'profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls  : ['./profile-picture.component.scss']
})


export class ProfilePictureComponent implements OnInit {

  // @Input() profile: any;
  @Input() picture: any;
  @Input() isItMe: boolean;

  public selectedFile: File;    // uploaded picture file object
  public showOptions: boolean;  // flag to show 'Change Picture' btn on hover
  public thumbnailUrl: string;  // url of temporary picture loaded in modal to show a thumbnail
  public loading = false;
  public modalRef;
  public isSomeoneElse;
  constructor(
    private _profileService: ProfileService,
    private _modalService: NgbModal,
    public _uploaderService: Uploader,
    private _wsseService: WSSEService,
    private _toastService: ToastService,
    private _loggerService: LoggerService,
    private _eventListenerService: EventListenerService
  ) {}

  ngOnInit() {
    this.isSomeoneElse = !!this.picture;
    this._profileService.currentPicture.subscribe((pic) => {
      this.picture = pic;
      this.thumbnailUrl = pic;
    });
    // this.thumbnailUrl = this.picture;
  }

  /////////////////////////////////////////////////////////////
  /////////////////////// FILE UPLOAD /////////////////////////
  /////////////////////////////////////////////////////////////

  changePicture(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
      this.readPictureUrl();
    }
  }

  // Get picture thumbnail url
  readPictureUrl() {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.thumbnailUrl = e.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
  }

  uploadDoc() {
    this.loading = true;
    let uploadItem = new UploadItem();
    uploadItem.url = URL_API_EMALSYS + "/me/picture";
    uploadItem.headers = {'x-wsse' : this._wsseService.getHeaderValue()};
    // uploadItem.formData = {title: 'ABC'};

    uploadItem.file = this.selectedFile;
    // uploadItem.formData['prevention_id'] = this.id;
    // uploadItem.formData['private'] = this.isNewFilePrivate;

    this.submitDoc(uploadItem);
  }

  submitDoc(item: UploadItem) {
    this._uploaderService.onSuccessUpload = (uploadedItem, response, status, headers) => {
         // success callback
         this.showToastUploaded();
         this._profileService.changePicture();
         this._eventListenerService.publish("user", true);
        //  this.inputFile.nativeElement.value=""; // reset the input element
    };
    this._uploaderService.onErrorUpload = (uploadedItem, response, status, headers) => {
         // error callback
         this.showToastError();
    };
    this._uploaderService.onCompleteUpload = (uploadedItem, response, status, headers) => {
         // complete callback, called regardless of success or failure
         this.loading = false;
         this.thumbnailUrl = null;
         this.modalRef.close();
        //  this.getMyDocs();
    };
    this._uploaderService.upload(item);

  }

  /// Toasts
  showToastUploaded() {
    this._toastService.show("pictureUploadToast", "show", 3000);
  }

  // showToastAdd() {
  //   this._toastService.show("docAddToast", "show", 3000);
  // }

    showToastError() {
    this._toastService.show("pictureUploadErrorToast", "show", 3000);
  }

}
