/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-all-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-all-data.component";
import * as i4 from "../../../../../services/api/profile/profile.api";
import * as i5 from "../../../../../services/api/profile/profile.dispatcher";
var styles_ProfileAllDataComponent = [i0.styles];
var RenderType_ProfileAllDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileAllDataComponent, data: {} });
export { RenderType_ProfileAllDataComponent as RenderType_ProfileAllDataComponent };
function View_ProfileAllDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_ProfileAllDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["As per the EU GDRP Directive, here is the personal information we store on your behalf:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAllDataComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataString; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProfileAllDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-all-data", [], null, null, null, View_ProfileAllDataComponent_0, RenderType_ProfileAllDataComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileAllDataComponent, [i4.ProfileService, i5.ProfileDispatcherService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileAllDataComponentNgFactory = i1.ɵccf("app-profile-all-data", i3.ProfileAllDataComponent, View_ProfileAllDataComponent_Host_0, {}, {}, []);
export { ProfileAllDataComponentNgFactory as ProfileAllDataComponentNgFactory };
