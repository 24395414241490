import { AbstractModel } from './abstract-model';

export class AlertInterface {

    public static readonly CACHE = {
        ORGANISATION_ALERTS: 'organisation_alerts_',
        OLD_ORGANISATION_ALERTS: 'old_organisation_alerts_',
        MY_ALERTS: 'my_alerts',
        ALERT_DETAILS: 'alert_details_',
    };


    /**
    * Alert id
    * @type {number}
    **/
    id?: number;
    /**
    * Alert organisation
    * @type {number}
    **/
    organisation: number;
    /**
    * Alert name
    * @type {string}
    **/
    name: string;
    /**
    * Alert country
    * @type {string}
    **/
    country: string;
    /**
    * Alert start date
    * @type {Date}
    **/
    startDate: Date;
    /**
    * Alert end date
    * @type {Date}
    **/
    endDate: Date;
    /**
    * Alert secondary countries
    * @type {any[]}
    **/
    secondaryCountries?: any[];
    /**
    * Alert description
    * @type {string}
    **/
    description?: string;
    /**
    * Alert description
    * @type {string}
    **/
    infoLink?: string;

}

export class Alert extends AbstractModel implements AlertInterface {
    id?: number = null;
    organisation: number = null;
    name = '';
    country: string = null;
    startDate: Date = null;
    endDate: Date = null;
    secondaryCountries?: any[] = [];
    description?: string = null;
    infoLink?: string = null;

    static getInstance(): Alert {
        return new Alert();
    }

    static from(map: any): Alert {
        return AbstractModel.from(Alert, map);
    }

    /**
     * Converts current object to an Alert
     *
     * @param fieldsToAdd List of field to keep in formdata. If you dont know, let it empty and all
     * all fields will be returned
     */
    toFormData(fieldsToAdd: string[] = []): FormData {
        return super.toFormData(fieldsToAdd, (body, item): boolean => {
            if (item === 'startDate' || item === 'endDate') {
                body.append(item, this[item].toLocaleString('en-EN'));
                return false;
            }
            return true;
        });
    }
}
