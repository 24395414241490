/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-news-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-news-map.component";
import * as i3 from "../../../../../../services/external/leaflet.service";
var styles_DashboardNewsMapComponent = [i0.styles];
var RenderType_DashboardNewsMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardNewsMapComponent, data: {} });
export { RenderType_DashboardNewsMapComponent as RenderType_DashboardNewsMapComponent };
export function View_DashboardNewsMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "shadow"], ["id", "dashboard-news-map-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organisation Alerts"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "dashboard-news-map-component-map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableZoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_DashboardNewsMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-news-map", [], null, null, null, View_DashboardNewsMapComponent_0, RenderType_DashboardNewsMapComponent)), i1.ɵdid(1, 638976, null, 0, i2.DashboardNewsMapComponent, [i3.LeafletService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardNewsMapComponentNgFactory = i1.ɵccf("dashboard-news-map", i2.DashboardNewsMapComponent, View_DashboardNewsMapComponent_Host_0, { alerts: "alerts", alertsUser: "alertsUser" }, {}, []);
export { DashboardNewsMapComponentNgFactory as DashboardNewsMapComponentNgFactory };
