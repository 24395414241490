import { AbstractModel } from './abstract-model';
export class AlertInterface {
}
AlertInterface.CACHE = {
    ORGANISATION_ALERTS: 'organisation_alerts_',
    OLD_ORGANISATION_ALERTS: 'old_organisation_alerts_',
    MY_ALERTS: 'my_alerts',
    ALERT_DETAILS: 'alert_details_',
};
export class Alert extends AbstractModel {
    constructor() {
        super(...arguments);
        this.id = null;
        this.organisation = null;
        this.name = '';
        this.country = null;
        this.startDate = null;
        this.endDate = null;
        this.secondaryCountries = [];
        this.description = null;
        this.infoLink = null;
    }
    static getInstance() {
        return new Alert();
    }
    static from(map) {
        return AbstractModel.from(Alert, map);
    }
    /**
     * Converts current object to an Alert
     *
     * @param fieldsToAdd List of field to keep in formdata. If you dont know, let it empty and all
     * all fields will be returned
     */
    toFormData(fieldsToAdd = []) {
        return super.toFormData(fieldsToAdd, (body, item) => {
            if (item === 'startDate' || item === 'endDate') {
                body.append(item, this[item].toLocaleString('en-EN'));
                return false;
            }
            return true;
        });
    }
}
