import { Component } from '@angular/core';

@Component({
  selector   : 'user-agreement-page',
  templateUrl: 'user-agreement.component.html',
  styleUrls  : ['user-agreement.component.scss']
})

export class UserAgreementComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
