/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-modal-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert-modal-confirmation.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_AlertModalConfirmationComponent = [i0.styles];
var RenderType_AlertModalConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertModalConfirmationComponent, data: {} });
export { RenderType_AlertModalConfirmationComponent as RenderType_AlertModalConfirmationComponent };
function View_AlertModalConfirmationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "emalsys_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "green": 0, "red": 1 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "emalsys_button"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.color === _co.GREEN), (_v.context.$implicit.color === "red")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_2); }); }
export function View_AlertModalConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertModalConfirmationComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttons; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.body; _ck(_v, 8, 0, currVal_1); }); }
export function View_AlertModalConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-modal-confirmation", [], null, null, null, View_AlertModalConfirmationComponent_0, RenderType_AlertModalConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertModalConfirmationComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertModalConfirmationComponentNgFactory = i1.ɵccf("app-alert-modal-confirmation", i3.AlertModalConfirmationComponent, View_AlertModalConfirmationComponent_Host_0, {}, {}, []);
export { AlertModalConfirmationComponentNgFactory as AlertModalConfirmationComponentNgFactory };
