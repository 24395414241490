import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserInterface } from '../../../app/model/user.interface';
import { filter } from 'rxjs/operators';
import { Dispatcher } from '../../dispatcher.service';
import { ProfilePhoneInterface } from '../../../app/model/profile-phone.interface';
import { UserTagInterface } from '../../../app/model/user-tag.interface';
import { UserLanguageInterface } from '../../../app/model/user-language.interface';
import { EmergencyContactInterface } from '../../../app/model/emergency-contact.interface';

@Injectable()
export class ProfileDispatcherService extends Dispatcher {

  private profile$ = new BehaviorSubject<UserInterface>(null);
  private userData$ = new BehaviorSubject<any>(null);
  private userPhones$ = new BehaviorSubject<ProfilePhoneInterface[]>([]);
  private userSkills$ = new BehaviorSubject<UserTagInterface[]>([]);
  private allSkills$ = new BehaviorSubject<UserTagInterface[]>([]);
  private userLanguages$ = new BehaviorSubject<UserLanguageInterface[]>([]);
  private userEmergencyContacts$ = new BehaviorSubject<EmergencyContactInterface[]>([]);

  constructor() {
    super();
  }

  public getProfile(): Observable<UserInterface> {
    return this.profile$.asObservable().pipe(
      filter(x => x !== null)
    );
  }

  public nextProfile(profile: UserInterface) {
    this.profile$.next(profile);
  }

  public getUserData(): Observable<any> {
    return this.pipeNotNull(this.userData$);
  }

  public nextUserData(userData: any) {
    this.userData$.next(userData);
  }

  public getUserPhones(): Observable<ProfilePhoneInterface[]> {
    return this.pipeNotNull(this.userPhones$);
  }

  public nextUserPhones(userPhones: ProfilePhoneInterface[]) {
    this.userPhones$.next(userPhones);
  }

  public getSkills(): Observable<UserTagInterface[]> {
    return this.pipeNotNull(this.allSkills$);
  }

  public nextSkills(allSkills: UserTagInterface[]) {
    this.allSkills$.next(allSkills);
  }

  public getUserSkills(): Observable<UserTagInterface[]> {
    return this.pipeNotNull(this.userSkills$);
  }

  public nextUserSkills(userSkills: UserTagInterface[]) {
    this.userSkills$.next(userSkills);
  }

  public getLanguages(): Observable<any[]> {
    return this.pipeNotNull(this.userLanguages$);
  }

  public nextLanguages(languages: UserLanguageInterface[]) {
    this.userLanguages$.next(languages);
  }

  public getEmergencyContacts(): Observable<EmergencyContactInterface[]> {
    return this.pipeNotNull(this.userEmergencyContacts$);
  }

  public nextEmergencyContacts(emergencyContacts: EmergencyContactInterface[]) {
    this.userEmergencyContacts$.next(emergencyContacts);
  }

}
