import { Injectable } from '@angular/core';
import {AlertModalConfirmationComponent, AlertButton} from '../../app/modules/shared/modals/alert-modal-confirmation/alert-modal-confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ConfirmationAlertModalService {

    constructor(
        private _modalService: NgbModal
    ) {}


    displayModal(title: string, body: string, buttons: AlertButton[]) {
        let modal = this._modalService.open(AlertModalConfirmationComponent, { backdrop: 'static' });
        modal.componentInstance.buttons = buttons;
        modal.componentInstance.title = title;
        modal.componentInstance.body = body;
    }

}
