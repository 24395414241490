/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../spinner/spinner.component.ngfactory";
import * as i3 from "../../spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./profile-tags.component";
import * as i6 from "../../../../../services/api/tag/tag.api";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../services/utils/logger.service";
import * as i9 from "../../../../../services/utils/toast.service";
var styles_ProfileTagsComponent = [i0.styles];
var RenderType_ProfileTagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileTagsComponent, data: {} });
export { RenderType_ProfileTagsComponent as RenderType_ProfileTagsComponent };
function View_ProfileTagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "add-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTagModal(i1.ɵnov(_v.parent, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show tags"]))], null, null); }
function View_ProfileTagsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-tags-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no tags to display"]))], null, null); }
function View_ProfileTagsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "tag-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileTagsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
function View_ProfileTagsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "modal-general"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags can only be assigned by managers"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "tag-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileTagsComponent_4)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileTagsComponent_5)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tagsList; _ck(_v, 12, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_ProfileTagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "shadow"], ["id", "profile-tags-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "margin-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileTagsComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noTags", 2]], null, 0, null, View_ProfileTagsComponent_2)), (_l()(), i1.ɵand(0, [["profileTagsModal", 2]], null, 0, null, View_ProfileTagsComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tagsList && (_co.tagsList.length > 0)); var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_ProfileTagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-tags", [], null, null, null, View_ProfileTagsComponent_0, RenderType_ProfileTagsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProfileTagsComponent, [i6.TagsService, i7.NgbModal, i8.LoggerService, i9.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileTagsComponentNgFactory = i1.ɵccf("profile-tags", i5.ProfileTagsComponent, View_ProfileTagsComponent_Host_0, { profile: "profile" }, {}, []);
export { ProfileTagsComponentNgFactory as ProfileTagsComponentNgFactory };
