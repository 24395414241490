<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title" *ngIf="step == 1">Deployment offer status</h4>
  <h4 class="modal-title" *ngIf="step == 2">Send deployment order</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<!--Modal body-->
<div class="modal-body" *ngIf="step == 1">
  <div *ngIf="deploymentOffers && deploymentOffers.length > 0">
    <label for="DeploymentOffers">Select the deployment offer:</label>
    <select2 id="DeploymentOffers" class="select" cssImport="true" [data]="deploymentOffersData" [options]="{multiple: false}" [value]="selectedOffer?.id" (valueChanged)="changedSelectOffer($event)">
    </select2>
  </div>
  <h2 class="no-orders" *ngIf="!deploymentOffers || deploymentOffers.length == 0">There are no deployment offers</h2>
  <p *ngIf="selectedOffer && !selectedOffer.offer.deployment && selectedOffer.offer.valid==1">The member is ready. <span *ngIf="checkOrganisationAssociate()">Deploy her/him now !</span> </p>
  <p *ngIf="selectedOffer && !selectedOffer.offer.deployment && selectedOffer.offer.valid==0">The member has not yet responded to the Deployment Offer. <span *ngIf="checkOrganisationAssociate()">You could force the deployment.</span></p>
</div>

<div class="modal-body" *ngIf="step == 2">
  <div class="date-group">
    <div class="date-input">
      <label for="StartOffer">Deploy From</label>
      <p-calendar id="StartOffer" [selectOtherMonths]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="start_deployment"></p-calendar>
    </div>

    <div class="date-input">
      <label for="EndOffer">To</label>
      <p-calendar id="EndOffer" [selectOtherMonths]="true" [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2030" [showIcon]="true" [(ngModel)]="end_deployment"></p-calendar>
    </div>
  </div>
  <div class="form-group">
    <label for="position">Position</label>
    <textarea id="position" class="form-control" [rows]="2" pInputTextarea autoResize="true" [(ngModel)]="position_deployment">
  </textarea >
  </div>
  <div class="form-group">
  <label for="message">Message</label>
  <textarea id="message" class="form-control" [rows]="4" pInputTextarea autoResize="true" [(ngModel)]="message_deployment">
  </textarea >
  </div>
  <div class="file-component">
  <input id="roster-logo" type="file" (change)="fileChange($event)"/>
    <h6>Maximum size : 2 Mb</h6>
  </div>
  <button class="copy-button" (click)="copy=!copy;">
      <p>Send a copy of this deployment offer</p>
      <i *ngIf="!copy" class="fa fa-chevron-down custom-right-icon"></i>
      <i *ngIf="copy" class="fa fa-chevron-up custom-right-icon"></i>
    </button>
   <div class="form-group" *ngIf="copy">
     <div *ngFor="let email of emails; index as i; trackBy:customTrackBy">
       <label for="{{email}}">Email</label>
       <input id="{{email}}" type="email" name="{{email}}" placeholder="Email" [(ngModel)]="emails[i]">
       <button id="add-skill-btn" class="btn emalsys_button green" *ngIf="i===emails.length-1" [disabled]="!emails[i]" (click)="emails.push('')">Add</button>
     </div>
    </div>
    <button class="more-button" (click)="moreOption=!moreOption;">
          <p *ngIf="!moreOption"><i class="fa fa-plus custom-add-icon"></i>More options</p>
          <p *ngIf="moreOption"><i class="fa fa-minus custom-add-icon"></i>Less options</p>
      </button>

    <div *ngIf="moreOption">
      <div class="form-group">
        <label for="InsuranceName">Insurance name</label>
        <input id="InsuranceName" class="form-control" [(ngModel)]="insurance_name"/>
      </div>
      <div class="form-group">
        <label for="InsuranceNumber">Insurance number</label>
        <input id="InsuranceNumber" class="form-control" [(ngModel)]="insurance_number"/>
      </div>
      <div class="form-group">
        <label for="Airport">Airport</label>
        <input id="Airport" class="form-control" [(ngModel)]="airport"/>
      </div>
      <div class="form-group">
        <label for="FlightNumber">Flight number</label>
        <input id="FlightNumber" class="form-control" [(ngModel)]="flight_number"/>
      </div>
    </div>
</div>
<div class="loading-custom" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
<!--Modal footer-->
<div class="modal-footer" *ngIf="step == 1">
  <button *ngIf="checkOrganisationAssociate() && selectedOffer && !selectedOffer.offer.deployment && selectedOffer.offer.valid==1" class="btn emalsys_button green margin-b margin-r" (click)="nextStep()"><i class="fa fa-plane custom-icon" aria-hidden="true"></i>Deploy !</button>
  <button *ngIf="checkOrganisationAssociate() && selectedOffer && !selectedOffer.offer.deployment && selectedOffer.offer.valid==0" class="btn emalsys_button red margin-b margin-r" (click)="nextStep()"><i class="fa fa-plane custom-icon" aria-hidden="true"></i>Deploy anyway</button>
</div>

<div class="modal-footer" *ngIf="step == 2">
<button [disabled]="loading" class="btn emalsys_button margin-b margin-r" (click)="previousStep()"><i class="fa fa-arrow-left custom-left-icon" aria-hidden="true"></i>Back</button>
  <button [disabled]="loading" class="btn emalsys_button green margin-b margin-r" (click)="deploy()"><i class="fa fa-plane custom-icon" aria-hidden="true"></i>Deploy !</button>
</div>

<div id="responderDeployedToast" class="createDeploymentOfferToast">Responder successfully deployed</div>
<div id="errorDeployToast" class="createDeploymentOfferToast">Failed to send deployment order,<br> please retry later</div>
