/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notFound.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./notFound.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["id", "not-found-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "logo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Emalsys"], ["class", "emalsys-logo"], ["src", "../assets/images/emalsys_hand_background_red.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "window-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-frown-o custom-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "infos-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "title-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Page not found"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "p", [["class", "text-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We're sorry, you don't have access to the page you requested."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We can't seem to find the page you're looking for. "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Please go back to the previous page."]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i2.NotFoundComponent, [i3.Title, i4.Location], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("not-found", i2.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
