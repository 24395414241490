import { Component } from '@angular/core';

@Component({
  selector   : 'administration-organisations',
  templateUrl: 'administration-organisations.component.html',
  styleUrls  : ['administration-organisations.component.scss']
})

export class AdministrationOrganisationsComponent {

}
