import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
@Component({
  selector: 'app-profile-all-data',
  templateUrl: './profile-all-data.component.html',
  styleUrls: ['./profile-all-data.component.scss']
})
export class ProfileAllDataComponent implements OnInit {
  public userData;
  public dataString;
  constructor(
    public _profileService: ProfileService,
    public _profileDispatcher: ProfileDispatcherService
  ) { }

  ngOnInit() {
    this._profileDispatcher.getUserData().subscribe(res => {
      this.userData = res;
      this.dataString = JSON.stringify(this.userData);

      this.dataString = this.dataString.replace(/{/g, '');
      this.dataString = this.dataString.replace(/}/g, '');
      this.dataString = this.dataString.replace(/"/g, '');

      this.dataString = this.dataString.replace(/gender:0/g, 'gender:male');
      this.dataString = this.dataString.replace(/gender:1/g, 'gender:female');

      this.dataString = this.dataString.replace(/level:0/g, 'level:Other');
      this.dataString = this.dataString.replace(/level:1/g, 'level:Elementary Proficiency');
      this.dataString = this.dataString.replace(/level:2/g, 'level:Limited Working Proficency');
      this.dataString = this.dataString.replace(/level:3/g, 'level:Professional Working Proficency');
      this.dataString = this.dataString.replace(/level:4/g, 'level:Full Professional Proficency');
      this.dataString = this.dataString.replace(/level:5/g, 'level:Native or Bilingual Proficency');

      this.dataString = this.dataString.replace(/rights:1/g, 'rights: Guest');
      this.dataString = this.dataString.replace(/rights:2/g, 'rights: Manager');
      this.dataString = this.dataString.replace(/rights:3/g, 'rights: Admin');

      this.dataString = this.dataString.split(",");
    });
    this.getUser();
  }
  getUser() {
    this._profileService.getUserData();
  }

}
