/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-deployment-calendar/alerts-deployment-calendar.component.ngfactory";
import * as i3 from "./alerts-deployment-calendar/alerts-deployment-calendar.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../../../services/api/alert/alert.api";
import * as i6 from "../../../../../services/api/usereventanswer.service";
import * as i7 from "../../../../../services/api/profile/profile.api";
import * as i8 from "../../../../../services/utils/toast.service";
import * as i9 from "../../../../../services/utils/logger.service";
import * as i10 from "../../../../../services/api/profile/profile.dispatcher";
import * as i11 from "./alerts-deployment-offer/alerts-deployment-offer.component.ngfactory";
import * as i12 from "./alerts-deployment-offer/alerts-deployment-offer.component";
import * as i13 from "../../../../../services/api/document.service";
import * as i14 from "../../../../../services/api/deployment-order.service";
import * as i15 from "@angular/router";
import * as i16 from "./alerts-deployment-order/alerts-deployment-order.component.ngfactory";
import * as i17 from "./alerts-deployment-order/alerts-deployment-order.component";
import * as i18 from "@angular/common";
import * as i19 from "./alerts-map/alerts-map.component.ngfactory";
import * as i20 from "./alerts-map/alerts-map.component";
import * as i21 from "./alerts-process/alerts-process.component.ngfactory";
import * as i22 from "./alerts-process/alerts-process.component";
import * as i23 from "./alerts-information/alerts-information.component.ngfactory";
import * as i24 from "./alerts-information/alerts-information.component";
import * as i25 from "./alerts-preloader/alerts-preloader-map/alerts-preloader-map.component.ngfactory";
import * as i26 from "./alerts-preloader/alerts-preloader-map/alerts-preloader-map.component";
import * as i27 from "./alerts-preloader/alerts-preloader-process/alerts-preloader-process.component.ngfactory";
import * as i28 from "./alerts-preloader/alerts-preloader-process/alerts-preloader-process.component";
import * as i29 from "./alerts-preloader/alerts-preloader-information/alerts-preloader-information.component.ngfactory";
import * as i30 from "./alerts-preloader/alerts-preloader-information/alerts-preloader-information.component";
import * as i31 from "./alerts-preloader/alerts-preloader-content/alerts-preloader-content.component.ngfactory";
import * as i32 from "./alerts-preloader/alerts-preloader-content/alerts-preloader-content.component";
import * as i33 from "./alerts.component";
import * as i34 from "@angular/platform-browser";
import * as i35 from "../../../../../services/storage/cache.service";
import * as i36 from "../../../../../services/api/deployment.service";
var styles_AlertsComponent = [i0.styles];
var RenderType_AlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsComponent, data: {} });
export { RenderType_AlertsComponent as RenderType_AlertsComponent };
function View_AlertsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-deployment-calendar", [], null, [[null, "SELECTIONSChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("SELECTIONSChange" === en)) {
        var pd_0 = ((_co.SELECTIONS = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AlertsCalendarComponent_0, i2.RenderType_AlertsCalendarComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertsCalendarComponent, [i4.NgbModal, i5.AlertService, i6.UserEventAnswerService, i7.ProfileService, i8.ToastService, i9.LoggerService, i10.ProfileDispatcherService], { alertInfo: [0, "alertInfo"], idAlert: [1, "idAlert"], SELECTIONS: [2, "SELECTIONS"] }, { SELECTIONSChange: "SELECTIONSChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertInfo; var currVal_1 = _co.idAlert; var currVal_2 = _co.SELECTIONS; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AlertsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-deployment-offer", [], null, [[null, "validate"], [null, "refuse"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("validate" === en)) {
        var pd_0 = (_co.onValidate($event) !== false);
        ad = (pd_0 && ad);
    } if (("refuse" === en)) {
        var pd_1 = (_co.onRefuse($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_AlertsDeploymentOfferComponent_0, i11.RenderType_AlertsDeploymentOfferComponent)), i1.ɵdid(1, 49152, null, 0, i12.AlertsDeploymentOfferComponent, [i13.DocumentService, i14.DeploymentOrderService, i9.LoggerService, i15.ActivatedRoute, i8.ToastService], { alertInfo: [0, "alertInfo"], idAlert: [1, "idAlert"], deploymentOffers: [2, "deploymentOffers"], nbrOffers: [3, "nbrOffers"] }, { validate: "validate", refuse: "refuse" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertInfo; var currVal_1 = _co.idAlert; var currVal_2 = _co.deploymentOffers; var currVal_3 = _co.nbrOffers; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AlertsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-deployment-order", [], null, null, null, i16.View_AlertsDeploymentOrderComponent_0, i16.RenderType_AlertsDeploymentOrderComponent)), i1.ɵdid(1, 114688, null, 0, i17.AlertsDeploymentOrderComponent, [i13.DocumentService, i9.LoggerService, i15.ActivatedRoute], { alertInfo: [0, "alertInfo"], idAlert: [1, "idAlert"], deploymentOrders: [2, "deploymentOrders"], nbrOrders: [3, "nbrOrders"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertInfo; var currVal_1 = _co.idAlert; var currVal_2 = _co.deploymentOrders; var currVal_3 = _co.nbrOrders; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_3)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_4)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_5)), i1.ɵdid(6, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarB; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.offerB; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.orderB; _ck(_v, 6, 0, currVal_2); }, null); }
function View_AlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "deployment-alert-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "alert-flex-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "alerts-map", [["class", "alert-flex-map-item shadow"]], null, null, null, i19.View_AlertsMapComponent_0, i19.RenderType_AlertsMapComponent)), i1.ɵdid(3, 49152, null, 0, i20.AlertsMapComponent, [], { alertInfo: [0, "alertInfo"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "alerts-process", [["class", "alert-flex-process-item shadow"]], null, [[null, "setting"], [null, "SELECTIONSChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setting" === en)) {
        var pd_0 = (_co.setComponent($event) !== false);
        ad = (pd_0 && ad);
    } if (("SELECTIONSChange" === en)) {
        var pd_1 = ((_co.SELECTIONS = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i21.View_AlertsProcessComponent_0, i21.RenderType_AlertsProcessComponent)), i1.ɵdid(5, 573440, null, 0, i22.AlertsProcessComponent, [i9.LoggerService], { alertInfo: [0, "alertInfo"], deploymentOffers: [1, "deploymentOffers"], nbrOrders: [2, "nbrOrders"], SELECTIONS: [3, "SELECTIONS"], nbrActiveOrders: [4, "nbrActiveOrders"] }, { setting: "setting" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "alerts-information", [["class", "alert-flex-information-item shadow"]], null, null, null, i23.View_AlertsInformationComponent_0, i23.RenderType_AlertsInformationComponent)), i1.ɵdid(7, 49152, null, 0, i24.AlertsInformationComponent, [], { alertInfo: [0, "alertInfo"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_2)), i1.ɵdid(9, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertInfo; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.alertInfo; var currVal_2 = _co.deploymentOffers; var currVal_3 = _co.nbrOrders; var currVal_4 = _co.SELECTIONS; var currVal_5 = _co.nbrActiveOrders; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.alertInfo; _ck(_v, 7, 0, currVal_6); var currVal_7 = _co.invited; _ck(_v, 9, 0, currVal_7); }, null); }
function View_AlertsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "deployment-alert-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "alert-flex-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "alerts-preloader-map", [["class", "alert-flex-map-item shadow"]], null, null, null, i25.View_AlertsPreloaderMapComponent_0, i25.RenderType_AlertsPreloaderMapComponent)), i1.ɵdid(3, 114688, null, 0, i26.AlertsPreloaderMapComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "alerts-preloader-process", [["class", "alert-flex-process-item shadow"]], null, null, null, i27.View_AlertsPreloaderProcessComponent_0, i27.RenderType_AlertsPreloaderProcessComponent)), i1.ɵdid(5, 114688, null, 0, i28.AlertsPreloaderProcessComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "alerts-preloader-information", [["class", "alert-flex-information-item shadow"]], null, null, null, i29.View_AlertsPreloaderInformationComponent_0, i29.RenderType_AlertsPreloaderInformationComponent)), i1.ɵdid(7, 114688, null, 0, i30.AlertsPreloaderInformationComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "alerts-preloader-content", [], null, null, null, i31.View_AlertsPreloaderContentComponent_0, i31.RenderType_AlertsPreloaderContentComponent)), i1.ɵdid(9, 114688, null, 0, i32.AlertsPreloaderContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_AlertsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { offer: 0 }), i1.ɵqud(402653184, 2, { order: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_1)), i1.ɵdid(3, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_6)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["id", "resetAvailabilitiesToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Availability reset"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertInfo; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.alertInfo; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "responder-alerts", [], null, null, null, View_AlertsComponent_0, RenderType_AlertsComponent)), i1.ɵdid(1, 49152, null, 0, i33.AlertsComponent, [i34.Title, i35.CacheService, i14.DeploymentOrderService, i36.DeploymentService, i9.LoggerService, i5.AlertService, i6.UserEventAnswerService, i15.ActivatedRoute], null, null)], null, null); }
var AlertsComponentNgFactory = i1.ɵccf("responder-alerts", i33.AlertsComponent, View_AlertsComponent_Host_0, {}, {}, []);
export { AlertsComponentNgFactory as AlertsComponentNgFactory };
