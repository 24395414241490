import { BehaviorSubject } from 'rxjs';
import { Dispatcher } from '../../dispatcher.service';
export class GlobalDispatcherService extends Dispatcher {
    constructor() {
        super(...arguments);
        this.allLanguages$ = new BehaviorSubject([]);
        this.allSkills$ = new BehaviorSubject([]);
    }
    nextSkills(skills) {
        this.allSkills$.next(skills);
    }
    getSkills() {
        return this.pipeNotNull(this.allSkills$);
    }
    nextLanguages(languages) {
        this.allLanguages$.next(languages);
    }
    getLanguages() {
        return this.pipeNotNull(this.allLanguages$);
    }
}
