import { GlobalDispatcherService } from './global.dispatcher';
import { UserLanguageInterface } from './../../../app/model/user-language.interface';
import { URL_API_EMALSYS } from './../../../environments/environment';
import { HttpService } from './../http.service';
import { HttpClient } from '@angular/common/http';
import { ApiCacherService } from './../../utils/api-cacher.service';
import { Injectable } from "@angular/core";
import { TagInterface } from '../../../app/model/tag.interface';

@Injectable()
export class GlobalDataService {

    private readonly ALL_LANGUAGES = 'all_languages';
    private readonly ALL_SKILLS = 'all_skills';

    constructor(
        private _apiCacher: ApiCacherService,
        private http: HttpClient,
        private _httpService: HttpService,
        private _global: GlobalDispatcherService
    ) { }

    getAllLanguages() {
        this._apiCacher.doWrapper(
            this.ALL_LANGUAGES,
            () => {
                let options = this._httpService.createRequestOptions();
                return this.http.get<UserLanguageInterface[]>(URL_API_EMALSYS + "/languages", options);
            },
            { canBeDeleted: false }
        ).subscribe(
            (res: UserLanguageInterface[]) => this._global.nextLanguages(res)
        );
    }

    getSkills() {
        this._apiCacher.doWrapper<TagInterface[]>(this.ALL_SKILLS, () => {
            const options = this._httpService.createRequestOptions();
            return this.http.get<TagInterface[]>(`${URL_API_EMALSYS}/skills/all`, options);
        },
        { canBeDeleted: false })
        .subscribe(skills => {
            this._global.nextSkills(skills);
        });
    }
}
