import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { LeafletService } from '../../../../../services/external/leaflet.service';

@Component({
  selector: 'dashboard-members-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges {

  @Input() members: any;
  @Input() organisation: any;
  @Input() selected = {};

  @Output() onProfileShow = new EventEmitter<number>();

  public map: any;
  public markers: any;

  constructor(
    private _leafletService: LeafletService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.members && changes.members.currentValue !== changes.members.previousValue)
      // || (changes.organisation && changes.organisation.currentValue != changes.organisation.previousValue)
      // || (changes.selected && changes.selected.currentValue != changes.selected.previousValue)
    ) {
      if (this.map) {
        this.markers.clearLayers();
        this.addMembers(this.members);
      } else {
        this.createMap();
        this.addMembers(this.members);
      }
    }
  }

  createMap() {
    this.map = this._leafletService.createMap("members-map", 2);
    this.markers = this._leafletService.createLayerGroup();
  }

  addMembers(members) {
    members.map(member => {
      if (member.longitude && member.latitude) {
        this.markers.addLayer(this._leafletService.createMarkerMember(member, this.organisation, this.selected, this.openProfile));
      }
    });
    this.markers.addTo(this.map);
  }

  enableZoom() {
    this.map.scrollWheelZoom.enable();
  }

  openProfile(id) {
    this.onProfileShow.emit(id);
  }
}
