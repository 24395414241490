import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DeploymentService } from '../../../../../../services/api/deployment.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
// Data
import { ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { ToastService } from '../../../../../../services/utils/toast.service';
@Component({
  selector: 'app-deployment-orders-modal',
  templateUrl: './deployment-orders-modal.component.html',
  styleUrls: ['./deployment-orders-modal.component.scss']
})
export class DeploymentOrdersModalComponent implements OnInit {
  @Input() selectedEventID: any;
  @Input() deploymentOffers;
  @Input() selectedUser;
  @Input() profile;
  @Input() organisation;
  @Output() onClickDeploy = new EventEmitter<any>();
  public selectedOffer: any ;
  public attachedFile: File = null;
  public step: number;

  public start_deployment;
  public end_deployment;
  public position_deployment;
  public message_deployment = "";
  public insurance_name;
  public insurance_number;
  public airport;
  public flight_number;
  public emails: any[];
  public deploymentOffersData;
  public loading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public _deploymentService: DeploymentService,
    public _loggerService: LoggerService,
    public _rightService: RightService,
    private _toastService: ToastService,
  ) { }


  ngOnInit() {
    const today = new Date();
    this.step = 1;
    this.deploymentOffersData = [];
    this.emails = [""];
    for (let i = 0; i < this.deploymentOffers.length; i++) {
      if (!this.deploymentOffers[i].deployment)  {
        this.deploymentOffersData.push(
          {
            id: this.deploymentOffers[i]['id'],
            offer: this.deploymentOffers[i],
            text: this.deploymentOffers[i]['position']
          }
        );
      }
    }
    if (this.deploymentOffersData && this.deploymentOffersData.length > 0) {
      this.selectedOffer = this.deploymentOffersData[0];
    }
  }

  nextStep() {
    this.step = this.step + 1;
    this.start_deployment = new Date(this.selectedOffer.offer.start_date);
    this.end_deployment = new Date(this.selectedOffer.offer.end_date);
    this.position_deployment = this.selectedOffer.offer.position;
    this.message_deployment = this.selectedOffer.offer.message ? this.selectedOffer.offer.message : "";
    this.insurance_name = "";
    this.insurance_number = "";
    this.airport = "";
    this.flight_number = "";
  }

  previousStep() {
    this.step = 1;
  }

  changedSelectOffer(data) {
    this.selectedOffer.id = data.value;
    this.selectedOffer.offer = data.data[0].offer;
    this.selectedOffer.text = data.data[0].text;
  }

  checkOrganisationAssociate() {
    return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["Associate"]);
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  deploy() {
    this.loading = true;
    let body = new FormData();
    body.append('id', this.selectedEventID);
    body.append('deployment_order_id', this.selectedOffer.id);
    body.append('position', this.position_deployment);
    body.append('responder', this.selectedUser);
    body.append('start_deployment', this.start_deployment.toLocaleString('en-EN'));
    body.append('end_deployment', this.end_deployment.toLocaleString('en-EN'));
    body.append('message', this.message_deployment);
    body.append('insurance_name', this.insurance_name);
    body.append('insurance_number', this.insurance_number);
    body.append('airport', this.airport);
    body.append('flight_number', this.flight_number);
    if (this.attachedFile) {
      body.append('file', this.attachedFile);
    }
    if (this.emails.length > 0) {
      let filteredEmails = this.emails.filter((value) => {
        return value !== "";
      });
      body.append('emails', JSON.stringify(filteredEmails));
    }
    this._deploymentService.createOrder(body).subscribe(res => {
      this.onClickDeploy.emit(res);
      this.loading = false;
      this.showToastDeployMember();
      this.activeModal.close();
    }, error => {
      this.loading = false;
      this.showToastDeployMemberError();
    });
  }

  showToastDeployMember() {
    this._toastService.show("responderDeployedToast", "show", 3000);
  }

  showToastDeployMemberError() {
    this._toastService.show("errorDeployToast", "show", 3000);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.attachedFile = fileList[0];
    }
  }
}
