import { RosterMembershipService } from './../../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

// Services
import { LoggerService } from '../../../../../../services/utils/logger.service';

// Data
import { MEMBER_CODE } from '../../../../../app.constants';


@Component({
  selector: 'roster-members',
  templateUrl: './roster-members.component.html',
  styleUrls: ['./roster-members.component.scss']
})


export class RosterMembersComponent implements OnChanges {

  @Input() rosterId: number;
  @Input() rosterStatus: string;

  public members: any;

  constructor(
    private _loggerService: LoggerService,
    private _rosterMembership: RosterMembershipService
  ) {
    this.members = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rosterId && changes.rosterId.currentValue !== changes.rosterId.previousValue) {
      this.getMembers();
    }
  }

  getMembers() {
    this._rosterMembership.getRosterMembers(this.rosterId).subscribe(res => {
      this.members = res;
      this._loggerService.log("MEMBERS : ", res);
    });
  }

  setType(member) {
    return MEMBER_CODE.filter(type => type.value === member.state)[0].label;
  }

}
