export class ProfileInterface {
}
ProfileInterface.CACHE = {
    USER_PROFILE: 'currentuser_profile',
    USER_PHONES: 'currentuser_phones',
    ALL_SKILLS: 'all_skills',
    ALL_LANGUAGES: 'all_languages',
    USER_SKILLS: 'currentuser_skills',
    USER_LANGUAGES: 'currentuser_languages',
    USER_DOCUMENTS: 'currentuser_documents',
    USER_EMERGENCY_CONTACTS: 'currentuser_emergency_contacts',
};
// add user fields
