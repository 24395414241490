<div class="calendar-component shadow">
    <i title="You can change your availability and add any time constrains, your Roster Manager will always have (in real time) your exact availability and updates on his follow up board.

If you are fully available or fully unavailable for the all deployment period use the two “quick buttons” on the top of the screen. The all deployment period will be filled with one type of availability status.
If you are partially available, with some uncertainties, click on the starting day and on the last day of the period concerned, and select one of the 3 options in the message box : Yes / Not sure / No.
  "class="fa fa-question-circle-o help-icon" aria-hidden="true">
    </i>
  <div class="label">
    <p>Use quick buttons for the entire alert</p>
  </div>

  <div class="calender-buttons-container row">
    <button class="btn emalsys_button green accept margin-b margin-r " (click)='setFullAvailability("available")'>I'm Available</button>
    <button class="btn emalsys_button red decline margin-b margin-r" (click)='setFullAvailability("notAvailable")'>I'm not Available</button>
  </div>
  <div class="label">
    <p>or select a specific period on the calendar</p>
    </div>
    <div class="calender-buttons-container row">
    <button class="btn emalsys_button blue margin-b margin-r" (click)='displayCalendar()'>Display the calendar</button>
  </div>
<div class="calendar-container flex-container column" *ngIf="display">
  <div class=" month-container flex-container row space-around">
      <button [disabled]='calendar.checkMonthValidity()' (click)="calendar.previousMonth();getDaysArray(); resetSelection()">
        <i class="fa fa-chevron-left" aria-hidden="true" ></i>
      </button>

      <p>{{calendar.getSelectedMonth()}} {{calendar.getSelectedYear()}}</p>
      <button (click)="calendar.nextMonth();getDaysArray();resetSelection()">
        <i class="fa fa-chevron-right" aria-hidden="true" ></i>
      </button>

  </div>

  <div class="days-container flex-container space-around row">

    <div class="flex-child">{{days[0].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[1].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[2].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[3].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[4].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[5].date| date: "EEE"}}</div>
    <div class="flex-child">{{days[6].date| date: "EEE"}}</div>
  </div>

  <div class="days-numbers-container flex-container row">
    <div class="line"></div>
    <div *ngFor="let day of days" style=" width:14%;">
      <div class="day-number-container"
           [class.selectedFrom]='day.status == selectionClass.SELECTED_FROM'
           [class.selectedInBetween]='day.status == selectionClass.SELECTED_BETWEEN'
           [class.selectedTo]='day.status == selectionClass.SELECTED_TO'
           [class.notAvailable]='day.status == selectionClass.NOT_AVAILABLE_STATUS'
           [class.available]='day.status == selectionClass.AVAILABLE_STATUS'
           [class.notSure]='day.status == selectionClass.NOT_SURE_STATUS'
           [class.notSetted]='day.status == selectionClass.NOT_SETTED_STATUS'
           [class.disabled]='day.status == selectionClass.DISABLED'
           (click)="onDaySelection(day.date,availabilityModal)"
           >
           <p [class.today]="calendar.currentDay(day.date)" class="day-number">{{day.date | date : "dd"}} </p>
         </div>
    </div>
  </div>


  <!-- AvailabilityModal -->
  <ng-template #availabilityModal  let-c="close" let-d="dismiss(refreshSelection())">
    <div class="modal-header">
      <h4 class="modal-title" i18n="@@changePicture">Availability</h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button> -->
    </div>
    <div class="modal-body">
      <div class="flex-container row">
        <button class="btn emalsys_button green accept margin-b margin-r"  (click)="c('available')">I'm Available</button>
       <button class="btn emalsys_button yellow not-sure margin-b margin-r"  (click)="c('notSure')">I'm Not sure</button>
        <button class="btn emalsys_button red  decline margin-b margin-r" (click)="c('notAvailable')">I'm not Available</button>
      </div>
    </div>
    <div class="modal-footer">
    </div>
  </ng-template>
</div>

<div class="selections-container flex-container center row" *ngIf="display">
  <div class="selection-container">
    <div class="selection d"></div>
    <p>Disabled</p>
  </div>
  <div class="selection-container">
    <div class="selection nse"></div>
    <p>Not Set</p>
  </div>
  <div class="selection-container">
    <div class="selection a"></div>
    <p>Available</p>
  </div>
  <div class="selection-container ">
    <div class="selection nsu"></div>
    <p>Not Sure</p>
  </div>
  <div class="selection-container">
    <div class="selection na"></div>
    <p>Not Available</p>
  </div>
</div>
<div class="flex-container center" *ngIf="display">
  <button class="btn emalsys_button red  decline margin-b margin-r" (click)='resetAll()' >Reset</button>
</div>

<!-- Toasts -->
<div id="disabledDateToast">The date that you selected is disabled</div>

<div id="wrongRangeDateToast">Select a date after the first date that you selected</div>


</div>
