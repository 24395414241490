import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Services
import { HttpService } from './api/http.service';
// Data
import { URL_API_EMALSYS } from '../environments/environment';
// Services
import { LoginService } from './security/login.service';
import { NavigationService } from './utils/navigation.service';
import { LoggerService } from './utils/logger.service';
import { ToastService } from './utils/toast.service';
// Interfaces
import { UserInterface } from '../app/model/user.interface';
import { OrganisationInterface } from '../app/model/organisation.interface';
@Injectable()


export class RegisterService {

  constructor(
    private _loginService: LoginService,
    private _navigationService: NavigationService,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

  sendVerifyEmail(body) {
    return this.http.post(URL_API_EMALSYS + "/codes/verify_email", body);
  }

  checkEmail(email, code) {
    return this.http.delete<UserInterface>(URL_API_EMALSYS + "/codes/check?email=" + email + "&code=" + code);
  }

  registerAsOrganisation(firstName, lastName, email, birthdate) {
    let user = {
      'firstname': firstName,
      'lastname': lastName,
      'email': email,
      'birthdate': new Date(birthdate).toISOString()
    };
    return this.http.post<UserInterface>(URL_API_EMALSYS + '/registration/organisation', user);
  }

  setPassword(email, password) {
    let user = {
      'email': email,
      'password': password
    };
    return this.http.put<UserInterface>(URL_API_EMALSYS + '/registration/password', user);
  }

  registerOrganisation(orga, subdomain) {
    let options = this._httpService.createRequestOptions();
    let organisation = {
      'name': orga.name,
      'acronym': orga.acronym,
      'type': orga.type,
      'website': orga.website,
      'description': orga.description,
      'subdomainName': subdomain
    };
    return this.http.post<OrganisationInterface>(URL_API_EMALSYS + '/organisations/create', organisation, options);
  }

  registerUser(firstName, lastName, email, birthdate) {
    let user = {
      'firstname': firstName,
      'lastname': lastName,
      'email': email,
      'birthdate': new Date(birthdate).toISOString()
    };
    return this.http.post(URL_API_EMALSYS + '/registration/invited', user);
  }
}
