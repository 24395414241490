import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { URL_API_EMALSYS } from '../../../../../../environments/environment';
import { HttpService } from '../../../../../../services/api/http.service';
import { ProfileDispatcherService } from '../../../../../../services/api/profile/profile.dispatcher';
import { RosterMembershipService } from '../../../../../../services/api/roster/dependencies/membership/roster-membership.api';
import { EventListenerService } from '../../../../../../services/utils/event-listener.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
import { ToastService } from '../../../../../../services/utils/toast.service';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, REGEX_TYPES } from '../../../../../app.constants';
import { UserInterface } from '../../../../../model/user.interface';
import { OrganisationMembershipService } from './../../../../../../services/api/organisation/dependencies/membership/organisation-membership.api';

@Component({
  selector: 'app-member',
  templateUrl: './member-modal.component.html',
  styleUrls: ['./member-modal.component.scss']
})
export class MemberModalComponent implements OnInit {

  public readonly CONTEXT_ORGANISATION = 'organisation';
  public readonly CONTEXT_ROSTER = 'roster';

  @Output() onClickAddMembers = new EventEmitter<any>();
  @Output() onClickAddManagers = new EventEmitter<any>();
  @Input() organisation: any;
  @Input() rosterId: any;
  @Input() addByEmail: boolean;
  @Input() context: string;
  private profile: UserInterface;
  public loading = false;
  private select2Ajax;
  private options;

  public data: string[] = [];
  public toAdd = null;
  public csv = null;
  public rights = 1;

  public emailRecipients: string[] = [];
  public typedEmail: string;

  public asManagerChecked: boolean;
  public canInviteAsManager = false;

  constructor(
    public http: HttpClient,
    public activeModal: NgbActiveModal,
    public _organisationService: OrganisationMembershipService,
    public _rosterMembership: RosterMembershipService,
    public _httpService: HttpService,
    public _loggerService: LoggerService,
    public _eventListenerService: EventListenerService,
    public _toasts: ToastService,
    public _profileDispatcher: ProfileDispatcherService,
    public _rightService: RightService
  ) { }

  ngOnInit() {

    this._profileDispatcher.getProfile().subscribe(
      (profile: UserInterface) => {
        if (this.context === this.CONTEXT_ROSTER) {
          let rosterManager = profile.roster_managers.includes(x => x.roster.id === this.rosterId);
          if (rosterManager) {
            this.canInviteAsManager = this._rightService.isRosterManagerRight(profile, this.rosterId, ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin);
          }
        }
      }
    );


    // select2 options definition

    // use select2 ajax option to get matching users from the server
    // returns emalsys users that are not members of the organisation
    if (this.context === this.CONTEXT_ORGANISATION) {
      this.select2Ajax = {
        url: URL_API_EMALSYS + "/organisations/" + this.organisation.id + "/invitable",
        delay: 250,
        transport: (params, success, failure) => {
          this.http.get(params.url + "?email=" + params.data.email, this._httpService.createRequestOptions())
            .subscribe(
              suc => {
                return success(suc);
              },
              err => {
                return failure(err);
              }
            );
        },
        dataType: 'json',
        data: params => {
          let query = {
            email: params.term
          };
          return query;
        },
        // modify the results returned from the server so that it matches
        // what Select2 expects
        processResults: data => {
          let res = {
            results: data.map(item => {
              return {
                text: item.fullname + " (" + item.email + ")",
                id: item.email
              };
            })
          };
          return res;
        }
      };
    } else if (this.context === this.CONTEXT_ROSTER) {
      this.select2Ajax = {
        url: URL_API_EMALSYS + "/rosters/" + this.rosterId + "/invitable",
        delay: 250,
        transport: (params, success, failure) => {
          this.http.get(params.url + "?email=" + params.data.email, this._httpService.createRequestOptions())
            .subscribe(
              suc => {
                return success(suc);
              },
              err => {
                return failure(err);
              }
            );
        },
        dataType: 'json',
        data: params => {
          let query = {
            email: params.term
          };
          return query;
        },
        // modify the results returned from the server so that it matches
        // what Select2 expects
        processResults: data => {
          let res = {
            results: data.map(item => {
              return {
                text: item.fullname + " (" + item.email + ")",
                id: item.email
              };
            })
          };
          return res;
        }
      };
    }


    this.options = {
      multiple: true,
      // allow manager to add new users, unregistered on the platform
      tags: true,
      ajax: this.select2Ajax,
      minimumInputLength: 1,
      // check if manager typed a correct email address before adding it
      createTag: params => {
        let emailType = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailType.test(params.term)) {
          return null;
        }
        return {
          id: params.term,
          text: params.term
        };
      }
    };
  }

  addTypedEmail() {
    if (REGEX_TYPES.EMAIL.test(this.typedEmail)) {
      this.emailRecipients.unshift(this.typedEmail);
      this.typedEmail = "";
    } else {
      this._toasts.show("email-not-valid", "show");
    }
  }

  removeRecipient(email: string) {
    let index = this.emailRecipients.findIndex(x => x === email);
    if (index !== -1) {
      this.emailRecipients.splice(index, 1);
    }
  }

  changed(data) {
    this.toAdd = data.value;
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.csv = fileList[0];
    }
  }

  addMembers() {
    let isChecked = this.asManagerChecked;
    let params: any;
    if (this.addByEmail) {
      params = {
        emails: this.context === this.CONTEXT_ORGANISATION ? this.emailRecipients : this.toAdd
      };
      if (isChecked) {
        params.rights = this.rights;
      }
    } else if (!this.addByEmail) {
      params = new FormData();
      params.append('csv', this.csv);
      if (isChecked) {
        params.append('rights', this.rights);
      }
    }
    this.loading = true;
    if (this.context === this.CONTEXT_ORGANISATION) {
      if (isChecked) {
        this._organisationService.addManagers(this.organisation.id, params).subscribe(res => {
          this.onClickAddManagers.emit(res);
          this.loading = false;
          this.activeModal.close();
        });
      } else {
        this._organisationService.addMembers(this.organisation.id, params).subscribe(res => {
          this.onClickAddMembers.emit(res);
          this.loading = false;
          this.activeModal.close();
        });
      }
    } else if (this.context === this.CONTEXT_ROSTER) {
      if (isChecked) {
        this._rosterMembership.inviteManagers(this.rosterId, params).subscribe(res => {
          this.onClickAddManagers.emit(res);
          this.loading = false;
          this.activeModal.close();
        });
      } else {
        this._rosterMembership.inviteMembers(this.rosterId, params).subscribe(res => {
          this.onClickAddMembers.emit(res);
          this.loading = false;
          this.activeModal.close();
        });
      }
    }

    // delete content of toAdd and CSV
    this.toAdd = null;
    this.csv = null;
    this.rights = 1;
  }
}
