/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerts-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alerts-map.component";
var styles_AlertsMapComponent = [i0.styles];
var RenderType_AlertsMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsMapComponent, data: {} });
export { RenderType_AlertsMapComponent as RenderType_AlertsMapComponent };
export function View_AlertsMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "alerts-map-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "alerts-map"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.errorHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", _co.imgUrl, "/", _co.iso3[((_co.alertInfo == null) ? null : _co.alertInfo.country)], ".png"); _ck(_v, 1, 0, currVal_0); }); }
export function View_AlertsMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts-map", [], null, null, null, View_AlertsMapComponent_0, RenderType_AlertsMapComponent)), i1.ɵdid(1, 49152, null, 0, i2.AlertsMapComponent, [], null, null)], null, null); }
var AlertsMapComponentNgFactory = i1.ɵccf("alerts-map", i2.AlertsMapComponent, View_AlertsMapComponent_Host_0, { alertInfo: "alertInfo" }, {}, []);
export { AlertsMapComponentNgFactory as AlertsMapComponentNgFactory };
