/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-document-compliances-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../node_modules/ng2-select2/ng2-select2.component.ngfactory";
import * as i4 from "ng2-select2/ng2-select2.component";
import * as i5 from "../../spinner/spinner.component.ngfactory";
import * as i6 from "../../spinner/spinner.component";
import * as i7 from "@angular/common";
import * as i8 from "./add-document-compliances-modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../../../../services/api/profile/profile.api";
import * as i11 from "../../../../../services/utils/logger.service";
import * as i12 from "../../../../../services/api/roster/dependencies/compliances/roster-compliances.api";
import * as i13 from "../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api";
import * as i14 from "angular2-http-file-upload/uploader/uploader";
import * as i15 from "../../../../../services/security/wsse.service";
var styles_AddDocumentCompliancesModalComponent = [i0.styles];
var RenderType_AddDocumentCompliancesModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddDocumentCompliancesModalComponent, data: {} });
export { RenderType_AddDocumentCompliancesModalComponent as RenderType_AddDocumentCompliancesModalComponent };
function View_AddDocumentCompliancesModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload document"]))], null, null); }
function View_AddDocumentCompliancesModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select a document from your gallery"]))], null, null); }
function View_AddDocumentCompliancesModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "compliance-properties"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "title-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["for", "compliance-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["id", "compliance-title"], ["placeholder", "Title"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.titleFile = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "file-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "label", [["for", "compliance-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "input", [["id", "compliance-file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.titleFile; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.extensionFile; _ck(_v, 11, 0, currVal_8); }); }
function View_AddDocumentCompliancesModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "compliance-properties"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["for", "compliance-gallery"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "select2", [["class", "select"], ["cssImport", "true"], ["id", "compliance-gallery"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.changedSelectDocument($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Select2Component_0, i3.RenderType_Select2Component)), i1.ɵdid(4, 4964352, null, 0, i4.Select2Component, [i1.Renderer], { data: [0, "data"], value: [1, "value"], cssImport: [2, "cssImport"], options: [3, "options"] }, { valueChanged: "valueChanged" }), i1.ɵpod(5, { multiple: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentListSelect2; var currVal_1 = ((_co.selectedDocument == null) ? null : _co.selectedDocument.id); var currVal_2 = "true"; var currVal_3 = _ck(_v, 5, 0, false); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AddDocumentCompliancesModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-custom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i6.SpinnerComponent, [], null, null)], null, null); }
function View_AddDocumentCompliancesModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use gallery"]))], null, null); }
function View_AddDocumentCompliancesModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload a document"]))], null, null); }
function View_AddDocumentCompliancesModalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn emalsys_button green"], ["type", "submit"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadDoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0 }), (_l()(), i1.ɵted(-1, null, ["Upload"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn emalsys_button green"; var currVal_3 = _ck(_v, 2, 0, (!_co.selectedFile || _co.loading)); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = (!_co.selectedFile || _co.loading); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AddDocumentCompliancesModalComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn emalsys_button green"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addCompliance(_co.selectedDocument.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0 }), (_l()(), i1.ɵted(-1, null, ["Use"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn emalsys_button green"; var currVal_2 = _ck(_v, 2, 0, (!_co.selectedDocument || _co.loading)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.selectedDocument || _co.loading); _ck(_v, 0, 0, currVal_0); }); }
export function View_AddDocumentCompliancesModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_4)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_5)), i1.ɵdid(15, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "button", [["class", "btn emalsys_button blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.gallery = !_co.gallery) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_6)), i1.ɵdid(19, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_7)), i1.ɵdid(21, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_8)), i1.ɵdid(23, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddDocumentCompliancesModalComponent_9)), i1.ɵdid(25, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.gallery; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.gallery; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.gallery; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.gallery; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.loading; _ck(_v, 15, 0, currVal_4); var currVal_5 = !_co.gallery; _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.gallery; _ck(_v, 21, 0, currVal_6); var currVal_7 = !_co.gallery; _ck(_v, 23, 0, currVal_7); var currVal_8 = _co.gallery; _ck(_v, 25, 0, currVal_8); }, null); }
export function View_AddDocumentCompliancesModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-add-document-compliances-modal", [], null, null, null, View_AddDocumentCompliancesModalComponent_0, RenderType_AddDocumentCompliancesModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.AddDocumentCompliancesModalComponent, [i9.NgbActiveModal, i10.ProfileService, i11.LoggerService, i12.RosterCompliancesService, i13.OrganisationCompliancesService, i14.Uploader, i15.WSSEService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddDocumentCompliancesModalComponentNgFactory = i1.ɵccf("app-add-document-compliances-modal", i8.AddDocumentCompliancesModalComponent, View_AddDocumentCompliancesModalComponent_Host_0, { complianceId: "complianceId", context: "context" }, { onAdd: "onAdd" }, []);
export { AddDocumentCompliancesModalComponentNgFactory as AddDocumentCompliancesModalComponentNgFactory };
