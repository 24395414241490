import { Component } from '@angular/core';

// Services
import { DocumentationService } from '../../../../services/api/documentation.service';

@Component({
  selector   : 'administration-documentation',
  templateUrl: 'administration-documentation.component.html',
  styleUrls  : ['administration-documentation.component.scss']
})

export class AdministrationDocumentationComponent {

  // Variable declarations

  // Data retrieved by the documentation service call
  public documentationContent: any;

  constructor(
    private documentationService: DocumentationService
  ) {
    this.documentationContent = this.documentationService.getDocumentation()
                                    .subscribe(res => {
                                      res;
                                    });
  }

}
