<!--Profile page-->
<div class="container-fluid">
  <!--1st row-->
  <div class="row">
    <!--Photo-->
    <div class="col-md-2 col-sm-4 col-xs-6 col-picture">
      <profile-picture [picture]='picture'></profile-picture>
    </div>

    <!--Name and description-->
    <div class="col-md-8 col-sm-5 col-xs-12 col-picture" *ngIf="profile">
      <profile-user [profile]='profile'></profile-user>
    </div>
    <div class="col-md-8 col-sm-5 col-xs-12 col-picture" *ngIf="!profile">
      <preloader-profile-user></preloader-profile-user>
    </div>

    <!--Phones, emergency contact-->
    <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="profile">
      
      <profile-phones [phones]="profile.profile.phones"></profile-phones>
      <profile-contact [contacts]="profile.profile.emergency_contact"></profile-contact>
    </div>
    <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="!profile">
      <preloader-profile-phones></preloader-profile-phones>
      <preloader-profile-contact></preloader-profile-contact>
    </div>
  </div>

  <!--2nd row-->
  <div class="row">
    <!--Skills, languages, documents-->
    <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="profile">
      <profile-skills [skills]="profile.user_tags"></profile-skills>
      <profile-tags [profile]='profile'></profile-tags>
      <profile-languages [languages]="profile.profile.languages"></profile-languages>
      <profile-documents [userId]="userId"></profile-documents>
    </div>
    <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="!profile">
      <preloader-profile-skills></preloader-profile-skills>
      <preloader-profile-languages></preloader-profile-languages>
      <preloader-profile-documents> </preloader-profile-documents>
    </div>
    <!--General information, deployments-->
    <div class="col-lg-8 col-md-12 col-sm-12" *ngIf="profile">
      <profile-general [profile]="profile" [isExternal]="true"></profile-general>
    </div>
    <div class="col-lg-8 col-md-12 col-sm-12" *ngIf="!profile">
      <preloader-profile-general></preloader-profile-general>
    </div>
  </div>

  <!--Toast-->
  <div id="retrievingProfileToast">Profile could not be loaded, try again later</div>
</div>
