import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Constants
import { URL_API_EMALSYS } from '../../../../environments/environment';
import { HttpService } from '../../../../services/api/http.service';
import { FilterInterface } from '../model/filter';

@Injectable()
export class FilterService {

    /**
     * List of filters registered
     */
    static registeredFilters: any = {};

    constructor(
        private http: HttpClient,
        private _httpService: HttpService
    ) {

    }

    /**
     * Register filters block to avoid duplicate
     * @param filters
     */
    subscribe(filterObject: any | Array<FilterInterface> | FilterInterface) {
        if (Array.isArray(filterObject)) {
            filterObject.forEach((filter: FilterInterface) => {
                this.addSingleFilter(filter);
            });
        } else {
            this.addSingleFilter(filterObject);
        }
    }

    /**
     * Get unique name of filter for registration and verify if filter already exist in list registeredFilters
     * @param filter
     */
    private addSingleFilter(filter: FilterInterface) {
        if (!FilterService.registeredFilters[filter.getFullname()]) {
            FilterService.registeredFilters[filter.getFullname()] = filter;
        }
        // else{
        // throw new Error('This filter (\''+filter.getFullname()+'\') is already registered');
        // }
    }

    /**
     * Delete filter in the list registeredFilters
     * @param filter
     */
    unsubscribe(filter: FilterInterface) {
        delete FilterService.registeredFilters[filter.getFullname()];
    }

    getRostersForSelector(organisationId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/rosters?organisation=" + organisationId, options);
    }

    getEventsForSelector(organisationId) {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/events?organisation=" + organisationId, options);
    }

    getLastDate() {
        let options = this._httpService.createRequestOptions();
        return this.http.get(URL_API_EMALSYS + "/reporting/lastdate", options);
    }

}
