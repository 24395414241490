/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-features-managers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-features-managers.component";
var styles_AdministrationFeaturesManagers = [i0.styles];
var RenderType_AdministrationFeaturesManagers = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationFeaturesManagers, data: {} });
export { RenderType_AdministrationFeaturesManagers as RenderType_AdministrationFeaturesManagers };
export function View_AdministrationFeaturesManagers_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This is the managers features management page in progress....\n"]))], null, null); }
export function View_AdministrationFeaturesManagers_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-features-managers", [], null, null, null, View_AdministrationFeaturesManagers_0, RenderType_AdministrationFeaturesManagers)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationFeaturesManagers, [], null, null)], null, null); }
var AdministrationFeaturesManagersNgFactory = i1.ɵccf("administration-features-managers", i2.AdministrationFeaturesManagers, View_AdministrationFeaturesManagers_Host_0, {}, {}, []);
export { AdministrationFeaturesManagersNgFactory as AdministrationFeaturesManagersNgFactory };
