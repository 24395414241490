import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { LoginService } from '../../../../../services/security/login.service';
import { CacheService } from '../../../../../services/storage/cache.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {
  @Input() password;
  @Output() onValidate = new EventEmitter();
  public loading: boolean = false;
  constructor(

    private _loggerService: LoggerService,
    private _loginService: LoginService,
    public cache: CacheService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  validate() {
    this.loading=true
    this.onValidate.emit(this.password)
  }

}
