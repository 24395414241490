import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RostersDispatcherService } from '../../../../../../services/api/roster/rosters.dispatcher';
import { RosterInterface } from '../../../../../model/roster.interface';
import { RosterService } from './../../../../../../services/api/roster/roster.cached';
import { ToastService } from './../../../../../../services/utils/toast.service';


@Component({
	selector: 'app-roster',
	templateUrl: './roster-modal.component.html',
	styleUrls: ['./roster-modal.component.scss']
})

export class RosterModalComponent {

	@Input() organisation: any;
	@Output() onCreate = new EventEmitter<any>();
	public loading = false;
	public roster: any = {};
	public profile: any = {};
	public logo: File;

	constructor(
		public activeModal: NgbActiveModal,
		private _rosterService: RosterService,
		private _rosterDispatcher: RostersDispatcherService,
		private _toastService: ToastService
	) { }

	fileChange(event) {
		let fileList: FileList = event.target.files;
		if (fileList.length > 0) {
			this.logo = fileList[0];
		}
	}

	createRoster() {

		this.loading = true;
		let body = new FormData();
		body.append('logo', this.logo);
		body.append('organisation', this.organisation);
		body.append('roster', JSON.stringify(this.roster));
		body.append('profile', JSON.stringify(this.profile));
		this._rosterService.createRoster(body).subscribe((res: RosterInterface) => {
			this.loading = false;
			this.activeModal.close(this.roster);
			this._rosterDispatcher.nextSelectedRosterId(res.id);
		},
		() => {
			this._toastService.showRed("The roster has not been created");
		});
	}

}
