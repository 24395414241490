<!--Dashboard component: dashboard-manager of selected organisation -->
<div class="dashboard-manager-component" *ngIf="organisation">
  <div class="dashboard-component-header">
    <dashboard-preloader-rosters *ngIf="!loadedRosters" class="dashboard-component-rosters shadow">
    </dashboard-preloader-rosters>
    <dashboard-manager-rosters *ngIf="loadedRosters"  class="dashboard-component-rosters shadow" #rosters></dashboard-manager-rosters>
    <!-- Number of alerts and 'Go to events' button -->
    <dashboard-preloader-buttons *ngIf="!loadedAlerts" class="dashboard-misc-links shadow">
    </dashboard-preloader-buttons>
    <div class="dashboard-misc-links shadow" *ngIf="loadedAlerts">
      <div class="dashboard-misc-buttons">
        <p class="alert-number">{{ eventsCount }}</p>
        <p class="alert-text">Alerts</p>
        <button class="button" routerLink='alert'><i class="fa fa-bell"></i>Go to Events</button>
      </div>
    </div>
  </div>
  <dashboard-preloader-search *ngIf="!loadedSkillsAndLanguages" class="dashboard-component-search shadow">
  </dashboard-preloader-search>
  <app-search *ngIf="loadedSkillsAndLanguages" class="dashboard-component-search shadow"
    [selectedRoster]="selectedRoster" (filteredMembers)="setFilteredMembers($event)" #search></app-search>
  <dashboard-preloader-members *ngIf="!loadedMembers" class="dashboard-component-members shadow">
  </dashboard-preloader-members>
  <dashboard-members *ngIf="loadedMembers" class="dashboard-component-members shadow" [selected]='selected'
    [organisation]='organisation' [members]='filteredMembers' [page]="'members'">
  </dashboard-members>

  <div id="organisationMemberAddToast">Member(s) invited</div>
  <div id="organisationManagerAddToast">Manager(s) invited</div>
  <div id="rosterCreateToast">Roster created</div>
</div>

<div class="add-button" *ngIf="canIAddOrganisationMembers || canIAddRosterMembers">
  <button class="parent" [ngClass]="{ 'clicked' : adding }" (click)="currentlyAdding()">
    <div class="plus" *ngIf="!adding"> <i class="fa fa-plus"></i> </div>
    <div class="plus" *ngIf="adding"> <i class="fa fa-times"></i> </div>
  </button>
  <div class="children" *ngIf="adding">
    <button class="add-member" (click)="addMember('addByEmail'); adding=false;">
      <div class="plus">
        <i class="fa fa-envelope"></i>
        Add members
      </div>
    </button>

    <button class="add-csv" (click)="addMember('addByCsv'); adding=false;">
      <div class="plus">
        <i class="fa fa-file"></i>
        Import from CSV
      </div>
    </button>
  </div>
</div>