export class Availability {

    static readonly AVAILABLE = 1;
    static readonly UNAVAILABLE = 0;
    static readonly NOTSURE = 2;


    static checkAvailability(date: string): boolean {
        let today = new Date().toDateString();
        let timestampToday = Date.parse(today);
        let result = Date.parse(date);
        return (result <= timestampToday) ? true :  false;
    }
}
