/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roster-members.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./roster-members.component";
import * as i4 from "../../../../../../services/utils/logger.service";
import * as i5 from "../../../../../../services/api/roster/dependencies/membership/roster-membership.api";
var styles_RosterMembersComponent = [i0.styles];
var RenderType_RosterMembersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RosterMembersComponent, data: {} });
export { RenderType_RosterMembersComponent as RenderType_RosterMembersComponent };
function View_RosterMembersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "member-picture"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.user.profile.picture, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_RosterMembersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "member-picture"], ["src", "assets/images/default_avatar.png"]], null, null, null, null, null))], null, null); }
function View_RosterMembersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["class", "members-member"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterMembersComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterMembersComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "member-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [["class", "member-status"]], [[2, "pending", null], [2, "frozen", null], [2, "active", null], [2, "invited", null]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.user.profile.picture; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.user.profile.picture; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.user.firstname; var currVal_3 = _v.context.$implicit.user.lastname; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.state == (0 - 1)); var currVal_5 = (_v.context.$implicit.state == 0); var currVal_6 = (_v.context.$implicit.state == 1); var currVal_7 = (_v.context.$implicit.state == 2); _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.setType(_v.context.$implicit); _ck(_v, 8, 0, currVal_8); }); }
export function View_RosterMembersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "shadow"], ["id", "roster-members-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "emalsys_section-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Roster Members"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "roster-members-component-members"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterMembersComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.members; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_RosterMembersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "roster-members", [], null, null, null, View_RosterMembersComponent_0, RenderType_RosterMembersComponent)), i1.ɵdid(1, 573440, null, 0, i3.RosterMembersComponent, [i4.LoggerService, i5.RosterMembershipService], null, null)], null, null); }
var RosterMembersComponentNgFactory = i1.ɵccf("roster-members", i3.RosterMembersComponent, View_RosterMembersComponent_Host_0, { rosterId: "rosterId", rosterStatus: "rosterStatus" }, {}, []);
export { RosterMembersComponentNgFactory as RosterMembersComponentNgFactory };
