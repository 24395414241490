/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modules/shared/banner/banner.component.ngfactory";
import * as i3 from "./modules/shared/banner/banner.component";
import * as i4 from "@angular/router";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../services/security/login.service";
import * as i7 from "../services/api/organisation/organisation.api";
import * as i8 from "../services/api/organisation/organisation.dispatcher";
import * as i9 from "../services/api/organisation/organisation.cacher";
import * as i10 from "../services/api/profile/profile.api";
import * as i11 from "../services/api/notification.service";
import * as i12 from "../services/utils/navigation.service";
import * as i13 from "../services/storage/cache.service";
import * as i14 from "../services/utils/logger.service";
import * as i15 from "../services/utils/event-listener.service";
import * as i16 from "../services/api/profile/profile.dispatcher";
import * as i17 from "../services/utils/right.service";
import * as i18 from "./modules/shared/banner/banner-updater.dispatcher";
import * as i19 from "../services/page/global.pager";
import * as i20 from "../services/page/dashboard.manager.pager";
import * as i21 from "./modules/shared/toast/toast/toast.component.ngfactory";
import * as i22 from "./modules/shared/toast/toast/toast.component";
import * as i23 from "../services/utils/toast.service";
import * as i24 from "./app.component";
import * as i25 from "../services/utils/update.service";
import * as i26 from "../services/utils/cache.notifications";
import * as i27 from "../services/api/pushnotifications.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["id", "app-component"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBodyClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-banner", [], null, null, null, i2.View_BannerComponent_0, i2.RenderType_BannerComponent)), i1.ɵdid(2, 114688, null, 0, i3.BannerComponent, [i4.Router, i5.NgbModal, i6.LoginService, i7.OrganisationApiService, i8.OrganisationDispatcherService, i9.CachedOrganisationService, i10.ProfileService, i11.NotificationService, i12.NavigationService, i13.CacheService, i14.LoggerService, i15.EventListenerService, i16.ProfileDispatcherService, i17.RightService, i18.BannerUpdater, i19.GlobalPageService, i20.DashboardManagerPageService], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-toast", [], null, null, null, i21.View_ToastComponent_0, i21.RenderType_ToastComponent)), i1.ɵdid(6, 114688, null, 0, i22.ToastComponent, [i23.ToastService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i24.AppComponent, [i13.CacheService, i25.UpdateService, i18.BannerUpdater, i26.CacheNotificationsService, i4.Router, i27.PushNotificationService, i16.ProfileDispatcherService, i14.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i24.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
