import { Component, OnInit } from '@angular/core';

// Service
import { SettingsService   } from '../../../../../services/api/settings-service';
import { CacheService      } from '../../../../../services/storage/cache.service';
import { NgbModal          } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '../../../../../services/utils/navigation.service';
import { ToastService      } from '../../../../../services/utils/toast.service';
import { LoggerService  } from '../../../../../services/utils/logger.service';

@Component({
  selector: 'preloader-profile-delete-account',
  templateUrl: './profile-delete-account.component.html',
  styleUrls: ['./profile-delete-account.component.scss']
})
export class ProfileDeleteAccountComponent implements OnInit {

    constructor(
      private _settingsService: SettingsService,
      private _navigationService: NavigationService,
      private _cacheService: CacheService,
      private _modalService: NgbModal,
      private _toastService: ToastService,
      private _loggerService: LoggerService
    ) {}

    ngOnInit() {}

    deleteMyAccount() {
      this._settingsService.deleteMyAccount()
        .subscribe(res => {
          this._loggerService.log("Account deleted");
          this._navigationService.goToPublic();
          this._cacheService.clear();
        });
    }

    open(content) {
      this._modalService.open(content, { windowClass: 'settings-modal', backdrop: 'static' });
    }

    showToastDeleted() {
      this._toastService.show("snackbarDelete", "show", 3000);
    }

    showToastCancel() {
      this._toastService.show("snackbarCancel", "show", 3000);
    }
}
