import { Component, OnInit, Input } from '@angular/core';

// Services
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../services/utils/toast.service';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';


@Component({
  selector: 'profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})


export class ProfileUserComponent implements OnInit {

  @Input() profile: any;
  // @Input() picture: any;
  public editEnabled = false;
  public aboutMe: string;

  public map: any;

  constructor(
    // private _leafletService : LeafletService,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    private _profileService: ProfileService,
    private _profileDispatcher: ProfileDispatcherService
  ) { }

  ngOnInit() {
    // this.createMap();
    this.getAboutMe();

    if (!this.profile) {
      this._profileDispatcher.getProfile().subscribe(res => {
        this.profile = res;
        this._loggerService.log("PROFILE : ", res);
        this.editEnabled = false;
      }, err => {
        this._loggerService.error("ERROR getting profile: ", err);
      });

    }
  }

  createMap() {
    // this.map = this._leafletService.createMap("profile-user-component-map", 7);
  }

  getAboutMe() {
    this.aboutMe = this.profile.profile.aboutme ? this.profile.profile.aboutme : 'No info yet';
  }

  enableEdit() {
    this.editEnabled = true;
  }

  disableEdit() {
    this.editEnabled = false;
  }

  saveChanges() {
    let profile = {
      profile: {
        about_me: this.aboutMe
      }
    };
    this.editEnabled = false;
    this._profileService.updateProfile(profile)
      .subscribe(res => {
        this._loggerService.log("User information updated");
        this.showToastUpdated();
      }, err => {
        this._loggerService.error("User information update error: ", err);
      });
  }

  showToastUpdated() {
    this._toastService.show("aboutMeUpdateToast", "show", 3000);
  }

}
