/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-picture.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../spinner/spinner.component.ngfactory";
import * as i3 from "../../spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./profile-picture.component";
import * as i6 from "../../../../../services/api/profile/profile.api";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "angular2-http-file-upload/uploader/uploader";
import * as i9 from "../../../../../services/security/wsse.service";
import * as i10 from "../../../../../services/utils/toast.service";
import * as i11 from "../../../../../services/utils/logger.service";
import * as i12 from "../../../../../services/utils/event-listener.service";
var styles_ProfilePictureComponent = [i0.styles];
var RenderType_ProfilePictureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfilePictureComponent, data: {} });
export { RenderType_ProfilePictureComponent as RenderType_ProfilePictureComponent };
function View_ProfilePictureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "change-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePicture(i1.ɵnov(_v.parent, 5)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Change picture"]))], null, null); }
function View_ProfilePictureComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "spinner-component", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], null, null)], null, null); }
function View_ProfilePictureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change the picture"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.thumbnailUrl = null;
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "upload-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["class", "control-label"], ["for", "prevention-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload a new picture"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "form-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [["fileInput", 1]], null, 0, "input", [["accept", ".jpg,.png,.jpeg"], ["id", "file-input"], ["placeholder", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "roster-image image-custom"], ["onerror", "this.src=\"assets/images/default_avatar.png\""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePictureComponent_3)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn emalsys_button red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectedFile = null;
        _co.thumbnailUrl = null;
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn emalsys_button green"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadDoc() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 15, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.thumbnailUrl ? _co.thumbnailUrl : _co.picture); _ck(_v, 12, 0, currVal_0); var currVal_2 = (!_co.selectedFile || _co.loading); _ck(_v, 19, 0, currVal_2); }); }
export function View_ProfilePictureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "shadow"], ["id", "profile-picture-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "profile-user-component-picture"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = ((_co.showOptions = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.showOptions = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "picture-image"], ["onerror", "this.src=\"assets/images/default_avatar.png\""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePictureComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_ProfilePictureComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["id", "pictureUploadToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Picture uploaded"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["id", "pictureUploadErrorToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error occured!"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isItMe && _co.showOptions); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.picture; _ck(_v, 2, 0, currVal_0); }); }
export function View_ProfilePictureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-picture", [], null, null, null, View_ProfilePictureComponent_0, RenderType_ProfilePictureComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProfilePictureComponent, [i6.ProfileService, i7.NgbModal, i8.Uploader, i9.WSSEService, i10.ToastService, i11.LoggerService, i12.EventListenerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePictureComponentNgFactory = i1.ɵccf("profile-picture", i5.ProfilePictureComponent, View_ProfilePictureComponent_Host_0, { picture: "picture", isItMe: "isItMe" }, {}, []);
export { ProfilePictureComponentNgFactory as ProfilePictureComponentNgFactory };
