import { filter } from 'rxjs/operators';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { ToastComponent } from './../../app/modules/shared/toast/toast/toast.component';
import { Injectable } from '@angular/core';


export class ToastInformations {
  public static readonly RED = 'red';
  public static readonly GREEN = 'green';

  public static readonly CLOSE_TOAST = 'close_toast';
  text: string;
  color: string;
  timer: number;
}
@Injectable()


export class ToastService {

  private toast$ = new Subject<ToastInformations>();

  constructor() { }

  /**
   * @deprecated
   * use showGreen and showRed instead
   *
   * Shows a toast by using the element identified by an id.
   * @param  {string}    targetId  the element to show
   * @param  {string}    className the toast class
   * @param  {number}    timer =3000 the visibility time
   */
  show(targetId: string, className: string = 'show', timer: number = 3000) {
    let element = document.getElementById(targetId);
    element.classList.add(className);
    setTimeout(() => {
      element.classList.remove(className);
    }, timer);
  }

  getToasts() {
    // got by the toast component
    return this.toast$.asObservable();
  }

  // TODO improve name after implementing the service
  private showNew(text: string, timer = 3000, color: string) {
    this.toast$.next(
      <ToastInformations>{
        text: text,
        color: color,
        timer: timer
      }
    );
  }

  showGreen(text: string, timer = 3000) {
    this.showNew(text, timer, 'green');
  }


  showRed(text: string, timer = 3000) {
    this.showNew(text, timer, 'red');
  }

  // To stop the toast early for any reason
  closeToast() {
    this.toast$.error(ToastInformations.CLOSE_TOAST);
    // this.show = false;
  }

  showAnyToast(targetId: string, className: string, timer: number = 3000) {
    let snackToShow = document.getElementById(targetId);
    snackToShow.className = className;
    setTimeout(function () {
      snackToShow.className = snackToShow.className.replace(className, "");
    }, timer);
  }

}
