import { Component } from '@angular/core';

@Component({
  selector   : 'user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls  : ['./user-guide.component.scss']
})


export class UserGuideComponent {

  constructor() {}

}
