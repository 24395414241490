import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

@Injectable()
export class NotificationService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) { }

 // Get user's number of notifications
  countNotifications() {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/me/notifications/number", options);
  }

  // Get all user's notifications
 getNotifications() {
    let options = this._httpService.createRequestOptions();
    return this.http.get(URL_API_EMALSYS + "/me/notifications", options);
  }
}
