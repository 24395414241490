// Components
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Title }     from '@angular/platform-browser';
@Component({
  selector   : 'not-found',
  templateUrl: './notFound.component.html',
  styleUrls  : ['./notFound.component.scss']
})


export class NotFoundComponent {

  constructor(
    private titleService: Title ,
    private location: Location) {
      this.titleService.setTitle("Emalsys - NotFound");
    }
  back() {
    this.location.back();
  }
}
