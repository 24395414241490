<!--Roster-compliances component-->
<div class="roster-compliances-component shadow">
  <div class="roster-compliances-component-compliances">
    <div class="title preloader"></div>
    <div class="line preloader"></div>
    <div class="line preloader"></div>
    <div class="line preloader"></div>
    <div class="title preloader"></div>
    <div class="line preloader"></div>
    <div class="line preloader"></div>
    <div class="line preloader"></div>
  </div>
</div>