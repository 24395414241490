<!--Name & Description component-->
<div id="profile-user-component" class="shadow">
  <!--Name of profile-->
  <div class="emalsys_section-title" *ngIf='profile'>
    <h2>{{profile.firstname}} {{profile.lastname}}</h2>
  </div>
  <!--Edit button-->
  <div class="edit-button" *ngIf="!editEnabled && !profile.external" (click)="enableEdit()">
    <i class="fa fa-pencil custom-edit-icon"></i>
  </div>
  <!--Description of profile-->
  <div class="profile-user-component-infos">
    <!-- <div class="infos-labels">
      <p class="labels-label">Gender :</p>
      <p class="labels-label">Email :</p>
      <p class="labels-label">Birthdate :</p>
      <p class="labels-label">Address :</p>
    </div>
    <div class="infos-values" *ngIf='profile'>
      <p class="values-value">{{profile.profile.gender == 0 ? "Male" : profile.profile.gender == 1 ? "Female" : ""}}</p>
      <p class="values-value">{{profile.profile.contact_email ? profile.profile.contact_email : "Email not specified"}}</p>
      <p class="values-value">{{profile.profile.birthday | dateFormat:"DD":"MMMM":"YYYY"}}</p>
      <p>{{profile.profile.profile_address.address ? profile.profile.profile_address.address : "Address not specified"}}</p>
      <p>{{profile.profile.profile_address.city}}</p>
      <p>{{profile.profile.profile_address.region}}</p>
      <p class="values-value">{{profile.profile.profile_address.country}}</p>
    </div> -->

<!--If edit is not enabled:-->
    <div class="information" *ngIf="!editEnabled">
      <h3> {{aboutMe}}
      </h3>
    </div>
    <!-- <div class="infos-map" id="profile-user-component-map"></div> -->
  </div>

<!--If edit is enabled:-->
  <div class="information" *ngIf="editEnabled">
    <!--Editable Text-->
    <textarea [rows]="3" pInputTextarea autoResize="true" [(ngModel)]="aboutMe"></textarea>
  </div>

  <div class="ui-g-12 ui-md-12 save-button" *ngIf="editEnabled">
    <!--Cancel button-->
    <button class="btn emalsys_button red" type="button" name="button" (click)="disableEdit()">Cancel</button>
    <!--Save button-->
    <button class="btn emalsys_button green" type="button" name="button" (click)="saveChanges()">Save</button>
  </div>
  <!--Toast-->
  <div id="aboutMeUpdateToast">Your information has been updated</div>

</div>
