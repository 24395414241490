import { Injectable } from '@angular/core';
import { UserEventAnswerService } from '../api/usereventanswer.service';
import { DeploymentService } from '../api/deployment.service';
import { DeploymentOrderService } from '../api/deployment-order.service';
import { AvailabilitesDispatcher } from '../api/availabilites/availabilites.dispatcher';
import { DeploymentOffersDispatcher } from '../api/deploymentoffers/deployment-offers.dispatcher';
import { DeploymentOrdersDispatcher } from '../api/deploymentorders/deployment-orders.dispatcher';
import { MembersDispatcherService } from '../api/member/members.dispatcher';
import { UserEventService } from '../api/userevent.service';
import { OrganisationDispatcherService } from '../api/organisation/organisation.dispatcher';
import { AlertDispatcherService } from '../api/alert/alert.dispatcher';
import { AlertService } from '../api/alert/alert.api';
import { AlertInterface } from '../../app/model/alert.interface';
import { switchMap, tap, map, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserInterface } from '../../app/model/user.interface';


/**
 * This class provide functions to manage different actions on the Alert page (manager section).
 *
 * MISSING:
 *  2) edit alert (modal with all its functionalities)
 *  3) alert creation
 */
@Injectable()
export class AlertPageService {

    private subscriptions: Subscription[] = [];

    constructor(
        private _alertDispatcher: AlertDispatcherService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _userEventService: UserEventService,
        private _usereventanswerService: UserEventAnswerService,
        private _memberDispatcher: MembersDispatcherService,
        private _availabilitesDispatcher: AvailabilitesDispatcher,
        private _deploymentOffersDispatcher: DeploymentOffersDispatcher,
        private _deploymentOrdersDispatcher: DeploymentOrdersDispatcher,
        private _deploymentorderService: DeploymentOrderService,
        private _deploymentService: DeploymentService,
    ) { }

    public selectAlert(alert: AlertInterface) {
        // TODO check why sometimes the alert is undefined
        if (alert === undefined) {
            return;
        }
        this._alertDispatcher.nextSelectedAlert(alert);

        this.subscriptions.push(
            // Take the organisation
            this._organisationDispatcher.getSelectedOrganisation()
                .pipe(
                    // Sometimes the alert is undefined
                    // TODO: check why this happens
                    filter(_ => alert !== undefined),
                    // Get the users from organisation and alert
                    switchMap(organisation => this._userEventService.getUserEvents(organisation.id, { event: alert.id })),
                    // Publish the users to display them
                    // TODO: test carefully this. I'm supposing that we use this function ONLY into alerts page
                    tap((eventMembers: UserInterface[]) => this._memberDispatcher.publishMembers(eventMembers)),
                    // Make an array of user ids
                    map(eventMembers => eventMembers.map(x => x.id)),
                    // If members ids are empty the server gives a 500 error
                    filter(eventMembers => eventMembers.length > 0)
                )
                .subscribe(
                    eventMembersIds => {
                        this.getAvailabilites(alert, eventMembersIds);
                    }
                )
        );

    }

    getAvailabilites(alert: AlertInterface, eventMembersIds: number[]) {
        this.subscriptions.push(this._usereventanswerService.getAvailabilities(alert.id, eventMembersIds)
            .subscribe(availabilites => this._availabilitesDispatcher.publishAvailabilites(availabilites))
        );

        this.subscriptions.push(this._deploymentorderService.getDeploymentOffersByEventAndUsers(alert.id, eventMembersIds)
            .subscribe(offers => this._deploymentOffersDispatcher.publishAvailabilites(offers))
        );

        this.subscriptions.push(this._deploymentService.getDeploymentOrdersByUsers(eventMembersIds)
            .subscribe(offers => this._deploymentOrdersDispatcher.publishAvailabilites(offers))
        );
    }

    public destroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

}


