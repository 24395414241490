export class RosterInterface {
    static getNoRosterInstance() {
        if (RosterInterface.noRosterInstance === null) {
            RosterInterface.noRosterInstance = new RosterInterface();
            RosterInterface.noRosterInstance.id = RosterInterface.NO_ROSTER;
        }
        return RosterInterface.noRosterInstance;
    }
}
RosterInterface.CACHE = {
    JOINABLE_ROSTERS: 'current_user_joinable_rosters',
    ROSTER_INFORMATION: 'roster_information_',
    ORGANISATION_ROSTERS: 'organisation_rosters_',
    ROSTER_MEMBERS: 'roster_members_',
};
RosterInterface.NO_ROSTER = -1;
RosterInterface.noRosterInstance = null;
