import { forkJoin ,  Observable ,  of } from 'rxjs';
import { CachedOrganisationService } from '../api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../api/organisation/organisation.dispatcher';
import { ROUTING, ORGANISATION_MANAGER_RIGHTS_LEVEL } from './../../app/app.constants';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, ActivatedRoute } from '@angular/router';
import { RightService } from '../utils/right.service';
import { CacheService } from '../storage/cache.service';
import { NavigationService } from '../utils/navigation.service';
import { take, map } from 'rxjs/operators';
import { OrganisationInterface } from '../../app/model/organisation.interface';
import { UserService } from '../api/user.service';
import { UserInterface } from '../../app/model/user.interface';

/**
 * This file is the beginning of a Strategy Pattern
 * to allow right access permissions on manager pages
 * TODO: complete Strartegy pattern for manager pages
 */

@Injectable()
export class ManagerGuardService implements CanActivate {

    readonly rightMap = {
        'settings': ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin
    };

    private user;

    constructor(
        private _rightService: RightService,
        private _cacheService: CacheService,
        private _navigationService: NavigationService,
        private _cachedOrganisationService: CachedOrganisationService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _userService: UserService

    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let organisationId: number = null, userId: number = null;

        // REFACTOR: understand what kind of interface this is
        this.user = this._cacheService.get(CacheService.USER);
        const currentOrganisation = this._cacheService.get(OrganisationInterface.CACHE.CURRENT_ORGANISATION);
        // check if the current user is manager if the current organisation
        if (currentOrganisation && this.user) {
            // userId = Number(next.paramMap.get(ROUTING.parameter.userId));
            const canNavigate = this.user.organisation_members
                .filter(x => x.organisation.id === currentOrganisation.id).length > 0;
            return canNavigate;
        }
        return false;

    }
}
