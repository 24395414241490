export class Selection {
    constructor(moment_from, moment_to, status) {
        if (moment_from) {
            this.startDate = moment_from;
        }
        if (moment_to) {
            this.endDate = moment_to;
        }
        if (status !== undefined) {
            this.status = status;
        }
    }
    setStatus(status) {
        if (status === "available") {
            this.status = Selection.AVAILABLE_STATUS;
        }
        else if (status === "notAvailable") {
            this.status = Selection.NOT_AVAILABLE_STATUS;
        }
        else if (status === "notSure") {
            this.status = Selection.NOT_SURE_STATUS;
        }
        else {
            this.status = Selection.NOT_SETTED_STATUS;
        }
    }
}
Selection.NOT_AVAILABLE_STATUS = 0;
Selection.AVAILABLE_STATUS = 1;
Selection.NOT_SURE_STATUS = 2;
Selection.SELECTED_FROM = 3;
Selection.SELECTED_BETWEEN = 4;
Selection.SELECTED_TO = 5;
Selection.DISABLED = 6;
Selection.NOT_SETTED_STATUS = 7;
