<div id="notification-container" (click)="$event.stopPropagation()">

  <div class="notification-header">
    <div class="notification-header-title">
      <h2>Notifications</h2>
      <button type="button" class="fa fa-times" (click)="close()"></button>
    </div>
    <div class="notification-header-filter">
      <button class="btn emalsys_button tabs start" (click)="invitationOrganisationFilter=!invitationOrganisationFilter" [ngClass]="{'active' : invitationOrganisationFilter,'disabled' : !invitationOrganisationCount }" [disabled]="!invitationOrganisationCount">Org<div class="notification-count">{{invitationOrganisationCount}} </div></button>
      <button class="btn emalsys_button tabs" (click)="invitationRosterFilter=!invitationRosterFilter" [ngClass]="{'active' : invitationRosterFilter,'disabled' : !invitationRosterCount }" [disabled]="!invitationRosterCount">Roster<div class="notification-count">{{invitationRosterCount}}</div></button>
      <button class="btn emalsys_button tabs" (click)="noAnsweredAlertFilter=!noAnsweredAlertFilter" [ngClass]="{'active' : noAnsweredAlertFilter,'disabled' : !noAnsweredAlertCount }" [disabled]="!noAnsweredAlertCount">Alerts <div class="notification-count">{{noAnsweredAlertCount}}</div></button>
      <button class="btn emalsys_button tabs" (click)="deploymentsFilter=!deploymentsFilter" [ngClass]="{'active' : deploymentsFilter,'disabled' : !deploymentsCount }" [disabled]="!deploymentsCount">Deploy<div class="notification-count"> {{deploymentsCount}}</div></button>
    </div>
  </div>

  <div class="notification-body">
    <div class="notification not" *ngIf="!notificationCount || !(invitationOrganisationFilter||invitationRosterFilter||noAnsweredAlertFilter||deploymentsFilter)">
      <h3>No notifications to display</h3>
    </div>
    <div *ngIf="notificationCount">
      <div *ngIf="invitationOrganisationFilter">
        <div *ngFor="let notification of notificationOrganisation">
          <div class="notification" *ngIf="notification.type==1">
            <div class="content">
              <img class="image-logo" src="assets/images/default_organisation.png" />
              <div class="primary-infos">
                <p>You have been invited to join the organisation {{notification.notification.organisation.name}} as a member</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acceptOrganisationMemberInvitation(notification.notification.organisation.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="declineOrganisationMemberInvitation(notification.notification.organisation.id)">Decline</button>
            </div>
          </div>
          <div class="notification" *ngIf="notification.type==2">
            <div class="content">
              <img class="image-logo" src="assets/images/default_organisation.png" />
              <div class="primary-infos">
                <p>You have been invited to join the organisation {{notification.notification.organisation.name}} as a manager</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acceptOrganisationManagerInvitation(notification.notification.organisation.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="declineOrganisationManagerInvitation(notification.notification.organisation.id)">Decline</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="invitationRosterFilter">
        <div *ngFor="let notification of notificationRoster">
          <div class="notification" *ngIf="notification.type==1">
            <div class="content">
              <img class="image-logo" src="assets/images/default_roster.png" />
              <div class="primary-infos">
                <p>You have been invited to join a roster {{notification.notification.roster.name}} as a member</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acceptRosterMemberInvitation(notification.notification.roster.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="declineRosterMemberInvitation(notification.notification.roster.id)">Decline</button>
              <button class="btn emalsys_button blue margin-r margin-b" (click)="openInviteModal(notification.notification)">More</button>
            </div>
          </div>
          <div class="notification" *ngIf="notification.type==2">
            <div class="content">
              <img class="image-logo" src="assets/images/default_roster.png" />
              <div class="primary-infos">
                <p>You have been invited to join a roster {{notification.notification.roster.name}} as a manager</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acceptRosterManagerInvitation(notification.notification.roster.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="declineRosterManagerInvitation(notification.notification.roster.id)">Decline</button>
              <button class="btn emalsys_button blue margin-r margin-b" (click)="openInviteModal(notification.notification)">More</button>
            </div>
          </div>
          <div class="notification" *ngIf="notification.type==3">
            <div class="content">
              <img class="image-logo" src="assets/images/default_roster.png" />
              <div class="primary-infos">
                <p>{{notification.notification.user.firstname}} {{notification.notification.user.lastname}} wants to join the roster {{notification.notification.roster.name}}.</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acceptRosterMemberRequest(notification.notification.roster.id,notification.notification.user.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="declineRosterMemberRequest(notification.notification.roster.id,notification.notification.user.id)">Decline</button>
              <button class="btn emalsys_button blue margin-r margin-b" (click)="openRequestModal(notification.notification)">More</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="noAnsweredAlertFilter">
        <div class="notification" *ngFor="let notificationAlert  of noAnsweredAlert">
          <div class="content">
            <div class="badge">
              <i class="fa fa-calendar-o" aria-hidden="true"></i>
            </div>
            <div class="primary-infos">
              <p>{{notificationAlert.organisation.name}} send an alert. Are you available from {{notificationAlert.start_date | date : "mediumDate"}} to {{notificationAlert.end_date | date : "mediumDate"}} ?</p>
            </div>
            <div class="secondary-infos">
              <h4>{{notificationAlert.creation_date|date : "short"}}</h4>
            </div>
          </div>
          <div class="notification-controle">
            <button class="btn emalsys_button green margin-r margin-b alert-notification" (click)="setAvailability(notificationAlert.id, notificationAlert.start_date, notificationAlert.end_date, 1)">Available</button>
            <button class="btn emalsys_button red margin-r margin-b alert-notification" (click)="setAvailability(notificationAlert.id, notificationAlert.start_date, notificationAlert.end_date, 0)">Unavailable</button>
            <button class="btn emalsys_button blue margin-r margin-b alert-notification" (click)="redirectAlert(notificationAlert.id)">More</button>
          </div>
        </div>
      </div>

      <div *ngIf="deploymentsFilter">
        <div class="notification" *ngFor="let notification  of deployments">
          <div class="notification" *ngIf="notification.type==1">
            <div class="content">
              <div class="badge">
                <i class="fa fa-plane" aria-hidden="true"></i>
              </div>
              <div class="primary-infos">
                <p> {{notification.notification.sender.firstname}} {{notification.notification.sender.lastname}} wants you as {{notification.notification.position}} from {{notification.notification.start_date | date : "mediumDate"}} to {{notification.notification.end_date | date : "mediumDate"}}</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.notification.creation_date|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="validateDeploymentOffer(notification.notification.id)">Accept</button>
              <button class="btn emalsys_button red margin-r margin-b" (click)="refuseDeploymentOffer(notification.notification.id)">Decline</button>
              <button class="btn emalsys_button blue margin-r margin-b" (click)="redirectAlert(notification.notification.event.id)">More</button>
            </div>
          </div>
          <div class="notification" *ngIf="notification.type==2">
            <div class="content">
              <div class="badge">
                <i class="fa fa-plane" aria-hidden="true"></i>
              </div>
              <div class="primary-infos">
                <p> {{notification.deploymentOrder.sender.firstname}} {{notification.deploymentOrder.sender.lastname}} deploys you as {{notification.deploymentOrder.position}} from {{notification.deploymentDate | date : "mediumDate"}} to {{notification.endDate | date : "mediumDate"}}</p>
              </div>
              <div class="secondary-infos">
                <h4>{{notification.creationDate|date : "short"}}</h4>
              </div>
            </div>
            <div class="notification-controle">
              <button class="btn emalsys_button green margin-r margin-b" (click)="acknowledgeDeploymentOrder(notification.deploymentOrder.id)">Ok</button>
              <button class="btn emalsys_button blue margin-r margin-b" (click)="acknowledgeDeploymentOrder(notification.deploymentOrder.id); redirectAlert(notification.deploymentOrder.event.id)">More</button>
            </div>
          </div>
        </div>
      </div>

  </div>

</div>
