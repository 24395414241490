import { AuthGuardService } from './../../../../services/security/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from './profile.component';
import { ProfileAllDataComponent } from './profile-all-data/profile-all-data.component';

// Do not change the order of the routes, it matters
export const routes: Routes = [
  {
    path: 'alldata', component: ProfileAllDataComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    pathMatch: 'full',
    component: ProfileComponent,
    canActivate: [AuthGuardService]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProfileRouting { }
