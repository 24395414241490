<!--Disaster component: Show the details about a disaster-->
<div id="disaster-component" *ngIf="disaster">

  <!--On the left-->
  <div class="left-disaster margin-b">

    <div class="disaster-global shadow margin-b">
      <img class="disaster-icon margin-r" src="assets/disaster-icons/{{disaster.fields.primary_type.code}}.png"
           [class.past]   ="disaster.fields.status === 'past'"
           [class.alert]  ="disaster.fields.status === 'alert'"
           [class.current]="disaster.fields.status === 'current'"/>
      <div>
        <h3 class="disaster-infos snipped">{{disaster.fields.date.created | niceDateDetailed}} </h3>
        <h3 class="disaster-infos snipped">{{disaster.fields.primary_country.name}}</h3>
      </div>
    </div>

    <div class="link-relief-div shadow text-center">
      <a class="link-relief-text" href="{{disaster.fields.url_alias}}" target="_blank" i18n="@@seeOnReliefWebLabel">See on ReliefWeb</a>
    </div>
  </div>

  <!--Center: details of the disaster-->
  <div class="disaster-details shadow text-justify margin-b">
    <h1 class="text-center margin-b">{{disaster.fields.name}} - {{disaster.fields.date.created | niceDate}}</h1>
    <p class="text-center margin-b primary-type-title">{{disaster.fields.primary_type.name}}</p>
    <div class="margin-t" [innerHTML]="disaster.fields['description-html']"></div>
  </div>

  <!--On the right-->
  <div class="country-global shadow">
    <!--More about the country-->
    <h2 class="text-center margin-b">More about {{disaster.fields.primary_country.name}}</h2>
    <!--Picture of the image-->
    <div class="text-center margin-b">
      <img class="map-picture" src="{{URL_API_RELIEF_WEB_IMG}}/{{disaster.fields.primary_country.iso3}}.png"/>
    </div>
    <!--List of the other disasters in the country-->
    <a class="desastres-desastre" *ngFor='let item of items' routerLink="disaster/{{item.id}}">
      <p class="text-justify margin-b"> - {{item.fields.title}}</p>
    </a>
  </div>

</div>
