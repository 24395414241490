export class ProfileInterface {

    public static readonly CACHE = {
        USER_PROFILE: 'currentuser_profile',
        USER_PHONES: 'currentuser_phones',
        ALL_SKILLS: 'all_skills',
        ALL_LANGUAGES: 'all_languages',
        USER_SKILLS: 'currentuser_skills',
        USER_LANGUAGES: 'currentuser_languages',
        USER_DOCUMENTS: 'currentuser_documents', // Keep? Remove?
        USER_EMERGENCY_CONTACTS: 'currentuser_emergency_contacts',
    };
    /**
    * Profile id
    * @type {number}
    **/
    id: number;
    /**
    * Profile public
    * @type {boolean}
    **/
    public: boolean;
    /**
    * Profile gender
    * @type {number}
    **/
    gender: number;
    /**
    * Profile birthday
    * @type {Date}
    **/
    birthday: Date;
    /**
    * Profile contactEmail
    * @type {string}
    **/
    contactEmail: string;
    /**
    * Profile website
    * @type {string}
    **/
    website: string;
    /**
    * Profile facebook
    * @type {string}
    **/
    facebook: string;
    /**
    * Profile twitter
    * @type {string}
    **/
    twitter: string;
    /**
    * Profile linkedIn
    * @type {string}
    **/
    linkedIn: string;
    /**
    * Profile skype
    * @type {string}
    **/
    skype: string;
    /**
    * Profile avatar
    * @type {string}
    **/
    avatar_name: string;
    /**
    * Profile about
    * @type {string}
    **/
    aboutme: string;
}
// add user fields
