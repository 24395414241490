<div id="alerts-map-component" [hidden]="alertList">
  <div id="alerts-map-component-map" (click)='enableZoom()'>
  </div>
  <div class="list-container" *ngIf="selectedLayer">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let alert of events" (click)='setSelectedAlert(alert); selectedLayer="";'>
        <p>{{ alert.name }} - {{ alert.start_date| date: "MMMM y" }} - {{ alert.end_date| date: "MMMM y" }} - {{ alert.contact_name}}</p>
      </li>
    </ul>
  </div>
  <div class="alert-list-selection">
    <h4>Select the alert you want see</h4>
    <i title="Display the alerts list"
       class="fa fa-list-alt" aria-hidden="true" (click)="handleChangeDisplay()">
    </i>
  </div>
</div>

<div id="alerts-list-component" [hidden]="!alertList">
  <div class="alerts-map-component-list">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let alert of eventsSorted" (click)='setSelectedAlert(alert); selectedLayer="";'>
        <p>{{ alert.name }} - {{ alert.country_name ? alert.country_name : "Other" }} - {{ alert.start_date| date: "MMMM y" }} - {{ alert.end_date| date: "MMMM y" }} - {{ alert.contact_name}}</p>
      </li>
    </ul>
  </div>
  <div class="alert-list-selection">
    <h4>Select the alert you want see</h4>
    <div class="alert-list-buttons">
      <i *ngIf="!oldList" title="Display the old alerts"
         class="fa fa-history" aria-hidden="true" (click)="handleChangeList()">
      </i>
      <i *ngIf="oldList" title="Display the alerts list"
         class="fa fa-list-alt" aria-hidden="true" (click)="handleChangeList()">
      </i>
      <i title="Display the alerts map"
        class="fa fa-map-o" aria-hidden="true" (click)="handleChangeDisplay()">
      </i>
    </div>
  </div>
</div>
