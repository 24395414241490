import { RosterInterface } from "./roster.interface";

export class UserInterface {

    public static CACHE = {
        ORGANISATION_MEMBER: 'organisation_member_',
        ROSTER_MEMBER: 'organisation_member_',
    };

    /**
    * User id
    * @type {number}
    **/
    id: number;
    /**
    * User email
    * @type {string}
    **/
    email: string;
    /**
    * User salted password
    * @type {string}
    **/
    saltedPassword: string;
    /**
    * User acess rights
    * @type {any[]}
    **/
    voters: any[];
    /**
    * Number of unanswered events
    * @type {number}
    **/
    nonAnsweredEvents: number;
    /**
    * Number of rosters user is invited to
    * @type {number}
    **/
    invitedRosterMembers: number;
    /**
    * Number of deployments
    * @type {name}
    **/
    deployments: number;
    /**
    * End unavailability date
    * @type {Date}
    **/
    end_unavailability: Date;
    /**
    * User first name
    * @type {string}
    **/
    firstname: string;
    /**
    * User last name
    * @type {string}
    **/
    lastname: string;
    /**
    * User profile picture
    * @type {string}
    **/
    picture: string;
    /**
    * Organisations managed by user
    * @type {any[]}
    **/
    organisation_managers: any[];
    /**
    * Organisations belonged by user
    * @type {any[]}
    **/
    organisation_members: any[];
    /**
    * Rosters managed by user
    * @type {any[]}
    **/
    roster_managers: any[];

    /**
     * Roster to which the user belongs
     * @type any[]
     */
    roster_members: any[];
	/**
    * User profile
    * @type {any}
    **/
    profile: any;

    /**
     * User skills
     */
    user_tags: any;
}
