<div class="modal-header">
  <h4 class="modal-title">Details of roster join request</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
      </button>
</div>

<div class="modal-body">
  <div id="roster-compliances">
    <table class="compliances-table">
        <tr class="emalsys_section-title">
          <h4 class="compliances-type">Organisation Compliances</h4>
      </tr>
      <tr class="table-head">
        <th>
          <h3>Title</h3></th>
        <th>
          <h3>Level</h3></th>
        <th>
          <h3>Document</h3></th>
      </tr>
      <tr *ngFor='let compliance of orgaFilledCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <button type="text" (click)="downloadDoc(compliance.organisation_requirement_documents[0].document)">{{compliance.organisation_requirement_documents[0].document.title}}</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of orgaEmptyCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td> <i class="fa fa-times" aria-hidden="true"></i></td>
      </tr>
      <div class="emalsys_section-title">
        <h4 class="compliances-type">Roster Compliances</h4>
      </div>
      <tr class="table-head">
        <th>
          <h3>Title</h3></th>
        <th>
          <h3>Level</h3></th>
        <th>
          <h3>Document</h3></th>
      </tr>
      <tr *ngFor='let compliance of rosterFilledCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td>
          <button type="text" (click)="downloadDoc(compliance.roster_requirement_documents[0].document)">{{compliance.roster_requirement_documents[0].document.title}}</button>
        </td>
      </tr>
      <tr *ngFor='let compliance of rosterEmptyCompliances'>
        <td>
          <p>{{compliance.title}}</p>
        </td>
        <td>
          <p>{{setLevel(compliance)}}</p>
        </td>
        <td> <i class="fa fa-times" aria-hidden="true"></i></td>
      </tr>
    </table>
  </div>
</div>

<div class="modal-footer">

</div>
