import { BehaviorSubject, of } from 'rxjs';
import { Dispatcher } from '../../dispatcher.service';
export class MembersDispatcherService extends Dispatcher {
    constructor() {
        super(...arguments);
        this.displayedMembers$ = new BehaviorSubject(null);
        this.rosterMembers$ = new BehaviorSubject(null);
    }
    getMembers() {
        return this.pipeNotNull(this.displayedMembers$);
    }
    getFilteredMembers() {
        return this.pipeNotNull(this.displayedMembers$);
    }
    /** Used when the search query returns empty */
    publishPreviousMembers() {
        this.displayedMembers$.next(this.previousMembers);
    }
    publishFilteredMembers(members) {
        this.displayedMembers$.next(members);
    }
    publishMembers(members) {
        this.previousMembers = members;
        this.displayedMembers$.next(members);
    }
    publishAllOrganisationMembers(members) {
        this.allMembers = members;
        this.publishMembers(members);
    }
    publish(members) {
        this.displayedMembers$.next(members);
    }
    filterByRoster(rosterId) {
        let filteredMembers = this.allMembers
            .filter((member) => {
            return member.roster_members
                .find(roster_member => roster_member.roster.id === rosterId) !== undefined;
        });
        return filteredMembers;
    }
    filterByRosterAsObservable(rosterId) {
        return of(this.filterByRoster(rosterId));
    }
    filterByRosterAndDispatch(rosterId) {
        this.publishFilteredMembers(this.filterByRoster(rosterId));
    }
    getRosterMembers() {
        return this.pipeNotNull(this.rosterMembers$);
    }
    publishRosterMembers(members) {
        this.rosterMembers$.next(members);
    }
    clearMembers() {
        this.displayedMembers$.next(null);
    }
}
