import { environment } from "../../environments/environment";
export class EnvironmentChecker {
    /**
     * Execute the given function.
     * The default return is null since the return type cant be guessed
     * @param env
     * @param exec
     */
    static execute(env, exec) {
        if (EnvironmentChecker.conditions[env]) {
            return exec();
        }
        return null;
    }
    static ifAdpc(exec) {
        return EnvironmentChecker.execute('adpc', exec);
    }
    static ifEmalsys(exec) {
        return EnvironmentChecker.execute('emalsys', exec);
    }
}
EnvironmentChecker.conditions = {
    adpc: environment.disabledFeatures && environment.disabledFeatures.public,
    emalsys: true,
};
