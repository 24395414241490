import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng2-select2';

import { DeploymentService } from '../../../../../../services/api/deployment.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';

@Component({
  selector: 'app-current-order-details',
  templateUrl: './current-order-details.component.html',
  styleUrls: ['./current-order-details.component.scss']
})
export class CurrentOrderDetailsComponent implements OnInit {

  @Input() deploymentOrders;
  @Input() country;
  public selectedOrder: any;
  public selectedOldOrder: any;
  public currentOrder: any;
  public upcomingOrders = new Array();
  public selectedUpcomingOrder: any;

  constructor(    public activeModal: NgbActiveModal,
    public _deploymentService: DeploymentService,
    public _loggerService: LoggerService) { }

  ngOnInit() {
    const today = new Date();
    this.currentOrder = this.deploymentOrders[0];

    this.deploymentOrders.forEach(order => {
      if (new Date(order.end_date) > today && new Date(order.deploymentdate) > today) {
        this.upcomingOrders.push(order);
      }
    });

    this.deploymentOrders.forEach(order => {
      if (new Date(order.end_date) > today && new Date(order.deploymentdate) < today) {
        if (new Date(order.deploymentdate) >= new Date(this.currentOrder.deploymentdate)) {
          this.currentOrder = order;
        }
      }
    });

    this.deploymentOrders.reverse();
    this.deploymentOrders.shift();

    this.selectedOrder = this.currentOrder;
    this.selectedOldOrder = this.deploymentOrders[0];
    this.selectedUpcomingOrder = this.upcomingOrders[0];
  }

}
