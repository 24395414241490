import { Component, Input } from '@angular/core';

@Component({
  selector   : 'alerts-information',
  templateUrl: 'alerts-information.component.html',
  styleUrls  : ['alerts-information.component.scss']
})
export class AlertsInformationComponent {
  @Input() alertInfo: any;


}
