import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alerts-preloader-search',
  templateUrl: './alerts-preloader-search.component.html',
  styleUrls: ['./alerts-preloader-search.component.scss']
})
export class AlertsPreloaderSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
