import { AppInjector } from './injector';
import { Injectable, Injector } from '@angular/core';
import * as io from 'socket.io-client/dist/socket.io.dev.js';
import { OrganisationInterface } from '../../app/model/organisation.interface';
import { Observable } from 'rxjs';

@Injectable()
export class CacheNotificationsService {

    // private static readonly CALL = "http://192.168.1.51:3000";
    // private static readonly socket = io(CacheNotificationsService.CALL);
    static injector: Injector;
    static providers = {};

    private calls = {
        [OrganisationInterface.CACHE.ORGANISATION_ROSTERS]: {
            service: 'RosterService',
            method: 'refreshRosters'
        }
        // this._rosterService.refreshRosters.bind(this)
    };

    constructor() {
    }

    public static notifyCacheChange(cacheKey: string, ...args: any[]) {
        // CacheNotificationsService.socket.emit("cache", cacheKey, ...args);
    }

    public subscribeToNotifications() {

        // This code has been used to resolve a certain problem
        // in a certain situation. Please don't do it at home.
        // CacheNotificationsService.socket.on('cache', (cacheKey: string, ...args: any[]) => {
        //     const service = this.calls[cacheKey]['service'];
        //     const provider = CacheNotificationsService.providers[service];
        //     const method = this.calls[cacheKey]['method'];
        //     const serviceInstantiated = CacheNotificationsService.injector.get(provider);
        //     let result = serviceInstantiated[method](...args);
        //     if (result instanceof Observable) {
        //         result.subscribe();
        //     }
        // });
    }
}
