<!-- HEADER -->
<div class="modal-header">
  <h4 class="modal-title">Roster tags</h4>
  <button class="close" aria-label="Close" (click)="_activeModal.dismiss()">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
</div>


<!-- BODY -->
<div class="modal-body">
  <!-- Content wrapper (padding here) -->
  <div class="modal-general">
    <div class="add-new-container" *ngIf="!membersMode">
      <!-- "Add new" button -->
      <!-- <button *ngIf="!addingMode" class="add-new" (click)="addMode();">
        <div class="add-new-content">
          <div class="add-new-label">Add new</div>
          <div class="add-new-cross">+</div>
        </div>
      </button> -->
      <!-- *ngIf="addingMode"
        (blur)="addingMode=false;"  -->
      <div class="">
        <input #newTagInput placeholder="Enter new tag's name" class="form-control" [(ngModel)]="newTagName" type="text"
          (keyup.enter)="addNewTag();">
        <!-- <button (click)="addNewTag();" class="add-tag-cross">
          +
        </button> -->
      </div>

    </div>

    <!-- Tag list -->
    <div class="tag-list">
      <button class="tag-button" [ngClass]="{'selected-tag': tag.selected}" *ngFor="let tag of tags"
        (click)="tagSelected(tag);">
        <div class="container-p">
          <p>
            {{tag.name}}
          </p>
        </div>
        <div class="container-cross">
          <i class="fa fa-times" (click)="deleteSingleTag(tag.id)"></i>
        </div>
      </button>
    </div>
  </div>

  <div class="modal-footer">
    <div class="footer-buttons">
      <button class="emalsys_button red" (click)="_activeModal.close(tags)">
        Close
      </button>
      <!-- REMOVE TAGS -->
      <button (click)="removeSelectedTags();" class="emalsys_button green" *ngIf="!membersMode">
        Delete selected
      </button>

      <button (click)="addNewTag()" class="emalsys_button green" *ngIf="!membersMode" [disabled]="newTagName.length == 0">
        Add new
      </button>

      <!-- REMOVE ROSTER_MEMBER_TAGS -->
      <button (click)="disapplyTags();" class="emalsys_button red" *ngIf="membersMode">
        Remove
      </button>

      <!-- Apply tag to members -->
      <button (click)="applyTags();" class="emalsys_button green" *ngIf="membersMode">
        Apply
      </button>

    </div>

  </div>
  <div class="spinner" *ngIf="loading">
    <spinner-component></spinner-component>
  </div>
</div>

<div id="tags-added" class="toast green-toast">Tag added correctly</div>
<div id="tags-removed" class="toast green-toast">Tag deleted correctly</div>
<div id="no-tag-selected" class="toast red-toast">No tag selected</div>
<div id="member-tags-applied" class="toast green-toast">The tag(s) have been added to the member(s)</div>
<div id="member-tags-removed" class="toast green-toast">The tag(s) have been removed from the member(s)</div>
<div id="tag-removal-failed" class="toast red-toast">Connection error, please try again</div>
