<!--Profile-general component-->
<div id="profile-general-component" class="card shadow">
  <!--Tabs-->
  <div class="card-nav">
    <div class="card-nav-item" [class.active]="tab=='info'" (click)="setTab('info')">
      <h2>GENERAL INFORMATION</h2>
    </div>
    <div class="card-nav-item" [class.active]="tab=='deploy'" (click)="setTab('deploy')">
      <h2>DEPLOYMENTS</h2>
    </div>
  </div>

  <!--General information tab-->
  <div class="" *ngIf="profile && tab=='info'">
    <!--Edit button-->
    <div class="edit-button" *ngIf="!editEnabled && !profile.external" (click)="enableEdit()">
      <i class="fa fa-pencil custom-edit-icon"></i>
    </div>
    <!-- <div class="edit-button" *ngIf="editEnabled" (click)="disableEdit()">
      <i class="fa fa-times custom-edit-icon"></i>
    </div> -->

    <!--Content display-->
    <div class="info" *ngIf="!editEnabled">
      <div class="profile-user-component-infos">
        <!--Label-->
        <!-- 
          PROPOSAL:
            MOVE THE VALUES AT THE RIGHT
            (wrap the labels in another span and use flex on the p.labels-label)
         -->
        <div class="infos-labels">
          <p class="labels-label"><span>First Name:</span> <span class="infos-value"> {{profile.firstname}}</span></p>
          <p class="labels-label"><span>Last Name:</span> <span class="infos-value"> {{profile.lastname}}</span></p>
          <p class="labels-label"><span>Gender:</span> <span class="infos-value"> {{profile.profile.gender == 0 ? "Male" : profile.profile.gender == 1 ? "Female" : "Gender not specified"}}</span></p>
          <p class="labels-label"><span>Email:</span> <span class="infos-value"> {{profile.profile.contact_email ? profile.profile.contact_email : "Email not specified"}}</span></p>
          <p class="labels-label"><span>Birthdate:</span> <span class="infos-value"> {{profile.profile.birthday | dateFormat:"DD":"MMMM":"YYYY"}}</span></p>
          <p class="labels-label"><span>Address:</span> <span class="infos-value">  {{profile.profile.profile_address?.address}},
            {{profile.profile.profile_address?.zip_code}},
            {{profile.profile.profile_address?.city}},
            {{profile.profile.profile_address?.country}}</span></p>
          <p class="labels-label" *ngIf="facebook"><span>Facebook: </span><span class="infos-value">{{facebook}}</span></p>
          <p class="labels-label" *ngIf="twitter"><span>Twitter: </span><span class="infos-value">{{twitter}}</span></p>
          <p class="labels-label" *ngIf="linkedin"><span>LinkedIn: </span><span class="infos-value">{{linkedin}}</span></p>
          <p class="labels-label" *ngIf="skype"><span>Skype: </span><span class="infos-value">{{skype}}</span></p>
          <p class="labels-label" *ngIf="website"><span>Website: </span><span class="infos-value">{{website}}</span></p>
        </div>
        <!--Value-->
        <!-- <div class="infos-values" *ngIf='profile'>
          <p class="values-value" *ngIf="profile.firstname">{{profile.firstname}}</p>
          <p class="values-value" *ngIf="profile.lastname">{{profile.lastname}}</p>
          <p class="values-value">
            {{profile.profile.gender == 0 ? "Male" : profile.profile.gender == 1 ? "Female" : "Gender not specified"}}
          </p>
          <p class="values-value">
            {{profile.profile.contact_email ? profile.profile.contact_email : "Email not specified"}}</p>
          <p class="values-value" *ngIf="profile.profile.birthday">
            {{profile.profile.birthday | dateFormat:"DD":"MMMM":"YYYY"}}</p>
          <p class="values-value" *ngIf="!profile.profile.birthday">Birthday not specified</p>
          <p class="values-value" *ngIf="profile.profile.profile_address">
            {{profile.profile.profile_address?.address}},
            {{profile.profile.profile_address?.zip_code}},
            {{profile.profile.profile_address?.city}},
            {{profile.profile.profile_address?.country}}
          </p>
          <p class="values-value" *ngIf="!profile.profile.profile_address">
            Address not specified
          </p>
          <p class="values-value" *ngIf="facebook">{{facebook}}</p>
          <p class="values-value" *ngIf="twitter">{{twitter}}</p>
          <p class="values-value" *ngIf="linkedin">{{linkedin}}</p>
          <p class="values-value" *ngIf="skype">{{skype}}</p>
          <p class="values-value" *ngIf="website">{{website}}</p>
        </div> -->
      </div>
    </div>
    <!-- <div class="infos-map" id="profile-general-component-map"></div> -->

    <!-- Profile editing -->
    <div class="info" *ngIf="editEnabled">
      <!--Content display-->
      <div class="">
        <!--Firstname-->
        <div class="ui-g-12 ui-md-12">
          <h3>First name</h3>
          <input class="form-control ui-md-5" [(ngModel)]="firstname" maxlength="50" />
        </div>
        <!--Lastname-->
        <div class="ui-g-12 ui-md-12">
          <h3>Last name</h3>
          <input class="form-control ui-md-5" [(ngModel)]="lastname" maxlength="50" />
        </div>
        <!--Gender-->
        <div class="ui-g-12 ui-md-4">
          <h3>Gender</h3>
          <select id="typeField" class="form-control" [(ngModel)]="gender" name="type" type="select">
            <option value="0" i18n="@@genderMale"> Male </option>
            <option value="1" i18n="@@genderFemale"> Female </option>
          </select>
        </div>
        <!--Email-->
        <div class="ui-g-12 ui-md-9">
          <h3>E-mail</h3>
          <input class="form-control" [(ngModel)]="email" maxlength="50" />
        </div>

        <!--Date of birth-->
        <!--Default: dans le calendrier, j-1 sur la date choisi-->
        <!-- <div class="ui-g ui-fluid"> -->
        <div class="ui-g-12 ui-md-4">
          <h3>Date of birth</h3>
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]=yearRange [(ngModel)]="birthdate"
            [showIcon]="true"></p-calendar>
        </div>
        <!-- </div> -->

        <!--Address-->
        <div class="ui-g-12 ui-md-12">
          <h3>Address</h3>
          <input class="form-control ui-md-5" [(ngModel)]="address" maxlength="50" />
          <h3>ZIP</h3>
          <input class="form-control" [(ngModel)]="zip" maxlength="20" />
          <h3>City</h3>
          <input class="form-control" [(ngModel)]="city" maxlength="20" />
          <h3>Country</h3>
          <select class="form-control" [(ngModel)]="country" name="type" type="select">
            <option *ngFor="let c of countries" [ngValue]="c.text" [selected]="country == c.country_code"> {{c.text}}
            </option>
          </select>
        </div>

        <!-- Web links -->
        <div class="ui-g-12 ui-md-12">
          <h3>Facebook</h3>
          <!-- <span class="ui-inputgroup-addon">www.facebook.com/</span> -->
          <input class="form-control" type="text" placeholder="Your Facebook profile" [(ngModel)]="facebook">

          <h3>Twitter</h3>
          <!-- <span class="ui-inputgroup-addon">www.twitter.com/</span> -->
          <input class="form-control" type="text" placeholder="Your Twitter profile" [(ngModel)]="twitter">

          <h3>LinkedIn</h3>
          <!-- <span class="ui-inputgroup-addon">www.linkedIn.com/</span> -->
          <input class="form-control" type="text" placeholder="Your LinkedIn profile" [(ngModel)]="linkedin">

          <h3>Skype</h3>
          <!-- <span class="ui-inputgroup-addon">www.linkedIn.com/</span> -->
          <input class="form-control" type="text" placeholder="Your Skype ID" [(ngModel)]="skype">

          <h3>Website</h3>
          <!-- <span class="ui-inputgroup-addon">www</span> -->
          <input class="form-control" type="text" placeholder="Your Website" [(ngModel)]="website">
        </div>

        <div class="ui-g-12 ui-md-12 save-button">
          <!--Cancel button-->
          <button class="btn emalsys_button red" type="button" name="button" (click)="disableEdit()">Cancel</button>
          <!--Save button-->
          <button class="btn emalsys_button green" type="button" name="button" (click)="saveChanges()">Save</button>
        </div>
      </div>
    </div>
  </div>

  <!--Deployment tab-->
  <div class="deployments" *ngIf="tab=='deploy'">


    <!--List of deployments-->
    <div class="deployments-title">
      <h3>CURRENT DEPLOYMENTS</h3>
    </div>
    <div class="no-deployment" style="text-align: center;"
      *ngIf="!currentDeployments || currentDeployments.length == 0">
      <h3>You do not have any current deployments yet</h3>
    </div>
    <div class="deployment" *ngFor="let d of currentDeployments" routerLink="/responder/alert/{{d.event.id}}">
      <h3>{{d.event.name}}</h3>
      <h4 class="infos">{{d.deployment_order.position}} - {{d.event.country_name}}</h4>
      <h4 class="infos">Start date: {{d.deploymentdate | date:"MM/dd/yyyy"}}</h4>
      <h4 class="infos">End date: {{d.end_date | date:"MM/dd/yyyy"}}</h4>
    </div>


    <div class="deployments-title">
      <h3>UPCOMING DEPLOYMENTS</h3>
    </div>
    <div class="no-deployment" style="text-align: center;"
      *ngIf="!upcomingDeployments || upcomingDeployments.length == 0">
      <h3>You do not have any deployments yet</h3>
    </div>
    <div class="deployment" *ngFor="let d of upcomingDeployments" routerLink="/responder/alert/{{d.event.id}}">
      <h3>{{d.event.name}}</h3>
      <h4>{{d.deployment_order.position}} - {{d.event.country_name}}</h4>
      <h4>Start date: {{d.deploymentdate | date:"MM/dd/yyyy"}}</h4>
      <h4>End date: {{d.end_date | date:"MM/dd/yyyy"}}</h4>
      <!-- <div class="separator"></div> -->
    </div>




    <div class="deployments-title">
      <h3>OLD DEPLOYMENTS</h3>
    </div>
    <div class="no-deployment" style="text-align: center;" *ngIf="!allOldDeployments || allOldDeployments.length == 0">
      <h3>You do not have any old deployments yet</h3>
    </div>
    <div *ngFor="let d of allOldDeployments">
      <!-- <div class="deployment" routerLink="/responder/alert/{{d.deployment.event.id}}"> -->
        <!-- <div class="deployment" *ngIf="d.type==1" routerLink="/responder/alert/{{d.deployment.event.id}}"> -->
        <!-- <div class="deployment-container"> -->
        <!-- <div class="deployment-block"> -->
        <!-- <div class="deployment-infos"> -->
        <!-- <h3>{{d.deployment.event.name}}</h3>
        <h4 class="infos">{{d.deployment.deployment_order.position}} - {{d.deployment.event.country_name}}</h4>
        <h4 class="infos">Start date: {{d.deployment.deploymentdate | date:"MM/dd/yyyy"}}</h4>
        <h4 class="infos">End date: {{d.deployment.end_date | date:"MM/dd/yyyy"}}</h4> -->

        <!-- </div> -->
        <!-- <div class="tag-and-buttons">
          <div class="deployment-tag">
            <h3 class="tag blue-light">Deployed via Emalsys</h3>
          </div>
          <div class="deployment-tag">
            <div class="btn tag red">Manual entry</div>
          </div>
          <div class="deployment-button">
            <button class="btn emalsys_button green margin-r"
              (click)="showEditDeployModal(addDeployModal,d.deployment)">Edit</button>
            <button class="btn emalsys_button red"
              (click)="deleteExternalDeployment(d.deployment.id)">Delete</button>
          </div>
        </div> -->
        <!-- </div> -->
        <!-- </div> -->
      <!-- </div> -->
      <!--List of External deployments-->
      <div class="deployment external">
        <!-- <div *ngIf="d.type==2" class="deployment external"> -->
        <div class="deployment-container">
          <div class="deployment-block">
            <div class="deployment-infos">
              <!-- Some properties are different in the two types of deployment -->
              <h3 *ngIf="d.type==2">{{d.deployment.title}}</h3>
              <h3 *ngIf="d.type==1">{{d.deployment.event.name}}</h3>
              <h4 class="infos" *ngIf="d.type==2">{{d.deployment.title}} - {{d.deployment.country_name}}</h4>
              <h4 class="infos" *ngIf="d.type==1">{{d.deployment.deployment_order.position}} - {{d.deployment.event.country_name}}</h4>
              <h4 class="infos" *ngIf="d.type==2">Start date: {{d.deployment?.start_date | date:"MM/dd/yyyy"}}</h4>
              <h4 class="infos" *ngIf="d.type==1">Start date: {{d.deployment?.deploymentdate | date:"MM/dd/yyyy"}}</h4>
              <h4 class="infos">End date: {{d.deployment?.end_date | date:"MM/dd/yyyy"}}</h4>
              <h3 *ngIf="d.type==1" class="tag blue-light">Deployed via Emalsys</h3>
            </div>
            <div class="tag-and-buttons">
              <div class="deployment-tag">
                  <!-- <h3 *ngIf="d.type==1" class="tag blue-light">Deployed via Emalsys</h3> -->
                <div class="btn tag red">Manual entry</div>
              </div>
              <div class="deployment-button">
                <button class="btn emalsys_button green margin-r"
                  (click)="showEditDeployModal(addDeployModal,d.deployment)">Edit</button>
                <button class="btn emalsys_button red"
                  (click)="deleteExternalDeployment(d.deployment.id)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn emalsys_button green add-deploy" *ngIf="!profile.external"
      (click)="showAddDeployModal(addDeployModal)">Add deployment manually</button>
  </div>

  <!-- My Organization Tab -->
  <!-- <div class="organization" *ngIf="profile && tab=='organization'">
    <label for="">
      <input type="checkbox" name="option1" >
      <h3>Option 1</h3>
    </label>
    <label for="">
      <input type="checkbox" name="option2" >
      <h3>Option 2</h3>
    </label>

  </div> -->

  <!-- Modal for Adding or Editing a Deployment -->
  <ng-template #addDeployModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 *ngIf="!deployId" class="modal-title">Add a Deployment</h4>
      <h4 *ngIf="deployId" class="modal-title">Edit a Deployment</h4>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><i class="fa fa-times-circle-o"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <label>Position</label>
      <input class="form-control" [(ngModel)]="position" maxlength="100" />
      <div class="form-group modal-field">
        <label>Country</label>
        <select2 class="select" cssImport="true" [data]="countries" [options]="{multiple: false}"
          [value]="deployCountry" (valueChanged)="changedSelectCountry($event)">
        </select2>
      </div>

      <div class="form-group modal-field">
        <label>Date range</label>
        <!-- <p-calendar selectionMode="range" [monthNavigator]="true" [yearNavigator]="true" [minDate]="minDate" [maxDate]="maxDate" yearRange="{{yearRange}}" [(ngModel)]="deployDate" [showIcon]="true"></p-calendar> -->
        <p-calendar selectionMode="range" [defaultDate]="maxDate" yearRange="{{yearRange}}" [monthNavigator]="true" [yearNavigator]="true"
          [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="deployDateRange" [showIcon]="true"
          (onClose)="isDateRangeCorrect();"></p-calendar>

        <!-- <p-calendar selectionMode="range" [defaultDate]="maxDate" [monthNavigator]="true" [yearNavigator]="true" [minDate]="minDate"
          [maxDate]="maxDate" [(ngModel)]="deployDateRange" [showIcon]="true"
          (onClose)="checkDateRange();"></p-calendar> -->
      </div>

      <!-- <h3>Message</h3>
            <input class="form-control" [(ngModel)]="message" maxlength="100"/>
            <h3>Contact name</h3>
            <input class="form-control" [(ngModel)]="contactName" maxlength="100"/>
            <h3>Contact E-mail</h3>
            <input class="form-control" [(ngModel)]="contactEmail" maxlength="100"/>
            <h3>Flight Number</h3>
            <input class="form-control" [(ngModel)]="flightNo" maxlength="100"/>
            <h3>Airport</h3>
            <input class="form-control" [(ngModel)]="airport" maxlength="100"/>
            <h3>Insurance Name</h3>
            <input class="form-control" [(ngModel)]="insuranceName" maxlength="100"/>
            <h3>Insurance Number</h3>
            <input class="form-control" [(ngModel)]="insuranceNo" maxlength="100"/>
            <h3>Deployment Document</h3>
            <input class="form-control" [(ngModel)]="document" maxlength="100"/> -->

    </div>
    <div class="loading" *ngIf="loading">
      <spinner-component>
      </spinner-component>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="loading" class="btn emalsys_button red" (click)="resetDeployVariables();c();"
        i18n="@@deployCancel">Cancel</button>
      <!-- QUESTION: better use the disabled attribute here or check after button pressing? -->
      <button *ngIf="!deployId"
        [disabled]="loading || !deployDateRange || deployDateRange.length===0 || deployDateRange[0] == null || deployDateRange[1] == null ||!position || !deployCountry"
        type="button" class="btn emalsys_button green" (click)="addExternalDeployment();c();">Add Deployment</button>
      <button *ngIf="deployId"
        [disabled]="loading || !deployDateRange || deployDateRange.length===0 || deployDateRange[0] == null || deployDateRange[1] == null ||!position || !deployCountry"
        type="button" class="btn emalsys_button green" (click)="editExternalDeployment();c();">Edit Deployment</button>
    </div>

  </ng-template>


  <!--Toast-->
  <div id="generalInfoUpdateToast">Your information has been updated</div>
  <div id="generalInfoUpdateErrorToast">The url inserted is not valid, please add full address. eg :
    http://facebook.com/example</div>
  <div id="externalAddedToast">Deployment added</div>
  <div id="externalUpdatedToast">Deployment updated</div>
  <div id="externalDeletedToast">Deployment deleted</div>
  <div id="externalErrorToast">Error occurred! Please try again</div>
  <div id="date-not-correct" class="toast red-toast">Please select a date range</div>
</div>