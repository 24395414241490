import { Injectable } from '@angular/core';
import { HttpClient       } from '@angular/common/http';

// Services
import { HttpService } from './http.service';

// Data
import { URL_API_EMALSYS } from '../../environments/environment';

@Injectable()
export class SettingsService {

  constructor(
    private http: HttpClient,
    private _httpService: HttpService
  ) {}

  // reset password
  changeUserPassword(password) {
    if (1) {
      throw new Error('Changing passsord this way is deprecated');
    }

    let body = password;
    let options = this._httpService.createRequestOptions();

    return this.http.post(URL_API_EMALSYS + "/me/password/change", body, options);
  }

  setNotificationAcceptance(notifAcceptance) {
    let body = notifAcceptance;
    let options = this._httpService.createRequestOptions();

    return this.http.put(URL_API_EMALSYS + "/settings/notification", body, options);
  }

  getNotificationAcceptance() {
    let options = this._httpService.createRequestOptions();

    return this.http.get<any>(URL_API_EMALSYS + "/settings/notification", options);
  }

  deleteMyAccount() {
    let options = this._httpService.createRequestOptions();

    return this.http.delete(URL_API_EMALSYS + "/me/delete", options);
  }
}
