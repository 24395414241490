import { Component, OnInit, Input, KeyValueDiffers, KeyValueDiffer, KeyValueChangeRecord } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { AbstractFilter } from '../../model/filter';
export interface SelectorFilterData {
  value: string
  style?: string
  class?: string
  item: number
}
@Component({
  selector: 'selector-filter',
  templateUrl: './selector-filter.component.html',
  styleUrls: ['./selector-filter.component.scss']
})
export class SelectorFilterComponent extends AbstractFilter {
  @Input() data;
  @Input() item;

  @Input() selectorFilter: SelectorFilterData;
  private _differ: KeyValueDiffer<any, any>;
  public selectedItem;

  constructor(
    private _differs: KeyValueDiffers,
    private filterService: FilterService
  ) {
    super();
  }

    ngOnInit() {
      this._differ = this._differs.find({}).create();
      this.filterService.subscribe(this);
      if(this.data.length){
        this.selectedItem = this.data[0].id;
        this.filter(this.selectorFilter.value);
      }
    }
    ngDoCheck() {
      if (this._differ) {
        const change = this._differ.diff(this.data);
        if (change) {
          change.forEachItem(
            (record: KeyValueChangeRecord<any, any>) => {

              if (record.key == "0") {
                this.selectedItem=record.currentValue.id
                this.filter(this.selectorFilter.value);
              }
            })
        }
      }
    }
  /**
   * Allow to know which block and which button in block is active
   * @param newFilter 
   */
  filter(newFilter: any) {
    this.selectorFilter.item=this.selectedItem
      super.filter(newFilter)
    
  }

  changed(data) {
    this.selectedItem = data.value;
    this.filter(this.selectorFilter.value);
  }
}
