import { Component, Input, OnInit } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';

import { DocumentService } from '../../../../../services/api/document.service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsComponent } from '../../members/modals/tags/tags.component';


@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {

  @Input() member = null;
  @Input() roster_members; // Association to see both rosters and tags
  // @Input() rosters;
  @Input() languages;
  @Input() skills;
  @Input() documents;
  @Input() organisationId;
  public rosterMemberTags: any[] = [];
  public rosters: any[] = [];

  public rostersMapped = [];
  constructor(
    public activeModal: NgbActiveModal,
    public _documentService: DocumentService
  ) { }

  ngOnInit() {}

  getAge(birthday: Date) {

    let ageDif = Date.now() - (new Date(birthday)).getTime();
    let ageDate = new Date(ageDif);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  downloadDoc(document) {
    this._documentService.documentDownload(document.id).subscribe(res => {
      importedSaveAs(res, document.title);
    });
  }
}
