<!--Roster-infos component-->
<div id="roster-infos-component">

  <!--Picture component-->
  <div class="roster-infos-component-picture shadow">
    <!--Picture-->
    <div class="picture-image">
      <img class="image-roster" [src]="roster.roster_profile?.browser_url ? roster.roster_profile.browser_url : 'assets/images/default_roster.png'" onerror='this.src="assets/images/default_roster.png"' />
    </div>
  </div>

  <!--Infos-->
  <div class="roster-infos-component-name shadow">
    <h1 class="emalsys_site-title">{{roster.name}}</h1>
    <p class="name-description">{{roster.description}}</p>
    <h6 class="name-contact">Roster Manager</h6>
    <p class="name-contact">{{roster.contact_name}}</p>
    <p class="name-contact">{{roster.contact_email}}</p>
  </div>

  <!--Action that you can do if you are:-->
  <!--To completed...-->
  <div class="roster-infos-component-actions shadow" *ngIf='displayButtons==1'>
    <!-- Public: the user is not a member of the roster-->
    <button class="btn emalsys_button green actions-button" *ngIf='rosterStatus=="public"' (click)='showConfirmation()'>Join Roster</button>
    <!-- Member: the user is a member of the roster -->
    <button class="btn emalsys_button red actions-button" *ngIf='rosterStatus=="member"' (click)='showConfirmation()'>Quit Roster</button>
    <!-- Invited: the user has been invited to join the roster -->
    <button class="btn emalsys_button green actions-button" *ngIf='rosterStatus=="invited"' (click)='acceptInvitation()'>Accept Invitation</button>
    <button class="btn emalsys_button red   actions-button" *ngIf='rosterStatus=="invited"' (click)='declineInvitation()'>Refuse Invitation</button>
    <!-- Pending -->
    <button class="btn emalsys_button red actions-button" *ngIf='rosterStatus=="request"' (click)='cancelPendingRequest()'>Cancel Request</button>
    <!-- Frozen -->
    <button class="btn emalsys_button red actions-button" *ngIf='rosterStatus=="frozen"' (click)='showConfirmation()'>Quit Roster</button>
  </div>

  <!--This element is displayed when the user make an action depending on his statut-->
  <div class="roster-infos-component-confirm shadow" *ngIf='displayButtons==2'>
    <!-- Public -->
    <p class="confirm-text" *ngIf='rosterStatus=="public"'>Send a join request to the roster manager ?</p>
    <button class="btn emalsys_button green confirm-button" *ngIf='rosterStatus=="public"' (click)='joinRoster()'>Yes</button>
    <button class="btn emalsys_button red  confirm-button" *ngIf='rosterStatus=="public"' (click)='showAction()'>No</button>
    <!-- Member -->
    <p class="confirm-text" *ngIf='rosterStatus=="member"'>Are you sure you want to quit this roster ?</p>
    <button class="btn emalsys_button green  confirm-button" *ngIf='rosterStatus=="member"' (click)='quitRoster()'>Yes</button>
    <button class="btn emalsys_button red confirm-button" *ngIf='rosterStatus=="member"' (click)='showAction()'>No</button>
    <!-- Frozen -->
    <p class="confirm-text" *ngIf='rosterStatus=="frozen"'>Are you sure you want to quit this roster ?</p>
    <button class="btn emalsys_button green  confirm-button" *ngIf='rosterStatus=="frozen"' (click)='quitRoster()'>Yes</button>
    <button class="btn emalsys_button red confirm-button" *ngIf='rosterStatus=="frozen"' (click)='showAction()'>No</button>
  </div>

</div>
