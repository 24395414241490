
<div class="news-preloader-menu">
		
  </div>
  <div class="news-preloader-all-news">
    <div class="news-preloader-big-line"></div>
    <div class="news-preloader-big-line"></div>
    <div class="news-preloader-big-line"></div>
    <div class="news-preloader-big-line"></div>
    <div class="news-preloader-big-line"></div>
  </div>
