 <!--Profile page-->
 <div class="container-fluid">
  <!--1st row-->
  <div class="row">
    <!--Photo-->
    <div class="col-md-2 col-sm-4 col-xs-6 col-picture">
      <profile-picture [picture]='picture' [isItMe]="true"></profile-picture>
    </div>

    <!--Name and description-->
    <div class="col-md-8 col-sm-5 col-xs-12 col-picture" *ngIf="profile">
      <profile-user [profile]='profile'></profile-user>
    </div>
     <div class="col-md-8 col-sm-5 col-xs-12 col-picture" *ngIf="!profile">
      <preloader-profile-user></preloader-profile-user>
    </div>

    <!--Available-->
     <div class="col-md-2 col-sm-3 col-xs-6 col-picture" *ngIf="profile">
      <profile-available></profile-available>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-6 col-picture" *ngIf="!profile">
      <preloader-profile-available></preloader-profile-available>
    </div>

  </div>

  <!--2nd row-->
  <div class="row">
    <!--Skills, languages, documents-->
    <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="profile">
      <profile-skills></profile-skills>
      <profile-tags [profile]='profile'></profile-tags>
      <profile-languages></profile-languages>
      <profile-documents> </profile-documents>
      <div class="button-center">
        <button class="btn emalsys_button blue " (click)="goToAllData()">See all user data</button>
        <!-- <button class="btn emalsys_button blue " routerLink="alldata">See all user data</button> -->
      </div>

    </div>
     <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="!profile">
      <preloader-profile-skills></preloader-profile-skills>
      <preloader-profile-tags></preloader-profile-tags>
      <preloader-profile-languages></preloader-profile-languages>
      <preloader-profile-documents> </preloader-profile-documents>
    </div>
    <!--General information, deployments-->
     <div class="col-lg-8 col-md-12 col-sm-12" *ngIf="profile">
      <profile-general></profile-general>
    </div>
    <div class="col-lg-8 col-md-12 col-sm-12" *ngIf="!profile">
      <preloader-profile-general></preloader-profile-general>
    </div>
    <!--Phones, emergency contact-->
     <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="profile">
      <profile-phones></profile-phones>
      <profile-contact></profile-contact>
      <preloader-profile-notifications></preloader-profile-notifications>
      <preloader-profile-reset-password></preloader-profile-reset-password>
      <preloader-profile-delete-account></preloader-profile-delete-account>

    </div>
     <div class="col-lg-2 col-md-12 col-xs-3" *ngIf="!profile">
      <preloader-profile-phones></preloader-profile-phones>
      <preloader-profile-contact></preloader-profile-contact>
    </div>
  </div>

   <!--Toast-->
   <div id="retrievingProfileToast">Profile could not be loaded, try again later</div>
 </div>
