<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Add members to a Roster</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>

<!--Modal body-->
<div class="modal-body">
    <div *ngIf="rosters && rosters.length == 1 || selectedRoster" class="add-members-text">
        <p>
            You are about to invite {{toInvite.length}} member<span *ngIf="toInvite && toInvite.length > 1">s</span> to the {{selectedRoster ? selectedRoster.text : rosters[0].text}} roster.
        </p>
    </div>
    <div *ngIf="rosters && rosters.length > 1">
        <label for="select-roster">Select a roster: </label>
        <select2 id="select-roster" class="roster-modal-select" cssImport="true" width="70%"
                  [data]="rosters" [value]="selectedRoster? selectedRoster.id: '' " [options]="{multiple: false}" (valueChanged)="changed($event)">
        </select2>
    </div>

    <div class="modal-manager">
      <p>As a manager</p>
      <input #checkRosterManager class="check-manager" type="checkbox">
    </div>

    <div *ngIf="checkRosterManager.checked" class="modal-rights">
      <p>Roster rights: </p>
      <select [(ngModel)]="rights">
          <option value=1>Guest</option>
          <option value=2>Manager</option>
          <option value=3>Supervisor</option>
         </select>
    </div>

</div>

<!--Modal footer-->
<div class="modal-footer">

  <button type="submit" class="btn emalsys_button green" (click)="inviteMembers(checkRosterManager.checked); activeModal.close()" [disabled]="!selectedRoster">Add</button>
</div>
