import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { LoginService } from '../../../../../services/security/login.service';
import { EventListenerService } from '../../../../../services/utils/event-listener.service';
import { LoginDataInterface } from './../../../../../services/security/login.service';
import { CacheService } from './../../../../../services/storage/cache.service';
import { NavigationService } from './../../../../../services/utils/navigation.service';
import { UserInterface } from './../../../../model/user.interface';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {
    public email = '';
    public password = '';
    public loading = false;
    public loginFailed = false;
    public gdprRefused = false;
    public user: UserInterface;

    constructor(
        private _loginService: LoginService,
        public activeModal: NgbActiveModal,
        public _navigationService: NavigationService,
        public cache: CacheService,
        public _profileService: ProfileService,
        _eventListenerService: EventListenerService,
    ) { }

    loginIn() {
        this.loading = true;
        this.loginFailed = false;
        this.gdprRefused = false;

        this._loginService.login(this.email, this.password)
            .subscribe((data: LoginDataInterface) => {
                if (data != null) {
                    this.loading = false;
                    // dismiss the login modal
                    this.activeModal.dismiss();
                    // we save user only if accept the GDPR
                    this._loginService.cacheUser();

                    if (data.user.organisation_managers && data.user.organisation_managers.length) {
                        this.cache.set(CacheService.ISMANAGER_WEBSITE, 1);
                        this._navigationService.goToWelcomeManager(data.user.organisation_managers);
                    } else if (data.user.organisation_members.length) {
                        this.cache.set(CacheService.ISMANAGER_WEBSITE, 0);
                        this._navigationService.goToWelcomeResponder(data.user.organisation_members);
                    } else {
                        this._navigationService.goToProfile();
                    }
                } else {
                    this.gdprRefused = true;
                }
            }, error => {
                this.loading = false;
                if (error === LoginService.GDPR_ERROR) {
                    this.gdprRefused = true;
                } else {
                    this.loginFailed = true;
                }
            });
    }
}
