<button class="component-roster" routerLink='roster/{{label}}/{{roster.id}}'>
  <div class="component-roster-label"
      [class.member] ='label=="member"'
      [class.invited]  ='label=="invited"'
      [class.request]='label=="request"'
      [class.frozen] ='label=="frozen"'>
    <h6 class="label-text">{{label}}</h6>
  </div>

  <div class="component-roster-image">
    <img class="image-logo" [src]="roster.roster_profile.browser_url ? roster.roster_profile.browser_url : 'assets/images/default_roster.png'" onerror='this.src="assets/images/default_roster.png"'/>
  </div>

  <div class="component-roster-name">
    <h3 class="name-text">{{roster.name | textSnipped:40}}</h3>
  </div>

  <div class="component-roster-number">
    <h6 class="number-text">{{roster.count_members}} members</h6>
  </div>
</button>
