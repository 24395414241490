import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URL_API_RELIEF_WEB_IMG_LG, ISO2_TO_ISO3 } from '../../../../../app.constants';
// Service
import { DeploymentOrderService } from '../../../../../../services/api/deployment-order.service';
import { DocumentService } from '../../../../../../services/api/document.service';
import { LoggerService          } from '../../../../../../services/utils/logger.service';
import { saveAs as importedSaveAs } from "file-saver";
import { ToastService } from '../../../../../../services/utils/toast.service';

@Component({
  selector: 'alerts-deployment-offer',
  templateUrl: 'alerts-deployment-offer.component.html',
  styleUrls: ['alerts-deployment-offer.component.scss']
})
export class AlertsDeploymentOfferComponent {
  @Output() validate: EventEmitter<any>=new EventEmitter();
  @Output() refuse: EventEmitter<any>=new EventEmitter();
  @Input() alertInfo;
  @Input() idAlert;
  @Input() deploymentOffers;
  @Input() nbrOffers;
  @Input() documents;
  @Input() eventRequirement;
  public downloadingFile: boolean = false;



  constructor(
    private _documentService: DocumentService,
    private _deploymentOrderService: DeploymentOrderService,
    private _loggerService: LoggerService,
    private route: ActivatedRoute,
    private _toastService: ToastService,
  ) {
  }

  ngOnChange() {
  
  }

  //Validate the offer
  validateDeploymentOffer(idDeploymentOffer) {
    this._deploymentOrderService.validateDeploymentOffer(idDeploymentOffer).subscribe(res => {
      this.validate.emit(res);
      this.showToastAcceptOffer()
    });
  }

  //Refuse the offer
  refuseDeploymentOffer(idDeploymentOffer) {
    this._deploymentOrderService.refuseDeploymentOffer(idDeploymentOffer).subscribe(res => {
      this.refuse.emit(res);
      this. showToastRefuseOffer()
    });
  }

  downloadDoc(document) {
      this._loggerService.log( "Doc ID to download: ", document.id);
      this.downloadingFile = true;
      this._documentService.documentDownload(document.id).subscribe( response => {
          this.downloadingFile = false;
          importedSaveAs(response, document.title);
          this._loggerService.log("Blob received: ", response);
      }, error => {
          this.downloadingFile = false;
          this._loggerService.error("Failed to download the file: ", error);
      })
  }

  showToastAcceptOffer() {
    this._toastService.show("acceptOfferToast", "show", 3000);
  }
  showToastRefuseOffer() {
    this._toastService.show("refuseOfferToast", "show", 3000);
  }
}
