import { BehaviorSubject } from 'rxjs';
import { Dispatcher } from '../../dispatcher.service';
export class AlertDispatcherService extends Dispatcher {
    constructor() {
        super(...arguments);
        this.organisationAlerts$ = new BehaviorSubject(null);
        this.oldOrganisationAlerts$ = new BehaviorSubject(null);
        this.myAlerts$ = new BehaviorSubject(null);
        this.selectedAlert$ = new BehaviorSubject(null);
    }
    getOrganisationAlerts() {
        return this.pipeNotNull(this.organisationAlerts$);
    }
    nextOrganisationAlerts(alerts) {
        this.organisationAlerts$.next(alerts);
    }
    getOldOrganisationAlerts() {
        return this.pipeNotNull(this.oldOrganisationAlerts$);
    }
    nextOldOrganisationAlerts(alerts) {
        this.oldOrganisationAlerts$.next(alerts);
    }
    getMyAlerts() {
        return this.pipeNotNull(this.myAlerts$);
    }
    nextMyAlerts(alerts) {
        this.myAlerts$.next(alerts);
    }
    getSelectedAlert() {
        return this.pipeNotNull(this.selectedAlert$);
    }
    nextSelectedAlert(alert) {
        if (typeof alert === 'number') {
            alert = this.organisationAlerts$.getValue()
                .find(x => x.id === alert);
        }
        if (alert) {
            this.selectedAlert$.next(alert);
        }
    }
    getSelectedAlertAsScalar() {
        return this.selectedAlert$.getValue();
    }
}
