import { UserInterface } from './model/user.interface';
import { GlobalDataService } from './../services/api/global/global.api';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheNotificationsService } from './../services/utils/cache.notifications';
import { BannerUpdater } from './modules/shared/banner/banner-updater.dispatcher';
import { Component, OnInit } from '@angular/core';

import { CacheService } from '../services/storage/cache.service';
import { UpdateService } from '../services/utils/update.service';
import { PushNotificationService } from '../services/api/pushnotifications.service';
import { BehaviorSubject } from 'rxjs';
import { GlobalDispatcherService } from '../services/api/global/global.dispatcher';
import { ProfileDispatcherService } from '../services/api/profile/profile.dispatcher';
import * as SHA256 from 'crypto-js/sha256';
import { LoggerService } from '../services/utils/logger.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    public cache: CacheService,
    private update: UpdateService,
    private bannerUpdater: BannerUpdater,
    private _cacheNotifications: CacheNotificationsService,
    private _route: Router,
    private push: PushNotificationService,
    private profile: ProfileDispatcherService,
    private _logger: LoggerService
  ) {

  }
  ngOnInit() {
    let credentialsCookie = this.getCookie('credentials');
    let credentials = credentialsCookie ? JSON.parse(decodeURIComponent(credentialsCookie)) : null;
    if (credentials) {
      let nbMember = credentials.organisation_members.length;
      let nbManager = credentials.organisation_managers.length + credentials.roster_managers.length;
      credentials.voters = {
        module_responder_access: (nbMember || nbManager) ? true : false,
        module_manager_access: (nbManager) ? true : false
      };

      let optionCache = {
        timeout: 172800 // 2 days in second
      };
      this.cache.set(CacheService.USER, credentials, optionCache);
    }

    this.profile.getProfile().subscribe(
      // listen for notifications. Use as User ID the email hash
      (x: UserInterface) => this.push.listenForNotifications(SHA256(x.email)),
      (err) => this._logger.error(err)
    );
  }

  getCookie(name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }



  /**
   * Along with the related dispatcher,
   * this method is a patch to fix TEMPORARLY the css
   * without breaking the site structure.
   * This method ensures that the menu will be closed if something is clicked but the banner
   */
  onBodyClicked(event) {
    if (event.path && event.path instanceof Array) {
      const banner = event.path.find(x => {
        return x.localName === 'app-banner';
      });
      const mainComponent = event.path.find(x => {
        return x.localName === 'div' && x.id === 'main-component';
      });
      // please don't remove 'null' from the condition. null ensures that the condition will return a boolean
      const bannerClicked = banner != null && mainComponent != null;
      if (!bannerClicked) {
        this.bannerUpdater.closeMenu();
      }
    }
  }
}
