export class OrganisationInterface {

    public static readonly CACHE = {
        ALL_ORGANISATIONS: 'all_organisations',
        USER_ORGANISATIONS: 'user_organisations',
        CURRENT_ORGANISATION: 'current_organisation',
        ORGANISATION_MEMBERS: 'organisation_members_',
        ORGANISATION_ROSTERS: 'organisation_rosters_',
    };

    /**
    * Organisation id
    * @type {number}
    **/
    id: number;
    /**
    * Organisation name
    * @type {string}
    **/
    name: string;
    /**
    * Organisation description
    * @type {string}
    **/
    description?: string;
    /**
    * Organisation locale
    * @type {string}
    **/
    locale?: string;
	/**
	* Organisation creation date
	* @type {Date}
	**/
	creationDate?: Date;
    /**
    * Organisation website
    * @type {string}
    **/
    website?: string;
    /**
    * Organisation facebook
    * @type {string}
    **/
    facebook?: string;
    /**
    * Organisation twitter
    * @type {string}
    **/
    twitter?: string;
    /**
    * Organisation linkedIn
    * @type {string}
    **/
    linkedIn?: string;
    /**
    * Organisation promo video
    * @type {string}
    **/
    promoVideo?: string;
    /**
    * Organisation acronym
    * @type {string}
    **/
    acronym?: string;
    /**
    * Organisation type
    * @type {string}
    **/
    type?: string;
    /**
    * Organisation logo name
    * @type {string}
    **/
    logo_name?: string;
    /**
    * Organisation network
    * @type {boolean}
    **/
    network?: boolean;
}
