import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared.module';
import { Select2Module } from 'ng2-select2';
//Routing
import { RosterRouting } from './roster.routing';
//Components
import { RosterComponent            } from './roster.component';
import { RosterInfosComponent       } from './roster-infos/roster-infos.component';
import { RosterCompliancesComponent } from './roster-compliances/roster-compliances.component';
import { RosterMembersComponent     } from './roster-members/roster-members.component';
import { RosterPreloaderMembersComponent } from './roster-preloader/roster-preloader-members/roster-preloader-members.component';
import { RosterPreloaderCompliancesComponent } from './roster-preloader/roster-preloader-compliances/roster-preloader-compliances.component';
import { RosterPreloaderInfosComponent } from './roster-preloader/roster-preloader-infos/roster-preloader-infos.component';



@NgModule({
  imports: [
    //Routing
    RosterRouting,
    //Angular Modules
    CommonModule,
    SharedModule,
    Select2Module
  ],
  declarations: [
    RosterComponent,
    RosterInfosComponent,
    RosterCompliancesComponent,
    RosterMembersComponent,
    RosterPreloaderMembersComponent,
    RosterPreloaderCompliancesComponent,
    RosterPreloaderInfosComponent,
  ],
  providers: [],
   entryComponents: [

   ]
})


export class RosterModule {}
