import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'news-preloader',
  templateUrl: './news-preloader.component.html',
  styleUrls: ['./news-preloader.component.scss']
})
export class NewsPreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
