<div class="roster-infos-component">

  <div class="roster-infos-component-picture preloader shadow"></div>

  <div class="roster-infos-component-name shadow">
    <div class="small-lines preloader"></div>
    <div class="big-lines preloader"></div>
    <div class="small-lines preloader"></div>
    <div class="big-lines preloader"></div>
    <div class="big-lines preloader"></div>
  </div>

  <div class="roster-infos-component-actions shadow">
    <div class="button preloader"></div>
    <div class="button preloader"></div>
  </div>
</div>