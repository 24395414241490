/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-responder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-preloader/dashboard-preloader-news/dashboard-preloader-news.component.ngfactory";
import * as i3 from "./dashboard-preloader/dashboard-preloader-news/dashboard-preloader-news.component";
import * as i4 from "./dashboard-news/dashboard-news.component.ngfactory";
import * as i5 from "./dashboard-news/dashboard-news.component";
import * as i6 from "../../../../services/api/organisation/organisation.api";
import * as i7 from "../../../../services/api/alert/alert.api";
import * as i8 from "../../../../services/external/disasters.service";
import * as i9 from "../../../../services/utils/logger.service";
import * as i10 from "../../../../services/api/alert/alert.dispatcher";
import * as i11 from "./dashboard-preloader/dashboard-preloader-alerts/dashboard-preloader-alerts.component.ngfactory";
import * as i12 from "./dashboard-preloader/dashboard-preloader-alerts/dashboard-preloader-alerts.component";
import * as i13 from "./dashboard-alerts/dashboard-alerts.component.ngfactory";
import * as i14 from "./dashboard-alerts/dashboard-alerts.component";
import * as i15 from "./dashboard-preloader/dashboard-preloader-rosters/dashboard-preloader-rosters.component.ngfactory";
import * as i16 from "./dashboard-preloader/dashboard-preloader-rosters/dashboard-preloader-rosters.component";
import * as i17 from "./dashboard-rosters/dashboard-rosters.component.ngfactory";
import * as i18 from "./dashboard-rosters/dashboard-rosters.component";
import * as i19 from "../../../../services/api/roster/roster.cached";
import * as i20 from "@ng-bootstrap/ng-bootstrap";
import * as i21 from "../../../../services/utils/toast.service";
import * as i22 from "../../../../services/api/roster/rosters.dispatcher";
import * as i23 from "../../../../services/api/roster/dependencies/membership/roster-membership.api";
import * as i24 from "@angular/common";
import * as i25 from "./dashboard-responder.component";
import * as i26 from "@angular/platform-browser";
import * as i27 from "@angular/router";
import * as i28 from "../../../../services/storage/cache.service";
import * as i29 from "../../../../services/api/profile/profile.dispatcher";
import * as i30 from "../../../../services/api/organisation/organisation.dispatcher";
import * as i31 from "../../../../services/api/organisation/organisation.cacher";
import * as i32 from "../../../../services/utils/right.service";
import * as i33 from "../../../../services/api/alert/alert.cacher";
var styles_DashboardResponderComponent = [i0.styles];
var RenderType_DashboardResponderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardResponderComponent, data: {} });
export { RenderType_DashboardResponderComponent as RenderType_DashboardResponderComponent };
function View_DashboardResponderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-news", [["class", "dashboard-component-news"]], null, null, null, i2.View_DashboardPreloaderNewsComponent_0, i2.RenderType_DashboardPreloaderNewsComponent)), i1.ɵdid(1, 114688, null, 0, i3.DashboardPreloaderNewsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DashboardResponderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-news", [["class", "dashboard-component-news"]], null, null, null, i4.View_DashboardNewsComponent_0, i4.RenderType_DashboardNewsComponent)), i1.ɵdid(1, 573440, null, 0, i5.DashboardNewsComponent, [i6.OrganisationApiService, i7.AlertService, i8.DisasterService, i9.LoggerService, i10.AlertDispatcherService], { organisation: [0, "organisation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DashboardResponderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-alerts", [["class", "dashboard-component-alerts"]], null, null, null, i11.View_DashboardPreloaderAlertsComponent_0, i11.RenderType_DashboardPreloaderAlertsComponent)), i1.ɵdid(1, 114688, null, 0, i12.DashboardPreloaderAlertsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DashboardResponderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-alerts", [["class", "dashboard-component-alerts"]], null, null, null, i13.View_DashboardAlertsComponent_0, i13.RenderType_DashboardAlertsComponent)), i1.ɵdid(1, 114688, null, 0, i14.DashboardAlertsComponent, [i9.LoggerService, i10.AlertDispatcherService], { organisation: [0, "organisation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DashboardResponderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-preloader-rosters", [["class", "dashboard-component-rosters"]], null, null, null, i15.View_DashboardPreloaderRostersComponent_0, i15.RenderType_DashboardPreloaderRostersComponent)), i1.ɵdid(1, 114688, null, 0, i16.DashboardPreloaderRostersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DashboardResponderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-responder-rosters", [["class", "dashboard-component-rosters"]], null, null, null, i17.View_DashboardRostersComponent_0, i17.RenderType_DashboardRostersComponent)), i1.ɵdid(1, 245760, null, 0, i18.DashboardRostersComponent, [i19.RosterService, i9.LoggerService, i20.NgbModal, i21.ToastService, i22.RostersDispatcherService, i23.RosterMembershipService], { organisation: [0, "organisation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organisation; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DashboardResponderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "dashboard-component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_1)), i1.ɵdid(2, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_2)), i1.ɵdid(4, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_3)), i1.ɵdid(6, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_4)), i1.ɵdid(8, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_5)), i1.ɵdid(10, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardResponderComponent_6)), i1.ɵdid(12, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["id", "joinRequestSendToast"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Request sent"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loadedAlerts; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loadedAlerts; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.loadedAlerts; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.loadedAlerts; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.loadedRosters; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.loadedRosters; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_DashboardResponderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_DashboardResponderComponent_0, RenderType_DashboardResponderComponent)), i1.ɵdid(1, 245760, null, 0, i25.DashboardResponderComponent, [i26.Title, i27.ActivatedRoute, i6.OrganisationApiService, i9.LoggerService, i28.CacheService, i7.AlertService, i29.ProfileDispatcherService, i30.OrganisationDispatcherService, i31.CachedOrganisationService, i32.RightService, i19.RosterService, i10.AlertDispatcherService, i22.RostersDispatcherService, i33.AlertCachedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardResponderComponentNgFactory = i1.ɵccf("dashboard", i25.DashboardResponderComponent, View_DashboardResponderComponent_Host_0, {}, {}, []);
export { DashboardResponderComponentNgFactory as DashboardResponderComponentNgFactory };
