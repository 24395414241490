import { Component, Input, OnInit } from '@angular/core';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { ProfileDispatcherService } from '../../../../../services/api/profile/profile.dispatcher';
import { LoggerService } from '../../../../../services/utils/logger.service';
import { ToastService } from '../../../../../services/utils/toast.service';

@Component({
  selector: 'profile-contact',
  templateUrl: './profile-contact.component.html',
  styleUrls: ['./profile-contact.component.scss']
})


export class ProfileContactComponent implements OnInit {


  // This variable is set only from the external-profile component
  @Input() contacts: any;

  public emergencyContacts: any;

  public prefixes: any;    // Country indicators
  public type: number; // Type (Cellphone / Fix etc...)
  public prefixe: any;    // Country code of the number
  public phoneNumber: any;

  public nameToAdd: any;
  public surnameToAdd: any;
  public emailToAdd: any;
  public phoneNumberToAdd: any;
  public relationshipToAdd: any;

  public loading = false;
  public modalRef;

  public isSomeoneElse = false;
  constructor(
    private _profileService: ProfileService,
    private _modalService: NgbModal,
    private _loggerService: LoggerService,
    private _toastService: ToastService,
    private _profileDispatcher: ProfileDispatcherService
  ) {
    this.prefixes = [];
    this.type = 0;
    this.prefixe = { code: 33, name: "France", iso2: "FR" };
  }

  ngOnInit() {

    this.isSomeoneElse = this.contacts !== undefined;
    // If contacts are NOT provided by external-profile
    if (!this.isSomeoneElse) {
      // Get them from the backend
      this.getMyContacts();
    } else {
      // Otherwise, here they are
      this.emergencyContacts = this.contacts;
    }
    this.getPrefixes();
    this.initVariables();

    this._profileService.getMyEmergencyContact();
  }

  getPrefixes() {
    this._profileService.getPrefixes()
      .subscribe(res => {
        Object.keys(res).forEach(key => {
          this.prefixes.push({
            code: res[key].code,
            name: res[key].name,
            iso2: key
          });
          this.prefixes.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        });
      });
  }

  getMyContacts() {
    this._profileDispatcher.getEmergencyContacts()
      .subscribe(res => {
        this.emergencyContacts = res;
        this._loggerService.log("MY EMERGENCY CONTACTS", this.emergencyContacts);
      });
  }

  showAddContact(content) {
    this.modalRef = this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
  }

  addEmergencyContact() {

    let phoneNumber = "+" + this.prefixe.code + this.phoneNumberToAdd;

    // This email pattern is weird... Does actually exist an email service that lets you use
    // such special characters? Found on SO.
    if (phoneNumber.match(/^\+[0-9]{1,3}[0-9]+$/) === null ||
        this.emailToAdd.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
      // this._toastService.show("wrongEmailErrorToast", "show");
      this._toastService.show("contactErrorToast", "show", 3000);
      return;
    }

    this.loading = true;
    let emergencyContact = {
      name: this.nameToAdd,
      surname: this.surnameToAdd,
      email: this.emailToAdd,
      phoneNumber: phoneNumber,
      relationship: this.relationshipToAdd
    };
    this._profileService.addEmergencyContact(emergencyContact)
      .subscribe(res => {
        this.loading = false;
        // this.getMyContacts();
        this.modalRef.close();
        this.showToastAdd();
      }, err => {
        this.loading = false;
        this.modalRef.close();
        this.showToastError();
      });

  }

  initVariables() {
    this.nameToAdd = null;
    this.surnameToAdd = null;
    this.emailToAdd = null;
    this.prefixe.code = null;
    this.phoneNumberToAdd = null;
    this.relationshipToAdd = null;
  }

  deleteEmergencyContact(emergencyContactId) {
    this._profileService.deleteEmergencyContact(emergencyContactId)
      .subscribe(res => {
        this.showToastDeleted();
        this.getMyContacts();
      });
  }

  showToastDeleted() {
    this._toastService.show("contactDeleteToast", "show", 3000);
  }

  showToastError() {
    this._toastService.show("contactErrorToast", "show", 3000);
  }

  showToastAdd() {
    this._toastService.show("contactAddToast", "show", 3000);
  }

}
