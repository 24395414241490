<!-- <app-chart #chartDiv (user_action) = "actionClicked($event)"
  [title]           = "title"
  [modalConfig]     = "modalConfig"
  [axis]            = "axis"
  [indicatorConfig] = "indicatorConfig"
  [noData]          = "noData"
  [loader]          = "loader"
  [menuOpen]        = "menuOpen">
</app-chart> -->

<chart-header
[title]           = "title"
[loader]          = "loader"
[noData]          = "noData"
[menuOpen]        = "menuOpen"
[modalConfig]     = "modalConfig"
(menuAction) = "actionClicked($event)"
></chart-header>

<div *ngIf="!loader && (!noData)" >
    <ngx-charts-pie-grid 
    [view]           = "view"
    [scheme]         = "scheme"
    [results]        = "data"
    [label]         = "'valeur'">
    </ngx-charts-pie-grid>
</div>
<div *ngIf="!loader && noData" class="no-data">
  <!-- <p> Aucune donnée encore enregistrée. </p> -->
  <!-- <img class="no-data" src="../../assets/images/no_data.jpg" /> -->
  <h1>NO DATA</h1>

</div>

<div *ngIf="loader" class="boxLoader"></div>

<modal-data *ngIf="(action == 'donnees')&&!loader"
  (closeMenu)         = "closeMenu($event)"
  (clearAction)       = "clearAction($event)"
  [modalId]           = "this.modalConfig.modalId"
  [modalTitle]        = "this.title"
  [data]              = "this.data"
  [indicatorConfig]   = "this.indicatorConfig"
  [filters]           = "this.filters"
  [typeChartModal]    = "this.indicatorConfig.type"
  [modalAxis]         = "{ x: this.axis.xAxisLabel, y: this.axis.yAxisLabel }"
  >
</modal-data>

<modal-details *ngIf="(action == 'details')&&!loader"
  (clearAction)       = "clearAction($event)"
  [modalId]             = "this.modalConfig.modalId"
  [modalTitle]          = "this.title"
  [modalComputedMethod] = "this.indicatorConfig.computedMethod">
</modal-details>