/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-delete-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./profile-delete-account.component";
import * as i3 from "../../../../../services/api/settings-service";
import * as i4 from "../../../../../services/utils/navigation.service";
import * as i5 from "../../../../../services/storage/cache.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../../../services/utils/toast.service";
import * as i8 from "../../../../../services/utils/logger.service";
var styles_ProfileDeleteAccountComponent = [i0.styles];
var RenderType_ProfileDeleteAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileDeleteAccountComponent, data: {} });
export { RenderType_ProfileDeleteAccountComponent as RenderType_ProfileDeleteAccountComponent };
function View_ProfileDeleteAccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deleting account"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.showToastCancel();
        var pd_0 = (_v.context.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "modal-body text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [["class", "margin-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to delete your account ?"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn emalsys_button green margin-r"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.showToastCancel();
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn emalsys_button red margin-r"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.deleteMyAccount();
        _co.showToastDeleted();
        var pd_0 = (_v.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
export function View_ProfileDeleteAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "shadow margin-b"], ["id", "settings-delete-account-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "text-center"], ["id", "delete-account-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "margin-b"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DELETE MY ACCOUNT"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn emalsys_button red"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(i1.ɵnov(_v, 6)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_ProfileDeleteAccountComponent_1)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["id", "snackbarDelete"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your account has been deleted"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["id", "snackbarCancel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You cancelled the deletion"]))], null, null); }
export function View_ProfileDeleteAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "preloader-profile-delete-account", [], null, null, null, View_ProfileDeleteAccountComponent_0, RenderType_ProfileDeleteAccountComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProfileDeleteAccountComponent, [i3.SettingsService, i4.NavigationService, i5.CacheService, i6.NgbModal, i7.ToastService, i8.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileDeleteAccountComponentNgFactory = i1.ɵccf("preloader-profile-delete-account", i2.ProfileDeleteAccountComponent, View_ProfileDeleteAccountComponent_Host_0, {}, {}, []);
export { ProfileDeleteAccountComponentNgFactory as ProfileDeleteAccountComponentNgFactory };
