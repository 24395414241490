import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Services
import { CacheService  } from './services/storage/cache.service';
import { LoggerService } from './services/utils/logger.service';

// Interfaces
import { SettingsInterface } from './app/model/settings.interface';

if (environment.production) {
  enableProdMode();
}

// Retrieve local from the cache
let cache = new CacheService();
let settings: SettingsInterface = cache.get(CacheService.SETTINGS) || {} ;
// Logger
let logger = new LoggerService(cache);

if (! settings.language) {
  settings.language = "en";
  cache.set(CacheService.SETTINGS, settings, {canBeDeleted: false});
  logger.log("Langue default EN: ", settings.language);
}

let locale = settings.language;

// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string
const translations = require(`raw-loader!./app/locale/messages.${locale}.xlf`);

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: TRANSLATIONS, useValue: translations},
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
  ]
})
  .catch(err => logger.log(err));
