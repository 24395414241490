import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// TODO: @Odysseas, did you see this component ?
@Injectable()
export class EventListenerService {

    private event = new Subject<Object>();

    event$ = this.event.asObservable();

    publish(key, value) {
        let object = new Object();
        object[key] = value;
        this.event.next(object);
    }

}
