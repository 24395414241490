import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
export class AlertButton {
}
AlertButton.RED = 'red';
AlertButton.GREEN = 'green';
export class AlertModalConfirmationComponent {
    constructor(_activeModal) {
        this._activeModal = _activeModal;
        // This because I couldn't find a way
        // to use the static variables into the template!
        this.RED = AlertButton.RED;
        this.GREEN = AlertButton.GREEN;
        this.title = "";
        this.body = "";
        this.buttons = [];
    }
    ngOnInit() {
    }
}
