import { Component, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { switchMap } from 'rxjs/operators';
import { AlertService } from '../../../../services/api/alert/alert.api';
import { AlertDispatcherService } from '../../../../services/api/alert/alert.dispatcher';
import { GlobalDataService } from '../../../../services/api/global/global.api';
import { GlobalDispatcherService } from '../../../../services/api/global/global.dispatcher';
import { MembersDispatcherService } from '../../../../services/api/member/members.dispatcher';
import { OrganisationApiService } from '../../../../services/api/organisation/organisation.api';
import { CachedOrganisationService } from '../../../../services/api/organisation/organisation.cacher';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { RostersDispatcherService } from '../../../../services/api/roster/rosters.dispatcher';
import { DashboardManagerPageService } from '../../../../services/page/dashboard.manager.pager';
import { RightService } from '../../../../services/utils/right.service';
import { ToastService } from '../../../../services/utils/toast.service';
import { ORGANISATION_MANAGER_RIGHTS_LEVEL, ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../app.constants';
import { RosterInterface } from '../../../model/roster.interface';
import { UserInterface } from '../../../model/user.interface';
import { InitializedDashboard } from '../../../model/utils/initialization-model.interface';
import { DashboardComponentModel } from '../../shared/component-model/dashboard/dashboard.component-model';
import { ProfileService } from './../../../../services/api/profile/profile.api';
import { CacheService } from './../../../../services/storage/cache.service';
import { EventListenerService } from './../../../../services/utils/event-listener.service';
import { LoggerService } from './../../../../services/utils/logger.service';
import { ROUTING } from './../../../app.constants';
import { MemberModalComponent } from './modals/member/member-modal.component';

@Component({
  selector: 'dashboard-manager',
  templateUrl: './dashboard-manager.component.html',
  styleUrls: ['./dashboard-manager.component.scss']
})
export class DashboardManagerComponent extends DashboardComponentModel implements OnInit, OnDestroy {

  @ViewChild("rosters") rosters;

  public adding = false;
  public canIAddOrganisationMembers = false;
  public canIAddRosterMembers = false;
  private _differ: KeyValueDiffer<any, any>;
  public filteredMembers: UserInterface[] = [];
  public members: UserInterface[] = [];
  public profile;
  public selected = {};
  public selectedRoster: RosterInterface;
  public show = false;

  public eventsCount = 0;

  public subscriptions$: Subscription[] = [];

  public loadedSkillsAndLanguages = false;
  public loadedRosters = false;
  public loadedMembers = false;
  public loadedAlerts = false;

  constructor(
    private _differs: KeyValueDiffers,
    protected titleService: Title,
    protected route: ActivatedRoute,
    protected _rightService: RightService,
    protected _organisationService: OrganisationApiService,
    protected _profileService: ProfileService,
    protected _loggerService: LoggerService,
    protected _cacheService: CacheService,
    protected _alertEmalsysService: AlertService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    protected _profileDispatcher: ProfileDispatcherService,
    protected _eventListenerService: EventListenerService,
    protected _organisationCachedService: CachedOrganisationService,
    protected _organisationDispatcher: OrganisationDispatcherService,
    protected _membersDispatcher: MembersDispatcherService,
    protected _globalService: GlobalDataService,
    private _dashboardPage: DashboardManagerPageService,
    private _globalDispatcher: GlobalDispatcherService,
    private _rosterDispatcher: RostersDispatcherService,
    private _alertDispatcher: AlertDispatcherService
  ) {
    super();
  }

  ngOnInit() {
    this.titleService.setTitle("Manager Organisation Area");
    this._dashboardPage.initialization();
    const route$ = this.route.paramMap
      .pipe(
        switchMap(params => {
          return this.initialize(+params.get(ROUTING.parameter.organisationId));
        })
      )
      .subscribe((initialized: InitializedDashboard) => {
        this.titleService.setTitle(initialized.organisation.name + ' - Manager');
      });
    this.subscriptions$.push(route$);

    this._differ = this._differs.find({}).create();

    this.subscriptions$.push(this._profileService.getProfile().subscribe());
    let organisationForRosters = this._organisationDispatcher.getSelectedOrganisation().subscribe();

    this.subscriptions$.push(organisationForRosters);
    this._globalService.getSkills();
    this._globalService.getAllLanguages();

    this.subscriptions$.push(
      combineLatest(
        this._globalDispatcher.getLanguages(),
        this._globalDispatcher.getSkills()
      ).subscribe(
        res => {
          if (res[0] && res[1] && res[1].length > 0) {
            this.loadedSkillsAndLanguages = true;
          }
        }
      ));
    this.subscriptions$.push(
      this._rosterDispatcher.getOrganisationRostersAsManager().subscribe(
        rosters => this.loadedRosters = true
      )
    );

    this.subscriptions$.push(
      this._membersDispatcher.getMembers().subscribe(
        members => this.loadedMembers = true
      )
    );

    // If it doesn't recive the alerts within 2.5 seconds
    // we assume that it's not connected or that there are no alerts
    this.subscriptions$.push(
      this._alertDispatcher.getOrganisationAlerts()
        .subscribe(
          alerts => {
            this.eventsCount = alerts === null ? 0 : alerts.length;
            this.loadedAlerts = true;
          }
        )
    );
  }

  concatSearch(search: any, rostersSelected: number) {
    search.rosterQuery = rostersSelected;
    return search;

  }

  checkOrganisationAssociate() {
    if (this.organisation) {
      return this._rightService.checkOrganisationManagerRight(this.profile, this.organisation.id, ORGANISATION_MANAGER_RIGHTS_LEVEL["Associate"]);
    }
  }

  checkRosterGuest(rosterId) {
    return this._rightService.checkRosterManagerRight(this.profile, rosterId, ROSTER_MANAGER_RIGHTS_LEVEL['Guest']);
  }

  showDropdown() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    this.subscriptions$.forEach(subscription => subscription.unsubscribe());
    this._dashboardPage.destroy();
    this._membersDispatcher.clearMembers();
  }

  addMember(option: string) {
    let modalRef = this._modalService.open(MemberModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static'});

    // ROSTER: needs rosters to check the "context" of adding someone
    if (this.rosters && this.rosters.selected === -1) {
      modalRef.componentInstance.context = "organisation";
      modalRef.componentInstance.organisation = this.organisation;
    } else {
      modalRef.componentInstance.context = "roster";
      modalRef.componentInstance.rosterId = this.rosters.selected;
    }

    if (option === 'addByEmail') {
      modalRef.componentInstance.addByEmail = true;
    } else {
      modalRef.componentInstance.addByEmail = false;
    }
    modalRef.componentInstance.onClickAddMembers.subscribe(($e) => {
      this.selected = {};
      this.showToastAddMember();
    });
    modalRef.componentInstance.onClickAddManagers.subscribe(($e) => {
      this.selected = {};
      this.showToastAddManager();
    });
  }

  /**
   * If adding is true, adding becomes false.
   * If adding is false, adding becomes true.
   */
  currentlyAdding() {
    this.adding = this.adding === false;
  }

  setFilteredMembers(members: UserInterface[]) {
    this.filteredMembers = members;
  }

  showToastAddMember() {
    this._toastService.show("organisationMemberAddToast", "show", 3000);
  }
  showToastAddManager() {
    this._toastService.show("organisationManagerAddToast", "show", 3000);
  }
  showToastRostercreate() {
    this._toastService.show("rosterCreateToast", "show", 3000);
  }

}
