<button class="component-alert" routerLink="alert/{{alert.id}}">
  <div class="component-alert-map">
    <img class="map-picture" src="{{URL_API_RELIEF_WEB_IMG}}/{{ISO2_TO_ISO3[alert.country]}}.png" (error)="errorHandler($event)" />
  </div>

  <div class="component-alert-infos">
    <h3 class="infos-name snipped">{{alert.name}}</h3>
    <h6 class="infos-country snipped">{{ alert.country_name }}</h6>
    <h6 class="infos-date snipped">{{alert.start_date | niceDateDetailed}}<br/>{{alert.end_date   | niceDateDetailed}}</h6>
  </div>

  <div class="component-alert-steps">
    <div class="steps-circle {{calendarStep}}" ></div>
    <div class="steps-line"></div>
    <div class="steps-circle {{offerStep}}"></div>
    <div class="steps-line"></div>
    <div class="steps-circle {{orderStep}}"></div>
  </div>
</button>
