import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationApiService } from '../../../../../../services/api/organisation/organisation.api';
import { CachedOrganisationService } from '../../../../../../services/api/organisation/organisation.cacher';
import { EventListenerService } from '../../../../../../services/utils/event-listener.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { NavigationService } from '../../../../../../services/utils/navigation.service';
// Data
import { COMPLIANCE_CODE } from '../../../../../app.constants';
import { OrganisationCompliancesService } from './../../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api';


@Component({
  selector: 'app-edit-organisation-modal',
  templateUrl: './edit-organisation-modal.component.html',
  styleUrls: ['./edit-organisation-modal.component.scss']

})
export class EditOrganisationModalComponent implements OnInit {

    @Input() orgaId: any;
    @Output() onDelete = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    public loading = false;
    public organisation: any = {};
    public profile: any = {};
    public logo: File;

    public organisationCompliances: any;
    public editActive: number= null;
    public addActive = false;

    public newLevelRequirement;
    public newTitleRequirement;
    public editLevelRequirement;
    public editTitleRequirement;

    constructor(
      public _navigationService: NavigationService,
      public activeModal: NgbActiveModal,
      private _organisationService: OrganisationApiService,
      private _loggerService: LoggerService,
      private _eventListenerService: EventListenerService,
      private _cachedOrganisationService: CachedOrganisationService,
      private _organisationCompliances: OrganisationCompliancesService
  ) { }

    ngOnInit() {
      this._organisationService.getOrganisation(this.orgaId).subscribe(res => {
        this.organisation = res;
        this.profile = this.organisation.organisation_profile;
        this.getOrganisationCompliances(this.orgaId);
      });
    }

    fileChange(event) {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        this.logo = fileList[0];
      }
    }

    editOrganisation() {
      this.loading = true;
        let body = new FormData();
        body.append('logoEdit', this.logo);
        body.append('organisationEdit', JSON.stringify(this.organisation));
        body.append('profileEdit', JSON.stringify(this.profile));
         this._organisationService.updateOrganisation(this.orgaId, body).subscribe(res => {
            this.onEdit.emit("Organisation edited");
            this._eventListenerService.publish("organisation", true);
            // TODO: check if organisation need to be published
            this.loading = false;
            this.activeModal.close();
         });
    }

    deleteOrganisation() {
      this.loading = true;
        this._organisationService.deleteOrganisation(this.orgaId).subscribe(res => {
          this.onDelete.emit("Organisation deleted");
          this.loading = false;
          this.activeModal.close();
          this._navigationService.goToPublic();
        });
    }

    addOrganisationCompliance(title, level) {
        let body = {
            title: null,
            level: null
        };
        body.title = title;
        body.level = level;
        this._organisationCompliances.addOrganisationCompliance(this.orgaId, body).subscribe(res => {
          this.getOrganisationCompliances(this.orgaId);
          this.addActive = false;
          this.newTitleRequirement = null;
          this.newLevelRequirement = null;
        });
    }

    editOrganisationCompliance(title, level, requirementId) {
        let body = {
            title: null,
            level: null
        };
        body.title = title;
        body.level = level;
        this._organisationCompliances.updateOrganisationCompliance(requirementId, body).subscribe(res => {
          this.getOrganisationCompliances(this.orgaId);
        });
    }

    deleteOrganisationCompliance(requirementId) {
        this._organisationCompliances.removeOrganisationCompliance(requirementId).subscribe(res => {
          this.getOrganisationCompliances(this.orgaId);
        });
    }

    getOrganisationCompliances(idOrganisation) {
      this._organisationCompliances.getOrganisationCompliances(idOrganisation).subscribe(res => {
        this.organisationCompliances = res;
      });
    }

    setLevel(member) {
      return COMPLIANCE_CODE.filter(level => level.value === member.level)[0].label;
    }


}
