/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stacked-vertical-bar-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@swimlane/ngx-charts/release/bar-chart/bar-vertical-stacked.component.ngfactory";
import * as i3 from "@swimlane/ngx-charts/release/bar-chart/bar-vertical-stacked.component";
import * as i4 from "../../modals/modal-data/modal-data.component.ngfactory";
import * as i5 from "../../modals/modal-data/modal-data.component";
import * as i6 from "../../services/indicator.service";
import * as i7 from "../../../../../services/storage/cache.service";
import * as i8 from "../../modals/modal-details/modal-details.component.ngfactory";
import * as i9 from "../../modals/modal-details/modal-details.component";
import * as i10 from "../header/header.component.ngfactory";
import * as i11 from "../header/header.component";
import * as i12 from "@angular/common";
import * as i13 from "./stacked-vertical-bar-chart.component";
import * as i14 from "../../services/chart-registration.service";
import * as i15 from "../../services/chart-data-loader.service";
var styles_StackedVerticalBarChartComponent = [i0.styles];
var RenderType_StackedVerticalBarChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StackedVerticalBarChartComponent, data: {} });
export { RenderType_StackedVerticalBarChartComponent as RenderType_StackedVerticalBarChartComponent };
function View_StackedVerticalBarChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "chart-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ngx-charts-bar-vertical-stacked", [], null, null, null, i2.View_BarVerticalStackedComponent_0, i2.RenderType_BarVerticalStackedComponent)), i1.ɵdid(2, 4898816, null, 1, i3.BarVerticalStackedComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"], view: [1, "view"], scheme: [2, "scheme"], legend: [3, "legend"], xAxis: [4, "xAxis"], yAxis: [5, "yAxis"] }, null), i1.ɵqud(335544320, 2, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.view; var currVal_2 = _co.scheme; var currVal_3 = false; var currVal_4 = _co.axis.showXAxis; var currVal_5 = _co.axis.showYAxis; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_StackedVerticalBarChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NO DATA"]))], null, null); }
function View_StackedVerticalBarChartComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "boxLoader"]], null, null, null, null, null))], null, null); }
function View_StackedVerticalBarChartComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "modal-data", [], null, [[null, "closeMenu"], [null, "clearAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeMenu" === en)) {
        var pd_0 = (_co.closeMenu($event) !== false);
        ad = (pd_0 && ad);
    } if (("clearAction" === en)) {
        var pd_1 = (_co.clearAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ModalDataComponent_0, i4.RenderType_ModalDataComponent)), i1.ɵdid(1, 638976, null, 0, i5.ModalDataComponent, [i6.IndicatorService, i7.CacheService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], data: [2, "data"], modalAxis: [3, "modalAxis"], typeChartModal: [4, "typeChartModal"], filters: [5, "filters"], indicatorConfig: [6, "indicatorConfig"] }, { closeMenu: "closeMenu", clearAction: "clearAction" }), i1.ɵpod(2, { x: 0, y: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalConfig.modalId; var currVal_1 = _co.title; var currVal_2 = _co.data; var currVal_3 = _ck(_v, 2, 0, _co.axis.xAxisLabel, _co.axis.yAxisLabel); var currVal_4 = _co.indicatorConfig.type; var currVal_5 = _co.filters; var currVal_6 = _co.indicatorConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_StackedVerticalBarChartComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal-details", [], null, [[null, "clearAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearAction" === en)) {
        var pd_0 = (_co.clearAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ModalDetailsComponent_0, i8.RenderType_ModalDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i9.ModalDetailsComponent, [i6.IndicatorService, i7.CacheService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], modalComputedMethod: [2, "modalComputedMethod"] }, { clearAction: "clearAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalConfig.modalId; var currVal_1 = _co.title; var currVal_2 = _co.indicatorConfig.computedMethod; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_StackedVerticalBarChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { headerRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "chart-header", [], null, [[null, "menuAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuAction" === en)) {
        var pd_0 = (_co.actionClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_HeaderComponent_0, i10.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i11.HeaderComponent, [], { title: [0, "title"], modalConfig: [1, "modalConfig"], loader: [2, "loader"], noData: [3, "noData"], menuOpen: [4, "menuOpen"] }, { menuAction: "menuAction" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedVerticalBarChartComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedVerticalBarChartComponent_2)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedVerticalBarChartComponent_3)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedVerticalBarChartComponent_4)), i1.ɵdid(10, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedVerticalBarChartComponent_5)), i1.ɵdid(12, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.modalConfig; var currVal_2 = _co.loader; var currVal_3 = _co.noData; var currVal_4 = _co.menuOpen; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = (!_co.loader && !_co.noData); _ck(_v, 4, 0, currVal_5); var currVal_6 = (!_co.loader && _co.noData); _ck(_v, 6, 0, currVal_6); var currVal_7 = _co.loader; _ck(_v, 8, 0, currVal_7); var currVal_8 = ((_co.action == "donnees") && !_co.loader); _ck(_v, 10, 0, currVal_8); var currVal_9 = ((_co.action == "details") && !_co.loader); _ck(_v, 12, 0, currVal_9); }, null); }
export function View_StackedVerticalBarChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "stacked-vertical-bar-chart", [], null, null, null, View_StackedVerticalBarChartComponent_0, RenderType_StackedVerticalBarChartComponent)), i1.ɵdid(1, 9027584, null, 0, i13.StackedVerticalBarChartComponent, [i1.KeyValueDiffers, i7.CacheService, i14.ChartRegistration, i15.ChartDataLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StackedVerticalBarChartComponentNgFactory = i1.ɵccf("stacked-vertical-bar-chart", i13.StackedVerticalBarChartComponent, View_StackedVerticalBarChartComponent_Host_0, { data: "data", view: "view", header: "header", title: "title", modalConfig: "modalConfig", axis: "axis", filters: "filters", indicatorConfig: "indicatorConfig", menuOpen: "menuOpen", loader: "loader", noData: "noData", selectedEvent: "selectedEvent", selectedRoster: "selectedRoster", frequence: "frequence" }, { selectedChart: "selectedChart", user_action: "user_action" }, []);
export { StackedVerticalBarChartComponentNgFactory as StackedVerticalBarChartComponentNgFactory };
