<div id="not-found-component">
    <div class="logo-container">
        <img class="emalsys-logo" src="../assets/images/emalsys_hand_background_red.png" alt="Emalsys">
    </div>
    <div class="window-error">
        <i class="fa fa-frown-o custom-icon" aria-hidden="true"></i>
        <div class="infos-error">
            <h1 class="title-error">Page not found</h1>
            <p class="text-error">
                We're sorry, you don't have access to the page you requested.<br>
                We can't seem to find the page you're looking for.
                <a (click)="back()" >Please go back to the previous page.</a>
            </p>
        </div>
    </div>
</div>
