<!--Modal header-->
<div class="modal-header">
  <h4 class="modal-title">Create a Roster</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
</div>

<!--Modal body-->
<div class="modal-body">
    <div class="roster-properties">
        <label for="roster-name">Roster name *</label>
        <input id="roster-name" type="text" [(ngModel)]="roster.name"/>
        <label for="roster-description">Description</label>
        <textarea id="roster-description" [(ngModel)]="roster.description"></textarea>
        <label for="roster-logo">Logo</label>
        <input id="roster-logo" type="file" (change)="fileChange($event)"/>
    </div>
</div>
<div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
<!--Modal footer-->
<div class="modal-footer">
    <button type="submit" class="btn emalsys_button green" (click)="createRoster()" [disabled]="!roster.name">Create</button>
</div>
