import { CacheService } from './../../../../services/storage/cache.service';
import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { Title             } from '@angular/platform-browser';
// Services
import { ProfileService } from '../../../../services/api/profile/profile.api';
import { LoggerService  } from '../../../../services/utils/logger.service';
import { ToastService   } from "../../../../services/utils/toast.service";
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { OrganisationInterface } from '../../../model/organisation.interface';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { Router } from '@angular/router';


@Component({
    selector   : 'profile',
    templateUrl: './profile.component.html',
    styleUrls  : ['./profile.component.scss']
})


export class ProfileComponent implements OnInit {
    public profile: any;
    public picture: any;

    constructor(
        private titleService: Title,
        public _profileService: ProfileService,
        public _toastService: ToastService,
        private _loggerService: LoggerService,
        private _profileDispatcher: ProfileDispatcherService,
        private _cacheService: CacheService,
        private _organisationDispatcher: OrganisationDispatcherService,
        private _navigation: NavigationService,
        private _router: Router
    ) {}

    ngOnInit() {
        this.titleService.setTitle("Profile");

        // subscribe to the profile updates
        this._profileDispatcher.getProfile().subscribe(res => {
            this.profile = res;
        }, err => {
            this._loggerService.error("ERROR getting profile: ", err);
            this._toastService.show("retrievingProfileToast", "show", 3000);
        });

        // We need to fetch the organisation from the cache if we "land" on this
        // page by url
        let currentOrganisation = this._cacheService.get(OrganisationInterface.CACHE.CURRENT_ORGANISATION);
        this._organisationDispatcher.selectOrganisation(currentOrganisation);
        // subscribe to the identity updates
        this._profileService.getProfileIdentity().subscribe(res => {
            this.picture = res.picture;
        });

        // get profile from api
        this._profileService.getProfile().subscribe();
    }

    goToAllData() {
        this._router.navigateByUrl("/responder/profile/alldata");
    }
}
