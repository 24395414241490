import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrganisationDispatcherService } from '../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../services/api/profile/profile.dispatcher';
import { CacheService } from '../../../../services/storage/cache.service';
import { EventListenerService } from '../../../../services/utils/event-listener.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { RightService } from '../../../../services/utils/right.service';
import { ToastService } from '../../../../services/utils/toast.service';
import { COMPLIANCE_CODE, ORGANISATION_MANAGER_RIGHTS_LEVEL } from '../../../app.constants';
import { OrganisationInterface } from '../../../model/organisation.interface';
import { UserInterface } from '../../../model/user.interface';
import { InitializedDashboard } from '../../../model/utils/initialization-model.interface';
import { OrganisationCompliancesService } from './../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api';
import { CachedOrganisationService } from './../../../../services/api/organisation/organisation.cacher';
import { ProfileService } from './../../../../services/api/profile/profile.api';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})


export class SettingsComponent implements OnInit, OnDestroy {

  @Output() onDelete = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  public loading = false;
  public organisation: any = {};
  public organisations: any;
  public managedOrganisations = new Array<OrganisationInterface>();
  /** Need to understand if this should be the organisation profile or user's */
  public profile: any = {};
  public logo: File;
  public thumbnailLogo: string;
  public editingDescription = false;
  public showOptions = false;
  public isManagerWebsite: any;
  public modalRef;

  public organisationCompliances: any;
  public editActive: number = null;
  public addActive = false;

  public newLevelRequirement = 0;
  public newTitleRequirement;
  public editLevelRequirement;
  public editTitleRequirement;

  // Not so much necessary becausenot Super Admins
  // can't actually access to organisation settings...
  public canDeleteOrganisation = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private titleService: Title,
    public _navigationService: NavigationService,
    private _organisationService: CachedOrganisationService,
    private _loggerService: LoggerService,
    private _eventListenerService: EventListenerService,
    private _cacheService: CacheService,
    private _modalService: NgbModal,
    private _toastService: ToastService,
    private _activatedRouteService: ActivatedRoute,
    private _profileDispatcher: ProfileDispatcherService,
    private _rightService: RightService,
    private _organisationCompliances: OrganisationCompliancesService,
    private _organisationDispatcher: OrganisationDispatcherService,
    private _profileService: ProfileService
  ) {
    this.titleService.setTitle("Settings");
    this.organisation = this._cacheService.get(CacheService.CURRENT_ORGANISATION);
    this.isManagerWebsite = this._cacheService.get(CacheService.ISMANAGER_WEBSITE);
  }

  ngOnInit() {

    const organisation = this._organisationDispatcher.getCurrentlyEditingOrganisation().subscribe(
      (res: OrganisationInterface) => this.organisation = res
    );

    const initialization = combineLatest(
      this._organisationDispatcher.getSelectedOrganisation(),
      this._profileDispatcher.getProfile()
    ).pipe(
      map(x => {
        return <InitializedDashboard>{
          user: x[1] as UserInterface,
          organisation: x[0] as OrganisationInterface
        };
      }),
      catchError(err => of(err))
    ).subscribe(
      (res: InitializedDashboard) => {
        this.organisation = res.organisation;
        this.getOrganisationCompliances(this.organisation.id);
        this.profile = res.user;
        this.canDeleteOrganisation =
          this._rightService.isOrganisationManagerRight(res.user, res.organisation, ORGANISATION_MANAGER_RIGHTS_LEVEL.SuperAdmin);
      },
      (err) => {
        this._loggerService.log("Error getting profile OR orga");
      }
    );

    this.getUserManagedOrganisations();

    this.subscriptions.push(initialization, organisation);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  selectedOrganisation(organisation) {
    if (organisation && this.organisation.id === Number(organisation.id)) {
      return (true);
    } else {
      return false;
    }
  }

  open(deleteContent) {
    this._modalService.open(deleteContent, { backdrop: 'static' }).result.then(
      result => {
        if (String(result) === 'cancel-click') {
          this.showToastDeleteCanceled();
        } else if (String(result) === 'delete-click') {
          this.deleteOrganisation();
          this._navigationService.goToSettings();
        }
      })
      .catch(error => { this._loggerService.log('error :', error); });
  }

  changeLogo(logoContent) {
    this._modalService.open(logoContent, { windowClass: 'emalsys-modal', backdrop: 'static' })
      .result.then(
        result => {
          this.editOrganisation();

        },
        error => {
          // TODO implement
        }
      );
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.logo = fileList[0];
    }
    this.readLogo();
  }

  readLogo() {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.thumbnailLogo = e.target.result;
    };
    reader.readAsDataURL(this.logo);
  }

  // Changed copies from switch Org (banner) - TODO: adapt for settings
  // Beggining ..............................

  getUserManagedOrganisations() {
    this.managedOrganisations = [];
    this._organisationService.getUserOrganisations().subscribe(res => {
      this.organisations = res;
      this.organisations.forEach(org => {
        if (this.isManager(org.id)) {
          this.managedOrganisations.push(org);
        }
      });
      this._cacheService.set(CacheService.ORGANISATIONS, this.organisations);
    });
  }

  setOrganisation(organisation) {
    this.organisation = organisation;
    // this._cacheService.set(CacheService.CURRENT_ORGANISATION, this.organisation);
    // this.ngOnInit();
    // this._organisationCompliances.refreshOrganisationCompliances(organisation.id);
    this.getOrganisationCompliances(organisation.id);

    if (this.isManager(this.organisation.id)) {
      // if(this.status == "responder"){
      //   this.titleService.setTitle(this.organisation.name+" - Responder" );
      // }else{
      //   this.titleService.setTitle(this.organisation.name+" - Manager" );
      // }
      // this._navigationService.goToOrganisation(this.organisation.id,this.status)
      // this._navigationService.goToSettings();
    } else {
      this.isManagerWebsite = 0;
      this._cacheService.set(CacheService.ISMANAGER_WEBSITE, this.isManagerWebsite);
      // this.status = "responder";
      this.titleService.setTitle(this.organisation.name + " - Responder");
      // this._navigationService.goToOrganisation(this.organisation.id,this.status)
    }
  }

  isManager(organisationId) {
    let organisation_managers = [];
    let user = this._cacheService.get(CacheService.USER);
    if (user) {
      organisation_managers = user.organisation_managers;
      for (let i = 0; i < organisation_managers.length; i++) {
        if (organisation_managers[i].organisation.id === organisationId) {
          return true;
        }
      }
      let roster_managers = [];
      roster_managers = user.roster_managers;
      for (let i = 0; i < roster_managers.length; i++) {
        if (roster_managers[i].roster.organisation.id === organisationId) {
          return true;
        }
      }
    }
    return false;
  }

  // ........................................ ending

  editOrganisation() {
    this.loading = true;
    let body = new FormData();
    body.append('logoEdit', this.logo);
    body.append('organisationEdit', JSON.stringify(this.organisation));
    body.append('profileEdit', JSON.stringify(this.organisation.organisation_profile));
    this._organisationService.updateCurrentOrganisation(this.organisation.id, body)
      .subscribe(res => {
        // I don't know why the result is an array
        this.organisation = res[0];
        this.getUserManagedOrganisations();
        this.onEdit.emit("Organisation edited");
        this._eventListenerService.publish("organisation", true);
        // TODO: check if organisation need to be published
        this.loading = false;
        this.showToastChangesSaved();
      }, error => {
        this.loading = false;
        this.showToastErrorChanges();
      });
  }

  deleteOrganisation() {
    this.loading = true;
    this._organisationService.deleteOrganisation(this.organisation.id).subscribe(res => {
      this.onDelete.emit("Organisation deleted");
      this.loading = false;
      this._navigationService.goToPublic();
    });
    // this._activeModalService.dismiss('delete');
  }

  addOrganisationCompliance(title, level) {
    // using structuring concept
    let body = { title, level };

    this._organisationCompliances.addOrganisationCompliance(this.organisation.id, body).subscribe(res => {
      this.getOrganisationCompliances(this.organisation.id);
      this.addActive = false;
      this.newTitleRequirement = null;
      this.newLevelRequirement = null;
    });
  }

  editOrganisationCompliance(title, level, requirementId) {
    // using structuring concept
    let body = { title, level };

    this._organisationCompliances.updateOrganisationCompliance(requirementId, body).subscribe(res => {
      this.getOrganisationCompliances(this.organisation.id);
    });
  }

  deleteOrganisationCompliance(requirementId) {
    this._organisationCompliances.removeOrganisationCompliance(requirementId).subscribe(res => {
      this.getOrganisationCompliances(this.organisation.id);
    });
  }

  getOrganisationCompliances(idOrganisation) {
    this._organisationCompliances.getOrganisationCompliances(idOrganisation).subscribe(res => {
      this.organisationCompliances = res;
    });
  }

  setLevel(member) {
    return COMPLIANCE_CODE.filter(level => level.value === Number(member.level))[0].label;
  }

  enableDescriptionEdit() {
    this.editingDescription = true;
  }

  saveChanges() {
    this.editOrganisation();
    this.editingDescription = false;
  }

  showToastChangesSaved() {
    this._toastService.show("snackbarNotif", "show", 3000);
  }

  showToastDeleteCanceled() {
    this._toastService.show("snackbarNotifCancel", "show", 3000);
  }

  showToastErrorChanges() {
    this._toastService.show("snackbarNotifError", "show", 3000);
  }
}
