import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../../../services/api/profile/profile.api';
import { tap, switchMap, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-gdpr-cookie-acceptation-modal',
  templateUrl: './gdpr-cookie-acceptation-modal.component.html',
  styleUrls: ['./gdpr-cookie-acceptation-modal.component.scss']
})
export class GdprCookieAcceptationModalComponent {
    // @Output() onAccept = new EventEmitter<any>();
    public loading = false;
    constructor(
        public activeModal: NgbActiveModal,
        public _profileService: ProfileService
    ) { }

    acceptGDPR() {
        this.loading = true;
        this._profileService.GdprAcceptation()
        .pipe(
            catchError((e) => {
                this.loading = false;
                throw e;
            }),
            switchMap(() => {
                return this._profileService.CookieAcceptation();
            }),
            tap(() => {
                this.loading = false;
                this.activeModal.close(true);
            })
        )
        .subscribe();
    }
}
