import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roster-preloader-infos',
  templateUrl: './roster-preloader-infos.component.html',
  styleUrls: ['./roster-preloader-infos.component.scss']
})
export class RosterPreloaderInfosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
