/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./administration-rights.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./administration-rights.component";
var styles_AdministrationRightsComponent = [i0.styles];
var RenderType_AdministrationRightsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdministrationRightsComponent, data: {} });
export { RenderType_AdministrationRightsComponent as RenderType_AdministrationRightsComponent };
export function View_AdministrationRightsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["This; is; the; rights; manegement; page in progress; ...\n"]))], null, null); }
export function View_AdministrationRightsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "administration-rights", [], null, null, null, View_AdministrationRightsComponent_0, RenderType_AdministrationRightsComponent)), i1.ɵdid(1, 49152, null, 0, i2.AdministrationRightsComponent, [], null, null)], null, null); }
var AdministrationRightsComponentNgFactory = i1.ɵccf("administration-rights", i2.AdministrationRightsComponent, View_AdministrationRightsComponent_Host_0, {}, {}, []);
export { AdministrationRightsComponentNgFactory as AdministrationRightsComponentNgFactory };
