import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagsService } from '../../../../../../services/api/tag/tag.api';
import { ToastService } from "../../../../../../services/utils/toast.service";
import { TagDispatcherService } from '../../../../../../services/api/tag/tag.dispatcher';
import { TagInterface } from '../../../../../model/tag.interface';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  // input which contains the id of the roster selected
  @Input() public idRosterSelected;

  // Input which contains the tab IDs of the members selected
  @Input() public tabIdMembersSelected;

  // Locales variables for RosterTags
  public tags: any = [];
  // private numberOfTags = 0;
  public newTagName: string;

  // Locales variables for RosterTagsMembers
  public rosterTagsMembersSelected: any = [];

  // Others variables
  public loading;

  constructor(
    public activeModal: NgbActiveModal,
    private _tagsService: TagsService,
    private _toastService: ToastService,
    private _tagDispatcher: TagDispatcherService
    ) {
  }

  ngOnInit() {
    this.loading = true;
    this.getAllRosterTags();

    this._tagDispatcher.getRosterTags().subscribe(
      (res: TagInterface[]) => {
        this.tags = res;
      }
    );
  }

  // Call the getAllRosterTags function from the TagsService and save the result in tags variable
  // TODO: check the relevance of this function
  getAllRosterTags() {
    this.loading = true;
    // this._tagsService.getAllRosterTags(this.idRosterSelected).subscribe(res => {
    //   let response = res;
    //   this.tags = [];
    //   for (let tag of Object.keys(response)) {
    //     // this.numberOfTags++;
    //     this.tags.push({'id': response[tag].id, 'name': response[tag].name, 'checked': 0});
    //   }
    //   this.loading = false;
    // });
  }

  // Call the aaddNewTagToRoster from the TagService and reload the list of tags
  addNewTagToRoster() {
    this._tagsService.addNewTagToRoster(this.idRosterSelected, this.newTagName).subscribe(() => {
      this.tags.length = 0;
      this.getAllRosterTags();
      this.newTagName = "";
      this.showToastRosterTagCreate();
    },
    () => {
      this.showToastRosterTagError();
    });
  }

  // Call the deleteNewTagToRoster from the TagService and reload the list of tags
  // deleteTagRoster(tagId) {
  //   this._tagsService.deleteTagRoster(tagId, 0).subscribe(() => {
  //     this.tags.length = 0;
  //     this.getAllRosterTags();
  //     this.showToastRosterTagDeleted();
  //   },
  //   () => {
  //     this.showToastRosterTagError();
  //   });
  // }

  showToastRosterTagDeleted() {
    this._toastService.show("rosterTagDeleteToast", "show", 3000);
  }

  showToastRosterTagError() {
    this._toastService.show("rosterTagErrorToast", "show", 3000);
  }

  showToastRosterTagCreate() {
    this._toastService.show("rosterTagCreateToast", "show", 3000);
  }

}
