import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class UpdateService {

  constructor(private swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      swUpdate.checkForUpdate();
      swUpdate.available.subscribe(event => {
        this.loadNewVersion();
      });
    }
  }

  private loadNewVersion() {
    let response = confirm('A new version of the application is available, do you want to load it?');
    if (response) {
      this.swUpdate.activateUpdate().then(() => {
        document.location.reload();
      });
    }
  }
}
