import { ApiCacherService } from './../utils/api-cacher.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserInterface } from '../../app/model/user.interface';
import { GdprCookieAcceptationModalComponent } from '../../app/modules/public/modals/gdpr-cookie-acceptation-modal/gdpr-cookie-acceptation-modal.component';
import { environment, URL_API_EMALSYS } from '../../environments/environment';
import { HttpService } from '../api/http.service';
import { ProfileService } from '../api/profile/profile.api';
import { UserService } from '../api/user.service';
import { CacheService } from '../storage/cache.service';
import { EnvironmentChecker } from '../utils/environment-checker.service';
import { LoggerService } from '../utils/logger.service';
import { NavigationService } from '../utils/navigation.service';
import { WSSEService } from './wsse.service';

export interface LoginDataInterface {
    user: UserInterface;
    profile: any;
    gdprAccepted?: boolean;
}

@Injectable()
export class LoginService {
    static readonly GDPR_ERROR = 'gdpr_error';

    private user: UserInterface;

    constructor(
        public http: HttpClient,
        private _httpService: HttpService,
        public _wsseService: WSSEService,
        public _navigationService: NavigationService,
        public logger: LoggerService,
        public cache: CacheService,
        public apiCacher: ApiCacherService,
        public _profileService: ProfileService,
        public _userService: UserService,
        private _modalService: NgbModal,
    ) {}

    getUser() {
        return this.user;
    }

    cacheUser() {
        let optionCache = {
            timeout: 172800 // 2 days in second
        };
        this.cache.set(CacheService.USER, this.user, optionCache);
    }

    // ------------------------------------------------------------------------//
    // ------------------------------LOGIN SYSTEM------------------------------//
    // ------------------------------------------------------------------------//

    // Request to the API to get the salt corresponding to an email
    requestSalt(email) {
        this._wsseService.setEmail(email);

        return this.http.get(URL_API_EMALSYS + '/salt?email=' + email, {});
    }

    checkCredentials() {
        let options = this._httpService.createRequestOptions();
        return this.http.get<any>(URL_API_EMALSYS + '/check', options);
    }

    // ------------------------------------------------------------------------//
    // ------------------------------RESET PASSWORD----------------------------//
    // ------------------------------------------------------------------------//

    sendEmail(email: string) {
        let headers = new HttpHeaders({ 'x-wsse': undefined });
        let body = { 'email': email };
        let options = { headers: headers };

        return this.http.put(URL_API_EMALSYS + '/reset', body, options);
    }

    login(email: string, password: string) {
        let saltedPassword = '';

        return this.requestSalt(email)
        // login
        .pipe(
            switchMap ( (salt) => {
                saltedPassword = this._wsseService.saltPassword(salt, password);
                return this.checkCredentials();
            })
        )
        // geolocation
        .pipe(
            switchMap (userFromApi => {
                let user = new UserInterface();
                user.id = userFromApi.id;
                user.email = email;
                user.saltedPassword = saltedPassword;
                user.nonAnsweredEvents = userFromApi.nonAnsweredEvents;
                user.invitedRosterMembers = userFromApi.invitedRosterMembers;
                user.deployments = userFromApi.deployments;
                this.user = user;
                this.cacheUser();
                // check geolocation authorization
                if (window.navigator.geolocation) {
                    window.navigator.geolocation.getCurrentPosition(
                        position => {
                            this._userService.setLocation(position).subscribe(res => {
                                this.logger.log("Geolocation success.");
                            });
                        },
                        error => {
                            switch (error.code) {
                                case error.PERMISSION_DENIED:
                                this.logger.log("User denied the request for Geolocation.");
                                break;
                                case error.POSITION_UNAVAILABLE:
                                this.logger.log("Location information is unavailable.");
                                break;
                                case error.TIMEOUT:
                                this.logger.log("The request to get user location timed out.");
                                break;
                            }
                    });
                } else {
                    this.logger.log("Geolocation is not supported by this browser.");
                    // TODO: ask for geolocation instead
                }

                // Take the profile, finally...
                return this._profileService.getProfile();
            })
        )
        // set profile information
        .pipe(
            map ((userFromApi: any) => {
                const user = this.user;
                user.firstname = userFromApi.firstname;
                user.lastname = userFromApi.lastname;
                user.end_unavailability = userFromApi.end_unavailability;
                user.organisation_managers = [];
                user.organisation_members = [];
                user.roster_managers = [];
                // TODO: @Odysseas the tasks below should be done on the backend side
                for (let i = 0; i < userFromApi.organisation_managers.length; i++) {
                    if (userFromApi.organisation_managers[i].state !== 2) {
                        user.organisation_managers.push(userFromApi.organisation_managers[i]);
                    }
                }
                for (let j = 0; j < userFromApi.organisation_members.length; j++) {
                    if (userFromApi.organisation_members[j].state !== 2) {
                        user.organisation_members.push(userFromApi.organisation_members[j]);
                    }
                }
                for (let i = 0; i < userFromApi.roster_managers.length; i++) {
                    if (userFromApi.roster_managers[i].state !== 2) {
                        user.roster_managers.push(userFromApi.roster_managers[i]);
                    }
                }

                user.voters = this.rightAccessDefinition(
                    user.organisation_members.length,
                    user.organisation_managers.length,
                    user.roster_managers.length
                );

                return <LoginDataInterface>{
                    user: user,
                    profile: userFromApi.profile,
                };
            }),
            switchMap((data: LoginDataInterface) => {
                return this._profileService.getProfileIdentity().pipe(
                    map( identity => {
                        data.user.picture = identity.picture;
                        this.user = data.user;
                        return data;
                    })
                );
            }),
        )
        // check GDPR
        .pipe(
            switchMap((data: LoginDataInterface) => {
                if (!data.profile.cookie_acceptation || !data.profile.gdpr_acceptation) {
                    return this.openGdprCookiesAcceptationModal(this.getUser(), data.profile);
                }
                return of(data);
            })
        );
    }

    logout() {
        this.cache.clear();
        EnvironmentChecker.ifAdpc(
            () => window.location.href = environment.routes.adpc.home,
        );
        EnvironmentChecker.ifEmalsys(
            () => this._navigationService.goToPublic()
        );
    }

    rightAccessDefinition(nbrOrganisationBelonged, nbrOrganisationManaged, nbrRosterManaged) {
        let voters: any = {};
        if (nbrOrganisationBelonged) {
            voters.module_responder_access = true;
        }
        if (nbrOrganisationManaged || nbrRosterManaged) {
            voters.module_responder_access = true;
            voters.module_manager_access = true;
        }
        //   if (item === 'ROLE_ADMIN') {
        //     voters.module_responder_access = true;
        //     voters.module_admin_access = true;
        //   }
        return voters;
    }

    private openGdprCookiesAcceptationModal(user, profile) {
        let modalRef = this._modalService.open(GdprCookieAcceptationModalComponent, { windowClass: 'emalsys-modal', backdrop: 'static' });
        return from(modalRef.result)
        .pipe(
            catchError((e) => {
                // remove profile from cache if GDPR is not accepted
                this.apiCacher.removeIfPresent(ProfileService.USER_PROFILE);
                throw LoginService.GDPR_ERROR;
            }),
            map((dataFromGDPRModal) => {
                if (dataFromGDPRModal !== true) {
                    return null;
                }
                return <LoginDataInterface>{
                    user: user,
                    profile: profile,
                    gdprAccepted: true
                };
            })
        );
    }

}
