<div *ngIf="loadedRosters" id="dashboard-rosters-component">
  <!-- (must send to page instead of popup)-->
  <ng-container *ngIf="amIOrganisationManager">
    <div class="left-divide">
      <button class="roster-card" [ngClass]="{'selected' : selected == NO_ROSTER}"
        (click)="selected = NO_ROSTER; changeRoster();">
        <div class="roster-image">
          <div class="image-icon">
            <i class="fa fa-clone"></i>
          </div>
        </div>
        <h3 class="roster-name">All members </h3>
      </button>
    </div>
    <span class="divide"></span>
  </ng-container>
  <div class="right-divide">
    <div class="roster-card" *ngFor="let roster of rosters" [ngClass]="{'selected' : selected === roster.id}"
      (click)="selected = roster.id; changeRoster(roster)">
      <!-- <div class="edit-button-container"> -->
      <div *ngIf="canEditRoster[roster.id] && selected === roster.id" class="edit-roster-button"
        (click)="handleClickEditRoster($event, roster.id)"><i class="fa fa-cog" aria-hidden="true"></i></div>
      <!-- </div> -->

      <div class="roster-image">
        <img class="image-logo"
          [src]="roster.roster_profile.browser_url ? roster.roster_profile.browser_url : 'assets/images/default_roster.png'" />
      </div>
      <h3 class="roster-name">{{roster.name | textSnipped:12}}</h3>

    </div>
    <button *ngIf="canAddNewRoster" class="roster-card new-roster" (click)="createRoster()">
      <div class="image-logo">
        <i class="fa fa-plus"></i>
      </div>
      <h3 class="roster-name">Create Roster</h3>
    </button>


    <!-- <button (click)="todoDelete()">pprngoewr</button> -->
  </div>
  <div id="organisationDeleteToast">The organisation has been deleted</div>
  <div id="organisationEditToast">The organisation has been edited</div>
  <div id="rosterDeleteToast">The roster has been deleted</div>
  <div id="rosterEditToast">The roster has been edited</div>
</div>