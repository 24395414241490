/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roster.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./roster-members/roster-members.component.ngfactory";
import * as i3 from "./roster-members/roster-members.component";
import * as i4 from "../../../../../services/utils/logger.service";
import * as i5 from "../../../../../services/api/roster/dependencies/membership/roster-membership.api";
import * as i6 from "@angular/common";
import * as i7 from "./roster-infos/roster-infos.component.ngfactory";
import * as i8 from "./roster-infos/roster-infos.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../../../../services/api/roster/roster.cached";
import * as i11 from "@angular/router";
import * as i12 from "../../../../../services/utils/toast.service";
import * as i13 from "./roster-compliances/roster-compliances.component.ngfactory";
import * as i14 from "./roster-compliances/roster-compliances.component";
import * as i15 from "../../../../../services/api/document.service";
import * as i16 from "../../../../../services/api/roster/dependencies/compliances/roster-compliances.api";
import * as i17 from "../../../../../services/api/organisation/dependencies/compliances/organisation-compliances.api";
import * as i18 from "@ng-bootstrap/ng-bootstrap";
import * as i19 from "./roster-preloader/roster-preloader-infos/roster-preloader-infos.component.ngfactory";
import * as i20 from "./roster-preloader/roster-preloader-infos/roster-preloader-infos.component";
import * as i21 from "./roster-preloader/roster-preloader-compliances/roster-preloader-compliances.component.ngfactory";
import * as i22 from "./roster-preloader/roster-preloader-compliances/roster-preloader-compliances.component";
import * as i23 from "./roster-preloader/roster-preloader-members/roster-preloader-members.component.ngfactory";
import * as i24 from "./roster-preloader/roster-preloader-members/roster-preloader-members.component";
import * as i25 from "./roster.component";
var styles_RosterComponent = [i0.styles];
var RenderType_RosterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RosterComponent, data: {} });
export { RenderType_RosterComponent as RenderType_RosterComponent };
function View_RosterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "roster-members", [], null, null, null, i2.View_RosterMembersComponent_0, i2.RenderType_RosterMembersComponent)), i1.ɵdid(1, 573440, null, 0, i3.RosterMembersComponent, [i4.LoggerService, i5.RosterMembershipService], { rosterId: [0, "rosterId"], rosterStatus: [1, "rosterStatus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rosterId; var currVal_1 = _co.rosterStatus; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_RosterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You have been invited to join this roster. "]))], null, null); }
function View_RosterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your request to join this roster has been sent. "]))], null, null); }
function View_RosterComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You are frozen in this roster. "]))], null, null); }
function View_RosterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "shadow"], ["id", "not-in-roster"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are not in this roster!"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_4)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_5)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_6)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rosterStatus === "invited"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.rosterStatus === "request"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.rosterStatus === "frozen"); _ck(_v, 8, 0, currVal_2); }, null); }
function View_RosterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "roster-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "roster-infos", [], null, null, null, i7.View_RosterInfosComponent_0, i7.RenderType_RosterInfosComponent)), i1.ɵdid(2, 573440, null, 0, i8.RosterInfosComponent, [i9.Title, i10.RosterService, i4.LoggerService, i11.Router, i12.ToastService, i5.RosterMembershipService], { rosterId: [0, "rosterId"], rosterStatus: [1, "rosterStatus"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "roster-compliances", [], null, null, null, i13.View_RosterCompliancesComponent_0, i13.RenderType_RosterCompliancesComponent)), i1.ɵdid(4, 638976, null, 0, i14.RosterCompliancesComponent, [i15.DocumentService, i16.RosterCompliancesService, i17.OrganisationCompliancesService, i18.NgbModal, i4.LoggerService, i12.ToastService], { rosterId: [0, "rosterId"], rosterStatus: [1, "rosterStatus"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_3)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rosterId; var currVal_1 = _co.rosterStatus; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.rosterId; var currVal_3 = _co.rosterStatus; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = (_co.rosterStatus === "member"); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.rosterStatus != "member"); _ck(_v, 8, 0, currVal_5); }, null); }
function View_RosterComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "roster-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "roster-preloader-infos", [], null, null, null, i19.View_RosterPreloaderInfosComponent_0, i19.RenderType_RosterPreloaderInfosComponent)), i1.ɵdid(2, 114688, null, 0, i20.RosterPreloaderInfosComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "roster-preloader-compliances", [], null, null, null, i21.View_RosterPreloaderCompliancesComponent_0, i21.RenderType_RosterPreloaderCompliancesComponent)), i1.ɵdid(4, 114688, null, 0, i22.RosterPreloaderCompliancesComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "roster-preloader-members", [], null, null, null, i23.View_RosterPreloaderMembersComponent_0, i23.RenderType_RosterPreloaderMembersComponent)), i1.ɵdid(6, 114688, null, 0, i24.RosterPreloaderMembersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_RosterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RosterComponent_7)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rosterId && _co.rosterStatus); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.rosterId || !_co.rosterStatus); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RosterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "roster", [], null, null, null, View_RosterComponent_0, RenderType_RosterComponent)), i1.ɵdid(1, 49152, null, 0, i25.RosterComponent, [i11.ActivatedRoute], null, null)], null, null); }
var RosterComponentNgFactory = i1.ɵccf("roster", i25.RosterComponent, View_RosterComponent_Host_0, {}, {}, []);
export { RosterComponentNgFactory as RosterComponentNgFactory };
