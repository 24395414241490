import { Selection } from './selection.class';

export class SelectionsArray<Selection> extends Array<Selection> {

  private constructor(items?: Array<Selection>) {
    super(...items);
  }

  static create<Selection>(): SelectionsArray<Selection> {
    return Object.create(SelectionsArray.prototype);
  }



}
