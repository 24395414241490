import { OrganisationApiService } from './organisation.api';
import { Injectable } from '@angular/core';
import { Dispatcher } from '../../dispatcher.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrganisationInterface } from '../../../app/model/organisation.interface';

@Injectable()
export class OrganisationDispatcherService extends Dispatcher {

    private organisations$ = new BehaviorSubject<OrganisationInterface[]>(null);
    private userOrganisations$ = new BehaviorSubject<OrganisationInterface[]>(null);
    private selectedOrganisation$ = new BehaviorSubject<OrganisationInterface>(null);
    private currentlyEditingOrganisation$ = new BehaviorSubject<OrganisationInterface>(null);

    /**
     * @param organisation OrganisationInterface or number (for the id)
     */
    public selectOrganisation(organisation: OrganisationInterface | number) {
        if ( typeof organisation === 'number') {
            organisation = this.organisations$.getValue().find(x => x.id === organisation);
        }

        if (organisation) {
            this.selectedOrganisation$.next(organisation);
        }
    }

    public getSelectedOrganisationScalar() {
        return this.selectedOrganisation$.getValue();
    }
    public getSelectedOrganisation() {
        return this.pipeNotNull(this.selectedOrganisation$);
    }

    public getScalarSelectedOrganisation() {
        return this.selectedOrganisation$.getValue();
    }

    public getOrganisations() {
        return this.pipeNotNull(this.organisations$);
    }

    public dispatchOrganisations(organisations: OrganisationInterface[]) {
        this.organisations$.next(organisations);
    }

    public getUserOrganisations() {
        return this.pipeNotNull(this.userOrganisations$);
    }

    public dispatchUserOrganisations(organisations: OrganisationInterface[]) {
        this.userOrganisations$.next(organisations);
    }

    public getCurrentlyEditingOrganisation(): Observable<OrganisationInterface> {
        return this.pipeNotNull(this.currentlyEditingOrganisation$);
    }
    public setCurrentlyEditingOrganisation(organisation: OrganisationInterface) {
        this.currentlyEditingOrganisation$.next(organisation);
    }
}
