<div id="deployment-alert-container" *ngIf="displayEventDashboard">
  <div class="alert-flex-container">

    <alerts-preloader-map *ngIf="!loadedAlerts" class="alert-flex-map-item shadow"></alerts-preloader-map>
    <alerts-manager-map *ngIf="loadedAlerts" class="alert-flex-map-item shadow"
      (onChangeEvent)='handleChangeEvent($event)' #eventmap>
    </alerts-manager-map>

    <alerts-preloader-informations *ngIf="!loadedAlerts" class="alert-flex-information-item shadow">
    </alerts-preloader-informations>
    <alerts-manager-informations *ngIf="loadedAlerts" class="alert-flex-information-item shadow"
      (onCatchClickSave)="updateAlert($event)" (onCatchClickDelete)="deleteAlert($event)"></alerts-manager-informations>
  </div>

  <alerts-preloader-search *ngIf="!loadedMembers" class="alerts-component-search shadow"></alerts-preloader-search>
  <app-search *ngIf="loadedMembers" class="alerts-component-search shadow" [page]="'alerts'"
    (filteredMembers)="setFilteredMembers($event)">
  </app-search>

  <!-- The eventmap has been forwarded because we need the setSelectedAlert() method in order to update the list of members
  TODO? : refactor the code to get rid of eventQuery and alertInfo -->
  <alerts-preloader-members *ngIf="!loadedMembers" class="alerts-component-search shadow"></alerts-preloader-members>

  <dashboard-members *ngIf="loadedMembers" class="alerts-component-members shadow" [organisation]='this.organisation'
    [selected]='selected' [members]='filteredMembers' [page]="'alerts'"></dashboard-members>
</div>

<div *ngIf="organisation">
  <div id="no-deployment-alert-container" *ngIf="loadedAlerts && !displayEventDashboard">
    <div class="no-alert-container shadow">
      <h2 class="no-alert-title">There are no events created.
        <span *ngIf="canCreateNewEvents">Do you want to create one?</span>
      </h2>
      <button *ngIf="canCreateNewEvents" class="btn emalsys_button green margin-b margin-r"
        (click)="handleCreateEvent()">Create an event</button>
    </div>
  </div>

  <div class="empty" *ngIf="loadedAlerts && !displayEventDashboard">
    <img src="../../../../../assets/images/not-yet-field.png" />
    <h2>This section is empty</h2>
  </div>
</div>

<div *ngIf="!loadedMembers">
  <div class="alert-flex-container">
    <!-- <alerts-preloader-map class="alert-flex-map-item shadow"></alerts-preloader-map> -->
    <!-- <alerts-preloader-informations class="alert-flex-information-item shadow"></alerts-preloader-informations> -->
  </div>
  <!-- <alerts-preloader-search *ngIf="!loadedMembers" class="alerts-component-search shadow"></alerts-preloader-search>
  <alerts-preloader-members *ngIf="!loadedMembers" class="alerts-component-search shadow"></alerts-preloader-members> -->
</div>


<div id="eventDeleteToast">Event deleted</div>
<div id="eventUpdateToast">Event edited</div>
<div id="eventCreateToast">Event created</div>

<div id="deploymentOfferCancelToast">Deployment offer cancelled</div>
<div id="noAvailabilitiesToast">No availability set for this responder</div>
<div id="responderDeployedToast">Member(s) deployed</div>

<div id="eventMemberAddToast">Member(s) added</div>

<div id="errorGettingEventsToast">Failed to get the events, please retry later</div>


<div class="add-button">

  <button class="parent" [ngClass]="{ 'clicked' : adding }" (click)="currentlyAdding()">
    <div class="plus" *ngIf="!adding"> <i class="fa fa-plus"></i> </div>
    <div class="plus" *ngIf="adding"> <i class="fa fa-times"></i> </div>
  </button>

  <div class="children" *ngIf="adding">

    <button class="add-to-alert" (click)="handleAddMember(eventmap.selectedAlert); adding=false;">
      <div class="plus">
        <i class="fa fa-user-plus"></i>
        Add members
      </div>
    </button>

    <button class="new-alert" (click)="handleCreateEvent(); adding=false;">
      <div class="plus">
        <i class="fa fa-plus"></i>
        Create new event
      </div>
    </button>

  </div>

</div>
