import { Component } from '@angular/core';

// Service
import { SettingsService   } from '../../../../../services/api/settings-service';
import { LoggerService     } from '../../../../../services/utils/logger.service';
import { NavigationService } from '../../../../../services/utils/navigation.service';
import { LoginService      } from '../../../../../services/security/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';

@Component({
  selector: 'preloader-profile-reset-password',
  templateUrl: './profile-reset-password.component.html',
  styleUrls: ['./profile-reset-password.component.scss']
})
export class ProfileResetPasswordComponent {

  constructor(
    private _settingsService: SettingsService,
    private _loggerService: LoggerService,
    private _navigationService: NavigationService,
    private _loginService: LoginService,
    private _modalService: NgbModal,
  ) {}

  openChangePasswordDialog() {
    let refDialog = this._modalService.open(
      ResetPasswordModalComponent,
      { windowClass: 'emalsys-modal', backdrop: 'static' }
    );
  }
}
