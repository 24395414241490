import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule    } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../../shared.module';

// Components
import { ContactComponent } from './contact.component';
import { ContactUsComponent } from './contact-us/contact-us.component';


@NgModule({
  imports: [
    // Angular Modules
    CommonModule,
    NgbModule,
    SharedModule
  ],
  declarations: [
    ContactComponent,
    ContactUsComponent
  ],
  providers: []
})


export class ContactModule {}
