<div id="alerts-process-body">
  <h5 class="alerts-process-header snipped">{{alertInfo?.name}}</h5>
  <p class="alerts-process-description">{{alertInfo?.description}}</p>
  <p *ngIf='!alertInfo?.description' class="alerts-process-description">There is no description.</p>

  <div class="process-icons-container">
    <button class="calendar-icon {{calendarStep}}" (click)="setComponent('calendar')">
      <i class="fa fa-calendar-o fa-2x" aria-hidden="true"></i>
    </button>
    <div class="lines">
    </div>
    <button class="message-icon {{offerStep}}" (click)="setComponent('offer')">
      <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
    </button>
    <div class="lines">
    </div>
    <button class="flight-icon {{orderStep}}" (click)="setComponent('order')">
      <i class="fa fa-plane fa-2x" aria-hidden="true"></i>
    </button>
  </div>
</div>
