import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { OrganisationDispatcherService } from '../../../../../../services/api/organisation/organisation.dispatcher';
import { ProfileDispatcherService } from '../../../../../../services/api/profile/profile.dispatcher';
import { RosterService } from '../../../../../../services/api/roster/roster.cached';
import { RostersDispatcherService } from '../../../../../../services/api/roster/rosters.dispatcher';
import { TagDispatcherService } from '../../../../../../services/api/tag/tag.dispatcher';
import { EventListenerService } from '../../../../../../services/utils/event-listener.service';
import { LoggerService } from '../../../../../../services/utils/logger.service';
import { RightService } from '../../../../../../services/utils/right.service';
// Data
import { COMPLIANCE_CODE, ROSTER_MANAGER_RIGHTS_LEVEL } from '../../../../../app.constants';
import { OrganisationInterface } from '../../../../../model/organisation.interface';
import { UserInterface } from '../../../../../model/user.interface';
import { TagsModalComponent } from '../../../../shared/modals/tags-modal/tags-modal.component';
import { RosterCompliancesService } from './../../../../../../services/api/roster/dependencies/compliances/roster-compliances.api';

@Component({
  selector: 'edit-roster-modal',
  templateUrl: './edit-roster-modal.component.html',
  styleUrls: ['./edit-roster-modal.component.scss']
})
export class EditRosterModalComponent implements OnInit {
  @Input() rosterId: any;
  @Input() organisationId: number;
  @Output() onDelete = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  public loading = false;
  public roster: any = {};
  public profile: any = {};
  public logo: File;
  public rosterCompliances: any;
  public editActive: number= null;
  public addActive = false;

  public newLevelRequirement;
  public newTitleRequirement;
  public editLevelRequirement;
  public editTitleRequirement;

  public canDeleteRoster = false;

  constructor(
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _rosterService: RosterService,
    private _loggerService: LoggerService,
    private _eventListenerService: EventListenerService,
    private _profileDispatcher: ProfileDispatcherService,
    private _rights: RightService,
    private _tagsDispatcher: TagDispatcherService,
    private _cachedRosterService: RosterService,
    private _rosterCompliancesService: RosterCompliancesService,
    private _rosterDispatcher: RostersDispatcherService,
    private _organisationDispatcher: OrganisationDispatcherService
  ) { }


  ngOnInit() {
    /**
     * TODO: switchmap!
     */
    this._rosterDispatcher.getSelectedRoster().subscribe(res => {
      // The roster is cloned
      this.roster = JSON.parse(JSON.stringify(res));
      this.profile = this.roster.roster_profile;
      this.getRosterCompliances(this.rosterId);
    });

    this._profileDispatcher.getProfile().subscribe(
      (res: UserInterface) => {
        this.profile = res;
        let rosterManager = res.roster_managers.find(x => x.roster.id === this.rosterId);
        if (rosterManager) {
          this.canDeleteRoster = this._rights.isRosterManagerRight(res, this.rosterId, ROSTER_MANAGER_RIGHTS_LEVEL.Supervisor);
        }
      }
    );

  }


  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.logo = fileList[0];
    }
  }

  editRoster() {
    this.loading = true;
    let body = new FormData();
    body.append('logo', this.logo);
    body.append('roster', JSON.stringify(this.roster));
    body.append('profile', JSON.stringify(this.profile));
    this._rosterService.updateRoster(this.rosterId, body).subscribe(res => {
      this.onEdit.emit("Roster edited");
      this.loading = false;
      this._eventListenerService.publish("roster", true);
      this.activeModal.close();
    });
  }

  deleteRoster() {
    this.loading = true;
    this._organisationDispatcher.getSelectedOrganisation().pipe(
      switchMap(
        (organisation: OrganisationInterface) => this._cachedRosterService.deleteRoster(this.rosterId, organisation.id)
      )
    ).subscribe(() => {
      this.onDelete.emit("Roster deleted");
      this.loading = false;
      this.activeModal.close();
    });
  }

  addRosterCompliance(title, level) {
      let body = {
          title: title,
          level: level
      };
      this._rosterCompliancesService.addRosterCompliance(this.rosterId, body).subscribe(res => {
        this.getRosterCompliances(this.rosterId);
        this.addActive = false;
        this.newTitleRequirement = null;
        this.newLevelRequirement = null;
      });
  }

  editRosterCompliance(title, level, requirementId) {
      let body = {
          title: title,
          level: level
      };
      this._rosterCompliancesService.updateRosterCompliance(requirementId, body).subscribe(res => {
        this.getRosterCompliances(this.rosterId);
      });
  }

  deleteRosterCompliance(requirementId) {
      this._rosterCompliancesService.removeRosterCompliance(requirementId).subscribe(res => {
        this.getRosterCompliances(this.rosterId);
      });
  }

  getRosterCompliances(idRoster) {
    this._rosterCompliancesService.getRosterCompliances(idRoster).subscribe(res => {
      this.rosterCompliances = res;
    });
  }

  setLevel(member) {
    return COMPLIANCE_CODE.filter(level => level.value === member.level)[0].label;
  }

  displayModalTag() {
    let tagModal = this._modalService.open(TagsModalComponent, {backdrop: 'static'});

    tagModal.componentInstance.rosterId = this.rosterId;
    tagModal.componentInstance.membersMode = false;
  }

}
