<div id="confirmation-container">

  <div class="loading" *ngIf="loading">
    <spinner-component>
    </spinner-component>
  </div>
  <div *ngIf="!loading">
    <i *ngIf="!enabled" class="fa fa fa-meh-o custom-icon red" aria-hidden="true"></i>
    <i *ngIf="enabled" class="fa fa-smile-o custom-icon green" aria-hidden="true"></i>
    <h2>{{content}}
      <br>
      <a *ngIf="environment !== 'adpc'" routerLink='/public'>Return to home page</a>
      <a *ngIf="environment === 'adpc'" href="https://app.adpc.net/practitioners-roster">Return to ADPC home page</a>
    </h2>
  </div>


</div>
