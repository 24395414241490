import { Component, OnInit } from '@angular/core';
// Services
import { AdministrationSkillsService } from '../../../../services/api/administration-skills.service';
import { LoggerService } from '../../../../services/utils/logger.service';
import { ListService   } from '../../../../services/utils/list.service';
import { ToastService        } from '../../../../services/utils/toast.service';
// Modal
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector   : 'administration-skills',
  templateUrl: 'administration-skills.component.html',
  styleUrls  : ['administration-skills.component.scss']
})

export class AdministrationSkillsComponent implements OnInit {

  // Variable declarations

  // List of skills to be retrived from api
  public listSkills: any;
  // Variable for the added skill
  public newSkill: any;
  // Keyword to filter the list
  public keyword: string;
  // Options for http requests
  public options: any;
  // Array of skills to merge
  public skillsToMerge: any;
  // Display the merge skill options
  public displayMergeOptions: boolean;
  // Display message when attempting to check more than 2 skills
  public errorMessageMerge = 'You cannot check more than 2 skills';
  public displayErrorMerge: boolean;
  // Display options to finalise merging
  public displayConfirmMerge: boolean;
  // Uncheck all checkbox when closing merge options
  public ckeckIsFalse: boolean;
  public selectedAll: any;
  // Change icons
  public ascOrder: boolean;
  public descOrder: boolean;
  // Skill selection
  public selectedSkill: any;
  // Change background color on skill selection
  public isSelected: boolean;

  constructor(
    private _admistrationSkillsService: AdministrationSkillsService,
    private _logger: LoggerService,
    public _listService: ListService,
    public _toastService: ToastService,
    private _modalService: NgbModal,
  ) {
    this.newSkill = {};
    this.skillsToMerge = [];
    this.displayMergeOptions = false;
    this.displayErrorMerge = false;
    this.displayConfirmMerge = false;
    this.keyword = '';
    this.ckeckIsFalse = false;
    this.ascOrder = true;
    this.descOrder = true;
    this.isSelected = false;
  }

  ngOnInit() {
    this.getSkillsList();
  }

  // Methods

  // GET ALL
  public getSkillsList() {
    this._admistrationSkillsService.getSkillsList().subscribe(res => {
      this.listSkills = res;
    this._logger.log('Skills', this.listSkills);
    });
  }

  // ADD
  public addSkill(data) {
    this._admistrationSkillsService.addSkill(data);
    this._logger.log('data', data);
  }

  // DELETE
  public deleteSkill(data) {
    this._admistrationSkillsService.deleteSkill(data);
    this._logger.log('id of data', data.id);
  }

  // SELECT
  public selectSkill(skill) {
    this.selectedSkill = skill;
    this.isSelected = true;
    if (this.skillsToMerge.length < 2) {
      this.skillsToMerge.push(skill);
      this._logger.log(this.skillsToMerge);
      this.displayMergeOptions = true;
      this._toastService.show('snackBarAdministrationSelectSkill', 'show', 30000);
    } else {
      this.displayErrorMerge = true;
    }
    if (this.skillsToMerge.length === 2) {
      this.displayConfirmMerge = true;
    }
  }

  // SORT
  public sortSkillsByNameOrder(listSkills) {
    this.listSkills = this._listService.sortList(listSkills, 'name');
    this.ascOrder = false;
  }

  public sortSkillsByNameReverse(listSkills) {
    this.listSkills = this._listService.sortList(listSkills, 'name', false);
    this.ascOrder = true;
  }

  public sortSkillsByUsersOrder(listSkills) {
    this.listSkills = this._listService.sortList(listSkills, 'skill.count_user_tags', false);
    this.descOrder = true;
  }

  public sortSkillsByUsersReverse(listSkills) {
    this.listSkills = this._listService.sortList(listSkills, 'skill.count_user_tags');
    this.descOrder = false;
  }

  // Prepare the list to merge
  public addToMerge(skill) {
    this._logger.log(this.displayErrorMerge);
    if (this.skillsToMerge.length < 2) {
      this.skillsToMerge.push(skill);
      this._logger.log(this.skillsToMerge);
      this.displayMergeOptions = true;
    } else {
      this.displayErrorMerge = true;
    }
    if (this.skillsToMerge.length === 2) {
      this.displayConfirmMerge = true;
    }
  }

  // Invert the skills to merge to keep the first skill name instead of the second
  public invertSkillsToMerge() {
    this.skillsToMerge = this.skillsToMerge.reverse();
  }

  // Close the merge options, empty the choice arrays and uncheck all checkbox
  public cancelMerge() {
    this.skillsToMerge = [];
    this.displayErrorMerge = false;
    this.displayConfirmMerge = false;
    this.displayMergeOptions = false;
    // this.uncheckAll();
  }

  // Modal
  open(content) {
    if (this.skillsToMerge.length === 2) {
      this.displayConfirmMerge = true;
      this._modalService.open(content, { windowClass: 'emalsys-modal', backdrop: 'static' });
    }
  }

  // public uncheckAll() {
  //     for (let i = 0; i < this.listSkills.length; i++) {
  //       this.listSkills[i].selected = this.selectedAll;
  //     }
  // }
  // checkIfAllSelected() {
  //   this.selectedAll = this.listSkills.every(function(item: any) {
  //     return item.selected == false;
  //   })
  // }
}
