/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map.component";
import * as i3 from "../../../../../services/external/leaflet.service";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dashboard-members-map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "members-map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableZoom() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard-members-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 573440, null, 0, i2.MapComponent, [i3.LeafletService], null, null)], null, null); }
var MapComponentNgFactory = i1.ɵccf("dashboard-members-map", i2.MapComponent, View_MapComponent_Host_0, { members: "members", organisation: "organisation", selected: "selected" }, { onProfileShow: "onProfileShow" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
